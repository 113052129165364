import gmx_zMixins         from '../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_LocalValues          from './set_LocalValues'
import lmx_Watching             from './Watching'

import lmx_Events               from './Events'
import lmx_Controller           from './Controller'

import chart_Discipline         from './chart_Discipline'
import chart_Punch              from './chart_Punch'


export default {
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_Watching,
    lmx_Events,
    lmx_LocalValues,

    lmx_Controller,
    
    chart_Discipline,
    chart_Punch,
  ],
}
