import * as d3 from 'd3'
import { finished } from 'stream'

export default {
  methods: {
    draw_SummaryTableLines() {
      let lineDepth_1 = 0
      let headerGroup = this.tableGroup

      // draw line when the HeaderBorderAuto is 'N'
      if (this.Table.HeadBorderTop == 'Y') {
        headerGroup
        .append('line') // Top
        .attr('x1',0).attr('y1', 0)
        .attr('x2',this.HeaderTotalWidth).attr('y2', 0)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }

      if (this.Table.HeadBorderBottom == 'Y') {
        headerGroup
        .append('line') // Bottom
        .attr('x1',0).attr('y1', this.HeaderTotalHeight)
        .attr('x2',this.HeaderTotalWidth).attr('y2', this.HeaderTotalHeight)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }
      if (this.Table.HeadBorderLeft == 'Y') {
        headerGroup
        .append('line') // Left
        .attr('x1',0).attr('y1', 0)
        .attr('x2',0).attr('y2', this.HeaderTotalHeight)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }
      if (this.Table.HeadBorderRight == 'Y') {
        headerGroup
        .append('line') // Right
        .attr('x1',this.HeaderTotalWidth).attr('y1', 0)
        .attr('x2',this.HeaderTotalWidth).attr('y2', this.HeaderTotalHeight)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }

      // Table Left & Right border
      if (this.Table.TableCellOutlineDisplay == 'Y') {
        headerGroup
        .append('line')
        .attr('x1',0).attr('y1', this.HeaderTotalHeight)
        .attr('x2',0)
        .attr('y2', this.TableTotalHeight)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])

        headerGroup
        .append('line')
        .attr('x1',this.HeaderTotalWidth).attr('y1', this.HeaderTotalHeight)
        .attr('x2',this.HeaderTotalWidth)
        .attr('y2', this.TableTotalHeight)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }
      // console.log(this.clone_Header)

      let col = -1
      this.clone_Header.forEach((data, h) => {
        // Draw the title of Header
        
        headerGroup
        .append('text')
        .attr('x', () => {
          if (this.Header[h].width > 0) {
            col += 1
            if (data.align == 'start') return data.x1 + this.clone_Columns[col].padding
            if (data.align == 'middle') return data.cx
            if (data.align == 'end') return data.x2 - this.clone_Columns[col].padding
          } else {
            if (data.align == 'start') return data.x1
            if (data.align == 'middle') return data.cx
            if (data.align == 'end') return data.x2
          }
        })

        .attr('y',data.cy)
        .style('font-family', this.Table.TableHeadFont)
        .attr('class', this.Table.TableHeadFontStyle[data.depth-1])
        .attr('text-anchor', data.align)
        .attr('alignment-baseline', 'middle')
        .attr('font-size', this.Table.TableHeadFontSize[data.depth-1])
        .attr('fill', this.Table.TableHeadFontColor[data.depth-1])
        .text(data.name)
        
        let fianlCell = this.HeaderTotalHeight + (this.DataItems.length * this.Table.TableCellHeight)
        
        // Draw the line of Header
        if (this.Table.HeaderBorderAuto == 'Y' &&  h < this.clone_Header.length-1) {
          if (data.depth == 1) {
            headerGroup
            .append('line') // Vertical Line of Group
            .attr('x1',data.x2).attr('x2',data.x2)
            .attr('y1',data.y1).attr('y2', this.HeaderTotalHeight)
            .attr('stroke', this.Table.TableHeadStrokeColor[0]).attr('stroke-width',this.Table.TableHeadStroke[data.depth-1])
          } else {
            headerGroup
            .append('line') // Horizental Line
            .attr('x1',data.x1).attr('y1',data.y1).attr('x2',data.x2).attr('y2',data.y1)
            .attr('stroke',this.Table.TableHeadStrokeColor[data.depth-1]).attr('stroke-width',this.Table.TableHeadStroke[data.depth-1])

            if (data.border > 0) {
              headerGroup
              .append('line') // Vertical Line
              .attr('x1', data.x2).attr('y1', data.y1).attr('x2', data.x2)
              .attr('y2', () => {
                if (this.Table.TableCellVLineDisplay == 'Y') {
                    return this.TableTotalHeight
                } else {
                  return data.y2
                }
              })
              .attr('stroke-width',this.Table.TableHeadStroke[data.depth-1])
              .attr('stroke', this.Table.TableHeadStrokeColor[data.border-1])
            }
          }
        }

        // Draw the line of Data Cell
        if (this.Table.TableCellVLineDisplay == 'Y' &&  h < this.clone_Header.length-1) {
          if (data.depth == 1) {
            headerGroup
            .append('line') // Vertical Line of Group
            .attr('x1',data.x2).attr('x2',data.x2)
            .attr('y1', this.HeaderTotalHeight).attr('y2', fianlCell)
            .attr('stroke', this.Table.TableHeadStrokeColor[0]).attr('stroke-width',this.Table.TableHeadStroke[data.depth-1])
          } else {
            if (data.border > 0) {
              headerGroup
              .append('line') // Vertical Line
              .attr('x1', data.x2).attr('x2', data.x2)
              .attr('y1', this.HeaderTotalHeight).attr('y2', fianlCell)
              .attr('stroke-width',this.Table.TableHeadStroke[data.depth-1])
              .attr('stroke', this.Table.TableHeadStrokeColor[data.border-1])
            }
          }
        }

        // Draw the line of Footer
        if (this.Table.TableFootLineDisplay == 'Y' &&  h < this.clone_Header.length-1) {
          if (data.x2 < this.FooterMergeWidth) return
          if (data.depth == 1) {
            headerGroup
            .append('line') // Vertical Line of Group
            .attr('x1',data.x2).attr('x2',data.x2)
            .attr('y1', fianlCell).attr('y2', fianlCell + this.Table.TableFootHeight)
            .attr('stroke', this.Table.TableHeadStrokeColor[0]).attr('stroke-width',this.Table.TableHeadStroke[data.depth-1])
          } else {
            if (data.border > 0) {
              headerGroup
              .append('line') // Vertical Line
              .attr('x1', data.x2).attr('x2', data.x2)
              .attr('y1', fianlCell).attr('y2', fianlCell + this.Table.TableFootHeight)
              .attr('stroke-width',this.Table.TableHeadStroke[data.depth-1])
              .attr('stroke', this.Table.TableHeadStrokeColor[data.border-1])
            }
          }
        }

        
    })
  },
  }
}
