import FetchStreamApi from './api/fetchStream.api'

export default class FetchStreamService {

  constructor() {
    this.fetchStreamApi = new FetchStreamApi()
  }

  selectVideoFile(auth) {
    return this.fetchStreamApi.selectVideoFile(auth)
  }

  selectPhotoFile(auth) {
    return this.fetchStreamApi.selectPhotoFile(auth)
  }

  selectedFile(token,path, name='', sort={}) {
    return this.fetchStreamApi.selectedFile(token, path, name, JSON.stringify(sort))
  }

  selectImageFile(auth) {
    return this.fetchStreamApi.selectImageFile(auth)
  }

}
