
import * as d3 from 'd3'

export default {

  methods: {

    draw_Forms() {

      this.canvas_Form = this.svg
      .append('g')
      .attr('id', `CANVAS_FROM`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')
     
      if(this.selectMenu.AREA == 'OVERALL') {
        this.canvas_Form
        .append('rect')
        .attr('x', 0).attr('y', 1590).attr('width', 1300).attr('height', 10)
        .attr('fill', '#D71638')
      } else {
        this.canvas_Form
        .append('rect')
        .attr('x', 0).attr('y', 1110).attr('width', 1600).attr('height', 10)
        .attr('fill', '#D71638')
      }
   
      this.canvas_Form
      .append('image')
      .attr('xlink:href', this.HOST + '/icon/Logo/TrionFPU_r1.svg')
      .attr('x', 30).attr('y', 30).attr('width', 230)


      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 108)`)
      .attr('font-size', 24)
      .attr('fill', '#000')
      .text('Erection Network Diagram for Construction')

      this.canvas_Form
      .append('text')
      .attr('transform', `translate(30, 125)`)
      .attr('font-size', 12)
      .attr('fill', '#44A9DF')
      .text('Block Division Rev.2 (29 Aug 2024)  / Block Schedule Rev.0 (29 Aug 2024)')
      
      this.loadSvg(
        this.canvas_Form, {
        x: 0, y: 0,
        url : `/Visualization/ErectionNetwork/Network_${this.selectMenu.AREA}.svg`
      }).then(() => {

        d3.selectAll("text").style('font-family', 'roboto').style('cursor', 'default')

        if(this.selectMenu.AREA == 'OVERALL') {
          
          //OVERALL
          this.blkData.forEach((d,i) => {
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            .style('cursor', 'pointer')
            .on('mouseover', () => {
              d3.select(`#PNG-${d.BLK}`).transition().duration(200).style('opacity', .5)
            })
            .on('mouseout', () => {
              d3.select(`#PNG-${d.BLK}`).transition().duration(200).style('opacity', 1)
            })
            .on('click', () => {
              this.draw_Popup_Window(d)
            }) 
          })

           // Load a JGS logo SVG
          this.loadSvg(
            this.canvas_Form, {
            x   : 1160, y   : 1540,
            url : '/icon/Logo/JGS.svg'
          })

        } else {

          // HULL & TOPSIDE
          this.blkData.filter(f=> f.AREA == this.selectMenu.AREA).forEach((d,i) => {
            d3.select(`#BOX-${d.BLK}`).attr('opacity', 1)
            d3.select(`#EREC-${d.BLK}`)
            .style('cursor', 'pointer')
            .on('mouseover', () => {
              d3.select(`#BOX-${d.BLK}`).transition().duration(200)
              .style('fill', '#F4F5F5').style('stroke', '#757575').attr('opacity', .5)
            })
            .on('mouseout', () => {
              d3.select(`#BOX-${d.BLK}`).transition().duration(200)
              .style('fill', '#fff').style('stroke', '#EC417A').attr('opacity', 1)
            })
            .on('click', () => {
              this.draw_Popup_Window(d)
            }) 
          })

        }

        

      })

     
      

    },


    

    

   

  }
}