export default {
  methods: {
    tableGrouppedBody(selection, source) {
      let table_ = selection.append('g')
      if(source.id) table_.attr('id', source.id)

      table_
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', 300).attr('height', 300)
      .attr('stroke-width', 0)
      .attr('fill', '#fff')
      console.log(source.lhHeader)

      table_
      .append('line')
      .attr('x1',0).attr('y1', source.form.lhHeader)
      .attr('x2', 300).attr('y2', source.form.lhHeader)
      .attr('stroke-width', 0.5)
      .style('stroke', '#757575')
    }
  }
}