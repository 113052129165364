
import * as d3 from 'd3'

export default {

  data: () => ({
    dir : [
      {AREA:'NORTH',    FOLDER: 'ND11'},
      {AREA:'CENTRAL',  FOLDER: 'CD11'},
      {AREA:'SOUTH',    FOLDER: 'SD11'},
      {AREA:'FLARE',    FOLDER: 'NF11'},
      {AREA:'LQ',       FOLDER: 'LD11'},
    ],

    pop           : {width: 1100, height: 700, x: 250, y: 150, bar: 32},
  }),


  methods: {

    draw_Popup_Window(blk) {

      let data_ = this.blockInfo.find(f=> f.BLK == blk)

      this.canvas_Popup = this.svg
      .append('svg')
      .attr('width', 1100).attr('height', 700)
   
      .append('g')
      .attr('id', `POPUP`)
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('font-family', 'roboto')
      .attr('opacity', 0).attr('visibility', 'hidden')
     
      // Background - Close
      this.canvas_Popup
      .append('rect')
      .attr('x', -.5).attr('y', -.5).attr('width', this.Canvas.CanvasWidth)
      .attr('height', this.Canvas.CanvasHeight).attr('fill', '#000').attr('opacity', .7)
      .on('click', () => {
        this.close_Popup()
      }) 

      let p = this.pop
      let scrollY = p.y

      // Filter Window
      this.canvas_Popup
      .append('rect')
      .attr('x', p.x).attr('y', scrollY)
      .attr('width', p.width).attr('height', p.height) .attr('rx', 2).attr('stroke', '#757575').attr('stroke-width', 1)
      .attr('fill', '#fff').attr('filter', 'url(#dropshadow)').attr('opacity', .5)

      // Window bar
      this.canvas_Popup
      .append('rect')
      .attr('x', p.x).attr('y', scrollY).attr('width', p.width).attr('height', p.bar)
      .attr('fill', 'url(#windowBar)')

      // Contents Window 
      this.canvas_Popup
      .append('rect')
      .attr('x', p.x).attr('y', scrollY + p.bar).attr('width', p.width).attr('height', p.height - p.bar)
      .attr('fill', 'url(#contentWindow)')

      this.canvas_Popup
      .append('line')
      .attr('x1', p.x).attr('x2', p.width)
      .attr('y1', scrollY + p.bar + 1).attr('y2', scrollY + p.bar + 1)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)

      this.canvas_Popup
      .append('line')
      .attr('x1', p.x).attr('x2', p.width)
      .attr('y1', scrollY + p.bar + 2).attr('y2',scrollY + p.bar + 2)
      .attr('stroke', '#fff').attr('stroke-width', 1)

      this.canvas_Popup.transition().duration(500)
      .attr('visibility', 'display').attr('opacity', 1)

      this.draw_Popup_Elements(this.canvas_Popup, data_)
    },


    draw_Popup_Elements(selection, data_) {

      let scrollY = (window.scrollY) + this.pop.y

      // Close Button
      let close = selection
      .append('g')
      .attr('id', 'CLOSE')
      .attr('transform', `translate(${this.pop.x+this.pop.width-25}, ${scrollY +8})`)
      .attr('font-family', 'roboto')
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        d3.select('#CLOSE').style('opacity', .3)
      })
      .on('mouseout', () => {
        d3.select('#CLOSE').style('opacity', 1)
      })
      .on('click', () => {
        this.close_Popup()
      }) 

      close
      .append('rect')
      .attr('x', 0).attr('t', 0)
      .attr('width', 15).attr('height', 15)
      .attr('fill', '#fff')
      .attr('stroke', '#bcbcbc').attr('stroke-width', .5)

      close
      .append('path')
      .attr('transform', `translate(0, 0) scale(.03)`)
      .attr('fill', '#333')
      .attr('d', `M289.94,256l95-95A24,24,0,0,0,351,127l-95,95-95-95A24,24,0,0,0,127,161l95,95-95,95A24,24,0,1,0,161,385l95-95,95,95A24,24,0,0,0,385,351Z`)
      
      // KEYPLAN
      this.loadSvg(
        selection, {
        x   : this.pop.x,
        y   : scrollY,
        url : `/Visualization/ErectionNetwork/Network_Keyplan.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
      })

      
      // Load SVG file
      let setUrl = ``

      if (data_.AREA == 'HULL') {
        setUrl = `Hull/${data_.BLK}`
      }  if (data_.AREA == 'TOPSIDE') {
        setUrl = `Topside/${this.dir.find(f=> f.AREA == data_.ZONE).FOLDER}/${data_.BLK}`
      } 
      // if (data_.LEVEL == 'MODULE') setUrl = `Topside/${data_.BLK}`
      
      this.loadSvg(
        selection, {
        x   : this.pop.x + 100,
        y   : scrollY + this.pop.bar + 70,
        url : `/Visualization/BlockDivision/${setUrl}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
        d3.select(`#BLOCK-GROUP`).attr('transform', `translate(0, 0) scale(.85)`)
      })


      selection
      .append('text')
      .attr('transform', `translate(${this.pop.x + 10}, ${scrollY + 20})`)
      .attr('font-size', 13).attr('fill', '#000')
      .text(`${data_.AREA} > ${data_.ZONE} > ${data_.LEVEL} > ${data_.BLK}`)

      selection
      .append('text')
      .attr('transform', `translate(${this.pop.x + 10}, ${scrollY + 100})`)
      .attr('font-size', 10).attr('fill', '#000')
      .text(data_.AREA)

      selection
      .append('text')
      .attr('transform', `translate(${this.pop.x + 10}, ${scrollY + 120})`)
      .attr('font-size', 10).attr('fill', '#000')
      .text(data_.BLK)
    },


    close_Popup() {
      this.canvas_Popup.transition().duration(500).attr('opacity', 0)
      this.canvas_Popup.transition().duration(500).delay(500).attr('visibility', 'hidden')

      setTimeout(() => { 
        this.canvas_Popup.remove()
      }, 500)
    },


  }
}