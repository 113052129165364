import Filter from '../../../../../../includes/primitives/Filter_Defs'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,
    Module: '41M001',
  }),
  computed: {

    ready2draw() {
      return (
        Object.keys(this.Canvas).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){

      Filter.setDefs_DropShadow(this.svg, 'optionShadow', 1, 0.1, 1, 1)
      
    },

    
    
    
  }
}