 /* 
  ### Chart Controllers ### 
  Predefined component's behaviors for the Filter & Action
*/
import System_ChartOptions from '../../09_Projects/TCO/00_System/ChartOptions'
import System_Dropdown from '../../09_Projects/TCO/00_System/Dropdown'
import System_SubMenu_01 from '../../09_Projects/TCO/00_System/SubMenu_01'
  
/* 
  ### Chart Customized ### 
  Unregulated chart components, not on the role of the controller.
*/
// Common
import CMN_HistoryTable from        '../../01_Charts/99_Common/HistoryTable'
import CMN_TBD from                 '../../01_Charts/99_Common/TBD'
import CMN_Up2UText from            '../../01_Charts/99_Common/Up2UText'
import CMN_3DViewerEmbedded from    '../../01_Charts/99_Common/3DViewerEmbedded'

//~~~~~~~~~~~~~~~~~~~~CRISP Project --> svg v2

//00_Home
//01_Project
//02_Engineering
//03_Planning
//04_Construction
//05_Procurement
//06_QualityControl
//07_SystemCompletion

export default {
  
  // System
  System_ChartOptions,
  System_Dropdown,
  System_SubMenu_01,

  // Common
  CMN_HistoryTable,
  CMN_TBD,
  CMN_Up2UText,
  CMN_3DViewerEmbedded,

  //~~~~~~~~~~~~~~~~~~~~CRISP Project --> svg v2

  //00_Home
  //01_Project
  //02_Engineering
  //03_Planning
  //04_Construction
  //05_Procurement
  //06_QualityControl
  //07_SystemCompletion
}
