import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    drawDeltaChart() {
      
      
      let Delta = this.chartArea
        .append('g')
        .selectAll('g')
        .data(this.DataItems)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${this.scaleXInfo.scale(d.title)},60)`)
      
      
      Delta // Front Box
      .append('path')
      .attr('d', d => {
        let width = this.setThickness 
        return `
            M 0 ${this.Canvas.CanvasChartHeight} 
            H ${width} 
            V ${this.Canvas.CanvasChartHeight} 
            H ${0} 
          `
      })
      .attr('fill', d => {
       if (d.delta < 0) {
         return '#EC407A'
       } else {return '#8BC248'}
      })
      .transition()
      .duration(500)
      .attr('d', d => {
        let width = this.setThickness 
        return `
            M 0 ${this.Canvas.CanvasChartHeight} 
            H ${width} 
            V ${this.Canvas.CanvasChartHeight + ((d.delta/2) * -1)} 
            H ${0} 
          `
      })

      // )
      Delta
        .append('text')
        .attr('id', (d, i) => `DeltaText${i}`)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', (d) => {
          if (d.delta < 0) { return 'hanging' } else { return '' }
        })
        .style('font-size', this.Bar.BarValueSize)
        .style('font-family', this.Bar.BarValueFont)
        .attr('x', this.setThickness / 2)
        .attr('y', d => {
          let y = this.Canvas.CanvasChartHeight + ((d.delta/2) * -1)
          
          if (d.delta < 0) {
            return y + 2
          } else 
          { return y - 2}
        })
        .attr('fill', (d) => {
          if (d.delta < 0) {
            return '#EC407A'
          } else {return '#8BC248'}
        })
        .text(d => `${d.delta?d.delta.toFixed(1):'0'}%`)
 


    }
  }
}
