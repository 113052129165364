<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-3d-viewer",
  mixins: [ mx_Core ],
  props: {
    id: String,
    modeExport: Boolean,

    FilterValues: {
      type: Object,
      default: () => ({})
    },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    // Queries: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()          // Global
      this.complete()
    },
    drawChart() {
      this.import3DViewer()
      this.open3DViewer()
    },
  }
}
</script>