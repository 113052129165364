import * as d3 from 'd3'
import { finished } from 'stream'

export default {
  methods: {
    // ICON
    draw_Container() {

      let modalHeader = this.ContainerGroup
      .append('g')
      .attr('transform', `translate(0,0)`)

      // Lines
      modalHeader
      .append('g')
      .selectAll('line')
      .data(this.ModalDraw.Lines)
      .enter()

      .append('line')
      .attr('x1', d => d.x1)
      .attr('y1', d => d.y1)
      .attr('x2', d => d.x2)
      .attr('y2', d => d.y2)
      .attr('stroke', d => d.color)
      .attr('stroke-width', d => d.stroke)

      // Rects
      modalHeader
      .append('g')
      .selectAll('rect')
      .data(this.ModalDraw.Rects)
      .enter()

      .append('rect')
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('rx', d => d.radius)
      .attr('ry', d => d.radius)
      .attr('width', d => d.width)
      .attr('height', d => d.height)
      .attr('stroke', d => d.strokeColor)
      .attr('stroke-width', d => d.strokeWidth)
      .attr('fill', d => d.fillColor)

      // Text
      modalHeader
      .append('g')
      .selectAll('text')
      .data(this.Text.Text)
      .enter()

      .append('text')
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('font', d => d.font)
      .style('class', d => d.class)
      .attr('font-size', d => d.size)
      .attr('text-alignment', d => d.align)
      .attr('fill', d => d.color)
      .text( d => d.name)
    },
  }
}
