import * as d3 from 'd3'

export default {
  methods: {

    draw_Header() {

      this.Canvas_Area = this.svg
      .append('g')
      .attr('transform',`translate(0, 0)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      this.Canvas_Area
      .append('ellipse')
      .attr('cx', 600).attr('cy', 205)
      .attr('rx', 580).attr('ry', 9)
      .attr('fill', 'url(#GradientShadow)').attr('stroke', '#E0E0DF').attr('stroke-width', 0)
      
      this.Canvas_Area
      .append('rect')
      .attr('x', 10).attr('y', 10.5)
      .attr('rx', 5).attr('ry', 5)
      .attr('width', this.canvas.width-20).attr('height', 200)
      .attr('fill', '#fff').attr('stroke', '#E0E0DF').attr('stroke-width', .5)

      this.Canvas_Area
      .append('rect')
      .attr('x', 12).attr('y', 12.5)
      .attr('rx', 4).attr('ry', 4)
      .attr('width', this.canvas.width-24).attr('height', 196).attr('opacity', .8)
      .attr('fill', 'url(#GradientBG)').attr('stroke', '#E0E0DF').attr('stroke-width', .5)



      // Title of Header
      this.Header = this.Canvas_Area
      .append('g')
      .attr('transform',`translate(20.5, 20.5)`)
      
      this.Header
      .append('text')
      .attr('transform',`translate(${5}, 13)`)
      .attr('font-size', 16)
      .attr('fill', '#000').attr('text-anchor', 'start')
      .text(`S-Curve for Procurement`)

      this.Header
      .append('text')
      .attr('transform',`translate(${5}, 26)`)
      .attr('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'start')
      .text('Procurement Progress Status by Area & Discipline')

      // Layout Lines
      let line = [
        {x1: 0,      y1:40,  x2: 270,   y2: 40  },
        {x1: 275,    y1:0,   x2: 275,   y2: 181 },
        {x1: 425,    y1:0,   x2: 425,   y2: 181 },
        {x1: 575,    y1:0,   x2: 575,   y2: 181 },
        {x1: 725,    y1:0,   x2: 725,   y2: 181 },
      ]
      line.forEach(d=> {
        this.Header
        .append('line')
        .attr('x1', d.x1).attr('y1', d.y1).attr('x2', d.x2).attr('y2', d.y2).attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)
      })
      
      this.header_Phase()
      this.header_Area()
      this.header_Disc()

      this.draw_Timeline()
      this.draw_Grid()
      this.draw_Axis_Primary()

      this.draw_Chart_Controller(false)
      this.draw_Legends()
      this.set_Milestone()
    },

  }
}