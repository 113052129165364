import * as d3 from 'd3'
import __C from '../../includes/primitives/_constant_'   // Global Defs

export default {
  props: {
    movable: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    attrName: {
      x: '',
      y: ''
    },
    delta: {
      offsetX: 0,
      offsetY: 0,
      x: 0,
      y: 0
    },
    dragged: {
      x: 0,
      y: 0
    },
    el2Drag: null,
    setCursorMovable: null,
    popup2drag: null,
    setPopupCurMoveing: null
  }),
  created() {

    // Note.
    // "el2Drag" and "setCursorMovable" sould be defined as a data variable.
    // If they are defined as a method, it is not working on d3.drag features.
    // - Need to be considered as a computed method, but not verified yet.

    this.el2Drag = this.movable ?
      d3.drag()
        .on('start', this.start)
        .on('drag', this.drag)
        .on('end', this.end) :
      () => { }

    this.setCursorMovable = this.movable ?
      (selection) => {
        selection
          .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })
          .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
      } :
      () => { }
    
    this.popup2drag = d3.drag()
    .on('start', this.pstart)
    .on('drag', this.pdrag)

    this.setPopupCurMoveing = (selection) => {
      selection
        .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'pointer') })
        .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
    }  
  },
  methods: {
    start(_, i, a) {
      if (!this.movable) return

      // let tagName = d3.select(a[i]).node().tagName
      let coordinate = this.getCoordinates(d3.select(a[i]))

      this.delta.x = coordinate[0] - d3.event.x
      this.delta.y = coordinate[1] - d3.event.y
    },
    drag(_, i, a) {
      if (!this.movable) return
      let transform_ = d3.select(a[i]).attr('transform')
      let translate_ = `translate(${d3.event.x + this.delta.x},${d3.event.y + this.delta.y})`
      d3.select(a[i]).attr('transform', transform_.replace(/translate(.*?)\)/gi, translate_))
    },
    end(_, i, a) {
      if (!this.movable) return

      let atts = {}

      if(d3.select(a[i]).attr(__C.CHART.MOVED_EL_TYPE_TEXT)) {
        var targetNames = d3.select(a[i]).attr(__C.CHART.MOVED_EL_TYPE_TEXT).split('$')

        atts['type'] = __C.CHART.MOVED_EL_TYPE_TEXT
        atts['propName'] = targetNames[0]
        atts['subName'] = targetNames[1]
        atts['index'] = targetNames[2]
        atts['els'] = {
          [targetNames[3]]: Number((Number(d3.event.x) + Number(this.delta.x)).toFixed(2)),
          [targetNames[4]]: Number((Number(d3.event.y) + Number(this.delta.y)).toFixed(2))
        }
      
      // updated by jihee ----------------------------------------------------------- begin 
      } else if(d3.select(a[i]).attr(__C.CHART.MOVED_EL_TYPE_BARSERIES)) {
        let targetNames = d3.select(a[i]).attr(__C.CHART.MOVED_EL_TYPE_BARSERIES).split('$')

        atts['type'] = __C.CHART.MOVED_EL_TYPE_BARSERIES
        atts['propName'] = targetNames[0]
        atts['index'] = targetNames[1]
        atts['key'] = targetNames[2]
        atts['els'] = {
          [targetNames[3]]: Number((Number(d3.event.x) + Number(this.delta.x)).toFixed(2)),
          [targetNames[4]]: Number((Number(d3.event.y) + Number(this.delta.y)).toFixed(2))
        }
      } else if(d3.select(a[i]).attr(__C.CHART.MOVED_EL_TYPE_LINE)) {
        let targetNames = d3.select(a[i]).attr(__C.CHART.MOVED_EL_TYPE_LINE).split('$')
        atts['type'] = __C.CHART.MOVED_EL_TYPE_LINE
        atts['propName'] = targetNames[0]
        atts['index'] = targetNames[1]
        atts['els'] = {
          [targetNames[2]]: Number((Number(d3.event.x) + Number(this.delta.x)).toFixed(2)),
          [targetNames[3]]: Number((Number(d3.event.y) + Number(this.delta.y)).toFixed(2))
        }
      // updated by jihee ------------------------------------------------------------- end 
      } else if(d3.select(a[i]).attr('id')) {
        targetNames = d3.select(a[i]).attr('id').split('$')
        targetNames.forEach((name, i) => {
          atts[name] = (i === 0) ? 
            Number((Number(d3.event.x) + Number(this.delta.x)).toFixed(2)) : 
            Number((Number(d3.event.y) + Number(this.delta.y)).toFixed(2))
        })
      }
      
      this.$emit('moved', atts)
    },
    getCoordinates(selection) {
      let translate = selection.attr('transform')
      let coordinates = translate.substring(translate.indexOf('(') + 1, translate.indexOf(')')).split(',')

      // return coordinates.map(c => Math.round(c))
      return coordinates.map(c => c)
    },
    
    pstart(_, i, a) {
      let coordinate = this.getCoordinates(d3.select(a[i]))
      this.delta.x = coordinate[0] - d3.event.x
      this.delta.y = coordinate[1] - d3.event.y
    },
    pdrag(_, i, a) {
      let transform_ = d3.select(a[i]).attr('transform')
      let translate_ = `translate(${d3.event.x + this.delta.x},${d3.event.y + this.delta.y})`
      d3.select(a[i]).attr('transform', transform_.replace(/translate(.*?)\)/gi, translate_))
    }
  }
}
