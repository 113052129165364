export default {
  methods: {
    tableBarProgress(selection, source) {
      let table__ = selection
      .append('g')
      .attr('transform', `translate(${source.form.x}, ${source.form.y})`)

      if(source.form.title.text) {
        table__
        .append('text')
        .attr('x', source.form.title.x).attr('y', -10)
        .style('font-family', 'roboto').style('font-size', source.form.title.tSize).style('fill', source.form.title.tColor)
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(source.form.title.text)
      }

      let cumWidth = 0

      source.data.header.forEach((header, i) => {
        let cumHeight = source.form.lineHeight + 10
        cumWidth += header.width
        
        if(header.type == 'space') return
        if(header.type == 'bar') {
          let barStart = cumWidth - header.width
          let bar = [
            { label: '0'  , x: barStart + .5 },
            { label: '50' , x: barStart + Math.round(header.width / 2) + .5 },
            { label: '100', x: barStart + header.width + .5 },
          ]

          bar.forEach(info__ => {
            table__
            .append('text')
            .attr('x', info__.x)
            .attr('y', 10)
            .attr('text-anchor', 'middle')
            .attr('alignment-baseline', 'middle')
            .style('font-family', 'roboto')
            .style('font-size', 8)
            .style('fill', '#bcbcbc')
            .text(info__.label)

            table__
            .append('line')
            .attr('x1', info__.x).attr('y1', 13)
            .attr('x2', info__.x).attr('y2', 16)
            .style('stroke', '#bcbcbc')
            .style('stroke-width', 1)
          })

          source.data.body.forEach((body, j) => {
            table__
            .append('rect')
            .attr('transform', `translate(${bar[0].x}, ${cumHeight-10})`)
            .attr('width', header.width).attr('height', source.form.lineHeight - 2)
            .attr('fill', '#F4F5F5')

            let barW__ = (body[header.colName]/100) * header.width
  
            table__
            .append('rect')
            .attr('transform', `translate(${bar[0].x}, ${cumHeight-10})`)
            .attr('width', 0).attr('height', source.form.lineHeight - 2)
            .attr('stroke-width', 0.3).attr('stroke', source.form.sColor).attr('fill', source.form.bColor)
            .style('opacity', source.form.opacity)
            .transition().duration(500)
            .attr('width', barW__)

            table__
            .append('text')
            .attr('x', body[header.colName] < 40 ? bar[0].x + 2 : bar[0].x - 2)//40->25
            .attr('y', cumHeight - 1) 
            .attr('alignment-baseline', 'middle')
            .attr('text-anchor', body[header.colName] < 40 ? 'start' : 'end')//40->25
            .style('font-family', 'roboto').style('font-size', 9)
            .style('fill', body[header.colName] < 40 ? source.form.tColor2 : source.form.tColor1)//40->25
            .style('opacity', 0)
            .text(`${body[header.colName].toFixed(1)}%`)
            .transition().duration(500)
            .attr('x', body[header.colName] < 40 ? bar[0].x + barW__ + 2 : bar[0].x + barW__ - 2)//40->25
            .style('opacity', 1)

            cumHeight += source.form.lineHeight
          })
          
        } else {
          table__
          .append('text')
          .attr('x', cumWidth)
          .attr('y', 10)
          .attr('text-anchor', 'end')
          .attr('alignment-baseline', 'middle')
          .style('font-family', 'roboto')
          .style('font-size', 10)
          .style('fill', '#333')
          .text(header.text)

          source.data.body.forEach((body, j) => {
            let link_ = table__
            .append('text')
            .attr('transform', `translate(${cumWidth - 2}, ${cumHeight})`)
            // .attr('x', cumWidth - 2)
            // .attr('y', cumHeight)
            .attr('text-anchor', 'end')
            .attr('alignment-baseline', 'middle')
            .style('font-family', 'roboto')
            .style('font-size', 10)
            .style('fill', header.vcolor)
            .text(body[header.colName])

            if(header.linkable && body[header.colName] !== 0) {
              let req__ = JSON.parse(JSON.stringify(source.request))

              if(header.values) header.values.forEach(v => { req__.filters[v] = body[v] })
              if(header.filterStringCol) {
                req__.filterString = body[header.filterStringCol] // for the unofficial
                if(req__.iFilters) req__.iFilters.filterString = body[header.filterStringCol] // for the official rule to set filter-string
              }
              if(header.queryNameCol) req__.action.no = body[header.queryNameCol]
              if(header.titleCol && req__.data) req__.data.title = body[header.titleCol]

              let data__ = {
                x: cumWidth - 2,
                y: cumHeight,
                request: req__
              }
              link_.style('cursor', 'pointer')
              link_.call(s => { this.callHandlerTextLinkAction(s, data__) })
            }

            cumHeight += source.form.lineHeight
          })
        }
      })

      return table__
    },
    tableBarQuantity(selection, source) {
      let table__ = selection
      .append('g')
      .attr('transform', `translate(${source.form.x}, ${source.form.y})`)
      
      let cumWidth = 0
    },
    tableBarQuantityOverlapped(selection, source) {
      let table__ = selection
      .append('g')
      .attr('transform', `translate(${source.form.x}, ${source.form.y})`)

      // draw bars
      let barInfo = source.data.header.find(h => h.type == 'bar')

      table__
      .append('line')
      .attr('x1', barInfo.x + .5).attr('y1', 13)
      .attr('x2', barInfo.x + .5).attr('y2', 16)
      .style('stroke', '#bcbcbc')
      .style('stroke-width', 1)

      table__
      .append('line')
      .attr('x1', barInfo.x + barInfo.width + .5).attr('y1', 13)
      .attr('x2', barInfo.x + barInfo.width + .5).attr('y2', 16)
      .style('stroke', '#bcbcbc')
      .style('stroke-width', 1)

      let barH__ = source.form.lineHeight - 3
      
      source.data.body.forEach((body, i) => {
        table__
        .append('rect')
        .attr('transform', `translate(${barInfo.x}, ${source.form.lineHeight * (i + 1) + 3})`)
        .attr('width', barInfo.width).attr('height', barH__)
        .attr('fill', '#F4F5F5')

        let barW__ = (body[barInfo.colName]/100) * barInfo.width
        table__
        .append('rect')
        .attr('transform', `translate(${barInfo.x}, ${source.form.lineHeight * (i + 1) + 3})`)
        .attr('width', 0).attr('height', barH__)
        .attr('fill', source.form.bColor)
        .style('opacity', source.form.opacity)
        .transition().duration(500)
        .attr('width', barW__)
      })
  
      // put list data
      source.data.header.forEach(header => {
        if((['space', 'bar']).includes(header.type)) return

        table__
        .append('text')
        .attr('transform', `translate(${header.x}, ${10})`)
        .attr('text-anchor', header.align)
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', 8)
        .style('fill', '#bcbcbc')
        .text(header.text)

        source.data.body.forEach((body, i) => {
          let y__ = source.form.lineHeight * (i + 1) + 10

          let link_ = table__
          .append('text')
          .attr('transform', `translate(${header.x}, ${y__})`)
          .attr('text-anchor', header.align)
          .attr('alignment-baseline', 'middle')
          .style('font-family', 'roboto')
          .style('font-size', 10)
          .style('fill', header.vcolor)
          .text(body[header.colName])

          if(header.linkable && body[header.colName] !== 0) {
            let req__ = JSON.parse(JSON.stringify(source.request))

            if(header.values) header.values.forEach(v => { req__.filters[v] = body[v] })
            if(header.filterStringCol) {
              req__.filterString = body[header.filterStringCol] // for the unofficial
              req__.iFilters.filterString = body[header.filterStringCol] // for the official rule to set filter-string
            }
            if(header.titleCol && req__.data) req__.data.title = body[header.titleCol]

            let data__ = {
              x: header.x - 2,
              y: y__,
              request: req__
            }
            link_.style('cursor', 'pointer')
            link_.call(s => { this.callHandlerTextLinkAction(s, data__) })
          }
        })
      })

      return table__
    }
  }
}
