import * as d3 from 'd3'
export default {
  data: () => ({
    J_radars: {
      radians: 2 * Math.PI,
      level: {
              no:             5,
              sWidth:         0.3,
              sColor:         '#BCBCBC',
              opacity:        0.5,
              lastStWidth:    0.5,
              lastStColor:    '#757575',
              lastOpacity:    .5,
              maxValue:       100,
              space:          3,
              tSize:          9,
              tColor:         '#757575',
      },  
      axes: { 
              display:        'Y',
              sWidth:         0.5,
              sColor:         '#bcbcbc',
              opacity:        0.7,
      },  
      series: { 
              tSize:          11,
              fWeight:        400,
              tColor:         ['#000','red','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000','#000',],
              factor:         0.75,
      },  
      value: {  
              display:        'Y',
              tSize:           9,
              tColor:         '#000',
      },  
      area: { 
              sWidth:         1,
              sColor:         "#44A9DF",
              fColor:         "LightBlue",
              opacity:        0.3,
              colorType:      '',
      },  
      node: { 
              size:           2,
              sWidth:         1,
              sColor:         "#44A9DF",
              fColor:         "#44A9DF",
              opacity:        0.7,
      },
    },
  }),

  methods: {

    draw_gmx_RadarChart(selection, s) {
      let ra = this.J_radars
      let radarData = s.data.reverse()

      let allAxis = radarData.map(d => d[s.code])
      let total = allAxis.length
      let radarSize = s.size
      let radius = radarSize / 2
      let angleSlice  = ra.radians / total

      ra.series.tColor = ra.series.tColor.reverse()

      //Circular segments
      let RadarChart = selection
      .append('g')
      .attr('transform', `translate(${s.x - (radarSize/2)}, ${s.y - (radarSize/2)})`)

      for(let j=0; j<ra.level.no; j++) {
        let levelFactor = radius * ((j + 1) / ra.level.no)
        RadarChart
        .append('circle')
        .attr('cx', radarSize/2)
        .attr('cy', radarSize/2)
        .attr('r', levelFactor)
        .attr('fill', 'Transparent')
        .attr('stroke-width', () => {if (j == (ra.level.no - 1)) return ra.level.lastStWidth; else return ra.level.sWidth})
        .attr('stroke', () => {if (j == (ra.level.no - 1)) return ra.level.lastStColor; else return ra.level.sColor})
        .attr('opacity', () => {if (j == (ra.level.no - 1)) return ra.level.lastOpacity; else return ra.level.opacity})
      }

      //Level Text indicating at what % each level is
      if(ra.axes.display == 'Y') {
        for(let j=0; j<ra.level.no; j++){
          let levelFactor = radius * ((j + 1) / ra.level.no)

          RadarChart
          .append('text')
          .attr('transform', `translate(${radarSize / 2 - levelFactor + ra.level.space}, ${radarSize/2-levelFactor})`)
          .attr('x', levelFactor * (1 - Math.sin(0)))
          .attr('y', levelFactor * (1 - Math.cos(0)))
          .attr('fill', ra.level.tColor)
          .attr('font-size', ra.level.tSize)
          .text(`${(j+1)*100/ra.level.no}%`)
        }
      }
        // group of the axes in the Radar Chart 
        let Axes = RadarChart
        .selectAll('.axis')
        .data(allAxis)
        .enter()
        .append('g')
        .attr('class', 'axis')

        // draw lines for each axis
        Axes
        .append('line')
        .attr('x1', radarSize / 2)
        .attr('y1', radarSize / 2)
        .attr('x2', (d, i) => radarSize / 2 * (1 - Math.sin(i * ra.radians / total)))
        .attr('y2', (d, i) => radarSize / 2 * (1 - Math.cos(i * ra.radians / total)))
        .style('stroke', ra.axes.sColor)
        .attr('stroke-width', ra.axes.sWidth)
        .attr('opacity', ra.axes.opacity)

        // put the title of the axis
        Axes
        .append('text')
        .attr('transform', 'translate(0, -10)')
        .attr('text-anchor', 'middle')
        .attr('dy', '1.5em')
        .attr('x', (d, i) => radarSize / 2 * (1 - ra.series.factor * Math.sin(i * ra.radians / total)) - 60 * Math.sin(i * ra.radians / total))
        .attr('y', (d, i) => radarSize / 2 * (1 - Math.cos(i * ra.radians / total)) - 20 * Math.cos(i * ra.radians / total))
        .attr('font-size', ra.series.tSize)
        .attr('font-weight', ra.series.fWeight)
        .attr('fill', (d, i) => ra.series.tColor[i])
        .text(d => d)
      
      
      let dataValues = JSON.parse(JSON.stringify(radarData))

      //Scale for the radius
      let rScale = d3.scaleLinear()
      .domain([0, ra.level.maxValue])
      .range([0, radius])

      //The radial line function
      let defaultRadial = d3.lineRadial()
      .angle((_, i) => (i * angleSlice) * -1)
      .radius(() => rScale(0))
      
      //The radial line function
      let lineRadial = d3.lineRadial()
      .angle((_, i) => (i * angleSlice) * -1)
      .radius(d => rScale(d.VALUE))

      defaultRadial.curve(d3.curveCardinalClosed.tension(0))
      lineRadial.curve(d3.curveCardinalClosed.tension(0))
      
      RadarChart
      .append('g')
      .style('fill-opacity', ra.area.opacity)
      .attr('transform', `translate(${radarSize/2}, ${radarSize/2})`)

      .append('path')
      .attr('d', defaultRadial(dataValues))
      .attr('stroke-width', ra.area.sWidth)
      .attr('stroke', ra.area.sColor)
      .attr('fill', ra.area.fColor)
      .transition().duration(700)
      .attr('d', lineRadial(dataValues))
      

      // Draw Node on area
      dataValues = []

      RadarChart
      .selectAll('.nodes')
      .data(radarData, (d, i) => {
        dataValues.push([
          radarSize / 2 * (1 - (parseFloat(Math.max(d.VALUE, 0)) / ra.level.maxValue) * Math.sin(i * ra.radians / total)), 
          radarSize / 2 * (1 - (parseFloat(Math.max(d.VALUE, 0)) / ra.level.maxValue) * Math.cos(i * ra.radians / total))
        ])
      })

      RadarChart
      .selectAll('.circle')
      .data(dataValues)
      .enter()
      .append('circle')
      .attr('cx', radarSize/2)
      .attr('cy', radarSize/2)
      .attr('r', ra.node.size)
      .attr('fill', ra.node.fColor)
      .attr('stroke-width', ra.node.sWidth)
      .attr('stroke', ra.node.sColor)
      .attr('opacity', ra.node.opacity)
      .transition().duration(700)
      .attr('cx', d => d[0])
      .attr('cy', d => d[1])

      let RadarValue = RadarChart
      .append('g')
      .attr('opacity', () => { if(ra.value.display == 'Y') return 1; else return 0 })

      RadarValue
      .selectAll('.text')
      .data(dataValues)
      .enter()
      .append('text')        
      .attr('x', radarSize/2)
      .attr('y', radarSize/2)
      .attr('font-size', ra.value.tSize)
      .attr('fill', ra.value.tColor)
      .attr('text-anchor', 'middle')
      .text((d,i) => `${Number(radarData[i].VALUE).toFixed(1)}%`)
      .transition().duration(700)
      .attr('x', d => d[0])
      .attr('y', d => d[1] - 5)
    },

  }
}