// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Titles from '../../../../includes/mixins/Draw_Titles.mixin'
import gmx_Legens from '../../../../includes/mixins/Draw_Legends.mixin'
import gmx_Notes from '../../../../includes/mixins/Draw_Notes.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'
import lmx_Colors from './Chart_Colors.mixin'          // Common functions for Drawing the Charts
import lmx_Chart from './Chart_Elements.mixin'

export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Titles,
    gmx_Legens,
    gmx_Notes,
    gmx_Miscellaneous,
    gmx_Movable,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Colors,
    lmx_Chart,
  ],
}
