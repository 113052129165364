import Defs from '../../../../includes/primitives/Color_Defs'
import Data from '../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'
import { finished } from 'stream'

export default {
  methods: {
    draw_SummaryTableHeader() {
      this.Table.TableHeadFillColor.forEach((color, i) => {
        // console.log(color.substr(0,1));
        if (color.substr(0,1) != '#' && color != '' && color != 'transparent') {
          Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', [color], [`Header${i}`, 0.5, 1.7, 0.5, -0.2], this.localId)
        }
      })

      let headerGroup = this.tableGroup
      .append('g')
      .attr('transform', `translate(0,0)`)
      // Header Background
      this.clone_Header.forEach((data, i) => {

        headerGroup
        .append('rect')
        .attr('class', 'header_rect')
        .attr('x',data.x1)
        .attr('y',data.y1)
        .attr('opacity', this.Table.TableHeadOpacity[data.depth-1])
        .attr('width', data.width)
        .attr('height',data.height)
        .attr('stroke-width',0)
        .attr('fill', () => {
          // console.log(this.Table.TableHeadFillColor[data.depth-1], data.depth-1);
          if (!!this.Table.TableHeadFillColor[data.depth-1] && this.Table.TableHeadFillColor[data.depth-1].substr(0,1) == '#') {
            return this.Table.TableHeadFillColor[data.depth-1]
          } else {
            return `url(#${this.localId}__LinearA4Header${data.depth-1}0)`
          }
        })
      }) 
      
      // // draw the title of Header
      // this.clone_Header.forEach((data, h) => {
      //   headerGroup
      //   .append('text')
      //   .attr('x',data.cx).attr('y',data.cy)
      //   .attr('font-family','roboto').style('font-style', this.Table.TableHeadFontStyle[data.depth-1]).attr('text-anchor','middle').attr('alignment-baseline', 'middle')
      //   .attr('font-size', this.Table.TableHeadFontSize[data.depth-1])
      //   .attr('fill', this.Table.TableHeadFontColor[data.depth-1])
      //   .text(data.name)
      // })
    },   
  }
}
