import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {
    
    draw_Timeline() {    

      let week_Timeline = this.timeline.week.filter(f=> f.eDate >=  new Date(this.timeline.startDate))
      let month_Timeline = this.timeline.month.filter(f=> f.eDate >=  new Date(this.timeline.startDate))

      this.timeline.scale = d3.scaleTime()
      .domain([
        week_Timeline[0].sDate, 
        week_Timeline[week_Timeline.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.length])

      let Timeline = this.SCurve
      .append('g')
      .attr('transform', `translate(0, ${this.Chart.height+.5})`)
      .style('font-family', 'roboto')


      // cutoff Week highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM-DD')

      this.timeline.week.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM-DD') == cutoff) {
          Timeline
          .append('rect')
          .attr('x', this.timeline.scale(d.sDate) + 1)
          .attr('y', .5)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate))
          .attr('height', () => {
            if (this.timeline.month_Visible == 'Y') return 9 
            else return 3
          })
          .style('opacity', 0.3)
          .attr('fill', '#F35E90')            
        }
      })


      // Month 
      if (this.timeline.month_Visible == 'Y') {
        Timeline
        .append('g')
        .selectAll('text')
        .data(month_Timeline) // *** week
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate) + 1).attr('y', 2)
        .style('font-size', this.timeline.mSize)
        .attr('fill', '#757575')
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
        .attr('cursor', 'default')
        .text(d => {
          // return d.eDate.getMonth() + 1
          return d.name.substr(0,1)
        })
      }


      Timeline
      .append('g')
      .selectAll('path')
      .data(month_Timeline) // *** week
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        let height
        (this.timeline.month_Visible == 'Y') ? height = this.timeline.mSize + 1 : height = 3
        if (i != month_Timeline.length-1) return `M${this.timeline.scale(d.eDate)+ 1}, 0 V ${height}` // *** week
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)


      
      // Year 
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', () => {
        if (this.timeline.month_Visible == 'Y') return 13 
        else return 5
      })
      .style('font-size', this.timeline.ySize)
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        let y_
        (this.timeline.month_Visible == 'Y') ? y_ = 13 : y_ = 3
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate) + 1}, ${y_} V ${y_ + this.timeline.ySize}`
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)
    }, 



  }
}
