import * as d3 from 'd3'
import moment from 'moment'
import icon from '../../../../../includes/primitives/icons'
import Defs from '../../../../../includes/primitives/colorDefs'
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',
    
    canvas_Form:      null,
    canvas_Sequence:  null,
    canvas_Dashboard: null,
    canvas_SVG:       null,

    selectedStep:     null,
    timeline:         {},

    progress:         null,
    stepData:         null,
    blocks:           [],
    blockInfo:        [],
    rawData:          [],
    cData:            {},
    currentPro:       null,
    icon:             icon.arrow,

    area: {
      barW:           36,
      barH:           120,
      disc:           100
    },

    codes: [
      {sn: 0, type: 'OV',  code: 'CO', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Construction (Overall)', bar: 500, y: 50  },
      {sn: 0, type: 'OV',  code: 'MO', pColor: '#FCE3EB', aColor: 'Pink',      title: 'Mechanical Completion', bar: 300, y: 310 },
      {sn: 0, type: 'FAB', code: 'CH', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Hull',                  bar: 100, y: 100 },
      {sn: 1, type: 'FAB', code: 'CT', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Topside',               bar: 100, y: 100 },
      {sn: 2, type: 'FAB', code: 'CL', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'LQ',                    bar: 100, y: 100 },
      {sn: 3, type: 'FAB', code: 'CR', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Transportation',        bar: 100, y: 100 },
      {sn: 4, type: 'FAB', code: 'CI', pColor: '#B4E3FA', aColor: 'LightBlue', title: 'Integration',           bar: 100, y: 100 },
      {sn: 0, type: 'MC',  code: 'MH', pColor: '#FCCADB', aColor: 'Pink',      title: 'Hull',                  bar: 100, y: 360 },
      {sn: 1, type: 'MC',  code: 'MT', pColor: '#FCCADB', aColor: 'Pink',      title: 'Topside',               bar: 100, y: 360 },
      {sn: 2, type: 'MC',  code: 'ML', pColor: '#FCCADB', aColor: 'Pink',      title: 'LQ',                    bar: 500, y: 360 },
    ],

    

  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.progress = JSON.parse(JSON.stringify(this.DataItems))

      let cutoff = this.Queries.SQL1[0] // Timeline
      this.stepData = this.localData.JSON1
      this.blocks = this.localData.JSON2 
      this.blockInfo = this.localData.JSON3

      this.stepData.forEach(d => {
        let data = this.progress.find(f=> f.CDATE == d.CDATE)
        this.rawData.push(data)
      })

      this.rawData.forEach((d,i) => {
        d.SN = i
        d.cDate = moment(d.CDATE).toDate()

        this.codes.forEach(c=> {
          d[`${c.code}_EP`] = this.setUndefined(d[`${c.code}_EP`])
          d[`${c.code}_A`] = this.setUndefined(d[`${c.code}_A`])
        })
      })


      // Timeline -------------------------------------------------------------------------------
      this.timeline = {
        baseX           : 200,
        baseY           : 200,
        length          : 1500,
        cutoff          : cutoff.CUTOFF,
        cDate           : moment(cutoff.CUTOFF).toDate(),
        cMonth          : new Date(cutoff.CUTOFF).getMonth()+1,
        startDate       : `${this.rawData[0].CDATE.substr(0,7)}-01`,
        endDate         : `${this.rawData[this.rawData.length-1].CDATE.substr(0,7)}-28`,
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
        height          : 35,
      }
      this.init_gmx_Set_Timeline_Month(this.timeline)

    },


    loadSvg(selection, data) {
      return d3.xml(`${this.HOST}${data.url}`).then(source => {
 
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)


        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },

    
    set_Gradients() {
      let gradients = this.svg.append('defs')

      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      gradients.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      gradients.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)

      Defs.setDefs_Gradients(this.svg, 'TL', 'LinearA4', 'LightGray', [ 0.5, 1.0, 0.5, -0.2], this.localId)

      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA2', 'Pink', [.1, 1, .1, 0], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA2', 'LightBlue', [.1, 1, .1, 0], this.localId)

      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA2', 'LightBlue', [.3, .5, 1, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA2', 'Pink', [.3, .5, 1, .5], this.localId)

      // Window bar
      let windowBar = gradients.append('linearGradient')
      .attr('id', `windowBar`).attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', 1)
      windowBar.append('stop').attr('stop-color', '#fff').attr('offset', '0').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '.4').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '.6').attr('stop-opacity', 1)
      windowBar.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 1)

      // // Contents Window
      let contentWindow = gradients.append('linearGradient')
      .attr('id', `contentWindow`).attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', .7)
      contentWindow.append('stop').attr('stop-color', '#F4F5F5').attr('offset', '0').attr('stop-opacity', 1)
      contentWindow.append('stop').attr('stop-color', '#fff').attr('offset', '.1').attr('stop-opacity', 1)

      let filter = gradients
      .append('filter').attr('id', 'dropshadow')

      filter.append('feGaussianBlur') .attr('in', 'SourceAlpha').attr('stdDeviation', 7).attr('result', 'blur')
      filter.append('feOffset').attr('in', 'blur').attr('dx', 2).attr('dy', 2).attr('result', 'offsetBlur')

      let feMerge = filter.append('feMerge')
      feMerge.append('feMergeNode').attr('in', 'offsetBlur')
      feMerge.append('feMergeNode').attr('in', 'SourceGraphic')

    },

  }
}