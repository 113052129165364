import Vue from 'vue'
import VueI18n from 'vue-i18n'

import cn from './cn.json'
import en from './en.json'

Vue.use(VueI18n)

export function detectLocale () {
  let locale = (navigator.language || navigator.browserLangugae).toLowerCase()
  switch (true) {
    case /^cn.*/i.test(locale):
      locale = 'cn'
      break
    default:
      locale = 'en'
  }

  return locale
}

const i18n = new VueI18n({
  locale: detectLocale(),
  fallbackLocale: 'en',
  messages: {
    'cn': cn,
    'en': en,
  }
})

export default i18n
