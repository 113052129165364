import * as d3 from 'd3'
import { SafeIdMixin } from '../../mixins/safeid.mixin'

export default {
  name: 'j-chart-auto-drawn',
  mixins: [SafeIdMixin],
  props: {
    id: String,
    jsonProps: String
  },
  computed: {
    jsProps() {
      if(typeof this.jsonProps == 'undefined' || !this.jsonProps) return {}
      return JSON.parse(this.jsonProps)
    }
  },
  created() {
    this.localId = this.id || 'j-chart-auto-drawn__' + this.safeId('')
  },
  methods: {
    draw() {
      if(!this.jsProps.svg) return

      let parent = d3.select(`#${this.localId}`)
      if(!parent.empty()) parent.html('')
      this.drawEl(parent, this.jsProps)
      this.$emit('complete', this.localId)
    },
    drawEl(parent, els) {
      Object.keys(els).forEach(k => {
        if(k.indexOf('_') === 0) {
          if(typeof els[k] == 'string') {
            parent.text(els[k])
          } else {
            this.setAttrs(parent, els[k])
          }
        } else {
          if(Array.isArray(els[k])) {
            let sibling = [...els[k]]
            sibling.forEach(els => {
              this.drawEl(parent.append(k), els)
            })
          } else {
            this.drawEl(parent.append(k), els[k])
          }
        }
      })
    },
    setAttrs(parent, els) {
      Object.keys(els).forEach(k => {
        parent.attr(k, els[k])
      })
    },
  },
  render () {
    let _attrs = {
      attrs: {
        id: this.localId
      },
      class: 'svg'
    }
    
    return (
      <div { ..._attrs }>
        { this.draw() }
      </div>
    )
  }
}