import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  data: () => ({
  }),

  methods: {

    Header_01(selection, _data, _style) {
      let Header_01 = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_style.x}, ${_style.y})`)

      Header_01
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', _style.radius).attr('ry', _style.radius)
      .style('width', _style.width).style('height', _style.height).style('fill', _style.bColor)

      Header_01
      .append('text')
      .attr('transform', `translate(10, ${_style.height/2 + 1})`)
      .style('font-family', 'roboto').style('font-size', _style.tSize).style('fill', _style.tColor).style('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text(_style.title)

      if (!_data) return

      Header_01
      .append('text') // Delta Value
      .attr('transform', `translate(${_style.width + 5}, ${_style.height/2 + 1})`)
      .style('fill', () => {
        if (_data < 0) return '#EC407A'; else return '#44A9DF'
      })
      .style('font-family', 'roboto').style('font-size', _style.progSize).style('text-anchor', 'start')
      .text(`${_data}%`).attr('alignment-baseline', 'middle')
    },

    
    Header_Progress_PAD(selection, _phase, _x, _y, _width, _height, _radius, _data, _tColor, _bColor) {

      let ProgressSummary = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_x}, ${_y})`)

      ProgressSummary
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('rx', 3).attr('ry', 3)
      .style('width', _width).style('height', _height).style('fill', _bColor)

      ProgressSummary
      .append('text')
      .attr('transform', `translate(10, 14)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', _tColor).style('text-anchor', 'start')
      .text(_phase)

      ProgressSummary
      .append('text') // Delta Value
      .attr('transform', `translate(${_width + 5}, 15)`)
      .style('fill', () => {
        if (_data[2] < 0) return '#EC407A'; else return '#44A9DF'
      })
      .style('font-family', 'roboto').style('font-size', 16).style('text-anchor', 'start').text(`${_data[2]}%`)

      ProgressSummary
      .append('text')
      .attr('transform', `translate(0, 35)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'start').text('Plan Cuml.')

      ProgressSummary
      .append('text') // Plan Value
      .attr('transform', `translate(50, 35)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#333').style('text-anchor', 'start').text(`${_data[0]}%`)

      ProgressSummary
      .append('text')
      .attr('transform', `translate(100, 35)`)
      .style('font-family', 'roboto').style('font-size', 9).style('fill', '#bcbcbc').style('text-anchor', 'start').text('Actual Cuml.')

      ProgressSummary
      .append('text') // Actual Value
      .attr('transform', `translate(157, 35)`)
      .style('font-family', 'roboto').style('font-size', 11).style('fill', '#44A9DF').style('text-anchor', 'start').text(`${_data[1]}%`)
    },


    Header_TitleBox(selection, _name, _x, _y, _width, _height, _radius, _tColor, bColor) {

      let titleBox = selection
      .append('g')
      .attr('id', '')
      .attr('transform', `translate(${_x}, ${_y})`)

      titleBox
      .append('rect')
      .attr('transform', `translate(0, 0)`).attr('rx', _radius).attr('ry', _radius)
      .style('width', _width).style('height', _height).style('fill', bColor)

      titleBox
      .append('text')
      .attr('transform', `translate(10, 14)`)
      .style('font-family', 'roboto').style('font-size', 12).style('fill', _tColor).style('text-anchor', 'start')
      .text(_name)
    },







  }
}
           