import Api from './api'
import Url from './request.url'

export default class TortueApi extends Api {

  constructor() {
    super()
  }

  getCMSLevelOptions(no) {
    return this.axios().get(Url.tortue.cms.leveloptions).then(res => {
      return new Promise((resolve) => {
        resolve(res.data)
      })
    })
  }
}