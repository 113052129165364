<template>
  <div :id='localId' class="j_mcr_comment_create">
    <div class="common_container">
      <div class="header_wrapper">
        <span class="comment_title">Model Review Comment - Create</span>
        <span class="comment_sub_title"></span>
      </div>
      <div class="comment_content">
        <div class="row">
          <div class="field">
            <label>*Project No.</label>
            <input v-model="mrHeader.projectNo" class="kim_input" type="text" placeholder="Input Project No." readonly>
          </div>
          <div class="spacer"></div>
          <div class="field">
            <label class="label_mr_stage">*Model Review Stage</label>
            <kim-select
              v-model="mrHeader.stage"
              placeholder="Select Stage"
              itemText="NAME"
              itemValue="CODE"
              :items="itemStage"
            ></kim-select>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <label class="label_mr_package">*Package No.</label>
            <kim-select
              v-model="mrHeader.packageNo"
              placeholder="Select Package"
              itemText="CODE"
              itemValue="CODE"
              :items="itemPackage"
              @change="onPackageChanged"
            ></kim-select>
          </div>
          <div class="spacer"></div>
          <div class="field">
            <label class="label_mr_plant">*Plant (SEI Unit - Level 2)</label>
            <kim-select
              v-model="mrHeader.plant"
              placeholder="Select Unit"
              itemText="DESCRIPTION"
              itemValue="CODE"
              :items="itemPlant"
              @change="onPlantChanged"
            ></kim-select>
          </div>
        </div>
      </div>
      <div class="header_wrapper">
        <div class="header_controller">
          <div class="title_wrapper">
            <span class="comment_title small">Comment(s)</span>
            <span class="comment_sub_title"></span>
          </div>
          <div class="button_wrapper">
            <button type="button" class="j_button type01 sky" @click="add">+ Add A Comment</button>
            <button type="button" class="j_button type01 sky" @click="importMLs('text/xml')">+ Import XML</button>
            <button type="button" class="j_button type01 sky" @click="importMLs('text/html')">+ Import HTML</button>
            <input 
              type="file"
              id="odfxml" 
              name="odfxml"
              style="display:none;"
              @change="parseMLs"
            >
          </div>
        </div>
      </div>
    </div>
    <div ref="vpContainer" class="viewpoint_container">
      <!-- <j-mcr-comment-viewpoint
        v-for="(comment, i) in mrComments"
        :key="safeId(i)"
        :number="i"
        :removable="removable"
        :value="comment"
        @input="v => { update(i, v) }"
        @remove="remove"
      /> -->
    </div>
    <div class="bottom_wrapper">
      <button type="button" class="j_button type01 sky" :class="{ disabled: !updatable }" :disabled="!updatable" @click="create">Create</button>
      <button type="button" class="j_button type01 pink" @click="onCancel">Cancel</button>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>
<script>
import Vue from 'vue'

import __C from '@/primitives/_constant_'
import Data from '@/primitives/_china1Default'

import { China1Service, DBSupportService, SvgService } from '@/services'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import JMcrCommentViewpoint from './JMcrCommentViewpoint'

export default {
  name: 'j-modal-slide-component---mcr-comment-create',
  mixins: [
    SafeIdMixin
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    value: null,
  },
  data: () => ({
    xmlService: null,
    china1Service: null,
    mrHeader: {
      projectNo: '75231',
      stage: '',
      packageNo: '',
      plant: '',
    },
    mrComments: [],
    items: {
      stage: [],
      package: [],
      plant: [],
      area: [],
      pids: [],
      tags: [],
      discipline: [],
      mrcStatus: [],
      mrcCategory: [],
    },
    vpInstances: [],
    vpFiles: [],
    accept: '',
    prevComments: '',
    close: false,

    msgOpen: false,
    msgInfo: {
      type: '',
      title: '',
      titleDescription: '',
      message: '',
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},
    no: () => {},
    cancel: () => {}
  }),
  computed: {
    __C_() { return __C },

    updatable() { return this.prevComments != JSON.stringify({ mrHeader: this.mrHeader, mrComments: this.mrComments }) },
    removable() { return this.mrComments.length > 1 },

    itemStage() { return this.items.stage },
    itemPackage() { return this.items.package },
    itemPlant() { return this.items.plant.filter(item => item.PACKAGE == this.mrHeader.packageNo) },
  },
  watch: {
    mrHeader: {
      handler(val) {
        // console.log('mrHeader', val)
      },
      deep: true
    },    
  },
  created() {
    this.localId = this.id || 'j-comm-comment__' + this.safeId('')
    
    this.china1Service = new China1Service()
    this.queryLibService = new DBSupportService()
    this.xmlService = new SvgService()
  },
  mounted() {
    this.add()
    this.prevComments = JSON.stringify({
      mrHeader: this.mrHeader,
      mrComments: this.mrComments
    })
  },
  beforeDestroy () {
  },
  methods: {
    onFile(fs, i) {
      this.vpFiles[i] = fs
    },
    onComplete() {
      this.queryLibService.getSqlQueryResult({ 
        idx: 37, 
        name: 'All Common Code For MR Comment Modification',
        filters: ''
      }).then(res => {
        let data = res[0]
        this.items = {
          stage: JSON.parse(data.stage),
          package: JSON.parse(data.package),
          plant: JSON.parse(data.plant),
          area: JSON.parse(data.area),
          pids: JSON.parse(data.pids),
          tags: JSON.parse(data.tags),
          discipline: JSON.parse(data.discipline),
          mrcStatus: JSON.parse(data.mrcStatus),
          mrcCategory: JSON.parse(data.mrcCategory),
        }

        this.vpInstances.forEach(ins => { 
          ins.items = this.items
        })

        this.$emit('complete', {
          name: 'Create Model Review Comment',
          background: '#f4f5f5 !important',
          dimention: { height: 0, width: 750 },
          preclose: this.onCancel
        })
      })
    },
    onPackageChanged(v) {
      this.vpInstances.forEach(ins => { 
        ins.package = v
        ins.plant = ''
      })
    },
    onPlantChanged(v) {
      this.vpInstances.forEach(ins => { 
        ins.plant = v
      })
    },
    onCancel() {
      if(this.close) return true

      let comments = JSON.stringify({
        mrHeader: this.mrHeader,
        mrComments: this.mrComments
      })

      if(!this.updatable) {
        this.close = true
        this.$emit('close')
      } else {
        this.yes = () => {
          this.msgOpen = false
          this.close = true
          this.$emit('close')
          this.yes = () => { }
        }
        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Action Approval"
        this.msgInfo.titleDescription = "Data Changed!"
        this.msgInfo.message = "Do you want to cancel current MRC creation?"
        this.msgInfo.button = [true, false, true]
        this.msgInfo.buttonText[0] = 'Yes'
        this.msgOpen = true
      }

      return false
    },
    create() {
      if(!this.validate()) return

      this.yes = () => {
        this.mrComments.forEach(c => {
          c.projectNo = this.mrHeader.projectNo
          c.stage = this.mrHeader.stage
          c.packageNo = this.mrHeader.packageNo
          c.plant = this.mrHeader.plant
        })

        let params = new FormData()
        params.append('data', JSON.stringify(this.mrComments))

        this.vpFiles.forEach((files, i) => {
          files.forEach(f => {
            params.append(`files[${i}]`, f)
          })
        })

        this.china1Service.putMrComment(params).then(() => {
          let len_ = this.mrComments.length

          this.yes = () => {
            this.msgOpen = false
            this.close = true
            this.$emit('close')
            this.$emit('request-reload')
            this.yes = () => {}
          }
          this.msgInfo.type = "INFO"
          this.msgInfo.title = "COMPLETE"
          this.msgInfo.titleDescription = "MR-Comment created."
          this.msgInfo.message = `${len_} MR-Comment${len_>1?'s were':' was'} created successfully.`
          this.msgInfo.button = [true, false, false]
          this.msgInfo.buttonText[0] = 'OK'
          this.msgOpen = true
        })
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Model Review Comment Creation"
      this.msgInfo.message = "Do you want to create current MRC(s)?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = 'Yes'
      this.msgOpen = true
    },
    parseMLs(e) {
      let reader = new FileReader()
      let mrComments = []

      reader.onload = () => {
        if(this.accept == 'text/html') {
          let keys = {
            0: 'status',
            1: 'createdBy',
            2: 'text'
          }
          let parser = new DOMParser()
          let doc__ = parser.parseFromString(reader.result, this.accept)
          let vps = doc__.querySelectorAll('.viewpoint')

          vps.forEach(vp => {
            // Parse viewpoint info
            let viewpoint = JSON.parse(JSON.stringify(Data.mrViewpoint))
            let el = vp.querySelector('h2') // First h2 element

            if(el) viewpoint.viewpointNo = el.textContent

            el = vp.querySelector('.namevaluepair .value') // Camera Position
            let text = el.textContent.replace(/\r?\n|\r|\t|\s|m/g, '').split(',')
            if(text.length > 0) viewpoint.coordinate = `x:${text[0]} y:${text[1]} z:${text[2]}`
            
            // Parse comment info
            let comments = vp.querySelectorAll('.comment') // First h2 element
            comments.forEach(c => {
              let mrComment = JSON.parse(JSON.stringify(Data.mrComment))

              c.querySelectorAll('.value').forEach((v, i) => {
                mrComment[keys[i]] = i === 0 ? Data.codeMatchStatus[v.textContent.toLowerCase()] : v.textContent
              })

              let created = c.textContent.match(/\d{4}\/\d+\/\d+/g)
              created = created.length > 0 ? created[0] : ''
              created = created.split('/')
              created = created.length > 0 ? `${created[0]}-${this.pad(created[1], 2)}-${this.pad(created[2], 2)}` : ''

              mrComment.createdDate = created
              mrComment.viewpoints.push(viewpoint)

              mrComments.push(mrComment)
            })
          })

        } else {
          let xmljs = this.xmlService.xml2js(reader.result)

          xmljs.exchange.viewpoints.view.forEach(v => {
            let viewpoint = JSON.parse(JSON.stringify(Data.mrViewpoint))
            let coordinate = v.viewpoint.camera.position.pos3f._attributes
            let rotation = v.viewpoint.camera.rotation.quaternion._attributes

            viewpoint.viewpointNo = v._attributes.name
            viewpoint.coordinate = `x:${coordinate.x} y:${coordinate.y} z:${coordinate.z}`
            viewpoint.rotation = `a:${rotation.a} b:${rotation.b} c:${rotation.c} d:${rotation.d}`

            let comments = v.comments.comment
            if(!Array.isArray(comments)) comments = [v.comments.comment]

            comments.forEach(c => {
              let mrComment = JSON.parse(JSON.stringify(Data.mrComment))
              let createdDate = c.createddate.date._attributes
              let status = Data.codeMatchStatus[c._attributes.status.toLowerCase()]

              mrComment.id = c._attributes.id
              mrComment.status = status ? status : c._attributes.status
              mrComment.text = c.body._text
              mrComment.createdDate = `${createdDate.year}-${String(createdDate.month).padStart(2, '0')}-${String(createdDate.day).padStart(2, '0')}`
              mrComment.createdBy = c.user._text
              mrComment.viewpoints.push(JSON.parse(JSON.stringify(viewpoint)))

              mrComments.push(mrComment)
            })
          })
        }
        
        this.mrComments = mrComments
        this.setMLData()
      }
      reader.readAsText(e.target.files[0])
    },
    importMLs(accept) {
      let input = document.querySelector(`#${this.localId} input[name='odfxml']`)
      input.accept = accept
      input.value = null
      input.click()

      this.accept = accept
    },
    setMLData() {
      this.$refs.vpContainer.innerHTML = ''
      this.vpInstances = []
      this.vpFiles = []

      let length = this.mrComments.length
      this.mrComments.forEach((c, i) => {
        let Component = Vue.extend(JMcrCommentViewpoint)
        let instance = new Component({
            propsData: { 
              number: i,
              removable: !!i,
              package: this.mrHeader.packageNo,
              plant: this.mrHeader.plant,
              items: this.items,
              value: c
            }
        })
        instance.$on('input', this.update)
        instance.$on('remove', this.remove)
        instance.$on('file', this.onFile)
        instance.$mount()

        this.$refs.vpContainer.appendChild(instance.$el)
        this.vpInstances.push(instance)
        this.vpFiles.push([])
      })
      this.vpInstances[0].removable = !!(length-1)
    },
    add() {
      let i = this.mrComments.length-1
      this.mrComments.push(JSON.parse(JSON.stringify(Data.mrComment)))
      i += 1

      let Component = Vue.extend(JMcrCommentViewpoint)
      let instance = new Component({
          propsData: { 
            number: i,
            removable: !!i,
            package: this.mrHeader.packageNo,
            plant: this.mrHeader.plant,
            items: this.items,
            value: this.mrComments[i]
          }
      })
      instance.$on('input', this.update)
      instance.$on('remove', this.remove)
      instance.$on('file', this.onFile)
      instance.$mount()

      console.log(instance)

      this.$refs.vpContainer.appendChild(instance.$el)
      this.vpInstances.push(instance)
      this.vpFiles.push([])

      this.vpInstances[0].removable = !!i
    },
    update(v, i) {
      let mrComment_ = JSON.parse(JSON.stringify(this.mrComments))
      mrComment_[i] = v
      this.mrComments = mrComment_
    },
    remove(i) {
      let mrComment_ = JSON.parse(JSON.stringify(this.mrComments))
      mrComment_[i] = null
      mrComment_ = mrComment_.filter(attr => !!attr)

      this.mrComments = mrComment_

      this.vpInstances[i].$el.parentNode.removeChild(this.vpInstances[i].$el) 
      this.vpInstances.splice(i, 1)
      this.vpFiles.splice(i, 1)

      let length = this.mrComments.length
      this.vpInstances.forEach((ins, i) => { 
        ins.number = i 
        ins.removable = length > 1 
      })
    },
    pad(num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    },
    validate() {
      let validated = true

      if(!this.mrHeader.packageNo) {
        document.querySelector('.label_mr_stage').classList.add('required')
        validated = false
      } else document.querySelector('.label_mr_stage').classList.remove('required')

      if(!this.mrHeader.stage) {
        document.querySelector('.label_mr_package').classList.add('required')
        validated = false
      } else document.querySelector('.label_mr_package').classList.remove('required')

      if(!this.mrHeader.stage) {
        document.querySelector('.label_mr_plant').classList.add('required')
        validated = false
      } else document.querySelector('.label_mr_plant').classList.remove('required')

      this.vpInstances.forEach(ins => { validated = !ins.validate() ? false : validated })

      if(!validated) {
        this.yes = () => {
          this.msgOpen = false
          this.yes = () => { }
        }
        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Failed to validate"
        this.msgInfo.titleDescription = "Required field cannot be empty."
        this.msgInfo.message = "Please check the required field is not empty."
        this.msgInfo.button = [true, false, false]
        this.msgInfo.buttonText[0] = 'OK'
        this.msgOpen = true
      }

      return validated
    },
    run() {
      this.onComplete()
    },
  }
}
</script>

<style lang="stylus" scoped>
.j_mcr_comment_create {
  position: relative;
  height: 100%;
  transition: width .45s;

  .bottom_wrapper {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: center;

    height: 5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.075);
    background-color: #eee;
    padding: 1rem;

    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.03);
  }

  .common_container {
    position: absolute;

    width: 100%;
    background-color: #f4f5f5;
  }

  .viewpoint_container {
    position: absolute;
    top: 20.2rem;
    right: 0;
    left: 0;
    bottom: 5rem;
    overflow: auto;

    border-width: 0 .5rem;
    border-style: solid;
    border-color: #eee;
  }

  .header_wrapper {
    display: flex;
    height: 4rem;
    background-color: #eee;
    padding: 1rem;

    .header_controller {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;
    }

    .comment_title {
      font-size: 1.2rem;
      font-weight: 900;
      margin-left: 1rem;

      &.small {
        font-size: 1rem;
        font-weight: 500;
      }
    }

    .comment_sub_title {
      font-weight: 500;
      color: #aeaeae;
      margin-left: 1rem;
    }
  }

  .comment_content {
    border-width: 0 .5rem;
    border-style: solid;
    border-color: #eee;
    padding: 3rem;
  }

  .button_wrapper {
    display: flex;
  }

  .submit_button_wrapper {
    display: flex;
  }

  .row {
    display: flex;
    margin-bottom: 1px;

    .field {
      display: flex;
      align-items: center;

      &.full_input {
        width: 100%;

        input {
          width: 100%;
        }
      }

      &.align_top {
        align-items: start;

        label {
          margin-top: .3rem;
        }
      }

      label {
        min-width: 12rem;

        &.required {
          color: #f54242;
          font-style: italic;
          font-weight: 500;
        }
      }

      .kim_input {
        width: 20rem;
        height: 3rem;
        border: 1px solid #bbb;
        padding: .5rem;
        padding-right: 2.4rem;

        background-color: #fff;
        border-radius: 3px;
      }
      .kim_input:hover {
        border-color: #777;
      }
      .kim_input::placeholder {
        color: #ccc;
        font-style: italic;
      }

      .j_fileuploader {
        width: 20rem;
      }
    } 

    .vfield {
      input {
        width: 32rem;
      }
    }
  }
}
</style>
