export default {

  methods: {

    chart_Controller() {
      

      let progress_Group = this.svg
      .append('g')
      .attr('id', `Progress_Group_${this.localId}`)
      .attr('transform', `translate(${this.commons.x + .5}, ${this.commons.y + .5})`)
      .style('font-family', this.commons.font)
      .attr('cursor', 'default')


      switch(this.ChartType) {
        case 'DISC':
          this.chart_Disciplune(progress_Group)
          break

        case 'PUNCH':
          this.chart_Punch(progress_Group)
          break
      }

    }
  }
}