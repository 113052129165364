import * as d3 from 'd3'

export default {
  data: () => ({
    scaleAxisYInfo: {
      tickValues: [],
      scale: null,
    },
    
    axisyArea: null,
    axisyAreaX: null,
    axisyAreaY: null,
    axisyWidth: null,
    axisyHeight: null,
  }),
  methods: {
    setAxisYValues(aValues) {
      this.axisyAreaX = aValues.x
      this.axisyAreaY = aValues.y
      this.axisyWidth = aValues.w
      this.axisyHeight = aValues.h
    },
    setScaleAxisYInfo(scaleHeight, maxValue) {
      let maxBoundValue = this.AxisY.AxisYBoundsAuto == 'Y' ? this.getMaxBound(maxValue) : maxValue

      // Portion of the increment for each
      let portionY = Math.round(maxBoundValue / 5)

      // Calculate the data values for each of the Grid-Lines between 
      // 0 to maxBoundValue.
      this.scaleAxisYInfo.tickValues = Array.from({ length: 6 }, (_, i) => (i * portionY))

      // This Y Scale, calculated by the data values, will be used
      // wherever the Y Coordinates are needed.
      this.scaleAxisYInfo.scale = d3
      .scaleLinear()
      .domain([0, maxBoundValue])
      .rangeRound([scaleHeight, 0])
    }
  }
}
