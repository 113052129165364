import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
export default {
  methods: {
    draw_SkylineBoxes() {
      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
        this.parentNode.appendChild(this);
        });
      };

      let self_ = this
      let Wrapper = this.viewBox
      .append('g')
      .attr('class', '_boxes_wrapper__')
      .attr('opacity', 1)

      this.Comp_DataItems.forEach((item_, i) => {
        item_.values.forEach((value_, j) => {
          let getProg = value_.prog2 > 0 ? value_.prog2 : value_.prog1
          let itemWrapper = Wrapper
          .datum({
            x: ((this.Boxes.BoxesBoxWidth + this.Boxes.BoxesBoxSpace) * i) + (this.Boxes.BoxesBoxSpace / 2),
            y: (this.Canvas.CanvasChartHeight - this.Boxes.BoxesBoxHeight - this.Boxes.BoxesBoxSpace) - (this.Boxes.BoxesBoxHeight + this.Boxes.BoxesBoxSpace) * j
          })
          .append('g')
          .attr('id', `n${value_.name}___${this.localId}`)
          .attr('transform', d => `translate(${d.x},${d.y})`)
          .attr('opacity', 0)
          .style('cursor', 'pointer')
          .on('mouseover', function(d) {
            d3.select(this)
            .moveToFront()
            .transition()
            .duration(100)
            .attr('transform', `translate(${d.x - self_.Boxes.BoxesBoxWidth/3},${d.y - self_.Boxes.BoxesBoxHeight/3}) scale(1.5)`)
          })
          .on('mouseout', function(d){
            d3.select(this).transition()
            .duration(100)
            .attr('transform', `translate(${d.x},${d.y}) scale(1)`)
          })
          
          itemWrapper
          .append('rect') // Boxes Rect
          .attr('rx', this.Boxes.BoxesBoxRadius)
          .attr('ry', this.Boxes.BoxesBoxRadius)
          .attr('width', this.Boxes.BoxesBoxWidth)
          .attr('height', this.Boxes.BoxesBoxHeight)
          .attr('stroke', value_.stroke)
          .attr('fill', value_.fill)
          .attr('opacity', 1)
          .attr('stroke-width', this.Boxes.BoxesBoxStroke)
          
          itemWrapper
          .append('rect')// Progress Bar
          .attr('class', 'progress_bar')
          .attr('rx', this.Boxes.BoxesBoxRadius)
          .attr('ry', this.Boxes.BoxesBoxRadius)
          .attr('width', this.Boxes.BoxesBoxWidth * ((getProg ? getProg : 0)/100)) 
          .attr('height', this.Boxes.BoxesBoxHeight)
          .attr('stroke', 'transparent')
          .attr('stroke-width', 1)
          .attr('fill', value_.barColor)
    
          itemWrapper
          .append('text')
          .attr('class', this.Boxes.BoxesTextStyle)
          .attr('transform', `translate(${this.Boxes.BoxesBoxWidth/2},${this.Boxes.BoxesBoxHeight/2 + 1})`)
          .style('font-family', this.Boxes.BoxesTextFont)
          .style('font-size', this.Boxes.BoxesTextSize) 
          .attr('fill', this.Boxes.BoxesTextColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(value_.name) // --->
        })
      }) 

      let delayPosition = 0
      this.Comp_DataItems.forEach((item_, i) => {

        let valuesLen = item_.values.length
        let getY = valuesLen * (this.Boxes.BoxesBoxSpace + this.Boxes.BoxesBoxHeight)
    
        item_.values.forEach((value_, j) => {
          //let value_ = item_.values[valuesLen - i - 1]

          this.svg.select(`#n${value_.name}___${this.localId}`)
          // .transition()
          // .duration(300)
          // .delay(() => {
          //   delayPosition += 75
          //   return delayPosition
          // })
          .attr('opacity', 1)
          // .attr('transform', `translate(${value_.x},${value_.y})`)

          .attr('transform', `translate(
            ${((this.Boxes.BoxesBoxWidth + this.Boxes.BoxesBoxSpace) * i) + (this.Boxes.BoxesBoxSpace / 2)},
            ${(this.Canvas.CanvasChartHeight - this.Boxes.BoxesBoxHeight - this.Boxes.BoxesBoxSpace) - (this.Boxes.BoxesBoxHeight + this.Boxes.BoxesBoxSpace) * j}
          )`)

          // .transition().duration(200).attr('transform', `translate(${value_.x},${value_.y})`)
          // .transition().duration(170).attr('transform', `translate(${value_.x},${this.drawPoint_Y - getY})`)
          // .attr('opacity', 1)
          // .transition().duration(140).attr('transform', `translate(${value_.x},${value_.y})`)
          // .transition().duration(110).attr('transform', `translate(${value_.x},${value_.y-2})`)
          // .attr('opacity', 1)
          // .transition().duration(90).attr('transform', `translate(${value_.x},${value_.y})`)
          // .transition().duration(70).attr('transform', `translate(${value_.x},${value_.y-1})`)
          // .attr('opacity', 1)
          // .transition().duration(60).attr('transform', `translate(${value_.x},${value_.y})`)
          // .transition().duration(50).attr('transform', `translate(${value_.x},${value_.y-.5})`)
          // .attr('opacity', 1)
          // .transition().duration(50).attr('transform', `translate(${value_.x},${value_.y})`)
        })

        delayPosition -= (valuesLen * 70) * .8
      })
    },



  }
}