import Api from './api'
import Url from './request.url'

export default class SystemApi extends Api {

  constructor() {
    super()
  }

  getDiscSummary(itemCode, callback) {
    this.axios().get(`${Url.system.config.discSummary}/${itemCode}`).then(res => callback(res.data))
  }
  get3DItems(itemCode,itemSize, callback) {
    this.axios().get(`${Url.system.config.item3D}/${itemCode}/${itemSize}`).then(res => callback(res.data))
  }

  get3DStage(itemCode, callback) {
    this.axios().get(`${Url.system.config.stage3D}/${itemCode}`).then(res => callback(res.data))
  }

  getCommodity(itemCode, callback) {
    this.axios().get(`${Url.system.config.commodity}/${itemCode}`).then(res => callback(res.data))
  }

  queryResult2JSON(params, callback) {
    this.axios().post(Url.system.config.query2json, params).then(res => callback(res))
  }

  getCodeGroup(callback) {
    // this.axios().get(Url.system.config.codegroup, requestHeader).then(res => callback(res.data))
    this.axios().get(Url.system.config.codegroup).then(res => callback(res.data))
  }
  // Request to insert a new code group
  putCodeGroup(codeGroup, callback) {
    this.axios().put(Url.system.config.codegroup, codeGroup).then(res => callback(res))
  }
  updCodeGroup(codeGroup, callback) {
    this.axios().post(Url.system.config.codegroup, codeGroup).then(res => callback(res))
  }
  delCodeGroup(code, callback) {
    // this.axios().delete(Url.system.config.codegroup, { params: { code: code }}).then(res => callback(res))
    this.axios().delete(`${Url.system.config.codegroup}/${code}`).then(res => callback(res))
  }
  getCodeIndex(groupCode, callback) {
    this.axios().get(`${Url.system.config.codeindex}/${groupCode}`).then(res => callback(res.data))
  }
  putCodeIndex(codeIndex, callback) {
    this.axios().put(Url.system.config.codeindex, codeIndex).then(res => callback(res))
  }
  updCodeIndex(codeIndex, callback) {
    this.axios().post(Url.system.config.codeindex, codeIndex).then(res => callback(res))
  }
  delCodeIndex(codeIndex, callback) {
    this.axios().delete(`${Url.system.config.codeindex}/${codeIndex.groupCode}/${codeIndex.code}`).then(res => callback(res))
  }
  getNavRelated(relatedType, code,callback) {
    this.axios().get(`${Url.system.config.navRelated}/${relatedType}/${code}`).then(res => callback(res.data))
  }
  getNavPhase(callback) {
    this.axios().get(Url.system.config.navphase).then(res => callback(res.data))
  }
  putNavPhase(navPhase, callback) {
    this.axios().put(Url.system.config.navphase, navPhase).then(res => callback(res))
  }
  updNavPhase(navPhase, callback) {
    this.axios().post(Url.system.config.navphase, navPhase).then(res => callback(res))
  }
  delNavPhase(phaseCode, callback) {
    this.axios().delete(`${Url.system.config.navphase}/${phaseCode}`).then(res => callback(res))
  }
  getNavGroup(phaseCode, callback) {
    this.axios().get(`${Url.system.config.navgroup}/${phaseCode}`).then(res => callback(res.data))
  }
  putNavGroup(navGroup, callback) {
    this.axios().put(Url.system.config.navgroup, navGroup).then(res => callback(res))
  }
  updNavGroup(navGroup, callback) {
    this.axios().post(Url.system.config.navgroup, navGroup).then(res => callback(res))
  }
  delNavGroup(phaseCode, groupCode, callback) {
    this.axios().delete(`${Url.system.config.navgroup}/${phaseCode}/${groupCode}`).then(res => callback(res))
  }
  getNavItem(callback, phaseCode, groupCode) {
    this.axios().get(`${Url.system.config.navitem}/${phaseCode}/${groupCode}`).then(
      (res) => { callback(res.data) }
    )
  }

  orignUpdate(updateItem, callback) {
    this.axios().post(Url.system.config.origin, updateItem).then(res => callback(res))
  }
  devUpdate(updateItem, callback) {
    this.axios().post(Url.system.config.devUp, updateItem).then(res => callback(res))
  }
  putNavItem(navItem, callback) {
    this.axios().put(Url.system.config.navitem, navItem).then(res => callback(res))
  }
  updNavItem(navItem, callback) {
    this.axios().post(Url.system.config.navitem, navItem).then(res => callback(res))
  }
  transferPhase(navPhase, callback) {
    this.axios().post(Url.system.config.phaseTrans, navPhase).then(res => callback(res))
  }
  transferGroup(navGroup, callback) {
    this.axios().post(Url.system.config.groupTrans, navGroup).then(res => callback(res))
  }
  transferItem(navItem, callback) {
    this.axios().post(Url.system.config.dbTransfer, navItem).then(res => callback(res))
  }
  transferSubItem(navSubItem, callback) {
    this.axios().post(Url.system.config.subitemTrans, navSubItem).then(res => callback(res))
  }
  delNavItem(itemCode, callback) {
    this.axios().delete(`${Url.system.config.navitem}/${itemCode}`).then(res => callback(res))
  }
  getNavMenuForSubItem(callback) {
    this.axios().get(Url.system.config.subitemmenu).then(res => callback(res.data))
  }
  getNavCatMenuForChart(callback) {
    this.axios().get(Url.system.config.catmenu.chart).then(res => callback(res.data))
  }
  getNavSubItem(callback, phaseCode, itemCode) {
    this.axios().get(`${Url.system.config.navsubitem}/${phaseCode}/${itemCode}`).then(res => callback(res.data))
  }
  putNavSubItem(navSubItem, callback) {
    this.axios().put(Url.system.config.navsubitem, navSubItem).then(res => callback(res))
  }
  updNavSubItem(navSubItem, callback) {
    this.axios().post(Url.system.config.navsubitem, navSubItem).then(res => callback(res))
  }
  delNavSubItem(idx, callback) {
    this.axios().delete(`${Url.system.config.navsubitem}/${idx}`).then(res => callback(res))
  }
  getNavCatMenuNumChild(type, callback) {
    this.axios().get(`${Url.system.config.catmenu.numChild}/${type}`).then(res => callback(res.data))
  }
  getWButton(callback, phaseCode, groupCode, itemCode, subItem) {
    this.axios().get(`${Url.system.config.wbutton}/${phaseCode}/${groupCode}/${itemCode}/${subItem}`).then(res => callback(res.data))
  }
  putWButton(wButton, callback) {
    this.axios().put(Url.system.config.wbutton, wButton).then(res => callback(res))
  }
  updWButton(wButton, callback) {
    this.axios().post(Url.system.config.wbutton, wButton).then(res => callback(res))
  }
  delWButton(idx, callback) {
    this.axios().delete(`${Url.system.config.wbutton}/${idx}`).then(res => callback(res))
  }
  getIconRelated(catCode, iconCode, callback){
    this.axios().get(`${Url.system.config.iconRelated}/${catCode}/${iconCode}`).then(res => callback(res.data))
  }
  getIcons(catCode, callback) {
    this.axios().get(`${Url.system.config.icons}/${catCode}`).then(res => callback(res.data))
  }
  getIcon(iconCode, callback) {
    this.axios().get(`${Url.system.config.icon}/${iconCode}`).then(res => callback(res.data))
  }
  putIcons(icon, callback) {
    this.axios().post(Url.system.config.iconsUpload, icon).then(res => callback(res))
  }
  updIcons(icon, callback) {
    this.axios().post(Url.system.config.icons, icon).then(res => callback(res))
  }
  delIcons(iconCode, callback) {
    this.axios().delete(`${Url.system.config.icons}/${iconCode}`).then(res => callback(res))
  }
  getCharts(catCode, callback) {
    this.axios().get(`${Url.system.config.charts}/${catCode}`).then(res => callback(res.data))
  }
  getChart(chartType, callback) {
    this.axios().get(`${Url.system.config.chart}/${chartType}`).then(res => callback(res.data))
  }
  putChart(chart, callback) {
    this.axios().post(Url.system.config.chartsUpload, chart).then(res => callback(res))
  }
  updChart(chart, callback) {
    this.axios().post(Url.system.config.charts, chart).then(res => callback(res))
  }
  delChart(idx, callback) {
    this.axios().delete(`${Url.system.config.charts}/${idx}`).then(res => callback(res))
  }
  setPriority(env, callback) {
    this.axios().post(Url.system.config.setpriorities, env).then(res => {
      if(!!callback) callback(res)
    })
  }

  getUpdateList(callback) {
    this.axios().get(Url.system.config.uploadList).then(res => callback(res.data))
  }

  getWorkorders(phase, group) {
    return new Promise(resolve => {
      this.axios().get(`${Url.system.service.workorders}/${phase}/${group}`).then(res => { resolve(res.data) })
    })
  }
  getWorkorder(idx, version) {
    return new Promise(resolve => {
      this.axios().get(`${Url.system.service.workorder}/${idx}/${version}`).then(res => { resolve(res.data) })
    })
  }
  putWorkorder(order) {
    return new Promise(resolve => {
      this.axios().put(Url.system.service.workorder, order).then(res => { resolve(res.data) })
    })
  }
  updWorkorder(order) {
    return new Promise(resolve => {
      this.axios().post(Url.system.service.workorder, order).then(res => { resolve(res.data) })
    })
  }
  getWorkorderFiles(idx, version) {
    return new Promise(resolve => {
      this.axios().get(`${Url.system.service.wofile}/${idx}/${version}`).then(res => { resolve(res.data) })
    })
  }
  putWorkorderFiles(params, config) {
    return new Promise(resolve => {
      this.axios().put(Url.system.service.wofile, params, config).then(res => { resolve(res.data) })
    })
  }
  delWorkorderFile(idx) {
    return new Promise(resolve => {
      this.axios().delete(`${Url.system.service.wofile}/${idx}`).then(res => { resolve(res.data) })
    })
  }
}
