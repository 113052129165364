import * as d3 from 'd3'

export default {
  methods: {
    draw_SummaryTableCells() {
      let dataTable = this.tableGroup
      .append('g')
      .attr('id', 'table_cell')
      .attr('transform', `translate(0,0)`)

      let py = (this.Table.TableCellHeight/2) + 1 // Point of Cell Center
      let ly = 0 // line of data row
      
      this.DataItems.forEach((data, d) => {

        let dataRow = dataTable
        .append('g')
        .attr('id', `dataRow_${d}`)
        
        .attr('transform', `translate(0,${this.HeaderTotalHeight})`)
        .on('mouseover', () => {
          dataRow.style('cursor', 'default')
          d3.select(`#${this.localId}_hovorLine_${d}`).transition().duration(150).attr('opacity', this.Table.TableCellHoverOpacity)
        })
        .on('mouseout', () => {
          d3.select(`#${this.localId}_hovorLine_${d}`).transition().duration(150).attr('opacity', 0)
        })
        .on('click', () => {})

        // Draw Line each row
        if (this.Table.TableCellHLineDisplay == 'Y' &&  d < this.DataItems.length - 1) {
          dataRow
          .append('line')
          .attr('class', 'row_line')
          .attr('x1', 0).attr('y1', ly + this.Table.TableCellHeight)
          .attr('x2', this.HeaderTotalWidth).attr('y2', ly + this.Table.TableCellHeight)
          .attr('stroke-width',this.Table.TableCellStroke).attr('stroke', this.Table.TableCellStrokeColor)
        } 

        // Text Style
        d3.select('#table_cell')
        .selectAll('text')
        .attr('class', this.Table.TableCellStyle)
        .style('font-family', this.Table.TableCellFont)

        // Line Background Rect for Hovoring
        dataRow
        .append('rect')
        .attr('id', `${this.localId}_hovorLine_${d}`).attr('x', 0).attr('y', ly )
        .attr('width', this.HeaderTotalWidth).attr('height', this.Table.TableCellHeight)
        .attr('stroke-width',0)
        .attr('fill', this.Table.TableCellHoverColor)
        .attr('opacity', 0)

        this.clone_Columns.forEach((col, c) => {
          switch (col.type) {
            case 'Icon':
              this.draw_Icon(dataRow, d, c, py, ly, data[col.column])
            break;

            case 'Bar':
              this.draw_BarChart(dataRow, d, c, py, ly, data[col.column])
            break;

            case 'Coloring':
              this.draw_Coloring(dataRow, d, c, py, ly, col.width, data[col.column])
            break;

            case 'Text':
              this.draw_Text(dataRow, d, c, py, data[col.column], col.column, data)
            break;
          }
        })
        py += this.Table.TableCellHeight
        ly += this.Table.TableCellHeight
      })

    },

    












  }
}
