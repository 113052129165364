<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'    // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    label: {
      type: String,
      default: () => 'Module Summary'
    },
    // colorIndex: {
    //   type: Number,
    //   default: () => 0
    // },
    Canvas: { 
      type: Object, 
      default: () => ({}) 
    },
  },
  data: () => ({
    colorIndex: 0
  }),
  created() {
    this.localId = `${this.id || 'j-slide-modal-tab'}__${this.safeId('')}`
  },
  mounted() {
    this.draw()
  },

  methods: {
    draw() {
      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawTapComponemt()
      this.complete()
    },
    drawCanvas() {
      // this.svg
      // .attr('xmlns', 'http://www.w3.org/2000/svg')
      // .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      // .attr('version', '1.2')
      // .attr('id', `${this.localId}_svg`)
      // .attr('class', 'canvas')
      // .attr('width', this.Canvas.CanvasWidth)
      // .attr('height', this.Canvas.CanvasHeight)
      // .style('border', `solid ${this.Canvas.CanvasBorderWeight}px ${this.Canvas.CanvasBorderColor}`)
      // .style('background-color', '#fff')
    }
  }
}
</script>
<style lang="stylus" scoped>

</style>