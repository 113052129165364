import Vue from 'vue'
import __C from '@/primitives/_constant_'
import { PageComponentService } from "@/services"

const _pageComponentService = new PageComponentService()

export default {
  namespaced: true,
  state: {
    pageItem: {},
    svgItems: []
  },
  mutations: {
    appendSvgItem(state, item) { state.svgItems.push(item) },
    setSvgItems(state, items) { state.svgItems = items },
    setPageItem(state, pageItem_) {
      if(Object.keys(pageItem_).length > 0) {
        Object.keys(pageItem_).forEach(k => {
          if(Object.keys(state.pageItem).includes(k)) state.pageItem[k] = pageItem_[k]
          else Vue.set(state.pageItem, k, pageItem_[k])
        })
      } else state.pageItem = {}
    },
  },
  getters: {
  },
  actions: {
  }
}
