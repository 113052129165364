import __C from '../../includes/primitives/_constant_'
import * as d3 from 'd3'

export default {
  computed: {
    __HOST_NAME() { return __C.HOST_NAME },
    __HOST_NAME_RESOURCE() { return `${__C.HOST_NAME_RESOURCE}/resources` }    
  },
  methods: {
    getMaxBound(v) {
      if(isNaN(v)) {
        console.log('[USER: getMaxBound(NaN)] Cannot find the Bound-Value of the NaN. 0 will be returned.')
        return 0
      }

      let autoValues = [5, 10, 15, 20, 25, 40, 50, 75, 100, 125, 150, 200, 250, 300, 400, 500, 750, 1000, 1250, 1500, 2000, 2500, 3000, 4000, 5000, 7500, 10000, 12500, 15000, 17500, 20000, 25000, 30000, 40000, 50000, 75000, 100000]
      let valid_ = autoValues.find(val => val > v)
      let increment_ = 100

      while(!valid_) {
        valid_ = autoValues.map(v => v * increment_).find(val => val > v)
        increment_ = increment_ * 100
      }

      return valid_
    },
    getMinBound(v) {
      if(isNaN(v)) {
        console.log('[USER: getMinBound(NaN)] Cannot find the Bound-Value of the NaN. 0 will be returned.')
        return 0
      }

      let autoValues = [1000, 750, 500, 400, 300, 250, 200, 150, 125, 100, 75, 50, 30, 25, 20, 15, 10, 5, 0, 15, 20, 25, 30, 40, 50, 75, 100]
      return autoValues.find(val => val < v)
    },

    getNodeElValue(id, name) {
      if(!this.svg.select(id).node()) return 0
      return this.svg.select(id).node().getBoundingClientRect()[name]
    },
    


    multiLineText(selection, _props={}) {
      let id_ = Math.round(Math.random() * 1000000000)
      let props_ = {
        x: 0,
        y: 0,
        tColor: '#757575',
        width: 150,
        // height: 56,
        padding: '0px 5px 1px 5px',
        stroke: '#dadada',
        strokeWidth: 1,
        fontSize: 10,
        lineHeignt: 12,
        textAlign: 'start',       // start | middle | end
        verticalAlign: 'middle',  // top | middle | bottom
        text: 'textarea',
        newLine: '<br>'
      }
      Object.keys(_props).forEach(k_ => {
        props_[k_] = _props[k_]
      })

      // textbox border
      selection
      .append('rect')
      .attr('id', `mt_border_${id_}`)
      .attr('transform', `translate(${props_.x}, ${props_.y})`)
      .attr('width', props_.width)
      .attr('fill', 'Transparent')
      .attr('stroke', props_.stroke)
      .attr('stroke-width', props_.strokeWidth)

      // multiline textbox
      selection
      .append("foreignObject")
      .attr('id', `mt_fo_${id_}`)
      .attr('x', props_.x)
      .attr('y', props_.y)
      .attr('width', props_.width)
      .append('xhtml:div')
      .append('div')
      .attr('id', `mt_textarea_${id_}`)
      .attr('style', `
        display: flex;
        padding: ${props_.padding}; 
        color: ${props_.tColor};
        font-size: ${props_.fontSize}px; 
        line-height: ${props_.lineHeignt}px; 
        text-align: ${props_.textAlign == 'start' ? 'left' : (props_.textAlign == 'end' ? 'right' : 'center')};
        justify-content: ${props_.textAlign == 'start' ? 'flex-start' : (props_.textAlign == 'end' ? 'flex-end' : 'center')};
        align-items: ${props_.verticalAlign == 'top' ? 'flex-start' : (props_.verticalAlign == 'bottom' ? 'flex-end' : 'center')};
      `)
      .html(props_.text)

      if(!props_.height) var h_ = Number(this.getNodeElValue(`#mt_textarea_${id_}`, 'height'))
      else h_ = props_.height

      d3.select(`#mt_border_${id_}`).attr('height', h_)
      d3.select(`#mt_fo_${id_}`).attr('height', h_)
      d3.select(`#mt_textarea_${id_}`).style('height', `${h_}px`)

      return {
        height: h_
      }
    },


    getFontStyleByName(name) {
      // let weight = {
      //   bold: 
      // }
      // return d3
      // .select(id)
      // .node()
      // .getBoundingClientRect()[name]
    },

    AddComma(num){
      var regexp = /\B(?=(\d{3})+(?!\d))/g;
      return num.toString().replace(regexp, ',')
    },

    // ToolTip Path Generator
    // topTooltipPath / bottomTooltipPath / leftTooltipPath / rightTooltipPath
    topTooltipPath(width, height, offset, radius) {
      const left = -width / 2
      const right = width / 2
      const top = -offset - height
      const bottom = -offset
      return `M 0,0 
        L ${-offset},${bottom} 
        H ${left + radius}
        Q ${left},${bottom} ${left},${bottom - radius}  
        V ${top + radius}   
        Q ${left},${top} ${left + radius},${top}
        H ${right - radius}
        Q ${right},${top} ${right},${top + radius}
        V ${bottom - radius}
        Q ${right},${bottom} ${right - radius},${bottom}
        H ${offset} 
        L 0,0 z`
    },
    bottomTooltipPath(width, height, offset, radius) {
      const left = -width / 2
      const right = width / 2
      const bottom = offset + height
      const top = offset
      return `M 0,0 
        L ${-offset},${top} 
        H ${left + radius}
        Q ${left},${top} ${left},${top + radius}  
        V ${bottom - radius}   
        Q ${left},${bottom} ${left + radius},${bottom}
        H ${right - radius}
        Q ${right},${bottom} ${right},${bottom - radius}
        V ${top + radius}
        Q ${right},${top} ${right - radius},${top}
        H ${offset} 
        L 0,0 z`
    },
    leftTooltipPath(width, height, offset, radius) {
      const left = -offset - width
      const right = -offset
      const top = -height / 2
      const bottom = height / 2
      return `M 0,0 
        L ${right},${-offset} 
        V ${top + radius}
        Q ${right},${top} ${right - radius},${top}  
        H ${left + radius}   
        Q ${left},${top} ${left},${top + radius}
        V ${bottom - radius}
        Q ${left},${bottom} ${left + radius},${bottom}
        H ${right - radius}
        Q ${right},${bottom} ${right},${bottom - radius}
        V ${offset} 
        L 0,0 z`
    },
    rightTooltipPath(width, height, offset, radius) {
      const left = offset
      const right = offset + width
      const top = -height / 2
      const bottom = height / 2
      return `M 0,0 
        L ${left},${-offset} 
        V ${top + radius}
        Q ${left},${top} ${left + radius},${top}  
        H ${right - radius}   
        Q ${right},${top} ${right},${top + radius}
        V ${bottom - radius}
        Q ${right},${bottom} ${right - radius},${bottom}
        H ${left + radius}
        Q ${left},${bottom} ${left},${bottom - radius}
        V ${offset} 
        L 0,0 z`
    },
    // ### Global Common Function - will be moved to data-function-mixin.
    getRequestColumnProps(colName='', dataSource={}, colProps=[]) {
      let colProps_ = colProps.find(props_ => props_.value == colName)

      if(!colProps_ || !colProps_.link) return null

      let columnParts = (colString) => {
        let colParts_ = colString.replace(/\s/g, '').split('=')
        if(colParts_.length === 0) return { source: '', target: '' }
        if(colParts_.length === 1) return { source: colParts_[0], target: colParts_[0] }
        return { source: colParts_[1], target: colParts_[0] }
      }

      let values_ = colProps_.link.values
      let valueObject_ = {}
      if(values_ && values_.length > 0) {
        values_.forEach(col_ => {
          let colParts__ = columnParts(col_)

          if(!colParts__.source) console.log(`[USER: undefined] Undefined column-name for the action-value.`)
          else if(!dataSource[colParts__.source]) console.log(`[USER: not found '${colParts__.source}'] Not found matched value-column for the filter-value, but it could be found from the 'FilterValues' instead.`)
          else valueObject_[colParts__.target] = dataSource[colParts__.source]
        })
      }
      
      return {
        dataType: 'row',
        action: {
          type: 'direct',
          ...colProps_.link.action
        },
        filters: valueObject_,
        iFilters: { 
          filterString: colProps_.link.filterString || '',
          inputFilter: ''
        }
      }
    },
    // target => d3 selection
    parseTranslate(target) {
      // let translate = (target.attr('transform') || '').replace(/\s/g, '').match(/translate(.*?)\)/g)
      let translate = (target.attr('transform') || '').match(/translate(.*?)\)/g)
      let coordinate = translate[0].match(/-?\d+\.?(\d+)?/g)
      return {
        x: parseInt(coordinate[0]),
        y: parseInt(coordinate[1]),
      }
    }
  }
}
