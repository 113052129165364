import Api from '../api'
import Url from '../request.url'

export default class SystemCompletionApi extends Api {

  constructor() {
    super()
  }

  getFilterOptionArea() {
    return new Promise(resolve => {
      this.axios().get(`${Url.md2.filter.scarea}`).then(res => { resolve(res.data) })
    })
  }
}