export default (function() {

  return {
    jsonEscape: (str) => str.replace(/\n/g, "\\\\n").replace(/\r/g, "\\\\r").replace(/\t/g, "\\\\t"),
    undefined2zero: (attr) => {
      return {
        undefined: typeof attr == 'undefined',
        value: typeof attr == 'undefined' ? 0 : Number(attr)
      }
    },
    extractEls: (source, type) => {
      let keys = Object.keys(source).filter(k => k.toLowerCase().indexOf(type) === 0)
      //if( type == 'chtcomm') {
        //console.log('source', source)
        //console.log('keys', keys)
      //}
      let els = {}
      keys.forEach(k => { els[k] = source[k] })
    
      return els
    },
    hex2rgba: (hex, alpha) => {
      let [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
      return `rgba(${r},${g},${b},${alpha})`
    },
    rgba2hex(rgba, toRGB=false, fgc=[255,255,255]) {
      if(!rgba) return { hex: '', alpha: 1 }

      let rgb = rgba.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i)
      let alpha = (rgb && rgb[4] || '')

      if(toRGB) {
        let a_ = alpha !== '' ? alpha : '01'
        a_ = Math.round(a_ * 100) / 100

        let rgb___ = rgb ? rgb.slice(1, 4).map((rgb_, i) => Math.round(a_ * rgb_ + (1 - a_) * fgc[i])) : []

        var hex = rgb ? 
            (rgb___[0] | 1 << 8).toString(16).slice(1) +
            (rgb___[1] | 1 << 8).toString(16).slice(1) +
            (rgb___[2] | 1 << 8).toString(16).slice(1) : 
            rgba

      } else {
        hex = rgb ? 
        (rgb[1] | 1 << 8).toString(16).slice(1) +
        (rgb[2] | 1 << 8).toString(16).slice(1) +
        (rgb[3] | 1 << 8).toString(16).slice(1) : 
        rgba
      }
        
      return { hex: `#${hex}`, alpha: alpha }
    },
    taAutoHeight(ta_, min=60) {
      ta_.style.height = ''
      ta_.style.height = Math.min(ta_.scrollHeight, min) + 'px'

      if(ta_.scrollHeight > min) ta_.style.overflowY = 'auto'
      else ta_.style.overflowY = 'hidden'
    },
    taDefaultHeight(ta_) {
      ta_.style.height = '16px'
      ta_.style.overflowY = 'hidden'
    }
  }
})()