export default {
  mrComment: {
    id: 0,
    trackingNo: '',
    text: '',
    status: 'Draft',
    projectNo: '75231',
    stage: '',
    packageNo: '',
    plant: '',
    area: '',
    pidref: '',
    location: '',
    category: '',
    discipline: '',
    targetDate: '',
    createdBy: '',
    createdDate: '',
    relatedTo: '',
    generalComment: '',
    viewpoints: [],
    newTags: [],
    removed: []
  },
  formTag: {
    tagNo: '',
    description: ''
  },
  mrViewpoint: {
    id: 0,
    viewpointNo: '',
    coordinate: '',
    rotation: '',
    file: {
      idx       : 0,
      name      : '',
      sysName   : '',
      mimeType  : '',
      fileType  : '',
      ext       : '',
      path      : '',
      dataUrl   : '',
      size      : '',
      dimention : '',
    }
  },
  codeMatchStatus: {
    new: 'Draft',
    active: 'In Progress',
    resolved: 'Closed',
  }
}
