<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'
import * as d3 from 'd3'

export default {
  name: "j-svg-page",
  mixins: [mx_Core],
  props: {
    id: String,
    // ### Necessaries for the SVG-Page ###
    Action: { type: Object, default: () => ({})},
    FilterValues: {type: Object, default: () => ({})},
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    Queries: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    values: { type: Object, default: () => ({})}
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    clearSvgGroup(){
      d3.select(`#__svg_group_`).remove()
    },

    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.initValues()
      this.drawCanvas()
      this.drawNotes()
      //this.drawTexts()
      this.set_LocalValues() 

      this.drawForms()
      this.complete()

    },

  }
}
</script>

<style lang="scss" scoped>

</style>