import _localStorage from './_pouchdb'
import _maps from './_location.map'

const _nonPrototypeEls = ['_id', '_rev']

export default class {
    
  constructor() {
    this.db = new _localStorage(_maps.DATABASES.MASTER)
  }

  getInfo(id) {
    return this.db.get(_maps.DOCUMENTS.INFO(id)).catch(err => {
      if(this.isNotFound) return {
        _id: _maps.DOCUMENTS.INFO(id),
        info: {}
      }
      throw err

    }).then(doc => new Promise(resolve => { resolve(this.toPrototype(doc).info)}))
  }
  getConfigProps(id) {
    return this.db.get(_maps.DOCUMENTS.CONFIG_PROPS(id)).catch(err => {
      if(this.isNotFound) return {
        _id: _maps.DOCUMENTS.CONFIG_PROPS(id),
        props: []
      }
      throw err

    }).then(doc => new Promise(resolve => { resolve(this.toPrototype(doc).props)}))
  }
  getChartFilter(id) {
    return this.db.get(_maps.DOCUMENTS.CHART_FILTER(id)).catch(err => {
      if(this.isNotFound) return {
        _id: _maps.DOCUMENTS.CHART_FILTER(id),
        filter: {
          chartNo: '',
          qFilters: ''
        }
      }
      throw err

    }).then(doc => new Promise(resolve => { resolve(this.toPrototype(doc).filter)}))
  }
  getEnvValues(id) {
    return this.db.get(_maps.DOCUMENTS.ENV_VALUES(id)).catch(err => {
      if(this.isNotFound) return {
        _id: _maps.DOCUMENTS.ENV_VALUES(id),
        values: {}
      }
      throw err

    }).then(doc => new Promise(resolve => { resolve(this.toPrototype(doc).values)}))
  }
  getFilterStatus(id) {
    return this.db.get(_maps.DOCUMENTS.FILTER_STATUS(id)).catch(err => {
      if(this.isNotFound) return {
        _id: _maps.DOCUMENTS.FILTER_STATUS(id),
        values: {}
      }
      throw err

    }).then(doc => new Promise(resolve => { resolve(this.toPrototype(doc).values)}))
  }
  getFilteredValues(id) {
    return this.db.get(_maps.DOCUMENTS.FILTERED_VALUES(id)).catch(err => {
      if(this.isNotFound) return {
        _id: _maps.DOCUMENTS.FILTERED_VALUES(id),
        values: {}
      }
      throw err

    }).then(doc => new Promise(resolve => { resolve(this.toPrototype(doc).values)}))
  }
  updateInfo(id, info) {
    return this.db.get(_maps.DOCUMENTS.INFO(id)).then(doc => { 
      doc.info = info
      return this.db.put(doc)

    }).catch(err => {
      if(this.isNotFound) return this.db.put({
        _id: _maps.DOCUMENTS.INFO(id),
        info: info
      }) 
      throw err
    })
  }
  updateConfigProps(id, props) {
    return this.db.get(_maps.DOCUMENTS.CONFIG_PROPS(id)).then(doc => { 
      doc.props = props
      return this.db.put(doc)

    }).catch(err => {
      if(this.isNotFound) return this.db.put({
        _id: _maps.DOCUMENTS.CONFIG_PROPS(id),
        props: props
      }) 
      throw err
    })
  }
  updateChartFilter(id, filter) {
    return this.db.get(_maps.DOCUMENTS.CHART_FILTER(id)).then(doc => { 
      if(doc.filter.chartNo == filter.chartNo) doc.filter = {
        chartNo: '',
        qFilters: ''
      }; else doc.filter = filter
      
      return this.db.put(doc)

    }).catch(err => {
      if(this.isNotFound) return this.db.put({
        _id: _maps.DOCUMENTS.CHART_FILTER(id),
        filter: filter
      }) 
      throw err
    })
  }
  updateEnvValues(id, values) {
    return this.db.get(_maps.DOCUMENTS.ENV_VALUES(id)).then(doc => { 
      doc.values = values
      return this.db.put(doc)

    }).catch(err => {
      if(this.isNotFound) return this.db.put({
        _id: _maps.DOCUMENTS.ENV_VALUES(id),
        values: values
      }) 
      throw err
    })
  }
  updateFilterStatus(id, values) {
    return this.db.get(_maps.DOCUMENTS.FILTER_STATUS(id)).then(doc => { 
      doc.values = values
      return this.db.put(doc)

    }).catch(err => {
      if(this.isNotFound) return this.db.put({
        _id: _maps.DOCUMENTS.FILTER_STATUS(id),
        values: values
      }) 
      throw err
    })
  }
  updateFilteredValues(id, values) {
    return this.db.get(_maps.DOCUMENTS.FILTERED_VALUES(id)).then(doc => { 
      doc.values = values
      return this.db.put(doc)

    }).catch(err => {
      if(this.isNotFound) return this.db.put({
        _id: _maps.DOCUMENTS.FILTERED_VALUES(id),
        values: values
      }) 
      throw err
    })
  }
  removeConfigProps(id) {
    return this.db.get(_maps.DOCUMENTS.CONFIG_PROPS(id)).then(doc => { 
      return this.db.remove(doc)
    }).catch(err => {
      throw err
    })
  }
  removeChartFilter(id) {
    return this.db.get(_maps.DOCUMENTS.CHART_FILTER(id)).then(doc => { 
      return this.db.remove(doc)
    }).catch(err => {
      throw err
    })
  }
  removeFilteredValues(id) {
    return this.db.get(_maps.DOCUMENTS.FILTERED_VALUES(id)).then(doc => { 
      return this.db.remove(doc)
    }).catch(err => {
      throw err
    })
  }
  toPrototype(doc) {
    Object.keys(doc).forEach(k => {
      if(_nonPrototypeEls.includes(k)) delete doc[k]
    })
    return doc
  }
  isNotFound(err) {
    return err.name === 'not_found'
  }


  // Board Report
  getBoardReportValues(id) {
    return this.db.get(_maps.DOCUMENTS.BOARD_REPORT(id)).catch(err => {
      if(this.isNotFound) return {
        _id: _maps.DOCUMENTS.BOARD_REPORT(id),
        values: {}
      }
      throw err

    }).then(doc => new Promise(resolve => { resolve(this.toPrototype(doc).values)}))
  }
  updateBoardReportValues(id, values) {
    return this.db.get(_maps.DOCUMENTS.BOARD_REPORT(id)).then(doc => { 
      doc.values = values
      return this.db.put(doc)

    }).catch(err => {
      if(this.isNotFound) return this.db.put({
        _id: _maps.DOCUMENTS.BOARD_REPORT(id),
        values: values
      }) 
      throw err
    })
  }

  // File browser
  getFileBrowserValues(id) {
    return this.db.get(_maps.DOCUMENTS.BOARD_REPORT(id)).catch(err => {
      if(this.isNotFound) return {
        _id: _maps.DOCUMENTS.BOARD_REPORT(id),
        values: {}
      }
      throw err

    }).then(doc => new Promise(resolve => { resolve(this.toPrototype(doc).values)}))
    }
    updateFileBrowserValues(id, values) {
      return this.db.get(_maps.DOCUMENTS.BOARD_REPORT(id)).then(doc => { 
        doc.values = values
        return this.db.put(doc)

      }).catch(err => {
        if(this.isNotFound) return this.db.put({
          _id: _maps.DOCUMENTS.BOARD_REPORT(id),
          values: values
        }) 
        throw err
      })
    }
}