
import { ApplicationService } from './'
import { PageComponentsApi } from './api'

export default class PageComponentService {
    
  constructor() {
    this.appService = new ApplicationService()
    this.pageComponentsApi = new PageComponentsApi()
  }
  
  getIdx (idx, name, callback) {
    this.pageComponentsApi.getIdx(idx, name, (res) => callback(res))
  }

  // Data-Table
  getDatatables (phaseCode, groupCode, itemCode, subItemCode, callback) {
    this.pageComponentsApi.getDatatables(phaseCode, groupCode, itemCode, subItemCode, (res) => callback(res))
  }
  getDatatable (idx, callback) {
    this.pageComponentsApi.getDatatable(idx, (res) => {
      if(!res) {
        callback([])
        return
      }

      res.titleAttrs = res.titleAttrs ? JSON.parse(res.titleAttrs) : {}
      res.tableAttrs = res.tableAttrs ? JSON.parse(res.tableAttrs) : {}
      res.headerAttrs = res.headerAttrs ? JSON.parse(res.headerAttrs) : {}
      res.bodyAttrs = res.bodyAttrs ? JSON.parse(res.bodyAttrs) : {}
      res.groupAttrs = res.groupAttrs ? JSON.parse(res.groupAttrs) : {}
      res.styleAttrs = res.styleAttrs ? JSON.parse(res.styleAttrs) : {}
      res.svgAttrs = res.svgAttrs ? JSON.parse(res.svgAttrs) : {}
      callback(res)
    })
  }
  putDatatable (item, callback) {
    item.titleAttrs = item.titleAttrs ? JSON.stringify(item.titleAttrs) : ''
    item.tableAttrs = item.tableAttrs ? JSON.stringify(item.tableAttrs) : ''
    item.headerAttrs = item.headerAttrs ? JSON.stringify(item.headerAttrs) : ''
    item.bodyAttrs = item.bodyAttrs ? JSON.stringify(item.bodyAttrs) : ''
    item.groupAttrs = item.groupAttrs ? JSON.stringify(item.groupAttrs) : ''
    item.styleAttrs = item.styleAttrs ? JSON.stringify(item.styleAttrs) : ''
    item.svgAttrs = item.svgAttrs ? JSON.stringify(item.svgAttrs) : ''

    this.pageComponentsApi.putDatatable(item, res => { callback(res) })
  }
  updDatatable (item, callback) {
    item.titleAttrs = item.titleAttrs ? JSON.stringify(item.titleAttrs) : ''
    item.tableAttrs = item.tableAttrs ? JSON.stringify(item.tableAttrs) : ''
    item.headerAttrs = item.headerAttrs ? JSON.stringify(item.headerAttrs) : ''
    item.bodyAttrs = item.bodyAttrs ? JSON.stringify(item.bodyAttrs) : ''
    item.groupAttrs = item.groupAttrs ? JSON.stringify(item.groupAttrs) : ''
    item.styleAttrs = item.styleAttrs ? JSON.stringify(item.styleAttrs) : ''
    item.svgAttrs = item.svgAttrs ? JSON.stringify(item.svgAttrs) : ''

    this.pageComponentsApi.updDatatable(item, res => { callback(res) })
  }
  delDatatable (idx, callback) {
    this.pageComponentsApi.delDatatable(idx, res => { callback(res) })
  }
  
  getDatatableRowsPerPage (idx, callback) {
    this.pageComponentsApi.getDatatableRowsPerPage(idx, res => { callback(res) })
  }
  getDatatablePreview (params, callback) {
    this.appService.requestDataCollectionIDG(params, res => {
      callback(res)
    })
  }
  
  // Dashboard
  getDashboardItems (phaseCode, groupCode, itemCode, subItemCode, typeCode, callback) {
    this.pageComponentsApi.getDashboardItems(phaseCode, groupCode, itemCode, subItemCode, typeCode, (res) => callback(res))
  }
  getDashboardItem (idx, callback) {
    this.pageComponentsApi.getDashboardItem(idx, (res) => {
      console.log('res',res)
      if(!res) {
        res.dashboard = {}
        res.dashboard.layoutAttrs = {}
        res.dashboard.containerAttrs = []
        res.dashboard.moreAttrs = {}
        res.chartItems =[]
      } else {
        res.dashboard = res.dashboard ? JSON.parse(res.dashboard) : {}
        res.dashboard.layoutAttrs = res.dashboard.layoutAttrs ? JSON.parse(res.dashboard.layoutAttrs) : {}
        res.dashboard.containerAttrs = res.dashboard.containerAttrs ? JSON.parse(res.dashboard.containerAttrs) : []
        res.dashboard.moreAttrs = res.dashboard.moreAttrs ? JSON.parse(res.dashboard.moreAttrs) : []
        res.chartItems = res.chartItems ? JSON.parse(res.chartItems) : []
        console.log(res.chartItems)
      }
      callback(res)
    })
  }
  putDashboardItem (item, callback) {
    let item_ = JSON.parse(JSON.stringify(item))
    if(item_.layoutAttrs) item_.layoutAttrs = JSON.stringify(item_.layoutAttrs)
    if(item_.containerAttrs) item_.containerAttrs = JSON.stringify(item_.containerAttrs)
    if(item_.moreAttrs) item_.moreAttrs = JSON.stringify(item_.moreAttrs)

    this.pageComponentsApi.putDashboardItem(item_, res => { callback(res) })
  }
  sasDashboardItem (item, callback) {
    let item_ = JSON.parse(JSON.stringify(item))
    if(item_.layoutAttrs) item_.layoutAttrs = JSON.stringify(item_.layoutAttrs)
    if(item_.containerAttrs) item_.containerAttrs = JSON.stringify(item_.containerAttrs)
    if(item_.moreAttrs) item_.moreAttrs = JSON.stringify(item_.moreAttrs)

    this.pageComponentsApi.sasDashboardItem(item_, res => { callback(res) })
  }
  updDashboardItem (item, callback) {
    let item_ = JSON.parse(JSON.stringify(item))
    if(item_.layoutAttrs) item_.layoutAttrs = JSON.stringify(item_.layoutAttrs)
    if(item_.containerAttrs) item_.containerAttrs = JSON.stringify(item_.containerAttrs)
    if(item_.moreAttrs) item_.moreAttrs = JSON.stringify(item_.moreAttrs)

    this.pageComponentsApi.updDashboardItem(item_, res => { callback(res) })
  }
  delDashboardItem (idx, callback) {
    this.pageComponentsApi.delDashboardItem(idx, res => { callback(res) })
  }

  // SVG Properties
  getSvgPropsList (phaseCode, groupCode, itemCode, subItemCode, callback) {
    this.pageComponentsApi.getSvgPropsList(phaseCode, groupCode, itemCode, subItemCode, (res) => callback(res))
  }
  getSvgProps (idx, callback) {
    this.pageComponentsApi.getSvgProps(idx, (res) => {
      res.canvas = JSON.parse(res.canvas)
      res.border = JSON.parse(res.border)
      res.style = JSON.parse(res.style)
      res.forms = JSON.parse(res.forms)
      res.note = JSON.parse(res.note)
      res.moreAttrs = JSON.parse(res.moreAttrs)
      
      callback(res)
    })
  }
  putSvgProps (props, callback) {
    let props_ = JSON.parse(JSON.stringify(props))
    props_.canvas = JSON.stringify(props_.canvas)
    props_.border = JSON.stringify(props_.border)
    props_.style = JSON.stringify(props_.style)
    props_.forms = JSON.stringify(props_.forms)
    props_.note = JSON.stringify(props_.note)
    props_.moreAttrs = JSON.stringify(props_.moreAttrs)

    this.pageComponentsApi.putSvgProps(props_, res => { callback(res) })
  }
  updSvgProps (props, callback) {
    let props_ = JSON.parse(JSON.stringify(props))
    props_.canvas = JSON.stringify(props_.canvas)
    props_.border = JSON.stringify(props_.border)
    props_.style = JSON.stringify(props_.style)
    props_.forms = JSON.stringify(props_.forms)
    props_.note = JSON.stringify(props_.note)
    props_.moreAttrs = JSON.stringify(props_.moreAttrs)

    this.pageComponentsApi.updSvgProps(props_, res => { callback(res) })
  }
  delSvgProps (idx, callback) {
    this.pageComponentsApi.delSvgProps(idx, res => { callback(res) })
  }
  
  // Custom Component's Env
  getCustomCompEnvs (phaseCode, groupCode, itemCode, subItemCode, callback) {
    this.pageComponentsApi.getCustomCompEnvs(phaseCode, groupCode, itemCode, subItemCode, (res) => callback(res))
  }
}
