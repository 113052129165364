import _configDefault from "@/primitives/_configDefault"

export default {
  namespaced: true,
  state: {
    layout: _configDefault.layout,
    language: 'en'
  },
  getters: {
  },
  mutations: {
    setLayout(state, layout) {
      state.layout = layout
    },
    setLnbVisible(state, visible) {
      state.layout.lnb.visible = visible
    },
    setSnbVisible(state, visible) {
      state.layout.snb.visible = visible
    },
    setFnbVisible(state, visible) {
      state.layout.fnb.visible = visible
    },
    setLanguage(state, lang) {
      state.language = lang
    }
  },
  actions: {
  }
}
