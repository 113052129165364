<template>
  <j-overlay 
    v-model="opened" 
    content-right
    :id="localId"
  >
    <div class="j-modal-slide--content-wrapper">
      <div class="j-modal-slide--content">
        <slot name="header"></slot>
        <slot></slot>
        <slot name="footer"></slot>
      </div>
    </div>
  </j-overlay>
</template>
<script>
import '@/assets/stylus/ui/component/_jModalSlide.styl'

import __C from '@/primitives/_constant_'

import * as d3 from 'd3'
import { mapMutations } from 'vuex'
import { SafeIdMixin } from '@/mixins/safeid.mixin'

export default {
  name: 'j-modal-slide',
  mixins: [
    SafeIdMixin
  ],
  props: {
    width: Number,
    value: null,
  },
  data: () => ({
    opened: false
  }),
  watch: {
    value(val) {
      if(!val) {
        this.onClose()
        return
      }
      this.opened = val
      this.setModalOpened(true)
      setTimeout(() => { this.run() })
    }
  },
  created() {
    this.localId = this.id || 'j-modal-slide__' + this.safeId('')
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown)
    setTimeout(() => { d3.select(`#${this.localId}`).select('.j-overlay__scrim').on('click', this.onClose) })
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, ['setModalOpened']),

    onKeydown(e) {
      if(e.key == 'Escape') this.onClose()
    },
    onClose() {
      d3.select(`#${this.localId}`)
      .select('.j-modal-slide--content')
      .transition()
      .delay(150)
      .duration(350)
      .style('width', '0px')

      setTimeout(() => { 
        this.opened = false 
        this.setModalOpened(false)
        this.$emit('input', false)
      }, 500)
    },
    run() {
      d3.select(`#${this.localId}`)
      .select('.j-modal-slide--content')
      .transition()
      .duration(500)
      .style('width', this.width ? `${this.width}px`: '750px')
    }
  }
}
</script>

