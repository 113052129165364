import * as d3 from 'd3'
import icon from '../../../../../includes/primitives/icons'

export default {
  methods: {

    chart_Controller() {

      this.draw_trendChart() 
      this.draw_Legends()
      this.draw_Delta()

    },

    draw_trendChart() {
      
      let boxChart = this.Trend
      .append('g')
      .attr('transform', `translate(0,-.5)`)
      .style('font-family', 'roboto')

      this.BarStyle.filter(f=> f.code !== 'FC_WK').forEach((cht,c)=> {
        boxChart
        .selectAll(`rect_${c}`)
        .data(this.rawData)
        .enter()
        .append('rect')
        .attr('x', d => this.scaleX(d.CDATE) - (this.Chart.barSize + this.Chart.barSize/2) + (this.Chart.barSize*c) +.5)
        .attr('y', d => this.scaleY(d[cht.code]))
        .attr('height', d => this.Chart.height - this.scaleY(d[cht.code]))
        .attr('width', this.Chart.barSize-1)
        .attr('fill',cht.color)
        .attr('stroke', cht.color)
        .attr('stroke-width', 0)
      })

      // Forecast
      let cht = this.BarStyle.find(f=> f.type == 'FC')
      let fc = this.BarStyle.findIndex(f=> f.type == 'ACT')

      boxChart
      .selectAll(`rect_${fc}`)
      .data(this.rawData.filter(f=> f.CDATE > this.timeline.cutoff))
      .enter()
      .append('rect')
      .attr('x', d => this.scaleX(d.CDATE) - (this.Chart.barSize + this.Chart.barSize/2) + (this.Chart.barSize*fc) +.5)
      .attr('y', d => this.scaleY(d[cht.code]))
      .attr('height', d => this.Chart.height - this.scaleY(d[cht.code]))
      .attr('width', this.Chart.barSize-1)
      .attr('fill',cht.color)
      .attr('stroke', cht.color)
      .attr('stroke-width', 0)
    },

    draw_Legends() {

      let legends = this.Trend
      .append('g')
      .attr('id', `LEGENDS_${this.localId}`)
      .attr('transform', `translate(${0}, ${-this.Legend.margin})`)

      let x_ = 0
      this.BarStyle.forEach((d,i)=> {

        let item = legends
        .append('g')
        .attr('id', `ITEM${i}_${this.localId}`)
        .attr('transform', `translate(${0}, ${-this.Legend.margin})`)
        .attr('font-family', 'roboto')

        item
        .append('rect')
        .attr('x', x_).attr('y', -4)
        .attr('width', 7).attr('height', 7)
        .attr('fill', d.color)

        item
        .append('text')
        .attr('id', `ITEM${i}_TEXT_${this.localId}`)
        .attr('x', x_ + 10)
        .attr('y', 0)
        .style('font-size', this.Legend.tSize).style('fill', this.Legend.tColor)
        .attr('alignment-baseline', 'middle')
        .text(d.name)

        let len = d3.select(`#ITEM${i}_${this.localId}`).node().getBBox().width
        x_ = x_ + len + 10
      })

      // Change Legend Position
      let totalLen = d3.select(`#LEGENDS_${this.localId}`).node().getBBox().width
      let position = 0

      if (this.Legend.align == 'center') {
        position = (this.Chart.width/2) - (totalLen/2)
      } else if (this.Legend.align == 'right') {
        position = this.Chart.width - totalLen
      }

      d3.select(`#LEGENDS_${this.localId}`).attr('transform', `translate(${position}, ${-this.Legend.margin})`)
    },


    draw_Delta() {
      
      
      this.rawData.filter(f=> f.CDATE <= this.timeline.cutoff).forEach((d,i)=> {

        let dev = (d.DE_WK + d.DL_WK)/2

        let delta = this.Trend

        .append('g')
        .attr('id', `DEV${i}_${this.localId}`)
        .attr('transform', `translate(${6}, ${-this.Delta.margin})`)
        .attr('font-family', 'roboto')

        delta
        .append('text')
        .attr('x', this.scaleX(d.CDATE))
        .attr('y', -this.Delta.margin)
        .style('font-size', this.Delta.tSize)
        .style('fill', ()=> {
          if(dev == 0) return '#757575'
          if(dev < 0) return this.Delta.down
          if(dev > 0) return this.Delta.up
        })
        .attr('alignment-baseline', 'middle').attr('text-anchor', 'end')
        .text(`${dev.toFixed(1)}%`)

        delta
        .append('path')
        .attr('transform', `translate(${this.scaleX(d.CDATE)+1}, ${-this.Delta.margin - 4.5}) scale(.6)`)
        .attr('d', ()=> {
          if(dev < 0) return icon.arrow.down
          if(dev > 0) return icon.arrow.up
        })
        .style('fill', ()=> {
          if(dev == 0) return '#757575'
          if(dev < 0) return this.Delta.down
          if(dev > 0) return this.Delta.up
        })
        .attr('stroke-width', .5)
      })

      this.Trend
      .append('text')
      .attr('x', this.Chart.width)
      .attr('y', -5)
      .style('font-size', 9)
      .style('fill', '#757575')
      .attr('alignment-baseline', 'middle').attr('text-anchor', 'end')
      .text(`Delta value is the average of early and late.`)

    },
  }
}