import Api from './api'
import Url from './request.url'

// http://patorjk.com/software/taag/#p=display&h=2&v=2&f=Banner3&t=BAR%20PROGRESS
export default class ChartLibraryApi extends Api {

  constructor() {
    super()
  }
  getQueryValidator(param, callback) {
    this.axios().post(Url.system.config.chartlib.validateq, param).then(res =>{ callback(res.data) })
  }

  getChartList(catCode, phaseCode, itemCode, callback) {
    this.axios().get(`${Url.system.config.chartlib.list}/${catCode}/${phaseCode}/${itemCode}`).then(res => callback(res.data))
  }
  getChartThumbs(catCode, phaseCode, itemCode, callback) {
    this.axios().get(`${Url.system.config.chartlib.thumbs}/${catCode}/${phaseCode}/${itemCode}`).then(res => callback(res.data))
  }

  // Bar Delta
  getChartBarDelta(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.bardelta}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartBarDelta (item, callback) {
    this.axios().put(Url.system.config.chartlib.bardelta, item).then(res => callback(res.data))
  }
  sasChartBarDelta (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.bardelta}/sas`, item).then(res => callback(res.data))
  }
  updChartBarDelta (item, callback) {
    this.axios().post(Url.system.config.chartlib.bardelta, item).then(res => callback(res))
  }
  delChartBarDelta (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.bardelta}/${chartNo}`).then(res => callback(res))
  }

  // Bar Normal(Axis)
  getChartBarNormal(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.barnormal}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartBarNormal(item, callback) {
    this.axios().put(Url.system.config.chartlib.barnormal, item).then(res => callback(res.data))
  }  
  sasChartBarNormal(item, callback) {
    this.axios().put(`${Url.system.config.chartlib.barnormal}/sas`, item).then(res => callback(res.data))
  }
  updChartBarNormal(item, callback) {
    this.axios().post(Url.system.config.chartlib.barnormal, item).then(res => callback(res))
  }
  delChartBarNormal(chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.barnormal}/${chartNo}`).then(res => callback(res))
  }

  // Progress Bar
  getChartBarProgress(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.barprogress}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartBarProgress (item, callback) {
    this.axios().put(Url.system.config.chartlib.barprogress, item).then(res => callback(res.data))
  }
  sasChartBarProgress (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.barprogress}/sas`, item).then(res => callback(res.data))
  }
  updChartBarProgress (item, callback) {
    this.axios().post(Url.system.config.chartlib.barprogress, item).then(res => callback(res))
  }
  delChartBarProgress (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.barprogress}/${chartNo}`).then(res => callback(res))
  }

  // Bar Solid
  getChartBarSolid(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.barsolid}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartBarSolid (item, callback) {
    this.axios().put(Url.system.config.chartlib.barsolid, item).then(res => callback(res.data))
  }
  sasChartBarSolid (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.barsolid}/sas`, item).then(res => callback(res.data))
  }
  updChartBarSolid (item, callback) {
    this.axios().post(Url.system.config.chartlib.barsolid, item).then(res => callback(res))
  }
  delChartBarSolid (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.barsolid}/${chartNo}`).then(res => callback(res))
  }

  // Donut
  getChartDonut(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.donut}/${chartNo}`).then(res => callback(res.data))
  }
  putChartDonut (item, callback) {
    this.axios().put(Url.system.config.chartlib.donut, item).then(res => callback(res.data))
  }
  sasChartDonut (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.donut}/sas`, item).then(res => callback(res.data))
  }
  updChartDonut (item, callback) {
    this.axios().post(Url.system.config.chartlib.donut, item).then(res => callback(res))
  }
  delChartDonut (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.donut}/${chartNo}`).then(res => callback(res))
  }

  //Floating
  getChartFloating(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.floating}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartFloating (item, callback) {
    this.axios().put(Url.system.config.chartlib.floating, item).then(res => callback(res.data))
  }
  sasChartFloating (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.floating}/sas`, item).then(res => callback(res.data))
  }
  updChartFloating (item, callback) {
    this.axios().post(Url.system.config.chartlib.floating, item).then(res => callback(res))
  }
  delChartFloating (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.floating}/${chartNo}`).then(res => callback(res))
  }

  // Histogram
  getChartHistogram(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.histogram}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartHistogram (item, callback) {
    this.axios().put(Url.system.config.chartlib.histogram, item).then(res => callback(res.data))
  }
  sasChartHistogram (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.histogram}/sas`, item).then(res => callback(res.data))
  }
  updChartHistogram (item, callback) {
    this.axios().post(Url.system.config.chartlib.histogram, item).then(res => callback(res))
  }
  delChartHistogram (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.histogram}/${chartNo}`).then(res => callback(res))
  }

  // Pie
  getChartPie(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.pie}/${chartNo}`).then(res => callback(res.data))
  }
  putChartPie(item, callback) {
    this.axios().put(Url.system.config.chartlib.pie, item).then(res => callback(res.data))
  }
  sasChartPie(item, callback) {
    this.axios().put(`${Url.system.config.chartlib.pie}/sas`, item).then(res => callback(res.data))
  }
  updChartPie(item, callback) {
    this.axios().post(Url.system.config.chartlib.pie, item).then(res => callback(res))
  }
  delChartPie(chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.pie}/${chartNo}`).then(res => callback(res))
  }

  // Radar
  getChartRadar(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.radar}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartRadar (item, callback) {
    this.axios().put(Url.system.config.chartlib.radar, item).then(res => callback(res.data))
  }
  sasChartRadar (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.radar}/sas`, item).then(res => callback(res.data))
  }
  updChartRadar (item, callback) {
    this.axios().post(Url.system.config.chartlib.radar, item).then(res => callback(res))
  }
  delChartRadar (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.radar}/${chartNo}`).then(res => callback(res))
  }
  
  // Skyline
  getChartSkyline(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.skyline}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartSkyline (item, callback) {
    this.axios().put(Url.system.config.chartlib.skyline, item).then(res => callback(res.data))
  }
  sasChartSkyline (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.skyline}/sas`, item).then(res => callback(res.data))
  }
  updChartSkyline (item, callback) {
    this.axios().post(Url.system.config.chartlib.skyline, item).then(res => callback(res))
  }
  delChartSkyline (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.skyline}/${chartNo}`).then(res => callback(res))
  }
  
  // Summary
  getChartSummary(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.summary}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartSummary (item, callback) {
    this.axios().put(Url.system.config.chartlib.summary, item).then(res => callback(res.data))
  }
  sasChartSummary (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.summary}/sas`, item).then(res => callback(res.data))
  }
  updChartSummary (item, callback) {
    this.axios().post(Url.system.config.chartlib.summary, item).then(res => callback(res))
  }
  delChartSummary (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.summary}/${chartNo}`).then(res => callback(res))
  }

  // Table Summary
  getChartTableSummary(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.tsummary}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartTableSummary (item, callback) {
    this.axios().put(Url.system.config.chartlib.tsummary, item).then(res => callback(res.data))
  }
  sasChartTableSummary (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.tsummary}/sas`, item).then(res => callback(res.data))
  }
  updChartTableSummary (item, callback) {
    this.axios().post(Url.system.config.chartlib.tsummary, item).then(res => callback(res))
  }
  delChartTableSummary (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.tsummary}/${chartNo}`).then(res => callback(res))
  }
  
  // Weekly
  getChartWeekly(chartNo, callback) {
    this.axios().get(`${Url.system.config.chartlib.weekly}/${chartNo}`).then(res => { callback(res.data) })
  }
  putChartWeekly (item, callback) {
    this.axios().put(Url.system.config.chartlib.weekly, item).then(res => callback(res.data))
  }
  sasChartWeekly (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.weekly}/sas`, item).then(res => callback(res.data))
  }
  updChartWeekly (item, callback) {
    this.axios().post(Url.system.config.chartlib.weekly, item).then(res => callback(res))
  }
  delChartWeekly (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.weekly}/${chartNo}`).then(res => callback(res))
  }

  // Customized Charts
  getChartCustom(chartNo, filters, callback) {
    this.axios().get(`${Url.system.config.chartlib.custom}/${chartNo}/${filters}`).then(res => callback(res.data))
  }
  putChartCustom (item, callback) {
    this.axios().put(Url.system.config.chartlib.custom, item).then(res => callback(res.data))
  }
  sasChartCustom (item, callback) {
    this.axios().put(`${Url.system.config.chartlib.custom}/sas`, item).then(res => callback(res.data))
  }
  updChartCustom (item, callback) {
    this.axios().post(Url.system.config.chartlib.custom, item).then(res => callback(res))
  }
  delChartCustom (chartNo, callback) {
    this.axios().delete(`${Url.system.config.chartlib.custom}/${chartNo}`).then(res => callback(res))
  }
}
