import Defs from '../../../../includes/primitives/Color_Defs'
import Data from '../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,

  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.ChartType', this.ChartType)
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('Object.keys(this.Note).length', Object.keys(this.Note).length)
      // console.log('this.Status.length', this.Status.length)
      // console.log('this.Border.length', this.Border.length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 &&
        Object.keys(this.Radar).length > 0
      )
    },

  },



  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))

      for(let n=1; n <= this.Radar.chart.data; n++) {
        let getColor = this.Radar.area.fColor[n-1]
        if (getColor.substr(0,1) != '#' && getColor != '' && getColor != 'transparent') {
          Defs.setDefs_Gradients(this.svg, 1, 'LinearA9', [getColor], [`${getColor}${n}`, 0.5, 0.2, 0.5, 1], this.localId)
        }
      }

    },

    
    
    
  }
}