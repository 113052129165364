import * as d3 from 'd3'
import __M from 'moment'

export default {
  methods: {
    Draw_Table() {
      if(this.table.display != 'Y') return

      let dataTable = this.svg
      .append('g')
      .attr('class', 'table_form')
      .attr('transform', `translate(
        ${this.Canvas.CanvasChartX},
        ${this.Canvas.CanvasChartY + this.axisYHeight + this.table.gapFromChart}
      )`)

      // reset coordinates by timeline scale
      this.table.headers.forEach((h_, i) => { h_.x = this.table.cellWidth * i })
      this.table.timeline = {}
      this.table.timeline.week = this.timeline.week
      this.table.timeline.month = this.timeline.month
      this.table.timeline.year = this.timeline.year
      this.table.timeline.scale = this.timeline.scale
      this.DataTable_Timeline(dataTable, this.dataSet, this.table)
    }, 
    putTableForm() {
      let dataTable = this.svg
      .append('g')
      .attr('class', 'table_form')
      .attr('transform', `translate(
        ${this.Canvas.CanvasChartX},
        ${this.Canvas.CanvasChartY + this.axisYHeight + this.table.gapFromChart}
      )`)

      // Horizontal Line
      dataTable
      .selectAll('path')
      .data(this.Queries.SQL1)
      .enter()
      .append('path')
      .attr('d', (_, i) => `M0,${this.table.lineHeight  * i}H${this.axisXWidth}`)
      .attr('stroke', '#757575')
      .attr('stroke-width', 0.5)
      
      // // Vertical Line - It's not working completely, why??? 
      // dataTable
      // .selectAll('path')
      // .data(this.dataXTimelineValues)
      // .enter()
      // .append('path')
      // .attr('d', d => {
      //   console.log(d, this.scaleXTimeline(d))
      //   return `M${this.scaleXTimeline(d)},0V${this.table.height}`
      // })
      // .attr('stroke', '#757575')
      // .attr('stroke-width', 0.5)

      // Vertical Line
      this.dataXTimelineValues.forEach(v_ => {
        dataTable
        .append('path')
        .attr('d', d => `M${this.scaleXTimeline(v_)},0V${this.table.height}`)
        .attr('stroke', '#757575')
        .attr('stroke-width', 0.5)
      })
    },
    putTableValues() {
      let dataTable = this.svg
      .append('g')
      .attr('class', 'table_text_value')
      .attr('transform', `translate(
        ${this.Canvas.CanvasChartX},
        ${this.Canvas.CanvasChartY + this.axisYHeight + this.table.gapFromChart + 0.5}
      )`)
      
      // Line Titles
      dataTable
      .selectAll('text')
      .data(this.Queries.SQL1)
      .enter()
      .append('text')
      .attr('transform', (_, i) => `translate(-80,${this.table.lineHeight  * i + 10})`)
      .attr('fill','#757575')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .style('font-family','roboto')
      .style('font-size', 9)
      .text(d => d.text)

      // Month Text & Timeline Seperator for the Month
      dataTable
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 5)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.style.month.tSize)
      .attr('fill', this.timeline.style.month.tColor)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .text(d => d.name.toUpperCase())

    //   dataTable
    //   .append('g')
    //   .selectAll('path')
    //   .data(this.timeline.month)
    //   .enter()
    //   .append('path')
    //   .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 20 V 161`)
    //   .attr('stroke', (d,i) => {
    //     if (i == this.timeline.month.length-1) return '#757575'; else return '#bcbcbc'
    //   })
    //   .attr('stroke-width', 0.5)
    
    //   // Weeks Text & Timeline Seperator for the Week
    //   dataTable
    //   .append('g')
    //   .selectAll('text')
    //   .data(this.timeline.week)
    //   .enter()
    //   .append('text')
    //   .attr('x', d => this.timeline.scale(d.mDate))
    //   .attr('y', 23)
    //   .style('font-family', this.timeline.font)
    //   .style('font-size', this.timeline.style.week.tSize)
    //   .attr('fill', '#bababa')
    //   // .attr('fill', this.timeline.style.week.tColor)
    //   .attr('text-anchor', 'middle')
    //   .attr('alignment-baseline', 'hanging')
    //   .text(d => __M(d.eDate).week())

    //   dataTable
    //   .append('g')
    //   .selectAll('path')
    //   .data(this.timeline.week)
    //   .enter()
    //   .append('path')
    //   .attr('d', (d, i) => `M${this.timeline.scale(d.eDate)}, 20 V 161`)
    //   .attr('stroke', this.timeline.style.week.sColor)
    //   .attr('stroke-width', 0.5)
    // },
    // putTableValues() {
    //   if(this.chartMounted) {
    //     d3.select(`#${this.localId}`).select('.table_values').remove()
    //     if(!this.Queries.SQL3 || this.Queries.SQL3.length === 0) return
    //   }

    //   let dataTable = this.SkylineCharts
    //   .append('g')
    //   .attr('class', 'table_values')
    //   .attr('transform', `translate(${this.timeline.baseX},${this.timeline.baseY + 60})`)

    //   // Group of all values
    //   dataTable
    //   .append('g')
    //   .selectAll('g')
    //   .data(this.timeline.week)
    //   .enter()
    //   .append('g')
    //   .attr('transform', (_, i) => `translate(${this.timeline.weekSize * i}, 45)`)
    //   .selectAll('text')
    //   .data(d => {
    //     let data_ = this.Queries.SQL3.find(d___ => __M(d.eDate).format('YYYY-MM-DD') == d___[this.timelineKeyName])
    //     if(!data_) return []
    //     return this.table.values.map((v_, j) => ({
    //       y: this.labelYGap * j,
    //       tColor: v_.tColor || '#757575',
    //       value: v_.colName == 'A_CUM_PG' && __M(d.eDate).format('YYYY-MM-DD') > this.Queries.SQL2[0].CDATE ? 0 : data_[v_.colName]
    //     }))
    //   })
    //   .enter()
    //   .append('text')
    //   .attr('transform', d => `translate(${this.timeline.weekSize-2},${d.y})`)
    //   .attr('font-family', 'roboto')
    //   .attr('font-size', 9)
    //   .attr('fill', d => d.tColor)
    //   .attr('text-anchor', 'end')
    //   .attr('alignment-baseline', 'middle')
    //   .text(d => d.value ? Number(d.value).toLocaleString() : '')

    //   // this.Queries.SQL2.forEach((d, i)=> {
    //   //   drawY = 45

    //   //   // Plan Weekly
    //   //   dataTable
    //   //   .append('text')
    //   //   .attr('x', drawX + (this.timeline.weekSize - 3))
    //   //   .attr('y', drawY)
    //   //   .attr('font-family', 'roboto')
    //   //   .attr('font-size', 10)
    //   //   .attr('fill', '#757575')
    //   //   .attr('text-anchor', 'end')
    //   //   .attr('alignment-baseline', 'middle')
    //   //   .text(() => {
    //   //     if (i == 0) {
    //   //       d.value
    //   //      } else {
    //   //        return (d.value - this.timeline.plan[i-1].value).toFixed(1)
    //   //      }
    //   //   })

    //   //   drawX += this.timeline.weekSize
    //   // })

    }
  }
}
