import * as d3 from 'd3'

export default {
  methods: {
    link_Data(d) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible 

      let type_ = ''
      let col_ = ''

      if (d.type !== '') {
        type_ = `JSON${d.type}`
      } else {
        type_ = 'request'
      }

      if (d.type == 'PIP') {
        if (d.col == 'ERE') col_ = 'ERECTED'
        else if (d.col == 'TEST') col_ = 'TESTED'
        else if (d.col == 'FLUSH') col_ = 'FLUSHED'
        else if (d.col == 'REIN') col_ = 'REINST'
      }
      
      if (d.val == 'TBC') return
      
      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(`${type_}`, dataSource, this.ColumnProps)
      if(!request_) return

      // set for filterString 
      let filterString_ = ''

      if (this.summaryTop[0].title == 'Piping (Dia-inch)' && col_ == 'ERECTED') {
        if (d.ref == 'REM') {
          filterString_ += `[${col_}_WK] IS NULL`
        } else if (d.ref == 'ACT') {
          filterString_ += `[${col_}_WK] IS NOT NULL`
        } else if (d.ref == '4WA') {
          filterString_ += `[${col_}_WK] = DATEADD(ww, -4, '${this.cutoff}')`
        } else if (d.ref == '3WA') {
          filterString_ += `[${col_}_WK] = DATEADD(ww, -3, '${this.cutoff}')`
        } else if (d.ref == '2WA') {
          filterString_ += `[${col_}_WK] = DATEADD(ww, -2, '${this.cutoff}')`
        } else if (d.ref == '1WA') {
          filterString_ += `[${col_}_WK] = DATEADD(ww, -1, '${this.cutoff}')`
        } else if (d.ref == 'TW') {
          filterString_ += `[${col_}_WK] = '${this.cutoff}'`
        }
      } else {
        if (d.ref == 'REM') {
          filterString_ += `[${col_}_WK] IS NULL`
        } else if (d.ref == 'ACT') {
          filterString_ += `[${col_}_WK] IS NOT NULL`
        } else if (d.ref == '4WA') {
          filterString_ += `[${col_}_WK] = DATEADD(ww, -4, '${this.cutoff}')`
        } else if (d.ref == '3WA') {
          filterString_ += `[${col_}_WK] = DATEADD(ww, -3, '${this.cutoff}')`
        } else if (d.ref == '2WA') {
          filterString_ += `[${col_}_WK] = DATEADD(ww, -2, '${this.cutoff}')`
        } else if (d.ref == '1WA') {
          filterString_ += `[${col_}_WK] = DATEADD(ww, -1, '${this.cutoff}')`
        } else if (d.ref == 'TW') {
          filterString_ += `[${col_}_WK] = '${this.cutoff}'`
        }
      }

      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

  }
}