export default {
  computed: {
    loading: {
      get() { return this.$store.state.$g.loading },
      set(val) { 
        if(val === false) setTimeout(() => { this.$store.commit("$g/setLoading", val) }, 100)
        else this.$store.commit("$g/setLoading", val)
      }
    },
  }
}