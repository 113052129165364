import Defs from '../../../../includes/primitives/Color_Defs'
import Data from '../../../../includes/primitives/Color_Data'
import * as d3 from 'd3'

export default {
  methods: {
    applyColorDefs() {
      let dataLength = this.DataItems.length
      if (dataLength === 0) return

      let colorDefContainer = this.svg.append('g').attr('class', '_gradation_effect_color_defines__')

      Defs.setDefs_Reflections(colorDefContainer, this.localId)
      Defs.setDefs_Shadows(colorDefContainer, this.localId)

      if (this.ChartType == 'Embossed') this.setColorDefsEmbossed(colorDefContainer, dataLength)
      else if (this.ChartType == 'Cylinder') this.setColorDefsCylinder(colorDefContainer, dataLength)
      else if (this.ChartType == 'Tube') this.setColorDefsTube(colorDefContainer, dataLength)
      else this.setColorDefsBox(colorDefContainer, dataLength)
    },
    setColorDefsEmbossed(selection, len) {
      Defs.setDefs_Gradients(selection, len, this.SharedColorSetType, this.SharedColorSet, ['', 0, 0.5, 1, 0.5], this.localId)

      let Shadow = selection
        .append('defs') // Tube Color (Left)
        .append('radialGradient')
        .attr('id', `${this.localId}__Shadow`)
      Shadow.append('stop')
        .attr('stop-color', '#a4a4a4')
        .attr('offset', '0.00')
        .attr('stop-opacity', '1')
      Shadow.append('stop')
        .attr('stop-color', '#ffffff')
        .attr('offset', '1.00')
        .attr('stop-opacity', '0.37')
    },
    setColorDefsCylinder(selection, len) {
      Defs.setDefs_Gradients(selection, len, 'LinearA2', this.SharedColorSet, ['', 0, 0.5, 1, 0.5], this.localId)
      Defs.setDefs_Gradients(selection, len, this.SharedColorSetType, this.SharedColorSet, ['', 0, 0.5, 1, 0.5], this.localId)
    },
    setColorDefsTube(selection, len) {
      Defs.setDefs_Gradients(selection, len, 'LinearA2', this.SharedColorSet, ['', 0, 0.5, 1, 0.5], this.localId)
      Defs.setDefs_Gradients(selection, len, this.SharedColorSetType, this.SharedColorSet, ['', 0, 0.5, 1, 0.5], this.localId)

      // ------------------------------------------------------------------------------------- Based Cylinder
      let TubeBase = selection
        .append('defs') // Tube Color (Left)
        .append('linearGradient')
        .attr('id', `${this.localId}__TubeBase`)
      TubeBase.append('stop')
        .attr('stop-color', '#a9adb0')
        .attr('offset', '0.00')
        .attr('stop-opacity', '1')
      TubeBase.append('stop')
        .attr('stop-color', '#f6f6f6')
        .attr('offset', '0.60')
        .attr('stop-opacity', '1')
      TubeBase.append('stop')
        .attr('stop-color', '#f6f6f6')
        .attr('offset', '1.00')
        .attr('stop-opacity', '1')

      // -------------------------------------------------------------------------------------- Tube Cylinder
      let TubeBox = selection
        .append('defs') // Tube Color
        .append('linearGradient')
        .attr('id', `${this.localId}__TubeBox`)
      TubeBox.append('stop')
        .attr('stop-color', '#b4b4b4')
        .attr('offset', '0.00')
        .attr('stop-opacity', '0.50')
      TubeBox.append('stop')
        .attr('stop-color', '#f2f2f2')
        .attr('offset', '0.30')
        .attr('stop-opacity', '0.15')
      TubeBox.append('stop')
        .attr('stop-color', '#fff')
        .attr('offset', '0.60')
        .attr('stop-opacity', '0.01')
      TubeBox.append('stop')
        .attr('stop-color', '#f2f2f2')
        .attr('offset', '0.80')
        .attr('stop-opacity', '0.15')
      TubeBox.append('stop')
        .attr('stop-color', '#b4b4b4')
        .attr('offset', '1.00')
        .attr('stop-opacity', '0.50')

      let TubeCap = selection
        .append('defs') // Tube Color (Left)
        .append('linearGradient')
        .attr('id', `${this.localId}__TubeCap`)
      TubeCap.append('stop')
        .attr('stop-color', '#efefef')
        .attr('offset', '0.00')
        .attr('stop-opacity', '0.90')
      TubeCap.append('stop')
        .attr('stop-color', '#f2f2f2')
        .attr('offset', '0.41')
        .attr('stop-opacity', '0.90')
      TubeCap.append('stop')
        .attr('stop-color', '#ececec')
        .attr('offset', '0.59')
        .attr('stop-opacity', '0.90')
      TubeCap.append('stop')
        .attr('stop-color', '#e8e8e8')
        .attr('offset', '1.00')
        .attr('stop-opacity', '0.90')
    },
    setColorDefsBox(selection, len) {
      Defs.setDefs_Gradients(selection, len, 'LinearA2', this.SharedColorSet, ['a', 0.5, 1, 0.5, 0], this.localId)
      Defs.setDefs_Gradients(selection, len, 'LinearA2', this.SharedColorSet, ['b', 0, 0, 1, 1], this.localId)
      Defs.setDefs_Gradients(selection, len, 'LinearA2', this.SharedColorSet, ['c', 1, 1, 0.5, 0], this.localId)
      Defs.setDefs_Gradients(selection, len, 'LinearA2', this.SharedColorSet, ['d', 0, 1, 1, 0], this.localId)
    },
    drawGrid() {
      this.drawGridLines()
      this.putLevelNUnit()
      this.drawYAxis()
    },
    drawGridLines() {
      if (this.Axis.AxisYGridDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      this.chartArea
        .append('g')
        .attr('class', '_y_axis_grid_line__')
        .selectAll('path')
        .data(this.scaleYInfo.tickValues)
        .enter()
        .append('path')
        // Transform a data value to Y coordinate.
        .attr('d', tickValue_ => `M 0,${this.scaleYInfo.scale(tickValue_)} H ${this.Canvas.CanvasChartWidth}`)
        .style('stroke', this.Axis.AxisYGridColor)
        .style('stroke-width', this.Axis.AxisYGridWeight)
    },
    putLevelNUnit() {
      if (this.Axis.AxisXLineDisplay != 'Y') return

      let levelBox = this.chartArea
        .append('g')
        .attr('class', '_y_axis_level_unit__')
        .selectAll('g')
        .data(this.scaleYInfo.tickValues)
        .enter()
        // Append every single box for a line value & unit. -------------------
        // Inited X, Y coordinate of the group is (0, 0), and its width
        // is 0 too. So, at the current time of that the '-this.Axis.AxisLevelSpace'
        // is applied to the X, X coordinate is '-this.Axis.AxisLevelSpace'.
        // And then the width will be extended toward left by the child
        // element's attribute ('text-anchor', 'end'). It doesn't need to
        // recalculate the X coordinate of the every single boxes.
        .append('g')
        .attr('transform', tickValue_ => `translate(${-this.Axis.AxisXLevelSpace},${this.scaleYInfo.scale(tickValue_)})`)

      // Append Unit
      levelBox
        .append('text')
        .attr('id', (_, i) => `${this.localId}__Level_Unit_${i}`)
        .attr('class', this.Axis.AxisYLevelStyle)
        .style('font-size', this.Axis.AxisXLevelUnitSize)
        .style('font-family', this.Axis.AxisXLevelFont)
        .style('font-weight', this.Axis.AxisXLevelWeight)
        .attr('fill', this.Axis.AxisXLevelColor)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'end')
        .text(this.Axis.AxisXLevelUnitFormat)

      // Append Unit
      levelBox
        .append('text')
        .attr('x', (_, i) => -Math.round(this.getNodeElValue(`#${this.localId}__Level_Unit_${i}`, 'width') * 1.1))
        .attr('class', this.Axis.AxisYLevelStyle)
        .style('font-size', this.Axis.AxisXLevelSize)
        .style('font-family', this.Axis.AxisXLevelFont)
        .style('font-weight', this.Axis.AxisXLevelWeight)
        .attr('fill', this.Axis.AxisXLevelColor)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', 'end')
        .text(tickValue_ => Math.round(tickValue_))

    },
    drawYAxis() {
      if (this.Axis.AxisYLevelPosition == 'left')  var axisY = d3.axisLeft(this.scaleYInfo.scale)
      else axisY = d3.axisRight(this.scaleYInfo.scale)

      axisY
      .tickValues([])
      .tickSize(0)
      .tickPadding(0)

      if (this.Axis.AxisYLineDisplay != 'N') {
        this.svg.select(`#chart_area___${this.localId}`)
          .append('g')
          .attr('class', 'axisLine')
          .call(axisY)

        this.svg.select('.axisLine > path')
          .style('stroke-width', this.Axis.AxisYLineWeight)
          .style('stroke', this.Axis.AxisYLineColor)
      }

      if (this.Axis.AxisYLevelDisplay != 'N') {
        let levelBox = this.svg
          .select(`#chart_area___${this.localId}`)
          .append('g')
          .attr('class', '_y_axis_level_unit__')
          .selectAll('g')
          .data(this.scaleYInfo.tickValues)
          .enter()
          .append('g')
          .attr('transform', tickValue_ => `translate(${this.Axis.AxisYLevelPosition == 'left' ? -this.Axis.AxisYLevelSpace : this.Axis.AxisYLevelSpace + this.Axis.AxisYLevelSize * 1.4 + this.Axis.AxisYLevelUnitSize}, ${this.scaleYInfo.scale(tickValue_)})`)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('fill', this.Axis.AxisYLevelColor)
          .attr('class', this.Axis.AxisYLevelStyle)

        // Append Text
        levelBox
          .append('text')
          .attr('x', -this.Axis.AxisYLevelUnitSize * 0.7)
          .style('font-size', this.Axis.AxisYLevelSize)
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(tickValue_ => Math.round(tickValue_))
          .style('font-family', this.Axis.AxisYLevelFont)

        // Append Unit
        levelBox
          .append('text')
          .attr('id', (_, i) => `${this.localId}__Level_Unit_${i}`)
          .style('font-size', this.Axis.AxisYLevelUnitSize)
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(this.Axis.AxisYLevelUnitFormat)
          .style('font-family', this.Axis.AxisYLevelFont)
      }
    }
  }
}
