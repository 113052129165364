import Api from './api'
import Url from './request.url'
import { SystemApi } from './'

export default class SystemCodeApi extends Api {

  constructor() {
    super()
    this.systemApi = new SystemApi()
  }

  chartOptions (catCode, chartType, callback) {
    this.axios().get(`${Url.system.code.chartoptions}/${catCode}/${chartType}`).then(res => callback(res.data))
  }
  datatableOptions (catCode, callback) {
    this.axios().get(`${Url.system.code.datatableoptions}/${catCode}`).then(res => callback(res.data))
  }
  dashboardOptions (catCode, callback) {
    this.axios().get(`${Url.system.code.dashboardoptions}/${catCode}`).then(res => callback(res.data))
  }

  companies (sysadmin, callback) {
    this.axios().get(`${Url.system.code.companies}/${sysadmin}`).then(res => callback(res.data))
  }
  // compUsers (compName, permit,  callback) {
  //   this.axios().get(`${Url.system.code.compusers}/${compName}/${permit}`).then(res => callback(res.data))
  // }
  compUsers(params) {
    return new Promise(resolve => {
      this.axios().post(Url.system.code.compusers, params).then(res => resolve(res.data))
    })
    // return new Promise(resolve => {
    //   this.axios().get(`${Url.system.code.compusers}/${compName}/${permit}`).then(res => { resolve(res.data) })
    // })
  }

  department (callback) {
    this.axios().get(Url.system.code.department).then(res => callback(res.data))
  }

  iconGroup (callback) {
    this.systemApi.getCodeIndex('ICON', callback)
  }
  permissionGroup (callback) {
    this.systemApi.getCodeIndex('PERMISSION', callback)
  }
  position (callback) {
    this.axios().get(Url.system.code.position).then(res => callback(res.data))
  }
  serviceStatus (callback) {
    this.systemApi.getCodeIndex('SVC_STATUS', callback)
  }
  userApproval (callback) {
    this.systemApi.getCodeIndex('APPROVAL', callback)
  }
  subItemPhase (callback) {
    this.axios().get(Url.system.code.subitemphase).then(res => callback(res.data))
  }
  subItemGroup (phaseCode, callback) {
    this.axios().get(`${Url.system.code.subitemgroup}/${phaseCode}`).then(res => callback(res.data))
  }
  subItemItem (phaseCode, groupCode, callback) {
    this.axios().get(`${Url.system.code.subitemitem}/${phaseCode}/${groupCode}`).then(res => callback(res.data))
  }
  sysColumns (dbName, tableName, callback) {
    this.axios().get(`${Url.system.code.sys.columns}/${dbName}/${tableName}`).then(res => callback(res.data))
  }
  sysColumnsFromQuery (params, callback) {
    this.axios().post(Url.system.code.sys.columnsq, params).then(res => callback(res.data))
  }
  sysTables (dbName, schemaName, callback) {
    this.axios().get(`${Url.system.code.sys.tables}/${dbName}/${schemaName}`).then(res => callback(res.data))
  }
  sysDatabases (callback) {
    this.axios().get(`${Url.system.code.sys.databases}`).then(res => callback(res.data))
  }
  galleryCodeOptions(callback) {
    this.axios().get(`${Url.system.code.galleryoptions}`).then(res => callback(res.data))
  }
  getNavList (callback) {
    this.axios().get(`${Url.system.code.navlist}`).then(res => callback(res))
  }
  getDataSourceList (callback) {
    this.axios().get(`${Url.system.code.datasourcelist}`).then(res => callback(res))
  }
  getCommentList (callback) {
    this.axios().get(`${Url.system.code.commentlist}`).then(res => callback(res))
  }
  getNotification (callback) {
    this.axios().get(`${Url.system.code.getNotification}`).then(res => callback(res))
  }
  getCompomentData (callback) {
    this.axios().get(`${Url.system.code.getCompomentData}`).then(res => callback(res))
  }
  getUrlData (callback) {
    this.axios().get(`${Url.system.code.getUrlData}`).then(res => callback(res))
  }
  getComment (callback) {
    this.axios().get(`${Url.system.code.getComment}`).then(res => callback(res))
  }
  putNotification (params, callback) {
    this.axios().post(Url.system.code.putNotification, params).then(res => callback(res))
  }
  upNotification (params, callback) {
    this.axios().post(Url.system.code.upNotification, params).then(res => callback(res))
  }
  delNotification (params, callback) {
    this.axios().post(Url.system.code.delNotification, params).then(res => callback(res))
  }
}