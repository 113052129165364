import Api from './api'
import Url from './request.url'

export default class PdfApi extends Api {

  constructor() {
    super()
  }

  create(params) {
    // , {
    //   // responseType: 'blob',
    //   responseType: 'arraybuffer',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Accept': 'application/octet-stream'
    //   }
    // }
    return this.axios().post(Url.pdf.create, params).then(
      res => new Promise(resolve => {
        resolve(res.data)
      })
    )
  }
}