export default {
  data: () => ({
    isMilestoneDataValid: true,
    milestoneArea: null,
    milestoneAreaX: null,
    milestoneAreaY: null,
    milestoneData: [],
    milestoneLineWeight: null,
    milestoneLineHeight: null,
    milestoneXCPoint: 0,
    scaleMilestone: Function,
  }),
  methods: {
    setMilestoneValues(mValues) {
      this.milestoneAreaX = mValues.x
      this.milestoneAreaY = mValues.y
      this.milestoneAreaWeight = mValues.w
      this.milestoneLineHeight = mValues.h
      this.milestoneXCPoint = mValues.c || 0
      this.scaleMilestone = mValues.scaleX

      this.isMilestoneDataValid = this.checkMilestoneDataValid()
    },
    checkMilestoneDataValid() {
      if(!this.Values.milestone || this.Values.milestone.length === 0) return false

      let msData_ = this.Values.milestone.map(data_ => {
        let scalePos_ = this.scaleMilestone(this.parseDate(data_.CDATE))
        if(scalePos_ < 15 || scalePos_ > this.milestoneAreaWeight - 15) return null
        return data_
      })
      msData_ = msData_.filter(data_ => !!data_)

      if(msData_.length === 0) return false

      this.milestoneData = msData_;
      return true
    }
  }
}
