import Defs from '../../../../includes/primitives/Color_Defs'

export default {
  methods: {
    applyColorDefs() {
      let dataLength = this.DataItems.length
      if(dataLength === 0) return

      this.SharedColorSet = this.SharedColorSet
      this.SharedColorSetType = this.Circle.CircleColorType

      Defs.setDefs_Gradients(this.svg, this.DataItems.length, this.Circle.CircleColorType, this.SharedColorSet, ['', -0.5, 0.5, 1.5, 0.5], this.localId)
      let Shadow = this.svg
      .append('defs') // Tube Color (Left)
      .append('radialGradient').attr('id', `${this.localId}__Shadow`)
      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')

      
      
    },
  }
}
