import * as d3 from 'd3'
import Filter from '../../../../includes/primitives/Filter_Defs'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  data: ()=>({
    zoomMousePosition: 60
  }),
  methods: {
    draw_Toolbar() {
      Filter.setDefs_DropShadow(this.svg, `${this.localId}__DropShadow`, 1 , 0.1, 1, 1)
      
      let ToolBoxWidth = 250
      let ToolBoxHeight = 350
      let self_ = this
      let toolbox = this.svg
      .append('g')
      .attr('id', `toolbox___${this.localId}`)
      .attr('transform', `translate(${5},${5})`)
      
      this.svg.select(`#toolbox___${this.localId}`)
      // .attr('transform', `translate(${5},${5})`)
      .attr('transform', `translate(${30},${30})scale(.01)`)
      
      // ToolBox Window
      toolbox 
      .append('rect') 
      .attr('x', 5)
      .attr('y', 5)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', ToolBoxWidth)
      .attr('height', 65)
      .attr('fill', '#fff')
      .attr('stroke', '#E0E0DF')
      .attr('stroke-width', 1)
      .style('filter', `url(#${this.localId}__DropShadow)`)
      
      toolbox 
      .append('rect') 
      .attr('x', 5)
      .attr('y', 60)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', ToolBoxWidth)
      .attr('height', ToolBoxHeight)
      .attr('fill', '#F5F5F6')
      .attr('stroke', '#E0E0DF').attr('stroke-width', 1).style('filter', `url(#${this.localId}__DropShadow)`)
      
      // ----------------------------------------------------------------- ToolBox Header
      // Toolbar Shadow Line
      let lineShadow = this.svg
      .append('defs') 
      .append('radialGradient').attr('id', `${this.localId}__lineShadow`).attr('cx', 0.5).attr('cy', 0.5).attr('r', 0.01).attr('gradientTransform', 'translate(-19.164 -26.218) scale(39.329 53.436)')
      lineShadow.append('stop').attr('stop-color', '#7a7a7a').attr('offset', 0).attr('stop-opacity', 0)
      lineShadow.append('stop').attr('stop-color', '#fff').attr('offset', 1).attr('stop-opacity', 0.5)
      
      let scrollBar = this.svg
      .append('defs') 
      .append('linear-gradient').attr('id', 'scrollBar').attr('x1', 0).attr('y1', 0.5).attr('x2', 1).attr('y2', 0.5)
      scrollBar.append('stop').attr('stop-color', '#5bade1').attr('offset', 0.102).attr('stop-opacity', 1)
      scrollBar.append('stop').attr('stop-color', '#91d9ff').attr('offset', 1).attr('stop-opacity', 1)

      toolbox.append('ellipse') .attr('cx', ToolBoxWidth/2+7).attr('cy', 65).attr('rx', 117).attr('ry', 3).attr('opacity', .7).attr('height', 10).attr('fill', `url(#${this.localId}__lineShadow)`)
      toolbox.append('rect') .attr('x', 5.5).attr('y', 55).attr('width', ToolBoxWidth-1).attr('height', 10).attr('fill', '#fff')
      toolbox.append('line') .attr('x1', 5.5).attr('y1', 65).attr('x2', ToolBoxWidth+5).attr('y2', 65).attr('stroke', '#bcbcbc').attr('stroke-width', .25)


      // ----------------------------------------------------------------- Tool Box Title
      toolbox.append('text') 
      .attr('transform', `translate(${40},${27})`).style('font-family', 'roboto').style('font-weight', 400).style('font-size', 15) .attr('fill', '#000')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('CHART OPTIONS')


      // ----------------------------------------------------------------- Scroll Bar for Scaling Skyline Chart
      let zoomScrollG = toolbox
      .append('g')
      .attr('class', 'zoomScrollG')

      zoomScrollG
      .append('text')
      .attr('transform', `translate(${45},${53})`)
      .style('font-family', 'roboto')
      .style('font-weight', 300)
      .style('font-size', 8) 
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle')
      .text('50%')

      zoomScrollG
      .append('text')
      .attr('transform', `translate(${225},${53})`)
      .style('font-family', 'roboto')
      .style('font-weight', 300)
      .style('font-size', 8) 
      .attr('fill', '#757575')
      .attr('text-anchor', 'middle')
      .text('200%')

      zoomScrollG
      .append('rect')
      .attr('x', 60)
      .attr('y', 45)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 150)
      .attr('height', 9)
      .attr('fill', '#e0e0e0')
      .attr('stroke', '#E0E0DF')
      .attr('stroke-width', 0.2)
      
      // Zoom the canvas & chart-area ----------------------------- 
      this.zoomScale = d3.scaleLinear()
      .domain([60, 120, 180])
      .range([0.5, 1, 2])

      let startScrollX_ = 0
      let startEventX_ = 0
      let zoomHandler = d3.drag()
      .on('start', (_, i, a) => {
        startScrollX_ = d3.select(a[i]).attr('x')
        startEventX_ = d3.event.x
      })
      .on('drag', (_, i, a) => {
        let moved_ = Number(startScrollX_) + Number(d3.event.x - startEventX_)
        let x_ = moved_ <= 60 ? 60 : (moved_ >= 180 ? 180 : moved_)
        let scale_ = this.zoomScale(x_)

        this.zoomTransform = {
          x: 0,
          y: 0,
          // For the chart-area resizing
          w: this.zoomTransformInit.w * scale_,
          h: this.zoomTransformInit.h * scale_
        }

        // Resize Canvas
        d3.select(`#${this.localId}_svg`).attr("width", this.zoomTransform.w + 200)
        d3.select(`#${this.localId}_svg`).attr("height", this.zoomTransform.h + 500)
        d3.select(`._canvas_border_background__${this.localId}`).attr("width", this.zoomTransform.w + 200)
        d3.select(`._canvas_border_background__${this.localId}`).attr("height", this.zoomTransform.h + 500)
        // Resize Chart-Area
        d3.select(`#svg_inline__chart_area__${this.localId}`).attr('width', this.zoomTransform.w)
        d3.select(`#svg_inline__chart_area__${this.localId}`).attr('height', this.zoomTransform.h)
        // Reset Scroll X
        d3.select(a[i]).attr('x', x_)
      })
      // -----------------------------------------------------------

      toolbox
      .append('rect')
      .attr('id', `button__scale_chart_area__${this.localId}`)
      .attr('x', 120)
      .attr('y', 45)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 30)
      .attr('height', 9)
      .attr('fill', '#44A9DF')
      .style('cursor', 'pointer')
      .call(zoomHandler)

      // ----------------------------------------------------------------- Tool Bax Title
      toolbox.append('text') 
        .attr('x', 20).attr('y', 100).style('font-family', 'roboto').style('font-weight', 400).style('font-size', 11) .attr('fill', '#757575')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Skyline Chart')
      toolbox.append('text') 
        .attr('x', 20).attr('y', 120).style('font-family', 'roboto').style('font-weight', 400).style('font-size', 11) .attr('fill', '#757575')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Line Charts')
      toolbox.append('text') 
        .attr('x', 20).attr('y', 140).style('font-family', 'roboto').style('font-weight', 400).style('font-size', 11) .attr('fill', '#757575')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Milestones')

      toolbox.append('text').attr('x', 115).attr('y', 90)
        .style('font-family', 'roboto').style('font-weight', 300).style('font-size', 8) .attr('fill', '#757575').attr('text-anchor', 'middle').text('0%')
      toolbox.append('text').attr('x', 233).attr('y', 90)
        .style('font-family', 'roboto').style('font-weight', 300).style('font-size', 8) .attr('fill', '#757575').attr('text-anchor', 'middle').text('100%')

      toolbox.append('rect') 
        .attr('x', 115).attr('y', 95).attr('rx', 4).attr('ry', 4).attr('width', 120).attr('height', 7).attr('fill', '#e0e0e0').attr('stroke', '#E0E0DF').attr('stroke-width', 0.2)
      
      toolbox.append('rect') 
        .attr('x', 115).attr('y', 115).attr('rx', 4).attr('ry', 4).attr('width', 120).attr('height', 7).attr('fill', '#e0e0e0').attr('stroke', '#E0E0DF').attr('stroke-width', 0.2)
      
      toolbox.append('rect') 
        .attr('x', 115).attr('y', 135).attr('rx', 4).attr('ry', 4).attr('width', 120).attr('height', 7).attr('fill', '#e0e0e0').attr('stroke', '#E0E0DF').attr('stroke-width', 0.2)
      
      
      let getBarScale = 100/(205-115)

      // Skyline Chart Scrollbar for Opacity
      var skylineData = [{x:205}]
      var skylineBar = toolbox
      .append('g').selectAll('rect').data(skylineData).enter()
      .append('rect').attr('x', 205).attr('y', 95).attr('rx', 4).attr('ry', 4).attr('width', 30).attr('height', 7).attr('fill', '#73CCFB').attr('stroke-width', 0)
      .style('cursor', 'pointer')
      var skylineHandler = d3.drag()
      .on('drag', function() {
        d3.select(this)
        .attr('x', function() {
          if (d3.event.x > 205) return skylineData[0].x = 205
          else if (d3.event.x < 115) return skylineData[0].x = 115
          else {
            d3.select(`#boxes`)
            .attr('opacity', ((d3.event.x - 115)*getBarScale) / 100)
            return skylineData[0].x = d3.event.x
          }
        })
      })
      skylineHandler(skylineBar);

      // Line Chart Scrollbar for Opacity 
      var lineData = [{x:115}]
      var lineBar = toolbox
      .append('g').selectAll('rect').data(lineData).enter()
      .append('rect').attr('x', 115).attr('y', 115).attr('rx', 4).attr('ry', 4).attr('width', 30).attr('height', 7).attr('fill', '#73CCFB').attr('stroke-width', 0)
      .style('cursor', 'pointer')
      var lineHandler = d3.drag()
      .on('drag', function() {
        d3.select(this)
        .attr('x', function() {
          if (d3.event.x > 205) return lineData[0].x = 205
          else if (d3.event.x < 115) return lineData[0].x = 115
          else {
            this.lineGroupOpacity =  ((d3.event.x - 115)*getBarScale) / 100

            d3.select(`#lines_group`)
            .attr('opacity', this.lineGroupOpacity)
            
            return lineData[0].x = d3.event.x
          }
        })
      })
      lineHandler(lineBar);

      // Milestone Chart Scrollbar for Opacity
      var milestoneData = [{x:115}]
      var milestoneBar = toolbox
      .append('g').selectAll('rect').data(milestoneData).enter()
      .append('rect').attr('x', 115).attr('y', 135).attr('rx', 4).attr('ry', 4).attr('width', 30).attr('height', 7).attr('fill', '#73CCFB').attr('stroke-width', 0)
      .style('cursor', 'pointer')
      var milestoneHandler = d3.drag()
      .on('drag', function() {
        d3.select(this)
        .attr('x', function() {
          if (d3.event.x > 205) return milestoneData[0].x = 205
          else if (d3.event.x < 115) return milestoneData[0].x = 115
          else {
            d3.select(`.milestone_area___${this.localId}`)
            .attr('opacity', ((d3.event.x - 115)*getBarScale) / 100)
            return milestoneData[0].x = d3.event.x
          }
        })
      })
      milestoneHandler(milestoneBar);

      // ----------------------------------------------------------------- INPUT BOX 
      toolbox.append('image')
      .attr('xlink:href', require('../../../../src/assets/svg/Search.svg'))
      .attr('x', 50).attr('y', 185).attr('width', 194).attr('height', 46)

      toolbox.append('rect')
      .attr('x', 100).attr('y', 198).attr('width', 120).attr('height', 18).attr('stroke', 'gray').attr('stroke-width', .5).attr('fill', '#fff')

      toolbox.append('text')
      .attr('x', 75).attr('y', 190).attr('fill', 'gray').attr('font-size', 11).text('Search')

      toolbox
      .append("foreignObject")
      .attr('x', 100)
      .attr('y', 198)
      .attr('width', 120)
      .attr('height', 18)
      .append('xhtml:div')
      .append('div')
      .attr('style', 'padding: 2px 5px;')
      .attr('contentEditable', true)
      .on('keyup', (_, i, a) => {
        let inputted_ = d3.select(a[i]).text()
        this.DataItems.forEach(item_ => {
          item_.values.forEach(value_ => {
            if(!value_.name) return
            let selection_ = d3.select(`#n${value_.name}___${this.localId}`)
            if (value_.name.indexOf(inputted_) > -1) selection_.transition().duration(400).attr('opacity', 1)
            else selection_.transition().duration(400).attr('opacity', 0)
          })
        }) 
      })

      // .append('text').attr('class', `search_text___${this.localId}`)
      // .attr('x', 103).attr('y', 203).text('hello').attr('alignment-baseline', 'hanging')
      // .call((selection) => {
      //   selection
      //   .on('mouseover', (_, i, a) => { d3.select(a[i]).style('cursor', 'text') })
      //   .on('mouseout', (_, i, a) => { d3.select(a[i]).style('cursor', 'default') })
      // })




    // d3.select(`#inputFilter_wrapper___${this.localId}`)
      // .attr('style', `display: none;`)
      // .select('input')
      // .on("keyup", (_, i, a) => {
      //   let inputted_ = a[i].value
      //   this.Comp_DataItems.forEach(item_ => {
      //     item_.values.forEach(value_ => {
      //       let selection_ = d3.select(`#n${value_.name}___${this.localId}`)

      //       if (value_.name.indexOf(inputted_) > -1) selection_.transition().duration(400).attr('opacity', 1)
      //       else selection_.transition().duration(400).attr('opacity', 0)
      //     })
      //   }) 
      // })

      // ----------------------------------------------------------------- Tool Box Button
      let toolbar = this.svg
      toolbar
      .append('g')
      .attr('transform', `translate(${20},${20})`)
      .style('cursor', 'pointer')

      .append('image')
      .attr('id', `ToolBtn___${this.localId}`)
      .attr('xlink:href', require('../../../../src/assets/svg/ToolBtn_Off.svg'))
      .attr('x', 0).attr('y', 0).attr('width', 18).attr('height', 18)
      .on('mouseover', function() {d3.select(`#ToolBtn___${this.localId}`).attr('xlink:href', require('../../../../src/assets/svg/ToolBtn_On.svg'))})
      .on('mouseout', function() {d3.select(`#ToolBtn___${this.localId}`).attr('xlink:href', require('../../../../src/assets/svg/ToolBtn_Off.svg'))})
      .on('click', function() {
        if (self_.Toolbar_OnOff == 'N') {
          self_.svg.select(`#toolbox___${self_.localId}`)
          .transition().duration(200)
          .attr('transform', `translate(${5},${5})scale(1)`)
          .transition().duration(35)
          self_.Toolbar_OnOff = 'Y'
        } else {
          self_.svg.select(`#toolbox___${self_.localId}`)
          .transition().duration(200)
          .attr('transform', `translate(${30},${30})scale(.01)`)
          self_.Toolbar_OnOff = 'N'
        }
      })
      
      

    },



  }
}
