import __C from '../../../primitives/_constant_'
import __F from '@/primitives/_function_'
import LocalStorage from '@/services/local.storage/appservice.persistence'
import { ApplicationService } from '@/services'

const _db = new LocalStorage()
const _appService = new ApplicationService()

export default {
  namespaced: true,
  state: {
    // Instant values
    cuscompID: -1,
    dimention: null,
    docID: '',
    type: 'PAGE',   // PAGE | PAGESVG

    /*** stored props in the local storage ***/
    // 'filteredValues' JS Data Structure
    // {
    //   COLUMN_NAME: "<String|Number|Array>",
    //   ...
    // }
    // Example:
    // {
    //   BLOCK:[],
    //   DEL_LEAD:["Rinat Bakirov","In-Tae Song","Zaigul Dossaliyeva"],
    //   MODULE:["42M073","44K156","43M104"],
    //   PE_BLOCK:["6HA","8QC","4HA","1UC"],
    //   RFS:"2020",
    //   SEALIFT:"2019",
    //   YARD:"DSM"
    // }
    filteredValues: {},
    /* ------------------------------------ */

    filterChains: [],
    filterOptions: [],

    fnRequest: () => {},
  },
  mutations: {
    setCuscompId (state, id) { state.cuscompID = id },
    setDocID (state, id) { state.docID = id },
    setType (state, type) { state.type = type },
    setFilterChains (state, values) { state.filterChains = values },
    setFilterOptions (state, values) { state.filterOptions = values },
    setFilteredValues (state, values) { state.filteredValues = values },
    setFnRequest (state, fn) { state.fnRequest = fn },
  },
  getters: {
    filterChains: (state) => {
      if(!state.filterChains || state.filterChains.length == 0) return null
      return state.filterChains
    },
    filterOptions: (state) => {
      if(!state.filterOptions || state.filterOptions.length == 0) return null
      return state.filterOptions
    },
    filteredValues: (state) => {
      if(!state.filteredValues || Object.keys(state.filteredValues).length == 0) return null
      return state.filteredValues
    },
  },
  actions: {
    initService ({ state, rootState, commit, dispatch }) {
      if(
        !rootState[__C.STORE_NAMESPACE.APPLICATION].navState || 
        !rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id
      ) {
        commit('setCuscompId', 0)
        return new Promise(resolve => resolve(null))
      }
      
      // id : nav-menu idx (item || subitem)
      let id___ = rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id
      let docID__ = `CUSCOMP:${state.type}:${id___}`
      commit('setCuscompId', id___)
      commit('setDocID', docID__)

      return new Promise((resolve) => {
        _db.getFilteredValues(docID__).then(values => {
          commit('setFilteredValues', values)
          resolve(id___)
        })
      })
    },
    sendRequest({ state }, type='init') {
      return state.fnRequest(type)
    },
    resetFilterChain({ state }, params) {
      return new Promise((resolve) => {
        resolve(true)
      })
    },
    updateFilteredValues ({ state, commit }, values) { 
      return new Promise((resolve) => {
        _db.updateFilteredValues(state.docID, values).then(() => {
          commit('setFilteredValues', values)
          resolve(true)
        })
        .catch(this.$showError)
      })
    },
  }
}
