
import { ThreedSupportApi } from './api'

export default class ThreedSupportService {
    
  constructor() {
    this.threedSupportApi = new ThreedSupportApi()
  }

  getConnectedUsers() {
    return this.threedSupportApi.getConnectedUsers()
  }
}
