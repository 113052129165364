
import Vue from 'vue'
import __C from '../../primitives/_constant_'

export default {
  namespaced: true,
  state: {
    modals: [],
    tabs: [],

    modalIdx: '',
    modalComponent: '',
    modalIndexs: [],
    layoutAttrs: {}
  },
  mutations: {
    appendModal (state, data) { 
      let found_ = state.modals.findIndex(m_ => m_.id == data.modal.id)
      if(found_ > -1) return
      
      state.modals.push(data.modal)
      state.tabs.push(data.tab)
    },
    appendModalIndexs (state, idx) { state.modalIndexs.push(idx) },
    removeModal (state) { 
      state.modals.pop()
      state.tabs.pop() 
    },
    removeModalIndex (state) { 
      state.modalIndexs.pop()
    },
    removeModalAll (state) { 
      state.modals = []
      state.tabs = []
    },
    updateTabStatus(state, tab) {
      Object.keys(tab.data).forEach(k => {
        if(Object.keys(state.tabs[tab.index]).includes(k)) state.tabs[tab.index][k] = tab.data[k]
        else Vue.set(state.tabs[tab.index], k, tab.data[k])
      })
    },

    setLayoutAttrs (state, attrs) { state.layoutAttrs = attrs },
    setModalIdx (state, idx) { state.modalIdx = idx },
    setModalComponent (state, component) { state.modalComponent = component },

    setModalsComponent(state, data) {
      let find = state.modals.findIndex(m_ => m_.id == data.id)
      state.modals[find].component = data.component
    },
    setModalIndex(state, data) {
      let find = state.modals.findIndex(m_ => m_.id == data.id)
      state.modals[find].idx = data.idx
    }
  },
  getters: {
    exportInfo: (state) => {
      let info_ = {
        name: state.layoutAttrs.name,
        width: state.layoutAttrs.dimention.width,
        height: state.layoutAttrs.dimention.height
      }

      return info_
    },
  },
  actions: {
    modalExist({ state }, id) { 
      return state.modals.findIndex(m_ => m_.id == `#${id}`) > -1 
    },
    
    createAction({ state, getters }) {
      return new Promise((resolve) => {
          let action__ = JSON.stringify({
            component: state.modalComponent,
            filter: {
              filters: getters[`${state.childId}/filteredValuesTrimed`],
              iFilters: { 
                filterString: '',
                inputFilter: ''
              }
            },
            target: {
              code: 'Modal',
              type: __C.PAGE_COMPONENT.REQUEST_TYPE_EXPORT,
              id: state.modalIdx,
              no: '',
            }
          })

          resolve(action__)
      })
    },
  }
}
