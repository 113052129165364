<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core  from './includes/mixins/importer' // Global & Local Mixins

export default {
  name: "j-page-svg--information-guide-sectors",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  
    
    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Queries: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    
    callback: { type: Object, default: () => ({}) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      if (!this.ready2draw) return
      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      this.complete()
    },
    redraw() {
      if(this.innerSvg) this.innerSvg.remove()

      this.setDefaultValues()
      this.drawSectorFromSvg()
      //this.drawSharedInfo()

      let el = d3.select(`#${this.localId} .sei_l1_code`)
      el.text(this.seiCodes.l1)

      el = d3.select(`#${this.localId} .sei_subtitle`)
      // SEI code subtitle (L1 description)
      let data = this.DataItems[0].seiCodeL1.find(d => d.SEICODE_L1 == this.seiCodes.l1)
      if(!data) el.text('')
      else el.text(data.SEICODE_L1_DESC_E)
      
      this.complete()
    },
    drawChart() {
      this.drawControlInfo()
      this.drawSectorFromSvg()
      //this.drawSharedInfo()

      //Toggle status for pdf export 
      if(this.selectedItems) {
        if(this.selectedItems.lang) {
          this.Toggle.status = this.selectedItems.lang
          this.toggle_Status(this.Toggle.status)
        }
      }
    },
  }
}
</script>
