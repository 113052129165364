<template>
  <div class="service_subsystem_comment_contents">
    <div class="service_subsystem_comment_contents_wrapper opened">
      <div class="lnb">
      </div>
      <div class="content">
        <a v-ripple class="btn-add" title="add new comment" @click="onWrite(0)">
          <i class="mdi mdi-plus-circle-outline"></i>
        </a>

        <div>
          <div class="tab-header">
            <span class="tab-title">Comment Service</span>
            <span class="tab-sub-title">{{comments_title}}</span>
          </div>
          <div class="comment_wrapper">

            <div v-for="comment in comments" :class="`comment_item depth_${comment.depth}`" :key="comment.idx">

              <div class="member-info">
                <div class="member-name">
                  <span class="name">{{ comment.userName }}</span>
                  <span v-if="!comment.editor" class="updated">
                    <span class="date">{{ comment.date }}</span>
                    <i class="mdi mdi-clock-outline"></i>
                    <span class="time">{{ comment.time }}</span>
                  </span>
                </div>
                <div class="member-position">
                  <span class="position">{{ comment.office }}</span> /
                  <span class="position">{{ comment.position }}</span> /
                  <span class="department">{{ comment.dept }}</span>
                </div>

                <j-editor 
                  v-if="comment.editor" 
                  :class="`editor depth_${comment.depth}`" 
                  :comment="comment"
                  :progress="progress" 
                  :allowNoComment="true"
                  :buttons="[false, true, true]"
                  @save="d => onSave(d, comment.parentId)"
                  @cancel="onCancel" 
                />

                <div v-else>
                  <div class="button_replay_wrapper">
                    <a v-ripple v-if="comment.userId==account.id" class="btn-edit" title="edit" @click="onEdit(comment.idx)">
                      <i class="mdi mdi-pencil"></i>
                    </a>
                    <a v-if="removable(comment)" v-ripple class="btn-remove" title="delete" @click="onDelete(comment)">
                      <i class="mdi mdi-trash-can"></i>
                    </a>
                    <a v-if="comment.depth==0" v-ripple class="btn-reply" title="reply" @click="onWrite(comment.idx)">
                      <i class="mdi mdi-reply"></i>
                    </a>
                  </div>
                  <!-- <div v-html="comment.comment" class="comment"></div> -->
                  <div v-html="sanitizedComment" class="comment"></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </div>
    
</template>
<script>
import '@/assets/stylus/ui/component/_jCommComments.styl'

import { mapState, mapGetters, mapMutations } from 'vuex'

import * as d3 from 'd3'
import __C from '@/primitives/_constant_'
import { CommunicationService, FileService } from "@/services"
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Download from '@/mixins/download.mixin'
import Loading from '@/mixins/loading.mixin'
import Url from '@/services/api/request.url'
import DOMPurify from 'dompurify'

export default {
  name: 'j-modal-slide-component---comments-subsystem',
  mixins: [
    Download,
    Loading,
    SafeIdMixin
  ],
  props: {
    value: null,
    filters: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    communicationService: null,
    fileService: null,

    classWrapper: 'closed',
    comments: [],

    files: [],
    totaluploadprogress: 0,

    opened: true,
    progress: {
      complete: 0,
      total: 0
    },

    subsystem: null,
    subMenuOn: false,
    subMenuOptions: [
      { label: 'One-Way Group Messages', mode: __C.MESSENGER.MODE_1WAY_GROUP_MESSAGE }
    ],

    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [false, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},

    mode: __C.FORM.EDIT_MODE_VIEW,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['commentProps', 'updatecomment']),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'getCommentTitle',
      'getUpdateComment'
    ]), 
    
    __C_() { return __C },
    comments_title() { return this.getCommentTitle },
    modeView() { return this.mode == __C.FORM.EDIT_MODE_VIEW },
    sanitizedComment() {
      return this.sanitizeHtml(this.comment.comment);
    }
  },
  created() {
    this.localId = this.id || 'j-subsystem-comment__' + this.safeId('')

    this.communicationService = new CommunicationService()
    this.fileService = new FileService()

    
  },
  mounted() {
    this.subsystem = this.filters.filters.SUBSYSTEM
    this.getComments()
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    // window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setUpdateComment'
    ]),

    sanitizeHtml(dirtyHtml) {
      return DOMPurify.sanitize(dirtyHtml, {
        ALLOWED_TAGS: ['p', 'h1', 'h2', 'h3', 'strong', 'em', 'ul', 'ol', 'li'],
        ALLOWED_ATTR: []
      });
    },

    onCancel(data, parentId) {
      let index_ = this.comments.findIndex(c_ => c_.editor)
      if(index_ < 0) return

      let comments_ = [ ...this.comments ]
      if(this.mode == __C.FORM.EDIT_MODE_EDIT) {
        comments_[index_].editor = false
        comments_[index_].mode = __C.FORM.EDIT_MODE_VIEW
        if (data != undefined)  {
          comments_[index_].comment = data.comment
          this.comments = comments_
    
          let data_ = {
            parentId: parentId,
            subsystem : this.subsystem,
            userId  : this.account.id,
            comment : data.comment
          }

          let params = new FormData()
          params.append("idx", comments_[index_].idx)
          params.append("data", JSON.stringify(data_))
          
          let config = {
            onUploadProgress: e => {
              this.progress = {
                complete: e.loaded,
                total: e.total
              }
            }
          }

          this.communicationService.editSubsystemComment(params, config).then(() => {
            this.progress = { complete: 0, total: 0 }
            this.getComments()
            this.mode = __C.FORM.EDIT_MODE_VIEW
          })
        }else {
          this.getComments()
          this.mode = __C.FORM.EDIT_MODE_VIEW
        }

      } else {
        comments_[index_] = null
        this.comments = comments_.filter(c_ => !!c_)
      }

      this.mode = __C.FORM.EDIT_MODE_VIEW

      this.$emit('updateComment', {code: this.commentProps.refCode, comments: comments_})
    },
    // onDownload(fileId, name, type) {
    onDownload(fileId) {
      var iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      let params = new URLSearchParams()
      params.append('token', this.account.token)
      iframe.src = `${Url.download}/${fileId}?${params.toString()}`
    },
    onDelete(comment) {
      this.yes = () => {
        this.msgOpen = false
        this.communicationService.delSubsystemComment(comment.idx).then(() => {
          let index_ = this.comments.findIndex(c_ => c_.idx == comment.idx)
          let comments_ = [ ...this.comments ]
          comments_[index_] = null
          this.comments = comments_.filter(c_ => !!c_)
          this.$emit('updateComment', {code: this.commentProps.refCode, comments: comment})
        })
      // let flag = this.updatecomment ? false : true
      // this.setUpdateComment(flag)
      //   this.yes = () => {}
      }

      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"

      // if(comment.files.length > 0) {
      //   this.msgInfo.titleDescription = "Important Notification"
      //   this.msgInfo.message = `
      //     <span style="
      //       font-style: italic; 
      //       font-weight: 300; 
      //       font-size: 12px; 
      //       color: #f75c02
      //     ">
      //       Attention: All of its attachments will be deleted.
      //     </span><br /><br />
      //     Do you want to delete this comment?
      //   `
      // } else


      this.msgInfo.message = "Do you want to delete this comment?"

      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true

      // this.$emit('updateComment', {code: this.commentProps.refCode, comments: comment})
    },
    onEdit(idx) {
      if(this.mode != __C.FORM.EDIT_MODE_VIEW) return

      let comments_ = [ ...this.comments ]
      let index_ = this.comments.findIndex(c_ => c_.idx == idx)
      comments_[index_].editor = true
      comments_[index_].mode = __C.FORM.EDIT_MODE_EDIT

      this.comments = comments_
      this.mode = __C.FORM.EDIT_MODE_EDIT

      // this.$emit('updateComment', {code: this.commentProps.refCode, comments: comments_})
    },
    onSave(data, parentId) {
      // this.loading = true
      if(this.mode == __C.FORM.EDIT_MODE_EDIT) {
        this.onCancel(data, parentId)
        return
      }

      let data_ = {
        parentId: parentId,
        subsystem : this.subsystem,
        userId  : this.account.id,
        comment : this.sanitizeHtml(data.comment)
      }

      let params = new FormData()
      params.append("data", JSON.stringify(data_))
      
      let config = {
        onUploadProgress: e => {
          this.progress = {
            complete: e.loaded,
            total: e.total
          }
        }
      }
      this.communicationService.putSubsystemComment(params, config).then(() => {
        this.progress = { complete: 0, total: 0 }
        this.getComments()
        this.mode = __C.FORM.EDIT_MODE_VIEW
        // let flag = this.updatecomment ? false : true
        // this.setUpdateComment(flag)
        // this.loading = false
      })

      // this.$emit('updateComment', {code: this.commentProps.refCode, comments: data.comment})
    },
    onWrite(parentId) {
      if(this.mode != __C.FORM.EDIT_MODE_VIEW) return

      let comment_ = {
        editor: true,
        depth: parentId ? 1 : 0,
        parentId: Number(parentId),
        userName: this.account.userName,
        position: this.account.position,
        dept: this.account.department,
      }
      if(parentId) {
        let comments_ = [ ...this.comments ]
        let index_ = this.comments.findIndex(c_ => c_.idx == parentId)
        let subComments_ = this.comments.filter(c_ => c_.idx == parentId || c_.parentId == parentId)

        subComments_.push(comment_)
        comments_.splice(index_, subComments_.length-1, ...subComments_)
        comments_ = comments_.filter(c_ => !!c_)

        this.comments = comments_
        
      } else {
        this.comments.push(comment_)
      }
      
      this.mode = __C.FORM.EDIT_MODE_ADD
    },

    getComments() {
      this.communicationService.getSubsystemComments(this.subsystem).then(res => {
        this.comments = res
      })
    },
    removable(comment) {
      if(comment.userId != this.account.id) return false
      if(comment.depth == 0 && this.comments.findIndex(c_ => c_.parentId == comment.idx) >= 0) return false
      return true
    }
  }
}
</script>
<style lang="stylus" scoped>
.service_subsystem_comment_contents {
  position: relative;
  .service_subsystem_comment_contents_wrapper {
    position: relative;
    display: flex;
    // right: 7rem;
    height: 90vh;
    // background-color: #f5f5f5;
    // box-shadow: 0 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.16);
    transition: width .45s;

    &.opened {
      width: 900px;
    }
    &.closed {
      width: 0;
    }

    // .lnb {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   padding-top: 3rem;
    //   width: .5rem;
    //   background-color: #eee;
    // }

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      opacity: 1;

      [class^=btn-] {
        text-align: center;
        color: #909090;
        font-size: 1.6rem;
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 50%;
        transition: color .15s
      }
      [class^=btn-]:hover {
        color: #454545;
      }

      .btn-cancel,
      .btn-add {
        position: absolute;
        color: #909090;
        font-size: 1.8rem;
        top: 1.2rem;
        right: 1rem;
      }
      .btn-cancel {
        right: .5rem;
      }
      .btn-cancel:hover,
      .btn-add:hover {
        color: #454545;
      }

      .sub-menu---activator {
        position: absolute;
        top: .6rem;
        right: .5rem;
        display: flex;
        justify-content: center;
        width: 2.4rem;
        height: 2.4rem;
        color: #909090;
        font-size: 1.8rem;
        border-radius: 50%;
      }
      .sub-menu---activator:hover {
        color: #454545;
      }
      .sub-menu {
        position: absolute;
        top: 2.8rem;
        right: 1.5rem;
        width: 18rem;
        background-color: #fafafa;
        border-radius: .3rem;
        box-shadow: 0 0.1rem 0.2rem 0 rgba(0, 0, 0, 0.16);
        z-index: 1;

        .sub-menu-item {
          display: flex;
          align-items: center;
          padding: 0 .7rem;
          height: 2.6rem;
          color: #575757;
          border-radius: .3rem;
          transition: color background-color .15s;

          .sub-menu-label {
            margin-left: .5rem;
          }
        }
        .sub-menu-item:hover {
          color: #000;
          background-color: #eee;
          cursor: pointer;
        }
      }

      .tab-header {
        display: flex;
        align-items: center;
        height: 4rem;
        background-color: #eee;

        .tab-title {
          font-size: 1.2rem;
          font-weight: 900;
          margin-left: 1rem;
        }

        .tab-sub-title {
          font-weight: 500;
          color: #aeaeae;
          margin-left: 1rem;
        }
      }

      .button_replay_wrapper {
        position: absolute;
        top: -.5rem;
        right: 17.5rem;
        display: flex;
        justify-content: flex-end;
      }

      .comment_wrapper {
        position: absolute;
        top: 4rem;
        bottom: 0;
        width: 100%;
        overflow-y: auto;
        overflow-x: hidden;

        .editor {
          position: relative;
          margin-top: .5rem;

          for i in (0 .. 1) {
            $default_width = 53.3rem;
            $depth_width = 6.5rem;

            &.depth_{i} {
              width: $default_width - ($depth_width * i);
            }
          }
        }
      }
      .comment_item {
        display: flex;
        padding: 1.5rem 2rem;
        transition: background-color .15s;

        for i in (0 .. 1) {
          $depth_width = 6.5rem;   // property button size :second tabs

          &.depth_{i} {
            margin-left: $depth_width * i;
          }
        }

        .v-avatar {
          border: solid 2px #fdfdfd;
        }
        .member-info {
          position: relative;
          margin-left: 2rem;
          width: 100%;

          .member-name {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            .name {
              text-align: left;
              font-size: 1.2rem;
              font-weight: 500;
              color: #000;
            }
            .updated {
              display: flex;

              span {
                display: inline-block;
              }

              .date {
                margin-right: 2rem;
                padding-bottom: 3px;
              }
              .time {
                margin-left: 3px;
              }
            }
          }
          .comment {
            color: #333;
            font-size: 1.1rem;
            margin-top: 3px;
            margin-bottom: .5rem;

            p {
              margin: 0;
              word-break: break-all;
            }
          }
          .attachement {
            .file_names {
              display: flex;
              align-items: center;
              color: #000;

              .mdi-attachment {
                font-size: 1.6rem;
              }
              .names {
                color: #333;
                margin: 0 .5rem;
                padding-bottom: .5rem;
              }
              .names:hover {
                color: #1976d2
              }
            }
            .thumbs {
              display: flex;

              .thumb {
                display: flex;
                flex-direction: column;
                margin-right: 3px;

                a {
                  border: solid 1px rgba(0, 0, 0, 0)

                  img {
                    margin-bottom: -.4rem;
                  }
                }
                a:hover {
                  border: solid 1px #ffca28;
                }
              }

              // .thumb > * {
              //   margin-left: 3px;
              //   margin-right: 3px;
              // }
            }
          }
        }

        .member-position {
          color: #aeaeae;
          text-align: left;
        }
      }
    }
  }
}
</style>