import * as d3 from 'd3'

export default {
  data: () => ({
    
  }),

  methods: {

    draw_Summary() {
      let dat = this.rawData
      let st = this.styles
      let box = this.box
      let title = this.title
      let columns = this.columns

      // console.log('st', st)
      // console.log('box',box)
      // console.log('title',title)
      let maxLen = 0
      let y_ = 0

      let Table = this.svg
      .append('g')
      .attr('id', `table-${this.localId}`)
      .attr('transform', `translate(${st.margin}, ${st.margin + title.tSize})`)
      .attr('font-family', 'roboto')

      this.rawData.forEach((d,i)=> {
        Table
        .append('text')
        .attr('id', `text_${this.localId}_${i}`)
        .attr('x', 0).attr('y', y_)
        .attr('fill', title.tColor).attr('font-size', title.tSize)
        .attr('font-weight', title.tWeight)
        .attr('text-anchor', ()=> {
          if (title.align == 'LEFT') return 'start'
          if (title.align == 'RIGHT') return 'end'
        })
        .attr('alignment-baseline', 'middle')
        .style('cursor', 'default')
        .text(d.TITLE)

        d.width = d3.select(`#text_${this.localId}_${i}`).node().getBBox().width
        d.y = y_
        
        if(maxLen < d.width) maxLen = d.width
        y_ += st.lineHeight
      })

      this.rawData.forEach((d,i)=> {
        
        if (title.align == 'RIGHT') d3.select(`#text_${this.localId}_${i}`).attr('x', maxLen)
        if (box.visible == 'Y') {
          Table
          .append('rect')
          .attr('x', maxLen + st.space)
          .attr('y', d.y - (box.height/2) + box.plusY)
          .attr('width', box.width)
          .attr('height', box.height)
          ///
          .attr('fill', columns.boxColor[i])
          .attr('stroke', columns.boxSrtoke[i])
          .attr('stroke-width', columns.boxSrtokeWidth[i])
          .attr('stroke-opacity', 1)
          .attr('fill-opacity', columns.boxOpacity[i])
          .attr('rx', columns.boxRadius[i]).attr('ry', columns.boxRadius[i])
        }
        
        Table
        .append('text')
        .attr('id', `value_${this.localId}_${i}`)
        .attr('x', ()=> {
          if (columns.align == 'LEFT') return maxLen + st.space + box.padding
          if (columns.align == 'RIGHT') return maxLen + st.space + box.width - box.padding
        })
        .attr('y', d.y)
        .attr('fill', columns.tColor[i]).attr('font-size', columns.tSize)
        .attr('font-weight', columns.tWeight)
        .attr('text-anchor', ()=> {
          if (columns.align == 'LEFT') return 'start'
          if (columns.align == 'RIGHT') return 'end'
        })
        .attr('alignment-baseline', 'middle') // hanging
        .attr('font-family', 'roboto')
        .style('cursor', 'default')
        .text(() => {
          if(columns.format[i] == '$') {
            return d.VALUE.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if(columns.format[i] == '%') {
            return `${d.VALUE}%`
          } else {
            return d.VALUE
          }
        })
      })

    },

  } 
}