import { VSelect } from 'vuetify/lib'

export default {
  name: 'j-select',
  components: {
    VSelect
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemText: String,
    itemValue: String,
    label: String,
    placeholder: String,
    value: null
  },
  render() {
    let _attrs = {
      attrs: Object.assign({}, this.$props),
      on: {
        input: value => this.$emit('input', value)
      },
      props: {
        contentClass: 'single_select',
        items: this.items,
        itemText: this.itemText,
        itemValue: this.itemValue,
        menuProps: {
          maxHeight: '200',
          closeOnContentClick: true
        },
        value: this.value
      }
    }
    return (
      <div class='flex wrap__select'>
        <v-select {..._attrs} />
      </div>
    )
  }
}
