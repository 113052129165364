
export default {
  data: () => ({
    rawData: [],
    
    Style:    {},

    angle:    {},
    gauge :  {},
    title:    {},
    columns:    {},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      set_ColorDefs()
    */

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      let s = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      
      this.angle.size =             180,
      this.angle.start =            270,
      this.angle.end =              450,

      this.gauge = {
        x:                s.CIRCLE_RADIUS + 20,
        y:                s.CIRCLE_Y,
        radius:           s.CIRCLE_RADIUS,
        tickness:         s.CIRCLE_TICKNESS,
        fColor:           s.CIRCLE_COLOR,
        opacity:          s.CIRCLE_OPACITY,
        gColor:           s.GRID_COLOR,
        gWeight:          s.GRID_WEIGHT,
        gOpacity:         s.GRID_OPACITY,
        nColor:           s.NEEDLE_COLOR,
      }
  
      this.title = {
        visible:          s.TITLE_VISIVLE,
        tColor:           s.TITLE_COLOR,
        tSize:            s.TITLE_SIZE,
        tWeight:          s.TITLE_WEIGHT,
        align:            s.TITLE_ALIGN,
      }

      this.columns = {
        visible:          s.DATA_VISIVLE,
        height:           s.DATA_LINEHEIGHT,
        refCode:          s.HEADER_CODE.replace(/\s/g, '').split('/'),
        
        header: {
          name:           s.HEADER_NAME.replace(/\s/g, '').split('/'),
          tSize:          s.HEADER_TEXT_SIZE.replace(/\s/g, '').split('/'),
          tColor:         s.HEADER_TEXT_COLOR.replace(/\s/g, '').split('/'),
        },
       
        value: {
          
          tSize:          s.VALUE_TEXT_SIZE.replace(/\s/g, '').split('/').map(Number),
          tColor:         s.VALUE_TEXT_COLOR.replace(/\s/g, '').split('/'),
          align:          s.VALUE_TEXT_ALIGN.replace(/\s/g, '').split('/'),
        },
        
        json_Map:         s.JSON_MAP.replace(/\s/g, '').split('/'),
        json_Link:        s.JSON_LINK.replace(/\s/g, '').split('/'),
        filter:           s.VALUE_FILTER.replace(/\s/g, '').split('/'),
      }   
     
      this.Canvas.CanvasWidth = (this.gauge.radius + 20) * 2 + 15
      this.Canvas.CanvasHeight = this.gauge.y + this.columns.value.tSize[3] + 25
    },

    
    set_ColorDefs(selection){

    }
  }
}