import { SystemValuesApi } from './api'

export default class SystemService {
    
  constructor() {
    this.SystemValuesApi = new SystemValuesApi()
  }

  sysValuesColumns (dbName, tableName, callback) {
    this.SystemValuesApi.sysValuesColumns(dbName, tableName, res => { callback(!res ? [] : res) })
  }
  sysValuesTables (dbName, callback) {
    this.SystemValuesApi.sysValuesTables( dbName, (res) => { callback(res) })
  }
  sysValuesDatabases (callback) {
    this.SystemValuesApi.sysValuesDatabases(res => callback(res))
  }

}
