import * as d3 from 'd3'

export default {
  methods: {
    

    call_Link(selection, d, _style) {
      if (_style.LINK !== 'Y') return
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.x}, 38) scale(1.3)`).style('fill', '#EC407A')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.x}, 38) scale(1)`).style('fill', '#757575')
      })
      .on('click', () => {
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        let dataSource = {KEY1:d.WEEK} // DO not Remark if possible 

        // if linkCol is exist, set dataSource

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // set for request from A/G jsonProps
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // Not exist A/G jsonProps (link Action)
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        if(!request_) return

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // set for filterString 
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // if filterCol is exist, set filterString_
        // let filterString_ = d.WEEK == 'LAST' || d.WEEK == 'THIS' ? 
        //     ` [ACTUAL] > DATEADD(WW,-1,'${d.DATE}') AND [ACTUAL] <= DATEADD(WW,0,'${d.DATE}')` : 
        //     ` [ACTUAL] = '${d.DATE}'`
        let filterString_ = ''
        // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
        if(request_.iFilters.filterString) {
          request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
        } else {
          request_.iFilters.filterString = filterString_||''
        }

        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // combine filterString and inputFilter &&& emit request-action
        // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
        // DO not Remark if possible 
        request_ = {...request_,
          filters : {
            ...this.FilterValues,
            ...request_.filters,
          },
          iFilters: { 
            filterString : request_.iFilters.filterString||'',
            inputFilter : request_.iFilters.inputFilter,},
        }
        this.$emit('request-action', request_)
      })
    },

  }
}