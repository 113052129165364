import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'

export default {
  data: () => ({
    rawData: [],

    attr: null,

    filter: {
      col: null,
      text: null,
    }
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.attr = {
        tSize: sql1.TEXT_SIZE,
        tColor: sql1.TEXT_COLOR,
        name: sql1.NAME,
        defaultText: sql1.DEFAULT_TEXT,
      }

      this.filter.col = this.rawData[0].COL.split('/')
      this.filter.text = this.rawData[0].TEXT.split('/')

      // Filter data sample
      // this.FilterValues = {
      //   OWNER_ID: ['TOP - REV0', 'HPLT', 'GALT'],
      //   SUBSYS_NO: '20600-P-0201',
      //   UNIT: ['ARU', 'ARU', 'ARU', 'ARU', 'ARU'], 
      // }
    },
  }
}