export default {
  // Initial Data Set
  data: () => ({
    drawPoint_X: 0,
    drawPoint_Y: 0,
    drawPoint_AxisY: 0,

    modeCategory: '',
    modeStatus: '',
    Comp_DataItems: [],
    Toolbar_OnOff: 'N',

    lineGroupOpacity: 0,

    viewBox: null
  }),
  computed: {
    ready2draw() {
      // console.log('------------------------------')
      // console.log('this.ChartType', this.ChartType)
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('Object.keys(this.Timeline).length', Object.keys(this.Timeline).length)
      // console.log('Object.keys(this.AxisY).length', Object.keys(this.AxisY).length)
      // console.log('Object.keys(this.Boxes).length', Object.keys(this.Boxes).length)
      // console.log('Object.keys(this.Line).length', Object.keys(this.Line).length)
      // console.log('Object.keys(this.Milestone).length', Object.keys(this.Milestone).length)
      // console.log('Object.keys(this.Status).length', Object.keys(this.Status).length)
      // console.log('Object.keys(this.Legends).length', Object.keys(this.Legends).length)

      return  this.ChartType &&
              this.DataItems.length > 0 &&
              Object.keys(this.Canvas).length > 0 &&
              Object.keys(this.Timeline).length > 0 &&
              Object.keys(this.AxisY).length > 0 &&
              Object.keys(this.Boxes).length > 0 &&
              Object.keys(this.Line).length > 0 &&
              Object.keys(this.Milestone).length > 0 &&
              Object.keys(this.Status).length > 0 && this.Status.StatusCategory.length > 0 && this.Status.StatusStage.length > 0 &&
              Object.keys(this.Legends).length > 0
    },
  },
  methods: {

    // --------------------------------------------------------------------------------------------------------------------------------------------
    setDefaultValues(){ 
      let updatables_ = {}

      // Default Chart-Area Width
      let getChart_Width = (this.Boxes.BoxesBoxSpace + this.Boxes.BoxesBoxWidth) * this.DataItems.length

      // Default Chart-Area Height
      if (this.Boxes.BoxesChartType == 'Auto') var getBoxChart_Height = (
        (this.Boxes.BoxesBoxSpace + this.Boxes.BoxesBoxHeight) * 
        Math.max( ...this.DataItems.map(item => item.values.length))
      ); else if (this.Boxes.BoxesChartType == 'Fixed') getBoxChart_Height = this.Boxes.BoxesChartFixed

      
      if(getChart_Width > this.Canvas.CanvasChartWidth) updatables_.CanvasChartWidth = getChart_Width
      if(getBoxChart_Height > this.Canvas.CanvasChartHeight) updatables_.CanvasChartHeight = getBoxChart_Height
      // ----------------
      if(getChart_Width + 200 > this.Canvas.CanvasWidth) updatables_.CanvasWidth = getChart_Width + 200
      if(getBoxChart_Height + 500 > this.Canvas.CanvasHeight) updatables_.CanvasHeight = getBoxChart_Height + 500


      this.drawPoint_X = this.Canvas.CanvasChartX
      this.drawPoint_Y = this.Canvas.CanvasChartY + getBoxChart_Height - (this.Boxes.BoxesBoxSpace + this.Boxes.BoxesBoxHeight)
      this.drawPoint_AxisY = this.Canvas.CanvasChartY - (this.Line.LineStyleHeight - getBoxChart_Height)

      // Request to reset the Chart-Area Width & Height if small sized
      if(Object.keys(updatables_).length > 0) this.$emit('prop-value-change', updatables_)
    },
    setComputedDataItems() {
      // ----------------------------------------------  Computed Data into DataItems
      this.Comp_DataItems = JSON.parse(JSON.stringify(this.DataItems))
      
      this.Comp_DataItems.forEach(dataSet => {
        dataSet.values.forEach(value_ => {
          
          let result = null
          result = this.Status.StatusCategory.find(cat_ => cat_.code == value_.cat1)
          if(!result) value_.stroke = this.Status.StatusCategory[0].value
            else value_.stroke = result.value

          result = this.Status.StatusStage.find(stg_ => stg_.code == value_.stage)
          
          if(result && result.prog1 == '' && result.prog2 == '') value_.fill = result.value
          else value_.fill = this.Status.StatusStage[0].value
          
          if(result && result.prog1 == 'Y' && result.prog2 == '' || result && result.prog1 == '' && result.prog2 == 'Y') value_.barColor = result.value
          else value_.barColor = 'transparent'


          value_.x = this.drawPoint_X
          value_.y = this.drawPoint_Y
          this.drawPoint_Y = this.drawPoint_Y - this.Boxes.BoxesBoxHeight - this.Boxes.BoxesBoxSpace
        })
        this.drawPoint_X = this.drawPoint_X + this.Boxes.BoxesBoxWidth + this.Boxes.BoxesBoxSpace
        this.drawPoint_Y = this.Canvas.CanvasChartY + this.Canvas.CanvasChartHeight - (this.Boxes.BoxesBoxSpace + this.Boxes.BoxesBoxHeight)
      })
    },
  }
}