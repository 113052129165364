/* 
  Gradient Color Setting - updated: 2019-08-06 
    This function is provided 3 type of gradient(16 colors().
      1. LanearA2 
      2. LanearA4
      3. LanearA9
*/

const setDefs_DropShadow = (selection, name, stdDeviation, opacity, dx, dy) => {

  var defs = selection
    .append('defs')

  var filter = defs.append('filter')
    .attr('id', name).attr('height', 5)
    
    filter.append('feGaussianBlur')
    .attr('in', 'SourceAlpha')
    .attr('stdDeviation', stdDeviation)
    .attr('result', 'blur');
    
    filter.append('feFlood')
    .attr('flood-color','#bbb')
    .attr('flood-opacity', opacity)
    .attr('result', 'color')
    
    filter.append('feComposite')
    .attr('in', 'color')
    .attr('in', 'drop')
    .attr('operator', 'in')
    .attr('result', 'shadow')
  
    filter.append('feOffset')
    .attr('in', 'shadow')
    .attr('dx', dx)
    .attr('dy', dy)
    .attr('result', 'dropshadow')

  var feMerge = filter.append('feMerge')

    feMerge.append('feMergeNode')
    .attr('in', 'dropshadow')
    
    feMerge.append('feMergeNode')
    .attr('in', 'SourceGraphic');
}

export default {
  setDefs_DropShadow,
}
