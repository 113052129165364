import * as d3 from 'd3'

export default {
  methods: {

    chart_Disciplune(selection) {

      let header =  this.header
      let tbl =     this.table
      let bar =     this.barChart
      let cols =    this.columns

      let len =     cols.length-1
      let colsBar = cols[len]

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, 100])
      .range([0, bar.width])

      // Draw Header
      let y_ =  0
      this.columns.forEach((d,i)=> {
        selection
        .append('text')
        .attr('x', d.x).attr('y', y_)
        .style('font-size', header.tSize).style('fill', header.tColor)
        .attr('text-anchor', d.align)
        .attr('alignment-baseline', 'middle')
        .text(d.name)
      })

      let sGrid = colsBar.stX
      let mGrid = colsBar.stX + bar.width / 2
      let eGrid = colsBar.stX + bar.width

      selection
      .append('text')
      .attr('x', sGrid).attr('y', tbl.y - tbl.lineHeight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0%')

      selection
      .append('text')
      .attr('x', mGrid).attr('y', tbl.y - tbl.lineHeight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('50%')

      selection
      .append('text')
      .attr('x', eGrid).attr('y', tbl.y - tbl.lineHeight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('100%')

      selection
      .append('line')
      .attr('x1', sGrid).attr('y1', tbl.y - tbl.lineHeight + 5).attr('x2', sGrid).attr('y2', header.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      selection
      .append('line')
      .attr('x1', mGrid).attr('y1', tbl.y - tbl.lineHeight + 5).attr('x2', mGrid).attr('y2', header.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      selection
      .append('line')
      .attr('x1', eGrid).attr('y1', tbl.y - tbl.lineHeight + 5).attr('x2', eGrid).attr('y2', header.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      
      y_ = y_ + tbl.y
      this.rawData.forEach((d,i)=> {
  
        this.columns.forEach((c,j)=> {
          if( j < len) {
            selection
            .append('text')
            .attr('id', `${c.code}_${j}_${this.localId}`)
            .attr('transform', `translate(${c.x}, ${y_})`)
            .attr('font-size', tbl.tSize)
            .attr('fill', c.tColor)
            .attr('text-anchor', c.align)
            .attr('alignment-baseline', 'middle')
            .attr('cursor', c.link == 'Y' ? 'pointer': 'default')
            .text(d[c.code])
            .call(this.call_LINK, {...c,idx:i})
          }
        })

        // Progress Bar Chart -------------
        let PROG = d[colsBar.code]
        selection
        .append('rect')
        .attr('transform', `translate(${colsBar.stX}, ${y_ - (tbl.tSize/2) - 1})`)
        .attr('width', barScale(100)).attr('height', tbl.tSize)
        .attr('fill', '#bcbcbc').style('opacity', .15)

        selection
        .append('rect')
        .attr('transform', `translate(${colsBar.stX}, ${y_ - (tbl.tSize/2) - 1})`)
        .attr('width', 0).attr('height', tbl.tSize)
        .attr('stroke-width', 0.3).attr('stroke', bar.color).style('stroke-opacity', 1)
        .attr('fill', bar.color).style('fill-opacity', bar.opacity)
        .transition().duration(500).attr('width', barScale(PROG))

        // Progress Text -------------
        selection
        .append('text')
        .attr('transform', `translate(${colsBar.stX}, ${y_})`)
        .attr('text-anchor', PROG > 75 ? 'end' : 'start')
        .style('font-size', tbl.tSize)
        .style('fill', '#333')
        .attr('alignment-baseline', 'middle')
        .text(`${PROG}%`)
        .transition().duration(500)
        .attr(
          'transform', PROG > 75 ? 
          `translate(${colsBar.stX + barScale(PROG) - 2}, ${y_})` : 
          `translate(${colsBar.stX + barScale(PROG) + 2}, ${y_})`
          )
        
        y_ = y_ + tbl.lineHeight
      })
      
    },

  }
}