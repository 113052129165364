import * as d3 from 'd3'

export default {
  methods: {

    draw_Button(EventBus) {
      
      let canvas = this.sCanvasArea
      .append('g')
      .attr('id', 'Buttons')

      this.create_Button(canvas, EventBus, {
        id: 'reset_Btn',
        x: 20,
        y: 20,
        width: 100,
        height: 30,
        boxColor: 'lightblue',
        text: 'Reset Colors',
        event: 'callReset',
      })

      this.create_Button(canvas, EventBus, {
        id: 'hide_Btn',
        x: 20,
        y: 70,
        width: 100,
        height: 30,
        boxColor: this.hide == 'ON' ? 'lightblue' : 'pink',
        text: 'Hide',
        event: 'callHide',
      })

      this.create_Button(canvas, EventBus, {
        id: 'unhide_Btn',
        x: 20,
        y: 120,
        width: 100,
        height: 30,
        boxColor: 'lightblue',
        text: 'Unhide',
        event: 'callUnhide',
      })

      this.create_Button(canvas, EventBus, {
        id: '1st_move_Btn',
        x: 20,
        y: 170,
        width: 100,
        height: 30,
        boxColor: 'yellow',
        text: '1st Separate',
        event: 'moveObj',
        target: 'Group',
      })

      this.create_Button(canvas, EventBus, {
        id: '2nd_move_Btn',
        x: 20,
        y: 220,
        width: 100,
        height: 30,
        boxColor: 'yellow',
        text: '2nd Separate',
        event: 'moveObj',
        target: 'Mesh',
      })

      this.create_Button(canvas, EventBus, {
        id: 'repos_Btn',
        x: 20,
        y: 270,
        width: 100,
        height: 30,
        boxColor: 'yellow',
        text: 'Reset Position',
        event: 'resetPos',
      })

      this.create_Button(canvas, EventBus, {
        id: 'modal_Btn',
        x: 20,
        y: 320,
        width: 100,
        height: 30,
        boxColor: 'lightgreen',
        text: 'Modal Window',
        event: '',
      })

      d3.select('#modal_Btn').on('click', () => {
        this.link_Skyline('link-modal event')
      })
    },

    redrawing(id) {
      d3.select(id).remove()

      this.draw_ChartForm()
    },


  }
}