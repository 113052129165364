import * as d3 from 'd3'

export default {
  data: () => ({
    innerSvg: null,
    
    style: null,
    rawData: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      let s_ = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      let d_ = JSON.parse(JSON.stringify(this.DataItems[0]))

      this.style = {
        cir: {
          radius:     s_.CIRCLE_RADIUS,
          thickness:  s_.CIRCLE_THICKNESS,
          width:      s_.CIRCLE_WIDTH,
          padding:    s_.CIRCLE_PADDING,
          sColor:     s_.CIRCLE_STROKE_COLOR,
          sSize:      s_.CIRCLE_STROKE_SIZE,
          xColor:     s_.CIRCLE_FILL_COLOR,
          opacity:    s_.CIRCLE_OPACITY,
          gColor:     s_.GRID_COLOR,
          gOpacity:   s_.GRID_OPACITY,
        },

        col: {
          vPadding:   s_.COL_TEXT_PADDING,
          fColor:     s_.COL_TEXT_COLOR,
          fSize:      s_.COL_TEXT_SIZE,
          fWeight:    s_.COL_TEXT_WEIGHT,
        },

        value: {
          fSize:      s_.VALUE_TEXT_SIZE,
          fWeight:    s_.VALUE_TEXT_WEIGHT,
        }
  
      }

      this.rawData = {
        count: d_.COUNT,
        text:   [],
        value:  [],
        color:  [],
        link:   [],
        width:  [],
        x:      [],
        unit:   [],
        filter: [],
      }

      for(let i=0; i < this.rawData.count; i++) {
        this.rawData.text[i] = d_[`TEXT${i+1}`]
        this.rawData.value[i] = d_[`VALUE${i+1}`]
        this.rawData.color[i] = d_[`COLOR${i+1}`]
        this.rawData.link[i] = d_[`LINK${i+1}`]
        this.rawData.filter[i] = d_[`FILTER${i+1}`]
      }

      this.rawData.text.forEach( (d,i)=> {
        if(d.substr(d.length-1, 1) == '$' || d.substr(d.length-1, 1) == '%') {
          this.rawData.unit[i] = d.substr(d.length-1, 1)
          this.rawData.text[i] = d.slice(0, -1)
        } else {
          this.rawData.unit[i] = ''
        }
      })


      // this.Canvas.CanvasWidth = s_.CIR_WIDTH + 2
      // this.Canvas.CanvasHeight = s_.CIR_HEIGHT + 2
    },

  }
}