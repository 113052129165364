import __C from '../../primitives/_constant_'
import Api from './api'
import Url from './request.url'

export default class China1Api extends Api {

  constructor() {
    super()
  }

  putMrComment(params) {
    return new Promise(resolve => {
      this.axios().put(Url.china1.mr.comment, params).then(res => { resolve(res.data) })
    })
  }
  updateMrComment(params) {
    return new Promise(resolve => {
      this.axios().post(Url.china1.mr.comment, params).then(res => { resolve(res.data) })
    })
  }
  updateMrCommentStatus(params) {
    return new Promise(resolve => {
      this.axios().post(Url.china1.mr.status, params).then(res => { resolve(res.data) })
    })
  }
}