import * as d3 from 'd3'

export default {
  data: () => ({
    
  }),

  methods: {

    draw_Summary() {
      let s = this.style
      let d = this.rawData

      let totalTextWidth = 0

      let Table = this.svg
      .append('g')
      .attr('id', `table-${this.localId}`)
      .attr('transform', `translate(1, 1)`)

      for(let i=0; i < d.count; i++) {
        Table
        .append('text')
        .attr('id', `cols_${this.localId}_${i}`)
        .attr('x', 0).attr('y', s.col.vPadding)
        .attr('fill', s.col.fColor).attr('font-size', s.col.fSize)
        .attr('font-weight', s.col.fWeight)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging') // hanging
        .attr('font-family', 'roboto')
        .text(d.text[i])

        d.width[i] = d3.select(`#cols_${this.localId}_${i}`).node().getBBox().width
        totalTextWidth += d.width[i]
      }

      let textwidth = s.box.width - ((d.count-1) * s.box.padding)
      let spaceSum = textwidth - totalTextWidth
      let space = (spaceSum / d.count) / 2

      let cols_x = 0
      for(let i=0; i < d.count; i++) {

        d3.select(`#cols_${this.localId}_${i}`)
        .attr('x', cols_x + ((d.width[i] + (space * 2))/2))

        Table
        .append('rect')
        .attr('x', cols_x).attr('y', 0)
        .attr('width', d.width[i] + (space * 2))
        .attr('height', s.box.height)
        .attr('fill', s.box.xColor)
        .attr('stroke', s.box.sColor)
        .attr('stroke-width', s.box.sSize)
        .attr('opacity', s.box.opacity)
        .attr('rx', s.box.radius).attr('ry', s.box.radius)
        .moveToBack()
        
        Table
        .append('text')
        .attr('id', `value_${this.localId}_${i}`)
        .attr('x', cols_x + ((d.width[i] + (space * 2))/2))
        .attr('y', s.col.vPadding + s.col.fSize + s.value.vPadding)
        .attr('fill', d.color[i]).attr('font-size', s.value.fSize)
        .attr('font-weight', s.value.fWeight)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging') // hanging
        .attr('font-family', 'roboto')
        .style('cursor', d.link[i] == 'Y' ? 'pointer' : 'default')
        .text(() => {
          if(d.unit[i] == '$') {
            return d.value[i].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          } else if(d.unit[i] == '%') {
            return `${d.value[i]}%`
          } else {
            return d.value[i]
          }
        })
        .call(this.event_Link, i)
        
        cols_x += d.width[i] + (space * 2) + s.box.padding
      }
    },

  } 
}