<template>
  <div :id='localId'></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'          // Mixins (Global & Local)

export default {
  name: "j-svg-component-tabs",
  mixins: [ mx_Core ],
  props: {
    id: String,

    FilterValues: {
      type: Object,
      default: () => ({})
    },
    
    ChartType: String,

    // Chart Data
    Database: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    
    // Table Header(Column) Information
    ModalDraw: { type: Object, default: () => ({}) },
    ModalTaps: { type: Object, default: () => ({}) },

    // Database Table Values (Rows)
    DataItems: { type: Array, default: () => ([]) },

    TabStatus: {
      type: Array,
      default: () => ([])
    },
  },

  created() {
    this.localId = `${this.id || 'j-svg-component-tabs'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    // Main-Process flow *****
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return
      this.Taps_Check()
      this.clear()
      
      this.init()
      this.setDefaultValues()
      // Canvas & Chart Area
      this.drawCanvas()   // Global

      this.drawChart()
      this.draw_Header()
      this.draw_Taps()
      this.drawTexts()    // Global
      
      this.complete()
      this.postComplete()
    },

    drawChart() {
      this.ContainerGroup = this.svg
      .append('g')
      .attr('transform', `translate(${0},${0})`)
    },
    Taps_Check() {
      let TapIDs = this.ModalTaps.Menus.map(d => d.action).map(v => v.id)
      // this.$emit('init-tab-status', TapIDs)
    },
    postComplete() {
      let request_ = {
        dataType 	  : 'row',
        action   		: this.ModalTaps.Menus[this.tabSelected].action,
        filters  		: this.FilterValues,
        iFilters 		: {
          filterString : this.ModalTaps.Menus[this.tabSelected].action.filterString || '',
          inputFilter  : ''
        }
      }
      // delete request_.action.filterString

      this.$emit('request-action', request_)
    }
  }
}
</script>
