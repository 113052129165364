import * as d3 from 'd3'

export default {
  data: () => ({
    innerSvg: null,
    rawData: null,
     
    styles:   {},
    box:      {},
    title:    {},
    columns:  {},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      this.rawData.width = []
      this.rawData.y = []

      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.styles = {
        width:              sql1.WIDTH,
        lineHeight:         sql1.LINEHEIGHT,
        align:              sql1.ALIGN,
        margin:             sql1.MARGIN,
        space:              sql1.SPACE,
      }   

      this.title = {  
        align:              sql1.TITLE_ALIGN,
        tSize:              sql1.TITLE_TEXT_SIZE,
        tColor:             sql1.TITLE_TEXT_COLOR,
        tWeight:            sql1.TITLE_TEXT_WEIGHT,
      }   

      this.box = {    
        visible:            sql1.BOX_VISIBLE,
        width:              sql1.BOX_WIDTH,
        height:             sql1.BOX_HEIGHT,
        padding:            sql1.BOX_PADDING,
        plusY:              sql1.BOX_PLUS_Y,
        sSize:              sql1.BOX_STROKE_SIZE,
        opacity:            sql1.BOX_OPACITY,
        radius:             sql1.BOX_RADIUS,
      }

      this.columns = {    
        align:              sql1.VALUE_ALIGN,
        tSize:              sql1.VALUE_TEXT_SIZE,
        tWeight:            sql1.VALUE_TEXT_WEIGHT,

        format:             sql1.VALUE_FORMAT.replace(/\s/g, '').split('/'),
        tColor:             sql1.VALUE_TEXT_COLOR.replace(/\s/g, '').split('/'),
        boxColor:           sql1.BOX_FILL_COLOR.replace(/\s/g, '').split('/'),
        boxSrtoke:          sql1.BOX_STROKE_COLOR.replace(/\s/g, '').split('/'),
        boxSrtokeWidth:     sql1.BOX_STROKE_SIZE.replace(/\s/g, '').split('/').map(Number),
        boxOpacity:         sql1.BOX_OPACITY.replace(/\s/g, '').split('/').map(Number),
        boxRadius:          sql1.BOX_RADIUS.replace(/\s/g, '').split('/').map(Number),

        jsonMap:            sql1.JSON_MAP.replace(/\s/g, '').split('/'),
        jsonLink:           sql1.JSON_LINK.replace(/\s/g, '').split('/'),
        filter:             sql1.FILTER.replace(/\s/g, '').split('/'),
      }
      console.log(this.columns.boxOpacity)
      if (this.box.visible !== 'Y') this.box.padding = 0
    },

  }
}