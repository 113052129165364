import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
import Data from '../../../../includes/primitives/Color_Data'

export default {
  methods: {
    drawChartBox() {
      let slope = this.setThickness * 0.25

      let chartWrapper = this.chartArea
        .append('g')
        .attr('class', '_chart_wrapper__')
        .selectAll('g')
        .data(this.DataItems)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${this.scaleXInfo.scale(d.title)},0)`)

      // ---------------------------------------------------------------------------------------------------- Reflected Effect
      if (this.Bar.BarReflection == 'Y') {
        chartWrapper // Reflected Front Box
          .append('path')
          .attr('d', d => {
            return `
                M 0 ${this.Canvas.CanvasChartHeight} 
                V ${this.Canvas.CanvasChartHeight} 
                H ${this.setThickness - slope} 
                V ${this.Canvas.CanvasChartHeight}
              `
          })
          .attr('opacity', '0.3')
          .attr('fill', (_, i) => `url(#${this.localId}__LinearA2b${i})`)
          .transition()
          .duration(500)
          .attr('d', d => {
            return `
                M 0 ${this.Canvas.CanvasChartHeight} 
                V ${this.Canvas.CanvasChartHeight + this.setThickness * 0.75} 
                H ${this.setThickness - slope} 
                V ${this.Canvas.CanvasChartHeight - slope}
              `
          })
        chartWrapper // Reflected Right Box
          .append('path')
          .attr(
            'd',
            `
            M ${this.setThickness - slope} ${this.Canvas.CanvasChartHeight} 
            V ${this.Canvas.CanvasChartHeight} 
            H ${this.setThickness}
            V ${this.Canvas.CanvasChartHeight - slope}
          `
          )
          .attr('fill', (_, i) => `url(#${this.localId}__LinearA2b${i})`)
          .attr('opacity', '0.3')
          .transition()
          .duration(500)
          .attr(
            'd',
            `
            M ${this.setThickness - slope} ${this.Canvas.CanvasChartHeight} 
            V ${this.Canvas.CanvasChartHeight + this.setThickness * 0.75} 
            H ${this.setThickness}
            V ${this.Canvas.CanvasChartHeight - slope}
          `
          )
      }

      chartWrapper // Shadow
        .append('ellipse')
        .attr('cx', this.setThickness / 4 - slope / 2)
        .attr('cy', this.Canvas.CanvasChartHeight - 2)
        .attr('rx', (this.setThickness * 1.5) / 2 - slope / 2)
        .attr('ry', (this.setThickness * 1.5) / 3 / 2 - slope / 2)
        .attr('fill', `url(#${this.localId}__Color_Shadow)`)

      chartWrapper // Transparent Gradation
        .append('rect')
        .attr('transform', `translate(0,${this.Canvas.CanvasChartHeight})`)
        .attr('width', this.setThickness * 1.5 + 2)
        .attr('height', this.setThickness + 2)
        .attr('fill', `url(#${this.localId}__Color_Reflection)`)

      // ------------------------------------------------------------------------- Box Bar
      chartWrapper // Front Box
        .append('path')
        .attr('d', d => {
          let width = this.setThickness - slope
          return `
              M 0 ${this.Canvas.CanvasChartHeight} 
              H ${width} 
              V ${this.Canvas.CanvasChartHeight} 
              H ${0} 
            `
        })
        .attr('fill', (_, i) => `url(#${this.localId}__LinearA2a${i})`)
        .transition()
        .duration(500)
        .attr('d', d => {
          let width = this.setThickness - slope
          return `
              M 0 ${this.Canvas.CanvasChartHeight} 
              H ${width} 
              V ${this.scaleYInfo.scale(d.value)} 
              H ${0} 
            `
        })

      chartWrapper // Top Box
        .append('path')
        .attr('d', d => {
          return `
              M 0 ${this.Canvas.CanvasChartHeight} 
              H ${this.setThickness - slope} 
              L ${this.setThickness} ${this.Canvas.CanvasChartHeight - slope} 
              H ${slope} 
            `
        })
        .attr('fill', (_, i) => `url(#${this.localId}__LinearA2b${i})`)
        .transition()
        .duration(500)
        .attr('d', d => {
          return `
              M 0 ${this.scaleYInfo.scale(d.value)} 
              H ${this.setThickness - slope} 
              L ${this.setThickness} ${this.scaleYInfo.scale(d.value) - slope} 
              H ${slope} 
            `
        })

      chartWrapper // Right Box
        .append('path')
        .attr(
          'd',
          d => `
          M ${this.setThickness - slope} ${this.Canvas.CanvasChartHeight} 
          V ${this.Canvas.CanvasChartHeight} 
          L ${this.setThickness} ${this.Canvas.CanvasChartHeight - slope} 
          V ${this.Canvas.CanvasChartHeight - slope}
          `
        )
        .attr('fill', (_, i) => `url(#${this.localId}__LinearA2c${i})`)
        .transition()
        .duration(500)
        .attr(
          'd',
          d => `
          M ${this.setThickness - slope} ${this.scaleYInfo.scale(d.value)} 
          V ${this.Canvas.CanvasChartHeight} 
          L ${this.setThickness} ${this.Canvas.CanvasChartHeight - slope} 
          V ${this.scaleYInfo.scale(d.value) - slope}
          `
        )



      // ------------------------------------------------------------------------- Series & Value
      if (this.Bar.BarSeriesDisplay == 'Y') {
        chartWrapper // Series Name
          .append('text')
          .attr('transform', `translate(${Math.round(this.setThickness / 2)},${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 3) + this.Bar.BarSeriesSpace})`)
          .attr('class', this.Bar.BarSeriesStyle)
          .style('font-size', this.Bar.BarSeriesSize)
          .style('font-family', this.Bar.BarSeriesFont)
          //.style('font-weight', this.Bar.BarSeriesWeight)
          .attr('fill', (_, i) => (this.Bar.BarSeriesAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarSeriesColor))
          .attr('text-anchor', 'middle')
          .text(d => d.title)
      }

      let valueWrapper = chartWrapper // Value
        .append('g')
        .attr('id', (_, i) => `value_wrapper_${i}`)

      if (this.Bar.BarValueDisplay == 'Y') {
        valueWrapper
          .append('text')
          .attr('id', (_, i) => `ValueText${i}`)
          .attr('class', this.Bar.BarValueStyle)
          .style('font-size', this.Bar.BarValueSize)
          .style('font-family', this.Bar.BarValueFont)
          //.style('font-weight', this.Bar.BarValueWeight)
          .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarValueColor))
          .text(d => `${d.value.toFixed(this.Bar.BarValueRound)}`)
      }

      if (this.Bar.BarUnitDisplay == 'Y') {
        valueWrapper // Units
          .append('text')
          .attr('transform', (_, i) => `translate(${this.Bar.BarValueDisplay == 'Y' ? Number(this.getNodeElValue(`#ValueText${i}`, 'width')) : 0},0)`)
          .attr('id', (_, i) => `ValueUnit${i}`)
          .attr('class', this.Bar.BarValueStyle)
          .style('font-size', this.Bar.BarUnitSize)
          .style('font-family', this.Bar.BarValueFont)
          //.style('font-weight', this.Bar.BarValueWeight)
          .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarUnitColor))
          .text(this.Bar.BarUnitFormat)
      }

      // Reset the position of the value-wrapper
      this.DataItems.forEach((d, i) => {
        let valueWrapperId_ = `#value_wrapper_${i}`
        let valueWrapperBox_ = d3.select(`#value_wrapper_${i}`).node().getBoundingClientRect()
        let wrapperWidth_ = this.getNodeElValue(valueWrapperId_, 'width')

        this.svg.select(`#value_wrapper_${i}`)
        .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)},${this.Canvas.CanvasChartHeight - this.Bar.BarValueSpace - slope})`)
        .transition()
        .duration(500)
        .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)}, 
            ${this.scaleYInfo.scale(d.value) - this.Bar.BarValueSpace - this.setThickness / 5.9 + ( this.Bar.BarValuePosition == 'top' ?  0 : + valueWrapperBox_.height)}
          )`)
        // .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)},${this.scaleYInfo.scale(d.value) - this.Bar.BarValueSpace - slope})`)
      })
    }
  }
}
