import Api from './api'
import Url from './request.url'

export default class MD2Api extends Api {

  constructor() {
    super()
  }

  getCoiItem(no) {
    return this.axios().get(`${Url.md2.sc.coiitem}/${no}`).then(res => {
      return new Promise((resolve) => {
        resolve(res.data)
      })
    })
  }

  putCoiFiles(params, config) {
    return new Promise(resolve => {
      this.axios().put(Url.md2.sc.coiitem, params, config).then(res => { resolve(res.data) })
    })
  }

  delCoiFile(idx) {
    return new Promise(resolve => {
      this.axios().delete(`${Url.md2.sc.coiitem}/${idx}`).then(res => { resolve(res.data) })
    })
  }
}