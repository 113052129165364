import SystemCompletionApi from '../api/md2/system.completion.api'

export default class SystemCompletionService {

  constructor() {
    this.scApi = new SystemCompletionApi()
  }

  getFilterOptionArea() {
    return new Promise(resolve => {
      this.scApi.getFilterOptionArea().then(res => {
        resolve(res)
      })
    })
  }
}