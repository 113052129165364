import Vue from 'vue'
import KimLibComponents from '../../lib/kim'

var Kim = {
  install: function install(_vue) {
    var opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {}
    if (this.installed) return
    this.installed = true

    if (Vue !== _vue) {
      console.log('Multiple instances of Vue detected.')
      return
    }

    _vue.prototype.$jin = new _vue();

    (function registerComponents(components) {
      if (components) {
        for (var key in components) {
          // console.log(`components's key: %s`, key)
          var component = components[key];
          // if (component && !registerComponents(component.$_vuetify_subcomponents)) {
            Vue.component(key, component)
          // }
        }
        return true
      }
      return false
    })(opts.components)
  },
  version: 'kim-0.0.1'
}

Vue.use(Kim, {
  components: {
    ...KimLibComponents,
  }
})
