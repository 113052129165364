import * as d3 from 'd3'

export default {
  methods: {

    Cube_A(selection) {

      let title = this.cubes.title
      let tbl =   this.cubes.table
      let pro =   this.columns.find(f=> f.code == 'PROG')

      if(this.texts.title.visible == 'Y') {
        selection
        .append('text')
        .attr('id', `TITLE_${this.localId}`)
        .attr('x', 0).attr('y', 0)
        .style('font-size', this.texts.title.tSize)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .attr('fill', this.texts.title.tColor)
        .text(title.name)

        let tWidth = this.getNodeElValue(`#TITLE_${this.localId}`, 'width') + (title.padding*2) + 10
        let tHeight = this.getNodeElValue(`#TITLE_${this.localId}`, 'height') + (title.padding*2)

        selection
        .append('rect')
        .attr('x', 0).attr('y', 0)
        .attr('rx', title.radius).attr('ry', title.radius)
        .attr('width', tWidth).attr('height', tHeight)
        .attr('fill', title.bgColor).attr('opacity', title.opacity)
        
        d3.select(`#TITLE_${this.localId}`).moveToFront()
        .attr('x', tWidth/2).attr('y', tHeight/2)

        // Progress
        selection
        .append('text')
        .attr('x', tWidth + pro.width).attr('y', tHeight/2 + (pro.tSize/8))
        .style('font-size', pro.tSize)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .attr('fill', pro.tColor)
        .text(this.set_Value(pro.format, pro.value))
      }


      // Table -------------------------------------------------------------------------------------
      let summaryTable = selection
      .append('g')
      .attr('id', `Summary_Table_Group_${this.localId}`)
      .attr('transform', `translate(0,${tbl.y})`)
      .style('font-family', this.area.font)

      summaryTable
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('rx', tbl.radius).attr('ry', tbl.radius)
      .attr('width', tbl.width).attr('height', tbl.height)
      .attr('fill', tbl.bgColor).attr('opacity', tbl.opacity)

      this.columns.forEach(d=> {
        if(d.code == 'PROG') return
        
        // Header
        summaryTable
        .append('text')
        .attr('x', d.eX).attr('y', this.texts.header.y)
        .style('font-size', this.texts.header.tSize)
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .attr('fill', this.texts.header.tColor)
        .text(d.head)

        // Value
        summaryTable
        .append('text')
        .attr('x', d.eX).attr('y', this.texts.value.y)
        .style('font-size', d.tSize)
        .attr('text-anchor', 'end').attr('alignment-baseline', 'middle')
        .attr('fill', d.tColor)
        .attr('cursor', d.link == 'Y' ? 'pointer': 'default')
        .text(this.set_Value(d.format, d.value))
        .call(this.call_LINK, d)
      })

    },

  }
}