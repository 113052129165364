// import _ from 'lodash'
import { MD2Api } from './api'

export default class MD2Service {
    
  constructor() {
    this.md2Api = new MD2Api()
  }

  getCoiItem(no) {
    return this.md2Api.getCoiItem(no).then(res => {
      return new Promise((resolve) => {
        if(res && res.files) {
          let files = JSON.parse(res.files)
          let index_ = files.findIndex(f => f.fileType == 'image')

          if(index_ >= 0) {
            let files_ = []
            let images_ = files.filter(f => f.fileType == 'image')

            images_.forEach(f => { if(f.name == `${res.ITEM_NO}.${f.ext}`) { f.preview = true; files_.push(f) }})
            images_.forEach(f => { if(f.name != `${res.ITEM_NO}.${f.ext}`) files_.push(f) })
            files.forEach(f => { if(f.fileType != 'image') files_.push(f) })

            files = files_
          }
          res.files = files
        }
        resolve(res)
      })
    })
  }

  putCoiFiles(params, config) {
    return this.md2Api.putCoiFiles(params, config)
  }

  delCoiFile(idx) {
    return this.md2Api.delCoiFile(idx)
  }
}