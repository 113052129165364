import * as d3 from 'd3'
import __M from 'moment'

export default {
  data: () => ({
    timelineFloat: {
      start: 2,
      end: 2
    },
    timeline: {
      width: 0,
      height: 0,
      blockWidth: 30,
      startDate: '2021-01-01 00:00:00',
      endDate: '2023-03-31 23:59:59',
      weekday: 'Friday',
      scale: null,
      year: [],
      month: [],
      week: [],
    },
    weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  }),
  methods: {
    setTimelineEnv() {
      this.timeline.scale = null
      this.timeline.year = []
      this.timeline.month = []
      this.timeline.week = []

      let sDate_ = __M(__M(this.timeline.startDate).toDate()).add(-this.timelineFloat.start, 'weeks').toDate()
      let eDate_ = __M(__M(__M(this.timeline.endDate).toDate()).add(this.timelineFloat.end, 'weeks').format('YYYY-MM-DD 23:59:59')).toDate()

      let weekday = this.weekdays.findIndex(n_ => n_ == this.timeline.weekday)
      let diff_ = weekday - sDate_.getDay()
      sDate_ = __M(sDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      diff_ = weekday - eDate_.getDay()
      eDate_ = __M(eDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      // ### Set Timeline-Values for the Week ### ---------------------------
      let nextDate = __M(__M(sDate_).format('YYYY-MM-DD 23:59:59')).toDate()
      while(nextDate <= eDate_) {
        let value_ = {
          name: d3.timeFormat('%d')(nextDate), 
          sDate: __M(__M(nextDate).add(-6, 'days').format('YYYY-MM-DD')).toDate(),
          mDate: null,
          eDate: nextDate
        }
        value_.mDate = new Date(d3.mean([value_.sDate, value_.eDate]))

        
        this.timeline.week.push({ name: '', ...value_ })
        nextDate = __M(nextDate).add(7, 'days').toDate()
      }
      // ### ------------------------------------- --------------------------
      
      // ### Set Timeline-Values for the Month ### --------------------------
      eDate_ = null
      let months_ = []
      let months__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y-%m')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y-%m')(w_.eDate)

          if(i > 0) {
            months_.push(months__)
            months__ = []
          }
        }
        months__.push(w_)
        if(i === this.timeline.week.length - 1) months_.push(months__)
      })
      months_.forEach(m_ => {
        this.timeline.month.push({
          name: d3.timeFormat('%b')(m_[0].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })
      // ### ------------------------------------- --------------------------
      
      // ### Set Timeline-Values for the Year ### ---------------------------
      eDate_ = null
      let years_ = []
      let years__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y')(w_.eDate)

          if(i > 0) {
            years_.push(years__)
            years__ = []
          }
        }
        years__.push(w_)
        if(i === this.timeline.week.length - 1) years_.push(years__)
      })
      years_.forEach(m_ => {
        this.timeline.year.push({
          name: d3.timeFormat('%Y')(m_[m_.length - 1].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })
      // ### ------------------------------------- --------------------------
      
      // get min & max values of the timeline-scale
      this.timeline.scale = d3.scaleTime()
      .domain([
        this.timeline.week[0].sDate, 
        this.timeline.week[this.timeline.week.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.week.length * this.timeline.blockWidth])
    },

    getTimelineCDate(date) {
      let d_ = new Date(date)
      let cdate = this.timeline.week.find(w_ => d_ >= w_.sDate && d_ <= w_.eDate)
      // return cdate ? __M(__M(cdate.eDate).format('YYYY-MM-DD')).toDate() : null
      return cdate ? cdate : null
    }
  },
}