<template>
  <div :id='localId' class="j_mcr_comment_create">
    <div class="header_warpper">
      <div class="title_warpper">
        <span class="comment_title">Model Review Comment - Edit</span>
        <span class="comment_sub_title">MRC No. {{ mrComment.trackingNo }}</span>
      </div>
      <div class="spacer"></div>
      <div></div>
    </div>
    <div class="comment_content">
      <div class="row">
        <!-- <div class="field">
          <label>Project No.</label>
          <input v-model="mrComment.projectNo" class="kim_input" type="text" placeholder="Input Project No." readonly>
        </div> -->
        <div class="field">
          <label class="no_validate">Project No.</label>
          <label class="data">{{ mrComment.projectNo }}</label>
        </div>
        <div class="spacer"></div>
        <div class="field">
          <label class="label_mr_stage">*Model Review Stage</label>
          <kim-select
            v-model="stage"
            placeholder="Select Stage"
            itemText="NAME"
            itemValue="CODE"
            :items="itemStage"
          ></kim-select>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <label class="label_mr_package">*Package No.</label>
          <kim-select
            v-model="packageNo"
            placeholder="Select Package"
            itemText="CODE"
            itemValue="CODE"
            :items="itemPackage"
          ></kim-select>
        </div>
        <div class="spacer"></div>
        <div class="field">
          <label class="label_mr_plant">*Plant (SEI Unit - Level 2)</label>
          <kim-select
            v-model="plant"
            placeholder="Select Unit"
            itemText="DESCRIPTION"
            itemValue="CODE"
            :items="itemPlant"
          ></kim-select>
        </div>
      </div>
      <div class="row_spacer"></div>
      <j-mcr-comment-viewpoint
        v-if="isValidComment"
        ref="vpcomment"
        class="comment_viewpoint"
        :number="1"
        :removable="false"
        :mode-new="false"
        :package="packageNo"
        :plant="plant"
        :items="items"
        :value="mrComment"
        @input="update"
        @file="onFile"
        @viewpoint-removed="remove"
      />
    </div>

    <div class="bottom_wrapper">
      <button type="button" class="j_button type01 sky" :class="{ disabled: !updatable }" :disabled="!updatable" @click="save">Save</button>
      <button type="button" class="j_button type01 pink" @click="onCancel">Cancel</button>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>
<script>
import __C from '@/primitives/_constant_'
import Data from '@/primitives/_china1Default'

import { China1Service, DBSupportService } from '@/services'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import JMcrCommentViewpoint from './JMcrCommentViewpoint'

export default {
  name: 'j-modal-slide-component---mcr-comment-edit',
  components: { JMcrCommentViewpoint },
  mixins: [
    SafeIdMixin
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    value: null,
  },
  data: () => ({
    xmlService: null,
    china1Service: null,
    mrComment: JSON.parse(JSON.stringify(Data.mrComment)),
    items: {
      stage: [],
      package: [],
      plant: [],
      area: [],
      pids: [],
      tags: [],
      discipline: [],
      mrcStatus: [],
      mrcCategory: [],
    },
    vpFiles: [],
    prevComment: '',
    close: false,

    msgOpen: false,
    msgInfo: {
      type: '',
      title: '',
      titleDescription: '',
      message: '',
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {}
  }),
  computed: {
    __C_() { return __C },

    // isValidComment is used for created() and mounted() of 
    // j-mcr-comment-viewpoint working correctly after the this.mrComment is set
    isValidComment() { return this.mrComment.id }, 
    updatable() { return this.prevComment != JSON.stringify(this.mrComment) },
    
    stage: {
      get() { return this.mrComment.stage },
      set(v) { this.mrComment.stage = v }
    },
    packageNo: { 
      get() { return this.mrComment.packageNo },
      set(v) { 
        this.mrComment.packageNo = v 
        this.plant = ''
      }
    },
    plant: {
      get() { return this.mrComment.plant },
      set(v) { this.mrComment.plant = v }
    },
    
    trackingId() { return this.filters.filters.IDX },

    itemStage() { return this.items.stage },
    itemPackage() { return this.items.package },
    itemPlant() { return this.items.plant.filter(item => item.PACKAGE == this.mrComment.packageNo) },
  },
  created() {
    this.localId = this.id || 'j-comm-comment__' + this.safeId('')
    
    this.china1Service = new China1Service()
    this.queryLibService = new DBSupportService()
  },
  mounted() {
  },
  beforeDestroy () {
  },
  methods: {
    onFile(fs) {
      this.vpFiles = fs
    },
    onComplete() {
      this.queryLibService.getSqlQueryResult({ 
        idx: 37, 
        name: 'All Common Code For MR Comment Modification',
        filters: ''
      }).then(res => {
        let data = res[0]

        this.items = {
          stage: JSON.parse(data.stage),
          package: JSON.parse(data.package),
          plant: JSON.parse(data.plant),
          area: JSON.parse(data.area),
          pids: JSON.parse(data.pids),
          tags: JSON.parse(data.tags),
          discipline: JSON.parse(data.discipline),
          mrcStatus: JSON.parse(data.mrcStatus),
          mrcCategory: JSON.parse(data.mrcCategory),
        }

        this.queryLibService.getSqlQueryResult({ 
          name: 'MR-ClosureTracking By Tracking ID.',
          filters: `IDX = ${this.trackingId}`
        }).then(res => {
          let comment_ = res ? res[0] : []

          this.queryLibService.getSqlQueryResult({ 
            name: 'Viewpoints By Tracking ID.',
            filters: `A.TRACKING_ID = ${this.trackingId}`
          }).then(res => {
            let source = []
            let vps_ = res || []

            vps_.forEach(vp => {
              source.push({
                id          : parseInt(vp.VIEWPOINT_ID),
                viewpointNo : vp.NO,
                coordinate  : vp.COORDINATE,
                rotation    : vp.ROTAION,
                file: {
                  idx       : parseInt(vp.FID),
                  name      : vp.NAME,
                  sysName   : vp.SYS_NAME,
                  mimeType  : vp.MIME_TYPE,
                  fileType  : vp.FILE_TYPE,
                  ext       : vp.EXT,
                  path      : vp.PATH,
                  dataUrl   : vp.DATAURL,
                  size      : vp.SIZE,
                  dimention : vp.DIMENTION,
                }
              })
            })

            this.mrComment.id             = parseInt(comment_.IDX)
            this.mrComment.trackingNo     = comment_.TRACKING_NO
            this.mrComment.text           = comment_.MR_COMMENT
            this.mrComment.status         = comment_.STATUS
            this.mrComment.projectNo      = comment_.PROJECT_NO
            this.mrComment.stage          = comment_.STAGE
            this.mrComment.packageNo      = comment_.PACKAGE_NO
            this.mrComment.plant          = comment_.PLANT
            this.mrComment.area           = comment_.AREA
            this.mrComment.pidref         = comment_.PID_REF
            this.mrComment.location       = comment_.LOCATION
            this.mrComment.category       = comment_.CATEGORY
            this.mrComment.discipline     = comment_.DISCIPLINE
            this.mrComment.targetDate     = comment_.TARGET_DATE
            this.mrComment.createdBy      = comment_.CREATED_BY
            this.mrComment.createdDate    = comment_.CREATED_DATE
            this.mrComment.relatedTo      = comment_.RELATED_TO
            this.mrComment.generalComment = comment_.COMMENT
            this.mrComment.viewpoints     = source

            this.prevComment = JSON.stringify(this.mrComment)

            this.$emit('complete', {
              name: 'Edit Model Review Comment',
              background: '#f4f5f5 !important',
              dimention: { height: 0, width: 750 },
              preclose: this.onCancel
            })
          })
        })
      })
    },
    onCancel() {
      if(this.close) return true

      if(!this.updatable) {
        this.close = true
        this.$emit('close')
      } else {
        this.yes = () => {
          this.msgOpen = false
          this.close = true
          this.$emit('close')
          this.yes = () => { }
        }
        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Action Approval"
        this.msgInfo.titleDescription = "Data Changed!"
        this.msgInfo.message = "Do you want to cancel current MRC creation?"
        this.msgInfo.button = [true, false, true]
        this.msgInfo.buttonText[0] = 'Yes'
        this.msgOpen = true
      }

      return false
    },
    save() {
      if(!this.validate()) return

      this.yes = () => {
        let params = new FormData()
        params.append('data', JSON.stringify(this.mrComment))

        this.vpFiles.forEach(f => { params.append('files', f || 'NULL FILE') })

        this.china1Service.updateMrComment(params).then(() => {
          this.msgOpen = false
          this.close = true
          this.$emit('close')
          this.$emit('request-reload')
        })
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = "Model Review Comment Update"
      this.msgInfo.message = "Do you want to update current MRC?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = 'Yes'
      this.msgOpen = true
    },
    update(comment) {
      this.mrComment.text           = comment.text
      this.mrComment.status         = comment.status
      this.mrComment.stage          = comment.stage
      this.mrComment.packageNo      = comment.packageNo
      this.mrComment.plant          = comment.plant
      this.mrComment.area           = comment.area
      this.mrComment.pidref         = comment.pidref
      this.mrComment.location       = comment.location
      this.mrComment.category       = comment.category
      this.mrComment.discipline     = comment.discipline
      this.mrComment.targetDate     = comment.targetDate
      this.mrComment.createdBy      = comment.createdBy
      this.mrComment.createdDate    = comment.createdDate
      this.mrComment.relatedTo      = comment.relatedTo
      this.mrComment.generalComment = comment.generalComment
      this.mrComment.viewpoints     = comment.viewpoints
      this.mrComment.newTags        = comment.newTags
    },
    remove(id) {
      this.mrComment.removed.push(id)
    },
    pad(num, size) {
      num = num.toString();
      while (num.length < size) num = "0" + num;
      return num;
    },
    validate() {
      let validated = true

      if(!this.mrComment.stage) {
        document.querySelector('.label_mr_stage').classList.add('required')
        validated = false
      } else document.querySelector('.label_mr_stage').classList.remove('required')

      if(!this.mrComment.packageNo) {
        document.querySelector('.label_mr_package').classList.add('required')
        validated = false
      } else document.querySelector('.label_mr_package').classList.remove('required')

      if(!this.mrComment.plant) {
        document.querySelector('.label_mr_plant').classList.add('required')
        validated = false
      } else document.querySelector('.label_mr_plant').classList.remove('required')

      if(!this.$refs.vpcomment.validate()) validated = false

      if(!validated) {
        this.yes = () => {
          this.msgOpen = false
          this.yes = () => { }
        }
        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Failed to validate"
        this.msgInfo.titleDescription = "Required field cannot be empty."
        this.msgInfo.message = "Please check the required field is not empty."
        this.msgInfo.button = [true, false, false]
        this.msgInfo.buttonText[0] = 'OK'
        this.msgOpen = true
      }

      return validated
    },
    run() {
      this.onComplete()
    },
  }
}
</script>

<style lang="stylus" scoped>
.j_mcr_comment_create {
  position: relative;
  height: 100%;
  transition: width .45s;

  .header_warpper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2; 

    display: flex;
    align-items: center;

    height: 4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    background-color: #eee;
    padding: 1rem;

    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);

    .comment_title {
      font-size: 1.2rem;
      font-weight: 900;
      margin-left: 1rem;
    }

    .comment_sub_title {
      font-weight: 500;
      color: #aeaeae;
      margin-left: 1rem;
    }
  }

  .comment_content {
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    bottom: 5rem;
    overflow: auto;
    
    border-width: 0 .5rem;
    border-style: solid;
    border-color: #eee;
    padding: 3rem;
  }

  .comment_viewpoint {
    padding: 0 !important;
  }

  .bottom_wrapper {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: center;

    height: 5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.075);
    background-color: #eee;
    padding: 1rem;

    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.03);
  }

  .row_spacer {
    margin: 2rem;
  }

  .row {
    display: flex;
    margin-bottom: 1px;

    .field {
      display: flex;
      align-items: center;

      &.full_input {
        width: 100%;

        input {
          width: 100%;
        }
      }

      &.align_top {
        align-items: start;
      }

      label {
        min-width: 12rem;

        &.required {
          color: #f54242;
          font-style: italic;
          font-weight: 500;
        }

        &.no_validate {
          color: #999;
        }
        
        &.data {
          display: flex;
          align-items: center;

          color: rgba(0,0,0,0.87);
          min-width: 20rem;
          height: 3rem;
          padding-left: .5rem;
          border-bottom: 1px solid #999;
        }
      }

      .kim_input {
        width: 20rem;
        height: 3rem;
        border: 1px solid #bbb;
        padding: .5rem;
        padding-right: 2.4rem;

        background-color: #fff;
        border-radius: 3px;
      }
      .kim_input:hover {
        border-color: #777;
      }
      .kim_input::placeholder {
        color: #ccc;
        font-style: italic;
      }

      .j_fileuploader {
        width: 20rem;
      }
    } 

    .vfield {
      input {
        width: 32rem;
      }
    }
  }
}
</style>
