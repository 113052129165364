import { NoticeApi } from './api'

export default class NoticeService {
    
  constructor() {
    this.noticeApi = new NoticeApi()
  }

  // sender    : me
  // recipient : you

  findMembersUnread(id, account) {
    return this.noticeApi.findMembersUnread(id, account)
  }
  findMessageMembers(id, name, account) {
    return this.noticeApi.findMessageMembers(id, name, account)
  }
  getNotices(id, type, account) {
    return this.noticeApi.getNotices(id, type, account)
  }
  getTalkingONs(id, chatType, account) {
    return this.noticeApi.getTalkingONs(id, chatType, account)
  }
  get1To1Messages(chatNo, sender, account) {
    return this.noticeApi.get1To1Messages(chatNo, sender, account)
  }
  getGroupMessages(chatNo, sender, account) {
    return this.noticeApi.getGroupMessages(chatNo, sender, account)
  }
  getUnreadMessageNum(chatNo, sender, account) {
    return this.noticeApi.getUnreadMessageNum(chatNo, sender, account)
  }
  getUnreadsNum(sender, account) {
    return new Promise(resolve => {
      this.noticeApi.getUnreadsNum(sender, account).then(res => {
        if(res) res.unreads = JSON.parse(res.unreads)
        resolve(res)
      })
    })
  }
  putMessage (message) {
    return this.noticeApi.putMessage(message)
  }
  getChatMembers(chatNo, sender, account) {
    return this.noticeApi.getChatMembers(chatNo, sender, account)
  }
}
