<template>
  <div :id="localId" class="comment_wrapper">
    <div class="comment_number">
      {{ number }}
    </div>
    <div class="row">
      <div class="field">
        <label class="label_mr_vpno">*Viewpoint No.</label>
        <input
          v-model="viewpointNo"
          class="kim_input" 
          type="text" 
          placeholder="Input the Viewpoint No."
        >
      </div>
      <div class="spacer"></div>
      <div class="field">
      </div>
    </div>
    <div class="row">
      <div class="field align_top">
        <label class="label_mr_vpfile" style="margin-top: 1rem;">*Viewpoint (Image)</label>
        <j-fileuploader
          @added="vpSelected"
          @thumbnail="vpThumbnail"
        />
      </div>
      <div class="spacer"></div>
      <div class="field_group">
        <div class="vfield">
          <label>Coordinate</label>
          <input 
            v-model="coordinate"
            class="kim_input" 
            type="text" 
            style="background-color: #f4f5f5;" 
            readonly
          >
        </div>
        <div class="vfield">
          <label>Rotation</label>
          <input 
            v-model="rotation"
            class="kim_input" 
            type="text" 
            style="background-color: #f4f5f5;" 
            readonly
          >
        </div>
      </div>
    </div>
    <div class="comment_controller">
      <div class="controller_box">
        <div class="comment_button" @click="remove">
          <i class="material-icons">
            close
          </i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import Data from '@/primitives/_china1Default'

import { SafeIdMixin } from '@/mixins/safeid.mixin'
import FileProcess from '@/mixins/file.process.mixin'

export default {
  name: 'j-mcr-comment-viewpoint-file',
  mixins: [
    SafeIdMixin,
    FileProcess
  ],
  props: {
    number: Number,
    value: {
      type: Object,
      default: () => JSON.parse(JSON.stringify(Data.mrViewpoint))
    },
  },
  data: () => ({
    viewpoint: JSON.parse(JSON.stringify(Data.mrViewpoint)),
    vpFile: null,
  }),
  computed: {
    __C_() { return __C },

    viewpointNo: {
      get() { return this.value.viewpointNo },
      set(v) {
        this.viewpoint.viewpointNo = v
        this.send2parent()
      },
    },
    coordinate: {
      get() { return this.value.coordinate },
      set(v) {
        this.viewpoint.coordinate = v
        this.send2parent()
      },
    },
    rotation: {
      get() { return this.value.rotation },
      set(v) {
        this.viewpoint.rotation = v
        this.send2parent()
      },
    },
  },
  created() {
    this.localId = this.id || 'j-mcr-comment-viewpoint__' + this.safeId('')
    this.viewpoint = JSON.parse(JSON.stringify(this.value))
  },
  mounted() {
  },
  beforeDestroy () {
  },
  methods: {
    remove() {
      this.$emit('remove', this.number-1)
    },
    validate() {
      let validated = true

      if(!this.viewpoint.viewpointNo) {
        document.querySelector(`#${this.localId} .label_mr_vpno`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_vpno`).classList.remove('required')

      if(!this.viewpoint.file.idx && !this.vpFile) {
        document.querySelector(`#${this.localId} .label_mr_vpfile`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_vpfile`).classList.remove('required')

      return validated
    },
    send2parent() {
      this.$emit('input', JSON.parse(JSON.stringify(this.viewpoint)), this.number-1)
    },
    vpThumbnail(data) {
      this.viewpoint.file.dataUrl = data.dataUrl
      this.send2parent()
      
      this.$emit('file', this.vpFile, this.number-1)
    },
    vpSelected(file) {
      this.getFileInfo(file)

      this.vpFile = file
      this.viewpoint.file = JSON.parse(JSON.stringify(Data.mrViewpoint.file))
      this.viewpoint.file.name      = this.vpFile.name
      this.viewpoint.file.fileType  = this.vpFile.type
      this.viewpoint.file.ext       = this.vpFile.ext
      this.viewpoint.file.size      = this.vpFile.size
      this.viewpoint.file.dimention = this.vpFile.width ? `${this.vpFile.width},${this.vpFile.height}` : ''
    },
  }
}
</script>

<style lang="stylus" scoped>
  .comment_wrapper {
    position: relative;

    .comment_number {
      position: absolute;
      top: .3rem;
      left: -3rem;

      display: flex;
      justify-content: center;
      align-items: center;
      
      width: 2.4rem;
      height: 2.4rem;
      
      color: #ccc;
      font-weight: 900;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: #eee;
    }

    .comment_controller {
      position: absolute;
      top: 0;
      right: -3rem;
      
      display: flex;
      padding: .3rem;
      background-color: #eee;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      .material-icons {
        font-size: 1.8rem !important;
      }
    }
    .comment_controller:hover {
      cursor: pointer;
    }
  }

  .kim_input {
    width: 20rem;
    height: 3rem;
    border: 1px solid #bbb;
    padding: .5rem;
    padding-right: 2.4rem;

    background-color: #fff;
    border-radius: 3px;
  }
  .kim_input:hover {
    border-color: #777;
  }
  .kim_input::placeholder {
    color: #ccc;
    font-style: italic;
  }

  .row {
    display: flex;
    margin-bottom: 1px;

    .field {
      display: flex;
      align-items: center;

      &.align_top {
        align-items: start;
      }

      label {
        min-width: 12rem;

        &.required {
          color: #f54242;
          font-style: italic;
          font-weight: 500;
        }
      }

      .j_fileuploader {
        width: 20rem;
      }
    } 

    .vfield {
      display: flex;
      flex-direction: column;
      margin-top: -1rem;
      margin-bottom: 1.5rem;

      .kim_input {
        width: 32rem;
      }
    }
  }
</style>
