import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({

    Options: {
      
    }
  }),


  methods: {
    
    drawChart() {
      let winHeight = 75

      let menuIcon = this.svg
      menuIcon
      .append('image')
      .attr('id', `menuIcon__${this.localId}`)
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/icon/svg/menu_out.svg`)
      .attr('x', 2).attr('y', 2).attr('width', 11).attr('height', 11)
      .on('mouseover', () => { 
        menuIcon.style('cursor', 'pointer')
        d3.select(`#menuIcon__${this.localId}`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/icon/svg/menu_on.svg`)
      })
      .on('mouseout', () => { 
        menuIcon.style('cursor', 'default')
        d3.select(`#menuIcon__${this.localId}`).attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/icon/svg/menu_out.svg`)
      })
      .on('click', () => { 
        if (document.getElementById(`chartMenu__${this.localId}`).style.visibility == 'hidden') {
          chartMenu.style('visibility', 'visible')
        } else {
          chartMenu.style('visibility', 'hidden')
          d3.select(`#dDOptions__${this.localId}`).style('visibility', 'hidden')
        }
      })


      let chartMenu = this.svg
      .append('g')
      .attr('id', `chartMenu__${this.localId}`)
      .style('visibility', 'hidden') // hidden visible
      .attr('transform', `translate(${-180}, ${16})`)

      chartMenu
      .append('rect') // Background Window
      .attr('x', -0.5).attr('y', -0.5).attr('rx', 5).attr('ry', 5).attr('width', 180).attr('height', winHeight)
      .attr('fill', '#fff').attr('stroke-width', 0.2).attr('stroke', '#bcbcbc')
      .style('filter', `url(#optionShadow)`)
      

      chartMenu
        .append('text')
        .attr('x', 10).attr('y', 15).attr('fill', '#000') .style('font-size', 11).style('font-family', 'roboto')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('Export Options:')

        chartMenu
        .append('text')
        .attr('x', 15).attr('y', 30).attr('fill', '#757575') .style('font-size', 10).style('font-family', 'roboto')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text('This can be used in your report.')



      let PDF = chartMenu
        .append('g')
        .attr('transform', `translate(${80}, ${50})`)
        .on('mouseover', () => { 
          menuIcon.style('cursor', 'pointer')
          d3.select(`#pdfRect__${this.localId}`).attr('fill','#BCBCBC')
        })
        .on('mouseout', () => { 
          menuIcon.style('cursor', 'default')
          d3.select(`#pdfRect__${this.localId}`).attr('fill','#F7BACF')
        })
        .on('click', () => { 
          if (document.getElementById(`chartMenu__${this.localId}`).style.visibility == 'hidden') {
            chartMenu.style('visibility', 'visible')
          } else {
            chartMenu.style('visibility', 'hidden')
            d3.select(`#dDOptions__${this.localId}`).style('visibility', 'hidden')
          }
          let request_ = {
            dataType: 'row',
            action: {
              ...this.ColumnProps.find(props_ => props_.value == 'PDF'),
              type: 'direct',    // important!
            },
          }
          this.$emit('request-action', request_)
        })
        
        PDF
        .append('rect')
        .attr('id', `pdfRect__${this.localId}`)
        .attr('x', 0).attr('y', 0).attr('rx', 2).attr('ry', 2).attr('width', 30).attr('height', 13).attr('fill', '#F7BACF')

        PDF
        .append('text')
        .attr('x', 15).attr('y', 2).attr('fill', '#fff') .style('font-size', 10).style('font-family', 'roboto')
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging').text('PDF')
      


      let PNG = chartMenu
        .append('g')
        .attr('transform', `translate(${120}, ${50})`)
        .on('mouseover', () => { 
          menuIcon.style('cursor', 'pointer')
          d3.select(`#pngRect__${this.localId}`).attr('fill','#BCBCBC')
        })
        .on('mouseout', () => { 
          menuIcon.style('cursor', 'default')
          d3.select(`#pngRect__${this.localId}`).attr('fill','#44A9DF')
        })
        .on('click', () => { 
          if (document.getElementById(`chartMenu__${this.localId}`).style.visibility == 'hidden') {
            chartMenu.style('visibility', 'visible')
          } else {
            chartMenu.style('visibility', 'hidden')
            d3.select(`#dDOptions__${this.localId}`).style('visibility', 'hidden')
          }
          let request_ = {
            dataType: 'row',
            action: {
              ...this.ColumnProps.find(props_ => props_.value == 'PNG'),
              type: 'direct',    // important!
            },
          }
          this.$emit('request-action', request_)
        })

        PNG
        .append('rect')
        .attr('id', `pngRect__${this.localId}`)
        .attr('x', 0).attr('y', 0).attr('rx', 2).attr('ry', 2).attr('width', 30).attr('height', 13).attr('fill', '#44A9DF')

        PNG
        .append('text')
        .attr('x', 15).attr('y', 2).attr('fill', '#fff') .style('font-size', 10).style('font-family', 'roboto')
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging').text('PNG')
      


      // let SVG = chartMenu
      //   .append('g')
      //   .attr('transform', `translate(${200}, ${50})`)
      //   .on('mouseover', () => { 
      //     menuIcon.style('cursor', 'pointer')
      //     d3.select(`#svgRect__${this.localId}`).attr('fill','#BCBCBC')
      //   })
      //   .on('mouseout', () => { 
      //     menuIcon.style('cursor', 'default')
      //     d3.select(`#svgRect__${this.localId}`).attr('fill','#B4E3FA')
      //   })
      //   .on('click', () => { 
      //     let request_ = {
      //       dataType: 'row',
      //       action: {
      //         ...this.ColumnProps.find(props_ => props_.value == 'SVG'),
      //         type: 'direct',    // important!
      //       },
      //     }
      //     this.$emit('request-action', request_)
      //   })

      //   SVG
      //   .append('rect')
      //   .attr('id', `svgRect__${this.localId}`)
      //   .attr('x', 0).attr('y', 0).attr('rx', 2).attr('ry', 2).attr('width', 30).attr('height', 13).attr('fill', '#B4E3FA')

      //   SVG
      //   .append('text')
      //   .attr('x', 15).attr('y', 2).attr('fill', '#fff') .style('font-size', 10).style('font-family', 'roboto')
      //   .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging').text('SVG')

      

      



      

    },

    

  }
}
