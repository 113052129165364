import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    import3DViewer() {
      let extViewer = this.svg
      .append('g')
      .attr('class', 'external_3d_viewer')
      .attr('transform', 'translate(10.5, 50.5)')

      extViewer
      .append('rect')
      .attr('width', this.viewerWidth)
      .attr('height', this.viewerHeight)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      .style('fill', '#fff')

      extViewer
      .append("foreignObject")
      .attr('transform', `translate(0, 0)`)
      .attr('clip-path', 'url(#mask_three_d_viewer)')
      .attr('width', this.viewerWidth)
      .attr('height', this.viewerHeight)
      .append('xhtml:iframe')
      .attr('id', 'three_d_viewer')
      .attr('width', this.viewerWidth)
      .attr('height', this.viewerHeight)
      .attr('frameBorder', 0)
      .attr('scrolling', 'no')
      .attr('style', 'border: none;')
    },
    open3DViewer() {
      if(!this.FilterValues.objectName) return
      d3.select('#three_d_viewer').attr('src', `${this.__HOST_NAME}/#/pub_3d_viewer/token/${this.FilterValues.objectName}/{"d":"cmn","w":${this.viewerWidth},"h":${this.viewerHeight}}`)
    }
  }
}
           