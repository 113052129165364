import '../../../../src/assets/stylus/ui/_dataGrid.styl'

export default {
  name: 'j-data-grid-tool-bar',
  props: {
    headers: {
      type: Array,
      default: () => []
    },
    items: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    genBody() {
      let trs = []
      this.items.forEach((item, index) => {
        trs.push(this.$scopedSlots.items({ item: item, index: index }))
      })
      return this.$createElement('tbody', trs)
    },
    genHeader() {
      let length = this.headers.length
      if(length == 0) return null

      let gearBt = []
      if(this.headers[0].type == 'conf') {
        gearBt = [this.$createElement('button', {
          class: 'jcon_config',
          on: {
            click: e => {
              this.$emit('click-config', e)
            }
          }
        }, [])]
      }

      let data_ = { class: 'column text-xs-center' }
      let ths = []
      let trs = []
      let toolBar_ = this.$slots['tool-bar'] ? true : false
      if(toolBar_) {
        ths.push(this.$createElement('th', data_, gearBt))
        ths.push(this.$createElement('th', { attrs: { colspan: length - 1 }}, [this.$slots['tool-bar']]))
        trs.push(this.$createElement('tr', ths))
      }

      ths = []
      this.headers.forEach(h => {
        let style = h.width ? {
          width: `${h.width}px`,
          maxWidth: `${h.width}px`,
          minWidth: `${h.width}px`
        } : {}
        ths.push(this.$createElement('th', { style }, [toolBar_ ? '' : h.text]))
      })

      let style = toolBar_ ? { height: 0 } : {}
      trs.push(this.$createElement('tr', { style }, ths))

      return this.$createElement('thead', trs)
    },
  },
  render() {
    let wrapper = {
      class: {
        j_datagrid: true,
        chart: true
      }
    }
    let table = {
      class: {
        'v-datatable': true,
        'v-table': true,
        'theme--light': true
      }
    }

    return (
      <div { ...wrapper }>
        <table { ...table }>
          { this.genHeader() }
          { this.genBody() }
        </table>
      </div>
    )
  }
}