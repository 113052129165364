import * as d3 from 'd3'

export default {
  data: () => ({
    pieCenterX: 0,
    angleSize: 0,
    angleStart: 0,
    angleEnd: 0,
    pieSectors: [],

    filterByType: {
      Single: (data) => data.slice(0, 1),
      Double: (data) => data.slice(0, 2),
      Triple: (data) => data.slice(0, 3),
    },

    localValue: null,
  }),
  computed: {
    ready2draw() {
      return this.ChartType &&
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 &&
        Object.keys(this.Legends).length > 0 &&
        Object.keys(this.Circle).length > 0 &&
        this.Value && this.Value.length > 0
    },
  },
  methods: {

    setDefaultValues() {
      this.localValue = JSON.parse(JSON.stringify(this.Value))
      this.pieCenterX = this.Circle.CircleCenterX
      this.SharedColorSet = this.Circle.CircleFtColor

      if (this.Circle.CircleIconDisplay == 'Y') {
        this.angleSize = 270; this.angleStart = 225; this.angleEnd = 495
      } else {
        this.angleSize = 360; this.angleStart = 0; this.angleEnd = 360
      }
    },

    calculatePieSectors() {
      var ra = 0
      var setStroke = 0
      var setBgColor = ''
      var setColor = ''

      this.filterByType[this.Circle.CircleType](this.DataItems).forEach((item, i) => {

        setStroke = this.Circle.CircleStroke[i]
        setBgColor = this.Circle.CircleBkColor[i]
        setColor = this.Circle.CircleFtColor[i]
        ra = Number(this.Circle.CircleRadius[i]) // get

        this.pieSectors.push({
          bgColor: setBgColor,
          color: setColor,
          stroke: setStroke,
          percentage: item.percentage,
          value: item.value,
          label: item.title,
          Ra: ra,
        })

      })
      return this.pieSectors
    },
  }
}