import * as d3 from 'd3'

export default {

  methods: {
    
    draw_Timeline_Month() {

      this.canvasForm = this.svg
      .append('g')
      .attr('id', 'TIMELINE')
      .attr('transform', `translate(50, 180)`)


      this.canvasForm
      .append('path') // shadow
      .attr('d', `M50,${this.timeline.height + 2} H ${this.timeline.length-50} L ${this.timeline.length},${this.timeline.height + 12} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`) 

      this.canvasForm
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 2)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .99)

      this.canvasForm
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.length).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .5)

      this.canvasForm
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.length).attr('height', 6)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`)


      // Month Text & Timeline Seperator for the Month
      this.canvasForm
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 4)
      .style('font-size', 10)
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name.toUpperCase())

      this.canvasForm
      .append('g')
      .selectAll('path')
      .data(this.timeline.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)}, 2 V15`
      })
      .attr('stroke', '#BCBCBC')
      .attr('stroke-width', 0.5)

      // Year Text & Timeline Seperator for the Year
      this.canvasForm
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 3 +  20)
      .style('font-size', 11)
      .attr('fill', '#000')
      .attr('font-weight', 500)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      this.canvasForm
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, ${15} V${35}`
      })
      .attr('stroke', '#BCBCBC')
      .attr('stroke-width', 0.5)
    }, 


  }
}
