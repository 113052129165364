<template>
  <div>
    <component 
      class="component--content-target"
      ref="target"
      :class="blindScreen"
      :is="maComponent"
      :filters="maFilters"
      :target="maTarget"
      @complete="onComplete"
    />
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import ServicePhotoGallary from '@/views/service/ServicePhotoGallerySingle'
import Service3DViews from '@/views/public/3DViewerCUSTOM'
import ServiceSubsystemComment from '@/views/service/ServiceSubsystemComment'
import Loading from '@/mixins/loading.mixin'
import { mapMutations } from 'vuex'
import { SystemCodeService } from '@/services'
export default {
  name: 'j-service-comp-page',
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({})
    },
    value: null,
  },
  mixins: [
    Loading
  ],
  components: {
    ServicePhotoGallary,
    Service3DViews,
    ServiceSubsystemComment
  },
  data: () => ({
    blindScreen: 'off',    
    maComponent: '',
    maFilters: null,
    maTarget: null,
    systemCodeService: null,    
    compomentData: []
  }),
  beforeCreate() {
    this.loading = true
  },
  created() {
    // MC Skyline Modal에서 Comment 선택시 filter chain이 사라져 수정
    if (this.target.no !== 'ServiceSubsystemComment') this.setChild('')

    this.systemCodeService = new SystemCodeService()
  },
  mounted() {
    this.getCompoment()
    this.run()
  },
  methods: {
  ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [ 'setChild' ]), 
    onComplete(v) {
     //
    },
    getCompoment() {
      this.systemCodeService.getUrlData(
        res => {
          if (res && res.length > 0) { 
            this.compomentData = res.find(d => d.url == this.$route.path)
          }

          this.run()
        }
      )
    },
    run() {
      this.loading = false
      if(this.target.no == 'ServicePhotoGallary') {
        this.maTarget = 'modal'
        this.maComponent = this.target.no
        this.maFilters = this.filters
      } else if (this.target.no == 'ServiceSubsystemComment') {
        this.maTarget = 'modal'
        this.maComponent = this.target.no
        this.maFilters = this.filters
      } else if (this.compomentData) {
        this.maComponent = this.compomentData.customCompoment
        this.maFilters = null
      } 
      // if(this.$route.path == '/service/construction/3d_viewer/overall_module_status') {
      //   this.maComponent = 'Service3DViews'
      //   this.maFilters = null
      //   this.setChild('')
      // }

      return new Promise(resolve => {
        resolve(true)
      })
    }
  }
}
</script>

<style>

</style>