<template>
  <div :id="localId" class="j_mcr_comment">
    <div class="header_warpper">
      <div class="title_warpper">
        <span class="comment_title">Model Review Comment - <span class="comment_status">{{ status }}</span></span>
        <span class="comment_sub_title">Created by {{ item.CREATED_BY }} on {{ item.CREATED_DATE }}</span>
      </div>
      <div class="spacer"></div>
      <button type="button" class="j_button type01 sky" @click="openComment()" title="Open Free Comment">Free Comment {{`(${freeComments})`}}</button>
      <button type="button" class="j_button type01 sky">PDF</button>
    </div>

    <div v-if="hasHistory" class="button_container">
      <button v-if="!displayHistory" v-ripple class="btn_history" title="Show Rejected History" @click="() => { this.displayHistory = true }">
        <v-icon>mdi-playlist-remove</v-icon>
      </button>
      <button v-else v-ripple class="btn_history_close" title="Close Rejected History" @click="() => { this.displayHistory = false }">
        <v-icon>close</v-icon>
      </button>
    </div>
    <div v-if="hasHistory && displayHistory" class="reject_history">
      <div class="table_reject_history_header">
        <span class="header_column col2">Status</span>
        <span class="header_column col2">Created By</span>
        <span class="header_column col3">Created Date</span>
        <span class="header_column col4">Rejected By</span>
        <span class="header_column col5">Rejected Date</span>
      </div>
      <div class="table_reject_history_body">
        <div v-for="(data, i) in rejectHistory" :key="i" class="table_reject_history_body_row" @click="onRequestAction(data.IDX, 'JMcrComments')">
          <span class="row_column col2">{{ data.STATUS }}</span>
          <span class="row_column col2">{{ data.CREATED_BY }}</span>
          <span class="row_column col3">{{ data.CREATED_DATE }}</span>
          <span class="row_column col4">{{ data.REJECTED_BY }}</span>
          <span class="row_column col5">{{ data.REJECTED_DATE }}</span>
        </div>
      </div>
      <div class="table_reject_history_footer">
        {{ `${rejectHistory.length} of the Reject History found for ${item.TRACKING_NO}.` }}
      </div>
    </div>
    <div class="comment_content">
      <div class="row">
        <div class="field">
          <label>MRC No.</label>
          <label class="data">{{ item.TRACKING_NO }}</label>
        </div>
        <div class="spacer"></div>
        <div class="field">
          <label>MRC Status</label>
          <label class="data">{{ status }}</label>
        </div>
      </div>
      <div class="row_spacer"></div>
      <div class="row">
        <div class="field">
          <label>Project No.</label>
          <label class="data">{{ item.PROJECT_NO }}</label>
        </div>
        <div class="spacer"></div>
        <div class="field">
          <label>Model Review Stage</label>
          <label class="data">{{ item.STAGE }}</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <label>Package No.</label>
          <label class="data">{{ item.PACKAGE_NO }}</label>
        </div>
        <div class="spacer"></div>
        <div class="field">
          <label>Plant (SEI Unit - Level 2)</label>
          <label class="data">{{ item.PLANT }}</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <label>Area No. (SEI Sub-Unit)</label>
          <label class="data">{{ item.AREA }}</label>
        </div>
        <div class="spacer"></div>
        <div class="field">
          <label>P&ID Reference</label>
          <label class="data">{{ item.PID_REF }}</label>
        </div>
      </div>
      <div class="row">
        <div class="field full_input align_top">
          <label>Location (Tag Number)</label>
          <div class="location_wrapper">
            <div
              v-for="(tag, i) in locationTags" 
              class="locatoin_box"
              :key="i"
            >
              <span class="location" :title="tag.MR_DESCR">{{ tag.TAG_NO }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="row_spacer"></div>
      <div class="row">
        <div class="field">
          <label>Comment Category</label>
          <label class="data">{{ item.CATEGORY }}</label>
        </div>
        <div class="spacer"></div>
        <div class="field">
          <label>Discipline</label>
          <label class="data">{{ item.DISCIPLINE }}</label>
        </div>
      </div>
      <div class="row_spacer"></div>
      <div class="row">
        <div class="field full_input align_top">
          <label>MR Comment</label>
          <pre class="data">{{ item.MR_COMMENT }}</pre>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <label>Date Created</label>
          <label class="data">{{ item.CREATED_DATE }}</label>
        </div>
        <div class="spacer"></div>
        <div class="field">
          <label>Target Completion Date</label>
          <label class="data">{{ item.TARGET_DATE }}</label>
        </div>
      </div>
      <div class="row">
        <div class="field">
          <label>Originator</label>
          <label class="data">{{ item.CREATED_BY }}</label>
        </div>
        <div class="spacer"></div>
        <div class="field">
        </div>
      </div>
      <div class="row">
        <div class="field full_input align_top">
          <label>Related To</label>
          <pre class="data">{{ item.RELATED_TO }}</pre>
        </div>
      </div>
      <div class="row">
        <div class="field full_input align_top">
          <label>Comment (General)</label>
          <pre class="data">{{ item.COMMENT }}</pre>
        </div>
      </div>
      <div v-for="(viewpoint, i) in viewpoints" :key="i">
        <div class="row_spacer"></div>
        <div class="row">
          <div class="field">
            <label>Viewpoint No.</label>
            <label class="data">{{ viewpoint.NO }}</label>
          </div>
          <div class="spacer"></div>
          <div class="field">
          </div>
        </div>
        <div class="row">
          <div class="field align_top">
            <label>Viewpoint</label>
            <img class="viewpoint_image" :src="viewpoint.DATAURL" />
          </div>
          <div class="spacer"></div>
          <div class="field_group">
            <div class="vfield" style="margin-bottom: 1rem;">
              <label>Coordinate</label>
              <label class="data">{{ viewpoint.COORDINATE }}</label>
            </div>
            <div class="vfield">
              <label>Rotation</label>
              <label class="data">{{ viewpoint.ROTAION }}</label>
            </div>
          </div>
        </div>
      </div>
      <div v-if="showSeparator">
        <div class="row_spacer"></div>
        <div class="comment_info_separator"></div>
        <div class="comment_info_separator"></div>
        <div class="row_spacer"></div>
      </div>
      <div v-if="editableResponse">
        <div class="row">
          <div class="field full_input align_top">
            <label class="label_mr_response_comment">Response Comment</label>
            <textarea v-model="source.responseComment" class="kim_input" type="text" placeholder="Input Response Comment"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="field full_input align_top">
            <label>Attachment (Multi Files)</label>
            <kim-file-uploader
              class="mrcomment_file_uploader"
              :limit-count="10"
              @change="onFileChanged"
            />
          </div>
        </div>
      </div>
      <div v-if="showResponsed">
        <div class="row">
          <div class="field full_input align_top">
            <label>Response Comment</label>
            <pre class="data">{{ item.RESPONSE_COMMENT }}</pre>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <label>Responded By</label>
            <label class="data">{{ item.RESPONSED_BY }}</label>
          </div>
          <div class="spacer"></div>
          <div class="field">
            <label>Responded Date</label>
            <label class="data">{{ item.RESPONSED_DATE }}</label>
          </div>
        </div>
        <div v-if="respondedFiles.length" class="row">
          <div class="field align_top">
            <label>Attachments</label>
            <j-mcr-comment-status-files
              :filesInfo="respondedFiles"
            />
          </div>
        </div>
      </div>
      <div v-if="editableReview">
        <div class="row_spacer"></div>
        <div class="row">
          <div class="field full_input align_top">
            <label class="label_mr_review_comment">Review Comment</label>
            <textarea v-model="source.reviewComment" class="kim_input" type="text" placeholder="Input Review Comment"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="field full_input align_top">
            <label>Attachment (Multi Files)</label>
            <kim-file-uploader
              class="mrcomment_file_uploader"
              :limit-count="10"
              @change="onFileChanged"
            />
          </div>
        </div>
      </div>
      <div v-if="showReviewed">
        <div class="row_spacer"></div>
        <div class="row">
          <div class="field full_input align_top">
            <label>Review Comment</label>
            <pre class="data">{{ item.REVIEW_COMMENT }}</pre>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <label>Reviewed By</label>
            <label class="data">{{ item.REVIEWED_BY }}</label>
          </div>
          <div class="spacer"></div>
          <div class="field">
            <label>Reviewed Date</label>
            <label class="data">{{ item.REVIEWED_DATE }}</label>
          </div>
        </div>
        <div v-if="reviewedFiles.length" class="row">
          <div class="field align_top">
            <label>Attachments</label>
            <j-mcr-comment-status-files
              :filesInfo="reviewedFiles"
            />
          </div>
        </div>
      </div>
      <div v-if="editableApprove">
        <div class="row_spacer"></div>
        <div class="row">
          <div class="field full_input align_top">
            <label class="label_mr_approve_comment">Approve Comment</label>
            <textarea v-model="source.approveComment" class="kim_input" type="text" placeholder="Input Approve Comment"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="field full_input align_top">
            <label>Attachment (Multi Files)</label>
            <kim-file-uploader
              class="mrcomment_file_uploader"
              :limit-count="10"
              @change="onFileChanged"
            />
          </div>
        </div>
      </div>
      <div v-if="showApproved">
        <div class="row_spacer"></div>
        <div class="row">
          <div class="field full_input align_top">
            <label>Approve Comment</label>
            <pre class="data">{{ item.APPROVE_COMMENT }}</pre>
          </div>
        </div>
        <div class="row">
          <div class="field">
            <label>Approved By</label>
            <label class="data">{{ item.APPROVED_BY }}</label>
          </div>
          <div class="spacer"></div>
          <div class="field">
            <label>Approved Date</label>
            <label class="data">{{ item.APPROVED_DATE }}</label>
          </div>
        </div>
        <div v-if="approvedFiles.length" class="row">
          <div class="field align_top">
            <label>Attachments</label>
            <j-mcr-comment-status-files
              :filesInfo="approvedFiles"
            />
          </div>
        </div>
      </div>
      <div class="row mrc"></div>    
    </div>

    <div class="bottom_wrapper">
      <div v-if="!(['Canceled', 'Rejected', 'Closed']).includes(status)" class="button_wrapper">
        <div class="button_box">
          <button type="button" class="j_button type01 sky" @click="onRequestAction(trackingId, 'JMcrCommentEdit')">Edit</button>
          <button type="button" class="j_button type01 pink" @click="cancel('Canceled')">Cancel</button>
        </div>
        <div class="button_box">
          <button v-if="rejectable" type="button" class="j_button type01 pink" @click="cancel('Rejected')">Reject</button>
          <button v-if="editableInProgress" type="button" class="j_button type01 sky" @click="submit('In Progress')">Submit (In Progress)</button>
          <button v-else-if="editableResponse" type="button" class="j_button type01 sky" @click="submit('Review')">Submit (For Review)</button>
          <button v-else-if="editableReview" type="button" class="j_button type01 sky" @click="submit('Approve')">Submit (For Approve)</button>
          <button v-else-if="editableApprove" type="button" class="j_button type01 sky" @click="submit('Closed')">Submit (Close)</button>
        </div>
      </div>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

    <input 
      type="file"
      id="multifile" 
      name="multifile"
      style="display:none;"
      multiple
    >

    <j-modal-slide-component 
      v-model="maOpened"
      :component="maComponent"
      :filters="maFilters"
      :target="maTarget"
      @request-reload="onRequestedReload"
    />
</div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import Moment from 'moment'

import __C from '@/primitives/_constant_'
import { China1Service, CommunicationService, DBSupportService } from '@/services'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Comment from '@/mixins/comment.mixin'
import JMcrCommentStatusFiles from '@/components/china1/JMcrCommentStatusFiles'

export default {
  name: 'j-modal-slide-component---mcr-comment',
  mixins: [
    Comment,
    SafeIdMixin
  ],
  components: {
    JMcrCommentStatusFiles
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    value: null,
  },
  data: () => ({
    china1Service: null,
    communicationService: null,
    item: {},
    viewpoints: [],
    source: {
      id: 0,
      status: '',
      respondedBy: '',
      respondedDate: '',
      responseComment: '',
      reviewedBy: '',
      reviewedDate: '',
      reviewComment: '',
      approvedBy: '',
      approvedDate: '',
      approveComment: '',
      canceledBy: '',
      canceledDate: '',
      cancelComment: '',
      rejectedBy: '',
      rejectedDate: '',
      rejectComment: '',
      files: []
    },

    close: false,
    msgOpen: false,
    msgInfo: {
      type: '',
      title: '',
      titleDescription: '',
      message: '',
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},
    no: () => {},

    ACCOUNT: null,
    locationTags: [],
    files: [],
    statusFileInfo: [],
    rejectHistory: [],
    displayHistory: false,
    
    // For the modal action
    maOpened: false,
    maComponent: '',
    maFilters: null,
    maTarget: null,
    maKeyValue: '',

    freeComments: 0,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    __C_() { return __C },

    editable() {
      // !(['Canceled', 'Rejected', 'Closed']).includes(this.status)
      return (
        ((['Draft', 'In Progress', 'Review']).includes(this.status) && this.permitReviewer) || 
        ((['Draft', 'In Progress', 'Review', 'Approve']).includes(this.status) && this.permitApprover)
      )      
    },
    rejectable() {
      return this.editableReview || this.editableApprove
    },
    editableInProgress() {
      return this.status == 'Draft' && this.permitDesigner
    },
    editableResponse() {
      return this.status == 'In Progress' && this.permitDesigner
    },
    editableReview() {
      return this.status == 'Review' && this.permitReviewer
    },
    editableApprove() {
      return this.status == 'Approve' && this.permitApprover
    },
    permitDesigner() {
      return this.account.permitLevel <= __C.ACCOUNT.PERMISSION_LEVEL.DESIGNER
    },
    permitReviewer() {
      return this.account.permitLevel <= __C.ACCOUNT.PERMISSION_LEVEL.REVIEWER
    },
    permitApprover() {
      return this.account.permitLevel <= __C.ACCOUNT.PERMISSION_LEVEL.APPROVER
    },

    showSeparator() { 
      return (
        (['In Progress', 'Review', 'Approve', 'Closed']).includes(this.status) ||
        this.item.RESPONSE_COMMENT ||
        this.item.REVIEW_COMMENT ||
        this.item.APPROVE_COMMENT
      )
    },
    showResponsed() {
      return (
        (['Review', 'Approve', 'Closed']).includes(this.status) ||
        this.item.RESPONSE_COMMENT
      )
    },
    showReviewed() {
      return (
        (['Approve', 'Closed']).includes(this.status) ||
        this.item.REVIEW_COMMENT
      )
    },
    showApproved() {
      return (
        (['Closed']).includes(this.status) ||
        this.item.APPROVE_COMMENT
      )
    },

    status() { return this.item.STATUS },
    trackingId() { return parseInt(this.filters.filters.IDX) || -1 },

    noHistory() { return this.filters.filters.NO_HISTORY },
    hasHistory() { return !this.noHistory && parseInt(this.item.REJECT_HISTORIES) ? true : false },

    respondedFiles() { return this.statusFileInfo.filter(info => info.status == 'Review') },
    reviewedFiles() { return this.statusFileInfo.filter(info => info.status == 'Approve') },
    approvedFiles() { return this.statusFileInfo.filter(info => info.status == 'Closed') },
  },
  created() {
    this.localId = this.id || 'j-comm-comment__' + this.safeId('')
    
    this.china1Service = new China1Service()
    this.communicationService = new CommunicationService()
    this.queryLibService = new DBSupportService()
  },
  mounted() {
    this.ACCOUNT = JSON.parse(localStorage.getItem('ACCOUNT'))
    this.setCommentOnChangedHandler({ MRC_HANDLER: this.onFreeCommentUpdated })
  },
  beforeDestroy () {
    this.removeCommentOnChangedHandler('MRC_HANDLER')
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setCommentTitle', 'setCommentsOpened', 'setCommentOnChangedHandler', 'removeCommentOnChangedHandler'
    ]),

    onComplete() {
      this.queryLibService.getSqlQueryResult({ 
        name: 'MR-ClosureTracking By Tracking ID.',
        filters: `IDX = ${this.trackingId}`
      }).then(res => {
        this.item = res ? res[0] : {}
        
        this.queryLibService.getSqlQueryResult({ 
          name: 'Viewpoints By Tracking ID.',
          filters: `A.TRACKING_ID = ${this.trackingId}`
        }).then(res => {
          this.viewpoints = res || []

          this.queryLibService.getSqlQueryResult({ 
            name: 'MRC Status Files By Tracking ID.',
            filters: `TRACKING_ID = ${this.trackingId}`
          }).then(res => {
            this.statusFileInfo = res || []

            if(!this.item.LOCATION) var tags = ['']
            else tags = this.item.LOCATION.split(',')

            this.queryLibService.getSqlQueryResult({ 
              name: 'Location Tag Details By Tags',
              filters: `TAG_NO IN ('${tags.join(`','`)}')`
            }).then(res => {
              this.locationTags = res || []

              if(this.hasHistory) this.queryLibService.getSqlQueryResult({ 
                name: 'MRC Reject History By Tracking No.',
                filters: `TRACKING_NO = '${this.item.TRACKING_NO}' AND IDX != ${this.trackingId}`
              }).then(res => {
                this.rejectHistory = res || []
              })

              this.getFreeCommentCount()
              this.$emit('complete', {
                name: `Model Review Comment - ${this.status}`,
                background: '#f4f5f5 !important',
                dimention: { height: 0, width: 750 }
              })
            })
          })
        })
      })
    },
    onFileChanged(files) {
      this.files = files
      this.source.files = this.files.map(f => ({
        name      : f.name,
        mimeType  : f.mimeType,
        fileType  : f.fileType,
        ext       : f.ext,
        dataUrl   : f.dataUrl,
        size      : f.size,
        dimention : f.dimention,
      }))
    },
    onFreeCommentUpdated() {
      this.getFreeCommentCount()
    },
     onRequestAction(trackingId, component) {
      this.maOpened = true
      this.maComponent = component
      this.maTarget = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: ''
      }
      this.maFilters = { filters: { 
        IDX: trackingId,
        NO_HISTORY: true 
      }}
    },
    onRequestedReload() {
      if(this.hasHistory) this.queryLibService.getSqlQueryResult({ 
        name: 'MRC Reject History By Tracking No.',
        filters: `TRACKING_NO = '${this.item.TRACKING_NO}' AND IDX != ${this.trackingId}`
      }).then(res => {
        this.rejectHistory = res || []
      })

      this.queryLibService.getSqlQueryResult({ 
        name: 'MR-ClosureTracking By Tracking ID.',
        filters: `IDX = '${this.trackingId}'`
      }).then(res => {
        this.item = res ? res[0] : {}
        
        this.queryLibService.getSqlQueryResult({ 
          name: 'Viewpoints By Tracking ID.',
          filters: `A.TRACKING_ID = ${this.trackingId}`
        }).then(res => {
          this.viewpoints = res || []

          if(!this.item.LOCATION) var tags = ['']
          else tags = this.item.LOCATION.split(',')

          this.queryLibService.getSqlQueryResult({ 
            name: 'Location Tag Details By Tags',
            filters: `TAG_NO IN ('${tags.join(`','`)}')`
          }).then(res => {
            this.locationTags = res || []
          })
        })
      })

      this.$emit('request-reload')
    },
    cancel(status) {
      let status_ = status == 'Canceled' ? 'cancel' : 'reject'

      this.yes = () => {
        this.source.id = this.trackingId
        this.source.status = status
        this.source[`${status_}edBy`] = this.ACCOUNT.userName
        this.source[`${status_}edDate`] = Moment(new Date().toLocaleString('en-US', {timeZone: 'Asia/Shanghai'})).format('YYYY-MM-DD')

        let params = new FormData()
        params.append('data', JSON.stringify(this.source))

        this.china1Service.updateMrCommentStatus(params).then(() => {
          this.msgOpen = false
          this.close = true
          this.$emit('close')
          this.$emit('request-reload')
          this.yes = () => {}
        })
      }
      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = `Do you want to ${status_} current MRC process?`
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = 'Yes'
      this.msgOpen = true
    },
    getFreeCommentCount() {
      this.communicationService.getComments((`MRC_${this.trackingId}`).toUpperCase()).then(res => {
        this.freeComments = res ? res.length : 0
      })
    },
    openComment() {
      this.setCommentTitle(`Free comment for the MRC No. ${this.item.TRACKING_NO}`)
      this.setCommentProps({ refCode: `MRC_${this.trackingId}` })
      this.onCommentOpen()
    },
    submit(status) {
      if(!this.validate(status)) return

      this.yes = () => {
        let data_ = Moment(new Date().toLocaleString('en-US', {timeZone: 'Asia/Shanghai'})).format('YYYY-MM-DD')

        this.source.id = this.trackingId
        this.source.status = status
        
        switch(status) {
          case 'Review': 
            this.source.respondedBy = this.ACCOUNT.userName
            this.source.respondedDate = data_
            break
          case 'Approve': 
            this.source.reviewedBy = this.ACCOUNT.userName
            this.source.reviewedDate = data_
            break
          case 'Closed': 
            this.source.approvedBy = this.ACCOUNT.userName
            this.source.approvedDate = data_
            break
          // default: resolve(this.getDocInfo(_file))
        }
        
        let params = new FormData()
        params.append('data', JSON.stringify(this.source))
        if((['Review', 'Approve', 'Closed']).includes(status)) this.files.forEach(f => { params.append('files', f) })

        this.china1Service.updateMrCommentStatus(params).then(() => {
          this.msgOpen = false
          this.close = true
          this.$emit('close')
          this.$emit('request-reload')
          this.yes = () => {}
        })
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "CONFIRMATION"
      this.msgInfo.titleDescription = "MR-Comment Submit."
      this.msgInfo.message = `Status will be changed to '${status}'.`
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = 'Yes'
      this.msgOpen = true
    },
    validate(status) {
      let validated = true

      if(status == 'Review') {
        if(this.source.responseComment) document.querySelector('.label_mr_response_comment').classList.remove('required')
        else {
          document.querySelector('.label_mr_response_comment').classList.add('required')
          validated = false
        }
      } else if(status == 'Approve') {
        if(this.source.reviewComment) document.querySelector('.label_mr_review_comment').classList.remove('required')
        else {
          document.querySelector('.label_mr_review_comment').classList.add('required')
          validated = false
        }
      } else if(status == 'Closed') {
        if(this.source.approveComment) document.querySelector('.label_mr_approve_comment').classList.remove('required')
        else {
          document.querySelector('.label_mr_approve_comment').classList.add('required')
          validated = false
        }
      }

      if(!validated) {
        this.yes = () => {
          this.msgOpen = false
          this.yes = () => { }
        }
        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Failed to validate"
        this.msgInfo.titleDescription = "Required field cannot be empty."
        this.msgInfo.message = "Please check the required field is not empty."
        this.msgInfo.button = [true, false, false]
        this.msgInfo.buttonText[0] = 'OK'
        this.msgOpen = true
      }

      return validated
    },

    run() {
      this.onComplete()
    }
  }
}
</script>

<style lang="stylus" scoped>
.j_mcr_comment {
  position: relative;
  height: 100%;
  transition: width .45s;

  .location_wrapper {
    display: flex;
    flex-wrap: wrap;

    .locatoin_box {
      display: flex;
      margin-right: .5rem;
      margin-bottom: .1rem;

      span {
        display: block;
        padding: .3rem;
        border: 1px solid #bbb;
        border-radius: 3px;
        cursor: default;
      }
    }
  }

  .button_container {
    position: absolute;
    top: 4rem;
    right: 0;
    z-index: 2; 

    padding: 1rem;
    
    .btn_history,
    .btn_history_close {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 3rem;
      height: 3rem;
      font-size: 1.8rem;
      border-radius: 50%;
      background-color: #f4f5f5;
    }
  }

  .reject_history {
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    z-index: 1; 

    padding: 5rem;
    border-bottom: 3rem solid #eee;
    background-color: #f4f5f5;

    span {
      display: block;
      text-align: center;
      width: 11rem;
      pointer-events: none;
    }

    .col1 {
      width: 13rem;
    }
    .col3,
    .col5 {
      width: 9rem;
    }

    .table_reject_history_header {
      display: flex;
      justify-content: center;
      align-items: center;

      height: 2.6rem;
      padding: .5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.075);
      background-color: #eee;
    }

    .table_reject_history_body {
      padding-top: .3rem;

      .table_reject_history_body_row {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 2.1rem;
        padding: 0 .5rem;

        .col1 {
          text-decoration: underline;
        }
      }
      .table_reject_history_body_row:hover {
        background-color: #eee;
      }
    }

    .table_reject_history_footer {
      display: flex;
      align-items: center;

      margin: 5rem -3rem -8rem -3rem;
      height: 3rem;
      color: #999;
    }
  }

  .mrcomment_file_uploader {
    width: 100%;
    margin-top: .3rem;
  }

  .kim_input {
    width: 20rem;
    height: 3rem;
    border: 1px solid #bbb;
    padding: .5rem;
    padding-right: 2.4rem;

    background-color: #fff;
    border-radius: 3px;
  }
  .kim_input:hover {
    border-color: #777;
  }
  .kim_input::placeholder {
    color: #ccc;
    font-style: italic;
  }
  textarea {
    height: 12rem !important;
  }

  .comment_status {
    color: #ff0000;
  }

  .header_warpper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2; 

    display: flex;
    align-items: center;

    height: 4rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    background-color: #eee;
    padding: 1rem;

    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);

    .comment_title {
      font-size: 1.2rem;
      font-weight: 900;
      margin-left: 1rem;
    }

    .comment_sub_title {
      font-weight: 500;
      color: #aeaeae;
      margin-left: 1rem;
    }
  }

  .bottom_wrapper {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;

    height: 5rem;
    border-top: 1px solid rgba(0, 0, 0, 0.075);
    background-color: #eee;
    padding: 1rem;

    box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.03);
  }

  .comment_content {
    position: absolute;
    top: 4rem;
    left: 0;
    right: 0;
    bottom: 5rem;
    overflow: auto;
    
    border-width: 0 .5rem;
    border-style: solid;
    border-color: #eee;
    padding: 3rem;
  }

  .row_spacer {
    margin: 4rem;
  }

  .comment_info_separator {
    border-top: 1px solid rgba(0, 0, 0, .2);
    border-bottom: 1px solid rgba(255, 255, 255, .75);
  }

  .button_wrapper {
    display: flex;
    justify-content: space-between;

    .button_box {
      display: flex;
    }
  }

  .row {
    display: flex;
    margin-top: 1.2rem;
    margin-bottom: 1.2rem;

    &.mrc {
      margin-top: 3rem;
      margin-bottom: 3rem;
      align-items: center;

      label {
        color: #aaa;
        min-width: 13rem;
      }

      .spacer {
        height: 1px;
        background-color: #ccc;
        margin-left: 7rem;
        margin-right: 7rem;
      }
    }

    .field {
      display: flex;
      align-items: center;

      &.full_input {
        width: 100%;

        .data {
          width: 100%;
        }

        input,
        textarea {
          width: 100%;
        }
      }

      &.align_top {
        align-items: flex-start;

        label {
          margin-top: .4rem;
        }
      }

      pre {
        min-height: 3rem;
        max-height: 12rem;
        padding: .5rem;
        border: 1px solid #999;
        border-radius: 3px;
        overflow: auto;
      }

      label {
        min-width: 12rem;
        color: #999;

        &.data {
          color: rgba(0,0,0,0.87);
          min-width: 20rem;
          height: 2rem;
          border-bottom: 1px solid #999;
        }
        &.required {
          color: #f54242;
          font-style: italic;
          font-weight: 500;
        }
      }

      input {
        width: 20rem;
      }

      .j_fileuploader {
        width: 20rem;
      }
    } 

    .vfield {
      display: flex;
      flex-direction: column;

      label {
        min-width: 12rem;
        color: #999;

        &.data {
          color: rgba(0,0,0,0.87);;
          min-width: 32rem;
          height: 2rem;
          border-bottom: 1px solid #999;
        }
      }
    }
  }

  .viewpoint_image {
    max-width: 12rem;
  }
}
</style>
