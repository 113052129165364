export const SafeIdMixin = {
  data: () => ({
    localId: null
  }),
  methods: {
    safeId(prefix) {
      const id = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      prefix = String(prefix || '').replace(/\s+/g, '_')
      return prefix ? `${prefix}_${id}` : id
    },
  },
}