import * as d3 from 'd3'
import __C from '../../includes/primitives/_constant_'
import SvgService from '../../src/services/svg.service'

export default {
  
  data: () => ({
    // Services
    svgService: null,
    
    //General data-props
    svg: null,
    chartArea: null,
    execRequested: [],

    resizableCanvasWidth: null,
    resizableCanvasHeight: null,
  }),



  computed: {
    parseDate() { return d3.timeParse('%Y-%m-%d') },
    canvasWidth() { return this.resizableCanvasWidth == null ? this.Canvas.CanvasWidth : this.resizableCanvasWidth },
    canvasHeight() { return this.resizableCanvasHeight == null ? this.Canvas.CanvasHeight : this.resizableCanvasHeight },
    
    __HOST_NAME() { return __C.HOST_NAME },
    __HOST_NAME_RESOURCE() { return `${__C.HOST_NAME_RESOURCE}/resources` }  
  },



  created() {

    this.svgService = new SvgService()

    d3.selection.prototype.moveToFront = function() {
      return this.each(function(){
        this.parentNode.appendChild(this);
      })
    }
    
    d3.selection.prototype.moveToBack = function() {  
      return this.each(function() { 
          var firstChild = this.parentNode.firstChild; 
          if (firstChild) { 
              this.parentNode.insertBefore(this, firstChild); 
          } 
      })
    }
  },



  methods: {
    /*
      clear()
      complete()
      exec()
      register()
      toJs()
      toJson()
      toJson()
      resizeCanvas()
    */

    clear() {
      if(!d3.select(`#${this.localId}`).empty()) d3.select(`#${this.localId}`).html('')
      this.svg = d3.select(`#${this.localId}`).append('svg')
    },


    complete() {
      setTimeout(() => {
        this.$emit('complete', this.localId)
        this.$emit('to-xml-string', this.toXmlString())
      }, 1000)
    },


    exec(requested, name='func') {
      if(requested.num != this.execRequested.length) return

      this.execRequested = []
      requested.func()
    },


    register(func, delay=250, name='func') {
      let requested = {
        name: name,
        num: this.execRequested.length + 1,
        time: new Date().getTime(),
        func: () => { func() }
      }
      this.execRequested.push(requested)
      setTimeout(() => { this.exec(requested, name) }, delay)
    },


    toJs() { return this.svgService.dom2js(d3.select(`#${this.localId}`)) },
    toJson() { return this.svgService.dom2json(d3.select(`#${this.localId}`)) },
    toXmlString() { return this.svgService.dom2xmlString(d3.select(`#${this.localId}`)) },

  

    resizeCanvas() {
      this.svg
      .attr('width', this.canvasWidth)
      .attr('height', this.canvasHeight)

      this.svg
      .select(`._canvas_border_background__${this.localId}`)
      .attr('width', this.canvasWidth)
      .attr('height', this.canvasHeight)
    }
  }
}