
export default {
  data: () => ({
    rawData:    [],
    
    config:     {},
    tube:       {
      scale: null,
    },
    columns:    {},
    filters:    {},

  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
      zoom()
    */

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.filters = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      let code =                sql1.REF_CODE.replace(/\s/g, '').split('/')
      this.config = {
        x:                  sql1.X,
        y:                  sql1.Y,
        tubeColor:          sql1.TUBE_COLOR,
        height:             sql1.TUBE_HEIGHT,
        tickness:           sql1.TUBE_TICKNESS,

        base: {
          height:           sql1.BASE_HEIGHT,
          padding:          sql1.BASE_PADDING,
        },

        value:       {
          TOTAL:            this.rawData[code[0]],
          ACTUAL:           this.rawData[code[1]],
          REMAIN:           this.rawData[code[2]],
          PROG:             this.rawData[code[3]],
        }
      }
      this.tube = {
        x:                  sql1.X,
        y:                  sql1.Y,
        color:              sql1.TUBE_COLOR,
        height:             sql1.TUBE_HEIGHT,
        tickness:           sql1.TUBE_TICKNESS,
        base: {
          tSize:            sql1.BASE_TEXT_SIZE,
          height:           sql1.BASE_HEIGHT,
          padding:          sql1.BASE_PADDING,
        },
      }
      this.columns = {
        code:               sql1.REF_CODE.replace(/\s/g, '').split('/'),
        title : {
          visible:          sql1.TITLE_VISIBLE,
          desc:             sql1.TITLE_DESC.replace(/\s/g, '').split('/'),
          tColor:           sql1.TITLE_COLOR.replace(/\s/g, '').split('/'),
          tSize:            sql1.TITLE_SIZE.replace(/\s/g, '').split('/').map(Number),
        },
        value : {
          tColor:           sql1.VALUE_COLOR.replace(/\s/g, '').split('/'),
          tSize:            sql1.VALUE_SIZE.replace(/\s/g, '').split('/').map(Number),
        },
        jsonMap:            sql1.JSON_MAP.replace(/\s/g, '').split('/'),
        jsonLink:           sql1.JSON_LINK.replace(/\s/g, '').split('/'),
        filter:             sql1.FINTER.replace(/\s/g, '').split('/'),
      }
      this.columns.values = {
        TOT:                this.rawData[code[0]],
        ACT:                this.rawData[code[1]],
        REM:                this.rawData[code[2]],
        PRO:                this.rawData[code[3]],
      }

      // console.log(this.columns)
    },
    
      
      


    
    set_ColorDefs(selection){

      let Shadow = selection
      .append('defs') // Shadow Left
      .append('radialGradient')
      .attr('id', `${this.localId}_Shadow`)

      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')


      let TubeBase = selection
      .append('defs') // d.base.height Cylinder
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeBase`)

      TubeBase.append('stop').attr('stop-color', '#a9adb0').attr('offset', '0.00').attr('stop-opacity', '1')
      TubeBase.append('stop').attr('stop-color', '#f6f6f6').attr('offset', '0.60').attr('stop-opacity', '1')
      TubeBase.append('stop').attr('stop-color', '#f6f6f6').attr('offset', '1.00').attr('stop-opacity', '1')

      let TubeBox = selection
      .append('defs') // Tube Box
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeBox`)

      TubeBox.append('stop').attr('stop-color', '#b4b4b4').attr('offset', '0.00').attr('stop-opacity', '0.50')
      TubeBox.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.30').attr('stop-opacity', '0.15')
      TubeBox.append('stop').attr('stop-color', '#fff').attr('offset', '0.60').attr('stop-opacity', '0.01')
      TubeBox.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.80').attr('stop-opacity', '0.15')
      TubeBox.append('stop').attr('stop-color', '#b4b4b4').attr('offset', '1.00').attr('stop-opacity', '0.50')

      let TubeCap = selection
      .append('defs') // Tube Cap
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeCap`)

      TubeCap.append('stop').attr('stop-color', '#efefef').attr('offset', '0.00').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.41').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#ececec').attr('offset', '0.59').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#e8e8e8').attr('offset', '1.00').attr('stop-opacity', '0.90')
    }
  }
}