import * as d3 from 'd3'

export default {
  data: () => ({
  }),
  methods: {
    drawBoxedSummarySingle(selection, d) {
      // Data Form -------------------
      //
      // d = {
      //   x         : 370,
      //   y         : 80,
      //   width     : 170,
      //   height    : 30,
      //   radius    : 3,
      //   bColor    : '#83D2F5',

      //   label     : 'Total',
      //   labelx    : 75,
      //   labely    : 18,
      //   labelSize : 9,
      //   labelColor: '#757575',

      //   value     : 116,
      //   valueSize : 18,
      //   valueColor: '#fff',
      //   valuex    : 65,
      //   valuey    : 16,
      // }

      let form__ = selection
      .append('g')
      .attr('transform', `translate(${d.x}, ${d.y})`)

      if(d.id) form__.attr('id', d.id)
      if(d.class) form__.attr('class', d.class)

      form__
      .append('rect')
      .attr('rx', d.radius)
      .attr('ry', d.radius)
      .style('width', d.width)
      .style('height', d.height)
      .style('fill', d.bColor)
      .style('opacity', d.opacity)

      if(d.value === 0 || d.value) {
        let text__ = form__
        .append('text') 
        .attr('transform', `translate(${d.valuex}, ${d.valuey})`)
        .style('alignment-baseline', 'middle')
        .text(d.value)

        if(d.valueSize) text__.style('font-size', d.valueSize)
        if(d.valueColor) text__.style('fill', d.valueColor)
        if(d.vanchor) text__.style('text-anchor', d.vanchor)
        if(d.linkable && d.value != 0) {
          let data__ = {
            x: d.valuex,
            y: d.valuey,
            request: d.request,
          }
          text__.style('cursor', 'pointer')
          text__.call(s => { this.callHandlerTextLinkAction(s, data__) })
        }
      }

      if(d.label) {
        let text__ = form__
        .append('text') 
        .attr('class', 'boxed_summary_single_label')
        .attr('transform', `translate(${d.labelx}, ${d.labely})`)
        .style('text-anchor', 'start')
        .style('alignment-baseline', 'middle')
        .text(d.label)

        if(d.labelSize) text__.style('font-size', d.labelSize)
        if(d.labelColor) text__.style('fill', d.labelColor)
      }
    }
  }
}