import * as d3 from 'd3'

export default {
  methods: {

    draw_WeekPerformance() {
      let area = this.area
      let title = this.title
      let weekend = this.weekend
      let weekday = this.weekday
      let value = this.value

      let sThis = this.rawData[1].cx
      let eThis = this.rawData[this.rawData.length-1].cx
      let mThis = (sThis + (eThis - sThis)/2)

      let weekStatus = this.svg
      .append('g')
      .attr('transform', `translate(${area.x}, ${area.y + .5})`)
      .style('font-family', area.font)

      weekStatus
      .append('text')
      .attr('x', this.rawData[0].cx).attr('y', 8)
      .style('font-size', title.tSize).style('fill', title.lastColor)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Last Week')

      weekStatus
      .append('text')
      .attr('x', mThis).attr('y', 8)
      .attr('font-size', title.tSize).attr('fill', title.thisColor)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('This Week')

      weekStatus
      .append('line')
      .attr('x1', sThis).attr('y1', 8).attr('x2', mThis - 30).attr('y2', 8)
      .style('stroke', '#757575').style('stroke-width', 1).style('stroke-opacity', .3)

      weekStatus
      .append('line')
      .attr('x1', mThis + 30).attr('y1', 8).attr('x2', eThis).attr('y2', 8)
      .style('stroke', '#757575').style('stroke-width', 1).style('stroke-opacity', .3)


      this.rawData.forEach((d,i)=> {

        weekStatus
        .append('text')
        .attr('x', d.cx).attr('y', 23)
        .attr('font-size', d.name == 'TOTAL' ? weekend.tSize : weekday.tSize)
        .attr('fill', d.name == 'TOTAL' ? weekend.tColor : weekday.tColor)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.name)

        weekStatus
        .append('rect')
        .attr('transform', `translate(${d.x}, 30)`)
        .attr('width', d.width).attr('height', 14)
        .attr('stroke-width',  d.name == 'TOTAL' ? weekend.sWidth : weekday.sWidth)
        .style('stroke-opacity', d.name == 'TOTAL' ? weekend.opacity : weekday.opacity)
        .attr('stroke', d.name == 'TOTAL' ? weekend.stroke : weekday.stroke)
        .attr('fill', d.name != 'TOTAL' && d.value > 0 ? '#F4F5F5' : '#fff')

        weekStatus
        .append('text')
        .attr('id', `weekValue_${this.localId}_${i}`)
        .attr('transform', `translate(${d.cx}, 38)`)
        .style('font-size', value.tSize).style('fill', value.tColor)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.value > 0 ? d.value : '')
        .style('cursor', area.link == 'Y' ? 'pointer' : 'default')
        .call(this.call_Link, d) // --->

      })

    },
  }
}
