export default {
  watch: {
    // Database: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    FloatBar: { handler(val) { this.register(this.draw) }, deep: true },
    FloatBarSeries: { handler(val) { this.register(this.draw) }, deep: true },
    FloatLine: { handler(val) { this.register(this.draw) }, deep: true },
    FloatBox: { handler(val) { this.register(this.draw) }, deep: true },
    Text: { handler(val) { this.register(this.draw) }, deep: true },
    DataItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },
  },
}
