import * as d3 from 'd3'
import Filter from '../../../../includes/primitives/Filter_Defs'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_Table() {

      
      let Table = this.svg
      Table
      .append('g')
      .attr('id', 'Table')
      .attr('transform', `translate(${300},${200})`)

      .append('image')
      .attr('id', 'TableHeader')
      .attr('xlink:href', require('../../../../src/assets/svg/Table_Header.svg'))
      .attr('x', 0).attr('y', 0).attr('width', 917).attr('height', 60)

      Table
      .append('line') 
      .attr('x1', 304).attr('y1', 260).attr('x2', 304).attr('y2', 400)
      .attr('stroke-width', 0.5).attr('stroke', '#707070')

      Table
      .append('line') 
      .attr('x1', 1213).attr('y1', 260).attr('x2', 1213).attr('y2', 400)
      .attr('stroke-width', 0.5).attr('stroke', '#707070')

      
  

    },



  }
}
