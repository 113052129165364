import * as d3 from 'd3'

export default {

  methods: {
    /*
      getNodeElValue(id, name)
      parseTranslate(target)
      handleImage(id_)
    */

    getNodeElValue(id, name) {
      if(!this.svg.select(id).node()) return 0
      return this.svg.select(id).node().getBoundingClientRect()[name]
    },

    
    parseTranslate(target) {
      // let translate = (target.attr('transform') || '').replace(/\s/g, '').match(/translate(.*?)\)/g)
      let translate = (target.attr('transform') || '').match(/translate(.*?)\)/g)
      let coordinate = translate[0].match(/-?\d+\.?(\d+)?/g)
      return {
        x: parseInt(coordinate[0]),
        y: parseInt(coordinate[1]),
      }
    },
    

    handleImage(id_) {
      return new Promise((resolve, reject) => {
        let img = new Image()
        img.onload = () => {
          resolve()
        }
        img.onerror = reject
        img.src = d3.select(id_).attr('href')
        
      })
    },

  


  }
}
