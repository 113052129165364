
import __M from 'moment'

import __C from '../../primitives/_constant_'
import LocalStorage from '@/services/local.storage/appservice.persistence'

import { ApplicationService, PageComponentService } from '../../services'
import IntendedDatagrid from './appservices/intended.datagrid.module'
import Dashboard from './appservices/dashboard.module'
import Customcomp from './appservices/customcomp.module'

const _db = new LocalStorage()
const _appService = new ApplicationService()
const _pageCompService = new PageComponentService()

export default {
  namespaced: true,
  modules: {
    idg: IntendedDatagrid,
    dash: Dashboard,
    cuscomp: Customcomp,
  },
  state: {
    // current child module on-service
    childId: '',    // idg | dash
    // for all the associated parties
    codePropagated: { id: -1, name: '', code: '' },
    // for the specified relatives
    prePropagated: {},

    // for the general purpose
    commentOpened: false,
    commentProps: {
      refCode: ''
    },
    commentOnChangedHandler: {},
    filemanagerOpened: false,
    messengerOpened: false,
    modalOpened: false,
    noticeOpened: false,
    commentsOpened: false,
    userInfoOpened: false,
    updatecomment: false,

    noticeOptions: false,
    urlOptions:'',

    popupImgOpened: false,
    imgPath: '',
    imgDimention: '',

    // for displaying left navigation
    recentDownloads: [],

    items: [],
    selectedItem: [],
    selectedItems: '',
    commentTitle: '',
    exportItems: '',
    nonFilter: '',
    filterStatus: {},
    photoitems: {},
    eqphotoitems: {},
    clear: false,
    eqclear: false,
    statusUpdate: false,
    eqstatusUpdate: false,
    wrapperOverflowHidden: false,
    wrapperOverflowWidth: 340,
    wrapperOverflowHeight: 321,    

    popupFreeOpened: false,
    popupFreeParams: {},
    popupHlsOpened: false,
    popupHlsParams: {},
    popupEqOpened: false,
    popupEqParams: {},
    popupHlEqOpened: false,
    popupHlEqParams: {},
    popupFreeCallback: {},

    isPdfExport3d: false,
    isExportInfo3d: false,
    exportInfo3d: '',

    sharedData: {},
    callbackData: {}
  },
  mutations: {
    initService (state) {
      state.filemanagerOpened = false
      state.messengerOpened = false
      state.modalOpened = false
      state.userInfoOpened = false
      state.popupFreeOpened = false
      state.popupHlsOpened = false
      state.popupEqOpened = false
      state.popupHlEqOpened = false
      state.roomPopupOpened = false
      state.popupImgOpened = false
      state.wrapperOverflowHidden = false

      state.idg.inputFilter = ''
      state.idg.filterString = ''
      state.idg.filteredValues = {}
      state.idg.resultSet = {
        info: {},
        filterChains: [],
        filterOptions: [],
        filterCompoment: {},
        chartValues: [],
        dataProps: [],
        dataList: [],
        summaryData: [],
      }

      state.dash.dimention = null
      state.dash.envValues = {}
      state.dash.filteredValues = {}
      state.dash.filteredPrev = ''
      state.dash.resultSet = {
        chartItem: {},
        chartItems: [],
        dashboard: {},
        filterChains: [],
        filterOptions: [],
        filterCompoment: {},
      },
      
      state.cuscomp.filteredValues = {}

      state.recentDownloads = []
    },

    closeAllFloats(state) {
      state.filemanagerOpened = false
      state.messengerOpened = false
      state.modalOpened = false
      state.userInfoOpened = false
      state.popupFreeOpened = false
      state.popupHlsOpened = false
      state.popupEqOpened = false
      state.popupHlEqOpened = false
      state.popupImgOpened = false
    },
    prePropagate (state, code) { state.prePropagated = code },
    codePropagate (state, code) { state.codePropagated = code },

    setChild (state, id) { state.childId = id },
    setItems (state, items) {state.items = items},
    setPhoItems (state, photoitems) {state.photoitems = photoitems},
    setClear (state, clear) {state.clear = clear},
    setEqPhoItems (state, eqphotoitems) {state.eqphotoitems = eqphotoitems},
    setEqClear (state, eqclear) {state.eqclear = eqclear},
    setStatusUp (state, statusUpdate) {state.statusUpdate = statusUpdate},
    setEqStatusUp (state, eqstatusUpdate) {state.eqstatusUpdate = eqstatusUpdate},
    setSelectItems (state, selectedItems) {state.selectedItems = selectedItems},
    setFilteredValues (state, values) { state[state.childId].filteredValues = values },
    setCommentOpened (state, status) { state.commentOpened = status },
    setCommentProps (state, props) { state.commentProps = props },
    // handler (Object) : { [handler_name]: [handler] }
    setCommentOnChangedHandler (state, handler) { 
      state.commentOnChangedHandler = {
        ...state.commentOnChangedHandler,
        ...handler
      }
    },
    removeCommentOnChangedHandler (state, name) { delete state.commentOnChangedHandler[name] },
    setFilemanagerOpened (state, status) { state.filemanagerOpened = status },
    setMessengerOpened (state, status) { state.messengerOpened = status },
    setNoticeOpened (state, status) { state.noticeOpened = status },
    setCommentTitle (state, status) { state.commentTitle = status},
    setUpdateComment (state, status) { state.updatecomment = status},
    setCommentsOpened (state, status) { state.commentsOpened = status },
    setNoticeOtipons (state, noticeOptions) {state.noticeOptions = noticeOptions},
    setUrlOptions (state, urlOptions) {state.urlOptions = urlOptions},
    setModalOpened (state, status) { state.modalOpened = status },
    setUserInfoOpened (state, status) { state.userInfoOpened = status },
    setPopupFreeCallback (state, data) { state.popupFreeCallback = data },
    setPopupFreeOpened (state, params) {
      state.popupFreeParams = params
      state.popupFreeOpened = params.open
    },
    setPopupHlsOpened (state, params) {
      state.popupHlsParams = params
      state.popupHlsOpened = params.open
    },
    setPopupEqOpened (state, params) { 
      state.popupEqParams = params
      state.popupEqOpened = params.open
    },
    setPopupHlEqOpened (state, params) {
      state.popupHlEqParams = params
      state.popupHlEqOpened = params.open
    },
    setImgPopupOpened (state, status) {
      state.imgPath = status.imgPath
      state.imgDimention = status.dimention
      state.popupImgOpened = status.open
    },
    setRecentDownloads (state, recent) { state.recentDownloads = recent },
    setNonFilter (state, status) { state.nonFilter = status },
    setModalExport (state, status) { state.exportItems = status },
    setWrapperOverflowHidden (state, hidden) { state.wrapperOverflowHidden = hidden },
    setWrapperOverflowWidth (state, width) { state.wrapperOverflowWidth = width },
    setWrapperOverflowDimention (state, dim) { 
      state.wrapperOverflowWidth = dim.width 
      state.wrapperOverflowHeight = dim.height 
    },
    setIsPdfExport3d (state, status) { state.isPdfExport3d = status },
    setIsExportInfo3d (state, status) { state.isExportInfo3d = status },
    setExportInfo3d (state, info) { state.exportInfo3d = info },

    setSharedData (state, data) { state.sharedData = data },
    setCallbackData (state, data) { state.callbackData = data },
  },
  getters: {
    getNoticeOpened: (state) => {
      return state.noticeOpened
    },
    getCommentsOpened: (state) => {
      return state.commentsOpened
    },
    getCommentTitle: (state) => {
      return state.commentTitle
    },
    getUpdateComment: (state) => {
      return state.updatecomment
    },
    commentOnChanged: (state) => {
      return state.commentOnChangedHandler
    },

    getItems: (state) => {
      return state.items
    },
    getModalExport: (state) => {
      return state.exportItems
    },
    getPhoItems: (state) => {
      return state.photoitems
    },
    getEqPhoItems: (state) => {
      return state.eqphotoitems
    },
    getClear: (state) => {
      return state.clear
    },
    getEqClear: (state) => {
      return state.eqclear
    },
    getStatusUp: (state) => {
      return state.statusUpdate
    },
    getEqStatusUp: (state) => {
      return state.eqstatusUpdate
    },
    getFilterStatus: (state) => {
      return state.selectedItem
    },
    getSelectItems: (state) => {
      return state.selectedItems
    },
    exportInfo: (state, getters ) => {
      return getters[`${state.childId}/exportInfo`]
    },
    filterChains: (state) => {
      if(!state.childId) return []
      if(![
        __C.STORE_NAMESPACE_KEY.DASHBOARD, 
        __C.STORE_NAMESPACE_KEY.DATATABLE, 
        __C.STORE_NAMESPACE_KEY.CUSTOM_COMPONENT
      ].includes(state.childId)) return []

      if(state.childId == __C.STORE_NAMESPACE_KEY.CUSTOM_COMPONENT) {
        if(state[state.childId].filterChains.length == 0) return []
        else var filterChains__ = state[state.childId].filterChains

      } else {
        if(
          !state[state.childId].resultSet.filterChains || 
          state[state.childId].resultSet.filterChains.length == 0
        ) return []
        else filterChains__ = state[state.childId].resultSet.filterChains
      }

      return filterChains__
    },
    filterOptions: (state) => {
      if(!state.childId) return []
      if(![
        __C.STORE_NAMESPACE_KEY.DASHBOARD, 
        __C.STORE_NAMESPACE_KEY.DATATABLE, 
        __C.STORE_NAMESPACE_KEY.CUSTOM_COMPONENT
      ].includes(state.childId)) return []

      if(state.childId == __C.STORE_NAMESPACE_KEY.CUSTOM_COMPONENT) {
        if(state[state.childId].filterOptions.length == 0) return []
        else var filterOptions__ = state[state.childId].filterOptions

      } else {
        if(
          !state[state.childId].resultSet.filterOptions || 
          state[state.childId].resultSet.filterOptions.length == 0
        ) return []
        else filterOptions__ = state[state.childId].resultSet.filterOptions
      }

      return filterOptions__
    },
    filteredValues: (state) => {
      if(!state.childId) return null
      if(![
        __C.STORE_NAMESPACE_KEY.DASHBOARD, 
        __C.STORE_NAMESPACE_KEY.DATATABLE, 
        __C.STORE_NAMESPACE_KEY.CUSTOM_COMPONENT
      ].includes(state.childId)) return null
      if(
        !state[state.childId].filteredValues || 
        Object.keys(state[state.childId].filteredValues).length == 0
      ) return null
      return state[state.childId].filteredValues
    },
    filterCompAvailable: (state, getters) => {
      if(state.childId != __C.STORE_NAMESPACE_KEY.DASHBOARD) return false
      return getters[`${state.childId}/filterCompAvailable`]
    }
  },
  actions: {
    getIdx({ state, rootState }, type) {
      if(!state.childId || state.childId != __C.STORE_NAMESPACE_KEY.DASHBOARD) {
        let err_ = `[USER: Not Available] Exporting '${type}' only available for the Dashboard.`
        return new Promise(r => r(err_))
      }
      let idx_ = rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id <= 0 ? 0 : rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id     
      
      return new Promise((resolve) => {
        resolve(idx_)
      })
    },
    createAction({ state, rootState, getters }, type) {
      
      if(!state.childId || state.childId != __C.STORE_NAMESPACE_KEY.DASHBOARD) {
        let err_ = `[USER: Not Available] Exporting '${type}' is only available for the Dashboard.`
        return new Promise(r => r(err_))
      }
      let idx_ = rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id <= 0 ? 0 : rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id
      let type_ = state[state.childId].type == 'PAGE' ? 'DASHBOARD' : state[state.childId].type == 'MODELVIEWER' ? 'MODELVIEWER' : 'PAGESVG'

      return new Promise((resolve) => {
        _pageCompService.getIdx(idx_, type_, idx => {
          if(!idx) {
            console.log(`[USER: #DEBUG] Cannot find Dashboard IDX.`)
            resolve(null)
            return
          }

          let action__ = JSON.stringify({
            component: type_ == 'DASHBOARD' ? __C.REQUEST_ACTION.COMPONENT_DASHBOARD : type_ == 'MODELVIEWER' ? __C.REQUEST_ACTION.COMPONENT_MODEL_VIEWER  : (__C.HOST_NAME.includes('dev') && (idx_ == 788 )) || (__C.HOST_NAME.includes('tortue') && (idx_ == 760 || idx_ == 761)) ? 'ServicePageSvgForMC' : __C.REQUEST_ACTION.COMPONENT_SVGPAGE,
            filter: {
              filters: getters[`${state.childId}/filteredValuesTrimed`],
              iFilters: { 
                filterString: '',
                inputFilter: ''
              }
            },
            target: {
              code: __C.PAGE_COMPONENT.TYPE_PAGE,
              type: __C.PAGE_COMPONENT.REQUEST_TYPE_EXPORT,
              id: idx,
              no: '',
            }
          })
          resolve(action__)
        })
      })
    },
    resetFilterChain({ state, rootState }, _params) {
      let childId___ = state.childId
      if(state.childId == __C.STORE_NAMESPACE_KEY.DASHBOARD && state.dash.type == __C.DASHBOARD.TYPE_CODE_PAGESVG) childId___ = 'pagesvg'
      let params = new URLSearchParams()
      params.append('idx', rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id)     // menu-item or sub-item's idx
      params.append('fNames', _params.fNames)           // targeted chain & columns
      params.append('fValues', _params.fValues)         // filtered columns & its values
      params.append('sender', childId___)

      console.log('[USER: #DEBUG# @idx    ]', rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id)
      console.log('[USER: #DEBUG# @fNames ]', _params.fNames)
      console.log('[USER: #DEBUG# @fValues]', _params.fValues)
      console.log('[USER: #DEBUG# @sender ]', childId___)

      return new Promise((resolve) => {
        _appService.requestFilteredChainOptions(
          params,
          (res) => {
            // responded data structure
            // {
            //   chainOptions: [ ...filterOptions ]
            // }

            // console.log(res.chainOptions)
            resolve(res.chainOptions)
          }
        )
      })
    },
    sendRequest({ state, dispatch }, type = 'init') {
      return dispatch(`${state.childId}/sendRequest`, type)
    },
    // Process just at this service.module directly, not in the child module.
    // @@params
    // values: {
    //   docID: <MENU IDX>,  // Should be removed before saving
    //   nonFilter: <NON-FILTER Y | N | '',
    // }
    updateFilterStatus ({ commit }, values) { 
      let docID_ = values.docID
      delete values.docID

      return new Promise((resolve) => {
        _db.updateFilterStatus(docID_, values).then(() => {
          commit('setNonFilter', values.nonFilter)
          resolve(true)
        })
      })
    },
    updateFilteredValues ({ state, dispatch }, values) { 
      return dispatch(`${state.childId}/updateFilteredValues`, values)
    },

    setFileBrowserValues({ state, dispatch }, values) {
      let filepath = (values.fbpath).split('/')
      let filename = decodeURI(filepath[filepath.length -1])

      dispatch('app/getFileBrowserValues', state.childId, { root: true }).then(res => {
        let recent__ = res.recent || []

        if(recent__.find(r => r.name == filename)) return
        if(recent__.length == 10) recent__.shift()

        recent__.push({ name: filename, fbname: values.fbname, fbpath: values.fbpath, date: __M().format('YYYY-MM-DD') })
        res.recent = recent__

        dispatch('app/updateFileBrowserValues', { id: state.childId, values: res }, { root: true })
      })
    },
    PhotoItemSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setPhoItems', values)
        resolve()
      })
    },
    PhotoStatusSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setStatusUp', values)
        resolve()
      })
    },
    EqPhotoItemSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setEqPhoItems', values)
        resolve()
      })
    },
    EqPhotoStatusSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setEqStatusUp', values)
        resolve()
      })
    },
    PhotoClearSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setClear', values)
        resolve()
      })
    },
    EqPhotoClearSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setEqClear', values)
        resolve()
      })
    },
    IsPdfExort3dSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setIsPdfExport3d', values)
        resolve()
      })
    },
    IsExportInfo3dSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setIsExportInfo3d', values)
        resolve()
      })
    },
    ExportInfo3dSet({ commit }, values) {
      return new Promise(resolve => {
        commit('setExportInfo3d', values)
        resolve()
      })
    },
    commentOnChanged({ state }, name) {
      if(name) {
        // console.log(state.commentOnChangedHandler[name])
      } else {
        let keys = Object.keys(state.commentOnChangedHandler)
        keys.forEach(k => {
          state.commentOnChangedHandler[k]()
        })
      }
    }
  }
}


