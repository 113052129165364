import * as d3 from 'd3'

export default {
  methods: {

    drawControlInfo() {
      this.cmmHeaderArea = this.svg
      // Back Button 
      this.cmmHeaderArea
      .append('image')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/btn_home.svg'))
      .attr('transform', 'translate(1520, 80)')
      .attr('width', 24).attr('height', 24)
      .style('cursor', 'pointer')
      //.call(this.callFuncBack) // ---> in Event.js
      .on('click', () => {
        // d: SEICODE_L1
        let request = this.backRequest(this.seiCodes.l1)
        this.$emit('request-action', request)
      })

      // home Button 
      this.cmmHeaderArea
      .append('image')
      .attr('xlink:href', require('../../../../../../src/assets/svg/icons/btn_back.svg'))
      .attr('transform', 'translate(1550, 80)')
      .attr('width', 24).attr('height', 24)
      .style('cursor', 'pointer')
      //.call(this.callFuncBack) // ---> in Event.js
      .on('click', () => {
        // d: SEICODE_L1
        let request = this.backRequest(this.seiCodes.l1)
        this.$emit('request-action', request)
      })

      // Tag Button Controller
      let tagctrl = this.cmmHeaderArea
      .append('g')
      .attr('id', 'tagbtn_control_wrapper')
      .attr('transform', `translate(1450, 80)`)
      .style('font-size', 10)
      .style('font-family', 'roboto')
      .style('cursor', 'pointer')  

      this.draw_Toggle(tagctrl)
    },

    drawSectorFromSvg() {
      this.loadSvg(this.svg, { 
        url: `/China1/Information_Guide/svg/${this.seiCodes.l1}.svg`, 
        x  : 0,
        y  : 0,
      }).then(svg_ => { 
        this.innerSvg = svg_
        this.innerSvg
        .style('opacity', 0)
        .transition().duration(400)
        .style('opacity', 1)
        //this.postLoadSEISector(this.seiCodes.l1)
        this.rebuildSEIInfo()
        this.drawHeaderInfo()
        this.drawSharedInfo()
        this.rebuildSEIBoxInfo() // for show the SEI L2 Area on mouseover
      })
    },

    // postLoadSEISector() {      
    //   this.rebuildSEIInfo()
    // },

    drawHeaderInfo() {
      this.cmmHeaderArea = this.svg
      .append('g')
      .attr('id', 'common_header_area')
      .attr('transform', 'translate(0, 0)')

      let data = this.DataItems[0].seiCodeL1.find(d => d.SEICODE_L1 == this.seiCodes.l1)
      if(!data) return

      let cmmHeaderTitleArea = this.cmmHeaderArea.append('g')
      .attr('transform', `translate(130, 14)`)

      // Unit Name
      cmmHeaderTitleArea
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', this.headerStyle.typeRect.width).attr('height', this.headerStyle.typeRect.height)
      .attr('fill', this.headerStyle.typeRect.rectFill)
      .attr('rx', this.headerStyle.typeRect.rx).attr('ry', this.headerStyle.typeRect.ry)
      .attr('stroke', this.headerStyle.typeRect.stroke)
      .attr('stroke-width', this.headerStyle.typeRect.strokeWidth)

      cmmHeaderTitleArea
      .append('text')
      .attr('transform', `translate(${this.headerStyle.typeRect.width/2}, ${this.headerStyle.typeRect.height/2+2})`)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .style('font-family', this.headerStyle.typeText.fontFamily)
      .style('font-size', this.headerStyle.typeText.fontSize)
      .style('font-weight', this.headerStyle.typeText.fontWeight)
      .style('fill', this.headerStyle.typeRect.unitTextFill)
      .text('Unit Name (装置名稱)')

      cmmHeaderTitleArea
      .append('text')
      .attr('transform', `translate(${this.headerStyle.typeRect.width + 20}, ${this.headerStyle.typeRect.height/2+2})`)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .style('font-family', this.headerStyle.typeText.fontFamily)
      .style('font-size', this.headerStyle.typeText.fontSize)
      .style('font-weight', this.headerStyle.typeText.fontWeight)
      .style('fill', this.headerStyle.typeRect.descTextFill)
      .text(`${data.SEICODE_L1_DESC_E} (${data.SEICODE_L1_DESC_C})`)
      //.text(data.SEICODE_L1_DESC_E) 

      // SEI Code
      let cmmHeaderSEIArea = this.cmmHeaderArea.append('g')
      .attr('transform', `translate(1330, 14)`)

      cmmHeaderSEIArea
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', this.headerStyle.typeRect.width).attr('height', this.headerStyle.typeRect.height)
      .attr('fill', this.headerStyle.typeRect.rectFill)
      .attr('rx', this.headerStyle.typeRect.rx).attr('ry', this.headerStyle.typeRect.ry)
      .attr('stroke', this.seiStyle.typeRect.stroke)
      .attr('stroke-width', this.headerStyle.typeRect.strokeWidth)

      cmmHeaderSEIArea
      .append('text')
      .attr('transform', `translate(${this.headerStyle.typeRect.width/2}, ${this.headerStyle.typeRect.height/2+2})`)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .style('font-family', this.headerStyle.typeText.fontFamily)
      .style('font-size', this.headerStyle.typeText.fontSize)
      .style('font-weight', this.headerStyle.typeText.fontWeight)
      .style('stroke', this.headerStyle.typeText.stroke)
      .style('fill', '#D92129')
      .text('SEI Code (装置代碼)')

      cmmHeaderSEIArea
      .append('text')
      .attr('class', 'sei_l1_code')
      .attr('transform', `translate(${this.headerStyle.typeRect.width + 20}, ${this.headerStyle.typeRect.height/2+2})`)
      .attr('alignment-baseline', 'middle')
      .style('font-family', this.headerStyle.typeText.fontFamily)
      .style('font-size', this.headerStyle.typeText.fontSize)
      .style('font-weight', this.headerStyle.typeText.fontWeight)
      .style('stroke', this.headerStyle.typeText.stroke)
      .style('fill', '#fff')
      .text(this.seiCodes.l1)
    },
    
    rebuildSEIInfo() {
      let tagLayer = this.innerSvg.select(`#TAG`)
      let codeLayer = this.innerSvg.select(`#CODE`)
      let lineLayer = this.innerSvg.select(`#LINE`)
      
      tagLayer.style('opacity', 0)
      codeLayer.style('opacity', 0)
      lineLayer.style('opacity', 0)

      if(tagLayer.empty() || codeLayer.empty()) {
        console.log('[DEBUG] Svg element #TAG|#CODE does not exist.')
        return
      }

      // Keep the tag position to re-position the sei-information
      let tagPostion = {}
      tagLayer.selectAll('text').each((_, i, a) => {
        let elText = d3.select(a[i])
        let text_ = elText.text().split('-')
        let no_ = text_[0]
        let direction_ = text_[1]

        tagPostion[no_] = direction_
      })
      // tagLayer.remove()

      codeLayer.selectAll('g').each((_, i, a) => {
        let group = d3.select(a[i])
        let elText = group.select('text')
        let elCircle = group.select('circle')
        
        if(elCircle.empty()) {
          var coordinate = group.select('path').node().getBBox()
          coordinate = {
            x: coordinate.x + this.seiStyle.CircleBorder.rx - 3,
            y: coordinate.y + this.seiStyle.CircleBorder.ry - 3,
          }
        } else {
          coordinate = {
            x: elCircle.attr('cx') ? Number(elCircle.attr('cx')) : 0,
            y: elCircle.attr('cy') ? Number(elCircle.attr('cy')) : 0,
          }
        }

        let no_ = elText.text()
        let idx = this.seiCodeInfo.findIndex(d => d.SVG_SN == parseInt(no_))
        if(idx < 0) {
          group.remove()
          console.log(`[DEBUG] Target data is not found against the code number ${no_}.`)
          return
        }

        let data = this.seiCodeInfo[idx]

        // The border of code provides two types Rect and ellipse.
        let g = this.innerSvg
        .append('g')
        .attr('class', `seigroup_${data.SEICODE_L2}`)
        .style('opacity', 0)

        // add constructor mark
        g.append('rect')
        .attr('transform', `translate(0, 0)`)
        .attr('width', this.seiStyle.typeRect.width).attr('height', this.seiStyle.typeRect.height)
        .attr('fill', this.seiStyle.typeRect.conFill)
        .attr('rx', 1).attr('ry', 1)
        .attr('stroke', this.seiStyle.typeRect.conStroke)
        .attr('stroke-width', 1)

        g.append('text')
        .attr('transform', `translate(${this.seiStyle.typeRect.width / 2}, 8)`)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#fff')
        .text(data.CON_CODE)

        // add jianli mark
        g.append('rect')
        .attr('transform', `translate(${this.seiStyle.typeRect.width+2}, 0)`)
        .attr('width', this.seiStyle.typeRect.width).attr('height', this.seiStyle.typeRect.height)
        .attr('fill', this.seiStyle.typeRect.jianliFill)
        .attr('rx', 1).attr('ry', 1)
        .attr('stroke', this.seiStyle.typeRect.jianliStroke)
        .attr('stroke-width', 1)

        g.append('text')
        .attr('transform', `translate(${(this.seiStyle.typeRect.width / 2) * 3 + 1}, 8)`)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto').style('font-size', 10)
        .style('fill', '#fff')
        .text(data.JIANLI_CODE)
        
        g.append('text')
        .attr('class', 'sei_description')
        .attr('transform', `translate(${(this.seiStyle.typeRect.width * 2 + 7)}, 8)`)
        .attr('fill', this.seiStyle.typeText.fontFill)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-family', this.seiStyle.typeText.fontFamily)
        .style('font-weight', this.seiStyle.typeText.fontWeight)
        .style('font-size', this.seiStyle.typeText.fontSize)
        .text(() => {
          return this.Toggle.status == 'EN' ?
          `${data.SEICODE_L2} ${data.SEICODE_L2_DESC_E}`
          :`${data.SEICODE_L2} ${data.SEICODE_L2_DESC_C}`
        })
        
        let elbox = g.node().getBBox()

        g.append('rect')
        .datum(data.SEICODE_L2)
        .attr('class',`seigroup_${data.SEICODE_L2}_guide`)
        .attr('width', elbox.width)
        .attr('height', elbox.height)
        .style('opacity', 0)
        .call(this.callFuncSEIInfoAction)
        
        g.attr('transform', `translate(${tagPostion[no_] == 'L' ? coordinate.x - parseInt(elbox.width) : coordinate.x}, ${coordinate.y - this.seiStyle.typeRect.height / 2})`)

        g
        .transition().duration(800)
        .style('opacity', 1)

        lineLayer
        .transition().duration(800)
        .style('opacity', 1)

      })
      // codeLayer.remove()
      //this.drawSharedInfo()

    },

    rebuildSEIBoxInfo() {
      let imageBoxLayer = this.innerSvg.select(`#IMAGEBOX`)
      if(imageBoxLayer.empty()) {
        console.log('[DEBUG] Svg element #IMAGEBOX does not exist.')
        return
      }
    },
    rebuildSEITag() {
      let tagLayer = this.innerSvg.select(`#TAG`)

      if(tagLayer.empty()) {
        console.log('[DEBUG] Svg element #TAG does not exist.')
        return
      }

      tagLayer.selectAll('text').each((_, i, a) => {
        let elText = d3.select(a[i])
        let matrix = (elText.attr('transform') || '').match(/matrix(.*?)\)/g)
        matrix = matrix[0].match(/-?\d+\.?(\d+)?/g)

        if(matrix.length == 6) var coordinate = {
          x: parseInt(matrix[4]),
          y: parseInt(matrix[5])
        }; else coordinate = {
          x: parseInt(matrix[6]),
          y: parseInt(matrix[7])
        }

        let text_ = elText.text().split('-')
        let no_ = text_[0]
        let direction_ = text_[1]
        let idx = this.seiCodeInfo.findIndex(d => d.NO == no_)
        if(idx < 0) return

        let data = this.seiCodeInfo[idx]
        this.innerSvg 
        .append('text')
        .attr('class', `tag_${data.SEICODE_L2}`)
        .attr('x', direction_ == 'L' ? coordinate.x - 2 : coordinate.x + 8)
        .attr('y', coordinate.y-1)
        .attr('fill', '#333')
        .attr('text-anchor', direction_ == 'L' ? 'end' : 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', 9)
        .style('cursor', 'pointer')
        // .call(s => { this.sendRequestAction(s, data) })
        .text(data.SEICODE_L2_DESC_E)
      })

      tagLayer.remove()
    },
    
    drawSharedInfo() {
      /**
       * Put Shared Data & Controllers (Tools) on the main SVG layer.
       */
      /**
       * Put Shared Data & Controllers (Tools) on the left side of the current page.
       */
      if(this.cmmSharedArea) this.cmmSharedArea.remove()

      this.cmmSharedArea = this.svg
      .append('g')
      .attr('id', 'common_shared_area')
      .attr('transform', 'translate(20, 70)')

      // Legend Critical ------------------------------------------
      let legend = this.cmmSharedArea
      .append('g')
      .attr('id', 'common_legend_area')
      .attr('transform', `translate(0, 50)`)

      legend
      .append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .style('width', 450)
      .style('height', 137)
      .style('stroke', '#f00')
      .style('stroke-width', 1)
      .style('fill', '#fff')
      .style('opacity', .3)

      legend
      .append('rect')
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('transform', `translate(5, 5)`)
      .style('width', 50)
      .style('height', 18)
      .style('fill', '#fff')
      .style('stroke', '#f00')
      .style('stroke-width', 1)
      .style('opacity', .2)

      legend
      .append('text')
      .attr('transform', `translate(10, 15)`)
      .style('font-weight', 700)
      .style('fill', '#D92129')
      .style('font-size', 12)
      .style('text-anchor', 'start')
      .style('alignment-baseline', 'middle')
      .text('Legend')

      // Legend : CC
      let legendCCBox = legend
      .append('g')
      .attr('id', 'common_legendBox_area')
      .attr('transform', `translate(10, 40)`)

      legendCCBox
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('width', this.seiStyle.typeRect.width).attr('height', this.seiStyle.typeRect.height)
      .attr('fill', this.seiStyle.typeRect.conFill)
      .attr('rx', 1).attr('ry', 1)
      .attr('stroke', this.seiStyle.typeRect.conStroke)
      .attr('stroke-width', 1)
    
      legendCCBox
      .append('text')
      .attr('transform', `translate(${this.seiStyle.typeRect.width / 2}, 8)`)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#fff')
      .text('CC')

      
      let conCodes = [...new Set(this.seiCodeInfo.map(d => d.CON_CODE))]
      conCodes.forEach((conCode, idx) => {
        let fdata = this.seiCodeInfo.find(info => info.CON_CODE == conCode)
        
        legendCCBox
        .append('text')
        .attr('transform', `translate(${this.seiStyle.typeRect.width + 6}, ${8 + (idx * 12)})`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', 11)
        .style('fill', '#4F4F4F')
        .style('font-weight', 600)
        // .style('opacity', 0)
        .text(this.Toggle.status == 'EN' ?
          `${fdata.CON_CODE} (${fdata.CON_DESC_E})`
         :`${fdata.CON_CODE} (${fdata.CON_DESC_C})`
        )
        // .transition().duration(500)
        // .style('opacity', 1)
      })
      let legendJianliBox = legend
      .append('g')
      .attr('id', 'common_legendBox_area')
      .attr('transform', `translate(10, 40)`)

      // Legend : JinaLi
      legendJianliBox
      .append('rect')
      .attr('transform', `translate(0, 50)`)
      .attr('width', this.seiStyle.typeRect.width).attr('height', this.seiStyle.typeRect.height)
      .attr('fill', this.seiStyle.typeRect.jianliFill)
      .attr('rx', 1).attr('ry', 1)
      .attr('stroke', this.seiStyle.typeRect.jianliStroke)
      .attr('stroke-width', 1)

      legendJianliBox
      .append('text')
      .attr('transform', `translate(${(this.seiStyle.typeRect.width / 2)}, 58)`)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .style('font-family', 'roboto')
      .style('font-size', 10)
      .style('fill', '#fff')
      .text('Jian Li')

      
      let jinaLiCodes = [...new Set(this.seiCodeInfo.map(d => d.JIANLI_CODE))]
      jinaLiCodes.forEach((jinaLiCode, idx) => {
        let fdata = this.seiCodeInfo.find(info => info.JIANLI_CODE == jinaLiCode)
        legendJianliBox
        .append('text')
        .attr('transform', `translate(${this.seiStyle.typeRect.width + 6}, ${58 + (idx * 12)})`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-family', 'roboto')
        .style('font-size', 11)
        .style('font-weight', 600)
        .style('fill', '#4F4F4F')
        // .style('opacity', 0)
        .text(this.Toggle.status == 'EN' ?
          `${fdata.JIANLI_CODE} (${fdata.JIANLI_DESC_E})`
          :`${fdata.JIANLI_CODE} (${fdata.JIANLI_DESC_C})`
        )
        // .transition().duration(500)
        // .style('opacity', 1)

      })

      // Keyplan --------------------------------------------------
      let keyplan = this.cmmSharedArea
      .append('g')
      .attr('id', 'common_keyplan_area')
      .attr('transform', `translate(0, 780)`)

      keyplan
      .append('rect')
      .attr('rx', 5)
      .attr('ry', 5)
      .style('width', 271)
      .style('height', 230)
      .style('fill', '#fff')
      .style('stroke', '#f00')
      .style('stroke-width', 1)
      .style('opacity', .2)

      keyplan
      .append('rect')
      .attr('rx', 2)
      .attr('ry', 2)
      .attr('transform', `translate(5, 5)`)
      .style('width', 50)
      .style('height', 18)
      .style('fill', '#fff')
      .style('stroke', '#f00')
      .style('stroke-width', 1)
      .style('opacity', .2)

      keyplan
      .append('text')
      .attr('transform', `translate(10, 15)`)
      .style('font-weight', 700)
      .style('font-size', 12)
      .style('fill', '#D92129')
      .style('text-anchor', 'start')
      .style('alignment-baseline', 'middle')
      .text('Keyplan')

      keyplan
      .append('image')
      .attr('class','el_keyplan')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/Information_Guide/svg/Keyplan/${this.seiCodes.l1}.png`)
      .attr('transform', `translate(10, 30)`)
      .style('width', 251)
      // ----------------------------------------------------------
    },
  }
}