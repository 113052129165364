import __C from '../../primitives/_constant_'

export default {
  DATABASES: {
    MASTER: '$jin',
    SYSTEM_CONFIG: '$jin/system',
    SERVICE_ADMINISTRATION: '$jin/service',
    ACCOUNT: '$jin/account',
    INTENDED_DATAGRID: '$jin/service/idg',
  },
  DOCUMENTS: {
    INFO: (id) => `info_${id}`,
    CONFIG_PROPS: (id) => `config_props_${id}`,
    CHART_FILTER: (id) => `chart_filter_${id}`,
    ENV_VALUES: (id) => `env_values_${id}`,
    FILTER_STATUS: (id) => `filter_status_${id}`,
    FILTERED_VALUES: (id) => `filtered_values_${id}`,
    BOARD_REPORT: (id) => `board_report_values_${id}`,
    FILE_BROWSER: (id) => `file_browser_values_${id}`,
  }
}