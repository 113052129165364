import __M from 'moment'
import Defs from '../../../../../includes/primitives/Color_Defs'

export default {
  methods: {
    setDefaultValues() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)

      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)

      let d = this.getMinMaxDate()

      this.timeline.startDate = d[0] + ' 00:00:00'
      this.timeline.endDate = d[1] + ' 23:59:59'
      // this.timeline.weekday = this.weekName
      this.timeline.weekday = this.weekdays[__M(this.cutoff).toDate().getDay()]

      this.setTimelineEnv()
    }
  }
}