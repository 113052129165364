import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'


// Locally declared common Data-Props & SVG Drawing Support

import lmx_LocalValues          from './set_LocalValues'
import lmx_Watching             from './Watching'

import lmx_Events               from './Events'

import lmx_Elements             from './draw_Elements'
import lmx_Timeline             from './draw_Timeline'
import lmx_Charte               from './draw_Chart'



export default {
  mixins: [
    gmx_zMixins, 
    
    // Local Mixins
    lmx_Watching,
    lmx_Events,
    
    lmx_LocalValues,
    lmx_Elements,
    lmx_Timeline,
    lmx_Charte,

  ],
}
