export default {
  watch: {
    // Canvas: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    // Text: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    // Queries: { handler(val) { if(!val || Object.keys(val).length === 0) return; this.register(this.draw); }, deep: true },
    // DataItems: { handler(val) { if(!val || val.length === 0) return; this.register(this.draw); }, deep: true },

    selectedItems() {
      this.$emit('selectedItems', this.selectedItems)
    },
    FilterValues: {
      handler(val) {
        if(!this.seiCodes.l1 || this.seiCodes.l1 == this.FilterValues.SEICODE_L1) return
        this.register(this.redraw)},
        deep: true
    },
  },
}
