import QueryLibService from '../../../../../src/services/database.support.service'

export default {
  data: () => ({
    // Services
    queryLib: null
  }),
  created() {
    this.queryLib = new QueryLibService()
  },
}