<template>
  <div class="three_d_viewer">
    <div class="page_wrapper">
      <div class="page_header">
        <div class="logo">
          <img src='@/assets/img/reportitle.png' alt="no-image">
          <div class="subtitle">
            <div class="main">3D Integrated Model Viewer</div>
            <div class="sub">Construction Status Overview</div>
          </div>
        </div>

        <div class="datasource_wrap" v-if="dataSource && dataSource.length > 0">
          <div class="datasource">
            <div class="datasource_item">
              <div style="color: rgb(68, 169, 223)">Datasource:</div>
            </div>
            <div class="datasource_item">
              <div v-for="(d,i) in dataSource[0]" :key="i">{{d}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="info_container">

        <!-- <div style="z-index:2; position: relative; left: 300px; top: 100px; color: #fff;">

          <div>
            <div style="display:flex;">
              <div style="width:65px;">Structure</div>
              <input type="checkbox" name="" id="">
            </div>
            <div style="display:flex;">
              <div style="width:65px;">Equipment</div>
              <input type="checkbox" name="" id="">
            </div>
            <div style="display:flex;">
              <div style="width:65px;">Pipe(ISO)</div>
              <input type="checkbox" name="" id="">
            </div>
          </div>

          <div>
            <v-btn elevation="2" fab rounded small color="#BDBDBD" style="opacity: 0.6; font-size: 20px;">B</v-btn>
            <v-btn elevation="2" fab rounded small color="#BDBDBD" style="opacity: 0.6; font-size: 20px;">F</v-btn>
            <v-btn elevation="2" fab rounded small color="#BDBDBD" style="opacity: 0.6; font-size: 20px;">S</v-btn>
            <v-btn elevation="2" fab rounded small color="#BDBDBD" style="opacity: 0.6; font-size: 20px;">R</v-btn>
          </div>

          <div style="display:flex;">
            <input type="text" name="" id="" placeholder="Tag No." 
              style="background-color: #EEEEEE; opacity: 0.7;width: 160px; padding: 5px; border: none; font-size: 10px; color: #000;">
            <button style="background-color: #E0E0E0;">
              <v-icon>mdi-magnify</v-icon>
            </button>
            
          </div>

          <div>
            <v-btn elevation="2" color="#757575" fab rounded small>
              <v-icon style="color:#fff;">mdi-fullscreen</v-icon>
            </v-btn>
          </div>
          
        </div> -->

        <div id="viewer_3d_content">

          <!-- hide dev -->
          <!-- <div class="error-notice">
            We are very sorry for the inconvenience to our service. <br/> From around 17:00 pm on Tuesday, October 9, 2021,<br/> An error has occurred on the 3D Streaming Server Connection,and that services are currently unavailable.<br/> We are working on a quick recovery, and we will keep you posted on any further updates.
          </div> -->

          <!-- hide tortue -->
          <iframe 
            v-if="runCirrusServer"
            :class="{'iframe_normal': !onFullscreen, 'iframe_full': onFullscreen}"
            :src="`${matchmaker_url}/?id=${userId}&token=${token}`"
            id="iframe_viewer_content"
            title="3D Model Viewer - Pipe Status"
            scrolling="no"
            allowfullscreen
          ></iframe>

          <div v-if="noCirrusServer" class='no_cirrus_server'>
            <div class="table_connected_users">
              <div class="header">
                <span class="title">All {{userIn}} users are in use.</span>
                <v-btn class="mx-2 retry" @click="reconnect2cirrus" fab small dark color="blue darken-2">
                  <v-icon>mdi-replay</v-icon>
                </v-btn>
              </div>
              <div>
                <table style="table-layout:fixed;">
                  <colgroup>
                      <col style="width:40px">
                      <col style="width:160px">
                      <col style="width:160px">
                      <col style="width:200px">
                      <col style="width:100px">
                      <col style="width:100px">
                  </colgroup>
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Connected(In minutes)</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Office</th>
                      <th>Position</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(user, i) in connectedUsers" :key="user.email" class="user">
                      <td>{{ i+1 }}</td>
                      <td>{{ user.connectedTime }}</td>
                      <td>{{ user.name }} {{user.instances > 1 ? `(${user.instances})` : ''}}</td>
                      <td>{{ user.email }}</td>
                      <td>{{ user.office }}</td>
                      <td>{{ user.position }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div v-if="sleepingServer" class='no_cirrus_server'>
            <div class="table_connected_users">
              <div class="header">
                <span class="title">Connection was closed</span>
                <v-btn class="mx-2 retry" @click="wakeupServer" fab small dark color="blue darken-2">
                  <v-icon>mdi-replay</v-icon>
                </v-btn>
              </div>
              <div class="sleeping">User actions are not detected during last {{minLimit}} minutes to keep the connection for use. <br/>Please reconnect to the server to discover it.</div>
            </div>
          </div>

          <div v-if="discCode != 'OV'" class="compo_detail_stage" :class="{'FullDetailStage': onFullscreen}">
            <!-- Detail Stage Wrapper -->
            <div class="summary_item_stage">
              <div class="stage_title_wrap">
                <div class="stage_title">{{discCode === 'ST' ? `Block Status` : discCode === 'ME' ? 'Equipment Status' : 'Test Packages Status'}}</div>
                <div class="pi_only">{{discCode === 'ST' ? '' : discCode === 'ME' ? '' : '- 3D Model Filterable.'}}</div>
              </div>
              <div class="stage_wrap">
                <div class="stage_total" @click="onRequestStageModal">
                  <div>Total</div>
                  <div>{{this.selectedStage && this.selectedStage.length > 0 ? this.selectedStage[0].TOTAL : ''}}</div>
                </div>
                <div class="stage_table">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <th v-for="h in stageTableHeader[discCode]" :key="h">
                          {{getStageTableHeader(h)}}
                        </th>
                      </tr>
                    </thead>
                    <tbody v-if="selectedStage.length > 0">
                      <tr v-for="(d, i) in selectedStage" :key="i+'available'">
                        <td>{{discCode === 'ST' ? `Inprogress` : `Available`}}</td>
                        <td v-for="(h, k) in stageTableHeader[discCode]" :key="k" 
                          :style="{cursor: discCode === 'PI' ? 'pointer' : 'default'}" 
                          @click="onRequestStageThreeD(h, 'AVAILABLE')">{{discCode === 'ST' ? d[`${h}_INPROGRESS`] : d[`${h}_AVAILABLE`]}}
                        </td>
                      </tr>
                      <tr v-for="(d, i) in selectedStage" :key="i+'comp'">
                        <td>Completed</td>
                        <td v-for="(h, k) in stageTableHeader[discCode]" :key="k" 
                          :style="{cursor: discCode === 'PI' ? 'pointer' : 'default'}" 
                          @click="onRequestStageThreeD(h, 'COMPLETED')">{{d[`${h}_COMPLETED`]}}
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr>
                        <td>{{discCode === 'ST' ? `Inprogress` : `Available`}}</td>
                        <td></td><td></td><td></td><td></td><td></td>
                      </tr>
                      <tr>
                        <td>Completed</td>
                        <td></td><td></td><td></td><td></td><td></td>
                      </tr>
                    </tbody>
                  </table>
                </div>  
              </div>    
            </div>
          </div>
          <div v-else class="compo_detail_stage overall_summary" :class="{'FullDetailStage': onFullscreen}">
            <div  class="summary_summary" v-for="(s, i) in overallSummary" :key="i">
              <div class="left">
                <img :src="getDiscSummaryImage(s, 'DISC_DESCR')">
              </div>
              <div class="right">
                <div class="right_up">{{s['DISC_DESCR']}}</div>
                <div class="right_down">
                  <div class="right_item">
                    <div class="right_key">Plan</div>
                    <div class="right_value">{{s.PLAN_PROG == null ? 0 :s.PLAN_PROG}}</div>
                  </div>
                  <div class="right_item">
                    <div class="right_key">Actual</div>
                    <div class="right_value">{{s.ACTUAL_PROG == null ? 0 :s.ACTUAL_PROG}}</div>
                  </div>
                  <div class="right_item">
                    <div class="right_key">Delta</div>
                    <div class="right_value">{{s.DELTA == null ? 0 :s.DELTA}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="onFullscreen" class="full_selectBox">
            <j-o-m-s-modules-fullscreen></j-o-m-s-modules-fullscreen>
          </div>

          <div class="summaryWrapper" :class="{'FullSummary': onFullscreen}">
            <div class="tabs_wrapper">
              <div class="line"></div>
              <div class="tabs"><div class="tab_item" :class="{'active': discCode == 'OV'}" @click="setSelectedDisc('OV')">Overall</div></div>
              <div class="tabs"><div class="tab_item" :class="{'active': discCode == 'ST'}" @click="setSelectedDisc('ST')">STR</div></div>
              <div class="tabs"><div class="tab_item" :class="{'active': discCode == 'ME'}" @click="setSelectedDisc('ME')">EQ</div></div>
              <div class="tabs"><div class="tab_item" :class="{'active': discCode == 'PI'}" @click="setSelectedDisc('PI')">PIP</div></div>
              <div class="line"></div>
            </div>
            <div>
              <div>
                
                <div v-if="discCode != 'OV'" class="summarys">
                  <div  class="summary_summary" v-for="(s, i) in ogmDiscSummary" :key="i">
                    <div class="left">
                      <img :src="getDiscSummaryImage(s, 'DISC_DESCR')">
                    </div>
                    <div class="right">
                      <div class="right_up">{{s['DISC_DESCR']}}</div>
                      <div class="right_down">
                        <div class="right_item">
                          <div class="right_key">Plan</div>
                          <div class="right_value">{{s.PLAN_PROG == null ? 0 :s.PLAN_PROG}}</div>
                        </div>
                        <div class="right_item">
                          <div class="right_key">Actual</div>
                          <div class="right_value">{{s.ACTUAL_PROG == null ? 0 :s.ACTUAL_PROG}}</div>
                        </div>
                        <div class="right_item">
                          <div class="right_key">Delta</div>
                          <div class="right_value">{{s.DELTA == null ? 0 :s.DELTA}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              
              <div v-if="discCode =='PI'" class="stage">
                <fieldset>
                  <legend>Stage</legend>
                  <div class="inner_stage">
                    <div :class="{'active': selectedPiStage == 'All'}" @click="changePiStage('All')">All</div>
                    <div :class="{'active': selectedPiStage == 'CON'}" @click="changePiStage('CON')">Module</div>
                    <div :class="{'active': selectedPiStage == 'IH'}" @click="changePiStage('IH')">IH</div>
                    <div :class="{'active': selectedPiStage == 'IM'}" @click="changePiStage('IM')">IM</div>
                  </div>
                </fieldset>
              </div> 

                <!-- Piping / Equipment / Structure Tab Status Wrapper summary_item -->
                <div class="status_wapper">

                  <div style="display:flex;justify-content: space-between;padding-right:5px;">
                    <div class="status_title">{{discCode === 'ST' ?`Block Erection` : discCode === 'ME' ? 'Equipment Installation' : discCode === 'OV' ? '':'Pipe (ISO) Installation'}}
                    </div>
                    <div style="font-size: 12px;font-style:italic;color:#0D47A1;">{{discCode === 'PI' ? 'Spool' : ''}}</div>
                  </div>
                  
                  <div class="table_wrap">
                    <table style="table-layout:fixed;">
                      <colgroup>
                          <col style="width:100px">
                          <col style="width:15px">
                          <col style="width:50px">
                          <col style="width:15px">
                          <col style="width:50px">
                          <col style="width:50px">
                      </colgroup>
                      <thead>
                        <tr>
                          <th></th>
                          <th><input type="checkbox" v-model="comCheckAll" @change="onComStatusChange('checked', comCheckAll)"></th>
                          <th>Com.</th>
                          <th><input type="checkbox" v-model="inproCheckAll" @change="onComStatusChange('inchecked', inproCheckAll)"></th>
                          <th>Inprog.</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, i) in statusSummary" :key="i">
                        <tr class="status_sub_title" v-if="item.ITEM == 'Total' && item.DISC == 'ST' && discCode == 'OV'">
                          <td colspan="6">Block Erection</td>
                        </tr>
                        <tr class="status_sub_title" v-if="item.ITEM == 'Total' && item.DISC == 'PI' && discCode == 'OV'">
                          <td colspan="5">Pipe (ISO) Installation</td>
                          <td style="font-style:italic;color:#0D47A1;">Spool</td>
                          </tr>
                        <tr class="status_sub_title" v-if="item.ITEM == 'Total' && item.DISC == 'ME' && discCode == 'OV'">
                          <td colspan="6">Equipment Installation</td>
                        </tr>
                        <tr v-if="item.ITEM == 'NotStarted'">
                          <td colspan="6" style="height: 5px !important;"></td>
                        </tr>
                        <tr class="status_rows">
                          <td class="cell status_name" :class="{'clickable': item.ITEM === 'Total'}"
                            @click="onRequestDetailThreeD(item)">{{ item.ITEM }}
                            <span v-if="item.ITEM == 'Total'" class="total_home_btn"><img src='@/assets/img/icon/home_btn.svg' alt="home-image"></span>
                          </td>
                          <td class="cell status_proc_cb">
                            <status-check-box
                              v-if="item.ITEM !== 'Total'"
                              v-model="item.checked"
                              :disc-code="item.DISC"
                              :status-code="item.STATUS"
                              @input="onStatusChanged"
                            ></status-check-box>
                          </td>
                          <td class="cell status_proc_count" @click="onRequestDetailModal(item, 'STATUS')">{{ item.QTY }}</td>
                          <td class="cell status_inproc_cb">
                            <status-check-box
                              v-if="item.ITEM !== 'NotStarted' && item.ITEM !== 'Total'"
                              v-model="item.inchecked"
                              :disc-code="item.DISC"
                              :status-code="item.IN_STATUS"
                              @input="onStatusChanged"
                            ></status-check-box>
                          </td>
                          <td class="cell status_inproc_count" @click="onRequestDetailModal(item, 'IN_STATUS')">{{ item.IN_QTY }}</td>
                          <td v-if="!['ST', 'ME'].includes(discCode)" class="cell status_proc_count" :class="{spoolLine: !['ST', 'ME'].includes(discCode)}" @click="onRequestDetailModal(item, 'SPOOL_QTY')">{{ item.SPOOL_QTY }}</td>
                        </tr>

                      </tbody>
                    </table>
                  </div>
              </div> 
              <div v-if="discCode =='PI'" class="size">
                <fieldset>
                  <legend>Size</legend>
                  <div class="inner_size">
                    <div :class="{'active': selectedSize == 'All'}" @click="changeSize('All')">All</div>
                    <div :class="{'active': selectedSize == 'LB'}" @click="changeSize('LB')">LB</div>
                    <div :class="{'active': selectedSize == 'SB'}" @click="changeSize('SB')">SB</div>
                  </div>
                </fieldset>
              </div> 

              <div>
                <div v-if="discCode !== 'OV'" class="summarys">
                  <div class="summary_summary" v-for="(s, i) in selectedComm" :key="i">
                    <div class="left">
                      <img :src="getDiscSummaryImage(s, 'COMMODITY')">
                    </div>
                    <div class="right">
                      <div class="right_up">{{s['COMMODITY']}}</div>
                      <div class="right_down">
                        <div class="right_item">
                          <div class="right_key">Plan</div>
                          <div class="right_value">{{s.PLAN_PROG == null ? 0 :s.PLAN_PROG}}</div>
                        </div>
                        <div class="right_item">
                          <div class="right_key">Actual</div>
                          <div class="right_value">{{s.ACTUAL_CUM_PROG == null ? 0 :s.ACTUAL_CUM_PROG}}</div>
                        </div>
                        <div class="right_item">
                          <div class="right_key">Delta</div>
                          <div class="right_value">{{s.DELTA == null ? 0 :s.DELTA}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div v-if="!noCirrusServer && !sleepingServer" class="current_wrap" :class="{'current_wrap_full': onFullscreen, 'current_wrap_normal': !onFullscreen }">
            <fieldset class="overall_condition" :style="{width: singleSelectedItem ? '760px' : '1020px'}">
              <legend>3D Model Filtering Conditions</legend>
              <div class="current_value" >
                <div v-for="(f, i) in getFilteringCondition" :key="i">
                  <span class="key">{{f.key}}</span>
                  <span :style="{'fontWeight': f.key == 'Module' ? 'bold' : 'default'}">{{f.value}}</span>
                </div>
              </div>
            </fieldset>
            
            <fieldset v-if="singleSelectedItem" class="selected_item_condition">
              <legend>Selected Item</legend>
              <div class="current_value" @click="requestSingleSelectedItem">
                <div>
                  <span class="selected_item" :class="{'active': singleSelectedItem}">{{singleSelectedItem}}</span>
                </div>
              </div>
            </fieldset>
          </div>

          <!-- VIEW POINT BUTTONS -->
          <div v-if="!noCirrusServer && !sleepingServer" class="view_btn_wrap" :class="{'btn_normal': !onFullscreen, 'btn_full': onFullscreen}">
            <v-btn fab small class="view_btn view_iso" color="#BDBDBD" @click="sendMessage('VIEW_ISO', '')"></v-btn>
            <v-btn fab small class="view_btn view_front" color="#BDBDBD" @click="sendMessage('VIEW_FRONT', '')"></v-btn>
            <v-btn fab small class="view_btn view_back" color="#BDBDBD" @click="sendMessage('VIEW_BACK', '')"></v-btn>
            <v-btn fab small class="view_btn view_top" color="#BDBDBD" @click="sendMessage('VIEW_TOP', '')"></v-btn>
            <v-btn fab small class="view_btn view_bottom" color="#BDBDBD" @click="sendMessage('VIEW_BOTTOM', '')"></v-btn>
            <v-btn fab small class="view_btn view_right" color="#BDBDBD" @click="sendMessage('VIEW_RIGHT', '')"></v-btn>
            <v-btn fab small class="view_btn view_left" color="#BDBDBD" @click="sendMessage('VIEW_LEFT', '')"></v-btn>
          </div>

          <v-btn v-if="!noCirrusServer && !onFullscreen && !sleepingServer" @click="requestFullScreen" 
            class="mx-2 full_btn" fab small dark color="grey darken-3">
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>

          <!-- <div class="view_slider" :class="{'slider_mormal': !onFullscreen, 'btn_full': onFullscreen}">
            <input id="view_slider_input" type="range" min="0" max="100" value="0" step="25" list="tickmarks" orient="vertical" @change="getSliderValue()">

            <datalist id="tickmarks">
              <option value="0"></option>
              <option value="25"></option>
              <option value="50"></option>
              <option value="75"></option>
              <option value="100"></option>
            </datalist>
          </div> -->


          <div v-if="!noCirrusServer && !onFullscreen && !sleepingServer" class="control"><img :src="getCotrolImage()" alt="control-image"></div>

          <div v-if="!noCirrusServer && !onFullscreen && !sleepingServer" class="viewpoint"><img :src="getViewpointImage()" alt="viewpoint-image"></div>
        </div>


      </div>
    </div>

    <j-modal-popup-3d-piping v-model="pipingOpen" :params="pipingParams"></j-modal-popup-3d-piping>
    <j-modal-popup-equipment v-model="popupEqOpen" :params="popupEqParams"></j-modal-popup-equipment>
    <j-modal-popup-3d-structure  v-model="strucOpen" :params="strucParams"></j-modal-popup-3d-structure>
    <j-modal-slide-component v-model="maOpened" :component="maComponent" :filters="maFilters" :target="maTarget" @request-action="onRequestActionThreeD"/>
  </div>
</template>
<script>
import '@/assets/stylus/ui/_3dcustom.styl'
import '@/assets/stylus/ui/component/_jModalviewerStatus.styl'

import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'

import __C from '../../primitives/_constant_'
import { SystemService, ThreedSupportService } from '@/services'
import { DBSupportService } from "@/services"
import JModalPopup3dPiping from '@/components/JModalPopup3DPiping'
import JModalPopup3dStructure from '@/components/JModalPopup3DStructure'
import JModalPopupEquipment from '@/components/JModalPopupEquipment'
import JOMSModulesFullscreen from '@/components/JOMSModulesFullscreen'
import StatusCheckBox from '@/components/controls/StatusCheckBox'
import Loading from '@/mixins/loading.mixin'

export default {
  name: "three_d_model_viewer",
  mixins: [
    Loading
  ],
  components: {
    JModalPopup3dPiping,
    JModalPopup3dStructure,
    JModalPopupEquipment,
    JOMSModulesFullscreen,
    StatusCheckBox
  },
  props: {
    filters: {
      type: Object,
      default: () => null
    },
    target: {
      type: Object,
      default: () => null
    }
  },
  data: () =>({
    systemService: null,
    queryLibService: null,
    threedSupportService: null,

    connectedUsers: [],

    modelLoaded: false,
    noCirrusServer: false,
    runCirrusServer: true,
    userIn: 0,

    sleepingServer: false,
    lastActionTime: null,

    pipingOpen: false,
    pipingParams: null,
    strucOpen:false,
    strucParams:null,
    popupEqOpen: false,
    popupEqParams: null,

    searchValue: null,

    maOpened: false,
    maComponent: '',
    maFilters: null,
    maTarget: null,

    ogmDiscSummary: [],
    selectedItem: [],
    selectedStage: [],
    selectedComm: [],

    overallSummary: [],

    selectedStatus: 'Total',
    selectedSize: null,
    discCode: 'OV',
    module: 'MW01',
    currentCondition: null,

    selectedPiStage: null,

    singleSelectedItem: null,

    onFullscreen: false,

    dataSource: [],

    timer: null,
    minLimit: 10,

    statusSummary: [],
    statusForMessage: {
      codes: [],
      size: 'All',
    },

    sliderVal: 0,

    comCheckAll: true,
    inproCheckAll: true,

    lastActionExport: '',
    
    stageTableHeader: {
      ST: ['FABRICATION', 'ASSEMBLY_BP', 'PAINTING', 'ASSEMBLY_AP', 'ERECTION'],
      ME: ['PO', 'FAT', 'IRN', 'DELIVERED', 'INSTALLED'],
      PI: ['LINE_CHECK', 'RELEASE_FOR_TEST', 'PRESSURE_TEST', 'INTERNAL_CLEANLINESS', 'REINSTATEMENT']
    },

    discDesc: {
      PI: 'Piping',
      ST: 'Structure',
      ME: 'Mechanical'
    },

    streamStatus: ''
  }),
  watch: {
    filteredValues(val, old) {
      // console.log('filtered values : ', val)
      if (val && val.MODULE) {
        
        // 21.05.17 수정
        // PDF Export를 위해 disc code, status(chekbox) 변경시 Update Filter 작동하여 3D Viewer 오작동
        // MODULE이 변경되었을 때만 작동하는것으로 변경
        if (old && val.MODULE == old.MODULE) return
        this.loading = true
        this.module = val.MODULE
        let filters = val.MODULE + ',' + this.discCode
        
        
        if (this.streamStatus != 'App Loaded') this.sendMessage('FOCUS_MODULE', filters)
        this.updateFilter({ MODULE: val.MODULE, DISC: this.discCode })

        // Module이 변경되었을경우 selected 초기화
        this.resetFilteringConditions()

        // Module이 변경되었을 경우 Disc List, Status Summary(chekcbox) 초기화
        this.getSelectDiscList(this.discCode)  
        this.getDiscStatusSummary(this.module, this.discCode)

      }
    },
    isPdfExport3d(val) {
      if (!val) return
      this.sendMessage('PDF_EXPORT', '')
      this.IsPdfExort3dSet(false)
    },
    singleSelectedItem(val) {
      if (val) this.wakeupServer()
    },
    searchValue(val) {
      if (val) this.updateFilter({ SEARCH_VALUE: val })
    },
    maOpened(val) {
      // for attatch slide modal
      setTimeout(() => {
        let slideModal = document.getElementById('j-modal-slide--content-wrapper')
        if(!slideModal) return

        if(this.onFullscreen && val) slideModal.style.right = '0'
        else if(this.onFullscreen && !val) slideModal.style.right = '7rem'
      })
    },

    discCode(val) {
      if (val) {
        this.selectedStatus = 'Total'
        this.selectedSize = val !== 'PI' ? null : 'All'
        this.selectedPiStage = val !== 'PI' ? null : 'All'
      }
    }

  },
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'isPdfExport3d', 
      'isExportInfo3d',
      'exportInfo3d',
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, ['filteredValues']),
    
    isExport() {
      return this.$route.path.includes('export')
    },
    userId() {
      return this.isExport ? '3dUser' : this.account.id
    },
    token() {
      return this.isExport ? this.$route.params.token : this.account.token
    },
    popupEqOpen: {
      get() { return this.popupEqOpened },
      set(val) { 
        this.setPopupEqOpened({
          open: val,
          queryid: 0,
          filters: null,
          data: {},
        })
      },
    },
    
    getFilteringCondition() {
      //현재 선택된 Filtering Condition
      let conditions = []

      if (this.module) conditions.push({key: 'Module', value: this.module})
      if (this.discCode) conditions.push({key: 'Discipline', value: this.discCode})
      if (this.currentCondition) conditions.push({key: this.currentCondition.key, value: this.currentCondition.value})
      if (this.searchValue) conditions.push({key: 'Search filter', value: this.searchValue})
      return conditions
    },

    matchmaker_url() {
      return __C.MATCHMATER_URL
    }
  },
  created() {
    // console.log(this.account.token)
    this.loading = true
    window.addEventListener('message', this.pixelStreamingMessage, false)

    this.queryLibService = new DBSupportService()
    this.systemService = new SystemService()
    this.threedSupportService = new ThreedSupportService()


    // for export pdf setting
    this.setChild(__C.STORE_NAMESPACE_KEY.DASHBOARD)
    this.setType('MODELVIEWER')
    this.setDimention({height: 930, width: 1325})  

    
    this.getDataSource()
    if (!this.isExport) this.checkSleeping()
  },
  mounted(){
    // console.log('IS MOUNTED: ', this.module)
    window.addEventListener('fullscreenchange', (e) => {
      this.wakeupServer()
      if (window.innerWidth !== e.target.clientWidth) {
        // Normal screen
        this.onFullscreen = false
        this.sendMessage('NORMAL_SCREEN', '')
      } else {
        // Full Screen
        this.onFullscreen = true
        this.sendMessage('FULL_SCREEN', '')
      }
    })


    // for notice error message
    setTimeout(() => {
      this.updateFilter({ MODULE: this.module })
      this.loading = false
    }, 1000)
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'updateFilteredValues',
      'IsPdfExort3dSet',
      'IsExportInfo3dSet',
      'ExportInfo3dSet'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.DASHBOARD, [ 'initService' ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [ 'setChild', 'setPopupEqOpened' ]),
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD, [ 'setDimention', 'setType' ]),
    
    updateFilter(values) {
      if (this.isExport) return
      
      // Update Filtered Value for Export PDF
      let filteredValues___ = { ...this.filteredValues }

      let lastActionExport = !(['EXPORT_INFO', 'ITEM_STATUS'].includes(Object.keys(values)[0])) ? { LAST_ACTION : Object.keys(values)[0] } : ''

      // if (lastActionExport == 'MODULE') this.updateFilteredValues({ ...values, ...lastActionExport })
      this.updateFilteredValues({ ...filteredValues___, ...values, ...lastActionExport })

    },
    async onExportMode() {
      let filters__ = this.filters

      let module = filters__.filters.MODULE ? filters__.filters.MODULE : this.module
      let discCode = filters__.filters.DISC ? filters__.filters.DISC : this.discCode
      let status = filters__.filters.ITEM_STATUS
      let datalist = filters__.filters.DATA_LIST
      let searchValue = filters__.filters.SEARCH_VALUE
      let exportInfo = filters__.filters.EXPORT_INFO

      let lastAction = filters__.filters.LAST_ACTION

      await this.setExportFocusModule(module, discCode)

      if (discCode) await this.setExportDisc(discCode)
      if (status) await this.setExportStatusSummary(status)
      if (datalist && lastAction == 'DATA_LIST') await this.setExportDataList(datalist)
      if (searchValue && lastAction == 'SEARCH_VALUE') await this.setExportSearchInput(searchValue)
      if (exportInfo) await this.setExportInfo(exportInfo)
      
    },
    setExportFocusModule(module, disc) {
      return new Promise(resolve => {
        this.module = module
        this.sendMessage('FOCUS_MODULE', module + ',' + disc)
        setTimeout(() => resolve(), 1500)
      })
    },
    setExportDisc(disc) {
      return new Promise(resolve => {
        this.setSelectedDisc(disc)
        setTimeout(() => resolve(), 1500)
      })
    },
    setExportStatusSummary(status) {
      return new Promise(resolve => {
        this.setCheckedStatus(status)
        setTimeout(() => resolve(), 1500)
      })
    },
    setExportDataList(datalist){
      return new Promise(resolve => {
        this.sendMessage('DATA_LIST', datalist)
        setTimeout(() => resolve(), 1500)
      })
    },
    setExportSearchInput(searchValue){
      return new Promise(resolve => {
        this.sendMessage('SEARCH_INPUT', searchValue)
        setTimeout(() => resolve(), 1500)
      })
    },
    setExportInfo(exportInfo){
      return new Promise(resolve => {
        this.sendMessage('PDF_PRINT', JSON.stringify(exportInfo))
        setTimeout(() => resolve(), 1500)
      })
    },
    
    sendMessage(sender, filters) {
      console.info(sender, filters)
      if (!document.getElementById('iframe_viewer_content')) return
      let iframeViewer = document.getElementById('iframe_viewer_content')
      let data = {
        sender: sender,
        filters: filters
      }

      iframeViewer.contentWindow.postMessage(data, '*')
    },
    setCheckedStatus(status) {
      // For Export PDF

      
      let codes = status.codes

      this.statusSummary = [ ...this.statusSummary ]

      this.statusSummary.forEach(item => {
        item.checked = codes.includes(item.STATUS)
        item.inchecked = codes.includes(item.IN_STATUS)
      })

      if (codes[0] == 'NONE') {
        this.comCheckAll = false
        this.inproCheckAll = false
      }

      if (codes.length == 1 && codes[0].includes('NOTSTARTED')) {
        this.comCheckAll = false
        this.inproCheckAll = false
      }

      this.sendMessage('ITEM_STATUS', JSON.stringify(status))
    },
    getSliderValue() {
      let rangeVal = document.getElementById('view_slider_input').value
      this.sendMessage('ZOOM', rangeVal)
    },
    getStatusSummary() {
      this.statusSummary = [ ...this.statusSummary ]

      this.statusForMessage.size = this.selectedSize
      this.statusForMessage.codes = []


      // Completed
      let filterChecked = this.statusSummary.filter(item => item.checked)
      
      let comNot = this.statusSummary.find(d => d.ITEM == 'NotStarted')


      if (this.discCode !== 'OV' && !comNot.checked && this.statusSummary.length -1 == filterChecked.length) this.comCheckAll = true
      else if (this.discCode == 'OV' && !comNot.checked && this.statusSummary.length -3 == filterChecked.length) this.comCheckAll = true
      else this.comCheckAll = false

      filterChecked.forEach(item => {
        if (item.STATUS == '') return // Total
        this.statusForMessage.codes.push(item.STATUS)
      })

      // In progress
      let fitlerInChecked = this.statusSummary.filter(item => item.inchecked)
      if (this.statusSummary.length == fitlerInChecked.length) this.inproCheckAll = true
      else this.inproCheckAll = false

      fitlerInChecked.forEach(item => {
        if (item.IN_STATUS == '' || item.IN_STATUS == 'NOTSTARTED') return  // Total, Notstarted
        this.statusForMessage.codes.push(item.IN_STATUS)
      })

      if (!this.comCheckAll && !this.inproCheckAll && this.statusForMessage.codes.length == 0) this.statusForMessage.codes = ['NONE']
      // console.log(JSON.parse(JSON.stringify(this.statusForMessage)))
      // for pdf export
      this.updateFilter({ ITEM_STATUS: JSON.parse(JSON.stringify(this.statusForMessage)) })
    },
    onAllStatusChange() {
      this.statusSummary.forEach(item => {
        item.checked = item.ITEM == 'NotStarted' ? false : true
        item.inchecked = true
      })
      this.statusSummary = [ ...this.statusSummary ]

      this.onStatusChanged()
    },
    onComStatusChange(col, value) {
      // checked || inchecked , true || false
      this.statusSummary.forEach(item => {
        if (item.ITEM != 'NotStarted') item[col] = value
        if (item.ITEM == 'NotStarted') item.checked = false

      })
      this.statusSummary = [ ...this.statusSummary ]

      this.onStatusChanged()
    },

    onStatusChanged() {
      this.wakeupServer()

      this.statusSummary = [ ...this.statusSummary ]

      this.statusForMessage.size = this.selectedSize
      this.statusForMessage.stage = this.selectedPiStage
      this.statusForMessage.codes = []


      // Completed
      let filterChecked = this.statusSummary.filter(item => item.checked)
      
      let comNot = this.statusSummary.find(d => d.ITEM == 'NotStarted')


      if (this.discCode !== 'OV' && !comNot.checked && this.statusSummary.length -1 == filterChecked.length) this.comCheckAll = true
      else if (this.discCode == 'OV' && !comNot.checked && this.statusSummary.length -3 == filterChecked.length) this.comCheckAll = true
      else this.comCheckAll = false

      filterChecked.forEach(item => {
        if (item.STATUS == '') return // Total
        this.statusForMessage.codes.push(item.STATUS)
      })

      // In progress
      let fitlerInChecked = this.statusSummary.filter(item => item.inchecked)
      if (this.statusSummary.length == fitlerInChecked.length) this.inproCheckAll = true
      else this.inproCheckAll = false

      fitlerInChecked.forEach(item => {
        if (item.IN_STATUS == '' || item.IN_STATUS == 'NOTSTARTED') return  // Total, Notstarted
        this.statusForMessage.codes.push(item.IN_STATUS)
      })

      if (!this.comCheckAll && !this.inproCheckAll && this.statusForMessage.codes.length == 0) this.statusForMessage.codes = ['NONE']

      this.sendMessage('ITEM_STATUS', JSON.stringify(this.statusForMessage))

      // for pdf export
      this.updateFilter({ ITEM_STATUS: JSON.parse(JSON.stringify(this.statusForMessage)) })

    },
    onRequestedAction(ma) {
      this.maComponent = ma.maComponent
      this.maFilters = ma.maFilters
      this.maTarget = ma.maTarget
      this.maOpened = true

      this.wakeupServer()
    },
    onRequestDetailThreeD(val){
      if (val.ITEM != 'Total') return

      let filters = `${!val.STATUS ? 'TOTAL' : val.STATUS}${this.selectedSize ? ',' + this.selectedSize : ''}`
      let conArr = filters.split(',')
      let condition = `${val.ITEM}${conArr[1] ?  ' ('+conArr[1]+')' : ''}`
      this.currentCondition = {key: 'Status', value: condition}

      this.selectedStatus = val.ITEM

      this.onAllStatusChange()

      this.wakeupServer()

    },
    onRequestDetailModal(val, status){
      if (status == 'STATUS' && val.QTY === '0') return
      if (status == 'IN_STATUS' && val.IN_QTY === '0') return
      if (status == 'SPOOL_QTY' && val.SPOOL_QTY === '0') return

      let modalId = val.DISC == 'PI' ? status == 'SPOOL_QTY' ? 240 : 227 : val.DISC == 'ME' ? 229 : 228

      let data = {
        maComponent: 'ServiceDatatable',
        maFilters: {
          filters: {
            DISC: val.DISC,
            GWBS_LV3:this.module
          },
          iFilters: {
            inputFilter: '',
            filterString: ''
          }
        },
        maTarget: {
          code: __C.PAGE_COMPONENT.TYPE_MODAL,
          type: '',
          id: modalId,
          no: '',
        }
      }
      if(status == 'SPOOL_QTY' && val.ITEM !== 'Total') { data.maFilters.filters.STATUS = val.STATUS }
      else { if (val.ITEM !== 'Total') data.maFilters.filters.STATUS = val[status]}

      if(this.selectedSize && this.selectedSize !== 'All') data.maFilters.filters.SIZE = this.selectedSize
      if(this.selectedPiStage && this.selectedPiStage !== 'All') data.maFilters.filters.STAGE = this.selectedPiStage

      this.onRequestedAction(data)

      let filters = `${!val.STATUS ? 'TOTAL' : val.STATUS}${this.selectedSize ? ',' + this.selectedSize : ''}`
      // this.sendMessage('ITEM_STATUS', filters)
      let conArr = filters.split(',')
      let condition = `${val.ITEM}${conArr[1] ?  ' ('+conArr[1]+')' : ''}`
      this.currentCondition = {key: 'Status', value: condition}

      this.selectedStatus = val.ITEM
    },
    onRequestStageThreeD(h, status){
      // Send: Piping Stage Table 
      if (this.discCode !== 'PI') return
      let data = {
        sender: 'TEST_PACKAGE',
        filters: h + '_' + status,
        condition: this.getStageTableHeader(h),
        status: status
      }

      this.sendMessage(data.sender, data.filters)

      this.searchValue = null

      this.resetFilteringConditions()

      let key = ''
      let condition = ''
      
      key = 'Stage Status'
      condition = `${data.condition}  ${data.status === 'COMPLETED' ? 'Completed' : 'Available'}`
      this.currentCondition = {key: key, value: condition}

      this.getSelectDiscList(this.discCode)
    },
    onRequestStageModal(){
      let data = {
        maComponent: 'ServiceDatatable',
        maFilters: {
          filters: {
            GWBS_LV3: this.module,
          },
          iFilters: {
            inputFilter: '',
            filterString: ''
          }
        },
        maTarget: {
          code: __C.PAGE_COMPONENT.TYPE_MODAL,
          type: '',
          id: this.discCode == 'PI' ? 226 : this.discCode == 'ME' ? 229 : 228,
          no: '',
        }
      }
      this.onRequestedAction(data)

      this.wakeupServer()
    },
    onRequestActionThreeD(val) {
      // Request data in Modal - TEST_PACKAGE || TAG_NO
      if (!val) return
      
      let keys = Object.keys(val.action.filters)[0]
      
      this.sendMessage(val.action.sender, keys + ',' + val.action.filters[keys])

      // DATALIST, For Export PDF
      this.updateFilter({ DATA_LIST: `${keys},${val.action.filters[keys]}` })

      // this.resetFilteringConditions()

      let condition = val.action.filters[keys]
      this.currentCondition = {key: keys === 'TEST_PACKAGE' ? 'Test Package' : 'Tag Number', value: condition}

      this.wakeupServer()
    },
    requestSingleSelectedItem() {
      // TEST: 'MW01DC-WF-8010071-02'
      let tagNumber = this.singleSelectedItem ? this.singleSelectedItem : 'MW01DC-WF-8010071-02'
      this.sendMessage('DATA_LIST', `TAG_NO,${tagNumber}`)
      this.updateFilter({ DATA_LIST: `TAG_NO,${tagNumber}` })

    },
    requestFullScreen(){
      document.getElementById('viewer_3d_content').requestFullscreen()
    },
    resetFilteringConditions(){
      // All filter condition reset
      this.currentCondition = ''
      this.searchValue = null
      this.selectedStatus = 'Total'
      this.selectedSize = this.discCode !== 'PI' ? null : 'All'
      this.selectedPiStage = this.discCode !== 'PI' ? null : 'All'
    },
    
    setSelectedDisc(disc) {
      // Disc 선택 - Summary List, Chekbox List 가져오기

      this.discCode = disc
      
      this.resetFilteringConditions()

      this.getSelectDiscList(disc)
      this.getDiscStatusSummary(this.module, disc)
      
      // fix me
      this.sendMessage('ACTIVATE_DISCIPLINE', disc)
    

      this.updateFilter({ DISC: disc })

    },
    getDiscStatusSummary(module, discode) {
      let filters = `GWBS_LV3 = '${module}'`
      if(discode != 'OV') filters = filters + ` AND DISC = '${discode}'`

      this.queryLibService.getSqlQueryResult({ 
        idx: 19, 
        name: 'Object Status Summary',
        filters: filters
      }).then(res => {
        this.statusSummary = res ? res : []

        this.statusSummary.forEach(item => {
          item.checked = item.ITEM !== 'NotStarted' ? true : false
          item.inchecked = true
        })
      })

      this.comCheckAll = true
      this.inproCheckAll = true
    },
    getSelectDiscList(code) {
      let discDesc = this.discDesc[code]
      let module = this.module
      
      this.wakeupServer()

      if (code == 'OV') {
        // select된 disc가 Overall 일 경우 상단 disc별 summary 가져옴
        this.queryLibService.getSqlQueryResult({ 
          idx: 18, 
          name: 'Object Overall Summary',
          filters: `GWBS_LV3 = '${module}'`
        }).then(res => {
          this.overallSummary = res ? res : []
        })

      } else {
        // Str, Eq, Pip

        // Disc Tab 별 좌측 상단 Disc Summary
        this.queryLibService.getSqlQueryResult({ 
          idx: 25, 
          name: 'OGM Disc Summary',
          filters: `[DISC_DESCR] = '${discDesc}' AND [GWBS_LV3] = '${module}'`
        }).then(res => {
          this.ogmDiscSummary = res ? res : []
        })

        // 하단 Commodity Summary
        this.queryLibService.getSqlQueryResult({
          idx: 29, 
          name: 'OGM Commodity Summary',
          filters: `[DISC_DESCR] = '${discDesc}' AND [GWBS_LV3] = '${module}'`
        }).then(res => {
          this.selectedComm = res ? res : []
        })

        // Disc별 Stage Summary
        if (code == 'ST') {
          this.queryLibService.getSqlQueryResult({
            idx: 26, 
            name: 'Structure Stage Summary',
            filters: `[DISC] = '${code}' AND [GWBS_LV3] = '${module}'`
          }).then(res => {
            this.selectedStage = res ? res : []
          })
        } else if (code == 'ME') {
          this.queryLibService.getSqlQueryResult({
            idx: 27, 
            name: 'Mechanical Stage Summary',
            filters: `[DISC] = '${code}' AND [GWBS_LV3] = '${module}'`
          }).then(res => {
            this.selectedStage = res ? res : []
          })
        } else {
          // 현재 값 없음, 값 들어오면 확인
          this.queryLibService.getSqlQueryResult({
            idx: 28, 
            name: 'Piping Stage Summary',
            filters: ` [GWBS_LV3] = '${module}'`
          }).then(res => {
            this.selectedStage = res ? res : []
          })
        }

      }
      
    },
    changePiStage(stage) {
      this.selectedPiStage = stage

      let filters = ` GWBS_LV3 = '${this.module}' `
      if (stage != 'All') filters += `AND STAGE = '${stage}'`
      if (this.selectedSize != 'All' && this.selectedSize != null) filters += `AND SIZE = '${this.selectedSize}'`

      this.queryLibService.getSqlQueryResult({ 
        idx: 20, 
        name: 'Piping Status Summary',
        filters: filters
      }).then(res => {
        if (!res) {
          this.statusSummary = []
          return
        } else {
          this.statusSummary = []
          this.statusSummary = res
        }

        this.statusSummary.forEach(item => {
          item.checked = item.ITEM !== 'NotStarted' ? true : false
          item.inchecked = true
        })
      })

      this.onAllStatusChange()

      this.wakeupServer()
    },
    changeSize(size) {
      this.selectedSize = size

      let filters = ` GWBS_LV3 = '${this.module}' `
      if (size != 'All') filters += `AND SIZE = '${size}'`

      if (this.selectedPiStage != 'All' && this.selectedPiStage != null) filters += ` AND STAGE = '${this.selectedPiStage}'`

      this.queryLibService.getSqlQueryResult({ 
        idx: 20, 
        name: 'Piping Status Summary',
        filters: filters
      }).then(res => {
        if (!res) {
          this.statusSummary = []
          return
        } else {
          this.statusSummary = []
          this.statusSummary = res
        }

        this.statusSummary.forEach(item => {
          item.checked = item.ITEM !== 'NotStarted' ? true : false
          item.inchecked = true
        })
      })

      this.onAllStatusChange()

      this.wakeupServer()
    },
    pixelStreamingMessage(message) {
      this.showConnectedUsers()

      let res = message.data.responseName
      this.streamStatus = res
      if(res == 'No Cirrus Server') {
        this.userIn = message.data.value
        this.noCirrusServer = true
        this.runCirrusServer = false
        this.showConnectedUsers()

        this.loading = false
      } else if(res == 'App Loaded') {
        this.noCirrusServer = false
        this.loading = true

        this.updateFilteredValues({})

        setTimeout(() => {
          this.sendMessage('FOCUS_MODULE', this.module + ',' + this.discCode)

          this.updateFilter({ MODULE: this.module })
        }, 1000)

        setTimeout(() => {
          this.loading = false
          if (this.isExport) this.onExportMode()
        }, 1200)

      } else if(res == 'Selected Item') {
        // When Single Clicked

        if (!message.data.value.TAG_NO) return
        this.singleSelectedItem = message.data.value.TAG_NO

      } else if(res == 'Object Data Item') {
        // When Dobule Clicked
        // There are piping, Mechanical, and Structure as conditions of selectedDisc.
        if (!message.data.value.TAG_NO) return

        this.singleSelectedItem = message.data.value.TAG_NO

        if (this.discCode === 'OV') {
          if (message.data.value.DISC === 'PI') this.getPipeDetailInfo(message.data.value.TAG_NO)
          else if (message.data.value.DISC === 'ME') this.getMechDetailInfo(message.data.value.TAG_NO)
          else if (message.data.value.DISC === 'ST') this.getStrDetailInfo(message.data.value.TAG_NO)
        } else {
          if (this.discCode === 'PI') this.getPipeDetailInfo(message.data.value.TAG_NO)
          else if (this.discCode === 'ME') this.getMechDetailInfo(message.data.value.TAG_NO)
          else if (this.discCode === 'ST') this.getStrDetailInfo(message.data.value.TAG_NO)
        }

      } else if (res === 'Object Deselected') {
        this.singleSelectedItem = null
        this.lastActionExport = ''
      } else if(res === 'Search Input') {
        this.getSearchInput(message.data.value)
      
      } else if(res === 'Module Initialized') {
        setTimeout(() => {
          this.loading = false  
        }, 1000)
      } else if(res === 'Export Info') {
        this.ExportInfo3dSet(message.data.value)
        this.updateFilter({ EXPORT_INFO: message.data.value })

        setTimeout(() => {
          this.getStatusSummary()
          this.IsExportInfo3dSet(true)
        }, 1000)
        
      }

    },
    getPipeDetailInfo(targetNo) {
      // Pipe Popup에 필요한 Data
      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'Object Status Detail Info - UE4',
        filters: `TAG_NO = '${targetNo}'`
      }).then(res => {
          this.pipingParams = res ? res[0] : null
          this.pipingOpen = true
      })
    },
    getMechDetailInfo(tag){
      
      this.popupEqOpen = true
      this.popupEqParams = {
        filters: {
          TAG: tag
        }
      }

      // // EQ Popup 오픈 (공통)
      // this.setPopupEqOpened({
      //   open: true,
      //   queryid: 0,
      //   filters: { TAG: tag }
      // })
    },
    getStrDetailInfo(targetNo){
      // Structure Popup에 필요한 Data
      this.queryLibService.getSqlQueryResult({
        idx: 0,
        name:'Object ST Detail Info',
        filters: "TAG_NO = '"+ targetNo +"'"
      }).then(res =>{
        this.strucParams = res ? res :null
        this.strucOpen = true
      })
    },
    getDataSource() {
      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'Data Source for 3D Piping'
      }).then(res => {
        if(!res) this.dataSource = []; 
        else this.dataSource = res
      })
    },
    
    getSearchInput(value) {
      this.resetFilteringConditions()
      this.searchValue = value
    },

    getCotrolImage(){
      let path = `${__C.HOST_NAME_RESOURCE}/TORTUE/svg/control.svg`
      return path
    },
    getViewpointImage(){
      let path = `${__C.HOST_NAME_RESOURCE}/TORTUE/svg/view.svg`
      return path
    },
    getDiscSummaryImage(summaryObj, title){
      let path = `${__C.HOST_NAME_RESOURCE}/TORTUE/Modules/LQDashboard/icons/${summaryObj[title].toLowerCase()}.png`
      return path
    },

    getStageTableHeader (th) {
      let header = {
        PO: 'PO',
        FAT: 'FAT',
        IRN: 'IRN',
        DELIVERED: 'Delivery',
        INSTALLED: 'Installed',
        FABRICATION: 'Pre-fabrication',
        ASSEMBLY_BP: 'Assembly B/P',
        PAINTING: 'Painting',
        ASSEMBLY_AP: 'Assembly A/P',
        ERECTION: 'Erection',
        LINE_CHECK: 'Line Check',
        RELEASE_FOR_TEST: 'Release for Test',
        PRESSURE_TEST: 'Pressure Test',
        INTERNAL_CLEANLINESS: 'Internal Cleanliness',
        REINSTATEMENT: 'Reinstatement'
      }
      return header[th] ? header[th] : th
    },

    showConnectedUsers() {
      this.threedSupportService.getConnectedUsers().then(res => { 
        if(!res) this.connectedUsers = []
        else this.connectedUsers = res
      })
    },
    reconnect2cirrus() {
      this.lastActionTime = new Date().getTime()
      this.runCirrusServer = true
    },
    wakeupServer() {
      this.lastActionTime = new Date().getTime()
      this.sleepingServer = false
      this.reconnect2cirrus()
    },
    checkSleeping() {
      // Check if the user is sleeping every 5 seconds
      this.timer = setInterval(() => {
        if (this.sleepingServer) return

        let datetime = new Date().getTime()
        let diff = (this.lastActionTime - datetime) / 1000
        let diffMin = diff /= 60
        let minutes = Math.abs(Math.round(diffMin))

        if (minutes >= this.minLimit) {
          this.sendMessage('SLEEPING', '')
          this.sleepingServer = true
          this.runCirrusServer = false
        }
        else {
          this.sleepingServer = false
        }

      }, 5000)
    },
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="stylus" scoped>
.error-notice {
  width: 800px;
  margin-left: 340px;
  margin-top: 32px;
  font-size: 14px;
  overflow: auto;
}
</style>