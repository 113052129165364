<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'

import __C from './primitives/_constant_'
import Loading from '@/mixins/loading.mixin'
import ServiceRoutes from '@/mixins/service.routers.mixin'

export default {
  name: "App",
  mixins: [
    Loading,
    ServiceRoutes
  ],
  data: () => ({
    locinfo: {},
    pagecallFuncInited: false,
    interval: 10,
    reit: null,
    time: 0
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APPLICATION, ['navDataitems', 'pagecallFunc']),
    ...mapState(__C.STORE_NAMESPACE.SYS_ENVIRONMENT, ['menuUpdated']),
  },
  watch: {
    menuUpdated() {
      this.getNavigationData().then((navItems) => {
        this.setServiceRouter(navItems)
      })
    },
    // ### Navigating & Loading Page process flow ##########################
    // # Very first visit or F5 --------------------------------------------
    // 1. Set NavState by Router-Path on App-Created (initialize())
    // 2. Create current page component by router path component
    //    - Register the component's Call-Function
    // 4. Run 'pagecallFunc' on (App.vue).watch to catch changing of the 
    //    pagecallFunc. 
    //    - pagecallFunc run only once at the time of App-Initialize
    // 5. Data-Access & Load Page by the NavState (current page component)
    // # Menu click --------------------------------------------------------
    // 1. Set NavState by Router-Path on (App.vue).watch of the '$route.path'
    // 2. Run 'pagecallFunc' on (App.vue).watch '$route.path' with little delay
    //    time unitl the current page component loaded & register Call-Function
    //    - every time router path chagned
    // 3. Create current page component by router path component
    //    - Register the component's Call-Function
    // 5. Data-Access & Load Page by the NavState
    pagecallFunc(f___) {
      if(this.pagecallFuncInited || !f___) return
      this.pagecallFuncInited = true
      f___().then((() => { this.loading = false }))
    },
    $route(to, from) {
      if(!localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)) return
      if(to.path == from.path) return

      let location = { 
        locinfo : JSON.stringify(this.locinfo),
        from    : from.path, 
        to      : to.path
      }
      this.reit = location
      this.time = 0
    },
    '$route.path'(path) {
      if(!localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)) return
      this.putRequestHistory(this.reit)
      this.setNavState(path)
      if(this.pagecallFunc) setTimeout(() => { this.pagecallFunc().then((() => { this.loading = false })) })

      this.closeAllFloats()

      setInterval(() => {
        this.time++
        
        let min = Math.floor(this.time / 60000)

        if (min >= this.interval) {
          let location = { 
            locinfo : JSON.stringify(this.locinfo),
            from    : this.reit.from, 
            to      : this.reit.from
          }
          this.LevedRequestHistory(location)
          this.time = 0
        }
      },10)
      
    }
  },
  created() {
    // let self = this
    // fetch('https://api.ipify.org?format=json')
    // .then(response => response.json())
    // .then(({ ip }) => {
    //   fetch(`https://ipapi.co/${ip}/json/`)
    //   .then(response => response.json())
    //   .then(locinfo => {
    //     self.locinfo = locinfo
    //   })
    // })
    
    // if(!localStorage.getItem('USER_LANG')) this.$i18n.locale = 'en'
    // else this.$i18n.locale = localStorage.getItem('USER_LANG')

    if(!document.getElementById('__iframe__')) {
      let iframe = document.createElement('iframe')
      iframe.id = '__iframe__'
      iframe.style.display = 'none'
      document.body.appendChild(iframe)
    }
    
    // ACCOUNT 정보가 없으면 로그인 페이지로 이동
    if(!localStorage.getItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)) return

    this.initialize().then(navItems => {
      this.setServiceRouter(navItems)
    })
    
  },
  mounted() {
    // let oidcScript = document.createElement('script')
    //   oidcScript.setAttribute('src', '../lib/oidc-client.js')
    //   document.head.appendChild(oidcScript)
      
    // let iidextityScript = document.createElement('script')
    //   iidextityScript.setAttribute('src', '.../lib/identityserver.js')
    //   document.head.appendChild(iidextityScript)
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.ACCOUNT, ['putRequestHistory', 'LevedRequestHistory']),

    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, ['closeAllFloats']),
    ...mapMutations(__C.STORE_NAMESPACE.APPLICATION, ['setNavState']),
    ...mapActions(__C.STORE_NAMESPACE.APPLICATION, ['getNavigationData', 'initialize']),
  }
}
</script>

<style lang="stylus">
@import '~@/assets/stylus/jstyle.styl';
</style>