<template>
  <div :id='localId'></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: 'j-chart-radar',
  mixins: [mx_Core],
  props: {
    id: String,
    ChartType: String,
    
    // Chart Data
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Radar: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
        //
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-radar'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      this.clear()
      
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      this.drawTexts()

      this.complete()
    },
    drawChart() {
      
      this.draw_RadarChart()
      // this.draw_Lengends()

    }
  }
}
</script>
