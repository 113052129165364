<template>
  <div :id="localId" class="kim_select">
    <input 
      v-model="inputText" 
      class="kim_select_input" 
      type="text" 
      :placeholder="placeholder"
      @click="inuptFocusIn" 
      @keydown="inputKeydown"
      @keyup="inputKeyup"
    />
    <div class="arrow_icon_box">
      <i v-if="editable" class="material-icons" style="font-size: 1.8rem; padding: 3px;">expand_more</i>
      <i v-else class="material-icons">arrow_drop_down</i>
    </div>
    <div v-if="filteredItems.length" class="item_text_wrapper">
      <div v-if="colApplied" class="item_text_box_table_header">
        <div class="item_text_box">
          <span class="data_col" v-for="(col, i) in cols" :key="i" :style="`width: ${itemColumns[col].width}px;`">{{ itemColumns[col].label }}</span>
        </div>
      </div>
      <div v-if="colApplied" class="item_text_box_table_body">
        <div class="item_text_box" v-for="(item, i) in filteredItems" :key="i" @click="selectItem(i)">
          <span class="data_col" v-for="(col, j) in cols" :key="j" :style="`width: ${itemColumns[col].width}px;`">{{ item[col] }}</span>
        </div>
      </div>
      <div v-else class="item_text_box" v-for="(item, i) in filteredItems" :key="i" @click="selectItem(i)">
        {{ singleValue ? item : item[itemText] }}
      </div>
    </div>
    <div v-else class="item_text_wrapper">
      <div class="item_text_box">
        No data found
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { SafeIdMixin } from '@/mixins/safeid.mixin'

export default {
  name: "kim-select",
  mixins: [
    SafeIdMixin
  ],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    itemColumns: {
      type: Object,
      default: () => ({})
    },
    itemText: String,
    itemValue: String,
    singleValue: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    clearText: {
      type: Boolean,
      default: false
    },
    placeholder: String,
    value: null
  },
  data: () => ({
    filteredItems: [],
    inputText: ''
  }),
  computed: {
    colApplied() { return !!Object.keys(this.itemColumns).length },
    cols() { return Object.keys(this.itemColumns) }
  },
  watch: {
    value(val) {
      if(val != '' && !val) return

      if(this.singleValue) {
        if(this.editable || this.items.find(item => item.toLowerCase() == val.toLowerCase())) this.inputText = val

      } else {
        let item_ = this.items.find(item => typeof item[this.itemValue] == 'number' ? item[this.itemValue] == val : item[this.itemValue].toLowerCase() == val.toLowerCase())
        if(item_) this.inputText = item_[this.itemText]
        else this.inputText = this.editable ? val : ''
      }
    },
    items: {
      handler(items_) {
        let val_ = this.value || ''

        if(this.singleValue) {
          if(this.editable || items_.find(item => item.toLowerCase() == val_.toLowerCase())) this.inputText = val_
        } else {
          let item_ = items_.find(item => typeof item[this.itemValue] == 'number' ? item[this.itemValue] == val_ : item[this.itemValue].toLowerCase() == val_.toLowerCase())

          if(item_) this.inputText = item_[this.itemText]
          else this.inputText = this.editable ? val_ : ''
        }
      },
      deep: true
    }
  },
  created() {
    this.localId = this.id || 'kim-select__' + this.safeId('')
    window.addEventListener('click', this.inuptFocusOut)
  },
  mounted() {
    let val_ = this.value || ''

    if(this.singleValue) {
      if(this.editable || this.items.find(item => item.toLowerCase() == val_.toLowerCase())) this.inputText = val_
    } else {
      let item_ = this.items.find(item => typeof item[this.itemValue] == 'number' ? item[this.itemValue] == val_ : item[this.itemValue].toLowerCase() == val_.toLowerCase())
      if(item_) this.inputText = item_[this.itemText]
      else this.inputText = this.editable ? val_ : ''
    }

    if(this.colApplied) {
      let width_ = _.sum(this.cols.map(k => this.itemColumns[k].width)) + this.cols.length * 10 + 17
      let el = document.querySelector(`#${this.localId} .item_text_wrapper`)
      el.style.width = `${width_}px`
      el.style.height = `200px`
      el.style.right = 0
      el.style.borderRadius = `3px`
      el.style.overflow = 'hidden'
    }
  },
  beforeDestroy () {
    window.removeEventListener('click', this.inuptFocusOut)
  },
  methods: {
    inuptFocusIn() {
      this.filteredItems = JSON.parse(JSON.stringify(this.items))

      let el = document.querySelector(`#${this.localId} .item_text_wrapper`)
      el.classList.add('show')
    },
    inuptFocusOut(e) {
      let el = document.querySelector(`#${this.localId} .kim_select_input`)
      if (!el || e.target == el) return
      
      el = document.querySelector(`#${this.localId} .item_text_wrapper`)
      el.classList.remove('show')

      if(!this.items.length) {
        this.inputText = ''
        return
      }
      
      this.checkEditable()
    },
    inputKeydown(e) {
      if(!(['Enter', 'Tab']).includes(e.key)) return

      if(this.singleValue) {
        var item_ = this.items.find(item => item.toLowerCase() == this.inputText.toLowerCase())
        var value_ = item_ || ''
      } else {
        item_ = this.items.find(item => item[this.itemText] && item[this.itemText].toLowerCase() == this.inputText.toLowerCase())
        value_ = item_ ? item_[this.itemValue] : ''
      }
      
      this.$emit('input', value_)
      this.$emit('change', value_)

      let el = document.querySelector(`#${this.localId} .item_text_wrapper`)
      el.classList.remove('show')

      this.checkEditable()
    },
    inputKeyup(e) {
      if((['Enter', 'Tab']).includes(e.key)) return

      let el = document.querySelector(`#${this.localId} .item_text_wrapper`)
      if(!el.classList.contains('show')) el.classList.add('show')

      if(this.inputText != '0' && !this.inputText) {
        var items_ = JSON.parse(JSON.stringify(this.items))
        
        this.$emit('input', '')
        this.$emit('change', '')

      } else if(this.singleValue) items_ = this.items.filter(item => item.toLowerCase().includes(this.inputText.toLowerCase()))
      else {
        if(this.colApplied) items_ = this.items.filter(item => this.cols.some(k => item[k].toLowerCase().includes(this.inputText.toLowerCase())))
        else items_ = this.items.filter(item => item[this.itemText] && item[this.itemText].toLowerCase().includes(this.inputText.toLowerCase()))
      }
      
      this.filteredItems = items_

      if(this.editable) this.$emit('input', this.inputText)
    },
    selectItem(i) {
      if(this.singleValue) {
        this.inputText = this.filteredItems[i]
        var value_ = this.inputText

      } else {
        let item_ = this.filteredItems[i]
        if(this.clearText) this.inputText = ''
        else {
          if(!this.colApplied) this.inputText = item_[this.itemText]
        }
        value_ = item_[this.itemValue]
      }

      this.$emit('input', value_)
      this.$emit('change', value_)
    },
    checkEditable() {
      if(!this.items.length) {
        this.inputText = ''
        return
      }

      if(this.editable) return

      if(this.singleValue) var item_ = this.items.find(item => item.toLowerCase() == this.inputText.toLowerCase())
      else item_ = this.items.find(item => item[this.itemText] && item[this.itemText].toLowerCase() == this.inputText.toLowerCase())

      if(!item_) this.inputText = ''
    }
  }
}
</script>

<style lang="stylus" scoped>
.kim_select {
  position: relative;

  input::placeholder {
    color: #ccc;
    font-style: italic;
  }

  .kim_select_input {
    width: 20rem;
    height: 3rem;
    border: 1px solid #bbb;
    padding: .5rem;
    padding-right: 2.4rem;

    background-color: #fff;
    border-radius: 3px;
  }

  .kim_select_input:hover {
    border-color: #777;
  }

  .arrow_icon_box {
    position: absolute;
    top: 2px;
    right: 0;
    color: #bbb;
    pointer-events: none;
  }

  .item_text_wrapper {
    position: absolute;
    top: 3rem;

    width: 20rem;
    max-height: 20rem;
    background-color: #fff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);
    overflow: auto;
    display: none;

    z-index: 1;

    .item_text_box_table_header {
      position: absolute;
      top: 0;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.05);

      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      background-color: #f4f5f5;

      .item_text_box {
        display: flex;
      }
    }
    .item_text_box_table_body {
      position: absolute;
      top: 2.2rem;
      max-height: 17.8rem;

      overflow: auto;

      .item_text_box {
        display: flex;
      }
    }

    &.show {
      display: block;
    }

    .item_text_box {
      width: 100%;
      padding: .3rem .5rem .3rem .7rem;
    }

    .item_text_box:hover {
      background-color: #efefef;
    }

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      margin-right: 1rem;
      pointer-events: none;
    }
  }
}
</style>