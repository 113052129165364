<template>
  <v-dialog hide-overlay attach="#viewer_3d_content" v-model="opened" :width="560" >
    <v-card>
      <v-card-title class="pipingPopupWrapper">
        <div class="drag_move">
            <v-icon class="drag_icon">mdi-cursor-move</v-icon>
            <div>DRAG TO MOVE</div>
        </div>
        <div class="popup_header">
          <div class="popup_header_item1">{{blockNo}} OVERVIEW</div>
        </div>
        <div class="popup_close">
          <a v-ripple class="btn-close3" title="Cancel & Close" @click="onClose">
            <i class="mdi mdi-close"></i>
          </a>
        </div>
        <div class="popup_strcuture">
          <table>
            <thead>
              <tr>
                <th rowspan="2" style="width:96px">Block Stage</th>
                <th colspan="3">Plan</th>
                <th colspan="3">Actual</th>
                <th rowspan="2" style="width:51px">Delta</th>
              </tr>
              <tr>
                <th style="width:70px">Start</th>
                <th style="width:70px">Finish</th>
                <th style="width:50px">Prog(%)</th>
                <th style="width:70px">Start</th>
                <th style="width:70px">Finish</th>
                <th style="width:50px">Prog(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(d, i) in params" :key="i">
                <td>{{d.ITEM}}</td>
                <td>{{d.PLAN_SD}}</td>
                <td>{{d.PLAN_FD}}</td>
                <td :class="{'zero': d.PLAN_PROG == 0}">{{d.PLAN_PROG ? Number(d.PLAN_PROG).toFixed(1) : ''}}</td>
                <td>{{d.ACTUAL_SD}}</td>
                <td>{{d.ACTUAL_FD}}</td>
                <td :class="{'zero': d.ACTUAL_PROG == 0}">{{d.ACTUAL_PROG ? Number(d.ACTUAL_PROG).toFixed(1) : ''}}</td>
                <td :class="{'zero': d.DELTA == 0, 'up': d.DELTA > 0, 'down' : d.DELTA < 0}">{{d.DELTA ? d.DELTA > 0 ?'▲'+ Number(d.DELTA).toFixed(1) :d.DELTA == 0 ? 0 :'▼'+ Number(d.DELTA).toFixed(1) : ''}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>
import '@/assets/stylus/ui/component/_j3DpipingPopup.styl'
export default {
  name: 'j-modal-structure-popup',
  props: {
    params: null,
    value: null
  },
  watch:{
    params(value){
      if (value) {
        this.blockNo = value[0].TAG_NO
      }
    }
  },
  data: () => ({
    blockNo:null
  }),
  computed: {
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    }
    
  },  
  methods: {
    onClose() {
      this.opened = false
    },
  }
}
</script>