import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
import Data from '../../../../includes/primitives/Color_Data'

export default {
  methods: {
    drawChartVNormal() {
      let slope = this.setThickness * 0.25

      let chartWrapper = this.chartArea
      .append('g')
      .attr('class', '_chart_wrapper__')
      .selectAll('g')
      .data(this.DataItems)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${this.scaleXInfo.scale(d.title)},0)`)

      // ---------------------------------------------------------------------------------------------------- Reflected Effect
      chartWrapper  // Shadow
      .append('ellipse')
      .attr('cx', this.setThickness / 4 - slope / 2)
      .attr('cy', this.Canvas.CanvasChartHeight - 2)
      .attr('rx', (this.setThickness * 1.5) / 2 - slope / 2)
      .attr('ry', ((this.setThickness * 1.5) / 3) / 2 - slope / 2)
      .attr('fill', 'url(#Color_Shadow)')

      chartWrapper // Transparent Gradation
      .append('rect')
      .attr('transform', `translate(0,${this.Canvas.CanvasChartHeight})`)
      .attr('width', this.setThickness * 1.5 + 2)
      .attr('height', this.setThickness + 2)
      .attr('fill', 'url(#Color_Reflection)')

      // ------------------------------------------------------------------------- Box Bar
      chartWrapper // Front Box 
      .append('rect')
      .attr('transform', d => `translate(0,${this.scaleYInfo.scale(d.value1)})`)
      .attr('width', this.setThickness * .75)
      .attr('height', d => this.scaleYInfo.scale(0) - this.scaleYInfo.scale(d.value1))
      .attr('fill', (_, i) => this.SharedColorSet[i])

      // ------------------------------------------------------------------------- Series & Value
      let valueWrapper = chartWrapper // Value
      .append('g')
      .attr('id', (_, i) => `value_wrapper_${i}`)

      if (this.Bar.BarValueDisplay == 'Y') {
        valueWrapper
        .append('text')
        .attr('id', (_, i) => `ValueText${i}`)
        .attr('class', this.Bar.BarValueStyle)
        .style('font-size', this.Bar.BarValueSize)
        .style('font-family', this.Bar.BarValueFont)
        .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.SharedColorSet[i]) : this.Bar.BarValueColor))
        .text(d => `${d.value1.toFixed(this.Bar.BarValueRound)}`)
      }

      if (this.Bar.BarUnitDisplay == 'Y') {
        valueWrapper // Units
        .append('text')
        .attr('transform', (_, i) => `translate(${this.Bar.BarValueDisplay == 'Y' ? Number(this.getNodeElValue(`#ValueText${i}`, 'width')) + 2 : 0},0)`)
        .attr('id', (_, i) => `ValueUnit${i}`)
        .attr('class', this.Bar.BarValueStyle)
        .style('font-size', this.Bar.BarUnitSize)
        .style('font-family', this.Bar.BarValueFont)
        .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.SharedColorSet[i]) : this.Bar.BarUnitColor))
        .text(this.Bar.BarUnitFormat)
      }

      // Reset the position of the value-wrapper 
      this.DataItems.forEach((d, i) => {
        let valueWrapperId_ = `#value_wrapper_${i}`
        let valueWrapperBox_ = d3.select(`#value_wrapper_1`).node().getBoundingClientRect()
        let wrapperWidth_ = this.getNodeElValue(valueWrapperId_, 'width')
        // Magic number for hanging text at top of Box
        let textAlign4Hang = 2
        this.svg.select(`#value_wrapper_${i}`)
          .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)- (wrapperWidth_/3)}, 
            ${this.scaleYInfo.scale(d.value1)  + ( this.Bar.BarValuePosition == 'top' ?  0 - this.Bar.BarValueSpace : + this.Bar.BarValueSize + this.Bar.BarValueSpace - textAlign4Hang)})`
          )
      })
    },
  }
}
