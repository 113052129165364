import Api from './api'
import Url from './request.url'

export default class PageComponentsApi extends Api {

  constructor() {
    super()
  }

  getIdx (idx, name, callback) {
    this.axios().get(`${Url.system.config.pagecomp.idx}/${idx}/${name}`).then(res => callback(res.data))
  }

  // Intended Datagrid
  getDatatables (phaseCode, groupCode, itemCode, subItemCode, callback) {
    this.axios().get(`${Url.system.config.pagecomp.intdg}/${phaseCode}/${groupCode}/${itemCode}/${subItemCode}`).then(res => callback(res.data))
  }
  getDatatable (idx, callback) {
    this.axios().get(`${Url.system.config.pagecomp.intdg}/${idx}`).then(res => callback(res.data))
  }
  putDatatable (item, callback) {
    this.axios().put(Url.system.config.pagecomp.intdg, item).then(res => callback(res))
  }
  updDatatable (item, callback) {
    this.axios().post(Url.system.config.pagecomp.intdg, item).then(res => callback(res))
  }
  delDatatable (idx, callback) {
    this.axios().delete(`${Url.system.config.pagecomp.intdg}/${idx}`).then(res => callback(res))
  }

  getDatatableRowsPerPage (idx, callback) {
    this.axios().get(`${Url.system.config.pagecomp.rowsperpage}/${idx}`).then(res => callback(res.data))
  }
  getDatatablePreview(params, callback) {
    this.axios().post(Url.system.config.pagecomp.previewdl, params).then(res => callback(res.data))
  }

  // Dashboard
  getDashboardItems (phaseCode, groupCode, itemCode, subItemCode, typeCode, callback) {
    this.axios().get(`${Url.system.config.pagecomp.dashadmin}/${phaseCode}/${groupCode}/${itemCode}/${subItemCode}/${typeCode}`).then(res => callback(res.data))
  }
  getDashboardItem (idx, callback) {
    this.axios().get(`${Url.system.config.pagecomp.dashadmin}/${idx}`).then(res => { callback(res.data) })
  }
  putDashboardItem (item, callback) {
    this.axios().put(Url.system.config.pagecomp.dashadmin, item).then(res => { callback(res) })
  }
  sasDashboardItem (item, callback) {
    this.axios().put(`${Url.system.config.pagecomp.dashadmin}/sas`, item).then(res => { callback(res) })
  }
  updDashboardItem (item, callback) {
    this.axios().post(Url.system.config.pagecomp.dashadmin, item).then(res => { callback(res) })
  }
  delDashboardItem (idx, callback) {
    this.axios().delete(`${Url.system.config.pagecomp.dashadmin}/${idx}`).then(res => callback(res))
  }

  // SVG Properties
  getSvgPropsList (phaseCode, groupCode, itemCode, subItemCode, callback) {
    this.axios().get(`${Url.system.config.pagecomp.svgprops}/${phaseCode}/${groupCode}/${itemCode}/${subItemCode}`).then(res => callback(res.data))
  }
  getSvgProps (idx, callback) {
    this.axios().get(`${Url.system.config.pagecomp.svgprops}/${idx}`).then(res => { callback(res.data) })
  }
  putSvgProps (props, callback) {
    this.axios().put(Url.system.config.pagecomp.svgprops, props).then(res => { callback(res) })
  }
  updSvgProps (props, callback) {
    this.axios().post(Url.system.config.pagecomp.svgprops, props).then(res => { callback(res) })
  }
  delSvgProps (idx, callback) {
    this.axios().delete(`${Url.system.config.pagecomp.svgprops}/${idx}`).then(res => callback(res))
  }
  
  // Custom Component's Env
  getCustomCompEnvs (phaseCode, groupCode, itemCode, subItemCode, callback) {
    this.axios().get(`${Url.system.config.pagecomp.custom}/${phaseCode}/${groupCode}/${itemCode}/${subItemCode}`).then(res => callback(res.data))
  }
}
