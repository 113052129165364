import * as d3 from 'd3'

export default {

  methods: {

    gmx_Single_BarChart(selection, d) {
      let miniChart = selection
      .append('g')
      .attr('transform',`translate(${d.x+.5}, ${d.y+.5})`)

      miniChart
      .append('text')
      .attr('x', d.col - 2).attr('y', 9.5)
      .attr('font-size', 10).attr('text-anchor', 'end')
      .attr('fill', d.tColor[0])
      .text(d.value[0])

      miniChart
      .append('rect')
      .attr('x', d.col).attr('y', 0).attr('width', d.width).attr('height', 12)
      .attr('fill', '#bcbcbc').attr('opacity', .2)

      // Progress
      let proWidth = (d.value[3]/100) * d.width
      miniChart
      .append('rect')
      .attr('x', d.col).attr('y', 0).attr('width', proWidth).attr('height', 12)
      .attr('fill', d.bColor).attr('opacity', 1)
      
      miniChart
      .append('text')
      .attr('y', 9.5).attr('font-size', 10)
      .attr('x', d.value[3] < d.value[4] ? d.col + proWidth + 2 : d.col + proWidth -  2)
      .attr('text-anchor', d.value[3] < d.value[4] ? 'start' : 'end')
      .attr('fill', d.value[3] < d.value[4] ? d.tColor[0] : d.tColor[1])
      .text(d.value[1])
      
      miniChart
      .append('text')
      .attr('x', d.col + d.width + 2).attr('y', 9.5)
      .attr('font-size', 10).attr('text-anchor', 'start')
      .attr('fill',  d.tColor[0])
      .text(d.value[2])
    },



  }
}
