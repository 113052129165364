import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      if(!this.Queries.SQL2 || this.legends.visible !== 'Y') return

      if(this.skyline.legend.type == 'DONUT') this.legend_Donut()
      if(this.skyline.legend.type == 'CUBE') this.legend_CUBE()
    },



    legend_Donut() {
      if(!this.Queries.SQL2 || this.legends.visible !== 'Y') return

      let arcProps = {
        angleSize: 360,
        angleStart: 0,
      }
      let values_ = this.legends.values
      
      let legendGroup = this.svg
      .append('g')
      .attr('id', 'Legend')
      .attr('transform', `translate(${this.legends.x + (this.legends.radius)}, ${this.legends.y + (this.legends.radius)})`)

      this.legends.styles.forEach((s, i) => {
        let arc = d3.arc()
        .outerRadius(this.legends.radius)
        .innerRadius(this.legends.radius - this.legends.stroke)
        .startAngle(d => d.angleStart * Math.PI / 180)
        .endAngle(d => d.angleEnd || d.angleStart * Math.PI / 180)

        let x__ = this.legends.width * i
        let legend_ = legendGroup
        .append('g')
        .attr('id', `Legend${s.refColumn}`)
        .attr('transform', `translate(${x__}, 0)`)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#Legend${s.refColumn}`).style('opacity', 0.6) 
        })
        .on('mouseout', () => { 
          d3.select(`#Legend${s.refColumn}`).style('opacity', 1) 
        })
        .on('click', ()=> {
          d3.select(`#Legend_text_${this.legends.currentStatus}`)
          .attr('font-weight', 400)
          .attr('fill', this.legends.titleColor)
          
          this.legends.currentStatus = s.refColumn
          d3.select(`#Legend_text_${s.refColumn}`)
          .attr('font-weight', 500)
          .attr('fill', '#000')

          this.filtering_Search(this.legends.currentStatus) // ---> Event.js
        })

        // Draw Background Legend Chart
        legend_
        .append('circle')
        .attr('cx', 0).attr('cy', 0).attr('r', this.legends.radius-3)
        .attr('stroke',this.legends.strokeColor)
        .attr('stroke-width', this.legends.stroke)
        .attr('stroke-opacity', .5)
        .attr('fill', `url(#${this.localId}_LightGray_TL)`)
        .attr('opacity', 0.3)

        let circleRate = values_[s.refColumn] / values_['TOTAL']

        // Draw Foreground Legend
        legend_
        .datum(arcProps)
        .append('path')
        .attr('d', arc)
        .attr('fill', s.fColor)
        .attr('opacity', this.legends.opacity)
        .transition()
        .duration(1500)
        .attrTween('d',(d) => {
          let endAngle_ = ((arcProps.angleSize * circleRate) + arcProps.angleStart) * Math.PI / 180
          let interpolate = d3.interpolate(arcProps.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.angleEnd = interpolate(t)
            return arc(d);
          }
        })

        legend_
        .append('text')
        .attr('transform', `translate(0, 1)`)
        .style('font-family', 'roboto')
        .style('font-size', this.legends.valueSize)
        .style('fill', s.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(values_[s.refColumn])

        // Legend
        legend_
        .append('text')
        .attr('id', `Legend_text_${s.refColumn}`)
        .attr('transform', `translate(0, ${this.legends.radius + this.legends.stroke + 5})`)
        .attr('font-family', 'roboto')
        .attr('font-size', this.legends.titleSize)
        .attr('font-weight', this.legends.currentStatus == s.refColumn ? 500 : 400)
        .attr('fill', this.legends.currentStatus == s.refColumn ? '#000' : this.legends.titleColor)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(s.text)
      })
    },


    legend_CUBE() {

      let values_ = this.legends.values
      
      let legendGroup = this.svg
      .append('g')
      .attr('id', 'Legend')
      .attr('transform', `translate(${this.legends.x + 5}, ${this.legends.y})`)

      let x_ = 0
      this.legends.styles.forEach((s, i) => {
        
        let legendItem = legendGroup
        .append('g')
        .attr('id', `Legend${s.refColumn}`)
        .attr('transform', `translate(${x_}, 0)`)
        .style('font-family', 'roboto')
        .style('opacity', this.legends.currentStatus == s.refColumn ? 1 : 0.8)
        .style('cursor', 'pointer')
        .on('mouseover', () => { 
          d3.select(`#Legend${s.refColumn}`).style('opacity', 0.5) 
        })
        .on('mouseout', () => { 
          d3.select(`#Legend${s.refColumn}`).style('opacity', this.legends.currentStatus == s.refColumn ? 1 : 0.8) 
        })
        .on('click', ()=> {
          d3.select(`Legend${s.refColumn}`).style('opacity', 0.8) 
          d3.select(`#Legend_text_${this.legends.currentStatus}`).attr('font-weight', 400).attr('fill', this.legends.titleColor)
          d3.select(`#Legend_box_${this.legends.currentStatus}`).style('stroke', 'white')

          this.legends.currentStatus = s.refColumn

          d3.select(`Legend${s.refColumn}`).style('opacity', 1) 
          d3.select(`#Legend_text_${s.refColumn}`).attr('font-weight', 600).attr('fill', '#000')
          d3.select(`#Legend_box_${this.legends.currentStatus}`).style('stroke', '#000')

          this.filtering_Search(this.legends.currentStatus) // ---> Event.js
        })
        
        legendItem
        .append('rect')
        .attr('id', `Legend_box_${s.refColumn}`)
        .attr('transform', `translate(-5.5, -6)`)
        .attr('rx', 2).attr('ry', 2)
        .style('width', 12).style('height', 12)
        .style('stroke', this.legends.currentStatus == s.refColumn ? '#000' : 'white')
        .style('stroke-width', .5)
        .style('fill', s.fColor)

        legendItem
        .append('text')
        .attr('id', `Legend_text_${s.refColumn}`)
        .attr('transform', `translate(10, 1)`)
        .style('font-size', 10)
        .attr('font-weight', this.legends.currentStatus == s.refColumn ? 600 : 400)
        .style('fill', '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(s.text)

        legendItem
        .append('text')
        .attr('transform', `translate(${this.getNodeElValue(`#Legend_text_${s.refColumn}`, 'width') + 12}, 1)`)
        .style('font-size', 10)
        .style('fill', '#000')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .text(`(${this.legends.values[s.refColumn]})`)

        x_ += this.getNodeElValue(`#Legend${s.refColumn}`, 'width') + 15
      })

    },

  }
}