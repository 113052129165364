export default {
  
  arrow: {
    up: 'M3.6,6.8c0,0.5,0,6.4,0,6.4h4.3c0,0,0-6,0-6.4s3.6,0,3.6,0L5.8,0L0,6.8C0,6.8,3.6,6.2,3.6,6.8z',
    down: 'M7.9,6.4c0-0.5,0-6.4,0-6.4H3.6c0,0,0,6,0,6.4c0,0.4-3.6,0-3.6,0l5.8,6.8l5.8-6.8C11.6,6.4,7.9,7,7.9,6.4z',
    zero: 'M3.6,8.4c0,1.7,0,3.5,0,3.5h4.3c0,0,0-1.8,0-3.5H3.6z',
    left: 'M7.9,1.1C7.9,0.5,7.4,0,6.8,0C6.6,0,6.3,0.1,6.1,0.3L0.6,5.8C0.4,6,0.3,6.3,0.3,6.6c0,0.3,0.1,0.6,0.4,0.8l5.5,5.5c0.2,0.2,0.5,0.3,0.8,0.3c0.6,0,1.1-0.5,1.1-1.1c0-0.3-0.1-0.6-0.3-0.8L2.9,6.6l4.7-4.7C7.8,1.7,7.9,1.4,7.9,1.1z',
    right: 'M7.6,5.8L2.1,0.3C1.9,0.1,1.6,0,1.4,0C0.8,0,0.3,0.5,0.3,1.1c0,0.3,0.1,0.6,0.3,0.8l4.7,4.7l-4.7,4.7c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.6,0.5,1.1,1.1,1.1c0.3,0,0.6-0.1,0.8-0.3l5.5-5.5C7.8,7.2,8,6.9,7.9,6.6C7.9,6.3,7.8,6,7.6,5.8z',
    chart: 'M24.4,0.3H18c-0.9,0-1.6,0.7-1.6,1.6 c0,0.9,0.7,1.6,1.6,1.6h2.6l-5.8,5.8L9.5,3.9c-0.6-0.6-1.6-0.6-2.3,0c0,0,0,0,0,0l-6.4,6.4c-0.6,0.6-0.7,1.6,0,2.3 c0.6,0.6,1.6,0.7,2.3,0c0,0,0,0,0,0l5.3-5.3l5.3,5.3c0.6,0.6,1.6,0.6,2.3,0c0,0,0,0,0,0l6.9-6.9v2.6c0,0.9,0.7,1.6,1.6,1.6 c0.9,0,1.6-0.7,1.6-1.6c0,0,0,0,0,0V1.9C26,1,25.3,0.3,24.4,0.3C24.4,0.3,24.4,0.3,24.4,0.3L24.4,0.3z'
  },

  icons: {
    info: 'M4.7,0.1C2.1,0.1,0,2.2,0,4.8c0,2.6,2.1,4.7,4.7,4.7c2.6,0,4.7-2.1,4.7-4.7 C9.4,2.2,7.3,0.1,4.7,0.1L4.7,0.1z M5.7,7.3C5.5,7.4,5.3,7.5,5.1,7.6C5,7.6,4.8,7.6,4.6,7.6C4.4,7.6,4.1,7.6,4,7.4 C3.8,7.3,3.7,7.1,3.7,6.9c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3l0.3-1.1c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3C4,4.3,3.9,4.3,3.8,4.3c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.2,0.1l0.1-0.3C3.6,4,3.8,4,4,3.9c0.2-0.1,0.3-0.1,0.5-0.1C4.7,3.8,5,3.9,5.2,4c0.2,0.1,0.2,0.3,0.2,0.5c0,0,0,0.1,0,0.2c0,0.1,0,0.2-0.1,0.3L5,6.2c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.2,0,0.2C4.9,6.9,4.9,7,5,7c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2-0.1L5.7,7.3z M5.7,3C5.5,3.1,5.3,3.2,5.2,3.2C5,3.2,4.8,3.1,4.6,3C4.5,2.9,4.4,2.7,4.4,2.5c0-0.2,0.1-0.4,0.2-0.5C4.8,1.9,5,1.9,5.2,1.9 c0.2,0,0.4,0.1,0.5,0.2C5.9,2.3,5.9,2.7,5.7,3C5.7,3,5.7,3,5.7,3z',
    alert: 'M23.1,16.9L13.8,1.3c-0.6-1-2-1.4-3-0.7C10.4,0.7,10.2,1,10,1.3L0.6,16.9 c-0.6,1.1-0.2,2.4,0.8,3c0.3,0.2,0.7,0.3,1.1,0.3h18.7c1.2,0,2.2-1,2.2-2.2C23.4,17.6,23.3,17.3,23.1,16.9L23.1,16.9z M11.9,17.6 c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3C13.1,17.1,12.6,17.6,11.9,17.6C11.9,17.6,11.9,17.6,11.9,17.6 L11.9,17.6z M12.7,14.1c0,0.4-0.4,0.8-0.8,0.8c-0.4,0-0.8-0.4-0.8-0.8c0,0,0,0,0,0l-0.7-7.8c0-0.8,0.7-1.5,1.5-1.5 c0.8,0,1.5,0.7,1.5,1.5L12.7,14.1z',
    approve: 'M10.6,0.2c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10S16.1,0.2,10.6,0.2 M16.8,7.8 l-5.5,5.5l-1.4,1.4c-0.2,0.2-0.5,0.2-0.7,0l-1.4-1.4l0,0L4.4,9.9c-0.2-0.2-0.2-0.5,0-0.7l1.4-1.4c0.2-0.2,0.5-0.2,0.7,0l3.1,3.1 l5.1-5.1c0.2-0.2,0.5-0.2,0.7,0l0,0l1.4,1.4C16.9,7.4,16.9,7.7,16.8,7.8z'
  }
}


