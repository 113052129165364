import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    Draw_Timeline() {
      let Timeline = this.svg
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX}, ${this.timeline.baseY})`)

      // Timeline Text using the scale function
      if (this.timeline.style.week.display == 'Y') {
        // Weeks Text & Timeline Seperator for the Week
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.week)
        .enter()
        .append('text')
        .attr('transform', d => `translate(${this.timeline.scale(d.mDate)}, ${this.timeline.style.week.tSize})`)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.week.tSize)
        .attr('fill', this.timeline.style.week.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(d => d3.timeFormat('%d')(d.eDate))

        // separator
        // Timeline
        // .append('g')
        // .selectAll('path')
        // .data(this.timeline.week)
        // .enter()
        // .append('path')
        // .attr('d', (d, i) => this.timeline.week.length-1 != i ? `M${this.timeline.scale(d.eDate)}, 3 V${this.timeline.style.week.line}` : '')
        // .attr('stroke', this.timeline.style.week.sColor)
        // .attr('stroke-width', this.timeline.style.week.sWidth)
      }
      if (this.timeline.style.month.display == 'Y') {
        // Month Text & Timeline Seperator for the Month
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.month)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', this.timeline.style.week.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.month.tSize)
        .attr('fill', this.timeline.style.month.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name.toUpperCase())

        // separator
        // Timeline
        // .append('g')
        // .selectAll('path')
        // .data(this.timeline.month)
        // .enter()
        // .append('path')
        // .attr('d', (d, i) => this.timeline.month.length-1 != i ? `
        //     M${this.timeline.scale(d.eDate)}, ${this.timeline.style.week.line} 
        //     V${this.timeline.style.week.line + this.timeline.style.month.line}
        //   ` : ''
        // )
        // .attr('stroke', this.timeline.style.month.sColor)
        // .attr('stroke-width', this.timeline.style.month.sWidth)
      }
      // Year Text & Timeline Seperator for the Year
      if (this.timeline.style.year.display == 'Y') {
        Timeline
        .append('g')
        .selectAll('text')
        .data(this.timeline.year)
        .enter()
        .append('text')
        .attr('x', d => this.timeline.scale(d.mDate))
        .attr('y', this.timeline.style.week.height +  this.timeline.style.month.height)
        .style('font-family', this.timeline.font)
        .style('font-size', this.timeline.style.year.tSize)
        .attr('fill', this.timeline.style.year.tColor)
        .attr('font-weight', 500)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'hanging')
        .text(d => d.name)

        // separator
        // Timeline
        // .append('g')
        // .selectAll('path')
        // .data(this.timeline.year)
        // .enter()
        // .append('path')
        // .attr('d', (d, i) => this.timeline.year.length-1 != i ? `
        //     M${this.timeline.scale(d.eDate)}, ${this.timeline.style.week.line + this.timeline.style.month.line} 
        //     V${this.timeline.style.week.line + this.timeline.style.month.line + this.timeline.style.year.line}
        //   ` : ''
        // )
        // .attr('stroke', this.timeline.style.year.sColor)
        // .attr('stroke-width', this.timeline.style.year.sWidth)
      }
    },
    Draw_AxesGrid() {
      let chartArea = this.svg
      .append('g')
      .attr('id', 'chart_area')
      .attr('transform', `translate(${this.Canvas.CanvasChartX}, ${this.Canvas.CanvasChartY})`)
      
      chartArea // Axis X
      .append('line')
      .attr('x1', 0)
      .attr('x2', this.axisXWidth)
      .attr('y1', this.axisYHeight)
      .attr('y2', this.axisYHeight)
      .style('stroke', '#333')
      .style('stroke-width', .5)
              

      // draw 0 and maxBounds on Axis Y1
      let gridY = 0
      let textGapY = 3
      let value1 = this.dataMaxValue1
      let value2 = this.dataMaxValue2

      for(var i=0; i<6; i++) {
        chartArea
        .append('text') // Axis 1
        .attr('x', -textGapY).attr('y',gridY).attr('font-size', 9).attr('text-anchor','end').attr('alignment-baseline','middle').attr('fill','#bcbcbc').text(value1.toLocaleString())

        chartArea
        .append('text') // Axis 2
        .attr('x', this.axisXWidth + textGapY).attr('y', gridY).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#bcbcbc').text(value2.toLocaleString())

        if(i < 6) {
          chartArea
          .append('line')
          .attr('stroke', '#bcbcbc').attr('stroke-width', 0.3).attr('opacity', 0.5).attr('x1', 0).attr('x2', this.axisXWidth).attr('y1', gridY).attr('y2', gridY)
        }

        gridY += (this.axisYHeight/5)
        value1 -= (this.dataMaxValue1/5)
        value2 -= (this.dataMaxValue2/5)
      }
    },
    Draw_BarLines() {
      // legend
      let legend = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasChartX}, ${this.Canvas.CanvasChartY-25})`)

      legend
      .append('rect')
      .attr('transform', `translate(0, 5)`)
      .attr('width', 8).attr('height', 8).attr('fill','#B4E3FA')

      legend
      .append('text') 
      .attr('transform', `translate(13, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('Closed (Weekly)')

      legend
      .append('line')
      .attr('x1', 85).attr('x2', 100).attr('y1', 10).attr('y2', 10).attr('stroke-width', 1).attr('stroke', '#333')

      legend
      .append('circle')
      .attr('cx', 93).attr('cy', 10).attr('r', 3)
      .attr('fill', '#fff').attr('stroke-width', 1).attr('stroke', '#757575')
      
      legend
      .append('text') 
      .attr('transform', `translate(105, 10)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text(`Remain'g ITR`)


      let chartArea = this.svg
      .append('g')
      .attr('transform', `translate(${this.Canvas.CanvasChartX}, ${this.Canvas.CanvasChartY})`)

      // bar-chart
      chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues2)
      .enter()
      .append('line')
      .attr('stroke', '#B4E3FA')
      .attr('stroke-width', 20).attr('opacity', 1)
      .attr('x1', d => this.timeline.scale(d.date))
      .attr('x2', d => this.timeline.scale(d.date))
      .attr('y1', this.scaleY2(0))
      .attr('y2', this.scaleY2(0))
      .transition()
      .duration(500)
      .attr('y1', this.scaleY2(0))
      .attr('y2', d => this.scaleY2(d.week))
      
      // bar-chart values
      chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues2)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(0)})`)
      .attr('font-size', 9)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','start')
      .attr('fill','#bcbcbc')
      .text(d => d.week.toLocaleString())
      .transition()
      .duration(500)
      .attr('transform', d => `translate(${this.timeline.scale(d.date)}, ${this.scaleY2(d.week)-2})`)

      // line-chart
      chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .append('path')
      .attr('d', this.lineZero(this.tickValues1))
      .style('stroke', '#757575').style('stroke-width', 1.5).attr('opacity', .7)
      .style('fill', 'none')
      .transition()
      .duration(500)
      .attr('d', this.lineFunc(this.tickValues1))

      // line-chart markup
      chartArea
      .append('g')
      .attr('transform', `translate(0, 0)`)
      .selectAll()
      .data(this.tickValues1)
      .enter()
      .append('circle')
      .attr('fill', '#fff')
      .attr('stroke', '#757575')
      .attr('stroke-width', 1)
      .attr('cx', d => this.timeline.scale(d.date))
      .attr('cy', this.axisYHeight)
      .attr('r', 3)
      .transition()
      .duration(500)
      .attr('cy', d => this.scaleY1(d.remain))
      

      // Draw the average (Value / Note / Line)
      chartArea
      .append('text') // Maxinum Value
      .attr('transform', `translate(${this.timeline.scale(this.lastDate)}, ${this.scaleY1(0)})`)
      .attr('font-size', 10)
      .attr('text-anchor','middle')
      .attr('alignment-baseline','middle')
      .attr('fill','#44A9DF')
      .text(this.lastValue.toLocaleString())
      .transition()
      .duration(500)
      .attr('transform', `translate(${this.timeline.scale(this.lastDate)}, ${this.scaleY1(this.lastValue)-8})`)
    },
    Draw_Charts() {
      let sChartITR = this.svg
      .append('g')
      .attr('transform', `translate(0.5, 0.5)`)

      if (!this.dataSet) {
        sChartITR
        .append('text')
        .attr('x', 150)
        .attr('y', 120).style('font-size',16)
        .style('font-family','roboto')
        .attr('text-anchor', 'middle')
        .attr('fill', '#bcbcbc')
        .text('Not Applicable' )
        return 
      }

      // SummaryTable_01
      this.SummaryTable_01(sChartITR, this.style) // ---------------------------> 

      let average = sChartITR
      .append('g')
      .attr('transform', `translate(220, 48)`)

      average
      .append('text') 
      .attr('transform', `translate(0, 5)`).attr('font-size', 14).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#44A9DF').text(this.lastWeek)

      average
      .append('text') 
      .attr('transform', `translate(0, 20)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('Last Week')

      average
      .append('text') 
      .attr('transform', `translate(60, 5)`).attr('font-size', 14).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#44A9DF').text(this.dataAvgValue.toFixed(0))

      average
      .append('text') 
      .attr('transform', `translate(60, 20)`).attr('font-size', 9).attr('text-anchor','start').attr('alignment-baseline','middle').attr('fill','#757575').text('8 Weeks Average')
    },
  }
}
           