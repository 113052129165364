import gmx_zMixins         from '../../../../includes/Jmixins/zMixins'

// Library
// import gmx_d3_Library              from '../../../../../includes/D3_Libraries/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_LocalValues          from './set_LocalValues'
import lmx_Watching             from './Watching'

import lmx_Events               from './Events'

import lmx_Controller           from './Controller'
import lmx_draw_Tube            from './draw_Tube'
import lmx_draw_CubeA           from './draw_CubeA'
import lmx_draw_CubeB           from './draw_CubeB'



export default {
  mixins: [
    gmx_zMixins, 
    // gmx_d3_Library,
    
    // Local Mixins
    
    lmx_Watching,
    lmx_Events,
    
    lmx_LocalValues,
    lmx_Controller,
    lmx_draw_Tube,
    lmx_draw_CubeA,
    lmx_draw_CubeB,
  ],
}
