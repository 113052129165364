import * as d3 from 'd3'

export default {
  methods: {

    Cube_B(selection) {


      let tbl =   this.cubes.table



      // Table -------------------------------------------------------------------------------------
      let summaryTable = selection
      .append('g')
      .attr('id', `Summary_Table_Group_${this.localId}`)
      .attr('transform', `translate(0,.5)`)
      .style('font-family', this.area.font)

      
      let pad = 3
      this.columns.forEach(d=> {
        
        summaryTable
        .append('text')
        .attr('x', d.mX).attr('y', this.texts.header.y)
        .style('font-size', this.texts.header.tSize)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .attr('fill', this.texts.header.tColor)
        .text(d.head)

        summaryTable
        .append('rect')
        .attr('x', d.sX + pad).attr('y', this.texts.value.y - 13)
        .attr('rx', tbl.radius).attr('ry', tbl.radius)
        .attr('width', d.width - (pad*2)).attr('height', 24)
        .attr('fill', tbl.bgColor).attr('opacity', tbl.opacity)

        summaryTable
        .append('text')
        .attr('x', d.mX).attr('y', this.texts.value.y)
        .style('font-size', d.tSize)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .attr('fill', d.tColor)
        .attr('cursor', d.link == 'Y' ? 'pointer': 'default')
        .text(this.set_Value(d.format, d.value))
        .call(this.call_LINK, d)
      })

    },

  }
}