// import * as d3 from 'd3'
import xmljs from 'xml-js'

const xmljsOption = { compact: true }

export default class {
    
  constructor() {}

  rxml(sourceUrl, callback) {
    var xhr = new XMLHttpRequest()
    xhr.open("GET", sourceUrl, true)
    xhr.onload = function() {
      if (this.status == 200) {
        callback(xhr.responseText)
      } else {
        console.warn('Cannot recieve the result of the svg request.')
        callback(null)
      }
    }
    xhr.send()
  }
  rxml2js(sourceUrl, callback) {
    this.rxml(sourceUrl, xmlstring => {
      callback(xmljs.xml2js(xmlstring, xmljsOption))
    })
  }
  rxml2json(sourceUrl, callback) {
    this.rxml(sourceUrl, xmlstring => {
      callback(xmljs.xml2json(xmlstring, xmljsOption))
    })
  }
  xml2js(xml) {
    return xmljs.xml2js(xml, xmljsOption)
  }
  dom2js(svg) {
    let xmlString = new XMLSerializer().serializeToString(this.dom2xmlDoc(svg))
    return xmljs.xml2js(xmlString, xmljsOption)
  }
  dom2json(svg) {
    let xmlString = new XMLSerializer().serializeToString(this.dom2xmlDoc(svg))
    return xmljs.xml2json(xmlString, xmljsOption)
  }
  dom2xmlString(svg, self=false) {
    let xmlString = ''
    try { xmlString = new XMLSerializer().serializeToString(self ? svg : this.dom2xmlDoc(svg)) } catch(e) { return '' }
    return xmlString
  }
  dom2xmlDoc(svg) {
    let html_ = ''
    try { html_ = svg.html()} catch(e) { return '' }
    return new DOMParser().parseFromString(html_, "application/xml")
  }
  xmlString2xmlDoc(xmlString) {
    return new DOMParser().parseFromString(xmlString, "application/xml")
  }
  xmlString2dom(xmlString) {
    return this.xmlString2xmlDoc(xmlString).documentElement
  }
}