import * as d3 from 'd3'

export default {
  data: () => ({
    // Scales
    scaleXInfo: {
      scale: null,
      tickValues: []
    },
    scaleYInfo: {
      scale: null,
      tickValues: []
    },

    // Common
    setThickness: 0,
    setDistance: 0,
  }),
  computed: {
    ready2draw() {
      return  this.ChartType &&
              this.DataItems.length > 0 &&
              Object.keys(this.Canvas).length > 0 &&
              Object.keys(this.Axis).length > 0 &&
              Object.keys(this.Title).length > 0 &&
              Object.keys(this.Bar).length > 0 &&
              Object.keys(this.Legends).length > 0 &&
              Object.keys(this.Note).length > 0
    },
  },
  methods: {
    setDefaultValues() {
      if(this.DataItems.length === 0) return
      
      this.setThickness = 0

      let dataLength = this.DataItems.length

      // Set shared colorset & type -------------------------------
      // ColorSet cannot only be a 'this.Bar.BarColorSet', other
      // colorset could be invoked for thier chart type.
      // Therefore the current colorset & its type should be shared
      // to the globally common variable to correctly run the global
      // drawings.

      // If the ColorSet's colors are less than the Data length, 
      // number of the color should be set as much as the length 
      // of the data. If not, NOT FOUND error will be occured.
      this.SharedColorSet = (
        dataLength > this.Bar.BarColorSet.length ?
        Array.from({ length: dataLength }, (_, i) => this.Bar.BarColorSet[i % this.Bar.BarColorSet.length]) :
        this.Bar.BarColorSet
      )
      this.SharedColorSetType = this.Bar.BarColorType
      // ----------------------------------------------------------
    },
    setScaleXInfo() {
      let dataLength = this.DataItems.length
      // The value's key name should be 'title' or others,
      // or the first column must be value?
      // Need to be regulated.
      let valueKeyName = 'title'
      this.scaleXInfo.tickValues = this.DataItems.map(item => item[valueKeyName])

      if(this.Bar.BarAutoSize == 'Y') var scaleXRangeMax_ = this.Canvas.CanvasChartWidth
      else scaleXRangeMax_ = (
        dataLength * (this.Bar.BarThickness + this.Bar.BarDistance) - this.Bar.BarDistance +
        this.Canvas.CanvasChartSpace * 2 - 
        this.Canvas.CanvasChartSpace
      )

      this.scaleXInfo.scale = d3.scaleBand()
      .domain(this.scaleXInfo.tickValues)
      .rangeRound([0, scaleXRangeMax_])
      // .paddingInner((this.Bar.BarAutoSize == 'Y' ? this.Canvas.CanvasChartWidth / dataLength : this.Bar.BarDistance) / 100)
      .paddingInner(this.Bar.BarDistance / 100)
      .paddingOuter(this.Canvas.CanvasChartSpace / 100)

      /* Reassigne the default value */
      this.setThickness = this.Bar.BarAutoSize == 'Y' ? this.scaleXInfo.scale.bandwidth() : this.Bar.BarThickness
    },
    setScaleYInfo() {
      // The value's key name should be 'value' or others,
      // or the second column must be value?
      // Need to be regulated.
      let valueKeyName = 'value'
      let targetValues_ = this.DataItems.map(item => item[valueKeyName])

      // ...targetValues_ : enumerates the values out of the array, 
      // ex) [1, 2, 3, 4, 5] => 1, 2, 3, 4, 5
      let maxDataValue_ = this.DataItems.length === 0 ? 9 : Math.max(...targetValues_)
      let maxBoundValue_ = this.Axis.AxisYBoundsAuto == 'Y' ? this.getMaxBound(maxDataValue_) : this.Axis.AxisYBoundsMax

      // Portion of the increment for each
      let portionY = Math.round(maxBoundValue_ / 5)

      // Calculate the data values for each of the Grid-Lines, 6, 
      // 0 to maxBoundValue_.
      this.scaleYInfo.tickValues = Array.from({ length: 6 }, (_, i) => (i * portionY))

      // This Y Scale, calculated by the data values, will be used
      // wherever the Y Coordinates are needed.
      this.scaleYInfo.scale = d3
      .scaleLinear()
      .domain([0, maxBoundValue_])
      .rangeRound([this.Canvas.CanvasChartHeight, 0])
    },
  }
}