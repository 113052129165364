
import __C from '../primitives/_constant_'
import { ServiceRoutes } from '../router/service'

export default {
  methods: {
    setServiceRouter(navItems) {
      ServiceRoutes(navItems, _routes__ => {
        // addRoutes merges new routes added to existing routes, and
        // replace old one to new one if found same routes with...
        // "children: rRoutes" means that rRoutes is added to 
        // existing routes, not removing or replacing the existings.
        let rootRoutes = this.$router.options.routes.find(route => route.path == '/')
        let serviceRoutes = {
          path: 'service',
          redirect: __C.APPLICATION.URL_HOME,
          name: 'Application Service Page',
          component: { render (c) { return c('router-view') }},
          children: _routes__
        }
        rootRoutes.children = [
          serviceRoutes
        ]

        // "addRoutes" adds new routes to the root routes. It doesn't find
        // child node, doesn't add new routes to their child. So that the 
        // proper way to add some routes to the existing routes is that the 
        // Vue router should be constructed flat design.
        this.$router.addRoutes([rootRoutes])
        // router.options is the object passed to the vuerouter constructor. 
        // It's not modified afterwards. So, right added new routes cannot
        // be shown by router.options.routes.
        // router.options.routes.forEach(route => {
        //   console.log(JSON.stringify(route))
        // })
      })
    }
  }
}