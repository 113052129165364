<template>
  <div :id='localId'></div>
</template>

<script>
import * as d3 from 'd3'
import { SafeIdMixin } from '../../mixins/safeid.mixin'
export default {
  name: 'j-svg-bar-progress',
  mixins: [
    SafeIdMixin
  ],
  props: {
    svgAttrs: {
      type: Object,
      default: () => ({
        width: Number,
        thickness: Number,
        radius: Number,
        background: String,
        textUnit: String,
        textAlign: String,  // auto | left | center | right
        bColor: String,
        tColor: String,
        value: Number,
      })
    },
    value: null
  },
  data: () => ({
  }),
  computed: {
    barValue() { return !this.svgAttrs.value ? 0 : this.svgAttrs.value },
    barWidth() { return this.svgAttrs.width * this.barValue / 100 },
  },
  watch: {
    svgAttrs: { handler(val) { this.draw() }, deep: true }
  },
  created() {
    this.localId = this.id || 'j-svg-bar-progress__' + this.safeId('')
  },
  mounted() {
    this.draw()
  },
  methods: {
    clear() {
      if(!d3.select(`#${this.localId}`).empty()) d3.select(`#${this.localId}`).html('')
      this.svg = d3.select(`#${this.localId}`).append('svg')
    },
    draw() {
      this.clear()
      this.drawSvg()
      this.drawBar()
      this.putText()
    },
    drawSvg() {
      this.svg
      .attr('width', this.svgAttrs.width)
      .attr('height', this.svgAttrs.thickness)
    },
    drawBar() {
      this.svg
      .append('rect')
      .attr('width', this.svgAttrs.width)
      .attr('height', this.svgAttrs.thickness)
      .attr('rx', this.svgAttrs.radius)
      .style('fill', this.svgAttrs.background)
      .style('stroke', this.svgAttrs.background)

      this.svg
      .append('rect')
      .attr('width', this.barWidth)
      .attr('height', this.svgAttrs.thickness)
      .attr('rx', this.svgAttrs.radius)
      .style('fill', this.svgAttrs.bColor)
      .style('stroke', this.svgAttrs.bColor)
    },
    putText() {
      this.svg
      .append('text')
      .attr('id', `${this.localId}__text___`)
      .attr('alignment-baseline', 'hanging')
      .style('fill', this.svgAttrs.tColor)
      .text(`${Math.round(this.barValue)}${this.svgAttrs.textUnit}`)

      let textEl = d3.select(`#${this.localId}__text___`)
      let textWidth = textEl.node().getBoundingClientRect().width
      let textPosName = this.svgAttrs.textAlign || 'auto'
      let setTextPosX = {
        auto: () => {
          if(this.barWidth === 0) textEl.attr('text-anchor', 'start').attr('transform', `translate(3,0)`)
          else if(this.barWidth + textWidth + 1 > this.svgAttrs.width) textEl.attr('text-anchor', 'end')
            .attr('transform', `translate(${this.svgAttrs.width - 3},0)`)
          else textEl.attr('text-anchor', 'start').attr('transform', `translate(${this.barWidth + 1},0)`)
        },
        start: () => { textEl.attr('text-anchor', 'start').attr('x', 3) },
        middle: () => { textEl.attr('text-anchor', 'middle').attr('x', this.svgAttrs.width / 2) },
        end: () => { textEl.attr('text-anchor', 'end').attr('x', this.svgAttrs.width - 3) }
      }
      setTextPosX[textPosName]()
    },
  }
}
</script>
