import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_watching             from './watching'

// Charts 
import lmx_LocalValuess         from './set_LocalValues'

import lmx_drawForms            from './draw_Forms'

import lmx_events               from './Events'
import lmx_customFunc           from './custom_Functions'

export default {
  
  mixins: [
    gmx_zMixins, 
    
    // Local Mixins
    lmx_watching,
    lmx_LocalValuess,
    lmx_events,
    lmx_customFunc,

    lmx_drawForms,

  ],

}
