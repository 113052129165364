import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  methods: {
    
    drawMenusOption() {
      this.svg.style('background-color', this.Canvas.CanvasFillColor)

      let subMenu = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)

      subMenu
      .append('text')
      .attr('x', 20).attr('y', 20)
      .attr('fill', '#BDBCBC').style('font-size', 11).style('font-family', 'roboto').attr('text-anchor', 'start').text('Progress(%)')

      subMenu
      .append('text')
      .attr('x', 50).attr('y', 60)
      .attr('fill', '#E0E0DF').style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').text('|')

      subMenu
      .append('text')
      .attr('id', `sortMOD`)
      .attr('x', 20).attr('y', 60)
      .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').text('MOD')
      .attr('fill', () => {
        if (this.sortMode == 'MOD') return '#E0E0DF'; else return '#757575'
      })
      .style('cursor', 'pointer')
      .on('click', () => {
        this.sortMode = 'MOD'
        d3.select('#sortRFS').attr('fill', '#757575')
        d3.select('#sortMOD').attr('fill', '#E0E0DF')

        this.setEmptyMenuItems()

        this.dataSet.sort(function(a, b) { 
          return a.MOD < b.MOD ? -1 : a.MOD > b.MOD ? 1 : 0;
        })
        
        this.moduleList()
        this.setEnvValues({ sort: this.sortMode })
      })

      subMenu
      .append('text')
      .attr('id', `sortRFS`)
      .attr('x', 60).attr('y', 60)
      .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').text('RFS')
      .attr('fill', () => {
        if (this.sortMode == 'RFS') return '#E0E0DF'; else return '#757575'
      })
      .style('cursor', 'pointer')
      .on('click', () => {
        this.sortMode = 'RFS'
        d3.select('#sortRFS').attr('fill', '#E0E0DF')
        d3.select('#sortMOD').attr('fill', '#757575')
        
        this.setEmptyMenuItems()

        this.dataSet.sort((a, b) => { 
          return a.rfsMOD < b.rfsMOD ? -1 : a.rfsMOD > b.rfsMOD ? 1 : 0;
        })
        
        this.moduleList()
        this.setEnvValues({ sort: this.sortMode })
      })
      
      subMenu
      .append('text').attr('x', 90).attr('y', 64).attr('fill', '#757575') .style('font-size', 8.5).style('font-family', 'roboto').attr('text-anchor', 'start').text('0')
      subMenu
      .append('text').attr('x', 140).attr('y', 64).attr('fill', '#757575') .style('font-size', 8.5).style('font-family', 'roboto').attr('text-anchor', 'middle').text('50')
      subMenu
      .append('text').attr('x', 190).attr('y', 64).attr('fill', '#757575') .style('font-size', 8.5).style('font-family', 'roboto').attr('text-anchor', 'end').text('100')


      let sMenu_FAB = this.svg
      .append('g')
      .attr('id', `sMenuFAB`)
      .attr('transform', `translate(0,0)`)
      .attr('opacity', () => {
        if (this.newMode == 'FAB') return 1; else return 0.4
      })
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        if (this.selectedMod == 'FAB') return
        d3.select(`#sMenuFAB`).transition().duration(150).attr('opacity', 1)
      })
      .on('mouseout', () => {
        if (this.newMode == 'FAB') return
        d3.select(`#sMenuFAB`).transition().duration(150).attr('opacity', 0.4)
      })
      .on('click', () => {
        if (this.newMode == 'FAB') return
        d3.select(`#sMenuFAB`).transition().duration(150).attr('opacity', 1)
        d3.select(`#sMenu_A1`).transition().duration(150).attr('opacity', 0.4)
        d3.select(`#sMenu_B1`).transition().duration(150).attr('opacity', 0.4)
        this.newMode = 'FAB'
        this.drawTransition()
        setTimeout(() => { this.prevMode = this.newMode }, 600);

        this.setEnvValues({ tab: this.newMode})
      })

        sMenu_FAB
        .append('rect')
        .attr('x', 90).attr('y', 10).attr('rx', 2).attr('ry', 2).attr('width', 30).attr('height', 14).attr('fill', `#83D2F5`)

        sMenu_FAB
        .append('text')
        .attr('x', 96).attr('y', 20)
        .attr('fill', '#333') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').text('FAB')

      let sMenu_A1 = this.svg
      .append('g')
      .attr('id', `sMenu_A1`)
      .attr('transform', `translate(0,0)`)
      .attr('opacity', () => {
        if (this.newMode == 'A1') return 1; else return 0.4
      })
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        if (this.selectedMod == 'A1') return
        d3.select(`#sMenu_A1`).transition().duration(150).attr('opacity', 1)
      })

      .on('mouseout', () => {
        if (this.newMode == 'A1') return
        d3.select(`#sMenu_A1`).transition().duration(150).attr('opacity', 0.4)
      })
      .on('click', () => {
        if (this.newMode == 'A1') return
        d3.select(`#sMenuFAB`).transition().duration(150).attr('opacity', 0.4)
        d3.select(`#sMenu_A1`).transition().duration(150).attr('opacity', 1)
        d3.select(`#sMenu_B1`).transition().duration(150).attr('opacity', 0.4)
        this.newMode = 'A1'
        this.drawTransition()
        setTimeout(() => { this.prevMode = this.newMode }, 600);

        this.setEnvValues({ tab: this.newMode})
      })

        sMenu_A1
        .append('rect')
        .attr('x', 125).attr('y', 10).attr('rx', 2).attr('ry', 2).attr('width', 40).attr('height', 14).attr('fill', `#83D2F5`)

        sMenu_A1
        .append('text')
        .attr('x', 128).attr('y', 20)
        .attr('fill', '#333') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').text('QVD A1')
        
        
      let sMenu_B1 = this.svg
      .append('g')
      .attr('id', `sMenu_B1`)
      .attr('transform', `translate(0,0)`)
      .attr('opacity', () => {
        if (this.newMode == 'B1') return 1; else return 0.4
      })
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        if (this.selectedMod == 'B1') return
        d3.select(`#sMenu_B1`).transition().duration(150).attr('opacity', 1)
      })

      .on('mouseout', () => {
        if (this.newMode == 'B1') return
        d3.select(`#sMenu_B1`).transition().duration(150).attr('opacity', 0.4)
      })
      .on('click', () => {
        if (this.newMode == 'B1') return
        d3.select(`#sMenuFAB`).transition().duration(150).attr('opacity', 0.4)
        d3.select(`#sMenu_A1`).transition().duration(150).attr('opacity', 0.4)
        d3.select(`#sMenu_B1`).transition().duration(150).attr('opacity', 1)
        this.newMode = 'B1'
        this.drawTransition()
        setTimeout(() => { this.prevMode = this.newMode }, 600);

        this.setEnvValues({ tab: this.newMode})
      })

        sMenu_B1
        .append('rect')
        .attr('x', 170).attr('y', 10).attr('rx', 2).attr('ry', 2).attr('width', 40).attr('height', 14).attr('fill', `#83D2F5`)

        sMenu_B1
        .append('text')
        .attr('x', 173).attr('y', 20)
        .attr('fill', '#333') .style('font-size', 10).style('font-family', 'roboto').attr('text-anchor', 'start').text('QVD B1')
      
      this.moduleList()
    },

    moduleList() {
      
      this.moduleMenuGroup = this.svg
      .append('g')
      .attr('id', `moduleMenuGroup`)
      .attr('transform', `translate(0,0)`)

      this.dataSet.forEach((d,i) => {

        let y = i * 25 + 45
        let moduleMenu = this.moduleMenuGroup
        .append('g')
        .attr('id', `sMenu_Group_${i}`)
        .attr('transform', `translate(${0}, ${20})`)
        .style('cursor', 'pointer')
        .on('mouseover', () => {
          if (this.selectedMod == d.MOD) return
          d3.select(`#sMenu_Box_${i}`).transition().duration(150).attr('opacity', 1)
          d3.select(`#sMenu_Text_${i}`).transition().duration(150).attr('fill', '#fff')
          d3.select(`#sMenu_Bar_${i}`).transition().duration(150).attr('opacity', 0.2)
          d3.select(`#sMenu_BarText_${i}`).transition().duration(150).attr('fill', '#fff')
        })

        .on('mouseout', () => {
          if (this.selectedMod == d.MOD) return
          d3.select(`#sMenu_Box_${i}`).transition().duration(150).attr('opacity', 0.1)
          d3.select(`#sMenu_Text_${i}`).transition().duration(150).attr('fill', '#bcbcbc')
          d3.select(`#sMenu_Bar_${i}`).transition().duration(150).attr('opacity', 0.1)
          d3.select(`#sMenu_BarText_${i}`).transition().duration(150).attr('fill', '#bcbcbc')
        })
        .on('click', () => {
          if (this.selectedMod == d.MOD) return
          d3.select(`#sMenu_Box_${this.prevIndex}`).transition().duration(500).attr('opacity', 0.1)
          d3.select(`#sMenu_Text_${this.prevIndex}`).transition().duration(500).attr('fill', '#bcbcbc')
          d3.select(`#sMenu_Bar_${this.prevIndex}`).transition().duration(500).attr('opacity', 0.1)
          d3.select(`#sMenu_BarText_${this.prevIndex}`).transition().duration(500).attr('fill', '#bcbcbc')

          this.selectedMod = d.MOD
          this.prevIndex = i

          let request_ = {
            filters: {
              ...this.FilterValues,
              MOD: d.MOD,
              MOD_DESC: d.MOD_DESC,
            }
          }
          this.$emit('request-action', request_)
      })


        moduleMenu
        .append('rect')
        .attr('id', `sMenu_Box_${i}`)
        .attr('x', 20).attr('y', y).attr('rx', 2).attr('ry', 2).attr('width', 60).attr('height', 19).attr('fill', `url(#${this.localId}_module)`)
        .attr('opacity', () => {
          if (this.selectedMod == d.MOD) return 1; else return 0.1
        })

        moduleMenu
        .append('text')
        .attr('id', `sMenu_Text_${i}`)
        .attr('x', 50).attr('y', y + 14)
        .style('font-size', 12).style('font-family', 'roboto').attr('text-anchor', 'middle').text(d.MOD)
        .attr('fill', () => {
          if (this.selectedMod == d.MOD) return '#fff'; else return '#bcbcbc'
        })
        moduleMenu
        .append('rect')
        .attr('id', `sMenu_Bar_${i}`)
        .attr('x', 90).attr('y', y + 2).attr('width', 0).attr('height', 13).attr('fill', '#B4E3FA')
        .transition().duration(500).attr('width', d[this.newMode])
        .attr('opacity', () => {
          if (this.selectedMod == d.MOD) return 0.2; else return 0.1
        })

        moduleMenu
        .append('text')
        .attr('id', `sMenu_BarText_${i}`)
        .attr('x', () => {
          if (d[this.newMode] < this.minProg) return 90; else return 90
        })
        .attr('text-anchor', 'start')
        .attr('y', y + 12)
        .style('font-size', 9).style('font-family', 'roboto').text(`${d[this.newMode]}%`)
        .attr('fill', () => {
          if (this.selectedMod == d.MOD) return '#fff'; else return '#bcbcbc'
        })
        .transition().duration(500).attr('x', 91 + d[this.newMode])
      })    
    },

  }
}
