import { VBtn, VMenu } from 'vuetify/lib'
import JButton from '../JButton'
import '../../../../src/assets/stylus/ui/_picker.styl'
// import '../../../../src/assets/stylus/ui/'

export default {
  name: 'j-icon-picker',
  components: {
    JButton,
    VBtn,
    VMenu
  },
  props: {
    icons: {
      type: Object,
      default: () => []
    },
    selectedIcon: {
      type: Object,
      default: () => Object.assign({})
    },
    value: null
  },
  render() {
    let contentClass = 'j_picker icon_picker'
    let _attrs = {
      slotActivator: {
        class: 'ddd'
      },
      slotActivatorBtn: {
        on: {
          click: () => this.popIconPicker(!this.value)
        }
      },
      vMenu: {
        attrs: {
          'close-on-content-click': false,
          'min-width': 300,
          'nudge-left': 18,
          'offset-y': true,
          value: this.value
        },
        on: {
          input: value => this.$emit('input', value)
        },
        props: {
          contentClass: contentClass,
          value: this.value
        }
      }
    }
    return (
      <v-menu {..._attrs.vMenu}>
        {/* <template v-slot:activator="{ on }" class="ddd">
          <div class="icon-picker">
            <span>icon</span>
            <v-btn v-on="on" @click="popIconPicker(!iconPickerOpen)"></v-btn>
          </div>
        </template>
        <v-card>
          <div class="j_picker__header">
            <span>
              Icon picker
              <small>SVG</small>
            </span>
          </div>
          <div class="j_picker__contents">
            <div class="current_icon">
              <button>
                <img v-if="selectedItem.subIconPath" :src="selectedItem.subIconPath" />
              </button>
              <span>Currnet Icon</span>
            </div>
            <div class="select_icon">
              <button
                v-for="icon in iconList"
                :value="icon.name"
                :key="icon.iconCode"
              >
                <img @click="iconSelect(icon)" :src="icon.filePath" />
              </button>
            </div>
          </div>
        </v-card> */}
      </v-menu>
    )
  }
}
