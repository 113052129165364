<template>
  <div :id="localId" class="svg_container"></div>
</template>

<script>
import * as d3 from 'd3'
import mx_Core from './includes/mixins/importer'

export default {
  name: "p6-summary-chart",
  mixins: [ mx_Core ],
  props: {
    items: {
      typs: Array,
      default: () => null
    }
  },
  data: () => ({
    svg: null,
    style: [
      { colName: 'TOTAL'              , bColor: '#fff'   , filterString: '' },
      { colName: 'BEHIND_START_DATE'  , bColor: '#9693fc', filterString: `STATUS_CURRENT_VS_BL0 = 'Behind Start Date'` },
      { colName: 'BEHIND_FINISH_DATE' , bColor: '#ffc107', filterString: `STATUS_CURRENT_VS_BL0 = 'Behind Finish Date'` },
      { colName: 'ON_GOING'           , bColor: '#64d969', filterString: `STATUS_CURRENT_VS_BL0 = 'On Going'` },
      { colName: 'COMPLETED'          , bColor: '#959595', filterString: `STATUS_CURRENT_VS_BL0 = 'Completed'` },
    ]
  }),
  watch: {
    items: {
      handler() {
        this.draw()
      },
      deep: true
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.draw()
  },
  methods: {
    draw() {
      if(this.svg) this.svg.remove()

      this.svg = d3.select(`#${this.localId}`)
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('version', '1.2')
      .attr('width', 565)
      .attr('height', 32)
      
      let gab = 10
      let d = {
        x         : 0,
        y         : 0,
        width     : 75,
        height    : 32,
        radius    : 3,
        bColor    : null,
        value     : null,
        valuex    : 37,
        valuey    : 18,
        valueSize : 12,
        valueColor: '#454545',
        vanchor   : 'middle',
        linkable  : true,
        // label     : 'Total',
        // labelx    : 85,
        // labely    : 20,
        // labelSize : 9,
        // labelColor: '#757575',
        request   : null,
      }

      let dataset = this.items[0]

      this.style.forEach((s, i) => {
        d.x       = i*(d.width+gab)
        d.bColor  = s.bColor
        d.value   = Number(dataset[s.colName]).toLocaleString()
        d.request = {
          action: { target: 'append-filter-string' },
          filterString: s.filterString
        }

        this.drawBoxedSummarySingle(this.svg, d)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.svg_container {
  margin-bottom: .7rem;
  width: 565px;
  height: 32px;
}
</style>