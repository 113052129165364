import * as d3 from 'd3'
import __C from '../../../../../includes/primitives/_constant_crisp'

export default {
  data: () => ({
    SceneWidth: 1100,
    SceneHeight: 800,
    
    RESOURCE:       `${__C.HOST_NAME_RESOURCE}/resources`,
    // RESOURCE:      `${window.location.origin}`,

    fbxFile:        'HULL(5)13.fbx',
    // fbxFile:        'System4.fbx',
    hdrFile:        'DH001LP.hdr',

    rgbeLoader:     'ON',

    sCanvasArea:    null,
    sceneInfo:      null,

    rawData:        [],

    hide:           'OFF',

    objects:        [],
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    setDefaultValues(){
      // this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      // let sql1 = this.Queries.SQL1[0]
      // let sql2 = this.Queries.SQL2[0]
      // let sql3 = this.Queries.SQL3[0]
    },

  }
}