<template>
  <div class="j_oms_modules_fullscreen">
    <v-select
      v-model="module"
      attach
      class="lnb_single_select"
      content-class="lnb_dropdown"
      item-text="desc"
      item-value="key"
      :items="moduleDesc"
      label="Modules"
      :menu-props="{ maxHeight: '190', closeOnContentClick: true}"
      :placeholder="`Modules`"
      @input="sendValuesSelect"
    ></v-select>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import Loading from '@/mixins/loading.mixin'
import { DBSupportService } from "@/services"

export default {
  name: 'j-lnb-o-m-s-modules-fullscreen',
  mixins: [
    Loading
  ],
  data: () =>({
    queryLibService: null,

    moduleOptions: [],
    moduleDesc: [],

    module: 'MW01',

    selectItem: null

  }),
  watch: {
    filteredValues(val) {
      if (val && val.MODULE) {
        this.module = val.MODULE
      }
    }
  },
  computed: {
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'filteredValues'
    ]),
  },
  created() {
    this.queryLibService = new DBSupportService()
    this.getModuleOptions()
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'sendRequest', 
      'updateFilteredValues'
    ]),
    sendValuesSelect(v) {
      // this.loading = true

      // hard clone, if not, this.optionValues is linked with 
      // state prop filteredValues, then will try to mutate
      // filteredValues through 'optionValues'
      // JSON.parse(JSON.stringify(this.optionValues)

      this.updateFilteredValues({ ...this.filteredValues, MODULE: v }).then(res => {
        this.sendRequest('filtered').then(() => { this.loading = false })
      })
    },
    getModuleOptions() {
      this.queryLibService.getSqlQueryResult({ 
        idx: 0, 
        name: 'Threed Module List',
        filters: ""
      }).then(res => {
        this.moduleOptions = res ? res.map(d => d.MODULE) : []
        this.moduleDesc = res ?  res.map(d => {
          return {
            key: d.MODULE,
            desc: d.MODULE_DESCR
          }
        })
        : []
      })
    }
  }
}
</script>