import * as d3 from 'd3'

export default {
  data: () => ({
    pathReigon: null,
    pathPoints: [],
  }),
  methods: {
    createPathReigon(selection, data) {
      this.pathReigon = selection
      .append('rect')
      .attr('transform', `translate(0, 0)`)
      .attr('stroke-width', data.strokeWidth).attr('stroke', data.strokeColor)
      .style('width', data.w).style('height', data.h)
      .style('fill-opacity', 0)
      .on('click', () => {
        let elrect = this.pathReigon.node().getBoundingClientRect()
        let pathDirection = {
          x: Math.round(d3.event.x - elrect.x),
          y: Math.round(d3.event.y - elrect.y),
        }

        this.pathPoints.push(`${pathDirection.x} ${pathDirection.y}`)
        console.log(this.pathPoints.join(' L '))
      })
    },
  }
}