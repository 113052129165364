import __C from '../primitives/_constant_'
import { AccountApi } from './api'
import Vue from 'vue'
export default class AccountService {
    
  constructor() {
    this.accountApi = new AccountApi()
  }

  signin(creds, callback) {
    // alert(`[ACCOUNT #SERVICE#]`)
    this.accountApi.signin(creds, account => {
      // // login success if there's a jwt token in the response
      if(account.token) {
        localStorage.setItem(__C.LOCAL_STORAGE_NAME.ACCOUNT, JSON.stringify(account))
        if(account.approval == "WAITING") account.error = true
      } else {
        account.error = true
      }
      callback(account)
    })
  }

  signms(creds, callback) {
    //alert(`[ACCOUNT #SERVICE#]`)
    this.accountApi.signms(creds, account => {
      // // login success if there's a jwt token in the response
      if(account.token) {
        localStorage.setItem(__C.LOCAL_STORAGE_NAME.ACCOUNT, JSON.stringify(account))
        if(account.approval == "WAITING") account.error = true
      } else {
        account.error = true
      }
      callback(account)
    })
  }

  signout(callback) {
    try {
      // 로컬 스토리지 및 쿠키 클리어
      Vue.$cookies.remove('token')
      localStorage.removeItem(__C.LOCAL_STORAGE_NAME.ACCOUNT)
      localStorage.removeItem(__C.LOCAL_STORAGE_NAME.TOKEN)
      
      // Keycloak 인스턴스가 있는지 확인
      if (window.keycloak && window.keycloak.authenticated) {
        // Keycloak 로그아웃은 auth 페이지에서 처리될 것이므로,
        // 여기서는 로컬 데이터만 정리
        callback()
      } else {
        // Keycloak 인스턴스가 없는 경우 직접 콜백 실행
        callback()
      }
    } catch (error) {
      console.error('Signout error:', error)
      // 에러가 발생해도 콜백 실행
      callback()
    }
  }
}

