import * as d3 from 'd3'
import Defs from '../../../../includes/primitives/colorDefs'

export default {
  methods: {

    chart_Controller() {

      // this.svg
      // .append('text')
      // .attr('transform', `translate(800,15)`)
      // .style('font-size', 10).attr('fill', '#757575')
      // .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      // .text('TUBE').attr('cursor', 'pointer')
      // .on('click', () => { 
      //   if(d3.select(`#Summary_Group_${this.localId}`)) d3.select(`#Summary_Group_${this.localId}`).remove()
      //   this.draw_Tube()
      // })

      // this.svg
      // .append('text')
      // .attr('transform', `translate(850,15)`)
      // .style('font-size', 10).attr('fill', '#757575')
      // .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      // .text('Cube A').attr('cursor', 'pointer')
      // .on('click', () => { 
      //   if(d3.select(`#Summary_Group_${this.localId}`)) d3.select(`#Summary_Group_${this.localId}`).remove()
      //   this.draw_Cube_A()
      // })

      // this.svg
      // .append('text')
      // .attr('transform', `translate(900,15)`)
      // .style('font-size', 10).attr('fill', '#757575')
      // .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      // .text('Cube B').attr('cursor', 'pointer')
      // .on('click', () => { 
      //   if(d3.select(`#Summary_Group_${this.localId}`)) d3.select(`#Summary_Group_${this.localId}`).remove()
      //   this.draw_Cube_B()
      // })

      switch(this.ChartType) {
        case 'TUBE':
          this.draw_Tube()
          break

        case 'CUBE-A':
          this.draw_Cube_A()
          break

        case 'CUBE-B':
          this.draw_Cube_B()
          break
      }
    },


    draw_Tube() {

      let y = this.texts.header.visible == 'Y' ? 30.5 : 15.5

      let summary_Group = this.svg
      .append('g')
      .attr('id', `Summary_Group_${this.localId}`)
      .attr('transform', `translate(${this.area.x + (this.tube.tickness/2)},${this.area.y + (this.tube.tickness/4) + y})`)
      .style('font-family', this.area.font)

      let TubeScale = d3.scaleLinear()
      .domain([0, 100])
      .rangeRound([this.tube.height, 0])

      this.set_ColorDefs(summary_Group)

      Defs.setDefs_Reflections(summary_Group, this.localId)
      Defs.setDefs_Gradients(summary_Group, 0, 'LinearA2', this.tube.color, [0, 0.5, 1, 0.5], this.localId)
      Defs.setDefs_Gradients(summary_Group, 1, 'LinearA9', this.tube.color, [0, 0.5, 1, 0.5], this.localId)

      this.tube_Chart(summary_Group, TubeScale)
      this.tube_Values(summary_Group, TubeScale)
    },


    draw_Cube_A() {
      let summary_Group = this.svg
      .append('g')
      .attr('id', `Summary_Group_${this.localId}`)
      .attr('transform', `translate(${this.area.x},${this.area.y})`)
      .style('font-family', this.area.font)

      this.Cube_A(summary_Group)
    },


    draw_Cube_B() {
      let summary_Group = this.svg
      .append('g')
      .attr('id', `Summary_Group_${this.localId}`)
      .attr('transform', `translate(${this.area.x},${this.area.y})`)
      .style('font-family', this.area.font)

      this.Cube_B(summary_Group)
    },


    set_Value(format, value) {
      
      if(format == '$') {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else if(format == '%') {
        return `${value}%`
      } else {
        return value
      }

    },
    
  }
}