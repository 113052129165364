export default {
  methods: {
    setDefaultValues(){
      this.svg
      .style('overflow', 'auto')
      .style('background-color', 'transparent')

      let depth_0 = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `${this.localId}_depth_0`).attr('x1', 1).attr('x2', 0).attr('y1', 0.5).attr('y2', 0.5)
      depth_0.append('stop').attr('stop-color', '#5fa2d9').attr('offset', '0')
      depth_0.append('stop').attr('stop-color', '#095fa7').attr('offset', '1')

      // let depth_1 = this.svg
      // .append('defs')
      // .append('linearGradient').attr('id', `${this.localId}_depth_1`).attr('x1', 1).attr('x2', 0).attr('y1', 0.5).attr('y2', 0.5)
      // depth_1.append('stop').attr('stop-color', '#74B8F2').attr('offset', '0')
      // depth_1.append('stop').attr('stop-color', '#1574BB').attr('offset', '1')

      let depth_2 = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `${this.localId}_depth_1`).attr('x1', 1).attr('x2', 0).attr('y1', 0.5).attr('y2', 0.5)
      depth_2.append('stop').attr('stop-color', '#83CEF5').attr('offset', '0')
      depth_2.append('stop').attr('stop-color', '#3A91D4').attr('offset', '1')

      // let depth_3 = this.svg
      // .append('defs')
      // .append('linearGradient').attr('id', `${this.localId}_depth_3`).attr('x1', 1).attr('x2', 0).attr('y1', 0.5).attr('y2', 0.5)
      // depth_3.append('stop').attr('stop-color', '#A8E1FB').attr('offset', '0')
      // depth_3.append('stop').attr('stop-color', '#5EB9E9').attr('offset', '1')


      let depth_4 = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `${this.localId}_depth_2`).attr('x1', 1).attr('x2', 0).attr('y1', 0.5).attr('y2', 0.5)
      depth_4.append('stop').attr('stop-color', '#C7EBFB').attr('offset', '0')
      depth_4.append('stop').attr('stop-color', '#7ABEE2').attr('offset', '1')


      var defs = this.svg
      .append('defs')

      var filter = defs.append('filter')
        .attr('id', 'tapsShadow').attr('width', 5).attr('height', 5)
        
        filter.append('feGaussianBlur')
        .attr('in', 'SourceAlpha')
        .attr('stdDeviation', 3)
        .attr('result', 'blur');
        
        filter.append('feFlood')
        .attr('flood-color','#000')
        .attr('flood-opacity', 0.3)
        .attr('result', 'color')
        
        filter.append('feComposite')
        .attr('in', 'color')
        .attr('in2', 'blur')
        .attr('operator', 'in')
        .attr('result', 'shadow')
      
        filter.append('feOffset')
        .attr('in', 'shadow')
        .attr('dx', -2)
        .attr('dy', 3)
        .attr('result', 'dropshadow')

      var feMerge = filter.append('feMerge')

        feMerge.append('feMergeNode')
        .attr('in', 'dropshadow')
        
        feMerge.append('feMergeNode')
        .attr('in', 'SourceGraphic');


    },

    
    
    
  }
}