import * as d3 from 'd3'

export default {

  methods: {
    /*
      getMaxBound(v)
      getMinBound(v)
      AddComma(num)
    */

    getMaxBound(v) {
      if(isNaN(v)) return 0
 
      let autoValues = [5, 10, 15, 20, 25, 40, 50, 75, 100, 125, 150, 200, 250, 300, 400, 500, 750, 1000, 1250, 1500, 2000, 2500, 3000, 4000, 5000, 7500, 10000, 12500, 15000, 17500, 20000, 25000, 30000, 40000, 50000, 75000, 100000]
      let valid_ = autoValues.find(val => val > v)
      let increment_ = 100

      while(!valid_) {
        valid_ = autoValues.map(v => v * increment_).find(val => val > v)
        increment_ = increment_ * 100
      }
      return valid_
    },

    getMaxBound_v2(v) {
      if(isNaN(v)) return 0
 
      let autoValues = [5, 10, 15, 20, 25, 40, 50, 75, 100, 125, 150, 200, 250, 300, 400, 500, 750, 1000, 1250, 1500, 2000, 2500, 3000, 4000, 5000, 7500, 10000, 12500, 15000, 17500, 20000, 25000, 30000, 40000, 50000, 75000, 100000]
      let valid_ = autoValues.find(val => val >= v)
      let increment_ = 100

      while(!valid_) {
        valid_ = autoValues.map(v => v * increment_).find(val => val > v)
        increment_ = increment_ * 100
      }
      return valid_
    },

    getMaxBound_wk(v) {
      if(isNaN(v)) return 0
 
      let autoValues = [0.25, 0.5, 0.75, 1.0, 1.5, 2.0, 2.5]
      let valid_ = autoValues.find(val => val >= v)
      let increment_ = 100

      while(!valid_) {
        valid_ = autoValues.map(v => v * increment_).find(val => val > v)
        increment_ = increment_ * 100
      }
      return valid_
    },


    getMinBound(v) {
      if(isNaN(v)) return 0

      let autoValues = [1000, 750, 500, 400, 300, 250, 200, 150, 125, 100, 75, 50, 30, 25, 20, 15, 10, 5, 0, 15, 20, 25, 30, 40, 50, 75, 100]
      return autoValues.find(val => val < v)
    },
    

    AddComma(num){
      var regexp = /\B(?=(\d{3})+(?!\d))/g;
      return num.toString().replace(regexp, ',')
    },

    Capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },

    setUndefined (str) {
      if (str == undefined) return 0
      else return str
    },

    setDeltaColor(no) {
      if (no == 0) return '#757575'
      if (no > 0) return '#44A9DF'
      if (no < 0) return '#F35E90'
    }
  }
}
