export default {
  data: () => ({
    rawData:    [],

    SCurve:     null,

    timeline:   {},
    Chart:      {},
    Title:      { main: {},sub: {}, },
    Primary:    { title: {}, value: {}, },
    Grid:       {},
    Legend:     {},
    Line:       [],

    scaleX:     null,
    scaleY:     null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      // raw data
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      // Styles
      let s_ = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.timeline = {
        length          : s_.CHART_WIDTH,
        cutoff          : s_.TL_CUTOFF,
        startDate       : s_.TL_START,
        endDate         : s_.TL_END,
        weekEnd         : s_.TL_WEEKEND,
        month_Visible   : s_.TL_MONTH_VISIBLE,
        mColor          : s_.TL_MONTH_TCOLOR,
        mSize           : s_.TL_MONTH_TSIZE,
        yColor          : s_.TL_YEAR_TCOLOR,
        ySize           : s_.TL_YEAR_TSIZE,

        day             : [],
        week            : [],
        month           : [],
        year            : [],
        scale           : null,
        height          : 35,
      }

      this.Chart.x                = s_.CHART_X
      this.Chart.y                = s_.CHART_Y
      this.Chart.width            = s_.CHART_WIDTH
      this.Chart.height           = s_.CHART_HEIGHT
      this.Chart.sColor           = s_.CHART_BORDER_COLOR
      this.Chart.sWidth           = s_.CHART_BORDER_SWIDTH
      this.Chart.opacity          = s_.CHART_BORDER_OPACITY

      this.Title.align            = s_.TITLE_ALIGN
      this.Title.main.visible     = s_.TITLE_MAIN_VISIBLE
      this.Title.main.Text        = s_.TITLE_MAIN
      this.Title.main.tColor      = s_.TITLE_MAIN_TCOLOR
      this.Title.main.tSize       = s_.TITLE_MAIN_TSIZE
      this.Title.main.margin      = s_.TITLE_MAIN_MARGIN

      this.Title.sub.visible      = s_.TITLE_SUB_VISIBLE
      this.Title.sub.Text         = s_.TITLE_SUB
      this.Title.sub.tColor       = s_.TITLE_SUB_TCOLOR
      this.Title.sub.tSize        = s_.TITLE_SUB_TSIZE
      this.Title.sub.margin       = s_.TITLE_SUB_MARGIN
      
      this.Primary.title.name     = s_.PRIMARY_TITLE_NAME
      this.Primary.title.tColor   = s_.PRIMARY_TITLE_TCOLOR
      this.Primary.title.tSize    = s_.PRIMARY_TITLE_TSIZE
      this.Primary.title.margin   = s_.PRIMARY_TITLE_MARGIN
      this.Primary.value.tColor   = s_.PRIMARY_VALUE_TCOLOR
      this.Primary.value.tSize    = s_.PRIMARY_VALUE_TSIZE
      this.Primary.value.margin   = s_.PRIMARY_VALUE_MARGIN

      this.Grid.sColor            = s_.GRID_COLOR
      this.Grid.sWidth            = s_.GRID_SWIDTH
      this.Grid.opacity           = s_.GRID_OPACITY
      this.Grid.height            = this.Chart.height / 5 // append

      this.Legend.tColor          = s_.LEGEND_TCOLOR
      this.Legend.tSize           = s_.LEGEND_TSIZE
      this.Legend.margin          = s_.LEGEND_MARGIN
      this.Legend.align           = s_.LEGEND_ALIGN

      // Line Chart Properties   
      let codes = s_.REF_CODE.replace(/\s/g, '').split('/')

      if (this.Line.length == 0) {
        codes.forEach((d,i)=> {
          this.Line.push({
            code    : s_.REF_CODE.replace(/\s/g, '').split('/')[i],
            name    : s_.NAME.replace(/\s/g, '').split('/')[i],
            type    : s_.LINE_TYPE.replace(/\s/g, '').split('/')[i],
            sWidth  : s_.LINE_SIZE.replace(/\s/g, '').split('/').map(Number)[i],
            sColor  : s_.LINE_COLOR.replace(/\s/g, '').split('/')[i],
            Opacity : s_.LINE_OPACITY.replace(/\s/g, '').split('/').map(Number)[i],
          })
        })
      }




      this.init_gmx_TimelineValues(this.timeline, 'day')
    },

  }
}