export default {
  methods: {
    
    draw_Header() {

      let modalHeader = this.ContainerGroup
      .append('g')
      .attr('transform', `translate(0,0)`)

      modalHeader
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', this.ModalDraw.Width)
      .attr('height', this.ModalDraw.Height)
      .attr('stroke-width', 0)
      .attr('opacity', this.ModalDraw.Opacity)
      .attr('fill', this.ModalDraw.Color)


      // Lines
      modalHeader
      .append('g')
      .selectAll('line')
      .data(this.ModalDraw.Lines)
      .enter()

      .append('line')
      .attr('x1', d => d.x1)
      .attr('y1', d => d.y1)
      .attr('x2', d => d.x2)
      .attr('y2', d => d.y2)
      .attr('stroke', d => d.color)
      .attr('stroke-width', d => d.stroke)

      // Rects
      modalHeader
      .append('g')
      .selectAll('rect')
      .data(this.ModalDraw.Rects)
      .enter()

      .append('rect')
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('rx', d => d.radius)
      .attr('ry', d => d.radius)
      .attr('width', d => d.width)
      .attr('height', d => d.height)
      .attr('stroke', d => d.strokeColor)
      .attr('stroke-width', d => d.strokeWidth)
      .attr('fill', d => d.fillColor)

      // Icons
    },
  }
}
