import * as d3 from 'd3'

export default {
  methods: {
    drawNotes(svg=null) {
      if(this.Note.NoteDisplay != 'Y') return
      
      let noteGroup = (svg ? svg : this.svg)    // Title
      .append('g')
      .attr('id', 'NoteX$NoteY')
      .attr('transform', `translate(${this.Note.NoteX},${this.Note.NoteY})`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)

      noteGroup
      .append('text')
      .attr('class', this.Note.NoteTitleStyle)
      .style('font-size', this.Note.NoteTitleSize)
      .style('font-family', this.Note.NoteTitleFont)
      // .style('font-weight', 500)
      .attr('fill', this.Note.NoteTitleColor)
      .attr('text-anchor', this.Note.NoteAlign)
      .text(this.Note.NoteTitleText)

      noteGroup // Note Contents
      .append('text')
      .attr('class', this.Note.NoteContentsStyle)
      .attr('x', 0)
      .attr('y', Math.round(this.Note.NoteTitleSize * 0.2))
      .style('font-family', this.Note.NoteContentsFont)
      // .style('font-weight', 300)
      .style('font-size', this.Note.NoteContentsSize)
      .attr('fill', this.Note.NoteContentsColor)
      .attr('text-anchor', this.Note.NoteAlign)
      .selectAll('tspan')
      .data((this.Note.NoteContentsText || '').split('\n'))
      .enter()
      .append("tspan")
      .attr("x", 0)
      // .attr("dx", 0)
      .attr("dy", Math.round(this.Note.NoteContentsSize * 1.2))
      .text(d => d)
    }
  }
}
