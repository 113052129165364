import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
import Data from '../../../../includes/primitives/Color_Data'

export default {
  methods: {
    drawChartVStack() {

      let keys_ = Object.keys(this.DataItems[0]).splice(1)

      let chartWrapper = this.chartArea
      .append('g')
      .selectAll('g')
      .data(d3.stack().keys(keys_)(this.DataItems))
      .enter()
      .append('g')
      .attr('fill', (_, i) => (this.SharedColorSet[i]))
      // .attr('fill', (_, i) => `url(#LinearA2a${i})`)
      .selectAll('rect')
      .data((d) => d)
      .enter()

      chartWrapper
      .append('rect')
      .attr('x', (d, i) => this.scaleXInfo.scale(this.DataItems[i].title))
      // .transition()
      // .duration(150)
      // .attr('transform', d => `translate(0,${this.scaleYInfo.scale(d.value)})`)
      .attr('y', (d) => d[1] >=  0 ? this.scaleYInfo.scale(d[1]) : this.scaleYInfo.scale(d[0]))
      .attr('width', this.setThickness * .75)
      .attr('height', (d) => Math.abs(this.scaleYInfo.scale(d[0]) - this.scaleYInfo.scale(d[1])))
      // .append("text", "dfdfddfddf")

      // ------------------------------------------------------------------------- Series & Value

      let valueWrapper = this.chartArea
      .append('g')
      .selectAll('g')
      .attr('id', (_, i) => `value_wrapper_${i}`)
      .data(d3.stack().keys(keys_)(this.DataItems))
      .enter()
      .append('g')
      .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.SharedColorSet[i]) : this.Bar.BarValueColor))
      // .attr('fill', (_, i) => `url(#LinearA2a${i})`)
      .selectAll('rect')
      .data((d) => d)
      .enter()

      if (this.Bar.BarValueDisplay == 'Y') {
        valueWrapper
        .append('text')
        .attr('id', (d, i) => {
          return `ValueText${i}`
        } )
        .style('font-size', this.Bar.BarValueSize)
        .style('font-family', this.Bar.BarValueFont)
        .attr('text-anchor', 'middle')
        .attr('class', this.Bar.BarValueStyle)
        // .attr('x', (d, i) => this.scaleXInfo.scale(this.DataItems[i].title))Math.round(this.setThickness / 2)
        .attr('x', (d, i) => this.scaleXInfo.scale(this.DataItems[i].title)+this.setThickness/3+1)
        // .transition()
        // .duration(150)
        // .attr('transform', d => `translate(0,${this.scaleYInfo.scale(d.value)})`)
        .attr('y', (d) => {
          let middleOfBoxHeight = (d[1] - d[0]) / 2
          let textYPosition
          // Magic number for hanging text at top of Box
          let textAlign4Hang = 2
          if(d[1] >=  0) {
            if(this.Bar.BarValuePosition == 'middle') {
              textYPosition = this.scaleYInfo.scale(d[1]) + middleOfBoxHeight
            } else if(this.Bar.BarValuePosition == 'bottom') {
              textYPosition = this.scaleYInfo.scale(d[1]) + this.Bar.BarValueSize
            } else {
              textYPosition = this.scaleYInfo.scale(d[1])
            }

            return  textYPosition + (this.Bar.BarValuePosition == 'top' ? -this.Bar.BarValueSpace : this.Bar.BarValueSpace -textAlign4Hang)
          }
          else {
            return this.scaleYInfo.scale(d[0])
          } 
        })
        // .style('alignment-baseline', this.Bar.BarValuePosition == 'below' ? 'hanging' : 'baseline')
        // Alignment of stack value
        //  if(this.Bar.BarValuePosition == 'top') {
        //   return this.scaleYInfo.scale(d[1]) 
        // } else if (this.Bar.BarValuePosition == 'below') {
        //   j[i].setAttribute(
        //     'alignment-baseline', 'hanging'
        //   )
          
        //   j[i].setAttribute(
        //     'y', this.scaleYInfo.scale(d[1])
        //   );
        // } else {
        //   return this.scaleYInfo.scale(d[1]) 
        // }

        //.style('font-weight', this.Bar.BarValueWeight)
        // .attr('fill', (d, i) => {
        //   console.log(d);
        //   return (this.Bar.BarValueAutoColor == 'Y' ? (this.SharedColorSet[i]) : this.Bar.BarValueColor)
        // })
        // .text((d , i) => `${this.DataItems[keys_[i]].toFixed(this.Bar.BarValueRound)}`)
        .text((d , i) =>{
          let val = d[1] >= d[0] ? (d[1] - d[0]) : d[1]
          return val == 0 ? '' : val.toFixed(this.Bar.BarValueRound)
        })
      }

      if (this.Bar.BarUnitDisplay == 'Y') {
        valueWrapper // Units
        .append('text')
        .attr('transform', (_, i) => `translate(${this.Bar.BarValueDisplay == 'Y' ? Number(this.getNodeElValue(`#ValueText${i}`, 'width')) : 0},0)`)
        .attr('id', (_, i) => `ValueUnit${i}`)
        .style('font-size', this.Bar.BarUnitSize)
        .style('font-family', this.Bar.BarValueFont)
        .attr('class', this.Bar.BarValueStyle)
        .attr('x', (d, i) => this.scaleXInfo.scale(this.DataItems[i].title)+this.setThickness/3-7)
        // .transition()
        // .duration(150)
        // .attr('transform', d => `translate(0,${this.scaleYInfo.scale(d.value)})`)
        // .attr('y', (d) => d[1] >=  0 ? this.scaleYInfo.scale(d[1]) : this.scaleYInfo.scale(d[0]))
        //.style('font-weight', this.Bar.BarValueWeight)
        // .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarValueColor))
        //.style('font-weight', this.Bar.BarValueWeight)
        // .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.SharedColorSet[i]) : this.Bar.BarUnitColor))
        .attr('y', (d) => {
          let middleOfBoxHeight = (d[1] - d[0]) / 2
          let textYPosition
          // Magic number for hanging text at top of Box
          let textAlign4Hang = 2
          if(d[1] >=  0) {
            if(this.Bar.BarValuePosition == 'middle') {
              textYPosition = this.scaleYInfo.scale(d[1]) + middleOfBoxHeight
            } else if(this.Bar.BarValuePosition == 'bottom') {
              textYPosition = this.scaleYInfo.scale(d[1]) + this.Bar.BarValueSize
            } else {
              textYPosition = this.scaleYInfo.scale(d[1])
            }

            return  textYPosition + (this.Bar.BarValuePosition == 'top' ? -this.Bar.BarValueSpace : this.Bar.BarValueSpace -textAlign4Hang)
          }
          else {
            return this.scaleYInfo.scale(d[0])
          } 
        })
        .text(this.Bar.BarUnitFormat)
      }

      // Reset the position of the value-wrapper 
      // this.DataItems.forEach((d, i) => {
      //   let valueWrapperId_ = `#value_wrapper_${i}`
      //   let wrapperWidth_ = this.getNodeElValue(valueWrapperId_, 'width')
        
      //   this.svg.select(`#value_wrapper_${i}`).
      //   attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)},${this.scaleYInfo.scale(d.value) - this.Bar.BarValueSpace })`)
      // })

    },
  }
}