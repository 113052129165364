<template>
  <div class="j_service_svg_tabs">
    <j-page-layout-builder-svg
      class="component--content-target"
      :mode="__C_.DASHBOARD.BUILDER_MODE_VIEWER"
      :chart-items="chartItems"
      :filters="filters.filters"
      @request-action="onRequestedAction"
    />

    <component 
      class="component--content-target"
      ref="target"
      :class="blindScreen"
      :is="maComponent"
      :filters="maFilters"
      :target="maTarget"
      @complete="onComplete"
    />

    <j-overlay 
      absolute
      :value="loading"
    >
      <j-spinner-orbit
        :border="3"
        :color="'#b3e5fc'"
        :animation-duration="1000"
        :size="150"
      />
    </j-overlay>
  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_service.svgtabs.styl'

import __C from '@/primitives/_constant_'
import { ApplicationService, ChartLibraryService } from '@/services'
import ServiceDashboard from '@/views/service/ServiceDashboardSingle'
import ServiceDatatable from '@/views/service/ServiceDatatable'
import ServicePageSvg from '@/views/service/ServicePageSvgSingle'
 import ServiceCompPage from '@/views/service/ServiceCompPage'
import Comment from '@/mixins/comment.mixin'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'service-svg-tabs',
  mixins: [
    Comment
  ],
  components: {
    ServiceDashboard,
    ServiceDatatable,
    ServicePageSvg,
    ServiceCompPage
  },
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({
        id: Number,
        no: String
      })
    },
  },
  data: () => ({
    // Service Instances -------------
    appService: null,
    chartLibraryService: null,
    
    // general data props ------------
    chartItems: [],
    dashboardId: null,
    dashboard: {
      layoutAttrs: {},
      containerAttrs: []
    },
    dashboardCItems: [],
    filterActs: {},
    requestUpdated: {},

    dimention: {},

    maOpened: false,
    maComponent: '',
    maFilters: null,
    maTarget: null,

    runByRun: false,
    blindScreen: 'off',
    loading: false,
    tabStatus: []
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.SLIDE_MODAL, ['modals']),
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    __C_() { return __C },
  },
  watch: {
    modals() {
      let modalLen = this.modals.length -1
      this.setModalIndex({id: this.modals[modalLen].id, idx: this.maTarget.id})  
    },
  },
  created() {
    this.appService = new ApplicationService()
    this.chartLibraryService = new ChartLibraryService()
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.SLIDE_MODAL, [
      'setLayoutAttrs', 'setModalIdx', 'setModalComponent', 'setModalIndex'
    ]),
    onComplete(v) {
      this.$emit('complete', { ...v, name: this.chartItems[0].Name })

      this.setLayoutAttrs({ ...v, name: this.chartItems[0].Name })
    },
    onDashboardResize(d) {
      this.dimention = {
        width: Math.round(d.width),
        height: Math.round(d.height)
      }
      this.$emit('resize', this.dimention)
    },
    onRequestedAction(_request) {
      if(!this.runByRun) {
        this.blindScreen = 'on'
        // this.loading = true
      } else this.runByRun = false

      let request = _request.sender

      if(!request.action) {
        console.log(`[USER: undefined 'action'] Target is not defined.`)
        return
      }
      if(!request.action.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }

      this.setModalIdx(request.action.id)
      this.setModalComponent(request.action.component)

      this.maOpened = true
      this.maComponent = request.action.component
      this.maTarget = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: '',
        id: request.action.id,
        no: request.action.no,
      }
      this.maFilters = {
        filters: { ...this.filters.filters, ...request.filters },
        iFilters: { ...request.iFilters, inputFilter: this.inputFilter }
      }

      if(this.maFilters && this.maFilters.filters && Object.keys(this.maFilters.filters).includes('SYS')) {
        this.setCommentProps({
          refCode: this.maFilters.filters.SYS
        })
      }

      console.log(`[USER: #DEBUG] Target  [0]: ${this.maComponent} ${JSON.stringify(this.maTarget)}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${JSON.stringify(this.maFilters.filters)}`)
      console.log(`[USER: #DEBUG] iFilters[2]: ${JSON.stringify(this.maFilters.iFilters)}`)

      let setOff = () => {
        setTimeout(() => { 
          this.blindScreen = 'off'
          // this.loading = false
        }, 500)
      }
      setTimeout(() => { 
        if(this.$refs.target.run) this.$refs.target.run().then(() => { setOff() })
        else setOff()
      }, 300)
    },
    getChart(no) {
      this.dashboard = {
        layoutAttrs: {},
        containerAttrs: []
      }
      this.dashboardCItems = []
      this.filterActs = {}

      return new Promise(resolve => {
        this.chartLibraryService.getChartCustom(no, this.filters.filters, res => {
          this.chartItems = [res]
          resolve(null)
        })
      })
    },
    getContainerActionQResult(request) {
      if(!request.idx) {
        console.error('No Dashboard Idx found.')
        return
      }

      let params_ = new URLSearchParams()
      params_.append('idx', request.idx)
      params_.append('action', JSON.stringify(request.action))
      params_.append('filters', JSON.stringify(request.filters))
      return new Promise((resolve) => {
        this.appService.getContainerActionQResult(params_, (res) => {
          resolve(res)
        })
      })
    },
    getDashboard(request) {
      let params_ = new URLSearchParams()
      params_.append('idx', request.idx)
      params_.append('catCode', 'Modal')
      params_.append('fValues', request.filters)
      params_.append('actions', '')
      params_.append('reqType', 'viewer')
 
      this.appService.getDashboard(params_, (res) => {
        this.dashboard = {}
        this.dashboardCItems = []
        
        setTimeout(() => {
          this.dashboard = res.dashboard
          this.dashboardCItems = res.chartItems
        })
      })
    },

    requestedActionTabs(request) {
      let filters_ = (
        !request.sender.filters || 
        Object.keys(request.sender.filters).length === 0 ? 
        '' : 
        JSON.stringify(request.sender.filters)
      )

      this.getDashboard({
        idx: request.sender.action.id,
        filters: filters_
      })

      // Keep current selected Dashboard ID & its filters
      // to process container's request.
      this.dashboardId = Number(request.sender.action.id)
      this.filterActs = filters_ ? JSON.parse(filters_) : {}
    },
    requestedActionContainer(request) {

      /* TO DO : Perform the right process by parent type (controller or others) */

      // // check request comming from controller
      // if(request.parent.roleNo && request.parent.roleNo.indexOf('CTL') === 0)
      // else 

      this.getContainerActionQResult({
        idx: this.dashboardId,
        action: {
          containerNo: request.parent.containerNo,
          ...request.parent.action,
          ...request.sender.action
        },
        filters: {
          ...request.sender.filters
        }
      }).then(res => {
        this.requestUpdated = {
          chartItems: res ? res : [],
          location: request.parent.location
        }
      })
    },

    run() {
      this.runByRun = true
      if(this.target.no) return this.getChart(this.target.no)

      return new Promise(resolve => {
        this.runByRun = false
        resolve(null)
      })
    }
  }
}
</script>
