import * as d3 from 'd3'

export default {

  methods: {
    
    draw_Timeline() {    

      let timeline = this.Trend

      this.rawData.forEach((d,i)=> {
        let date_ = new Date(d.CDATE)

        timeline
        .append('line')
        .attr('x1', this.scaleX(d.CDATE)).attr('x2', this.scaleX(d.CDATE))
        .attr('y1', this.Chart.height).attr('y2', this.Chart.height + 3)
        .attr('stroke', '#757575').attr('stroke-width', 0.5)
        .attr('opacity', 1)

        timeline
        .append('text')
        .attr('x', this.scaleX(d.CDATE))
        .attr('y', this.Chart.height + 10)
        .style('font-size', this.timeline.tSize).style('fill', this.timeline.tColor)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .text(`${date_.getDate()}/${date_.getMonth()+1}`)
      })
      
      let nowX = this.scaleX(this.timeline.cutoff)
      this.Trend
      .append('line')
      .attr('x1', nowX).attr('x2', nowX)
      .attr('y1', this.Chart.height + 15).attr('y2', this.Chart.height + 30)
      .attr('stroke', '#B5549B').attr('stroke-width', 0.5)
      .attr('opacity', 1)

      this.Trend
      .append('text')
      .attr('x', nowX - 3)
      .attr('y', this.Chart.height + 25)
      .style('font-size', this.timeline.tSize + 1).style('fill', '#B5549B')
      .attr('text-anchor', 'end')
      .attr('alignment-baseline', 'middle')
      .text(`Past 8 weeks`)

      this.Trend
      .append('text')
      .attr('x', nowX + 3)
      .attr('y', this.Chart.height + 25)
      .style('font-size', this.timeline.tSize + 1).style('fill', '#B5549B')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(`Next 4 weeks`)


    }, 
  }
}
