import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_Watching           from './Watching'
import lmx_Events             from './Events'
  
// Charts 
import lmx_LocalValues        from './set_LocalValues'
import lmx_draw_Chart         from './draw_Chart'
import lmx_draw_Overall       from './draw_Overall'
import lmx_draw_Detail        from './draw_Detail'



export default {
  
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_Events,
    lmx_Watching,

    lmx_LocalValues,
    lmx_draw_Chart,
    lmx_draw_Overall,
    lmx_draw_Detail,

  ],

}
