export default {
  
  setReflection: [
    { name: 'Offset', values: [0, 0.268, 0.79, 0.895, 1] },
    { name: 'Opacity', values: [0, 0.569,0.929, 0.969, 1] },
    { name: 'Color', values: ['#fff', '#fff', '#fff', '#fff', '#fff'] },
  ],
  
  // Color Data for Gradients
  setGradients: {
    Length: {
      LinearA2: 2,
      LinearA4: 4,
      LinearA9: 9,
    },

    Represent : (name) => {
      let data = { 
        LightGray: '#B7B7B7',
        Gray: '#787777',
        Yellow: '#C7C702',
        Gold: '#AE6800',
        Orange: '#EB3A16',
        Pink: '#D8678D',
        Violet: '#AD0365',
        Red: '#A4111A',
        Thistle: '#BC6BBC',
        Purple:'#542F82',
        Indigo: '#333A85',
        LightBlue: '#66AED4',
        SkyBlue: '#277DAB',
        SteelBlue: '#005374',
        YellowGreen: '#97AC32',
        DarkGreen: '#478515',
      }
      return data[name]
    },

    RepresentR : (code) => {
      let data = { 
        '#B7B7B7': 'LightGray',
        '#787777': 'Gray',
        '#C7C702': 'Yellow',
        '#AE6800': 'Gold',
        '#EB3A16': 'Orange',
        '#D8678D': 'Pink',
        '#AD0365': 'Violet',
        '#A4111A': 'Red',
        '#BC6BBC': 'Thistle',
        '#542F82': 'Purple',
        '#333A85': 'Indigo',
        '#66AED4': 'LightBlue',
        '#277DAB': 'SkyBlue',
        '#005374': 'SteelBlue',
        '#97AC32': 'YellowGreen',
        '#478515': 'DarkGreen',
      }
      return data[code]
    },

    LinearA2: (name, i) => {
      let data = {
        Opacity: [1, 1],
        Offset: [0, 1],
        LightGray: ['#CFCFCF', '#eeeeee'],
        Gray: ['#B7B7B7', '#e9e9e9'],
        Yellow: ['#c7c702', '#ffff7b'],
        Gold: ['#ae6800', '#ffdb00'],
        Orange: ['#eb3a16', '#ffc900'],
        Pink: ['#d8678d', '#fce3eb'],
        Violet: ['#D2067C', '#E593C3'],
        Red: ['#a4111a', '#e74540'],
        Thistle: ['#bc6bbc', '#e5d5e5'],
        Purple: ['#542f82', '#d1b9ef'],
        Indigo: ['#333a85', '#b38dc1'],
        LightBlue: ['#66aed4', '#d4eaf3'],
        SkyBlue: ['#277dab', '#44a9df'],
        SteelBlue: ['#005374', '#3ac5f2'],
        YellowGreen: ['#8ba123', '#d7ee51'],
        DarkGreen: ['#478515', '#b1c91e'],
      }
      return data[name][i]
    },

    LinearA4: (name, i) => {
      let data = {
        Opacity: [1, 1, 1, 1],
        Offset: [0, 0.41, 0.80, 1],
        LightGray: ['#b4b4b4', '#dcdcdc', '#f6f6f6', '#fbfbfb'],
        Gray: ['#787777', '#afafaf', '#d9d9d9', '#eeeeee'],
        Yellow: ['#b2b200', '#dede3f', '#f1f176', '#ffff9d'],
        Gold: ['#9f5f00', '#d29b00', '#f8cf08', '#ffe43d'],
        Orange: ['#c83314', '#f3730d', '#f9a518', '#fbcd21'],
        Pink: ['#ce5a81', '#e79cb6', '#fbdbe6', '#fff5f8'],
        Violet: ['#D2067C', '#bc2f81', '#df7cb6', '#E593C3'],
        Red: ['#C62828', '#a72426', '#e14d4a', '#eb5a55'],
        Thistle: ['#a75ba7', '#cf9bcf', '#e3c7e3', '#ecdfec'],
        Purple: ['#4d287a', '#8868af', '#c4abe2', '#d9c3f3'],
        Indigo: ['#0e144d', '#574896', '#926fae', '#ba94c8'],
        LightBlue: ['#539bc1', '#91c6e0', '#c6e4f2', '#e1f2f9'],
        SkyBlue: ['#1E88E5', '#338fc0', '#5cb6e5', '#78c6ef'],
        SteelBlue: ['#005374', '#217da1', '#43a7cf', '#50b8e1'],
        YellowGreen: ['#647709', '#a3b92e', '#cae14e', '#eafb8c'],
        DarkGreen: ['#2d5d06', '#5c8712', '#98bb21', '#c7e129'],
        PunchGreen: ['#C5E1A5', '#C5E1A5', '#C5E1A5', '#C5E1A5'],
        PunchRed: ['#EF9A9A', '#EF9A9A', '#EF9A9A', '#EF9A9A'],
        Red1: ['#FFCDD2', '#FFCDD2', '#FFCDD2', '#FFCDD2'],
        Red2: ['#EF9A9A', '#EF9A9A', '#EF9A9A', '#EF9A9A'],
        Red3: ['#E57373', '#E57373', '#E57373', '#E57373'],
        Red4: ['#EF5350', '#EF5350', '#EF5350', '#EF5350'],
        Red5: ['#F44336', '#F44336', '#F44336', '#F44336'],
        Red6: ['#E53935', '#E53935', '#E53935', '#E53935'],
        Red7: ['#D32F2F', '#D32F2F', '#D32F2F', '#D32F2F'],
        Teal2: ['#80CBC4', '#80CBC4', '#80CBC4', '#80CBC4'],
        Cyan3: ['#4DD0E1', '#4DD0E1', '#4DD0E1', '#4DD0E1'],
        LightBlue5: ['#03A9F4', '#03A9F4', '#03A9F4', '#03A9F4'],
        Green5: ['#4CAF50', '#4CAF50', '#4CAF50', '#4CAF50'],
        LightGreen3: ['#AED581', '#AED581', '#AED581', '#AED581'],
        Lime4: ['#D4E157', '#D4E157', '#D4E157', '#D4E157'],
        Amber4: ['#FFCA28', '#FFCA28', '#FFCA28', '#FFCA28'],
        Yellow3: ['#FFEE58', '#FFEE58', '#FFEE58', '#FFEE58'],
        Orange4: ['#FFA726', '#FFA726', '#FFA726', '#FFA726'],
        Blue6: ['#1E88E5', '#1E88E5', '#1E88E5', '#1E88E5'],
        Indigo3: ['#7986CB', '#7986CB', '#7986CB', '#7986CB'],
        Gray4: ['#BDBDBD', '#BDBDBD', '#BDBDBD', '#BDBDBD'],
        DeepOrange3: ['#FF8A65', '#FF8A65', '#FF8A65', '#FF8A65'],
        LQGreen: ['#81C784', '#81C784', '#81C784', '#81C784'],
        LQGray: ['#E0E0E0', '#E0E0E0', '#E0E0E0', '#E0E0E0'],
      }
      return data[name][i]
    },

    LinearA9: (name, i) => {
      let data = {
        Opacity: [1, 1, 1, 1, 1, 1, 1, 1, 1],
        Offset: [0, 0.09, 0.25, 0.40, 0.53, 0.65, 0.79, 0.93, 1],
        LightGray: ['#c9c9c9', '#d6d6d6', '#e6e6e6', '#F5F2F2', '#FCFCFC', '#FCFCFC', '#F5F2F2', '#e1e1e1', '#d4d4d4'],
        Gray: ['#919191', '#a7a7a7', '#c9c9c9', '#e2e0e0', '#e9e9e9', '#e9e9e9', '#e2e0e0', '#d1d1d1', '#b9b9b9'],
        Yellow: ['#C5C500', '#CECE00', '#E2E239', '#F3F347', '#F5F568', '#F5F568', '#F3F347', '#E8E809', '#DCDC05'],
        Gold: ['#A16000', '#B58106', '#D8B424', '#F1D321', '#F6DF55', '#F6DF55', '#F1D321', '#e2be2c', '#cba305'],
        Orange: ['#e55c00', '#f26b11', '#ff9224', '#ffbb00', '#ffcf27', '#ffcf27', '#ffbb00', '#f8992d', '#f29233'],
        Pink: ['#CF5E84', '#DB7B9B', '#F1A1BC', '#F5BFD1', '#F2C7D4', '#F2C7D4', '#F5BFD1', '#F5A8C2', '#f1a3bd'],
        Violet: ['#c92245', '#cf2e64', '#eb5487', '#f279a3', '#F690B3', '#F690B3', '#f279a3', '#ec5b8c', '#ec467e'],
        Red: ['#7e0f16', '#981517', '#ae2729', '#d13c39', '#e74540', '#e74540', '#d13c39', '#bc3133', '#b1222b'],
        Thistle: ['#bc6bbc', '#c571c5', '#de97de', '#E5AEE5', '#E8C0E8', '#E8C0E8', '#E5AEE5', '#cf97cf', '#d292d2'],
        Purple: ['#542f82', '#6a409f', '#956acc', '#B58BE9', '#BE9DE5', '#BE9DE5', '#B58BE9', '#9B6DD2', '#8356bb'],
        Indigo: ['#212760', '#353d82', '#524896', '#815fa3', '#9665A8', '#9665A8', '#815fa3', '#5a3e9e', '#353d82'],
        LightBlue: ['#56A3CB', '#5EA8CF', '#83C1E2', '#9BD1E8', '#A6DAEE', '#A6DAEE', '#9BD1E8', '#8ec9e8', '#77b6d8'],
        SkyBlue: ['#277dab', '#1584bf', '#309cd5', '#59B6DE', '#6EC8EF', '#6EC8EF', '#59B6DE', '#389ed5', '#3198cf'],
        SteelBlue: ['#004663', '#055675', '#1a7ea7', '#49a2c5', '#65b8d8', '#65b8d8', '#4ea7c9', '#238bb4', '#1682ac'],
        YellowGreen: ['#7d911e', '#8ba123', '#a5bf2c', '#c1d946', '#CFE63F', '#CFE63F', '#c1d946', '#afc543', '#a3b935'],
        DarkGreen: ['#23400d', '#355b17', '#778e3d', '#98b21c', '#aac11e', '#aac11e', '#98b21c', '#7da160', '#6d8f51'],
      }

      return data[name][i]
    },
  },
}


