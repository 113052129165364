import * as d3 from 'd3'

export default {

  methods: {

    draw_gmx_WeekPerformance(selection, style_) {

      style_.data.forEach(d=> {
        // d.date = d.DATE
        if(d.WEEK =='LAST' || d.WEEK =='THIS') {
          d.name = 'Total'
          d.width = style_.width[0]
          d.tColor = '#333'
        } else {
          d.name = d.WEEK
          d.width = style_.width[1]
          d.tColor = '#bcbcbc'
        }
        d.value = d.VALUE
      })
      
      let weekStatus = selection
      .append('g')
      .attr('transform', `translate(${style_.x}, ${style_.y + .5})`)

      weekStatus
      .append('text')
      .attr('x', 0).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('Last Week')

      weekStatus
      .append('text')
      .attr('x', 140).attr('y', 8)
      .style('font-family', 'roboto').style('font-size', 10).style('fill', style_.tColor)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('This Week')

      weekStatus
      .append('line')
      .attr('x1', 35).attr('y1', 8).attr('x2', 110).attr('y2', 8).style('stroke', '#757575').style('stroke-width', 0.3)
      weekStatus
      .append('line')
      .attr('x1', 170).attr('y1', 8).attr('x2', 245).attr('y2', 8).style('stroke', '#757575').style('stroke-width', 0.3)

      
      let drawX = 0

      style_.data.forEach((d,i)=> {
        d.x = drawX

        weekStatus
        .append('text')
        .attr('x', drawX).attr('y', 22)
        .style('font-family', 'roboto').style('font-size', 9).style('fill', d.tColor).attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(d.name)

        weekStatus
        .append('rect')
        .attr('transform', `translate(${drawX - (d.width/2)},30)`)
        .attr('width', d.width).attr('height', 14).attr('stroke-width', .3)
        .attr('stroke', () => { if (d.name == 'Total') return style_.sColor; else return '#bcbcbc' })
        .attr('fill', () => { if(d.name != 'Total' && d.value > 0) return '#F4F5F5'; else return '#fff' })

        weekStatus
        .append('text')
        .attr('id', `weekValue_${this.localId}_${i}`)
        .attr('transform', `translate(${drawX}, 38)`)
        .style('font-family', 'roboto').style('font-size', 10).style('fill', '#757575').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .text(() => {
          if(d.value > 0) return d.value
        })
        // .style('cursor', style_.LINK == 'Y' ? 'pointer' : 'default')
        // .call(this.call_Link, d, style_) // --->

        drawX += d.width + style_.weekGap
        if(i==7) drawX += 10
      })

    },


  }
}
