import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
import Data from '../../../../includes/primitives/Color_Data'

export default {
  methods: {
    drawChartHNormal() {
      let slope = this.setThickness * 0.25

      let chartWrapper = this.chartArea
      .append('g')
      .attr('class', '_chart_wrapper__')
      .selectAll('g')
      .data(this.DataItems)
      .enter()
      .append('g')
      .attr('transform', d => `translate(0,${this.scaleYInfo.scale(d.title)})`)

      // ---------------------------------------------------------------------------------------------------- Reflected Effect
      chartWrapper // Transparent Gradation
      .append('rect')
      .attr('transform', `translate(0,${this.Canvas.CanvasChartWidth})`)
      .attr('height', this.setThickness * 1.5 + 2)
      .attr('width', this.setThickness + 2)
      .attr('fill', 'url(#Color_Reflection)')

      // ------------------------------------------------------------------------- Box Bar
      chartWrapper // Front Box 
      .append('rect')
      .attr('transform', d => {
        `translate(0,${this.scaleXInfo.scale(d.value1)})`
      })
      .attr('width', d => this.scaleXInfo.scale(0) - this.scaleXInfo.scale(d.value1))
      .attr('height', this.setThickness * .75)
      // .attr('fill', (_, i) => `url(#LinearA2a${i})`)
      // .attr('fill', (_, i) => this.Bar.BarValueColor)
      .attr('fill', (_, i) => this.SharedColorSet[i])

      // ------------------------------------------------------------------------- Series & Value


      let valueWrapper = chartWrapper // Value
      .append('g')
      .attr('id', (_, i) => `value_wrapper_${i}`)

      if (this.Bar.BarValueDisplay == 'Y') {
        valueWrapper
        .append('text')
        .attr('id', (_, i) => `ValueText${i}`)
        .style('font-size', this.Bar.BarValueSize)
        .style('font-family', this.Bar.BarValueFont)
        .attr('class', this.Bar.BarValueStyle)
        //.style('font-weight', this.Bar.BarValueWeight)
        .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarValueColor))
        .text(d => `${d.value1.toFixed(this.Bar.BarValueRound)}`)
      }

      if (this.Bar.BarUnitDisplay == 'Y') {
        valueWrapper // Units
        .append('text')
        .attr('transform', (_, i) => `translate(${this.Bar.BarValueDisplay == 'Y' ? Number(this.getNodeElValue(`#ValueText${i}`, 'width')) + 2 : 0},0)`)
        .attr('id', (_, i) => `ValueUnit${i}`)
        .style('font-size', this.Bar.BarUnitSize)
        .style('font-family', this.Bar.BarValueFont)
        .attr('class', this.Bar.BarValueStyle)
        //.style('font-weight', this.Bar.BarValueWeight)
        .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarUnitColor))
        .text(this.Bar.BarUnitFormat)
      }

      // Reset the position of the value-wrapper 
      this.DataItems.forEach((d, i) => {
        // let valueWrapperId_ = `#value_wrapper_${i}`
        // let wrapperWidth_ = this.getNodeElValue(valueWrapperId_, 'width')

        this.svg.select(`#value_wrapper_${i}`).
        attr('transform', `translate(${this.Bar.BarValuePosition == 'top' ? this.scaleXInfo.scale(0) - this.scaleXInfo.scale(d.value1) + this.Bar.BarValueSpace 
          : this.Bar.BarValueSpace }, ${Math.round((this.setThickness) / 2)})`)
      })
    },
  }
}
