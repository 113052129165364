<template>
  <input v-model="checked" type="checkbox" :checked="value == trueValue" />
</template>

<script>
export default {
  name: 'j-check-box',
  props: {
    trueValue: [String, Number],
    falseValue: [String, Number],
    value: null
  },
  computed: {
    checked: {
      get() { return this.trueValue == this.value },
      set(val) { this.$emit('input', val ? this.trueValue : this.falseValue) }
    }
  }
}
</script>
