export default {

  methods: {

    set_Milestone() {

      this.Milestone_Area = this.Chart_Area
      .append('g')
      .attr('transform', `translate(0 , ${0})`)
      .style('font-family', 'roboto').style('cursor', 'default')

     
      this.milestone.forEach(d=> {
        this.draw_Milestone(d)
      })
      
    },
    
    draw_Milestone(d) {

      let x = this.timeline.scale(new Date(d.MSDATE))

      let milestone = this.Milestone_Area
      .append('g')
      .attr('transform', `translate(${parseInt(x)}, ${0})`)
      .style('font-family', 'roboto').style('cursor', 'default')

      milestone
      .append('line')
      .attr('x1', 0).attr('x2', 0)
      .attr('y1', 0).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .2)

      milestone
      .append('text')
      .attr('transform',`translate(-2, 55) rotate(-90)`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'end')
      .text(d.MS)

      milestone
      .append('text')
      .attr('transform',`translate(-2, 2) rotate(-90)`)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'end')
      .text(d.MSDATE)
    },

  }
}