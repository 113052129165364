import '@babel/polyfill'
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'

/* With the File-Browser Features */
import Vue from '../lib/filebrowser/src/utils/vue'
// import Vue from 'vue'

import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'
import App from './App'
import Axios from 'axios'
import router from './router'
import store from './store'
import i18n from './i18n'
import { ModalDraggableMixin } from './mixins/modal.draggable.mixin'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.$cookies.config('7d')
import '@mdi/font/css/materialdesignicons.css'

Axios.defaults.withCredentials = true
Vue.config.productionTip = false
// Sync store with router
sync(store, router)

new Vue({
  router,
  store,
  i18n,
  mixins: [
    ModalDraggableMixin
  ],
  // created() {
  //   // URL에서 account 정보를 추출하여 localStorage에 저장
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const accountDataString = urlParams.get('account');

  //   if (accountDataString) {
  //     const accountData = JSON.parse(decodeURIComponent(accountDataString));
      
  //     // ACCOUNT 정보를 localStorage에 저장
  //     localStorage.setItem('ACCOUNT', JSON.stringify(accountData));
      
  //     // 저장이 완료되면 서비스 홈으로 리다이렉트 (URL에서 파라미터 제거)
  //     window.history.replaceState({}, document.title, "/");
  //     console.log("ACCOUNT 정보가 localStorage에 저장되었습니다.");
  //   } else {
  //     console.log("ACCOUNT 정보가 없습니다. 다시 인증이 필요합니다.");
  //   }
  // },
  render: h => h(App)
}).$mount('#app')
