import * as d3 from 'd3'

export default {
  methods: {

    draw_Elements() {

      this.SCurve = this.svg
      .append('g')
      .attr('transform', `translate(${this.Chart.x +.5}, ${this.Chart.y + .5})`)
      .style('font-family', 'roboto')

      this.draw_ChartCanvas()
      this.draw_Title()
      this.draw_Grid()
    },

    
    draw_ChartCanvas() {
      this.SCurve
      .append('line')
      .attr('x1', 0).attr('x2', 0).attr('y1', 0).attr('y2', this.Chart.height)
      .attr('stroke', this.Chart.sColor ).attr('stroke-width', this.Chart.sWidth)
      .attr('opacity', this.Chart.opacity)

      this.SCurve
      .append('line')
      .attr('x1', 0).attr('x2', this.Chart.width).attr('y1', this.Chart.height).attr('y2', this.Chart.height)
      .attr('stroke', this.Chart.sColor ).attr('stroke-width', this.Chart.sWidth)
      .attr('opacity', this.Chart.opacity)

      this.SCurve
      .append('line')
      .attr('x1', this.Chart.width).attr('x2', this.Chart.width).attr('y1', 0).attr('y2', this.Chart.height)
      .attr('stroke', this.Chart.sColor ).attr('stroke-width', this.Chart.sWidth)
      .attr('opacity', this.Chart.opacity)
    },


    draw_Title() {
      let x_ = 0
      let align_ = 'start'
      if (this.Title.align == 'center') {
        x_ = this.Chart.width/2
        align_ = 'middle'
      } else if (this.Title.align == 'right') {
        x_ = this.Chart.width
        align_ = 'end'
      }

      if (this.Title.main.visible == 'Y') {
        this.SCurve
        .append('text')
        .attr('x', x_)
        .attr('y', -this.Title.main.margin)
        .style('font-size', this.Title.main.tSize).style('fill', this.Title.main.tColor)
        .attr('text-anchor', align_)
        .attr('alignment-baseline', 'middle')
        .text(this.Title.main.Text)
      }

      if (this.Title.sub.visible == 'Y') {
        this.SCurve
        .append('text')
        .attr('x', x_)
        .attr('y', -this.Title.sub.margin)
        .style('font-size', this.Title.sub.tSize).style('fill', this.Title.sub.tColor)
        .attr('text-anchor', align_)
        .attr('alignment-baseline', 'middle')
        .text(this.Title.sub.Text)
      }
    },


    draw_Grid() {
      for(let i=0; i<6; i++) {
        this.SCurve
        .append('line')
        .attr('x1', 0).attr('x2', this.Chart.width)
        .attr('y1', this.Grid.height * i).attr('y2', this.Grid.height * i)
        .attr('stroke', this.Grid.sColor ).attr('stroke-width', this.Grid.sWidth)
        .attr('opacity', this.Grid.opacity)

        this.SCurve
        .append('text')
        .attr('x', -this.Primary.value.margin)
        .attr('y', this.Grid.height * i)
        .style('font-size', this.Primary.value.tSize).style('fill', this.Primary.value.tColor)
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(`${100-(i*20)}%`)
      }

      this.SCurve
      .append('text')
      .attr('transform', `translate(${-this.Primary.title.margin}, ${this.Chart.height/2}) rotate(-90)`)
      .style('font-size', this.Primary.title.tSize).style('fill', this.Primary.title.tColor)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(this.Primary.title.name)
    },

  }
}
           