import * as d3 from 'd3'
import __M from 'moment'
import __C from '../../../../includes/primitives/_constant_'
import AxisY from '../../../../includes/classes/axisy'

export default {
  methods: {
    drawAxisPrimary() {
      if (this.Axis.AxisPrLevelDisplay != 'Y') return

      let axisy = new AxisY({
        i: this.localId,                
        s: this.chartArea,                // Selection where the AxisY-Wrapper(SVG Group) to be placed.
        x: 0,                             // X coordinate of the Axis wrapper(SVG Group)
        y: 0,                             // Y coordinate of the Axis wrapper(SVG Group)
        w: this.Canvas.CanvasChartWidth,  // Width of the Grid Line
        h: this.Canvas.CanvasChartHeight, // Height of the Axis Y
        b: {                              // Bound Min & Max
          min: this.scaleYInfo.primary.minBoundValue || 0,
          max: this.scaleYInfo.primary.maxBoundValue || 0
        },
        c: this.ChartType == __C.CHART.TYPE_CODE_COMPARE ? true : false, // isComparison = true | false
        o: {                              // Object Values. If not assigned, default object values will 
          levelPosition: 'left',
          levelSpace: this.Axis.AxisPrLevelSpace,
          levelFont: this.Axis.AxisPrLevelFont,
          levelStyle: this.Axis.AxisPrLevelStyle,
          levelSize: this.Axis.AxisPrLevelSize,
          levelWeight: this.Axis.AxisPrLevelWeight,
          levelColor: this.Axis.AxisPrLevelColor,
          levelUnitSize: this.Axis.AxisPrLevelUnitSize,
          levelUnitFormat: this.Axis.AxisPrLevelUnitFormat,
          lineWeight: this.Axis.AxisYLineWeight,
          lineColor: this.Axis.AxisYLineColor,
          gridNum: this.Axis.AxisGridNum,
          gridWeight: this.Axis.AxisGridWeight,
          gridColor: this.Axis.AxisGridColor,
          gridOpacity: this.Axis.AxisGridOpacity,
          zeroLineWeight: this.Axis.AxisZeroLineDisplay == 'Y' ? this.Axis.AxisZeroLineWeight : 0,
          zeroLineColor: this.Axis.AxisZeroLineDisplay == 'Y' ? this.Axis.AxisZeroLineColor : '',
          zeroLineOpacity: this.Axis.AxisZeroLineOpacity,
        },
      }, {
        tickValues: this.scaleYInfo.primary.tickValues,
        scale: this.scaleYInfo.primary.scale,
      })

      axisy.draw()
      if (this.Axis.AxisGridDisplay == 'Y') axisy.drawGrid()
    },
    drawAxisSecondary() {
      if (this.Axis.AxisSeLevelDisplay != 'Y') return

      let axisy = new AxisY({
        i: this.localId,                
        s: this.chartArea,                // Selection where the AxisY-Wrapper(SVG Group) to be placed.
        x: this.Canvas.CanvasChartWidth,                             // X coordinate of the Axis wrapper(SVG Group)
        y: 0,  // Y coordinate of the Axis wrapper(SVG Group)
        h: this.Canvas.CanvasChartHeight, // Height of the Axis Y
        b: {                              // Bound Min & Max
          min: this.scaleYInfo.secondary.minBoundValue || 0,
          max: this.scaleYInfo.secondary.maxBoundValue || 0
        },
        c: this.ChartType == __C.CHART.TYPE_CODE_COMPARE ? true : false, // isComparison = true | false
        o: {                              // Object Values. If not assigned, default object values will 
          levelPosition: 'right',
          levelSpace: this.Axis.AxisSeLevelSpace,
          levelFont: this.Axis.AxisSeLevelFont,
          levelStyle: this.Axis.AxisSeLevelStyle,
          levelSize: this.Axis.AxisSeLevelSize,
          levelWeight: this.Axis.AxisSeLevelWeight,
          levelColor: this.Axis.AxisSeLevelColor,
          levelUnitSize: this.Axis.AxisSeLevelUnitSize,
          levelUnitFormat: this.Axis.AxisSeLevelUnitFormat,
          lineWeight: this.Axis.AxisYLineWeight,
          lineColor: this.Axis.AxisYLineColor,
        },
      }, {
        tickValues: this.scaleYInfo.secondary.tickValues,
        scale: this.scaleYInfo.secondary.scale,
      })

      axisy.draw()
    },
    // drawTitlesAxis() {
    //   this.putTitleAxisPrimary()
    //   this.putTitleAxisSecondary()
    // },
    // putTitleAxisPrimary() {
    //   if (this.Title.TitlePrDisplay != 'Y') return

    //   this.svg
    //     .append('g')
    //     .attr('id', 'TitlePrX$TitlePrY')
    //     .attr('transform', `translate(${this.Title.TitlePrX},${this.Title.TitlePrY})`)
    //     .call(this.el2Drag)
    //     .call(this.setCursorMovable)
    //     .append('text')
    //     .attr('transform', 'rotate(-90)')
    //     .attr('class', `text_axis_primary___${this.localId} ${this.Title.TitlePrStyle}`)
    //     .text(this.Title.TitlePrText)
    //     .style('font-size', this.Title.TitlePrSize)
    //     .style('fill', this.Title.TitlePrColor)

    //   this.svg.select(`.text_axis_primary___${this.localId}`)
    //     .attr('x', -Math.round(this.getNodeElValue(`.text_axis_primary___${this.localId}`, 'height')))
    // },
    // putTitleAxisSecondary() {
    //   if (this.Title.TitleSeDisplay != 'Y') return

    //   this.svg
    //     .append('g')
    //     .attr('id', 'TitleSeX$TitleSeY')
    //     .attr('transform', `translate(${this.Title.TitleSeX},${this.Title.TitleSeY})`)
    //     .call(this.el2Drag)
    //     .call(this.setCursorMovable)
    //     .append('text')
    //     .attr('class', `title_text_secondary___${this.localId}`)
    //     .attr('transform', 'rotate(-90)')
    //     .style('font-size', this.Title.TitleSeSize)
    //     .style('fill', this.Title.TitleSeColor)
    //     .text(this.Title.TitleSeText)

    //   this.svg.select(`.title_text_secondary___${this.localId}`)
    //     .attr('x', -Math.round(this.getNodeElValue(`.title_text_secondary___${this.localId}`, 'height')))
    // },
    drawBars() {
      if (!this.scaleYInfo.secondary.valid) return

      if (this.ChartType == __C.CHART.TYPE_CODE_S_CURVE) this.drawBarsGrouped()
      else if (this.ChartType == __C.CHART.TYPE_CODE_MONTHLY) this.drawBarsGrouped()
      else if (this.ChartType == __C.CHART.TYPE_CODE_STACK) this.drawBarsStacked()
      else if (this.ChartType == __C.CHART.TYPE_CODE_COMPARE) this.drawBarsCompared()
    },
    drawBarsGrouped() {
      let keys_ = Object.keys(this.scaleYInfo.secondary.data[0])

      let bar_ = this.chartArea
      .append('g')
      .selectAll('g')
      .data(this.tickValues)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${Math.round(this.scaleTimelineInfo.scale(d) - this.scaleTimelineInfo.base.bandwidth / 2)},0)`)
      .selectAll('rect')
      .data((d, i) => keys_.map(k => ({
        name: k,
        tick: d,
        value: this.scaleYInfo.secondary.data[i][k] ? this.scaleYInfo.secondary.data[i][k] : 0
      })))
      .enter()
      .append('rect')
      .attr('x', (d) => this.scaleBarGroup.scaleband.group(d.name))
      .attr('y', this.Axis.AxisSeBoundsCenter != 'Y' ? this.Canvas.CanvasChartHeight : this.scaleYInfo.secondary.scale(0))

      bar_
      .attr('fill', (d, i) => this.getColorForBar(i, d.tick))
      // .attr('width', this.Bar.BarAutoSize == 'Y' ? [Calculated bar width] : this.Bar.BarThickness)
      .attr('width', this.Bar.BarThickness)
      .attr('height', 0)
      .transition()
      .duration(500)
      .attr('y', (d) => this.scaleYInfo.secondary.scale(Math.max(0, d.value)))
      .attr('height', d => Math.abs(this.scaleYInfo.secondary.scale(0) - this.scaleYInfo.secondary.scale(d.value)))

      if(this.Bar.BarBorder.display != 'Y') return

      bar_
      .style('stroke', (d, i) => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.color == 'undefined' || typeof this.Bar.BarBorder.color[i] == 'undefined' ? 0 : this.Bar.BarBorder.color[i])
      .style('stroke-width', (d, i) => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.weight == 'undefined' || typeof this.Bar.BarBorder.weight[i] == 'undefined' ? 0 : this.Bar.BarBorder.weight[i])
      .style('stroke-opacity', (d, i) => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.opacity == 'undefined' || typeof this.Bar.BarBorder.opacity[i] == 'undefined' ? 0 : this.Bar.BarBorder.opacity[i])

    },
    drawBarsStacked() {
      let keys_ = Object.keys(this.scaleYInfo.secondary.data[0])
      let data_ = this.scaleYInfo.secondary.data.map(d => {
        keys_.forEach(k => { d[k] = d[k] ? d[k] : 0 })
        return d
      })

      let bar_ = this.chartArea
      .append('g')
      .selectAll('g')
      .data(d3.stack().keys(keys_)(data_))
      .enter()
      .append('g')
      .attr('fill', (_, i) => this.scaleColorInfo.bar.colors[i])
      .selectAll('rect')
      .data((d, i) => d.map(d_ => ({index: i, value: d_})))
      .enter()
      .append('rect')
      .attr('x', (_, i) => this.scaleTimelineInfo.scale(this.tickValues[i]) - this.Bar.BarThickness / 2)
      .attr('y', (d) => d.value[1] >= 0 ? this.scaleYInfo.secondary.scale(d.value[1]) : this.scaleYInfo.secondary.scale(d.value[0]))
      .attr('width', this.Bar.BarThickness)
      .attr('height', (d) => Math.abs(this.scaleYInfo.secondary.scale(d.value[0]) - this.scaleYInfo.secondary.scale(d.value[1])))

      if(this.Bar.BarBorder.display != 'Y') return

      bar_
      .style('stroke', (d) => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.color == 'undefined' || typeof this.Bar.BarBorder.color[d.index] == 'undefined' ? 0 : this.Bar.BarBorder.color[d.index])
      .style('stroke-width', (d) => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.weight == 'undefined' || typeof this.Bar.BarBorder.weight[d.index] == 'undefined' ? 0 : this.Bar.BarBorder.weight[d.index])
      .style('stroke-opacity', (d) => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.opacity == 'undefined' || typeof this.Bar.BarBorder.opacity[d.index] == 'undefined' ? 0 : this.Bar.BarBorder.opacity[d.index])
    },
    drawBarsCompared() {
      let keys_ = Object.keys(this.scaleYInfo.secondary.data[0]).slice(0, 2)
      let data_ = keys_.map((k, i) => this.scaleYInfo.secondary.data.map(d => (d[k] ? d[k] * (i > 0 ? -1 : 1) : 0)))

      let bar_ = this.chartArea
      .append('g')
      .selectAll('g')
      .data(data_)
      .enter()
      .append('g')
      .attr('fill', (_, i) => this.scaleColorInfo.bar.colors[i])
      .selectAll('rect')
      .data((d, i) => d.map(d_ => ({index: i, value: d_})))
      .enter()
      .append('rect')
      .attr('x', (_, i) => this.scaleTimelineInfo.scale(this.tickValues[i]) - this.Bar.BarThickness / 2)
      .attr('y', this.scaleYInfo.secondary.scale(0))
      .attr('width', this.Bar.BarThickness)
      .attr('height', 0)
      .transition()
      .duration(500)
      .attr('y', d => this.scaleYInfo.secondary.scale(Math.max(0, d.value)))
      .attr('height', d => Math.abs(this.scaleYInfo.secondary.scale(0) - this.scaleYInfo.secondary.scale(d.value)))

      if(this.Bar.BarBorder.display != 'Y') return

      bar_
      .style('stroke', d => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.color == 'undefined' || typeof this.Bar.BarBorder.color[d.index] == 'undefined' ? 0 : this.Bar.BarBorder.color[d.index])
      .style('stroke-width', d => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.weight == 'undefined' || typeof this.Bar.BarBorder.weight[d.index] == 'undefined' ? 0 : this.Bar.BarBorder.weight[d.index])
      .style('stroke-opacity', d => typeof this.Bar.BarBorder == 'undefined' || typeof this.Bar.BarBorder.opacity == 'undefined' || typeof this.Bar.BarBorder.opacity[d.index] == 'undefined' ? 0 : this.Bar.BarBorder.opacity[d.index])
    },
    putBarValues() {
      if (!this.scaleYInfo.secondary.valid) return
      if (this.Bar.BarValueDisplay != 'Y') return

      if (this.ChartType == __C.CHART.TYPE_CODE_S_CURVE) this.putBarValuesGrouped()
      else if (this.ChartType == __C.CHART.TYPE_CODE_MONTHLY) this.putBarValuesGrouped()
      else if (this.ChartType == __C.CHART.TYPE_CODE_STACK) this.putBarValuesStacked()
      else if (this.ChartType == __C.CHART.TYPE_CODE_COMPARE) this.putBarValuesCompared()
    },
    putBarValuesGrouped() {
      let keys_ = Object.keys(this.scaleYInfo.secondary.data[0])


      this.chartArea
      .append('g')
      .selectAll('g')
      .data(this.tickValues)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${Math.round(this.scaleTimelineInfo.scale(d) - this.scaleTimelineInfo.base.bandwidth / 2)},-5)`)
      .selectAll('text')
      .data((d, i) => keys_.map(k => ({
        name: k,
        tick: d,
        value: this.scaleYInfo.secondary.data[i][k] ? this.scaleYInfo.secondary.data[i][k] : 0
      })))
      .enter()
      .append('text')
      .attr('x', (d) => this.scaleBarGroup.scaleband.group(d.name) + this.Bar.BarThickness / 2)
      .attr('y', (d) => this.getBarValueYPos(d.value))
      .attr('fill', (_, i) => this.Bar.BarValueAutoColor == 'Y' ? this.scaleColorInfo.bar.colors[i] : this.Bar.BarValueColor)
      .style('font-family', this.Bar.BarValueFont)
      .attr('class', this.Bar.BarValueStyle)
      .attr('font-size', this.Bar.BarValueSize)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(d => d.value ? d3.format(`,.${this.Bar.BarValueRound?this.Bar.BarValueRound:0}f`)(d.value) : '')
      .style('opacity', 0)
      .transition()
      .delay(500)
      .style('opacity', 1)
    },
    putBarValuesStacked() {
      let keys_ = Object.keys(this.scaleYInfo.secondary.data[0])
      let data_ = this.scaleYInfo.secondary.data.map(d => {
        keys_.forEach(k => { d[k] = d[k] ? d[k] : 0 })
        return d
      })

      this.chartArea
      .append('g')
      .selectAll('g')
      .data(d3.stack().keys(keys_)(data_))
      .enter()
      .append('g')
      .selectAll('text')
      .data((d, i) => d.map(d_ => ({index: i, value: d_})))
      .enter()
      .append('text')
      // .attr('x', (_, i) => this.scaleTimelineInfo.scale(this.tickValues[i]) - this.Bar.BarThickness / 2)
      .attr('x', (_, i) => this.scaleTimelineInfo.scale(this.tickValues[i]))
      .attr('y', (d) => this.getBarValueYPos(d.value[1], d.value[0]))
      .attr('fill', (d) => this.Bar.BarValueAutoColor == 'Y' ? this.scaleColorInfo.bar.colors[d.index] : this.Bar.BarValueColor)
      .style('font-family', this.Bar.BarValueFont)
      .attr('class', this.Bar.BarValueStyle)
      .attr('font-size', this.Bar.BarValueSize)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      // .text(d => d.value[1] ? d3.format(`,.${this.Bar.BarValueRound ? this.Bar.BarValueRound : 0}f`)(d.value[1]) : '')
      .text(d => d.value[1]- d.value[0] )
      .style('opacity', 0)
      .transition()
      .delay(500)
      .style('opacity', 1)
    },
    putBarValuesCompared() {
      let keys_ = Object.keys(this.scaleYInfo.secondary.data[0]).slice(0, 2)
      let data_ = keys_.map((k, i) => this.scaleYInfo.secondary.data.map(d => (d[k] ? d[k] * (i > 0 ? -1 : 1) : 0)))

      this.chartArea
      .append('g')
      .selectAll('g')
      .data(data_)
      .enter()
      .append('g')
      .selectAll('text')
      .data((d, i) => d.map(d_ => ({ index: i, value: d_ })))
      .enter()
      .append('text')
      .attr('x', (_, i) => this.scaleTimelineInfo.scale(this.tickValues[i]) - this.Bar.BarThickness / 2)
      .attr('y', d => d ? this.scaleYInfo.secondary.scale(this.Bar.BarValuePosition == 'top'? d.value : 0) - (this.Bar.BarValueSpace * (d.value < 0 ? -1.2 : 1)) : 0)
      .attr('fill', d => this.Bar.BarValueAutoColor == 'Y' ? this.scaleColorInfo.bar.colors[d.index] : this.Bar.BarValueColor)
      .style('font-family', this.Bar.BarValueFont)
      .attr('class', this.Bar.BarValueStyle)
      .attr('font-size', this.Bar.BarValueSize)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(d => d.value ? d3.format(`,.${this.Bar.BarValueRound?this.Bar.BarValueRound:0}f`)(Math.abs(d.value)) : '')
      .style('opacity', 0)
      .transition()
      .delay(500)
      .style('opacity', 1)
    },
    drawLines() {
      if(!this.scaleYInfo.primary.valid) return
      if(this.Line.LineDisplay != 'Y') return

      let lineArea = this.chartArea.append('g')
      let lineFunc = d3.line()
        .x(d => this.scaleTimelineInfo.scale(d[this.axisXName.base]))
        .y(d => this.scaleYInfo.primary.scale(d.value))
      // .curve(d3.curveNatural)

      this.scaleYInfo.primary.data.forEach((data___, i) => {
        // let data___ = data_.filter(d_ => d_.value)

        // draw line
        lineArea
          .append('path')
          .attr('d', lineFunc(data___))
          .style('stroke', this.scaleColorInfo.line.colors[i])
          .style('stroke-width', this.Line.LineWeight[i])
          .style('fill', 'none')
          .style('stroke-dasharray', !this.Line.LineDash[i] ? 0 : (this.Line.LineDash[i] * 1.5))

        if (!this.Line.LineMarkerType || this.Line.LineMarkerType == 'none') return
        if (this.Line.LineMarkerDisplay != 'Y') return 

        // draw marker
        let lineMarkerEntered = lineArea
          .append('g')
          .selectAll(this.Line.LineMarkerType == 'rect' ? 'square' : 'circle')
          .data(data___)
          .enter()

        if (this.Line.LineMarkerType == 'circle') {
          lineMarkerEntered
            .append('circle')
            .attr('fill', 'white')
            .attr('stroke', this.scaleColorInfo.line.colors[i])
            .attr('stroke-width', this.Line.LineMarkerStroke)
            .attr('cx', d => this.scaleTimelineInfo.scale(d[this.axisXName.base]))
            .attr('cy', d => this.scaleYInfo.primary.scale(d.value))
            .attr('r', this.Line.LineMarkerSize / 2)

        } else {
          lineMarkerEntered
            .append('rect')
            .attr('x', d => this.scaleTimelineInfo.scale(d[this.axisXName.base]) - this.Line.LineMarkerSize / 2)
            .attr('y', d => this.scaleYInfo.primary.scale(d.value) - this.Line.LineMarkerSize / 2)
            .attr('width', this.Line.LineMarkerSize)
            .attr('height', this.Line.LineMarkerSize)
            .attr('stroke', this.scaleColorInfo.line.colors[i])
            .attr('stroke-width', this.Line.LineMarkerStroke)
            .attr('fill', 'white')
        }
      })
    },
    putLineValues() {
      if (!this.scaleYInfo.primary.valid) return
      if (this.Line.LineValueDisplay != 'Y') return

      this.chartArea
        .append('g')
        .selectAll('g')
        .data(this.scaleYInfo.primary.colNames.slice(0, this.scaleYInfo.primary.data.length))
        .enter()
        .append('g')
        .selectAll('text')
        .data((_, i) => this.scaleYInfo.primary.data[i])
        .enter()
        .append('text')
        .attr('class', this.Line.LineValueStyle)
        .attr('x', d => this.scaleTimelineInfo.scale(d[this.axisXName.base]))
        .attr('y', d => this.scaleYInfo.primary.scale(d.value) - this.Line.LineValueSpace)
        .attr('fill', this.Line.LineValueColor)
        .style('font-family', this.Line.LineValueFont)
        .style('font-size', this.Line.LineValueSize)
        .style('text-anchor', 'middle')
        .style('alignment-baseline', 'middle')
        .text(d => d3.format(',')(d.value) + this.Line.LineValueFormat)
        .style('opacity', 0)
        .transition()
        .delay(500)
        .style('opacity', 1)
    },
    drawLegend() {
      if(this.Legends.LegendDisplay != 'Y') return

      let legendItems = []
      let axisTitleArray = this.ChartType != 'Rundown' ? ['primary', 'secondary'] : ['primary']
      
      // let legendItems = [];
      // ['secondary', 'primary'].forEach(k_ => {
        
      axisTitleArray.forEach(k_ => {
        if(this.scaleYInfo[k_].valid) this.scaleYInfo[k_].colNames.forEach((name, i) => {
          legendItems.push({
            text: name,
            markerType: k_ == 'secondary' ? 'rect' : 'path',
            markerSubType: k_ == 'secondary' ? '' : (!this.Line.LineDash[i] ? 0 : (this.Line.LineDash[i] * 1.5)),
            markerShape: k_ == 'secondary' ? '' : (this.Line.LineMarkerType == 'square' ? 'rect' : 'circle'),
            markerSize: this.Legends.LegendBulletSize,
            markerColor: k_ == 'secondary' ? this.scaleColorInfo.bar.colors[i] : this.scaleColorInfo.line.colors[i],
          })
        })
      })

      let nextXPos_ = 0
      let nextYPos_ = 0
      let legendArea = this.svg
      .append('g')
      .attr('id', 'LegendX$LegendY')
      .attr('transform', `translate(${this.Legends.LegendX}, ${this.Legends.LegendY})`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)

      legendItems.forEach((item, i) => {
        let setToZeroPos_ = (v) => -Math.round(v / 2)
        let markerArea = legendArea
          .append('g')
          .attr('class', `legend_marker_group_${i}___${this.localId}`)
          .attr('transform', `translate(${nextXPos_},${nextYPos_})`)

        if(this.Legends.LegendBulletDisplay == 'Y') {
          if (item.markerType == 'rect') {
            markerArea
              .append('rect')
              .attr('width', this.Legends.LegendBulletSize)
              .attr('height', this.Legends.LegendBulletSize)
              .attr('x', 0)
              .attr('y', setToZeroPos_(item.markerSize))
              .attr('fill', item.markerColor)
              
  
          } else if (item.markerType == 'path') {
            markerArea 
              .append('path')
              .attr('d', `M 0,0 H ${this.Legends.LegendBulletSize * 2}`)
              .attr('transform', `translate(${-(this.Legends.LegendBulletSize/2)},0)`)
              .style('stroke', item.markerColor)
              .style('stroke-width', 1)
              .style('stroke-dasharray', item.markerSubType)
        
            if (this.Line.LineMarkerType == 'circle') {
              markerArea
                .append('circle')
                .attr('fill', 'white')
                .attr('stroke', item.markerColor)
                .attr('stroke-width', 1)
                .attr('cx', item.markerSize / 2)
                .attr('cy', 0)
                .attr('r', (this.Legends.LegendBulletSize * .7) / 2)
  
            } else if (this.Line.LineMarkerType == 'square') {
              markerArea
                .append('rect')
                .attr('x', item.markerSize / 2)
                .attr('y', setToZeroPos_(item.markerSize))
                .attr('transform', `translate(${this.Line.LineMarkerType == 'square' ? -this.Legends.LegendBulletSize/2: 0},0)`)
                .attr('width', this.Legends.LegendBulletSize * .7)
                .attr('height', this.Legends.LegendBulletSize * .7)
                .attr('fill', item.markerColor)
            }
          }
        }
       
        let textY_ = -(this.Legends.LegendValueSize * 0.7)
        markerArea
          .append('text')
          .attr('class', this.Legends.LegendSeriesStyle)
          .attr('transform', `translate(${ 
            item.markerType == 'rect' ? 
            Number(this.Legends.LegendBulletSpace) + (this.Legends.LegendBulletDisplay == 'Y' ? this.Legends.LegendBulletSize : 0) :
            Number(this.Legends.LegendBulletSpace) + (this.Legends.LegendBulletDisplay == 'Y' ? this.Legends.LegendBulletSize * 1.5 : 0)
          },${
            setToZeroPos_(textY_)
          })`)
          // .filter(() => this.ChartType == 'Rundown' ? item.markerShape == 'circle' : item)
          .text(item.text)
          .style('font-family', this.Legends.LegendSeriesFont)
          .style('font-size', this.Legends.LegendSeriesSize)
          .style('text-anchor', 'start')
          .style('fill', this.Legends.LegendSeriesColor)

        if (this.Legends.LegendDirection == 'Horizontal') {
          nextXPos_ += Math.round(Number(this.getNodeElValue(`.legend_marker_group_${i}___${this.localId}`, 'width')) + this.Legends.LegendSeriesSpace)
        } else {
          nextYPos_ += Math.round(Number(this.getNodeElValue(`.legend_marker_group_${i}___${this.localId}`, 'height')) + this.Legends.LegendLineSpace)
        }
      })
    },
    drawTable() {
      if(this.TableSummary.display != 'Y') return
      if(this.TableSummary.headers.length === 0) return

      let tableWrapper_ = this.chartArea
      .append('g')
      .attr('id', `hist_summary_table___${this.localId}`)
      .attr('transform', `translate(${Number(this.TableSummary.x)},${Number(this.TableSummary.y)+.5})`)

      this.drawTableForm(tableWrapper_)

    },
    drawTableForm(parent) {
      // ### Table Form --------------------------------------
      let form_ = parent.append('g').attr('class', '__hist_summary_table_form___')
      // header lines
      // form_.append('path').attr('d', `M0,0H${this.table.width}`).style('stroke', this.table.sColor).style('stroke-width', this.table.sWidth)
      form_.append('path')
      .attr('d', `M${-this.table.labelWidth},${this.table.hHeight}H${this.table.width}`)
      .style('stroke', this.table.sColor)
      .style('stroke-width', this.table.sWidth)

      // row (horizontal) lines & effect
      this.TableSummary.headers.forEach((_, i) => {
        // row effect
        form_
        .append('rect')
        .attr('transform', `translate(${-this.table.labelWidth},${this.table.hHeight + (this.table.lineHeight * i)})`)
        .attr('fill', '#fff')
        .attr('width', this.table.width + this.table.labelWidth)
        .attr('height', this.table.lineHeight)
        .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('fill', '#F5F5F5') })
        .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('fill', '#fff') })
        
        // line
        form_
        .append('path')
        .attr('d', `M${-this.table.labelWidth},${this.table.hHeight + this.table.lineHeight + (this.table.lineHeight * i)}H${this.table.width}`)
        .style('stroke', i == this.TableSummary.headers.length-1 ? this.table.sColor : '#CCC')
        .style('stroke-width', this.table.sWidth)
        .style('opacity', i == this.TableSummary.headers.length-1 ? 1 : .5)
      })

      // column (vertical) lines
      // label
      let heightY_ = this.table.hHeight + this.table.lineHeight * this.TableSummary.headers.length
      form_.append('path').attr('d', `M${-this.table.labelWidth},${this.table.hHeight}V${heightY_}`).style('stroke', this.table.sColor).style('stroke-width', this.table.sWidth)
      form_.append('path').attr('d', `M0,${this.table.hHeight}V${heightY_}`).style('stroke', this.table.sColor).style('stroke-width', this.table.sWidth)
      // week
      this.tickValues.forEach((d, i) => {
        form_
        .append('path')
        .attr('d', `M${this.scaleTimelineInfo.scale(d) +  this.table.cellWidth/2},${this.table.hHeight}V${heightY_}`)
        .style('stroke', i == this.tickValues.length-1 ? '#fff' : '#CCC')
        .style('stroke-width', this.table.sWidth)
      })
      // month
      this.scaleTimelineInfo.month.tickValues.forEach((d, i) => {
        form_
        .append('path')
        .attr('d', `M${this.scaleTimelineInfo.scale(d.end) +  this.table.cellWidth/2},${this.table.hHeight}V${heightY_}`)
        .style('stroke', this.table.sColor)
        .style('stroke-width', this.table.sWidth)
      })


      // ### Header Text & Values ----------------------------
      let text_ = parent.append('g').attr('class', '__hist_summary_table__text_values___')

      // header timeline week text
      // text_
      // .selectAll('text')
      // .data(this.tickValues)
      // .enter()
      // .append('text')
      // .attr('transform', d => `translate(${this.scaleTimelineInfo.scale(d)}, ${this.table.hHeight/2})`)
      // .style('font-family', 'roboto')
      // .style('font-size', 9)
      // .style('fill', '#000')
      // .attr('text-anchor', 'middle')
      // .attr('alignment-baseline', 'middle')
      // .text(d => __M(d).format('DD MMM'))

      // row data label
      let rowLabels_ = text_
      .append('g')
      .attr('transform', `translate(${-this.table.labelWidth+3}, ${this.table.hHeight + 9})`)
      .selectAll('text')
      .data(this.TableSummary.headers)
      .enter()

      rowLabels_
      .append('text')
      .attr('transform', (_, i) => `translate(0, ${this.table.lineHeight * i})`)
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#757575')
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(d => d.text)

      let items__ = this.TableSummary.shareData == 'Y' ? this.DataItems : this.TableSummary.items

      items__.forEach(d___ => {

        text_
        .append('g')
        .attr('transform', `translate(${this.scaleTimelineInfo.scale(__M(d___[this.timelineKeyName]))}, ${this.table.hHeight})`)
        .selectAll('text')
        .data(this.TableSummary.headers)
        .enter()
        .append('text')
        .attr('transform', (_, j) => `translate(${this.table.cellWidth/2 - this.table.tRMargin}, ${this.table.lineHeight*j + this.table.fontSize})`)
        .style('font-family', 'roboto')
        .style('font-size', this.table.fontSize)
        .style('fill', this.table.tColor)
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .text(d => d___[d.value])
        .on('mouseover', (d, j, a) => {
          if(d.link == 'N') return

          let node = 2
          d3.select(a[j]).style('cursor', 'pointer')
          d3.select(a[j]).transition().duration(100)
          .attr('transform', `translate(${this.table.cellWidth/2 - this.table.tRMargin + node}, ${this.table.lineHeight*j + this.table.fontSize}) scale(1.3)`).style('fill', '#EC407A')
        })
        .on('mouseout', (d, j, a) => {
          if(d.link == 'N') return

          d3.select(a[j]).transition().duration(100)
          .attr('transform', `translate(${this.table.cellWidth/2 - this.table.tRMargin}, ${this.table.lineHeight*j + this.table.fontSize}) scale(1)`).style('fill', this.table.tColor)
        })
        // .on('click', () => { 
        //   if(c.link == 'N') return

        //   //
        // })
      })
    }
  }
}
