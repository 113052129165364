import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
import Data from '../../../../includes/primitives/Color_Data'

export default {
  methods: {
    drawChartCylinder() {
      let chartWrapper = this.chartArea
        .append('g')
        .attr('class', '_chart_wrapper__')
        .selectAll('g')
        .data(this.DataItems)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${this.scaleXInfo.scale(d.title)},0)`)

      // ---------------------------------------------------------------------------------------------------- Reflected Effect
      if (this.Bar.BarReflection == 'Y') {
        chartWrapper // Reflected Box
          .append('rect')
          .attr('transform', `translate(0,${this.Canvas.CanvasChartHeight})`)
          .attr('width', this.setThickness)
          .attr('height', this.setThickness)
          .attr('opacity', '0.5')
          .attr('fill', (_, i) => `url(#${this.localId}__${this.Bar.BarColorType}${i})`)
      }

      console.log(this.Bar.BarColorType)

      chartWrapper // Shadow
        .append('ellipse')
        .attr('cx', this.setThickness / 4)
        .attr('cy', this.Canvas.CanvasChartHeight)
        .attr('rx', (this.setThickness * 1.5) / 2)
        .attr('ry', (this.setThickness * 1.5) / 3 / 2)
        .attr('fill', `url(#${this.localId}__Color_Shadow)`)

      chartWrapper // Bright Line (Bottom)
        .append('path')
        .attr(
          'd',
          `
        M 0 ${this.Canvas.CanvasChartHeight} 
        C 0 ${this.Canvas.CanvasChartHeight + this.setThickness / 3 - this.setThickness / 3 / 3}, 
          ${this.setThickness} ${this.Canvas.CanvasChartHeight + this.setThickness / 3 - this.setThickness / 3 / 3}, 
          ${this.setThickness} ${this.Canvas.CanvasChartHeight}
      `
        )
        .attr('fill', 'transparent')
        .attr('stroke', 'white')
        .attr('stroke-width', 1)
        .attr('opacity', '.7')

      chartWrapper // Transparent Gradation
        .append('rect')
        .attr('transform', `translate(0,${this.Canvas.CanvasChartHeight})`)
        .attr('width', this.setThickness * 1.5 + 2)
        .attr('height', this.setThickness + 2)
        .attr('fill', `url(#${this.localId}__Color_Reflection)`)
      
      // ------------------------------------------------------------------------- Cylinder Bar
      
      chartWrapper
        .append('path') // Cyinder Box
        .attr('d', d => {
          let h_ = this.scaleYInfo.scale(0) - this.scaleYInfo.scale(d.value)
          return `
            M ${0} ${this.Canvas.CanvasChartHeight}
            V ${this.Canvas.CanvasChartHeight} 
            C 0 ${((this.setThickness/3/2)*1.25) + this.Canvas.CanvasChartHeight} ${this.setThickness} ${((this.setThickness/3/2)*1.25) + this.Canvas.CanvasChartHeight} ${this.setThickness} ${this.Canvas.CanvasChartHeight} 
            V ${this.Canvas.CanvasChartHeight} 
          `
        })
        .attr('fill', (_, i) => `url(#${this.localId}__${this.Bar.BarColorType}${i})`)
        .transition()
        .duration(500)
        .attr('d', d => {
          let h_ = this.scaleYInfo.scale(0) - this.scaleYInfo.scale(d.value)
          return `
            M ${0} ${this.Canvas.CanvasChartHeight - h_}
            V ${this.Canvas.CanvasChartHeight} 
            C 0 ${((this.setThickness/3/2)*1.25) + this.Canvas.CanvasChartHeight} ${this.setThickness} ${((this.setThickness/3/2)*1.25) + this.Canvas.CanvasChartHeight} ${this.setThickness} ${this.Canvas.CanvasChartHeight} 
            V ${this.Canvas.CanvasChartHeight - h_} 
          `
        })

      chartWrapper // Top Cap
        .append('ellipse')
        .attr('cx', this.setThickness / 2)
        .attr('cy', this.Canvas.CanvasChartHeight)
        .attr('rx', this.setThickness / 2)
        .attr('ry', this.setThickness / 3 / 2)
        .attr('fill', (_, i) => `url(#${this.localId}__LinearA2${i})`)
        .transition()
        .duration(500)
        .attr('cy', d => this.scaleYInfo.scale(d.value))
        
      // ------------------------------------------------------------------------- Bright Line
      chartWrapper // Bright Line (Top)
        .append('path')
        .attr(
          'd',
          d => `
          M 0 ${this.Canvas.CanvasChartHeight} 
          C 0 ${this.Canvas.CanvasChartHeight + this.setThickness / 3 - this.setThickness / 3 / 3}, 
          ${this.setThickness} ${this.Canvas.CanvasChartHeight + this.setThickness / 3 - this.setThickness / 3 / 3}, 
          ${this.setThickness} ${this.Canvas.CanvasChartHeight}
          `
        )
        .attr('fill', 'transparent')
        .attr('stroke', 'white')
        .attr('stroke-width', 1)
        .attr('opacity', '.3')
        .transition()
        .duration(500)
        .attr(
          'd',
          d => `
          M 0 ${this.scaleYInfo.scale(d.value)} 
          C 0 ${this.scaleYInfo.scale(d.value) + this.setThickness / 3 - this.setThickness / 3 / 3}, 
          ${this.setThickness} ${this.scaleYInfo.scale(d.value) + this.setThickness / 3 - this.setThickness / 3 / 3}, 
          ${this.setThickness} ${this.scaleYInfo.scale(d.value)}
        `
        )

      // ------------------------------------------------------------------------- Series & Value
      if (this.Bar.BarSeriesDisplay == 'Y') {
        chartWrapper // Series Name
          .append('text')
          .attr('class', this.Bar.BarSeriesStyle)
          .attr('transform', `translate(${Math.round(this.setThickness / 2)},${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 3) + this.Bar.BarSeriesSpace})`)
          .style('font-size', this.Bar.BarSeriesSize)
          .style('font-family', this.Bar.BarSeriesFont)
          //.style('font-weight', this.Bar.BarSeriesWeight)
          .attr('fill', (_, i) => (this.Bar.BarSeriesAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarSeriesColor))
          .attr('text-anchor', 'middle')
          .text(d => d.title)
      }

      let valueWrapper = chartWrapper // Value
        .append('g')
        .attr('id', (_, i) => `value_wrapper_${i}`)

      if (this.Bar.BarValueDisplay == 'Y') {
        valueWrapper
          .append('text')
          .attr('id', (_, i) => `ValueText${i}`)
          .attr('class', this.Bar.BarValueStyle)
          .style('font-size', this.Bar.BarValueSize)
          .style('font-family', this.Bar.BarValueFont)
          //.style('font-weight', this.Bar.BarValueWeight)
          .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarValueColor))
          .text(d => `${d.value.toFixed(this.Bar.BarValueRound)}`)
      }

      if (this.Bar.BarUnitDisplay == 'Y') {
        valueWrapper // Units
          .append('text')
          .attr('class', this.Bar.BarValueStyle)
          .attr('transform', (_, i) => `translate(${this.Bar.BarValueDisplay == 'Y' ? Number(this.getNodeElValue(`#ValueText${i}`, 'width')) : 0},0)`)
          .attr('id', (_, i) => `ValueUnit${i}`)
          .style('font-size', this.Bar.BarUnitSize)
          .style('font-family', this.Bar.BarValueFont)
          //.style('font-weight', this.Bar.BarValueWeight)
          .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarUnitColor))
          .text(this.Bar.BarUnitFormat)
      }

      // Reset the position of the value-wrapper
      this.DataItems.forEach((d, i) => {
        let valueWrapperId_ = `#value_wrapper_${i}`
        let valueWrapperBox_ = d3.select(`#value_wrapper_${i}`).node().getBoundingClientRect()
        let wrapperWidth_ = this.getNodeElValue(valueWrapperId_, 'width')

        this.svg.select(`#value_wrapper_${i}`)
          .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)}, ${this.Canvas.CanvasChartHeight - this.Bar.BarValueSpace - this.setThickness / 5.9})`)
          .style('alignment-baseline', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)}, ${this.scaleYInfo.scale(d.value) - this.Bar.BarValueSpace - this.setThickness / 5.9})`)
          .transition()
          .duration(500)
          .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)}, 
            ${this.scaleYInfo.scale(d.value) - this.Bar.BarValueSpace - this.setThickness / 5.9 + ( this.Bar.BarValuePosition == 'top' ?  0 : + valueWrapperBox_.height)}
          )`)
      })
    }
  }
}
