import { SafeIdMixin } from '../../mixins/safeid.mixin'
import { SvgService } from '../../../../src/services'

export default {
  name: 'j-chart-svg',
  mixins: [SafeIdMixin],
  props: {
    id: String,
    jsonProps: String,
    xmlString: String,
    url: String,
    jsProps: {
      type: Object,
      default: () => ({})
    },
    width: Number,
    height: Number,
  },
  data: () => ({
    svgService: null,
  }),
  computed: {
    isJsProps() {
      return Object.keys(this.jsProps).length > 0
    }
  },
  created() {
    this.localId = this.id || 'j-chart-svg__' + this.safeId('')
    this.svgService = new SvgService()
  },
  methods: {
    sendComplete() {
      setTimeout(() => {
        this.$emit('complete', this.localId)
      }, 10)
    },
    svgResize(svg_) {
      let width_ = svg_.getAttribute('width')
      let height_ = svg_.getAttribute('height')
      svg_.setAttribute('viewBox', `0 0 ${width_} ${height_}`)

      if(!this.width && !this.height) return
      if(this.width && !this.height) {
        var rate_ = this.width / width_
        var tWidth_ = this.width
        var tHeight_ = Math.round(height_ * rate_)

      } else if(!this.width && this.height) {
        rate_ = this.height / height_
        tWidth_ = Math.round(width_ * rate_)
        tHeight_ = this.height

      } else {
        tWidth_ = this.width
        tHeight_ = this.height
      }

      svg_.setAttribute('width', tWidth_)
      svg_.setAttribute('height', tHeight_)

      return svg_
    }
  },
  render() {
    let _attrs = {
      attrs: {
        id: this.localId
      },
      class: 'svg',
      style: {
        padding: '10px',
        cursor: 'default',
      }
    }
    
    if(!!this.jsonProps) {
      // _attrs.domProps = { innerHTML: xmljs.json2xml(this.jsonProps, xmljsOption) }

    } else if(!!this.xmlString) {
      let svg_ = this.svgService.xmlString2dom(this.xmlString)
      this.svgResize(svg_)

      _attrs.domProps = { innerHTML: this.svgService.dom2xmlString(svg_, true) } 

    } else if(!!this.url) {
      // var xhr = new XMLHttpRequest()
      // xhr.open("GET", this.url, true)
      // xhr.onload = function() {
      //   if (this.status == 200) {
      //     _attrs.domProps = { innerHTML: xhr.responseText }
      //   } else {
      //     console.warn('Cannot recieve the result of the svg request.')
      //   }
      //   this.sendComplete()
      // }
      // xhr.send()

    } else if(this.isJsProps) {
      // let svgJSON = this.svgReSize(JSON.parse(JSON.stringify(this.jsProps)))
      // _attrs.domProps = { innerHTML: xmljs.json2xml(svgJSON, xmljsOption) }
    } 

    if(!!this.xmlString || !!this.jsonProps || this.isJsProps) this.sendComplete()
   
    return (
      <div style=" width: auto; cursor: default; border: solid 1px #ff00ff;" { ..._attrs }>
      </div>
    )
  }
}