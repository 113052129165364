<template>
  <div class="j_date_picker">
    <v-menu
      ref="menu"
      v-model="menu"
      transition="scale-transition"
      offset-y
      min-width="290px"
      :close-on-content-click="false"
      :nudge-left="nudgeLeft"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="date"
          :class="textFieldClass"
          :label="label"
          placeholder=" "
          prepend-icon="event"
          readonly
          v-on="on"
        ></v-text-field>
      </template>
      <v-date-picker v-model="date" no-title @input="menu=false" />
    </v-menu>
  </div>
</template>
<script>
import '@/assets/stylus/ui/component/_jDatePicker.styl'
import Moment from 'moment'

export default {
  name: 'j-date-picker',
  props: {
    label: { type: String, default: '' },
    noBorder: { type: Boolean, default: false },
    noDefault: { type: Boolean, default: false },
    nudgeLeft: { type: Number, default: 0 },
    value: { type: String, default: new Date().toISOString().substr(0, 10) }
  },
  data: () => ({
    menu: false,
  }),
  computed: {
    date: {
      get() { return this.value || (this.noDefault ? '' : new Date().toISOString().substr(0, 10)) },
      set(val) { this.$emit('input', val) }
    },
    textFieldClass() {
      return { 'no-border': this.noBorder }
    }
  },
}
</script>