<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'

import Defs from '../../includes/primitives/Color_Defs'     // SVG Color Defines for Gradation Effect
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins


export default {
  name: "j-chart-bar-solid",
  mixins: [
    mx_Core
  ],
  props: {
    id: String,
    ChartType: String,

    // Chart Data
    Database: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    Title: { type: Object, default: () => ({}) },
    Legends: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    Circle: { type: Object, default: () => ({}) },
    Guideline: { type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: ''
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-pie'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()
      
      // Local - running at the local mixin 'Declares.mixin.mixin' ---
      this.init()                         // Init local data (array) & values
      this.setDefaultValues()
      this.applyColorDefs()

      // Canvas & Chart Area
      this.drawCanvas()                   // Global
      this.drawChart()                    // Local  - running at the subprocess flow --->
      this.drawTitles()                   // Global
      this.drawLegends()                  // Global
      this.drawNotes()                    // Global
      
      this.complete()
    },
    drawChart() {
      this.drawPieChart()
      this.drawGuidline()
    },

  }
}
</script>