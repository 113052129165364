import * as d3 from 'd3'
import __C from '../../../../../../../../../src/primitives/_constant_'

export default {
  data: () => ({
    innerSvg: null,
    cmmHeaderArea: null,
    seiCodes: { l1: null, l2: null },
    seiCodeInfo: null,
    selectedItems: null,
  }),
  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 
        && Object.keys(this.Canvas).length > 0 
        // && Object.keys(this.FilterValues).length > 0 
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.selectedItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      
      this.seiCodes.l1 = this.FilterValues && this.FilterValues.SEICODE_L1 ? this.FilterValues.SEICODE_L1 : '121400'
      this.seiCodes.l2 = null
      this.Toggle.status = this.FilterValues && this.FilterValues.TOGGLE ? this.FilterValues.TOGGLE : this.Toggle.status
      this.seiCodeInfo = this.DataItems[0] ? this.DataItems[0].seiCodeInfo.filter(d => d.SEICODE_L1 == this.seiCodes.l1): []
    },
    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },
    buildRequest(seicode_l2) {
      let d = this.seiCodeInfo.find(d => d.SEICODE_L2 == seicode_l2)
      if(!d) return {}
      console.log('buildRequest')

      let request = this.getRequestColumnProps('routeto', d, this.ColumnProps)
      // if(!request) return {}
      request.action.path = `${request.action.path}?useinfo=false&filters=${JSON.stringify(request.filters)}`
      // return request
    },
    backRequest(seicode_l1) {
      let d = this.seiCodeInfo.find(d => d.SEICODE_L1 == seicode_l1)
      if(!d) return {}
      d.TOGGLE = this.Toggle.status
      let request = this.getRequestColumnProps('routeto', d, this.ColumnProps)
      if(!request) return {}

      request.action.path = `${request.action.path}?useinfo=false&filters=${JSON.stringify(request.filters)}`

      return request
    },
  }
}