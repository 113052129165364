import * as d3 from 'd3'

export default {
  methods: {
    drawLegends() {
      if (this.Legends.LegendDisplay != 'Y') return

      // ---------------------------------------------------------------------------------------------------- Legends
      let legendGroup = this.svg
        .append('g')
        .attr('id', 'LegendX$LegendY')
        .attr('class', 'legend_wrapper')
        .attr('transform', `translate(${this.Legends.LegendX},${this.Legends.LegendY})`)
        .call(this.el2Drag)
        .call(this.setCursorMovable)

     
      let legendItem = legendGroup
        .selectAll('g')
        .data(this.DataItems)
        .enter()
        .append('g')
        .attr('id', (_, i) => `legend_series_item_group_${i}___${this.localId}`)

      if (this.Legends.LegendBulletDisplay == 'Y') {
        if (this.Legends.LegendBulletType == 'square') {
          legendItem
            .append('rect')
            .attr('width', this.Legends.LegendBulletSize)
            .attr('height', this.Legends.LegendBulletSize)
            .attr('fill', (_, i) => this.SharedColorSet[i])
        } else {
          legendItem
            .append('circle')
            .attr('cx', this.Legends.LegendBulletSize / 2)
            .attr('cy', this.Legends.LegendBulletSize / 2)
            .attr('r', this.Legends.LegendBulletSize / 2)
            .attr('fill', (_, i) => this.SharedColorSet[i])
        }
      }

      legendItem // SERIES
        .append('text')
        .attr('id', (_, i) => `SeriesText${i}___${this.localId}`)
        .attr(
          'transform',
          `translate(
        ${this.Legends.LegendBulletSize + this.Legends.LegendBulletSpace},
        ${this.Legends.LegendBulletSize / 2 + this.Legends.LegendSeriesSize / 10}
      )`
        )
        .attr('class', this.Legends.LegendSeriesStyle)
        .style('font-size', this.Legends.LegendSeriesSize)
        .style('font-family', this.Legends.LegendSeriesFont)
        .attr('font-weight', 400)
        .attr('fill', this.Legends.LegendSeriesColor)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(d => d.title)

      if (this.Legends.LegendValueDisplay == 'Y') {
        legendItem // VALUE
          .append('text')
          .attr('id', (_, i) => `SeriesValue${i}___${this.localId}`)
          .attr(
            'transform',
            (_, i) => `translate(
          ${this.Legends.LegendBulletSize + this.Legends.LegendSeriesSpace + this.getNodeElValue(`#SeriesText${i}___${this.localId}`, 'width') + 2},
          ${this.Legends.LegendBulletSize / 2 + this.Legends.LegendSeriesSize / 10}
        )`
          )
          .attr('class', this.Legends.LegendSeriesStyle)
          .style('font-size', this.Legends.LegendValueSize)
          .style('font-family', this.Legends.LegendSeriesFont)
          .attr('font-weight', 400)
          .attr('fill', this.Legends.LegendValueColor)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(d => `${d.value.toFixed(this.Legends.LegendValueRound)}`)
      }

      if (this.Legends.LegendUnitDisplay == 'Y') {
        legendItem // UNIT
          .append('text')
          .attr('id', (_, i) => `SeriesUnit${i}___${this.localId}`)

          .attr(
            'transform',
            (_, i) => `translate(
          ${this.Legends.LegendBulletSize + this.Legends.LegendSeriesSpace + this.getNodeElValue(`#SeriesText${i}___${this.localId}`, 'width') + (this.Legends.LegendValueDisplay == 'Y' ? this.getNodeElValue(`#SeriesValue${i}___${this.localId}`, 'width') + 2 : 0)},
          ${this.Legends.LegendBulletSize / 2 + this.Legends.LegendSeriesSize / 10}
        )`
          )
          .attr('class', this.Legends.LegendSeriesStyle)
          .style('font-size', this.Legends.LegendUnitSize)
          .style('font-family', this.Legends.LegendSeriesFont)
          .attr('font-weight', 400)
          .attr('fill', this.Legends.LegendUnitColor)
          .attr('text-anchor', 'start')
          .attr('alignment-baseline', 'middle')
          .text(this.Legends.LegendUnitFormat)
      }

      let cumulated = 0

      if (this.Legends.LegendDirection == 'Horizontal') {
        let Total_Width = 0
        let Base_Point = 0

        Total_Width = this.DataItems.map((_, i) => this.getNodeElValue(`#legend_series_item_group_${i}___${this.localId}`, 'width') + this.Legends.LegendSeriesSpace).reduce((a, b) => a + b) - this.Legends.LegendSeriesSpace

        switch (this.Legends.LegendBasepoint) {
          case 'start':
            Base_Point = 0
            break
          case 'middle':
            Base_Point = -(Total_Width / 2)
            break
          case 'end':
            Base_Point = -Total_Width
            break
        }

        this.DataItems.forEach((_, i) => {
          this.svg.select(`#legend_series_item_group_${i}___${this.localId}`).attr('transform', `translate(${Base_Point},0)`)
          Base_Point += this.getNodeElValue(`#legend_series_item_group_${i}___${this.localId}`, 'width') + this.Legends.LegendSeriesSpace
        })

        if (this.Legends.LegendTitleDisplay == 'Y') {
          legendGroup // Title
            .append('text')
            .attr('id', `legend_title___${this.localId}`)
            .attr('class', this.Legends.LegendTitleStyle)
            
            .style('font-size', this.Legends.LegendTitleSize)
            .style('font-family', this.Legends.LegendTitleFont)
            .style('text-anchor', this.Legends.LegendBasepoint == 'end' ? 'start' : 'end')
            .attr('fill', this.Legends.LegendTitleColor)
            .attr('text-anchor', 'start')
            .text('Legends')

          let title_box = d3.select(`#legend_title___${this.localId}`).node().getBoundingClientRect().width

          d3.select(`#legend_title___${this.localId}`)
          .attr('transform', `translate(${this.Legends.LegendBasepoint == 'end' ?  -title_box : title_box}, -10)`)
            
        }

      } else if (this.Legends.LegendDirection == 'Vertical') {
        this.DataItems.forEach((_, i) => {
          this.svg.select(`#legend_series_item_group_${i}___${this.localId}`).attr('transform', `translate(0,${cumulated})`)
          cumulated += this.getNodeElValue(`#legend_series_item_group_${i}___${this.localId}`, 'height') + this.Legends.LegendLineSpace
        })
      } else {
        console.log('none v-h')
      }
    }
  }
}
