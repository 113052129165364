import * as d3 from 'd3'
import __M from 'moment'

export default {
  data: () => ({
    timelineFloat: {
      start: 0,
      end: 0
    },
    timelineFor: 'skyline',    // history | skyline
    timelineKeyName: 'date',
    timelineWeekEnd: 'friday',
  }),
  methods: {
    setTimelineEnv(datasource) {
      this.timeline.year = []
      this.timeline.month = []
      this.timeline.week = []

      if(datasource.length === 0) {
        console.log('[USER: setTimelineEnv()] Timeline data is not available in the "DateItems".')
        return
      }
      
      if(this.timelineFor == 'skyline') {
        this.timelineFloat = {
          start: this.skyline.startFloat,
          end: this.skyline.endFloat
        }
        this.timelineWeekEnd = this.skyline.weekEnd
      }

      let dates_ = datasource.map(d_ => __M(d_[this.timelineKeyName]).toDate())
      let sDate_ = __M(Math.min(...dates_)).add(-this.timelineFloat.start, 'weeks').toDate()
      let eDate_ = __M(__M(Math.max(...dates_)).add(this.timelineFloat.end, 'weeks').format('YYYY-MM-DD 23:59:59')).toDate()

      let weekNames = ['sunday', 'monday', 'tuesday', 'wednesday ', 'thursday', 'friday', 'saturday']
      let weekDay = weekNames.findIndex(n_ => n_ == this.timelineWeekEnd)
      let diff_ = weekDay - sDate_.getDay()
      sDate_ = __M(sDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      diff_ = weekDay - eDate_.getDay()
      eDate_ = __M(eDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      // ### Set Timeline-Values for the Week ### ---------------------------
      let nextDate = __M(__M(sDate_).format('YYYY-MM-DD 23:59:59')).toDate()
      while(nextDate <= eDate_) {
        let value_ = {
          sDate: __M(__M(nextDate).add(-6, 'days').format('YYYY-MM-DD')).toDate(),
          mDate: null,
          eDate: nextDate
        }
        value_.mDate = new Date(d3.mean([value_.sDate, value_.eDate]))
        
        this.timeline.week.push({ name: '', ...value_ })
        nextDate = __M(nextDate).add(7, 'days').toDate()
      }
      // ### ------------------------------------- --------------------------

      
      // ### Set Timeline-Values for the Month ### --------------------------
      eDate_ = null
      let months_ = []
      let months__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y-%m')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y-%m')(w_.eDate)

          if(i > 0) {
            months_.push(months__)
            months__ = []
          }
        }
        months__.push(w_)
        if(i === this.timeline.week.length - 1) months_.push(months__)
      })
      months_.forEach(m_ => {
        this.timeline.month.push({
          name: d3.timeFormat('%b')(m_[0].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })
      // ### ------------------------------------- --------------------------
      
      // ### Set Timeline-Values for the Year ### ---------------------------
      eDate_ = null
      let years_ = []
      let years__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y')(w_.eDate)

          if(i > 0) {
            years_.push(years__)
            years__ = []
          }
        }
        years__.push(w_)
        if(i === this.timeline.week.length - 1) years_.push(years__)
      })
      years_.forEach(m_ => {
        this.timeline.year.push({
          name: d3.timeFormat('%Y')(m_[m_.length - 1].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })
      // ### ------------------------------------- --------------------------

      
      // ### Set necessary ENV-Values for the Skyline ### -------------------
      if(this.timelineFor == 'skyline') {
        this.timeline.length = this.timeline.week.length * (this.skyline.box.width + (this.skyline.box.gap*2))
        this.timeline.weekSize = this.skyline.box.width + (this.skyline.box.gap*2)
      }
      // ### ------------------------ ---------------------------------------


      // get min & max values of the timeline-scale
      this.timeline.scale = d3.scaleTime()
      .domain([
        this.timeline.week[0].sDate, 
        this.timeline.week[this.timeline.week.length - 1].eDate
      ])
      .rangeRound([0, this.timeline[this.timelineFor == 'skyline' ? 'length' : 'width']])
    },

    getTimelineCDate(date) {
      let d_ = new Date(date)
      let cdate = this.timeline.week.find(w_ => d_ >= w_.sDate && d_ <= w_.eDate)
      // return cdate ? __M(__M(cdate.eDate).format('YYYY-MM-DD')).toDate() : null
      return cdate ? cdate : null
    }
  },
}