
import * as d3 from 'd3'
import moment from 'moment'

export default {
  methods: {

    draw_Milestone() {

      let transport = this.canvasForm
      .append('g')
      .attr('id', `MILESTONE`)
      .attr('transform', `translate(0, 43)`)
      .attr('font-family', 'roboto')
      .attr('cursor', 'default')
     
      transport
      .append('text')
      .attr('x', 0)
      .attr('y', 18)
      .attr('font-size', 10).attr('text-anchor', 'start').attr('fill', '#757575')
      .text(`Blocks transported from COSCO in China`)

      // TRANSPORTATION
      this.transport.forEach(d=> {
        transport
        .append('path')
        .attr('id', ``)
        .attr('transform', `translate(${this.timeline.scale(moment(d.EF).toDate())-3}, 0)`)
        .attr('d', 'M0,3.2L3.2,0l3.2,3.2L5.5,4.1c0,0-1.7-1.7-1.6-1.6c0,0,0,5.3,0,5.3H2.6V2.5L0.9,4.1L0,3.2z')
        .attr('fill', '#757575')
      
        let scaleX = this.timeline.scale(moment(d.EF).toDate())
        transport
        .append('text')
        .attr('x', scaleX + .5)
        .attr('y', 20 + d.y)
        .attr('font-size', 9).attr('text-anchor', d.align).attr('fill', '#757575')
        .text(d.BLK)


        transport
        .append('rect')
        .attr('x', ()=> {
          if(d.align == 'start') return scaleX - 1.5
          else if(d.align == 'middle') return scaleX - 13.5
          else if(d.align == 'end') return scaleX - 24.5
        })
        .attr('y', 10.5 + d.y)
        .attr('width', 28).attr('height', 12)
        .attr('stroke', '#bcbcbc').attr('stroke-width', .5).attr('fill', 'none')
      })

      // MODULE
      this.module.forEach(d=> {
        transport
        .append('path')
        .attr('id', ``)
        .attr('transform', `translate(${this.timeline.scale(moment(d.ES).toDate())-3}, 0)`)
        .attr('d', 'M0,3.2L3.2,0l3.2,3.2L5.5,4.1c0,0-1.7-1.7-1.6-1.6c0,0,0,5.3,0,5.3H2.6V2.5L0.9,4.1L0,3.2z')
        .attr('fill', '#4CAE4E')
      
        let scaleX = this.timeline.scale(moment(d.ES).toDate())
        
        transport
        .append('rect')
        .attr('x', scaleX - (d.width/2))
        .attr('y', 10.5 + d.y)
        .attr('width', d.width).attr('height', 12)
        .attr('stroke', '#4CAE4E').attr('stroke-width', .5).attr('fill', '#F4F5F5').attr('opacity', .5)

        transport
        .append('text')
        .attr('x', scaleX + .5)
        .attr('y', 20 + d.y)
        .attr('font-size', 9).attr('text-anchor', d.align).attr('fill', '#4CAE4E')
        .text(d.BLK)
      })
      
    },


    

    

   

  }
}