import * as d3 from 'd3'
import { finished } from 'stream'

export default {
  methods: {
 
    // ------------------------------------------------- Style Type: Linked
    draw_Coloring(selection, d, c, py, ly, width, value) {
      let styleSet = JSON.parse(JSON.stringify(this.Styles.find(f => f.type == 'Coloring' && f.series == this.clone_Columns[c].series)))
      if (styleSet.zeroDisplay == 'N' && value == 0) return

      let codeSet = styleSet.codes.find(f => f.code == this.DataItems[d][styleSet.refColumn])
      let codeNo = styleSet.codes.findIndex(f => f.code == this.DataItems[d][styleSet.refColumn])
      
      let progLength = 0
      if (this.DataItems[d][styleSet.dataColumn] != '') progLength = (this.DataItems[d][styleSet.dataColumn] / 100) * width-1
      if (styleSet.progress == 'Y') styleSet.paddingX = 1

      let bColor = selection
      .append('g')
      .attr('id', `btnGroup_${d}_${c}`)
      .attr('transform', `translate(0,0)`)
      .on('mouseover', () => {
        if (styleSet.linked == 'Y') {
          bColor.style('cursor', 'pointer')
          d3.select(`#text_${d}_${c}`).transition().duration(150).attr('font-size', styleSet.textSize + styleSet.hovorText)
        }
      })
      .on('mouseout', () => {
        if (styleSet.linked == 'Y') {
          bColor.style('cursor', 'default')
          d3.select(`#text_${d}_${c}`).transition().duration(150).attr('font-size', styleSet.textSize)
        }
      })
      .on('click', function() {})
      
      bColor
      .append('rect')
      .attr('x', this.clone_Columns[c].x1 + styleSet.paddingX)
      .attr('y', ly + styleSet.paddingY)
      .attr('rx', styleSet.radius).attr('ry', styleSet.radius)
      .attr('width', () => {
        if (styleSet.progress == 'Y') return progLength
        else return this.clone_Columns[c].width - (styleSet.paddingX * 2)
      })
      .attr('height', this.Table.TableCellHeight - (styleSet.paddingY*2))
      .attr('stroke', styleSet.strokeColor).attr('stroke-width', styleSet.strokeWidth)
      .attr('fill', () => {
        if (codeSet.bColor.substr(0,1) == '#' || codeSet.bColor == 'transparent') {
          return codeSet.bColor
        } else {
          return `url(#${this.localId}__LinearA4${styleSet.type}${styleSet.series}${codeNo}0)`
        }
      })

      // TEXT
      bColor
      .append('text')
      .attr('id', `text_${d}_${c}`)
      .attr('x', this.clone_Columns[c].px)
      .attr('y', py)
      .style('font-family', this.Styles.find(f => f.type == 'Text').textFont)
      .attr('class', this.Styles.find(f => f.type == 'Text').textStyle)
      .attr('font-size', styleSet.textSize)
      .attr('fill', codeSet.tColor)
      .attr('text-anchor', this.clone_Columns[c].align)
      .attr('alignment-baseline', 'middle')
      .text(value)
    },


  }
}
