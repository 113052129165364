<template>
  <div :id="localId" class="j_mrc_status_files">
    <div v-if="fileDocs.length>0" class="file_names">
      <a v-for="doc in fileDocs" 
        v-text="`${doc.name} (${convertFileSize(doc.size)})`"
        class="names" 
        :key="doc.idx" 
        @click="onDownload(doc.idx)"
      >
      </a>
    </div>
    <div v-if="fileImages.length>0" class="thumbs">
      <div v-for="image in fileImages" class="thumb" :key="image.idx">
        <a @click="onDownload(image.idx)">
          <img :src="image.dataUrl" :title="image.name" />
        </a>
        <span v-text="`(${convertFileSize(image.size)})`"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import Url from '@/services/api/request.url'

export default {
  name: 'j-mrc-status-files',
  mixins: [
    SafeIdMixin
  ],
  props: {
    filesInfo: {
      type: Array,
      default: () => ([])
    },
  },
  data: () => ({
    locaFiltes: []
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    fileDocs() { return this.filesInfo.filter(f_ => !(['image', 'video']).includes(f_.fileType)) },
    fileImages() { return this.filesInfo.filter(f_ => (['image', 'video']).includes(f_.fileType)) },
  },
  created() {
    this.localId = this.id || 'j-comm-comment__' + this.safeId('')
  },
  mounted() {
    this.locaFiltes = JSON.parse(JSON.stringify(this.filesInfo))
    this.locaFiltes.forEach(f => { f.size = parseInt(f.size) })
  },
  methods: {
    onDownload(fileId) {
      let el = document.querySelector('#__iframe__')
      if(!el) {
        el = document.createElement('iframe')
        el.setAttribute('id', '__iframe__')
        el.style.display = 'none'

        document.body.appendChild(el)
      }
      let params = new URLSearchParams()
      params.append('token', this.account.token)
      el.src = `${Url.download}/${fileId}?${params.toString()}`
    },
    convertFileSize(size) {
      if(size < 1024) var fsize = `${size} Bytes`
      else if(size < 1024000) fsize = `${(size/1024).toFixed(2)} KB`
      else if(size < 1024000000) fsize = `${(size/1024).toFixed(2)} MB`
      else fsize = `${(size/1024).toFixed(2)} GB`
      return fsize
    }
  }
}
</script>

<style lang="stylus" scoped>
.j_mrc_status_files {
  .file_names {
    display: flex;
    flex-direction: column;
    
    .names {
      color: #333;
      margin: 0 .5rem;
      padding-bottom: .5rem;
    }
    .names:hover {
      color: #1976d2
    }
  }
  .thumbs {
    display: flex;

    .thumb {
      display: flex;
      flex-direction: column;
      align-items: center;
      
      margin-right: 3px;

      a {
        border: solid 1px rgba(0, 0, 0, 0)

        img {
          margin-bottom: -.4rem;
        }
      }
      a:hover {
        border: solid 1px #ffca28;
      }
    }

    // .thumb > * {
    //   margin-left: 3px;
    //   margin-right: 3px;
    // }
  }
 
}
</style>