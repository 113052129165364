import __C from '../primitives/_constant_'

export default {
  methods: {
    getFileInfo(_file) {
      let type_ = _file.type.split('/')

      _file.mimeType = _file.type
      _file.fileType = type_[0]

      return new Promise(resolve => {
        switch(_file.fileType) {
          case 'image': this.getImageInfo(_file).then(f_ => { resolve(f_) }); break;
          case 'video': this.getMediaInfo(_file).then(f_ => { resolve(f_) }); break;
          default: resolve(this.getDocInfo(_file))
        }
      })
    },
    getThumbBlob(target, _file) {
      let defaultSize_ = this.thumbSize

      if(_file.width <= defaultSize_ && _file.height <= defaultSize_) {
        var w_ = _file.width
        var h_ = _file.height

      } else if(_file.width >= _file.height) {
        var ratio_ = (defaultSize_ / _file.width)
        w_ = defaultSize_
        h_ = Math.round(_file.height * ratio_)
        
      } else {
        ratio_ = (defaultSize_ / _file.height)
        w_ = Math.round(_file.width * ratio_)
        h_ = defaultSize_
      }

      let canvas_ = document.createElement('canvas')
      let ctx_ = canvas_.getContext('2d')

      canvas_.width = w_
      canvas_.height = h_

      ctx_.drawImage(target, 0, 0, canvas_.width, canvas_.height)

      let dataUrl_ = canvas_.toDataURL()
      
      return dataUrl_
    },
    getImageInfo(_file) {
      if(_file.type == 'image/svg+xml') {
        // let img = document.querySelector('.dz-image img')
        // _file.width = parseInt(img.naturalWidth)
        // _file.height = parseInt(img.naturalHeight)
        _file.ext = 'svg'
      } else {
        _file.ext = _file.type.split('/')[1]
      }

      let image_ = document.createElement('img')
      let objectURL_ = URL.createObjectURL(_file)

      return new Promise(resolve => {
        image_.onload = () => {
          _file.width = image_.width
          _file.height = image_.height
          _file.dimention = `${image_.width},${image_.height}`
          _file.dataUrl = this.getThumbBlob(image_, _file)

          resolve(_file)
        }
        image_.src = objectURL_
      })
    },
    getMediaInfo(_file) {
      _file.ext = _file.type.split('/')[1]
      if (!['mov', 'mpeg', 'mp4', 'wmv'].includes(_file.ext)) return new Promise(resolve => {
        resolve(_file)
        return
      })

      let initScreenshot__ = () => {
        // video_.setAttribute('currentTime', 5)   // not working
        video_.currentTime = 5

        _file.width = video_.videoWidth
        _file.height = video_.videoHeight
        _file.dimention = `${video_.videoWidth},${video_.videoHeight}`
      }
      let video_ = document.createElement('video')
      let objectURL_ = URL.createObjectURL(_file)

      return new Promise(resolve => {
        video_.setAttribute('src', objectURL_)
        video_.addEventListener('loadedmetadata', () => { initScreenshot__() })
        video_.addEventListener('canplay', () => {
          _file.dataUrl = this.getThumbBlob(video_, _file)

          resolve(_file)
        })

        document.body.appendChild(video_)
        video_.play()
      })
    },
    getDocInfo(_file) {
      let fileNameParts = _file.name.split('.')
      _file.ext = fileNameParts[fileNameParts.length-1]
      return _file
    },
  }
}