// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_AxisY from '../../../../includes/mixins/AxisY_Draw.mixin'
import gmx_Timeline from '../../../../includes/mixins/Timeline_Draw.mixin'
import gmx_Milestone from '../../../../includes/mixins/Milestone_Draw.mixin'
import gmx_Titles from '../../../../includes/mixins/Draw_Titles.mixin'
import gmx_Notes from '../../../../includes/mixins/Draw_Notes.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../includes/mixins/Movable.mixin'
import gmx_Zoomable from '../../../../includes/mixins/Zoomable.mixin'


// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'

import lmx_Boxes from './Draw_Boxes.mixin'
import lmx_Lines from './Draw_LineCharts.mixin'
import lmx_Legend from './Draw_Legends.mixin'
import lmx_Tool from './Draw_Toolbar.mixin'
import lmx_Table from './Draw_Table.mixin'


export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_AxisY,
    gmx_Timeline,
    gmx_Milestone,
    gmx_Titles,
    gmx_Notes,
    gmx_Miscellaneous,
    gmx_Movable,
    gmx_Zoomable,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Boxes,
    lmx_Lines,
    lmx_Legend,
    lmx_Tool,
    lmx_Table,
  ],
}
