import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    rawData: [],
    
    route: null,

    extension: null,

    scale: 1,
    x: 0,
    y: 0,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.route = `${this.HOST}${sql1.PATH}/${sql1.NAME}.${sql1.EXTENSION}`
      this.extension = sql1.EXTENSION
      this.scale = sql1.SCALE
      this.x = sql1.X
      this.y = sql1.Y
    },
  }
}