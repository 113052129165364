<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'

import Defs from '../../includes/primitives/Color_Defs'   // Global Defs
import mx_Core from './includes/mixins/importer'          // Mixins (Global & Local)

export default {
  name: "j-chart-bar-solid",
  mixins: [mx_Core],
  props: {
    id: String,
    ChartType: String,

    // Chart Data
    Database: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    Axis: { type: Object, default: () => ({}) },
    Title: { type: Object, default: () => ({}) },
    Bar: { type: Object, default: () => ({}) },
    Legends: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: ''
      })
    }
  },
  data: () => ({
    chartArea: null,
  }),
  created() {
    this.localId = `${this.id || 'j-chart-bar-solid'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    // Main-Process flow *****
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()

      // Local - running at the local mixin 'DeclaresNDrawingSupport.mixin.mixin' ---
      this.setDefaultValues()
      this.setScaleXInfo()
      this.setScaleYInfo()
      // ----------------------------------------------------------------------------

      // Define the d3 effect at the very 
      // top of the SVG scope, not middle
      // of the SVG along with other elements.
      this.applyColorDefs()

      // Canvas & Chart Area
      this.drawCanvas()                   // Global
      // Other Elements
      this.drawTitles()                   // Global
      this.drawLegends()                  // Global
      this.drawNotes()                    // Global

      this.drawGrid()                     // Local  - running at the local mixin 'Draw_Elements.mixin'
      this.drawChart()                    // Local  - running at the subprocess flow

      // Send drawing complete event to the parent, 
      // and the others completing the job.
      this.complete()
    },

    // Subprocess flow *****
    drawChart() {
      // Check the data available
      let dataLength_ = this.DataItems.length

      if (dataLength_ === 0) {
        // If the data is unavailable, puts the 'NO-DATA-FOUND' 
        // text message in the chart area instead of trying to
        // draw the chart.
        // this.noDataFound()
        return
      }

      // Draw the chart's elements if the data is available.
      if (this.ChartType == 'Embossed') this.drawChartEmbossed()
      else if (this.ChartType == 'Cylinder') this.drawChartCylinder()
      else if (this.ChartType == 'Tube') this.drawChartTube()
      else this.drawChartBox()
      // this.drawDeltaChart()
    },
  }
}
</script>