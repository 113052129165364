import ServiceDashboard from '@/views/service/ServiceDashboardSingle'
import ServiceDashboardContainer from '@/views/service/ServiceDashboardContainer'
import ServiceDatatable from '@/views/service/ServiceDatatable'
import ServicePageSvg from '@/views/service/ServicePageSvgSingle'
import ServicePageSvgForMC from '@/views/service/ServicePageSvg'
import ServiceSvgTabs from '@/views/service/ServiceSvgTabs'
import ServiceCompPage from '@/views/service/ServiceCompPage'
import ServiceModelViewer from '@/views/public/3DViewerCUSTOM'

import JModalContentCOIItem from '@/components/JModalContentCOIItem'
import JCommentsBoard from '@/components/JCommentsBoard'

// For the china1 custom components
import JMcrCommentCreate from '@/components/china1/JMcrCommentCreate'
import JMcrCommentEdit from '@/components/china1/JMcrCommentEdit'
import JMcrComments from '@/components/china1/JMcrComments'

export default {
  ServiceDashboard,
  ServiceDashboardContainer,
  ServiceDatatable,
  ServicePageSvg,
  ServicePageSvgForMC,
  ServiceSvgTabs,
  ServiceCompPage,
  ServiceModelViewer,

  JModalContentCOIItem,
  JCommentsBoard,

  // For the china1 custom components
  JMcrCommentCreate,
  JMcrCommentEdit,
  JMcrComments
}

