import Api from './api'
import Url from './request.url'

export default class DatabaseSupportApi extends Api {

  constructor() {
    super()
  }

  getSqlQueries (phaseCode, groupCode, itemCode, subItemCode, callback) {
    this.axios().get(`${Url.system.config.database.sqlquery}/${phaseCode}/${groupCode}/${itemCode}/${subItemCode}`).then(res => { callback(res.data) })
  }
  getSqlQuery (idx, callback) {
    this.axios().get(`${Url.system.config.database.sqlquery}/${idx}`).then(res => { callback(res.data) })
  }
  putSqlQuery (query, callback) {
    this.axios().put(Url.system.config.database.sqlquery, query).then(res => callback(res))
  }
  updSqlQuery (query, callback) {
    this.axios().post(Url.system.config.database.sqlquery, query).then(res => callback(res))
  }
  delSqlQuery (idx, callback) {
    this.axios().delete(`${Url.system.config.database.sqlquery}/${idx}`).then(res => callback(res))
  }

  getSqlQueryResult (params, privilege, callback) {
    let url = privilege ? Url.system.config.dbexec.sqlexec : Url.system.config.database.sqlexec
    this.axios().post(url, params).then(res => { callback(res.data) })
  }
}