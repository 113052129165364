import * as d3 from 'd3'
import _ColorData from '../../../../includes/primitives/Color_Data'
import Filter from '../../../../includes/primitives/Filter_Defs'

export default {
  methods: {
    // --------------------------------------------------------------------------------------------------------------------------------------------
    drawPieChart() {
      this.get_CoordinatorsXY(this.Circle.CircleX, this.Circle.CircleY, -this.Guideline.GuideInside, 'inX', 'inY')
      this.get_CoordinatorsXY(this.Circle.CircleX, this.Circle.CircleY, this.Guideline.GuideOutside, 'outX', 'outY')
      this.get_PieChartData()

      Filter.setDefs_DropShadow(
        this.svg, 
        `${this.localId}__DropShadow`, 
        this.Circle.CircleDropshadowDeviation , 
        this.Circle.CircleDropshadowOpacity , 
        this.Circle.CircleDropshadowX, 
        this.Circle.CircleDropshadowY
      )
      var self_ = this
      if (this.Circle.CircleShadowDisplay == 'Y') {
        this.svg // Shadow
        .append('ellipse')
        .attr('cx', this.Circle.CircleX)
        .attr('cy', this.Circle.CircleY + this.Circle.CircleDiameter)
        .attr('rx', this.Circle.CircleDiameter)
        .attr('ry', this.Circle.CircleDiameter * 0.1)
        .attr('fill', `url(#${this.localId}__Shadow)`)
      }
      
      var arc = d3.arc()
        .outerRadius(d => d.data.stack)
        .innerRadius(this.Circle.CircleHoleSize)
        .cornerRadius(this.Circle.CircleRadius) // Coner Radius

      var pie = d3.pie()
        .sort(null)
        .value((d) => d.value)
        .padAngle(this.Circle.CircleSliceSize/100) // Padding Pie

      let donutGroup = this.svg
        .append('g')
        .attr('transform', `translate(${this.Circle.CircleX},${this.Circle.CircleY})`)
        
        donutGroup
        .selectAll('path')
        .data(pie(this.pieSectors))
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d, i) => Object.keys(_ColorData.setGradients.Length).includes(this.Circle.CircleColorType) ? `url(#${self_.localId}__${this.Circle.CircleColorType}${i})` : this.SharedColorSet[i])
        .style('filter', function() {
          if (self_.Circle.CircleDropshadowDisplay == 'Y' ) return `url(#${self_.localId}__DropShadow)`;
          })
        .transition()
        .duration(750)
        .attrTween('d', function(d) {
            var interpolate = d3.interpolate({startAngle: 0, endAngle: 0}, d);
            return function(t) {
                return arc(interpolate(t));
            };
          });

      // this.svg
      //   .append('g')
      //   .selectAll('path')
      //   .data(this.pieSectors)
      //   .enter()
      //   .append('path')
      //   .attr('fill', (_, i) => this.SharedColorSet[i])
      //   .attr('d', (_, i) => `
      //     M ${this.Circle.CircleX}, ${this.Circle.CircleY - this.pieSectors[i].h_Ra} 
      //     L ${this.Circle.CircleX}, ${this.Circle.CircleY - this.pieSectors[i].Ra} 
      //     A ${this.pieSectors[i].Ra}, ${this.pieSectors[i].Ra} 
      //       0 ${this.pieSectors[i].arcSweep}, 1 
      //       ${this.Circle.CircleX - this.pieSectors[i].Ra + this.pieSectors[i].endX}, ${this.Circle.CircleY - this.pieSectors[i].Ra + this.pieSectors[i].endY} 
      //     L ${this.Circle.CircleX - this.pieSectors[i].h_Ra + this.pieSectors[i].h_endX}, ${this.Circle.CircleY - this.pieSectors[i].h_Ra + this.pieSectors[i].h_endY} 
      //     A ${this.pieSectors[i].h_Ra}, ${this.pieSectors[i].h_Ra} 
      //       0 ${this.pieSectors[i].arcSweep}, 0 
      //         ${this.Circle.CircleX}, ${this.Circle.CircleY - this.pieSectors[i].h_Ra}
      //   `)
      //   .attr('stroke', '#ffffff')
      //   .attr('fill', (d, i) => `url(#${this.localId}__LinearA2${i})`)
      //   .attr('stroke-width', this.Circle.CircleSliceSize)
      //   .attr('transform', (d, i) => `rotate(${this.pieSectors[i].Ro},${this.Circle.CircleX},${this.Circle.CircleY})`)

    },

    // --------------------------------------------------------------------------------------------------------------------------------------------
    drawGuidline() {
      if (this.Guideline.GuideDisplay != 'Y') return
      var self_ = this
      this.svg
        .append('g')
        .selectAll('line') // Create Line from inside to outside
        .data(this.pieSectors)
        .enter()
        .append('line')
        .attr('x1', (d, i) => this.pieSectors[i].inX)
        .attr('y1', (d, i) => this.pieSectors[i].inY)
        .attr('x2', (d, i) => this.pieSectors[i].outX)
        .attr('y2', (d, i) => this.pieSectors[i].outY)
        .attr('stroke', this.Guideline.GuideColor)
        .attr('stroke-width', this.Guideline.GuideWeight)
        
      this.svg
        .append('g')
        .selectAll('line') // Create Line from outside to bullet
        .data(this.pieSectors)
        .enter()
        .append('line')
        .attr('x1', (d, i) => this.pieSectors[i].outX)
        .attr('y1', (d, i) => this.pieSectors[i].outY)
        .attr('x2', (d, i) => this.pieSectors[i].outX + this.pieSectors[i].direct)
        .attr('y2', (d, i) => {
          // console.log(this.pieCenterAngles[i])
          return this.pieSectors[i].outY
        })
        .attr('stroke', this.Guideline.GuideColor)
        .attr('stroke-width', this.Guideline.GuideWeight)

      this.svg
        .append('g')
        .selectAll('circle') // Create bullets
        .data(this.pieSectors)
        .enter()
        .append('circle')
        // .attr('fill', (d, i) => `url(#${this.localId}__LinearA2${i})`)
        .style('fill', this.Guideline.GuideBulletAutoColor == 'Y' ? (d, i) => `url(#${this.localId}__LinearA2${i})` : this.Guideline.GuideBulletColor)
        .attr('cx', (d, i) => this.pieSectors[i].outX + this.pieSectors[i].direct)
        .attr('cy', (d, i) => this.pieSectors[i].outY)
        .attr('r', this.Guideline.GuideBulletSize)

      let SeriesNameGroup = this.svg
        .append('g')
        .selectAll('g')
        .data(this.pieSectors)
        .enter()
        .append('g')
        .attr('id', (_, i) => `series_group_${i}`)
        .attr(
          'transform',
          (d, i) => `translate(
            ${this.pieSectors[i].outX + this.pieSectors[i].direct + this.pieSectors[i].textSpace},
            ${this.pieSectors[i].outY}
            )`
          )
      SeriesNameGroup.append('text') // title
        .attr('id', (_, i) => `series_title_${i}`)
        .attr('class', this.Guideline.GuideTextStyle)
        .style('font-size', this.Guideline.GuideTextSize)
        .style('font-family', this.Guideline.GuideTextFont)
        .attr('fill', this.Guideline.GuideTextColor)
        // .attr('text-anchor', (d,i) =>this.pieSectors[i].align)
        .attr('alignment-baseline', 'middle')
        .text((d, i) => this.pieSectors[i].title)

      SeriesNameGroup.append('text') // value
        .attr('id', (_, i) => `series_value_${i}`)
        .attr('class', this.Guideline.GuideTextStyle)
        .style('font-size', this.Guideline.GuideValueSize)
        .style('font-family', this.Guideline.GuideTextFont)
        .attr('fill', this.Guideline.GuideValueColor)
        // .attr('text-anchor', (d,i) =>this.pieSectors[i].align)
        .attr('alignment-baseline', 'middle')
        .text((d, i) => this.pieSectors[i].value.toFixed(this.Guideline.GuideValueRound))

      SeriesNameGroup.append('text') // unit
        .attr('id', (_, i) => `series_unit_${i}`)
        .attr('class', this.Guideline.GuideTextStyle)
        .style('font-size', this.Guideline.GuideUnitSize)
        .style('font-family', this.Guideline.GuideTextFont)
        .attr('fill', this.Guideline.GuideUnitColor)
        // .attr('text-anchor', (d,i) =>this.pieSectors[i].align)
        .attr('alignment-baseline', 'middle')
        .text(this.Guideline.GuideUnitFormat)

      this.pieSectors.forEach((item, i) => {
        let titleWidth = this.getNodeElValue(`#series_title_${i}`, 'width')
        let valueWidth = this.getNodeElValue(`#series_value_${i}`, 'width')

        this.svg.select(`#series_value_${i}`).attr('transform', `translate(${titleWidth + 1},0)`)
        this.svg.select(`#series_unit_${i}`).attr('transform', `translate(${titleWidth + 1 + valueWidth + 1},0)`)

        if (this.pieCenterAngles[i] > 0.5) {
          let gWidth = this.getNodeElValue(`#series_group_${i}`, 'width')
          this.svg.select(`#series_group_${i}`).attr('transform', `translate(${this.pieSectors[i].outX + this.pieSectors[i].direct + this.pieSectors[i].textSpace - gWidth},${this.pieSectors[i].outY})`)
        }
      })
    }
  }
}
