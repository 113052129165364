import * as d3 from 'd3'

export default {
  // Initial Data Set
  data: () => ({
    PieStacks: [],
    pieCenterAngles: [],
    pieSectors: []
  }),
  computed: {
    ready2draw() {
      return  this.ChartType &&
              this.DataItems.length > 0 &&
              Object.keys(this.Canvas).length > 0 &&
              Object.keys(this.Title).length > 0 &&
              Object.keys(this.Legends).length > 0 &&
              Object.keys(this.Note).length > 0 &&
              Object.keys(this.Circle).length > 0 &&
              Object.keys(this.Guideline).length > 0
    },
  },
  methods: {

    // Every times to draw the chart, this values should be inited.
    // If not, d3 drawing errors will be occured.
    init() {
      this.PieStacks = [],
      this.pieCenterAngles = [],
      this.pieSectors = []
    },
    
    // --------------------------------------------------------------------------------------------------------------------------------------------
    setDefaultValues() {
      if (this.DataItems.length === 0) return

      let dataLength = this.DataItems.length

      this.PieStacks = []
      this.pieCenterAngles = []
      this.pieSectors = []

      var sortData = JSON.parse(JSON.stringify(this.DataItems))
      sortData.sort(this.executeSort)

      // Pushing Data [PieStacks], [pieCenterAngles]
      var sum = 0
      this.DataItems.forEach((item, i) => {
        sum = sum + this.DataItems[i].value
      })
      let cumulative = 0
      this.DataItems.forEach((item, j) => {
        sortData.forEach((sItem, i) => {
          if (sItem.title == item.title) this.PieStacks.push(this.Circle.CircleDiameter - i * this.Circle.CircleStackSize)
        })
        cumulative = cumulative + (this.DataItems[j].value/sum)
        this.pieCenterAngles.push(cumulative - (this.DataItems[j].value/sum) / 2)
      })
      
      // Set shared colorset & type -------------------------------
      // ColorSet cannot only be a 'this.Circle.CircleColorSet', other
      // colorset could be invoked for thier chart type.
      // Therefore the current colorset & its type should be shared
      // to the globally common variable to correctly run the global
      // drawings.

      // If the ColorSet's colors are less than the Data length,
      // number of the color should be set as much as the length
      // of the data. If not, NOT FOUND error will be occured.
      this.SharedColorSet = dataLength > this.Circle.CircleColorSet.length ? Array.from({ length: dataLength }, (_, i) => this.Circle.CircleColorSet[i % this.Circle.CircleColorSet.length]) : this.Circle.CircleColorSet
      this.SharedColorSetType = this.Circle.CircleColorType
      // ----------------------------------------------------------
    },

    executeSort(a, b) {
      if (a.value == b.value) return 0
      return a.value < b.value ? 1 : -1
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------
    get_CoordinatorsXY(Cx, Cy, size, nameX, nameY) {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate

      this.pieCenterAngles.forEach((item, sn) => {
        ra = this.PieStacks[sn] + size
        a = 360 * item
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        x = aCalc <= 90 ? ra * Math.sin(aRad) : ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        endY = Math.sqrt(z * z - x * x)
        endX = a <= 180 ? ra + x : ra - x

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            [nameX]: endX + Cx - ra,
            [nameY]: endY + Cy - ra,
          }
        }


      })
    },

    // --------------------------------------------------------------------------------------------------------------------------------------------
    get_PieChartData() {
      var ra
      var a = 0 // Angle
      var aCalc = 0 //
      var arcSweep = 0
      var aRad = 0 // Angle in Rad
      var z = 0 // Size z
      var x = 0 // Side x
      var endX = 0 // SVG endX coordinate
      var endY = 0 // SVG endY coordinate
      var Ro = 0 // Rotation

      var h_ra
      var h_z = 0 // Size z
      var h_x = 0 // Side x
      var h_endX = 0 // SVG endX coordinate
      var h_endY = 0 // SVG endY coordinate

      var textSpace = 0
      var direct = 0
      var align = 0

      this.DataItems.forEach((item, sn) => {
        ra = this.PieStacks[sn]
        h_ra = this.Circle.CircleHoleSize // Hole
        a = 360 * (item.value / 100)
        aCalc = a > 180 ? 360 - a : a
        aRad = (aCalc * Math.PI) / 180
        z = Math.sqrt(2 * ra * ra - 2 * ra * ra * Math.cos(aRad))
        h_z = Math.sqrt(2 * h_ra * h_ra - 2 * h_ra * h_ra * Math.cos(aRad)) // Hole
        if (aCalc <= 90) {
          x = ra * Math.sin(aRad)
          h_x = h_ra * Math.sin(aRad) // Hole
        } else {
          x = ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
          h_x = h_ra * Math.sin(((180 - aCalc) * Math.PI) / 180)
        }

        endY = Math.sqrt(z * z - x * x)
        h_endY = Math.sqrt(h_z * h_z - h_x * h_x) // Hole

        if (a <= 180) {
          endX = ra + x
          h_endX = h_ra + h_x
          arcSweep = 0
        } else {
          endX = ra - x
          h_endX = h_ra - h_x
          arcSweep = 1
        }
        if (this.pieCenterAngles[sn] < 0.5) {
          direct = this.Guideline.GuideExtend
          // align = 'start'
          textSpace = 10
        } else {
          direct = -this.Guideline.GuideExtend
          // align = 'end'
          textSpace = -10
        }

        this.pieSectors[sn] = {
          ...this.pieSectors[sn],
          ...{
            angle: a,
            value: item.value,
            title: item.title,
            color: this.SharedColorSet[sn],
            arcSweep: arcSweep,
            Ra: ra,
            endX: endX,
            endY: endY,

            h_Ra: h_ra, // Hole
            h_endX: h_endX, // Hole
            h_endY: h_endY, // Hole
            Ro: Ro,
            direct: direct,
            // align: align,
            textSpace: textSpace,
            stack: this.PieStacks[sn]
          }
        }

        Ro = Ro + a
      })
    }
  }
}
