import { VCard, VCardActions, VCardTitle, VCardText, VDialog, VIcon, VSpacer } from 'vuetify/lib'
import JButton from '../JButton'
import '../../../../src/assets/stylus/ui/_modal.styl'

export default {
  name: 'j-modal',
  components: {
    JButton,
    VCard,
    VCardActions,
    VCardTitle,
    VCardText,
    VDialog,
    VIcon,
    VSpacer
  },
  props: {
    activatorOn: Boolean,
    title: String,
    titleDescription: String,
    value: null,
    width: {
      type: [String, Number],
      default: '350'
    }
  },
  render() {
    let _attrs = {
      iconButtonClose: {
        class: {
          modal_close: true
        },
        on: {
          click: () => this.$emit('close')
        }
      },
      vDialog: {
        attrs: {
          persistent: true,
          value: this.value,
          width: this.width
        },
        on: {
          input: e => this.$emit('input', e.target.value)
        },
        props: {
          contentClass: 'j_modal type01',
          value: this.value
        }
      }
    }
    return (
      <v-dialog {..._attrs.vDialog}>
        <v-card>
          <v-card-title class='modal__header'>
            <span>
              {this.title || 'Jin Modal Window'}
              <small>{this.titleDescription || 'Title Description'}</small>
            </span>
            <j-button {..._attrs.iconButtonClose}>
              <v-icon>mdi-close</v-icon>
            </j-button>
          </v-card-title>
          <v-card-text class='modal__contents'>{this.$slots.default}</v-card-text>
          {this.$slots.modalFooter || (
            <v-card-actions>
              <v-spacer></v-spacer>
              <j-button class='type01 sky' {...{ on: { click: () => this.$emit('close') } }}>
                Close
              </j-button>
            </v-card-actions>
          )}
        </v-card>
      </v-dialog>
    )
  }
}
