import getData from './Color_Data'
/*
  setDefs_Reflections
  setDefs_Shadows
  setDefs_Gradients                   Ipdated by Jinkee 2023-04-08
*/

// Set Color Reflection Effect
const setDefs_Reflections = (selection, id) => {
  let Color_Reflection = selection
    .append('defs')
    .append('linearGradient')
    .attr('id', `${id ? id + '__' : ''}Color_Reflection`)
    .attr('x1', '0')
    .attr('y1', '0')
    .attr('x2', '0')
    .attr('y2', '100%')

  getData.setReflection[1].values.forEach((item, i) => {
    Color_Reflection.append('stop')
      .attr('stop-color', getData.setReflection[2].values[i])
      .attr('stop-opacity', getData.setReflection[1].values[i])
      .attr('offset', getData.setReflection[0].values[i])
  })
}

const setDefs_Shadows = (selection, id) => {
  // Set Color Shadow Effect
  let Color_Shadow = selection
    .append('defs')
    .append('radialGradient')
    .attr('id', `${id ? id + '__' : ''}Color_Shadow`)

  Color_Shadow.append('stop')
    .attr('stop-color', '#414141')
    .attr('offset', '0')

  Color_Shadow.append('stop')
    .attr('stop-color', '#fff')
    .attr('stop-opacity', '0.3')
    .attr('offset', '1')
}

/* 
  Gradient Color Setting - updated: 2019-08-06 
    This function is provided 3 type of gradient(16 colors().
      1. LanearA2 
      2. LanearA4
      3. LanearA9
*/

const setDefs_Gradients = (selection, idx, type, color, direct = [], id) => {

  let Gradients = selection
    .append('defs')
    .append('linearGradient')
    .attr('id', `${id ? id + '_' : ''}${color}_${idx}`)
    .attr('x1', `${direct[0]}`)
    .attr('y1', `${direct[1]}`)
    .attr('x2', `${direct[2]}`)
    .attr('y2', `${direct[3]}`)

  let arrayValue = Array.from({ length: getData.setGradients.Length[type] }, (_, i) => i)
  arrayValue.forEach(i => {
    Gradients.append('stop')
      .attr('stop-color', getData.setGradients[type](color, i))
      .attr('offset', getData.setGradients[type]('Offset', i))
  })
}

export default {
  setDefs_Reflections,
  setDefs_Shadows,
  setDefs_Gradients
}
