import Api from './api'
import Url from './request.url'
import { SystemApi } from '.'

export default class SystemValuesApi extends Api {

  constructor() {
    super()
    this.systemApi = new SystemApi()
  }

  sysValuesColumns (dbName, tableName, callback) {
    this.axios().get(`${Url.system.values.columns}/${dbName}/${tableName}`).then(res => { callback(res.data) })
  }
  sysValuesTables (dbName, callback) {
    this.axios().get(`${Url.system.values.tables}/${dbName}`).then(res => { callback(res.data) })
  }
  sysValuesDatabases (callback) {
    this.axios().get(`${Url.system.values.databases}`).then(res => { callback(res.data) })
  }
}