import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,
    newMode: '',
    prevMode: '',

    prevIndex: 17,
    selectedMod: '',
    sortMode: 'MOD',
    minProg: 25,
    moduleMenuGroup: null,
    ModOrder: [
                {sn: 1 , MOD: "42M078"},
                {sn: 2 , MOD: "41M008"},
                {sn: 3 , MOD: "42M057"},
                {sn: 4 , MOD: "42M065"},
                {sn: 5 , MOD: "42M075"},
                {sn: 6 , MOD: "41M010"},
                {sn: 7 , MOD: "41M011"},
                {sn: 8 , MOD: "42M056"},
                {sn: 9 , MOD: "41M009"},
                {sn: 10, MOD: "42M054"},
                {sn: 11, MOD: "42M055"},
                {sn: 12, MOD: "41M801"},
                {sn: 13, MOD: "47M265"},
                {sn: 14, MOD: "41M007"},
                {sn: 15, MOD: "47M266"},
                {sn: 16, MOD: "41M006"},
                {sn: 17, MOD: "47M268"},
                {sn: 18, MOD: "47M267"},
                {sn: 19, MOD: "41M001"},
                {sn: 20, MOD: "41M004"},
                {sn: 21, MOD: "47M261"},
                {sn: 22, MOD: "47M262"},
                {sn: 23, MOD: "41M003"},
                {sn: 24, MOD: "47M263"},
              ]
  }),
  computed: {

    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },

  },
  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      
      if(!this.EnvValues) {
        this.newMode = 'FAB'
        this.prevMode = 'FAB'
        this.sortMode = 'MOD'
      } else {
        if(!this.EnvValues.tab) {
          this.newMode = 'FAB'
          this.prevMode = 'FAB'
        } else {
          this.newMode = this.EnvValues.tab
          this.prevMode = this.EnvValues.tab
        }
        if(!this.EnvValues.sort) this.sortMode = 'MOD'
        else this.sortMode = this.EnvValues.sort
      }

      if(!this.FilterValues || !this.FilterValues.MOD) this.selectedMod = '41M001'
      else this.selectedMod = this.FilterValues.MOD
      
      this.dataSet.forEach((d,i) => { 
        d.rfsMOD = this.ModOrder[i].MOD
      })

      this.dataSet.sort((a, b) => { 
        return a[this.sortMode] < b[this.sortMode] ? -1 : a[this.sortMode] > b[this.sortMode] ? 1 : 0;
      })

      this.prevIndex = this.dataSet.findIndex(d => d.MOD == this.selectedMod)

      let color = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `${this.localId}_module`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      color.append('stop').attr('stop-color', '#83d2f5').attr('offset', '0')
      color.append('stop').attr('stop-color', '#44a9df').attr('offset', '1')
    },
    setEmptyMenuItems() {
      if(!this.moduleMenuGroup) return
  
      this.moduleMenuGroup.remove()
      this.moduleMenuGroup = null
    },
    setEnvValues(val={}) {
      if(!this.EnvValues) var envValues_ = {}
      else envValues_ = { ...this.EnvValues }
  
      this.$emit('env-values', { ...envValues_, ...val })
    }
  },
}