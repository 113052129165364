import gmx_zMixins         from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support

import lmx_Watching from './Watching' // Reactive Events
import lmx_LocalValues from '././set_LocalValues'
import lmx_Forms from './draw_Forms'
import lmx_Charts from './draw_Charts'

export default {

  mixins: [
    gmx_zMixins, 

    // Local Mixins

    lmx_Watching,
    lmx_LocalValues,
    lmx_Forms,
    lmx_Charts,
  ],
}
