export default {
  data: () => ({
    area_Summary: null,
    
    rawData:    null,
    
    common:     null,
    texts:      null,
    tube:       null,
    cube:      null,
    columns:    null,

    filters:      null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems[0]))
      this.columns = JSON.parse(JSON.stringify(this.DataCols.Columns))
      this.filters = JSON.parse(JSON.stringify(this.DataCols.Filters))

      let commons = JSON.parse(JSON.stringify(this.Common))
      let types = JSON.parse(JSON.stringify(this.CTypes))
      

      this.area =   commons.area
      this.texts =  commons.texts

      // Charts Type
      this.tube =   types.tube
      this.cubes =   types.cubes
      
      let x_ = 0
      this.columns.forEach(d=> {
        d.sX = x_
        d.mX = x_ + d.width/2
        x_ +=  d.width
        d.eX = x_

        d.value = this.rawData[d.code]
      })
      // console.log(this.columns)
    },
    
      
    
    set_ColorDefs(selection){

      let Shadow = selection
      .append('defs') // Shadow Left
      .append('radialGradient')
      .attr('id', `${this.localId}_Shadow`)

      Shadow.append('stop').attr('stop-color', '#a4a4a4').attr('offset', '0.00').attr('stop-opacity', '1')
      Shadow.append('stop').attr('stop-color', '#ffffff').attr('offset', '1.00').attr('stop-opacity', '0.37')


      let TubeBase = selection
      .append('defs') // d.base.height Cylinder
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeBase`)

      TubeBase.append('stop').attr('stop-color', '#a9adb0').attr('offset', '0.00').attr('stop-opacity', '1')
      TubeBase.append('stop').attr('stop-color', '#f6f6f6').attr('offset', '0.60').attr('stop-opacity', '1')
      TubeBase.append('stop').attr('stop-color', '#f6f6f6').attr('offset', '1.00').attr('stop-opacity', '1')

      let TubeBox = selection
      .append('defs') // Tube Box
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeBox`)

      TubeBox.append('stop').attr('stop-color', '#b4b4b4').attr('offset', '0.00').attr('stop-opacity', '0.50')
      TubeBox.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.30').attr('stop-opacity', '0.15')
      TubeBox.append('stop').attr('stop-color', '#fff').attr('offset', '0.60').attr('stop-opacity', '0.01')
      TubeBox.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.80').attr('stop-opacity', '0.15')
      TubeBox.append('stop').attr('stop-color', '#b4b4b4').attr('offset', '1.00').attr('stop-opacity', '0.50')

      let TubeCap = selection
      .append('defs') // Tube Cap
      .append('linearGradient')
      .attr('id', `${this.localId}__TubeCap`)

      TubeCap.append('stop').attr('stop-color', '#efefef').attr('offset', '0.00').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#f2f2f2').attr('offset', '0.41').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#ececec').attr('offset', '0.59').attr('stop-opacity', '0.90')
      TubeCap.append('stop').attr('stop-color', '#e8e8e8').attr('offset', '1.00').attr('stop-opacity', '0.90')
    },

    
  }
}