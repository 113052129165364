
import * as d3 from 'd3'

export default {
  methods: {

    draw_Forms() {

      this.canvasForm = this.svg
      .append('g')
      .attr('id', `CANVAS_FROM`)
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('font-family', 'roboto')
      
      this.canvasForm
      .append('image')
      .attr('xlink:href', this.HOST + '/icon/Logo/TrionFPU_r1.svg').attr('x', 30).attr('y', 30).attr('width', 230)

      this.canvasForm
      .append('text')
      .attr('id', 'SKY_TITLE')
      .attr('transform', `translate(30, 108)`)
      .attr('font-size', 24)
      .attr('fill', '#000')
      .text('Skyline Chart for Cutting Blocks of Overall')

      this.canvasForm
      .append('text')
      .attr('transform', `translate(30, 125)`)
      .attr('font-size', 12)
      .attr('fill', '#44A9DF')
      .text(this.timeline.title)

    },


    

    

   

  }
}