<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'          // Mixins (Global & Local)

export default {
  name: "j-chart-histogram",
  mixins: [mx_Core],
  props: {
    id: String,
    ChartType: String,

    // Chart Data
    Canvas    : { type: Object, default: () => ({}) },
    Timeline  : { type: Object, default: () => ({}) },
    Axis      : { type: Object, default: () => ({}) },
    Bar       : { type: Object, default: () => ({}) },
    Line      : { type: Object, default: () => ({}) },
    Milestone : { type: Object, default: () => ({}) },
    Text      : { type: Object, default: () => ({}) },
    Legends   : { type: Object, default: () => ({}) },

    // Database
    Cutoff    : String,
    DataItems : { type: Array, default: () => ([]) },

    // Summary Table
    TableSummary: { type: Object, default: () => ({}) },

    FilterValues: {
      type: Object,
      default: () => ({})
    },
    Values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: ''
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-histogram'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()

      this.initData()
      
      this.setScaleYInfo()
      this.setScaleColor()
      // ScaleBarGroup should be set after the setting of the aboves.
      this.setScaleBarGroup()

      // Draw canvas & chart-area
      this.drawCanvas()
      this.drawAxisPrimary()
      this.drawAxisSecondary()

      let bandInfo_ = this.calBandInfo()

      // Global - Timeline 
      this.drawTimeline({
        s: this.chartArea,
        x: 0,
        y: this.Canvas.CanvasChartHeight,
        w: bandInfo_.areaWidth
      })

      this.drawBars()
      this.putBarValues()
      this.drawLines()
      this.putLineValues()

      // 'drawMilestone' uses pre-defined scale to get X coordinate
      // instead of its own.
      this.drawMilestone({
        s: this.chartArea,                  // Selection where the AxisY-Wrapper(SVG Group) to be placed.
        x: 0,                               // X coordinate of the Axis wrapper(SVG Group)
        y: this.Canvas.CanvasChartHeight,   // Y coordinate of the Axis wrapper(SVG Group)
        w: this.Canvas.CanvasChartWidth,    // Chart Area Width
        scaleX: (val) => {                  // Scale Callback function for X coordinate
          return this.scaleTimelineInfo.scale(val)
        }
      })

      this.drawLegend()
      this.drawTexts()

      // Summary Table
      this.setTableEnvValues()
      this.drawTable()

      // Send drawing complete event to the parent, 
      // and the others completing the job.
      this.complete()
    },
  }
}
</script>