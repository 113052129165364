<template>
  <div :id="localId"></div>
</template>

<script>
import __C from '../../includes/primitives/_constant_'   // Global Defs
import mx_Core from './includes/mixins/importer'
import chart_mixin from './includes/mixins/Draw_Elements.mixin'



export default {
  name: "j-chart-bar-normal",
  mixins: [
    mx_Core,
    chart_mixin,
  ],
  props: {
    id: String,
    ChartType: String,

    // Chart Data
    Database: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    Axis: { type: Object, default: () => ({}) },
    Title: { type: Object, default: () => ({}) },
    Bar: { type: Object, default: () => ({}) },
    Legends: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },

    // Database
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: ''
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-bar-normal'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    // Main-Process flow *****
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return

      this.clear()

      // Local - running at the local mixin 'DeclaresNDrawingSupport.mixin.mixin' ---
      this.setDefaultValues()
      this.setScaleInfo()
      // // ----------------------------------------------------------------------------

      // // Define the d3 effect at the very 
      // // top of the SVG scope, not middle
      // // of the SVG along with other elements.
      // this.applyColorDefs()

      // Canvas & Chart Area
      this.drawCanvas()                   // Global
      this.drawTitles()                   // Global
      this.drawLegendsCheck()             // in local & Global
      this.drawNotes()                    // Global

      // Other Elements
      
      this.drawGrid()                     // Local  - axis grid render
      this.drawChart()                    // Local  - bar render
      this.drawAxis()                     // Local  - axis render
      // this.genChart()

      // Send drawing complete event to the parent, 
      // and the others completing the job.
      this.complete()
    },

    // Subprocess flow *****
    drawChart() {
      // Check the data available
      let dataLength_ = this.DataItems.length

      if (dataLength_ === 0) {
        // If the data is unavailable, puts the 'NO-DATA-FOUND' 
        // text message in the chart area instead of trying to
        // draw the chart.
        // this.noDataFound()
        return
      }
      if (this.ChartType == __C.CHART.TYPE_CODE_VERTICAL) this.drawChartVNormal()
      else if (this.ChartType == __C.CHART.TYPE_CODE_HORIZONTAL) this.drawChartHNormal()
      else if (this.ChartType == __C.CHART.TYPE_CODE_STACK_VERTICAL) this.drawChartVStack()
      else if (this.ChartType == __C.CHART.TYPE_CODE_GROUP_VERTICAL) this.drawChartVGroup()
    },
  }
}
</script>