import Vue from "vue";
import Vuex from "vuex";

import Account from "./modules/account.module"
import Application from "./modules/application.module"
import Appsvc from "./modules/service.module"
import Chartlib from "./modules/chartlib.module"
import Config from "./modules/config.module"
import Common from "./modules/common.module"
import DashboardAdmin from "./modules/adminservices/pagecomponents/dashboard.module"
import DBSupport from "./modules/dbsupport.module"
import IDGAdmin from "./modules/adminservices/pagecomponents/intended.datagrid.module"
import Notice from "./modules/notice.module"
import SlideModal from "./modules/slidemodal.module"
import SvgPropsAdmin from "./modules/adminservices/pagecomponents/svgcontrol.module"
import Sysenv from "./modules/system.module"

import SvgPageBuilder from "./modules/adminservices/pagecomponents/svg.pagebuilder.module"

/* For the Filebrowser */
import mutations from '../../lib/filebrowser/src/store/mutations'
import getters from '../../lib/filebrowser/src/store/getters'

Vue.use(Vuex)

/* For the Filebrowser */
const state = {
  user: {},
  req: {},
  oldReq: {},
  clipboard: {
    key: '',
    items: []
  },
  jwt: '',
  fbname: '',   // '' <system file manager> | 'Coi' <certificate of indpection>
  fbpath: '',
  progress: 0,
  loading: false,
  reload: false,
  selected: [],
  multiple: false,
  show: null,
  showShell: false,
  showMessage: null,
  showConfirm: null,
  inputValue: '',
  sort: {
    by: 'name',
    asc: false
  }
}


export default new Vuex.Store({
    strict: process.env.NODE_ENV !== `production`,
    state,
    getters,
    mutations,
    modules: {
      '$g': Common,

      account: Account,
      app: Application,
      appsvc: Appsvc,
      chartlib: Chartlib,
      config: Config,
      dbsupport: DBSupport,
      idgadmin: IDGAdmin,
      notice: Notice,
      dashadmin: DashboardAdmin,
      svgprops: SvgPropsAdmin,
      sysenv: Sysenv,

      svgpagebuilder: SvgPageBuilder,
      slidemodal: SlideModal,
    }
})

