import * as d3 from 'd3'

export default {
  data: () => ({


  }),

  computed: {
  },

  methods: {
    draw_Lengends() {
      
      if (this.Radar.legend.display != 'Y') return

      // Legends
      let legendWidth = []

      let RadarLegend = this.svg
      .append('g')
      .attr('id', `${this.localId}__LegendGroup`)
      .attr('transform', `translate(${this.Radar.legend.x}, ${this.Radar.legend.y})`)

      for(let n=0; n < this.Radar.chart.data; n++) {
          let legends = RadarLegend
          .append('g')
          .attr('id', `${this.localId}__Legend_${n}`)
              legends
              .append('rect')        
              .attr('x', 0)
              .attr('y', 0)
              .attr('width', this.Radar.legend.bullet)
              .attr('height', this.Radar.legend.bullet)
              .attr('storke-width', 0)
              .attr('fill', this.Radar.area.stColor[n])

              legends
              .append('text')        
              .attr('x', 13)
              .attr('y', (this.Radar.legend.bullet/2)+0.5)
              .style('font-family', this.Radar.legend.font)
              .attr('font-size', this.Radar.legend.tSize)
              .attr('fill', this.Radar.legend.tColor)
              .attr('text-anchor', 'start')
              .attr('alignment-baseline', 'middle')
              .text(this.Radar.legend.text[n])

          legendWidth.push(this.getNodeElValue(`#${this.localId}__Legend_${n}`, 'width'))
        }
       
        for(let n=1; n < this.Radar.chart.data; n++) {
          d3.select(`#${this.localId}__Legend_${n}`)
          .attr('transform', `translate(${legendWidth[n-1] + 10}, ${0})`)
        }

        let totoalLength = this.getNodeElValue(`#${this.localId}__LegendGroup`, 'width') / 2
        d3.select(`#${this.localId}__LegendGroup`)
        .attr('transform', `translate(${this.Radar.legend.x - totoalLength}, ${this.Radar.legend.y})`)
    }
  }
}
