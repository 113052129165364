import * as d3 from 'd3'

export default {
  data: () => ({
    route: {
      to: null,
      from: null,
    },
    routeHistory: [],
    loadFunc: null,
    unloadFunc: null,
  }),
  watch: {
    DataItems: {
      handler(v) {
        this.register(this.draw)
      },
      deep: true
    }
  },
  mounted() {
    this.loadFunc = {
      main: this.loadMain,
      package: this.loadPackage,
      area: this.loadArea,
      equipment: this.loadEquipment,
    }
    this.unloadFunc = {
      main: this.unloadMain,
      package: this.unloadPackage,
      area: this.unloadArea,
      equipment: this.unloadEquipment,
    }
  },
  methods: {
    routeTo(param, from=null) {
      /**
       * instruction of the router param
       * {
       *    level    : Number   // page order   => main: 0 | package: 1 | area: 2 | equipment: 3
       *    lname    : String   // level name   => main | package | area | equipment
       *    package  : String   // package code => P1 | P2 | P3
       *    area     : String   // area code    => BTX | BD
       *    equipment: String   // area-unit    => A | B | C | D
       * }
       */

       let param__ = param
       let from__ = from

       // By the back button
      if(typeof param == 'number' && param === -1) {
        let len = this.routeHistory.length

        from__ = this.routeHistory[len-1]
        this.routeHistory.splice(-1)

        len -= 1
        param__ = this.routeHistory[len-1]

      } else {
        this.routeHistory.push(param__)
      }
      
      this.$emit('selected-items', [param__])

      this.selectedItems = {
        ...this.selectedItems,
        route: param__
      }
      
      this.route.to = param__
      this.route.from = from__

      if(from__) this.unloadFunc[from__.lname]().then(() => {
        this.loadFunc[param__.lname]()
      }); else this.loadFunc[param__.lname]()

      this.setLocationSubtitle()
      this.setHomeBackButton()
      this.setTagControlButton()
      this.setStageStatus()
      this.setLegendColorCode()
      this.setLegendAction()
      this.setKeyplan()
    },
    setLocationSubtitle() {
      switch(this.route.to.lname) {
        case 'main':
          var title_ = 'Main Plant'
          break
        case 'package':
          title_ = `Package ${this.route.to.package}`
          break
        case 'area':
          title_ = `Package ${this.route.to.package}. Area ${this.route.to.area}`
          break
        case 'equipment':
          title_ = `Package ${this.route.to.package}. Area ${this.route.to.area} ${this.route.to.equipment}`
      }
      
      d3.select(`#${this.localId}`).select('#el_location_subtitle')
      .transition().delay(250)
      .text(title_)
    },
    setHomeBackButton() {
      d3.select(`#${this.localId}`).select('#el_g_route_to')
      .style('pointer-events', this.route.to.level > 0 ? 'auto' : 'none')
      .transition().duration(250)
      .style('opacity', this.route.to.level > 0 ? 1 : 0)
    },
    setTagControlButton() {
      d3.select(`#${this.localId}`).select('#tagbtn_control_wrapper')
      .style('pointer-events', this.route.to.level == 3 ? 'auto' : 'none')
      .transition().duration(250)
      .style('opacity', this.route.to.level == 3 ? 1 : 0)
    },
    setStageStatus() {
      switch(this.route.to.lname) {
        case 'main':
          var source = this.dataset.stageSummary.main
          var filters = {}
          break
        case 'package':
          source = this.dataset.stageSummary.package.find(d => d.LV2 == this.route.to.package)
          filters = { LV2: source.LV2 }
          break
        case 'area':
          source = this.dataset.stageSummary.area.find(d => d.LV2 == this.route.to.package && d.LV3 == this.route.to.area)
          filters = { LV2: source.LV2, LV3: source.LV3 }
          break
        case 'equipment':
          source = this.dataset.stageSummary.equipment.find(d => d.LV2 == this.route.to.package && d.LV3 == this.route.to.area && d.AREA_UNIT == this.route.to.equipment)
          filters = { LV2: source.LV2, LV3: source.LV3, AREA_UNIT: source.AREA_UNIT }
      }
      
      // Master Data
      let data__ = {
        x     : 0,
        y     : 0,
        shadow: false,
        source,
        filters,
      }
      this.drawStageStatus(this.cmmSharedArea, data__)
    },
    setLegendColorCode() {
      d3.select(`#${this.localId}`).select('#common_legend_color_code_area')
      .transition().delay(150).duration(250)
      .style('opacity', this.route.to.lname == 'equipment' ? 1 : 0)
    },
    setLegendAction() {
      d3.selectAll('.legend_delivery_item').each((d, i, a) => {
        let label = d3.select(a[i]).select('.boxed_summary_single_label')
        let coordicate = this.getCoordinates(label)

        label
        .transition().duration(150)
        .attr('transform', `translate(${this.route.to.level == 3 ? this.eqStyle.legend.labelx2 : this.eqStyle.legend.labelx1}, ${coordicate[1]})`)
      })
      d3.selectAll('.legend_critical_checkbox').each((d, i, a) => {
        d3.select(a[i])
        .style('pointer-events', this.route.to.level == 3 ? 'auto' : 'none')
        .transition().duration(150)
        .style('opacity', this.route.to.level == 3 ? 1 : 0)
      })
    },
    setKeyplan() {
      switch(this.route.to.lname) {
        case 'main':
          var keyplanImage = 'MAIN.png'
          break
        case 'package':
          keyplanImage = `${this.route.to.package}.png`
          break
        case 'area':
          keyplanImage = `${this.route.to.package}_${this.route.to.area}.png`
          break
        case 'equipment':
          keyplanImage = `${this.route.to.package}_${this.route.to.area}_${this.route.to.equipment}.png`
      }
      
      d3.select(`#${this.localId}`).select('.el_keyplan')
      .attr('xlink:href', `${this.__HOST_NAME_RESOURCE}/China1/EquipmentLayout/Information_Guide/svg/Keyplan/${keyplanImage}`)
    },
  }
}