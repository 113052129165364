import '../../../../src/assets/stylus/ui/_dataGrid.styl'

import { VDataTable, VIcon } from 'vuetify/lib'
import { getObjectValueByPath } from 'vuetify/lib/util/helpers'
import Sortable from "sortablejs"

import { SafeIdMixin } from '../../mixins/safeid.mixin'

var _slicedToArray = function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"]) _i["return"](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError("Invalid attempt to destructure non-iterable instance"); } }; }();

export default {
  name: 'j-data-grid',
  mixins: [SafeIdMixin],
  components: {
    VDataTable, VIcon
  },
  props: {
    draggable: Boolean,
    headers: {
      type: Array,
      default: () => []
    },
    headersLength: {
      type: Number
    },
    headerText: {
      type: String,
      default: 'text'
    },
    headerKey: {
      type: String,
      default: null
    },
    hideHeaders: Boolean,
    items: {
      type: Array,
      default: () => []
    },
    itemKey: String,
    pagination: {
      type: Object,
      default: function _default() {
        return {
          descending: false,
          page: 1,
          rowsPerPage: -1 /* -1 for All */,
          sortBy: null,
          totalItems: 0
        }
      }
    },
    search: null,
    icon: {
      type: Boolean,
      default: false
    },
    chart: {
      type: Boolean,
      default: false
    },
    sortIcon: {
      type: String,
      default: 'mdi-chevron-up'
    },
    value: null,
  },
  data: () => ({
    defaultPagination: {
      descending: false,
      page: 1,
      rowsPerPage: -1 /* -1 for All */,
      sortBy: null,
      totalItems: 0
    },
  }),
  computed: {
    dragHandlerExist() {
      return this.headers.find(h => h.type == 'drag')
    },
    computedPagination () {
      return this.hasPagination
        ? this.pagination
        : this.defaultPagination
    },
    hasPagination () {
      const pagination = this.pagination || {}
      return Object.keys(pagination).length > 0
    },
  },
  methods: {
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending
      } else {
        this.pagination.sortBy = column
        this.pagination.descending = false
      }
    },
    customFilter (items, search, filter, headers) {
      search = search.toString().toLowerCase();
      if (search.trim() === '') return items;
      var props = headers.map(function (h) {
        return h.value;
      })
      var filtered = items.filter(function (item) {
        return props.some(function (prop) {
          return filter(getObjectValueByPath(item, prop, item[prop]), search);
        })
      })
      this.$emit('filtered', filtered)
      return filtered
    },
    customSort (items, index, isDescending) {
      if (index === null) return items;

      var sorted = items.sort(function (a, b) {
        var sortA = getObjectValueByPath(a, index);
        var sortB = getObjectValueByPath(b, index);
        if (isDescending) {
          var _ref = [sortB, sortA];
          sortA = _ref[0];
          sortB = _ref[1];
        }
        // Check if both are numbers
        if (!isNaN(sortA) && !isNaN(sortB)) {
          return sortA - sortB;
        }
        // Check if both cannot be evaluated
        if (sortA === null && sortB === null) {
          return 0;
        }

        var _map = [sortA, sortB].map(function (s) {
          return (s || '').toString().toLocaleLowerCase();
        });

        var _map2 = _slicedToArray(_map, 2);

        sortA = _map2[0];
        sortB = _map2[1];

        if (sortA > sortB) return 1;
        if (sortA < sortB) return -1;

        return 0;
      });

      this.$emit('sorted', sorted)

      return sorted
    },
    genHeader () {
      if (this.hideHeaders) return // Exit Early since no headers are needed.

      var row = []

      this.headers.forEach((header, i) => {
        if(this.search && header.type == 'drag') return
        row.push(this.$createElement('th', ...this.genHeaderData(header, [header[this.headerText]], this.headerKey ? header[this.headerKey] : i)))
      })

      return this.genTR(row)
    },
    genHeaderData (header, children, key) {
      const classes = ['column']
      let data = {
        key,
        attrs: {
          role: 'columnheader',
          scope: 'col',
          width: header.width || null,
          'aria-label': header[this.headerText] || '',
          'aria-sort': 'none'
        }
      }

      if(this.draggable) {
        if((this.dragHandlerExist && header.type == 'drag') || (!this.dragHandlerExist && header.type == 'conf')) data.domProps = {
          innerHTML: "<button class='jcon_config'></button>"
        }
      } else {
        if(header.type == 'conf') data.domProps = {
          innerHTML: "<button class='jcon_config'></button>"
        }
      }

      if (!this.draggable && (header.sortable == null || header.sortable)) {
        this.genHeaderSortingData(header, children, data, classes)
      } else {
        data.attrs['aria-label'] += ': Not sorted.' // TODO: Localization
      }

      classes.push(`text-xs-${header.align || 'left'}`)
      if (Array.isArray(header.class)) {
        classes.push(...header.class)
      } else if (header.class) {
        classes.push(header.class)
      }
      data.class = classes

      return [data, children]
    },
    genHeaderSortingData (header, children, data, classes) {
      if (!('value' in header)) {
        console.warn('Headers must have a value property that corresponds to a value in the v-model array', this)
      }

      data.attrs.tabIndex = 0
      data.on = {
        click: () => {
          this.changeSort(header.value)
        }
      }

      classes.push('sortable')
      const icon = this.$createElement(VIcon, {
        props: {
          small: true
        }
      }, this.sortIcon)
      if (!header.align || header.align === 'left') {
        children.push(icon)
      } else {
        children.unshift(icon)
      }

      const pagination = this.computedPagination
      const beingSorted = pagination.sortBy === header.value
      if (beingSorted) {
        classes.push('active')
        if (pagination.descending) {
          classes.push('desc')
          data.attrs['aria-sort'] = 'descending'
          data.attrs['aria-label'] += ': Sorted descending. Activate to remove sorting.' // TODO: Localization
        } else {
          classes.push('asc')
          data.attrs['aria-sort'] = 'ascending'
          data.attrs['aria-label'] += ': Sorted ascending. Activate to sort descending.' // TODO: Localization
        }
      } else {
        data.attrs['aria-label'] += ': Not sorted. Activate to sort ascending.' // TODO: Localization
      }
    },
    genTR (children, data = {}) {
      return this.$createElement('tr', data, children)
    },
    initDraggable (id, callback) {
      let table = document.querySelector('#' + this.localId + ' .v-datatable tbody')
      Sortable.create(table, {
        handle: ".handle", // Use handle so user can select text
        onEnd({ newIndex, oldIndex }) {
          callback ({ newIndex: newIndex, oldIndex: oldIndex })
        }
      })      
    },
    onRowMoved(val) {
      this.$emit('row-move-to', val)
    },
    updatePagination (val) {
      var pagination = this.hasPagination ? this.pagination : this.defaultPagination;
      var updatedPagination = Object.assign({}, pagination, val);
      this.$emit('update:pagination', updatedPagination);
      if (!this.hasPagination) {
          this.defaultPagination = updatedPagination;
      }
    },
  },
  created() {
    this.localId = 'j-data-grid__' + this.safeId('')
    this.pagination.sortBy = this.itemKey
  },
  mounted() {
    if(this.draggable) this.initDraggable(this.localId, this.onRowMoved)
  },
  render () {
    let _attrs = {
      vDataTable: {
        attrs: {
          id: this.localId,
          ...this.$props
        },
        class: {
          'j_datagrid': true,
          // control: !this.search,
          icon: this.icon,
          chart: this.chart
        },
        on: {
          input: (value) => this.$emit('input', value),
          'update:pagination': (e) => {
            this.updatePagination(e)
          },
        },
        props: {
          id: this.localId,
          customFilter: this.customFilter,
          customSort: this.customSort,
          headers: this.headers,
          hideActions: true,
          itemKey: this.itemKey,
          items: this.items,
          value: this.value,
        },
        scopedSlots: {
          headers: this.$scopedSlots.headers || this.genHeader,
          items: this.$scopedSlots.items,
        },
        // The target of the sync event must be props element, 
        // not the data or method...
        sync: {
          pagination: this.pagination
        }
      }
    }
    return (
      <table { ..._attrs.vDataTable } >
      </table>
    )
  }
}