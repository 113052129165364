import * as d3 from 'd3'

export default {
  methods: {

    draw_Elements() {
      
      let y_ =  0
      let styles = this.styles
      let bar = this.barChart
      let cols = this.columns

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, styles.maxValue])
      .range([0, bar.barWidth])

      let discipline = this.svg
      .append('g')
      .attr('transform', `translate(${styles.x + .5}, ${styles.y + .5})`)
      .style('font-family', 'roboto')

      // Draw Header
      this.columns.name.forEach((d,i)=> {
        discipline
        .append('text')
        .attr('x', cols.position[i]).attr('y', y_)
        .style('font-size', styles.hSize).style('fill', styles.hColor)
        .attr('text-anchor', cols.align[i]).attr('alignment-baseline', 'middle')
        .text(d)
      })

      let grid_start =  cols.position[cols.last_idx]
      let grid_mid   =  cols.position[cols.last_idx] + bar.barWidth / 2
      let grid_end   =  cols.position[cols.last_idx] + bar.barWidth

      discipline
      .append('text')
      .attr('x', grid_start).attr('y', styles.tableY - styles.lineHight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      discipline
      .append('text')
      .attr('x', grid_mid).attr('y', styles.tableY - styles.lineHight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(styles.maxValue/2)

      discipline
      .append('text')
      .attr('x', grid_end).attr('y', styles.tableY - styles.lineHight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(styles.maxValue)

      discipline
      .append('line')
      .attr('x1', grid_start).attr('y1', styles.tableY - styles.lineHight + 5).attr('x2', grid_start).attr('y2', styles.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      discipline
      .append('line')
      .attr('x1', grid_mid).attr('y1', styles.tableY - styles.lineHight + 5).attr('x2', grid_mid).attr('y2', styles.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      discipline
      .append('line')
      .attr('x1', grid_end).attr('y1', styles.tableY - styles.lineHight + 5).attr('x2', grid_end).attr('y2', styles.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      
      y_ = y_ + styles.tableY
      this.rawData.forEach((d,i)=> {
        
         this.columns.code.forEach((c,j)=> {

          if (cols.overlap[j] == 'N') {
            let pre = ''
            let current = this.rawData[i][this.columns.code[j]]
            i == 0 ? pre = '' : pre = this.rawData[i-1][this.columns.code[j]]
            if (pre == current) return
          }
          discipline
          .append('text')
          .attr('id', `${i}_${j}`)
          .attr('transform', `translate(${cols.position[j]}, ${y_})`)
          .style('font-size', styles.tSize)
          .style('fill', cols.tColor[j])
          .attr('text-anchor', cols.align[j]).attr('alignment-baseline', 'middle')
          .text(d[c])
          .style('cursor', cols.jsonLink[j] !== '' ? 'pointer' : 'default')
          .call(this.call_LINK, {
            code      : c,
            idx       : i,
            colno     : j,
            jsonLink  : cols.jsonLink[j],
            x         : cols.position[j], 
            y         : y_, 
            tColor    : cols.tColor[j],
            align     : cols.align[j],
            filter    : cols.filter[j],
          })
          
        })

        // Bar Chart
        discipline
        .append('rect')
        .attr('transform', `translate(${cols.position[cols.last_idx]}, ${y_ - (styles.tSize/2) - 1})`)
        .attr('width', barScale(d.TOTAL)).attr('height', styles.tSize)
        .attr('fill', '#bcbcbc').style('opacity', .15)

        discipline
        .append('rect')
        .attr('transform', `translate(${cols.position[cols.last_idx]}, ${y_ - (styles.tSize/2) - 1})`)
        .attr('width', 0).attr('height', styles.tSize)
        .attr('stroke-width', 0.3).attr('stroke', cols.bColor).style('stroke-opacity', 1)
        .attr('fill', bar.bColor).style('fill-opacity', bar.opacity)
        .transition().duration(500).attr('width', barScale(d[cols.lastCode]))
       
        // ACTIVE ------------->
        discipline
        .append('text')
        .attr('id', `${cols.lastCode}_${i}`)
        .attr('transform', `translate(${ cols.position[cols.last_idx]}, ${y_})`)
        .attr('text-anchor', 'start')
        .style('font-size', styles.tSize)
        .style('fill', cols.tColor[cols.last_idx])
        .attr('alignment-baseline', 'middle')
        .text(()=> { if(d[cols.lastCode] > 0) return d[cols.lastCode] })
        .call(this.call_LINK, {
          code      : cols.lastCode,
          idx       : i, 
          colno     : cols.last_idx,
          jsonLink  : cols.jsonLink[cols.last_idx],
          x         : cols.position[cols.last_idx] + barScale(d[cols.lastCode]) + 2, 
          y         : y_, 
          tColor    : cols.tColor[cols.last_idx],
          align     : cols.align[cols.last_idx],
          filter    : cols.filter[cols.last_idx],
        })
        .style('cursor', cols.jsonLink[cols.last_idx] !== '' ? 'pointer' : 'default')
        .transition().duration(500)
        .attr('transform', `translate(${cols.position[cols.last_idx] + barScale(d[cols.lastCode]) + 2}, ${y_})`)
        
        y_ = y_ + styles.lineHight
      })
      
      
    },

  }
}