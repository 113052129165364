import * as d3 from 'd3'
import TimelineDeclares from './Timeline_Declares.mixin'

export default {
  data: () => ({
    axisBase: null,
    basicAxisGroup: null,
  }),
  mixins: [TimelineDeclares],
  methods: {
    // valueObject: { selection, TimelineX, TimelineY, TimelineWidth }
    drawTimeline(valueObject) {
      if (this.DataItems.length == 0) return

      this.setTimelineArea(valueObject)
      this.setScaleTimelineInfo()
      this.setScaleTimelineTicks()

      this.drawTimelineBase()
      this.drawTimelineMonth()
      this.drawTimelineYear()
      this.drawTimelineTicks()
    },
    drawTimelineBase() {
      // 'TimelineWrapperDay' should be drawn to provide its height for 
      // calculating the Timeline-Month&Year's Y pos.
      let TimelineWrapperDW = this.timelineArea
        .append('g')
        .attr('id', `timeline_wrapper_day___${this.localId}`)

      // A Path is drawn to be the Axis-Base-Timeline instead of the real one.
      // The real Axis-Base-Timeline cannot be used for, because of that the 
      // paddings in the linear scale is unavailable, and therefore the paddings
      // should manually be invoked to the the real Axis-Base-Timeline including
      // some space on the line as much as the length of the paddings applied.
      // And then, a Path for the Axis-Base-Timeline will manually be drawn.
      TimelineWrapperDW
        .append('path')
        .attr('d', `M 0 0 H ${this.timelineAreaWidth}`)
        .style('stroke', this.Timeline.TimelineLineColor)
        .style('stroke-width', this.Timeline.TimelineLineStroke)

      this.axisBase = d3.axisBottom(this.scaleTimelineInfo.scale)

      this.axisBase
      .tickValues(this.scaleTimelineInfo.base.tickValues)
      .tickFormat(d => d3.timeFormat('%d')(d))
      .tickSize(this.Timeline.TimelineType == 'TL-A' ? 0 : this.Timeline.TimelineWeekLineHeight)
      .tickPadding(this.Timeline.TimelineWeekLineSpace - 2)

      TimelineWrapperDW
        .append('g')
        .attr('class', `timeline_day_axis___${this.localId}`)
        .attr('transform', `translate(0,${this.getTimelineYPos('day')})`)
        .call(this.axisBase)

      this.basicAxisGroup = TimelineWrapperDW

      // Hence the real Axis-Base-Timeline will be hidden.
      this.svg.select(`.timeline_day_axis___${this.localId} > path`).style('display', 'none')

      this.svg.selectAll(`.timeline_day_axis___${this.localId} > .tick > line`)
        .style('stroke', this.Timeline.TimelineWeekLineColor)
        .style('stroke-width', this.Timeline.TimelineWeekLineStroke)

      // Timeline-Base-Text -------------------
      if (this.Timeline.TimelineWeekTextDisplay != 'Y') {
        this.svg.selectAll(`.timeline_day_axis___${this.localId} > .tick > text`).style('display', 'none')
        return
      }

      this.svg.select(`.timeline_day_axis___${this.localId}`)
        .style('text-anchor', 'middle')

      this.svg.selectAll(`.timeline_day_axis___${this.localId} > .tick > text`)
        .attr('class', this.Timeline.TimelineWeekTextStyle)
        .style('font-family', this.Timeline.TimelineWeekTextFont)
        .style('font-size', this.Timeline.TimelineWeekTextSize)
        .style('fill', this.Timeline.TimelineWeekTextColor)
    },
    drawTimelineMonth() {
      // 'timelineWrapperMonth' should be drawn to provide its height for 
      // calculating the Timeline-Year's Y pos. No metter the 'timelineMonthLineOnoff' 
      // is 'Y' or 'N', it should be existed at the time of drawing the Timeline-Year.
      let timelineWrapperMonth = this.timelineArea
        .append('g')
        .attr('transform', `translate(0,${this.getTimelineYPos('month')})`)
        .attr('id', `timeline_wrapper_month___${this.localId}`)

      if (this.Timeline.TimelineMonthDisplay != 'Y') return

      // Draw horizontal line for the months
      if (this.Timeline.TimelineType == 'TL-B' && this.Timeline.TimelineMonthLineDisplay == 'Y') {
        timelineWrapperMonth
          .append('g')
          .selectAll('path')
          .data(this.scaleTimelineInfo.month.tickValues)
          .enter()
          .append('path')
          .attr('class', '_timeline_wrapper_month__line__')
          .attr('d', d => `M ${this.scaleTimelineInfo.scale(d.start)} 0 H ${this.scaleTimelineInfo.scale(d.end) + 1}`)
          .style('stroke', this.Timeline.TimelineMonthLineColor)
          .style('stroke-width', this.Timeline.TimelineMonthLineStroke)
      }

      let axisMonth_ = d3.axisBottom(this.scaleTimelineInfo.scale)
        .tickValues(this.scaleTimelineInfo.month.tickValues.map(v => v.center))
        .tickFormat(d => d3.timeFormat('%b')(d))
        .tickSize(this.Timeline.TimelineType == 'TL-A' ? 0 : this.Timeline.TimelineMonthLineHeight)
        .tickPadding(this.Timeline.TimelineMonthLineSpace + 1)

      timelineWrapperMonth
        .append('g')
        .attr('class', `x-axis month-axis___${this.localId}`)
        .attr('transform', `translate(0,${this.Timeline.TimelineMonthLineStroke})`)
        .call(axisMonth_)

      // Axis-Base(Timeline) is only the line of the axis-X(Timeline),
      // so, other lines of the axis on the timeline will not be drew.
      this.svg.select(`.month-axis___${this.localId} > path`).style('display', 'none')

      // Reset Default Tick Styles
      this.svg.selectAll(`.month-axis___${this.localId} > .tick > line`)
        .style('stroke', this.Timeline.TimelineMonthLineColor)
        .style('stroke-width', this.Timeline.TimelineMonthLineStroke)

      // Timeline-Month-Text -------------------
      this.svg.select(`.month-axis___${this.localId}`)
        .style('text-anchor', 'middle')
        .style('font-weight', 'bold')
        .style('text-transform', 'uppercase')

      this.svg.selectAll(`.month-axis___${this.localId} > .tick > text`)
        .attr('class', this.Timeline.TimelineMonthTextStyle)
        .style('font-family', this.Timeline.TimelineMonthTextFont)
        .style('font-size', this.Timeline.TimelineMonthTextSize)
        .style('fill', this.Timeline.TimelineMonthTextColor)
    },
    drawTimelineYear() {
      let timelineWrapperYear = this.timelineArea
        .append('g')
        .attr('transform', `translate(0,${this.getTimelineYPos('year')})`)
        .attr('class', `timeline-wrapper-year___${this.localId}`)

      if (this.Timeline.TimelineYearDisplay != 'Y') return

      // Draw horizontal line for the months
      if (this.Timeline.TimelineType == 'TL-B' && this.Timeline.TimelineYearLineDisplay == 'Y') {
        timelineWrapperYear
          .append('g')
          .selectAll('path')
          .data(this.scaleTimelineInfo.year.tickValues)
          .enter()
          .append('path')
          .attr('d', d => `M ${this.scaleTimelineInfo.scale(d.start)} 0 H ${(this.scaleTimelineInfo.scale(d.end))}`)
          .style('stroke', this.Timeline.TimelineYearLineColor)
          .style('stroke-width', this.Timeline.TimelineYearLineStroke)
      }

      let axisYear_ = d3.axisBottom(this.scaleTimelineInfo.scale)
        .tickValues(this.scaleTimelineInfo.year.tickValues.map(v => v.center))
        .tickFormat(d => d3.timeFormat('%Y')(d))
        .tickSize(this.Timeline.TimelineType == 'TL-A' ? 0 : this.Timeline.TimelineYearLineHeight)
        .tickPadding(this.Timeline.TimelineYearLineSpace + 1)

      timelineWrapperYear
        .append('g')
        .attr('class', `x-axis year-axis___${this.localId}`)
        .attr('transform', `translate(0,${this.Timeline.TimelineYearLineStroke})`)
        .call(axisYear_)

      // Axis-Base(Timeline) is only the line of the axis-X(Timeline),
      // so, other lines of the axis on the timeline will not be drew.
      this.svg.select(`.year-axis___${this.localId} > path`).style('display', 'none')

      // Reset Default Tick Styles
      this.svg.selectAll(`.year-axis___${this.localId} > .tick > line`)
        .style('stroke', this.Timeline.TimelineYearLineColor)
        .style('stroke-width', this.Timeline.TimelineYearLineStroke)


      // Timeline-Month-Text -------------------

      this.svg.select(`.year-axis___${this.localId}`)
        .style('text-anchor', 'middle')
        .style('font-weight', 'bold')
        .style('text-transform', 'uppercase')

      this.svg.selectAll(`.year-axis___${this.localId} > .tick > text`)
        .attr('class', this.Timeline.TimelineYearTextStyle)
        .style('font-family', this.Timeline.TimelineYearTextFont)
        .style('font-size', this.Timeline.TimelineYearTextSize)
        .style('fill', this.Timeline.TimelineYearTextColor)
    },
    drawTimelineTicks() {
      if (this.Timeline.TimelineType != 'TL-A') return

      this.drawTimelineTickBase()
      this.drawTimelineTickMonth()
      this.drawTimelineTickYear()
    },
    drawTimelineTickBase() {
      let axisTickBase_ = d3.axisBottom(this.scaleTimelineTicks.scale)
        .tickValues(this.scaleTimelineTicks.base.tickValues)
        .tickSize(this.Timeline.TimelineWeekLineHeight)

      this.timelineTickArea
        .append('g')
        .attr('class', `timeline-wrapper-tick-dw___${this.localId}`)
        .attr('transform', `translate(0,0)`)
        .call(axisTickBase_)

      this.svg.select(`.timeline-wrapper-tick-dw___${this.localId} > path`).style('display', 'none')
      this.svg.selectAll(`.timeline-wrapper-tick-dw___${this.localId} > .tick > text`).style('display', 'none')
      this.svg.selectAll(`.timeline-wrapper-tick-dw___${this.localId} > .tick > line`)
        .style('stroke', this.Timeline.TimelineWeekLineColor)
        .style('stroke-width', this.Timeline.TimelineWeekLineStroke)
    },
    drawTimelineTickMonth() {
      if (this.Timeline.TimelineMonthDisplay != 'Y') return

      let axisTickMonth_ = d3.axisBottom(this.scaleTimelineTicks.scale)
        .tickValues(this.scaleTimelineTicks.month.tickValues)
        .tickSize(this.Timeline.TimelineMonthLineHeight)

      this.timelineTickArea
        .append('g')
        .attr('class', `timeline-wrapper-tick-month___${this.localId}`)
        .attr('transform', `translate(0,${this.getTimelineTickYPos('month')})`)
        .call(axisTickMonth_)

      this.svg.select(`.timeline-wrapper-tick-month___${this.localId} > path`).style('display', 'none')
      this.svg.selectAll(`.timeline-wrapper-tick-month___${this.localId} > .tick > text`).style('display', 'none')
      this.svg.selectAll(`.timeline-wrapper-tick-month___${this.localId} > .tick > line`)
        .style('stroke', this.Timeline.TimelineMonthLineColor)
        .style('stroke-width', this.Timeline.TimelineMonthLineStroke)
    },
    drawTimelineTickYear() {
      if (this.Timeline.TimelineYearDisplay != 'Y') return

      let axisTickYear_ = d3.axisBottom(this.scaleTimelineTicks.scale)
        .tickValues(this.scaleTimelineTicks.year.tickValues)
        .tickSize(this.Timeline.TimelineYearLineHeight)

      this.timelineTickArea
        .append('g')
        .attr('class', `timeline-wrapper-tick-year___${this.localId}`)
        .attr('transform', `translate(0,${this.getTimelineTickYPos('year')})`)
        .call(axisTickYear_)

      this.svg.select(`.timeline-wrapper-tick-year___${this.localId} > path`).style('display', 'none')
      this.svg.selectAll(`.timeline-wrapper-tick-year___${this.localId} > .tick > text`).style('display', 'none')
      this.svg.selectAll(`.timeline-wrapper-tick-year___${this.localId} > .tick > line`)
        .style('stroke', this.Timeline.TimelineYearLineColor)
        .style('stroke-width', this.Timeline.TimelineYearLineStroke)
    },
  }
}