import Api from './api'
import Url from './request.url'

export default class SystemApi extends Api {

  constructor() {
    super()
  }

  getMembers(permitCode, callback) {
    this.axios().get(Url.service.control.members + '/' + permitCode).then(res => callback(res.data))
  }
  getBPMembers(permitCode, callback) {
    this.axios().get(Url.service.control.bpmembers + '/' + permitCode).then(res => callback(res.data))
  }
  getMember(memberID, callback) {
    this.axios().get(Url.service.control.member + '/' + memberID).then(res => callback(res.data))
  }
  putMember(member, callback) {
    this.axios().put(Url.service.control.member, member).then(res => callback(res))
  }
  putAdminMember(member, callback) {
    this.axios().put(Url.service.control.memberAdmin, member).then(res => callback(res))
  }
  updMember(member, callback) {
    return this.axios().post(Url.service.control.member, member).then(res => callback(res))
  }
  updAdminMember(member, callback) {
    return this.axios().post(Url.service.control.memberAdmin, member).then(res => callback(res))
  }
  delMember(sn, callback) {
    this.axios().delete(Url.service.control.member + '/' + sn).then(res => callback(res))
  }
  getUpdateItems(callback) {
    this.axios().get(Url.service.control.updateItems).then(res => callback(res))
  }

  putUpdateItems(upItems, callback) {
    this.axios().put(Url.service.control.putUpItems, upItems).then(res => callback(res))
  }

  editUpdateItems(upItems, callback) {
    this.axios().post(Url.service.control.editUpItems, upItems).then(res => callback(res))
  }

  delUpdateItems(upItems, callback) {
    this.axios().post(Url.service.control.delUpItems, upItems).then(res => callback(res))
  }

  getWorkorders() {
    return new Promise(resolve => {
      this.axios().get(Url.service.control.Workorders).then(res => {
        resolve(res)
      })
    })
  }
  getWorkorder(idx) {
    return new Promise(resolve => {
      this.axios().get(`${Url.service.control.Workorder}/${idx}`).then(res => {
        resolve(res)
      })
    })
  }
  putWorkorder(params) {
    return new Promise(resolve => {
      this.axios().put(Url.service.control.Workorder, params).then(res => {
        resolve(res)
      })
    })
  }
  updWorkorder(params) {
    return new Promise(resolve => {
      this.axios().post(Url.service.control.Workorder, params).then(res => {
        resolve(res)
      })
    })
  }
  delWorkorder(idx) {
    return new Promise(resolve => {
      this.axios().delete(`${Url.service.control.Workorder}/${idx}`).then(res => {
        resolve(res)
      })
    })
  }
  
  getOtpSecret(memberID, callback) {
    return this.axios().get(Url.service.control.getOtpSecret + '/' + memberID).then(res => callback(res.data))
  }
  //params = {userName: userName, otpCode: otpCode}
  getOtpVerify(params, callback) {
    return this.axios().post(Url.service.control.getOtpVerify , params).then(res => callback(res.data))
  }
  regOtpSecret(params, callback) {
    return this.axios().post(Url.service.control.regOtpSecret, params).then(res => callback(res.data))
  }
  updOtpSecret(params, callback) {
    return this.axios().post(Url.service.control.updOtpSecret, params).then(res => callback(res.data))
  }
  delOtpSecret(memberID, callback) {
    return this.axios().get(Url.service.control.delOtpSecret + '/' + memberID ).then(res => callback(res.data))
  }
}

