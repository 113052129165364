import __C from '../../primitives/_constant_'
import Api from './api'
import Url from './request.url'

export default class ApplicationApi extends Api {

  constructor() {
    super()
  }

  getComments(refCode) {
    return new Promise(resolve => {
      this.axios().get(`${Url.communicate.comments}/${refCode}`).then(res => { resolve(res.data) })
    })
  }
  putComment(params, config) {
    return new Promise(resolve => {
      this.axios().put(Url.communicate.comment, params, config).then(res => { resolve(res.data) })
    })
  }
  editComment(params, config) {
    return new Promise(resolve => {
      this.axios().post(Url.communicate.editcomment, params, config).then(res => { resolve(res.data) })
    })
  }
  delComment(idx) {
    return new Promise(resolve => {
      this.axios().delete(`${Url.communicate.comment}/${idx}`).then(res => { resolve(res.data) })
    })
  }
  
  getSubsystemComments(subsystem) {
    return new Promise(resolve => {
      this.axios().get(`${Url.subsystemcomment.comments}/${subsystem}`).then(res => { 
        resolve(res.data) 
      })
    })
  }
  putSubsystemComment(params, config) {
    return new Promise(resolve => {
      this.axios().put(Url.subsystemcomment.comment, params, config).then(res => { resolve(res.data) })
    })
  }
  editSubsystemComment(params, config) {
    return new Promise(resolve => {
      this.axios().post(Url.subsystemcomment.editcomment, params, config).then(res => { resolve(res.data) })
    })
  }
  delSubsystemComment(idx) {
    return new Promise(resolve => {
      this.axios().delete(`${Url.subsystemcomment.comment}/${idx}`).then(res => { resolve(res.data) })
    })
  }
}