<template>
  <j-overlay 
    v-model="opened" 
    content-right
    class="service_board"
    style="z-index: 6;"
    :id="localId"
  >
  </j-overlay>
</template>

<script>
import __M from 'moment'
import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
export default {
   name: 'j-modal-slide-component---comments',
   mixins: [
     SafeIdMixin
   ],
   props: {
     value: null
   },
   data: () => ({

   }),
   computed: {
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val)},
    }     
   },
   created() {
     this.localId = this.id || 'j-comments__' + this.safeId('')
   }
}
</script>