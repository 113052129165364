
import __F from '@/primitives/_function_'
import xlsx from 'xlsx-populate'
import gradientmap from '../svgchartlib/includes/primitives/Color_Data'
import Url from '../../../src/services/api/request.url'

export default {
  data: () => ({
    colIndexMap: [
      'A' , 'B' , 'C' , 'D' , 'E' , 'F' , 'G' , 'H' , 'I' , 'J' , 'K' , 'L' , 'M' , 'N' , 'O' , 'P' , 'Q' , 'R' , 'S' , 'T' , 'U' , 'V' , 'W' , 'X' , 'Y' , 'Z' ,
      'AA', 'AB', 'AC', 'AD', 'AE', 'AF', 'AG', 'AH', 'AI', 'AJ', 'AK', 'AL', 'AM', 'AN', 'AO', 'AP', 'AQ', 'AR', 'AS', 'AT', 'AU', 'AV', 'AW', 'AX', 'AY', 'AZ',
      'BA', 'BB', 'BC', 'BD', 'BE', 'BF', 'BG', 'BH', 'BI', 'BJ', 'BK', 'BL', 'BM', 'BN', 'BO', 'BP', 'BQ', 'BR', 'BS', 'BT', 'BU', 'BV', 'BW', 'BX', 'BY', 'BZ',
      'CA', 'CB', 'CC', 'CD', 'CE', 'CF', 'CG', 'CH', 'CI', 'CJ', 'CK', 'CL', 'CM', 'CN', 'CO', 'CP', 'CQ', 'CR', 'CS', 'CT', 'CU', 'CV', 'CW', 'CX', 'CY', 'CZ',
      'DA', 'DB', 'DC', 'DD', 'DE', 'DF', 'DG', 'DH', 'DI', 'DJ', 'DK', 'DL', 'DM', 'DN', 'DO', 'DP', 'DQ', 'DR', 'DS', 'DT', 'DU', 'DV', 'DW', 'DX', 'DY', 'DZ',
      'EA', 'EB', 'EC', 'ED', 'EE', 'EF', 'EG', 'EH', 'EI', 'EJ', 'EK', 'EL', 'EM', 'EN', 'EO', 'EP', 'EQ', 'ER', 'ES', 'ET', 'EU', 'EV', 'EW', 'EX', 'EY', 'EZ',
      'FA', 'FB', 'FC', 'FD', 'FE', 'FF', 'FG', 'FH', 'FI', 'FJ', 'FK', 'FL', 'FM', 'FN', 'FO', 'FP', 'FQ', 'FR', 'FS', 'FT', 'FU', 'FV', 'FW', 'FX', 'FY', 'FZ',
      'GA', 'GB', 'GC', 'GD', 'GE', 'GF', 'GG', 'GH', 'GI', 'GJ', 'GK', 'GL', 'GM', 'GN', 'GO', 'GP', 'GQ', 'GR', 'GS', 'GT', 'GU', 'GV', 'GW', 'GX', 'GY', 'GZ',
      'HA', 'HB', 'HC', 'HD', 'HE', 'HF', 'HG', 'HH', 'HI', 'HJ', 'HK', 'HL', 'HM', 'HN', 'HO', 'HP', 'HQ', 'HR', 'HS', 'HT', 'HU', 'HV', 'HW', 'HX', 'HY', 'HZ',
    ],

    currentRow: 0,
    currentSheet: null,
    tableStartRow: 0,
  }),
  methods: {
    // dataSource: {
    //   title: Object,
    //   legend: Object,
    //   summary: Object,
    //   items: Object
    // }
    // async toxlsx(dataSource) {
    //   let workbook = await xlsx.fromBlankAsync()

    //   this.currentRow = 0
    //   this.currentSheet = workbook.sheet(0)
    //   this.currentSheet.gridLinesVisible(false)

    //   this.setSheetTitle(dataSource.title, dataSource.legend)
    //   this.setSheetHeader()
    //   // this.setSheetRowsDefault(this.summaries, dataSource.items || this.items)
    //   this.setSheetRowsDefault(this.summaries, this.items)
    //   this.setSheetSummaryRows(this.summaries)
    //   // this.setSheetBodyRows(dataSource.items || this.items)
    //   this.setSheetBodyRows(this.items)

    //   this.postDrawing()

    //   return workbook.outputAsync()
    // },
    async toxlsx(idx, filter, ifilter, pageData, type) {
      
      let params = new URLSearchParams()
      params.append('idx', String(idx))    // menu-item or sub-item's idx
      params.append('fValues', filter)
      params.append('iFilter', ifilter)
      params.append('easyFormedHeaders',JSON.stringify(this.easyFormedHeaders))
      params.append('pagination', JSON.stringify({page:1,rowsPerPage:2500,reset:1}))
      params.append('requestType', type)
      
      var iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      iframe.src = `${Url.fetch.excel.get}?${params.toString()}`
      
    },
    setSheetTitle(_title, _legend) {
      this.currentRow = 2

      // main & sub title
      let richText_ = new xlsx.RichText()
      richText_.add(`${_title.main.text}`, { 
        fontSize: _title.main.fontSize 
      })
      richText_.add('   ')
      richText_.add(_title.sub.text, { 
        fontColor: _title.sub.color.replace('#', ''),
        fontSize: _title.sub.fontSize 
      })
      this.currentSheet.row(this.currentRow).cell(2).value(richText_)

      // Query Descriptions
      let desc_ = _title.queries ? (
            (_title.queries._1st || '') +
            (_title.queries._2nd || '') +
            (_title.queries._3rd || '') +
            (_title.queries._4th || '') +
            (_title.queries._5th || '')
          ) : ''

      if(desc_) {
        this.currentRow++

        // descriptions from the queries
        // styles for the description
        let style_ = _title.query ? {
          fontColor: _title.query.color.replace('#', ''),
          fontSize: _title.query.fontSize
        } : {}

        let lines_ = (desc_.match(/\r?\n/g)||[]).length
        if(lines_ > 0) this.currentSheet.row(this.currentRow).height(_title.query.fontSize * lines_ * 2)

        this.currentSheet
        .range(`B${this.currentRow}:${this.colIndexMap[19]}${this.currentRow}`).merged(true)
        .style(style_)
        .value(new xlsx.RichText().add(desc_))
      }

      // Note
      if(_title.note && _title.note.text) {
        this.currentRow++

        let style_ = {
          fontColor: _title.note.color.replace('#', ''),
          fontSize: _title.note.fontSize
        }

        let lines_ = (_title.note.text.match(/\r?\n/g)||[]).length
        if(lines_ > 0) this.currentSheet.row(this.currentRow).height(_title.note.fontSize * lines_* 2)
        
        this.currentSheet
        .range(`B${this.currentRow}:${this.colIndexMap[19]}${this.currentRow}`).merged(true)
        .style(style_)
        .value(new xlsx.RichText().add(_title.note.text))
      }
    },
    setSheetHeader() {
      let headers_ = JSON.parse(JSON.stringify(this.siftedHeaders))
      let headerProperties = this.setHeaderProperites(headers_)

      // remove last empty column
      if(headerProperties[headerProperties.length-1].type == 'spacer') headerProperties.pop()

      // calculate & set the cell start position to set the range of 
      // the cells with colspan and rowspan
      this.setSheetHeaderColStart(headerProperties)

      let headerDepth = this.getHeaderDepth(headerProperties)
      let flattened_ = this.flatten(headerProperties, null, headerDepth).filter(h => !!h.width)
      let indices = [...Array(headerDepth).keys()]

      // set header's each column width
      flattened_.forEach((c, i) => {
        this.currentSheet.column(this.colIndexMap[i+1]).width(c.width/6.5)
      })

      this.tableStartRow = this.currentRow + 1
      // set header's each row height + only the text align
      indices.forEach(i => {
        this.currentRow++

        if(this.tableAttrs.header.styles[i]) 
          this.currentSheet
          .row(this.currentRow)
          .height(Number(this.tableAttrs.header.styles[i].height) * .8)
          .style({
            horizontalAlignment: 'center',
            verticalAlignment: 'center'
          })

        // set header cells
        this.setSheetHeaderCells(this.extractHeaderCellData(headerProperties, i), this.currentRow)
      })
    },
    setSheetHeaderColStart(props, colstart=1) {
      // colstart => parent's colstart
      props.forEach(h => {
        h.colstart = colstart
        colstart += h.colspan

        if(h.type == 'group') this.setSheetHeaderColStart(h.children, h.colstart)
      })
    },
    extractHeaderCellData(props, depth, data=[]) {
      props.forEach(h => {
        if(h.trindex === depth) data.push(h)
        if(h.type == 'group') this.extractHeaderCellData(h.children, depth, data)
      })
      return data
    },
    setSheetHeaderCells(data, rowIndex) {
      data.forEach(h => {
        this.setSheetHeaderCell(h, rowIndex)
      })
    },
    setSheetHeaderCell(h, rowIndex) {
      let style_ = {}

      // Check & Set Style -----------------------
      if(this.tableAttrs.header.styles[h.trindex]) {
        let reqStyles_ = this.tableAttrs.header.styles[h.trindex]

        // ### set fill color
        if(gradientmap.setGradients[reqStyles_.background.gradient]) {
          // reqStyles_.background.gradient (gradient type name)                      : LinearA2 | LinearA4 | LinearA9
          // reqStyles_.background.color (gradient style name, represented by color)  : LightGray | Gray | Yellow | ...

          let gradientFunc_ = gradientmap.setGradients[reqStyles_.background.gradient]

          style_.fill = { 
            type: 'gradient', 
            stops: Array.from({ length: gradientmap.setGradients.Length[reqStyles_.background.gradient] }, (_, i) => ({ 
              position: gradientFunc_('Offset', i), 
              color: __F.rgba2hex(__F.hex2rgba(gradientFunc_(reqStyles_.background.color, i), reqStyles_.background.opacity), true).hex.replace('#', '')
            })), 
            angle: [90]
          }
        } else {
          // reqStyles_.background.color : hexadecimal

          style_.fill = {
            type: 'solid', 
            rgb: reqStyles_.background.color.replace('#', '')
          }
        }
        
        // ### set font style
        reqStyles_.fontStyle.split(' ').forEach((s, i) => {
          if(s == 'bold') style_.bold = true
          if(s == 'italic') style_.italic = true
        })
        style_.fontSize = reqStyles_.fontSize
        style_.fontColor = reqStyles_.tColor.replace('#', '')

        // ### set border(s)
        style_.leftBorder = true
        style_.bottomBorder = true

        // if(h.trindex !== 0 && h.siblingPosition == h.siblings) {
        if(h.trindex !== 0 && h.siblingPosition === 1) {
          let index_ = this.getLineBlockIndex(h.parentPosition)
          let parStyles_ = index_ < 0 ? reqStyles_ : this.tableAttrs.header.styles[index_]
          style_.leftBorderColor = __F.rgba2hex(parStyles_.border.color, true).hex.replace('#', '')

        } else if(h.siblingPosition !== 1) 
          style_.leftBorderColor = __F.rgba2hex(reqStyles_.border.color, true).hex.replace('#', '')

        if(Number(h.trindex) + Number(h.rowspan) < this.maxDepth) 
          style_.bottomBorderColor = __F.rgba2hex(reqStyles_.border.color, true).hex.replace('#', '')
      }

      // Set style for the theader-bottom-border
      if(Number(h.trindex) + Number(h.rowspan) == this.maxDepth)
        style_.bottomBorderColor = __F.rgba2hex(this.tableAttrs.header.border.color, true).hex.replace('#', '')

      this.currentSheet
      .range(`${this.colIndexMap[h.colstart]}${rowIndex}:${this.colIndexMap[h.colstart+h.colspan-1]}${rowIndex+h.rowspan-1}`).merged(true)
      .style(style_)
      .value(h.text)
    },
    setSheetRowsDefault(dataSummary, dataItems) {
      // Not increased currentRow here
      // Use local variable instead of this.currentRow
      let currentRow___ = this.currentRow
      currentRow___++

      let totalRows = dataSummary.length + dataItems.length
      let colNames_ = this.getHeaderNames(this.siftedHeaders)
      if(!colNames_[colNames_.length-1]) colNames_.pop()

      // ### set style for the whole range 
      this.currentSheet
      .range(`B${currentRow___}:${this.colIndexMap[colNames_.length]}${currentRow___+totalRows-1}`)
      .style({ 
        border: true, 
        borderColor: 'efefef',
        verticalAlignment: 'center'
      })
      // ### set style for the whole column 
      colNames_.forEach((colName, i) => { 
        let columnInfo_ = this.easyFormedHeaders[colName]
        if(!columnInfo_) return

        this.currentSheet
        .range(`${this.colIndexMap[i+1]}${currentRow___}:${this.colIndexMap[i+1]}${currentRow___+totalRows-1}`)
        .style({ 
          horizontalAlignment: columnInfo_.alignRowValue || 'center'
        })
      })
      // ### set style for the summary rows 
      if( 
        dataSummary.length > 0 && 
        this.tableAttrs.body && 
        this.tableAttrs.body.summary && 
        Object.keys(this.tableAttrs.body.summary).length > 0
      ) {
        this.currentSheet
        .range(`B${currentRow___}:${this.colIndexMap[colNames_.length]}${currentRow___+dataSummary.length-1}`)
        .style({ 
          // fontFamily: this.tableAttrs.body.summary.font,
          fontSize: this.tableAttrs.body.summary.fontSize, 
          fontColor: this.tableAttrs.body.summary.color.replace('#', ''),
          fill: this.tableAttrs.body.summary.bColor.replace('#', '')
        })

        dataSummary.forEach((_, i) => {
          this.currentSheet
          .row(currentRow___+i)
          .height(this.tableAttrs.body.summary.height * .8)
        })
        
        // set last row's border-bottom
        this.currentSheet
        .row(currentRow___+dataSummary.length-1)
        .style({ 
          bottomBorderColor: __F.rgba2hex(this.tableAttrs.body.summary.border.color, true).hex.replace('#', '')
        })
      }
      // ### set style for the data rows 

    },
    setSheetSummaryRows(_data) {
      if(!_data || _data.length === 0) return
      
      let headerNames = this.getHeaderNames(this.siftedHeaders)
      let colNames = []
      _data.forEach(item => { colNames = Array.from(new Set([ ...colNames, ...Object.keys(item)])) })
      
      let indecies = colNames.map(k => headerNames.findIndex(h => h == k)).filter(index_ => index_ >= 0)
      // Find the first met column index which can be the length of the
      // label's space (colspan).
      let labelColspan = Math.min(...indecies)
      // get only the available column ranges for summary result
      let targetHeaderEls = headerNames.slice(Math.min(...indecies), Math.max(...indecies) + 1)

      _data.forEach(item => {
        this.currentRow++
        this.setSheetSummaryCellLabel(item, { headerNames, targetHeaderEls, labelColspan })

        targetHeaderEls.forEach((colName, i) => { 
          this.setSheetDataCell(item, colName, labelColspan+i+2) 
        })
      })
    },
    setSheetSummaryCellLabel(item, info) {
      let style_ = {}

      // Label TD at the start of the columns
      let colIndex_ = info.headerNames.findIndex(colName => colName == info.targetHeaderEls[0])
      if(colIndex_ > 0) style_ = this.getDataCellStyleBorder(info.headerNames[colIndex_-1], 'summary')

      // set range style
      this.currentSheet
      .range(`B${this.currentRow}:${this.colIndexMap[info.labelColspan]}${this.currentRow}`).merged(true)
      .style(style_)
      .value(item.text)
    },
    setSheetBodyRows(_data) {
      if(!_data || _data.length === 0) return

      let bodyProps_ = this.tableAttrs.body
      let cellProps_ = this.tableAttrs.body.cell
      let colNames_ = this.getHeaderNames(this.siftedHeaders)
      if(!colNames_[colNames_.length-1]) colNames_.pop()

      this.currentRow++

      // set range style
      this.currentSheet
      .range(`B${this.currentRow}:${this.colIndexMap[colNames_.length]}${this.currentRow+_data.length-1}`)
      .style({ 
        fontSize: cellProps_.fontSize,
        fontColor: cellProps_.color.replace('#', '')
      })
      // set cell data & style
      _data.forEach(item => {
        colNames_.forEach((colName, i) => { 
          // i+2 => start index(B instead of A) +1, zero start +1 = +2
          this.setSheetDataCell(item, colName, i+2) 
        })
        this.currentRow++
      })
    },
    setSheetDataCell(item, colName, colIndex) {
      let columnInfo_ = this.easyFormedHeaders[colName]
      if(!columnInfo_) {
        console.error(`[USER: "toxlxs"] Invalid column name, ${colName}`)
        return
      }

      let style_ = this.getDataCellStyleBorder(colName)
      let text_ = item[colName]
      
      if(this.styleAttrAvailable && columnInfo_.styleAttr) {
        let styleAttr = columnInfo_.styleAttr
        let colNameCode = (
          !columnInfo_.styleAttrData.code || columnInfo_.styleAttrData.code == 'self' ?
          colName :
          columnInfo_.styleAttrData.code
        )
        if(!item[colNameCode]) {
          console.log(`[USER: toxlsx: column code '${colNameCode}' undefined] Cannot find a column code to be matched with style attribute.`)

        } else if(!this.codeAttrAvailable('style', columnInfo_.styleAttr, item[colNameCode])) {
          console.log(`[USER: toxlsx: Not found] Cannot find style attribute for CODE-VALUE '${item[colNameCode]}'.`)

        } else {
          let codeAttr_ = this.tableAttrs.style[styleAttr].find(style => style.code == item[colNameCode])
          style_ = { ...style_, ...this.getDataCellStyleStyleAttr(item, colName, styleAttr, codeAttr_) }
        }
      } else if(this.svgAttrAvailable && columnInfo_.svgAttr) {
        let svgAttr = columnInfo_.svgAttr
        let colNameCode = (
          !columnInfo_.svgAttrData.code || columnInfo_.svgAttrData.code == 'self' ?
          colName :
          columnInfo_.svgAttrData.code
        )

        if(!item[colNameCode]) {
          console.log(`[USER: toxlsx: column code '${colNameCode}' undefined] cannot find a column code to be matched with svg style attribute.`)

        } else if(!this.codeAttrAvailable('svg', columnInfo_.svgAttr, item[colNameCode])) {
          console.log(`[USER: toxlsx: Not found] Cannot find SVG style attribute for CODE-VALUE '${item[colNameCode]}'.`)

        } else {
          let codeAttr_ = this.tableAttrs.svg[svgAttr].attrs.find(svg => svg.code == item[colNameCode])
          let svgProps = this.getDataCellStyleSvgAttr(item, colName, svgAttr, codeAttr_)
          style_ = { ...style_, ...svgProps.style }
          text_ = svgProps.value
        }
      } else {
        // 
      }

      this.currentSheet
      .row(this.currentRow)
      .cell(colIndex)
      .style(style_)
      .value(!text_ || columnInfo_.putValue === false ? '' : (columnInfo_.numFormat ? text_.toLocaleString() : text_))
    },
    // type: summary | cell
    getDataCellStyleBorder(colName, type='cell') {  
      let columnInfo_ = this.easyFormedHeaders[colName]
      let styles___ = this.tableAttrs.body[type].styles
      let style_ = {}

      if(!styles___[columnInfo_.depth]) return style_

      let reqStyles_ = styles___[columnInfo_.depth]

      if(columnInfo_.depth !== 0) {
        let rowspanSum_ = this.getRowspanSum(columnInfo_.parentPosition)
        if(columnInfo_.siblingPosition == columnInfo_.siblings) {
          let index_ = this.getLineBlockIndex(columnInfo_.parentPosition, 'right')
          let parStyles_ = index_ < 0 ? reqStyles_ : styles___[index_]
          style_.rightBorderColor = __F.rgba2hex(parStyles_.border.color, true).hex.replace('#', '')

        } else if(columnInfo_.depth != rowspanSum_) {
          let childStyles_ = !styles___[rowspanSum_] ? reqStyles_ : styles___[rowspanSum_]
          style_.rightBorderColor = __F.rgba2hex(childStyles_.border.color, true).hex.replace('#', '')

        } else 
          style_.rightBorderColor = __F.rgba2hex(reqStyles_.border.color, true).hex.replace('#', '')
          
      } else 
        style_.rightBorderColor = __F.rgba2hex(reqStyles_.border.color, true).hex.replace('#', '')

      return style_
    },
    getDataCellStyleStyleAttr(item, colName, styleAttr, attrs) {
      let style_ = {}
      
      if(!attrs) return style_

      let colNameValue = (
        !this.easyFormedHeaders[colName].styleAttrData.value ||
        this.easyFormedHeaders[colName].styleAttrData.value == 'self' ? 
        colName :
        this.easyFormedHeaders[colName].styleAttrData.value
      )

      if(styleAttr == 'cell') {
        let value_ = item[colNameValue] ? Math.round(item[colNameValue]) : 0
        style_.fill = {
          type: 'gradient',
          stops: this.getCellProgressStops(value_, attrs.bColor.replace('#', ''), 'ffffff')
        }
  
      } else if(styleAttr == 'color') {
        style_.fill = {
          type: 'solid',
          rgb: attrs.bColor ? attrs.bColor.replace('#', '') : 'ffffff'
        }
  
      } // else; for just the text style already be set at the parent.
      
      style_.fontColor = attrs.tColor.replace('#', '')
  
      return style_
    },
    getDataCellStyleSvgAttr(item, colName, svgAttr, attrs) {
      let style_ = {}  

      if(svgAttr == 'bar') {
        let colNameValue = (
          !this.easyFormedHeaders[colName].svgAttrData.value ||
          this.easyFormedHeaders[colName].svgAttrData.value == 'self' ? 
          colName :
          this.easyFormedHeaders[colName].svgAttrData.value
        )
        var value_ = item[colNameValue] ? Math.round(item[colNameValue]) : 0
        style_.fill = {
          type: 'gradient',
          stops: this.getCellProgressStops(value_, attrs.bColor.replace('#', ''), 'f5f5f5')
        }
        style_.fontColor = attrs.tColor.replace('#', '')
        style_.horizontalAlignment = this.tableAttrs.svg[svgAttr].textAlign || 'center'
      } else {
        // 
      }

      return {
        style: style_,
        value: value_ ? `${value_}%` : ''
      }
    },
    getCellProgressStops(value, forecolor, bgcolor) {
      let stops = Array.from({ length: 100 }, (_, i) => ({
        position: i / 100, 
        color: value <= i ? bgcolor : forecolor
      }))
      stops.push({ 
        position: 1, 
        color: value < 100 ? bgcolor : forecolor
      })
      return stops
    },
    postDrawing() {
      if(this.tableAttrs.table.border.applied != 'Y') return
      
      let currentRow___ = this.currentRow - 1
      let styleBorder_ = this.tableAttrs.table.border
      let colNames_ = this.getHeaderNames(this.siftedHeaders)
      if(!colNames_[colNames_.length-1]) colNames_.pop()

      this.currentSheet
      .range(`B${this.tableStartRow}:${this.colIndexMap[colNames_.length]}${this.tableStartRow}`)
      .style({ topBorder: true, topBorderColor: __F.rgba2hex(styleBorder_.top.color, true).hex.replace('#', '') })

      this.currentSheet
      .range(`${this.colIndexMap[colNames_.length]}${this.tableStartRow}:${this.colIndexMap[colNames_.length]}${currentRow___}`)
      .style({ rightBorder: true, rightBorderColor: __F.rgba2hex(styleBorder_.right.color, true).hex.replace('#', '') })

      this.currentSheet
      .range(`B${currentRow___}:${this.colIndexMap[colNames_.length]}${currentRow___}`)
      .style({ bottomBorder: true, bottomBorderColor: __F.rgba2hex(styleBorder_.bottom.color, true).hex.replace('#', '') })

      this.currentSheet
      .range(`B${this.tableStartRow}:B${currentRow___}`)
      .style({ leftBorder: true, leftBorderColor: __F.rgba2hex(styleBorder_.left.color, true).hex.replace('#', '') })
    }
  }
}