import * as d3 from 'd3'

export default {
  methods: {
    

    call_LINK(selection, idx_) {
      let d_ = {
          code      : this.columns.code[idx_],
          idx       : idx_,
          x         : this.tube.tickness + 25, 
          y         : [this.tube.scale(100), this.tube.scale(this.columns.values.PRO/2), this.tube.scale(this.columns.values.PRO + (100-this.columns.values.PRO)/2),],
          tColor    : this.columns.value.tColor[idx_],
          align     : 'end',
      }
      if (d_.jsonLink == '') return
      
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(100)
        .style('fill', '#EC407A')
        .style('cursor', 'pointer')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .style('fill', d_.tColor)
        .style('cursor', 'default')
      })
      .on('click', () => { 

        // if linkCol is exist, set dataSource
        let dataSource = {}
        if(this.columns.jsonMap.filter(el => el).length > 0){
          this.columns.jsonMap.forEach((d, i) => {
            if (d !== '') dataSource = {...dataSource, [d]: this.rawData[d_.idx][this.columns.code[i]]}
          })
        }

        
        // ********************************************************************************
        console.log('dataSource: ', dataSource)
        let filterTesting = d_.filter !== '' ? this.filters[d_.filter] : ''
        console.log('filterString_: ', filterTesting)
        // ********************************************************************************

        
        // Mapping the jsonLink(value) to JsonProps in A/G 
        let request_ = this.getRequestColumnProps(d_.jsonLink, dataSource, this.ColumnProps)
        if(!request_) return

        // set for filterString 
        let filterString_ = d_.filter !== '' ? this.filters[d_.filter] : ''
        

        // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
        if(request_.iFilters.filterString) {
          request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
        } else {
          request_.iFilters.filterString = filterString_||''
        }

        // combine filterString and inputFilter &&& emit request-action
        request_ = {...request_,
          filters : {
            ...this.FilterValues,
            ...request_.filters,
          },
          iFilters: { 
            filterString : request_.iFilters.filterString||'',
            inputFilter : request_.iFilters.inputFilter,},
        }
        this.$emit('request-action', request_)
      })
    }, 


 
    
  }
}