<template>
  <div>
    <label class="status_label" :class="checked" @click="onClicked">
      <span class="sign" :class="value">√</span>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    discCode: null,
    statusCode: null,
    value: null,
  },
  data: () => ({
    imgsrc: {
      ST_NOTSTARTED: ''
    }
  }),
  computed: {
    checked() {
      let classes = `${this.discCode}_${this.statusCode}`

      if(this.value) classes += ' checked'
      else classes += ' unchecked'

      return classes
    }
  },
  methods: {
    onClicked() {
      if(this.value) this.$emit('input', false)
      else this.$emit('input', true)
    },
  }
}
</script>

<style lang="stylus" scoped>
.status_label:hover {
  cursor: pointer;
}
.status_label {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;

  &.checked {
    .sign {
      display: initial;
      color:#fff;
      text-shadow: 1px 2px 2px rgba(60, 60, 60, 0.4);
      font-family:'Roboto', 'Consolas', 'Calibri';  

    }
  }
  &.unchecked {
    .sign {
      display: none;
    }
  }

  &.ME_DELIVERED {
    background: no-repeat url("~@/assets/img/status_color/DELIVERED.jpg");
  }
  &.ME_FAT {
    background: no-repeat url("~@/assets/img/status_color/FAT.jpg");
  }
  &.ME_INSTALLED {
    background: no-repeat url("~@/assets/img/status_color/INSTALLED.jpg");
  }
  &.ME_IRN {
    background: no-repeat url("~@/assets/img/status_color/IRN.jpg");
  }
  &.ME_NOTSTARTED_ME {
    background: no-repeat url("~@/assets/img/status_color/NOTSTARTED.jpg");
  }
  &.ME_PO {
    background: no-repeat url("~@/assets/img/status_color/PO.jpg");
  }
  &.ME_NULL {
    background: no-repeat url("~@/assets/img/status_color/NOTSTARTED.jpg"); // what?
  }
  &.ME_IN_DELIVERED {
    background: no-repeat url("~@/assets/img/status_color/IN_DELIVERED.jpg");
  }
  &.ME_IN_FAT {
    background: no-repeat url("~@/assets/img/status_color/IN_FAT.jpg");
  }
  &.ME_IN_INSTALLED {
    background: no-repeat url("~@/assets/img/status_color/IN_INSTALLED.jpg");
  }
  &.ME_IN_IRN {
    background: no-repeat url("~@/assets/img/status_color/IN_IRN.jpg");
  }
  &.ME_IN_PO {
    background: no-repeat url("~@/assets/img/status_color/IN_PO.jpg");
  }

  &.PI_ERECTED {
    background: no-repeat url("~@/assets/img/status_color/ERECTED.jpg");
  }
  &.PI_FABRICATED {
    background: no-repeat url("~@/assets/img/status_color/FABRICATED.jpg");
  }
  &.PI_NOTSTARTED_PI {
    background: no-repeat url("~@/assets/img/status_color/NOTSTARTED.jpg");
  }
  &.PI_PAINTED {
    background: no-repeat url("~@/assets/img/status_color/PAINTED.jpg");
  }
  &.PI_REINSTATED {
    background: no-repeat url("~@/assets/img/status_color/REINSTATED.jpg");
  }
  &.PI_RFT {
    background: no-repeat url("~@/assets/img/status_color/RFT.jpg");
  }
  &.PI_SUPPORTED {
    background: no-repeat url("~@/assets/img/status_color/SUPPORTED.jpg");
  }
  &.PI_WELDED {
    background: no-repeat url("~@/assets/img/status_color/WELDED.jpg");
  }
  &.PI_NULL {
    background: no-repeat url("~@/assets/img/status_color/NOTSTARTED.jpg");  // WHAT?
  }
  &.PI_IN_ERECTED {
    background: no-repeat url("~@/assets/img/status_color/IN_ERECTED.jpg");
  }
  &.PI_IN_FABRICATED {
    background: no-repeat url("~@/assets/img/status_color/IN_FABRICATED.jpg");
  }
  &.PI_IN_PAINTED {
    background: no-repeat url("~@/assets/img/status_color/IN_PAINTED.jpg");
  }
  &.PI_IN_REINSTATED {
    background: no-repeat url("~@/assets/img/status_color/IN_REINSTATED.jpg");
  }
  &.PI_IN_RFT {
    background: no-repeat url("~@/assets/img/status_color/IN_RFT.jpg");
  }
  &.PI_IN_SUPPORTED {
    background: no-repeat url("~@/assets/img/status_color/IN_SUPPORTED.jpg");
  }
  &.PI_IN_WELDED {
    background: no-repeat url("~@/assets/img/status_color/IN_WELDED.jpg");
  }

  &.ST_ASSEMBLY_AP {
    background: no-repeat url("~@/assets/img/status_color/ASSEMBLY_AP.jpg");
  }
  &.ST_ASSEMBLY_BP {
    background: no-repeat url("~@/assets/img/status_color/ASSEMBLY_BP.jpg");
  }
  &.ST_ERECTION {
    background: no-repeat url("~@/assets/img/status_color/ERECTION.jpg");
  }
  &.ST_FABRICATION {
    background: no-repeat url("~@/assets/img/status_color/FABRICATION.jpg");
  }
  &.ST_NOTSTARTED_ST {
    background: no-repeat url("~@/assets/img/status_color/NOTSTARTED.jpg");
  }
  &.ST_PAINTING {
    background: no-repeat url("~@/assets/img/status_color/PAINTING.jpg");
  }
  &.ST_NULL {
    background: no-repeat url("~@/assets/img/status_color/NOTSTARTED.jpg"); // what?
  }
  &.ST_IN_ASSEMBLY_AP {
    background: no-repeat url("~@/assets/img/status_color/IN_ASSEMBLY_AP.jpg");
  }
  &.ST_IN_ASSEMBLY_BP {
    background: no-repeat url("~@/assets/img/status_color/IN_ASSEMBLY_BP.jpg");
  }
  &.ST_IN_ERECTION {
    background: no-repeat url("~@/assets/img/status_color/IN_ERECTION.jpg");
  }
  &.ST_IN_FABRICATION {
    background: no-repeat url("~@/assets/img/status_color/IN_FABRICATION.jpg");
  }
  &.ST_IN_PAINTING {
    background: no-repeat url("~@/assets/img/status_color/IN_PAINTING.jpg");
  }
}

</style>
