import Defs from '../../../../../../includes/primitives/Color_Defs'

export default {
  data: () => ({
    seiStyle: {
      CircleBorder: {
        rx: 6.5,
        ry: 6.5,
        color: '#757575',
        bColor: '#efefef',
        stroke: .5,
        textSize: 8.5,
        padding: 1,
      },
      typeRect: {
        width: 40,
        height: 14,
        conFill: '#e9522f',
        conStroke: '#bc3718',
        jianliFill: '#69ad1f',
        jianliStroke: '#4f8614',
      },
      typeText: {
        fontFamily: 'roboto',
        fontFill: '#004D82',
        fontSize : 14,
        fontWeight: 700,
      }
    },

    headerStyle: {
      typeRect: {
        width: 170,
        height: 30,
        rx: 1,
        ry: 1,
        rectFill: '#fff',
        stroke: '#bc3718',
        strokeWidth: 1,
        unitTextFill: '#D92129',
        descTextFill: '#fff',
      },
      typeText: {
        fontFamily: 'roboto',
        fontSize : 16,
        fontWeight: 700,
      }
    },

    Toggle: {
      status: 'EN',
      labelWidth: 20,
      en: {
        bcolor: '#004D82',
        tcolor: '#fff',
        textx: 26,
        texty: 10,
        slidex: 51,
        slidey: 9,
      },
      cn: {
        bcolor: '#D92129',
        tcolor: '#fff',
        textx: 37,
        texty: 10,
        slidex: 29,
        slidey: 9,
      }
    }
  }),
  methods: {
    setStyles() {
      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)
      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
  }
}