<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import * as d3 from 'd3'
import __M from 'moment'

import SvgService       from '../../../../src/services/svg.service'
import QueryLibMixin    from '../../../../src/mixins/querylib.mixin'

import { SafeIdMixin }  from '../../../../includes/mixins/safeid.mixin'
import Timeline         from '../../../../includes/mixins/timeline.v2.mixin'

import Declares from './mixins/declares.mixin'

export default {
  name: 'k-chart-timeline-milestone-main',
  mixins: [
    QueryLibMixin,
    SafeIdMixin,
    Timeline,
    Declares,
  ],
  props: {
    id: String,
    cutoffFilter: String,
    items: {
      type: Array,
      default: () => ([
        {
          TASK:"Shipment5",
          EQ_DESC:"CAUSTICTOWER HPDEPROPANIZER",
          HH_PCS:2,
          LOGI_VENDOR:"SLTC",
          DUR_DAY:4,
          PLAN_ARR_DATE:"2022-11-10",
          PLAN_DEP_DATE:"2022-11-13",
          FC_ARR_DATE:"2022-12-05",
          FC_DEP_DATE:"2022-12-08",
          ACT_ARR_DATE:"2022-12-07",
          ACT_DEP_DATE:"2022-12-11"
        }
      ])
    },
    target: {
      type: Object,
      default: () => ({
        plan: {
          start: 'PLAN_ARR_DATE',
          end: 'PLAN_DEP_DATE'
        },
        forecast: {
          start: 'FC_ARR_DATE',
          end: 'FC_DEP_DATE'
        },
        actual: {
          start: 'ACT_ARR_DATE',
          end: 'ACT_DEP_DATE'
        },
      })
    },
    // milestone: {
    //   type: Object,
    //   default: () => ({
    //     typeColName: 'ACTIVITY_TYPE',
    //     typeCode: {
    //       'start' : 'Start_Milestone',
    //       'end'   : 'Finish_Milestone',
    //       'line'  : 'Task'
    //     }
    //   })
    // }
    milestone: null
  },
  data: () => ({
    svgService: null,

    svg: null,
    timelineArea: null,
    cutoff: null,
    pointerPath: 'M 0 0 L 10 0 L 10 10 L 14 10 L 5 20 L -4 10 L 0 10 Z',
  }),
  watch: {
    items: {
      handler(d) {
        if(!d) return
        this.draw()
      }
    }
  },
  created() {
    this.localId = `${this.id || 'k-chart-timeline-milestone-main'}__${this.safeId('')}`
    this.svgService = new SvgService()

    d3.selection.prototype.moveToFront = function() {
      return this.each(function(){
        this.parentNode.appendChild(this)
      })
    }
    d3.selection.prototype.moveToBack = function() {  
      return this.each(function() { 
          var firstChild = this.parentNode.firstChild; 
          if (firstChild) { 
              this.parentNode.insertBefore(this, firstChild); 
          } 
      })
    }
  },
  mounted() {
    this.predraw()
  },
  methods: {
    predraw() {
      // Using the Query-Library Service -----------------------------------
      // @@Usage
      //  1. import QueryLibMixin from '../../../mixins/querylib.mixin'
      //  2. mixins: [ QueryLibMixin ]
      //  3. Compose the script with the function "this.queryLib.getSqlQueryResult" 
      //
      // @@Parameters
      // {
      //   idx: Number,
      //   name: String,
      // }
      // - <idx>  should be the number type id indicated a SQL-Query.
      // - <name> (Name of the Query) could be an empty string but 
      //          recommended to notice.
      // ex)

      this.queryLib.getSqlQueryResult({ 
        idx: 0, 
        name: 'CutOff',
        filters: this.cutoffFilter
      }).then(result => {
        this.cutoff = result[0].CUTOFF + ' 23:59:59'
        this.draw()
      })
      // ------------------------------------------------------------------
    },
    draw() {
      if(this.svg) this.svg.remove()

      this.svg = d3.select(`#${this.localId}`)
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('version', '1.2')
      .attr('id', `${this.localId}_svg`)
      .attr('class', 'canvas')
      
      this.setDefaultValues()

      this.timelineArea = this.svg
      .append('g')
      .attr('id', 'timeline_wrapper')

      this.drawTimeline()

      let el = d3.select(`#${this.localId} #timeline_wrapper`).node()
      let elbox = el.getBoundingClientRect()

      this.timeline.width = Math.round(elbox.width) + 20
      this.timeline.height = Math.round(elbox.height) + 10
      
      this.svg.attr('width', this.timeline.width)
      this.svg.attr('height', this.timeline.height)

      this.$emit('complete', { 
        w: this.timeline.width,
        sdate: this.timeline.startDate,
        edate: this.timeline.endDate,
        cutoff: this.cutoff,
        weekday: this.timeline.weekday,
        target: this.target,
        milestone: this.milestone
      })

      this.drawTimelineBar()

      if(this.cutoff < this.timeline.startDate) return

      console.log(this.cutoff)

      // draw indicator ---------------------------
      let pointer = this.timelineArea
      .append('g')
      .attr('id', 'item_indicator')
      .attr('transform', `translate(${this.timeline.scale(__M(this.cutoff).toDate())-3}, 35) scale(.65)`)

      // shadow //
      pointer
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.12)')
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      pointer
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.1)')
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      pointer
      .append('g')
      .attr('id', 'item_indicator_shadow')
      .attr('transform', 'translate(0, 1) scale(1.07)')
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#000')
      .attr('fill-opacity', .08)

      // indicator //
      pointer
      .append('path')
      .attr('d', this.pointerPath)
      .attr('fill', '#fa2c72')
      .attr('stroke', '#970234')
      .attr('stroke-width', 1)
      // ------------------------------------------

      setTimeout(() => { this.toXmlString() }, 500)
    },
    drawTimeline() {
      this.timelineArea
      .append('g')
      .attr('class', '__year_text_wrapper')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 15).style('font-size', 15).style('fill', '#EC407A').attr('font-weight', 500)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())
      
      this.timelineArea
      .append('g')
      .attr('class', '__month_text_wrapper')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 30).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      this.timelineArea
      .append('g')
      .attr('class', '__week_text_wrapper')
      .selectAll('text')
      .data(this.timeline.week)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 45).style('font-size', 10).style('fill', '#333')
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(d => d.name.toUpperCase())

      let prev = {
        year: this.timeline.year[0].name,
        month: this.timeline.month[0].name,
      }

      // Timeline Seperator
      let tlSeparator = this.timelineArea
      .append('g')
      .attr('class', '__month_separator_wrapper')
      .attr('transform', `translate(.5, .5)`)

      this.timeline.week.forEach((d, i) => {
        if(i === this.timeline.week.length-1) return

        let monthName = d3.timeFormat('%b')(this.timeline.week[i+1].eDate)
        let yearName = d3.timeFormat('%Y')(this.timeline.week[i+1].eDate)
        let stroke = ''
        let path = ''

        if(prev.year != yearName) {
          prev.year = yearName
          prev.month = monthName
          stroke = '#757575'
          path = `M${Math.round(this.timeline.scale(d.eDate))}, 7 V 50`

        } else if(prev.month != monthName) {
          prev.month = monthName
          stroke = '#999'
          path = `M${Math.round(this.timeline.scale(d.eDate))}, 27 V 50`

        } else {
          stroke = '#bbb'
          path = `M${Math.round(this.timeline.scale(d.eDate))}, 38 V 50`
        }

        tlSeparator
        .append('path')
        .attr('d', path)
        .attr('stroke', stroke)
        .attr('stroke-width', 1)
      })
    },
    drawTimelineBar() {
      let bar = this.timelineArea
      .append('g')
      .attr('id', 'timeline_bar_wrapper')

      // bar
      // .append('path') // shadow
      // .attr('d', `M 50 ${this.timeline.height} H ${this.timeline.width-50} L ${this.timeline.width} ${this.timeline.height + 10} H 0 Z`)
      // .attr('fill', `url(#shadowTimeline)`) 

      bar
      .append('rect')
      .attr('transform', 'translate(0, 0)')
      .attr('width', this.timeline.width).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}__LinearA4LightBlue0)`)
      .attr('opacity', .5)

      bar
      .append('rect')
      .attr('transform', `translate(0, ${this.timeline.height})`)
      .attr('width', this.timeline.width).attr('height', 6)
      .attr('fill', `url(#${this.localId}__LinearA4LightBlue0)`)

      bar.moveToBack()
    },
    getMinMaxDate() {
      let dateSource = []
      let keys = Object.keys(this.target)

      this.items.forEach(d => {
        keys.forEach(k => {
          if(d[this.target[k].start]) dateSource.push(d[this.target[k].start])
          if(d[this.target[k].end]) dateSource.push(d[this.target[k].end])
        })
      })

      dateSource = dateSource.sort()

      return [dateSource[0], dateSource[dateSource.length-1]]
    },
    toXmlString() {
      let elString = this.svgService.dom2xmlString(d3.select(`#${this.localId}`))
      this.$emit('to-xml-string', {
        timeline: {
          xmlString: elString,
          width: this.timeline.width,
          height: this.timeline.height,
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.svg_wrapper {
  display: flex !important;
}
</style>