import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  dataSet: () => ({

  }),


  methods: {
    
    drawTapComponemt() {

      let taps = this.svg
      .append('g')
      .attr('id', `tapsGroup_${this.localId}`)
      .attr('transform', `translate(0, 0)`)

      // Tap Area
      taps
      .append('path')
      .attr('id', `tapsArea_${this.localId}`)
      .attr('fill', `url(#${this.localId}_depth_${this.colorIndex})`)
      .style('filter', `url(#tapsShadow)`)
      .on('mouseover', (_, i, a) => { 
        d3.select(a[i]).style('cursor', 'pointer')
        d3.select(`#tapsClose_${this.localId}`).attr('fill', `#fff`).attr('fill-opacity', 0.8)
      })
      .on('mouseout', (_, i, a) => { 
        d3.select(a[i]).style('cursor', 'default') 
        d3.select(`#tapsClose_${this.localId}`).attr('fill', `#004890`).attr('fill-opacity', 1)
      })
      .on('click', () => { this.$emit('close') })

      // Tap Tet
      taps
      .append('text')
      .attr('id', `tapsText_${this.localId}`)
      .attr('fill', '#fff') .style('font-size', 12).style('font-family', 'roboto').attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(this.label)
      .style('pointer-events', 'none')

      // Close Button
      taps
      .append('path')
      .attr('id', `tapsClose_${this.localId}`)
      .attr('transform', `translate(15,20)`).attr('fill', `#004890`)
      .attr('d', `M8.13,1.06L7.07,0L4.07,3.01L1.06,0L0,1.06l3.01,3.01L0,7.07l1.06,1.06l3.01-3.01l3.01,3.01 l1.06-1.06L5.13,4.07L8.13,1.06z`)
      .style('pointer-events', 'none')
      
      let textHeight = this.getNodeElValue(`#tapsText_${this.localId}`, 'width')
      d3.select(`#tapsArea_${this.localId}`)
      .attr('d', `M 30, ${0} c-8.3, 16.63 -25, 11.317-25, 35V ${35 + textHeight} c0, 23.449, 16.7, 18.137, 25, 35 Z`)

      d3.select(`#tapsText_${this.localId}`)
      .attr('transform', `translate(19, ${40 + textHeight}) rotate(-90)`)
      d3.select(`#tapsGroup_${this.localId}`).lower()

      let dimention = { 
        width: Math.ceil(this.getNodeElValue(`#tapsGroup_${this.localId}`, 'width')), 
        height: Math.ceil(this.getNodeElValue(`#tapsGroup_${this.localId}`, 'height'))
      }
      this.svg.attr('width', 30).attr('height', dimention.height)
      this.$emit('resize', { dimention })
    },
  }
}
