import '../../../../src/assets/stylus/ui/_button.styl'

export default {
  name: 'j-button',
  props: {},
  render() {
    let _attrs = {
      class: 'j_button',
      attrs: {
        type: 'button'
      },
      on: { click: () => this.$emit('click') }
    }
    return (
      <button {..._attrs} v-ripple>
        {this.$slots.default}
      </button>
    )
  }
}
