import __C from '../../primitives/_constant_'
import Api from './api'
import Url from './request.url'

export default class ApplicationApi extends Api {

  constructor() {
    super()
  }

  findMembersUnread(id) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.membersunread}/${id}`).then(res => { resolve(res.data) })
    })
  }
  findMessageMembers(id, name) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.members}/${id}/${name}`).then(res => { resolve(res.data) })
    })
  }
  getNotices(id, type) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.notices}/${id}/${type}`).then(res => { resolve(res.data) })
    })
  }
  getTalkingONs(id, chatType) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.onchats}/${id}/${chatType}`).then(res => { resolve(res.data) })
    })
  }
  get1To1Messages(chatNo, sender) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.one2one}/${chatNo}/${sender}`).then(res => { resolve(res.data) })
    })
  }
  getGroupMessages(chatNo, sender) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.group}/${chatNo}/${sender}`).then(res => { resolve(res.data) })
    })
  }
  getUnreadMessageNum(chatNo, sender) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.unreads}/${chatNo}/${sender}`).then(res => { resolve(res.data) })
    })
  }
  getUnreadsNum(sender) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.unreadsnum}/${sender}`).then(res => { resolve(res.data) })
    })
  }
  putMessage (message) {
    return new Promise(resolve => {
      this.axios().put(Url.notice.message.send, message).then(res => { resolve(res.data) })
    })
  }
  getChatMembers(chatNo, sender) {
    return new Promise(resolve => {
      this.axios().get(`${Url.notice.message.onchat.members}/${chatNo}/${sender}`).then(res => { resolve(res.data) })
    })
  }
}