import Api from './api'
import Url from './request.url'

export default class FetchStreamAPi extends Api {
  
  selectVideoFile(auth) {
    return new Promise(resolve => {
      let params = new URLSearchParams()
      params.append('token', auth.token)
      params.append('path', auth.path)
      let url = `${Url.fetch.gallery.selectfiles}?${params.toString()}`
      fetch(url, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-Type': 'video/mp4'
        }
      })
      .then(response => { resolve(response)})
    })
  }

  selectPhotoFile(auth) {
    return new Promise(resolve => {
      let params = new URLSearchParams()
      params.append('token', auth.token)
      params.append('path', auth.path)
      let url = `${Url.fetch.gallery.selectfiles}?${params.toString()}`
      fetch(url, {
        method: 'get',
        credentials: 'include',
      })
      .then(response => { resolve(response)})
    })
  }

  selectedFile(token, path, name, sort) {
    return new Promise(resolve => {
      let params = {token, path, name, sort }
      this.axios().get(Url.system.filebrowser.resources, { params }).then(res => { resolve(res.data) })
    })
  }

  selectImageFile(auth) {
    return new Promise(resolve => {
      let params = new URLSearchParams()
      params.append('token', auth.token)
      params.append('path', auth.path)
      let url = `${Url.fetch.file.image}?${params.toString()}`
      fetch(url, {
        method: 'get',
        credentials: 'include',
      })
      .then(response => { resolve(response)})
    })
  }

  streamVideo(auth) {
    return new Promise(resolve => {
      let params = new URLSearchParams()
      params.append('token', auth.token)
      params.append('path', auth.path)
      let url = `${Url.fetch.gallery.selectfiles}?${params.toString()}`
      fetch(url, {
        method: 'get',
        credentials: 'include',
        headers: {
          'Content-Type': 'video/mp4'
        }
      })
      .then(response => { resolve(response)})
    })
  }
}
