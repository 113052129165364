import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {
    
    draw_Timeline() {    

      let Timeline = this.SkylineCharts
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX}, ${this.timeline.baseY})`)

      /////////// draw the timeline background without the text
      Timeline
      .append('path') // shadow
      .attr('d', `M50,${this.timeline.height + 6} H ${this.timeline.length-50} L ${this.timeline.length},${this.timeline.height + 16} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`) 

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 2)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .99)

      Timeline
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.length).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .5)

      Timeline
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.length).attr('height', 6)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`)


      // Month Text & Timeline Seperator for the Month
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 4)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.styles.month.tSize)
      .attr('fill', this.timeline.styles.month.tColor)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name.toUpperCase())

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)}, 2 V${this.timeline.styles.month.line}`
      })
      .attr('stroke', this.timeline.styles.month.sColor)
      .attr('stroke-width', this.timeline.styles.month.sWidth)


      // cutoff month highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM')

      this.timeline.month.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM') == cutoff) {
          Timeline
          .append('rect')
          .attr('x',this.timeline.scale(d.sDate) + 1)
          .attr('y',2.5)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate) - this.skyline.skyBox.gap)
          .attr('height',this.timeline.styles.month.height - 5)
          .style('opacity', 0.3)
          .attr('fill','#00b0f0')
          .attr('stroke','#bcbcbc')
          .attr('stroke-width',0.5)               
        }
      })

      // Year Text & Timeline Seperator for the Year
      Timeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 3 +  this.timeline.styles.month.height)
      .style('font-family', this.timeline.font)
      .style('font-size', this.timeline.styles.year.tSize)
      .attr('fill', this.timeline.styles.year.tColor)
      .attr('font-weight', 500)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      Timeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, ${this.timeline.styles.month.line} V${this.timeline.styles.month.line + this.timeline.styles.year.line}`
      })
      .attr('stroke', this.timeline.styles.year.sColor)
      .attr('stroke-width', this.timeline.styles.year.sWidth)
    }, 
  }
}
