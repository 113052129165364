import * as i18n from '../i18n'
import moment from 'moment'

const mutations = {
  initFilebrowser: state => {
    state.user        = {}
    state.req         = {}
    state.oldReq      = {}
    state.clipboard   = {
      key: '',
      items: []
    }
    state.jwt         = ''
    state.fbname      = ''  // '' <system file manager> | 'Coi' <certificate of indpection> | 'Pub' <Shared Folder / Public Data>
    state.fbpath      = ''
    state.progress    = 0
    state.loading     = false
    state.reload      = false
    state.selected    = []
    state.multiple    = false
    state.show        = null
    state.showShell   = false
    state.showMessage = null
    state.showConfirm = null
    state.sort        = {
      by: 'name',
      asc: false,
    }
  },
  closeHovers: state => {
    state.show = null
    state.showMessage = null
  },
  toggleShell: (state) => {
    state.showShell = !state.showShell
  },
  showHover: (state, value) => {
    if (typeof value !== 'object') {
      state.show = value
      return
    }

    state.show = value.prompt
    state.showMessage = value.message
    state.showConfirm = value.confirm
  },
  showError: (state, value) => {
    state.show = 'error'
    state.showMessage = value
  },
  showSuccess: (state, value) => {
    state.show = 'success'
    state.showMessage = value
  },
  setLoading: (state, value) => { state.loading = value },
  setReload: (state, value) => { state.reload = value },
  setSort: (state, value) => { state.sort = value },
  setUser: (state, value) => {
    if (value === null) {
      state.user = null
      return
    }

    let locale = value.locale

    if (locale === '') {
      locale = i18n.detectLocale()
    }

    moment.locale(locale)
    i18n.default.locale = locale
    state.user = value
  },
  setJWT: (state, value) => (state.jwt = value),
  multiple: (state, value) => (state.multiple = value),
  addSelected: (state, value) => (state.selected.push(value)),
  addPlugin: (state, value) => {
    state.plugins.push(value)
  },
  removeSelected: (state, value) => {
    let i = state.selected.indexOf(value)
    if (i === -1) return
    state.selected.splice(i, 1)
  },
  resetSelected: (state) => {
    state.selected = []
  },
  updateUser: (state, value) => {
    if (typeof value !== 'object') return

    if(!state.user) state.user = {}

    let user_ = JSON.parse(JSON.stringify(state.user))

    for (let field in value) {
      if (field === 'locale') {
        moment.locale(value[field])
        i18n.default.locale = value[field]
      }

      user_[field] = value[field]
    }

    state.user = user_
  },
  updateRequest: (state, value) => {
    state.oldReq = state.req
    state.req = value
  },
  updateClipboard: (state, value) => {
    state.clipboard.key = value.key
    state.clipboard.items = value.items
  },
  resetClipboard: (state) => {
    state.clipboard.key = ''
    state.clipboard.items = []
  },
  setProgress: (state, value) => {
    state.progress = value
  },
  setDirection(state, path) {
    state.fbpath = path
  },
  setFbSharedName(state, name) {
    state.fbname = name
  },
  setInputValue(state, value) {
    state.inputValue = value
  },

}

export default mutations
