
import Chart from './chartlibs/chart.module'

export default {
  namespaced: true,
  modules: {
    chart: Chart,
  },
  state: {
    sharedItem: {},
  },
  mutations: {
    initSharedItem (state) {
      state.sharedItem = {}
    },
    updateSharedItem (state, payload) {
      state.sharedItem = { ...state.sharedItem, ...payload }
    },
  },
}
