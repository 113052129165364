import * as d3 from 'd3'

export default {
  data: () => ({

    table: {
      Depth1: [
        {name: '',                    width: 80, },
        {name: 'This Week',           width: 175, },
        {name: 'Cumulative Progress', width: 262, },
      ],

      Depth2: [
        {name: '',            width: 80, },
        {name: 'Plan',        width: 70, },
        {name: 'Actual',      width: 35, },
        {name: 'Delta',       width: 70, },
        {name: 'Plan',        width: 76, },
        {name: 'Progress(%)', width: 110,},
        {name: 'Delta',       width: 76, },
       
      ],

      Depth3: [
        {code: '',       name: '',      delta: false, width: 80,  align: 'start', sWidth: 0.2, from: 'Y1'},
        {code: 'PE_WK',  name: 'Early', delta: false, width: 35,  align: 'end',   sWidth: 0.1, from: 'Y3'},
        {code: 'PL_WK',  name: 'Late',  delta: false, width: 35,  align: 'end',   sWidth: 0.2, from: 'Y2'},
        {code: 'AC_WK',  name: '',      delta: false, width: 35,  align: 'end',   sWidth: 0.2, from: 'Y2'},
        {code: 'DE_WK',  name: 'Early', delta: true,  width: 35,  align: 'end',   sWidth: 0.1, from: 'Y3'},
        {code: 'DL_WK',  name: 'Late',  delta: true,  width: 35,  align: 'end',   sWidth: 0.2, from: 'Y1'},
        {code: 'PE_CUM', name: 'Early', delta: false, width: 38,  align: 'end',   sWidth: 0.1, from: 'Y3'},
        {code: 'PL_CUM', name: 'Late',  delta: false, width: 38,  align: 'end',   sWidth: 0.2, from: 'Y2'},
        {code: 'AC_CUM', name: '',      delta: false, width: 110, align: 'start', sWidth: 0.2, from: 'Y2'},
        {code: 'DE_CUM', name: 'Early', delta: true,  width: 38,  align: 'end',   sWidth: 0.2, from: 'Y3'},
        {code: 'DL_CUM', name: 'Late',  delta: true,  width: 38,  align: 'end',   sWidth: 0.2, from: 'Y1'},
      ],
      Y1: -38,
      Y2: -22,
      Y3: -9,
    }
  }),

  methods: {

    header_Disc() {

      this.disc = this.Header
      .append('g')
      .attr('transform', `translate(725, 0)`)
      .style('font-family', 'roboto')

      this.disc
      .append('text')
      .attr('id', `DISC_MAIN_TEXT`)
      .attr('transform',`translate(15, 5)`)
      .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(`Progress by Discipline for ${this.current.phase.name}`)

      this.disc
      .append('text')
      .attr('id', `DISC_SUB_TEXT`)
      .attr('transform',`translate(15, 15)`)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'start')
      .text(`${this.current.phase.name} Progress Status`)

      this.draw_table()

      this.WBS.disc.forEach((d,i)=> {
        this.draw_Disc(d, i)
      })
    },

    draw_table() {
      
      let x_ = 0
      this.table.Depth1.forEach((d,i)=> {
        d.x = x_ + d.width/2
        x_ = x_ + d.width
      })

      x_ = 0
      this.table.Depth2.forEach((d,i)=> {
        d.x = x_ + d.width/2
        x_ = x_ + d.width
      })

      x_ = 0 
      this.table.Depth3.forEach((d,i)=> {
        if(d.align == 'start')  d.dX = x_
        if(d.align == 'end')    d.dX = x_ + d.width - 5
        d.tX = x_ + d.width/2
        x_ = x_ + d.width
        d.lineX = x_
      })


      let header = this.Header
      .append('g')
      .attr('transform', `translate(738, 58)`)

      header
      .append('rect')
      .attr('x', 0).attr('y', 4)
      .attr('width', x_).attr('height', 120)
      .attr('fill', '#fff').attr('opacity', .5)

      header
      .append('line')
      .attr('x1', 80).attr('x2', x_)
      .attr('y1', this.table.Y1).attr('y2', this.table.Y1)
      .attr('stroke', '#757575').attr('stroke-width', .3)

      header
      .append('line')
      .attr('x1', 80).attr('x2', x_)
      .attr('y1', this.table.Y2).attr('y2', this.table.Y2)
      .attr('stroke', '#757575').attr('stroke-width', .3)

      header
      .append('line')
      .attr('x1', 80).attr('x2', this.table.Depth3[2].lineX)
      .attr('y1', this.table.Y3).attr('y2', this.table.Y3).attr('stroke', '#757575').attr('stroke-width', .3)
      header
      .append('line')
      .attr('x1', this.table.Depth3[3].lineX).attr('x2', this.table.Depth3[7].lineX)
      .attr('y1', this.table.Y3).attr('y2', this.table.Y3).attr('stroke', '#757575').attr('stroke-width', .3)
      header
      .append('line')
      .attr('x1', this.table.Depth3[8].lineX).attr('x2', x_)
      .attr('y1', this.table.Y3).attr('y2', this.table.Y3).attr('stroke', '#757575').attr('stroke-width', .3)

      header
      .append('line')
      .attr('x1', 0).attr('x2', x_)
      .attr('y1', this.table.Y3 + 12).attr('y2', this.table.Y3 + 12)
      .attr('stroke', '#757575').attr('stroke-width', .3)

      header
      .append('line')
      .attr('x1', 0).attr('x2', x_)
      .attr('y1', 125).attr('y2', 125)
      .attr('stroke', '#757575').attr('stroke-width', .3)

      this.table.Depth1.forEach((d,i)=> {
        header
        .append('text')
        .attr('transform', `translate(${d.x}, -27)`)
        .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'middle')
        .text(d.name)
      })

      this.table.Depth2.forEach((d,i)=> {
        header
        .append('text')
        .attr('transform', (i==2) ? `translate(${d.x}, -7.5)` : `translate(${d.x}, -11.5)`)
        .attr('fill', (i==2 || i == 5) ? '#44A9DF' : '#333')
        .attr('font-size', 10).attr('text-anchor', 'middle')
        .text(d.name)

        if (i==5) {
          header
          .append('text')
          .attr('transform', `translate(${d.x-50}, 1)`)
          .attr('fill', '#BDBCBC').attr('font-size', 8).attr('text-anchor', 'start')
          .text(0)

          header
          .append('text')
          .attr('transform', `translate(${d.x}, 1)`)
          .attr('fill', '#BDBCBC').attr('font-size', 8).attr('text-anchor', 'middle')
          .text(50)
          
          header
          .append('text')
          .attr('transform', `translate(${d.x+50}, 1)`)
          .attr('fill', '#BDBCBC').attr('font-size', 8).attr('text-anchor', 'end')
          .text(100)
        }
      })

      this.table.Depth3.forEach((d,i)=> {
        header
        .append('text')
        .attr('transform', `translate(${d.tX}, 0)`)
        .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'middle')
        .text(d.name)

        header
        .append('line')
        .attr('x1', d.lineX).attr('x2', d.lineX)
        .attr('y1', this.table[`${d.from}`]).attr('y2', 125)
        .attr('stroke', '#757575').attr('stroke-width',d.sWidth)
      })

      
    },



    draw_Disc(_d, _no) {
      let x_ = 742, y_ = 75

      let disc = this.Header
      .append('g')
      .attr('id', `DISC_${_d.id}`)
      .attr('transform', `translate(${x_}, ${y_ + (_no * 20)})`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .on('mouseover', function() {
        d3.select(this).style('opacity', .5)
      })
      .on('mouseout', function() {
        d3.select(this).style('opacity', 1)
      })
      .on('click', ()=> {
        d3.select(`#DISC_CHECK_${this.current.disc.id}`).attr('opacity', 0)
        if(_d.id == this.current.disc.id) {
          this.current.disc.name = 'Overall'
          this.current.disc.id = 'OV'
        } else {
          d3.select(`#DISC_CHECK_${_d.id}`).attr('opacity', 1)
          this.current.disc.name = _d.name
          this.current.disc.id = _d.id
        }

        d3.select(`#ADDRESS`)
        .text(`${this.current.phase.name} > ${this.current.area.name} > ${this.current.disc.name}`)

        this.draw_Chart_Controller(true)

        // for export pdf
        this.set_selectedItems()
      })

      disc
      .append('rect')
      .attr('x', 0).attr('y', -8)
      .attr('width', 10).attr('height', 10).attr('fill', '#fff').attr('stroke-opacity', .2)
      .attr('stroke', '#000').attr('stroke-width', .5)

      disc
      .append('path')
      .attr('id', `DISC_CHECK_${_d.id}`)
      .attr('d', `M2,-4 4,0 8,-6`).attr('opacity', 0)
      .attr('fill', 'none').attr('stroke', '#44A9DF').attr('stroke-width', 1.5)

      disc
      .append('text')
      .attr('transform',`translate(15, 0)`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .text(_d.name)


      let dataGrid = this.Header
      .append('g')
      .attr('transform', `translate(${x_}, ${y_ + (_no * 20)})`)
      .style('font-family', 'roboto')

      let data = this.rawData.find(f=> // ##### dataSet -> rawData
        f.CDATE == this.timeline.cutoff && 
        f.LEV == this.current.phase.name && 
        f.AREA == this.current.area.name
        && f.DISC == _d.name
      )

      let discGroup = dataGrid
      .append('g')
      .attr('id', `DISC_GROUP_${_d.id}`)
      .attr('transform', `translate(0,0)`)
      .attr('opacity', 1)

      this.table.Depth3.forEach((d,i)=> {
        

        if(d.code !== 'AC_CUM') {
          discGroup
          .append('text')
          .attr('id', `DISC_${_d.id}_${d.code}_VALUE`)
          .attr('transform', (d.code=='DE_CUM' || d.code=='DL_CUM') ? `translate(${d.dX-9}, 0)` : `translate(${d.dX-4}, 0)`)
          .attr('font-size', 10)
          .attr('fill', '#333')
          .attr('fill', (d.delta) ? this.set_DeltaColor(data[`${d.code}`], 'no') : '#333')
          .attr('text-anchor', 'end')
          .text(data[`${d.code}`])

          if(d.code=='DE_CUM' || d.code=='DL_CUM') { 
            discGroup
            .append('path')
            .attr('id', `DISC_${_d.id}_${d.code}_ICON`)
            .attr('transform', `translate(${d.dX-8}, ${-7.5}) scale(.6)`)
            .attr('d', this.set_DeltaColor(data[`${d.code}`], 'icon'))
            .attr('fill', this.set_DeltaColor(data[`${d.code}`], 'no'))
            .attr('stroke-width', .5)
          }

        } else {
          discGroup
          .append('rect')
          .attr('x', d.tX-55).attr('y', -8)
          .attr('width', 100).attr('height', 10).attr('fill', '#B4E3FA').attr('opacity', .5)

          discGroup
          .append('rect')
          .attr('id', `DISC_${_d.id}_${d.code}_BAR`)
          .attr('x', d.tX-55).attr('y', -8)
          .attr('width', data[`${d.code}`]).attr('height', 10)
          .attr('fill', '#83D2F5').attr('stroke', '#44A9DF').attr('stroke-width', .2)

          let pro = data[`${d.code}`]
          discGroup
          .append('text')
          .attr('id', `DISC_${_d.id}_${d.code}_VALUE`)
          .attr('transform', (pro>70) ? `translate(${d.tX-55 + pro - 1}, 0)`: `translate(${d.tX-55 + pro + 1}, 0)`)
          .attr('font-size', 10).attr('fill', '#333')
          .attr('text-anchor', (pro>70) ? 'end' : 'start')
          .text(`${pro}%`)
        }
      })

    },
  }
}