import * as d3 from 'd3'

export default {

  methods: {

    event_Link(selection, d) {
      if (this.Column.jsonLink[d.col] == '') return

      let baseY = 0
      if (this.Value.baseY) baseY = this.Value.baseY

      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.x}, ${d.y + baseY}) scale(1.2)`)
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .attr('transform', `translate(${d.x}, ${d.y}) scale(1)`)
      })
      .on('click', () => { 
        this.link_Action(d) // --->
      })
    },


    link_Action(d_) {

      let dataSource = {}

      // if jsonLink is exist, set dataSource
      if(this.Group.title.jsonMap !== '' || this.Group.title.jsonMap !== 'N') {
        dataSource = {...dataSource, [this.Group.title.jsonMap]: this.rawData[d_.idx][this.Group.refCode]}
      }
      
      if(this.Column.jsonMap.filter(el => el).length > 0){
        this.Column.jsonMap.forEach((d, i) => {
          if (d !== '') dataSource = {...dataSource, [d]: this.rawData[d_.idx][this.Column.refCode[i]]}
        })
      }

      // ********************************************************************************
        // console.log('dataSource: ', dataSource)
        // let filterTesting = this.Column.filter[d_.col] !== '' ? this.filters[this.Column.filter[d_.col]] : ''
        // console.log('filterString_: ', filterTesting)
      // ********************************************************************************

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(`request${d_.gn}`, dataSource, this.ColumnProps)
      if(!request_) return

      // if filterCol is exist, set filterString_
      let filterString_ = this.Column.filter[d_.col] !== '' ? this.filters[this.Column.filter[d_.col]] : ''

      // if A/G iFilters is exist, (filterString_ + iFilters)
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

  } 
}