import { CommunicaitonApi } from './api'

export default class CommunicaitonService {
    
  constructor() {
    this.communicaitonApi = new CommunicaitonApi()
  }

  getComments(refCode) {
    return this.communicaitonApi.getComments(refCode).then(res => {
      if(!res) res = []

      res.forEach(data => {
        if(!data.files) data.files = []
        else data.files = JSON.parse(data.files)
      })
      return new Promise(resolve => { resolve(res) })
    })
  }
  putComment(params, config) {
    return this.communicaitonApi.putComment(params, config)
  }
  editComment(params, config) {
    return this.communicaitonApi.editComment(params, config)
  }
  delComment(idx) {
    return this.communicaitonApi.delComment(idx)
  }

  
  getSubsystemComments(subsystem) {
    return this.communicaitonApi.getSubsystemComments(subsystem).then(res => {
      if(!res) res = []
      return new Promise(resolve => { resolve(res) })
    })
  }
  putSubsystemComment(params, config) {
    return this.communicaitonApi.putSubsystemComment(params, config)
  }
  editSubsystemComment(params, config) {
    return this.communicaitonApi.editSubsystemComment(params, config)
  }
  delSubsystemComment(idx) {
    return this.communicaitonApi.delSubsystemComment(idx)
  }
}
