import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
import Data from '../../../../includes/primitives/Color_Data'

export default {
  methods: {
    drawChartVGroup() {
      //------------------------------------------------------------------------------------
      let keys_ = Object.keys(this.DataItems[0]).splice(1)

      var color = d3.scaleOrdinal()
        .range(["#98abc5", "#8a89a6", "#7b6888", "#6b486b", "#a05d56", "#d0743c", "#ff8c00"])

      let chartWrapper = this.chartArea
        .append("g")
        .selectAll("g")
        .data(this.DataItems)
        .join("g")
        .attr("transform", d => `translate(${this.scaleChartVGroup.groupScale(d.title)},0)`)
        .selectAll("rect")
        .data(d => keys_.map(key => ({ key, value: d[key] })))
        .join("rect")
        .attr("x", d => this.scaleChartVGroup.barScale(d.key))
        .attr("y", d => this.scaleYInfo.scale(d.value))
        .attr("width", this.scaleChartVGroup.barScale.bandwidth())
        .attr("height", d => this.scaleYInfo.scale(0) - this.scaleYInfo.scale(d.value))
        // .attr("fill", d => color(d.key))
        .attr('fill', (_, i) => (this.SharedColorSet[i]))



      // ------------------------------------------------------------------------- Series & Value

      let valueWrapper = this.chartArea // Value
        .append("g")
        .selectAll("g")
        .data(this.DataItems)
        .join("g")
        .attr('id', (_, i) => {
          return `value_wrapper_${i}`
        })

      if (this.Bar.BarValueDisplay == 'Y') {

        valueWrapper
          .selectAll("text")
          .data(d => keys_.map(key => ({ key, value: d[key] })))
          .join("text")
          .attr('class', (d, i) => `ValueText${i} ${this.Bar.BarValueStyle}`)
          .style('font-size', this.Bar.BarValueSize)
          .style('font-family', this.Bar.BarValueFont)
          .attr("x", d => this.scaleChartVGroup.barScale(d.key))
          .attr("y", d => this.scaleYInfo.scale(d.value))
          .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.SharedColorSet[i]) : this.Bar.BarValueColor))
          .text(d => `${d.value.toFixed(this.Bar.BarValueRound)}`)
      }


      if (this.Bar.BarUnitDisplay == 'Y') {
        valueWrapper
          .selectAll("p")
          .data(d => keys_.map(key => ({ key, value: d[key] })))
          .join("text") // Units
          .attr('transform', (d, i) => {
            return `translate(${this.Bar.BarValueDisplay == 'Y' ? Number(this.getNodeElValue(`.ValueText${i}`, 'width')) + 3 : 0},0)`
          })
          .style('font-size', this.Bar.BarUnitSize)
          .style('font-family', this.Bar.BarValueFont)
          .attr('class', this.Bar.BarValueStyle)
          .attr("x", d => this.scaleChartVGroup.barScale(d.key))
          .attr("y", d => this.scaleYInfo.scale(d.value))
          .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? (this.SharedColorSet[i]) : this.Bar.BarValueColor))
          .text(this.Bar.BarUnitFormat)
      }

      // Reset the position of the value-wrapper

      // Magic number for hanging text at top of Box
      let textAlign4Hang = 2
      this.DataItems.forEach((d, i) => {
        this.svg.select(`#value_wrapper_${i}`)
          .attr("transform", d => `translate(${this.scaleChartVGroup.groupScale(d.title)},
            ${(this.Bar.BarValuePosition == 'top' ?  -this.Bar.BarValueSpace : this.Bar.BarValueSpace + this.Bar.BarValueSize - textAlign4Hang)})`
          )
      
      })
    },
  }
}