
import * as d3 from 'd3'

export default {
  methods: {

    block_Summary(props) {

      let ePlan = this.progress[`${props.code}_EP`]
      let lPlan = this.progress[`${props.code}_LP`]
      let act = this.progress[`${props.code}_A`]
      let eDelta = act - ePlan
      let lDelta = act - lPlan

      let barLen = 180
      let status = [
        {name: 'Cutting Commenced',   ref: 'FAB',  pCode: 'SC_AS',  aCode: 'SC_AS',  opacity: .25},
        {name: 'Fab. Commenced',      ref: 'FAB',  pCode: 'FAB_AS', aCode: 'FAB_AS', opacity: .40},
        {name: 'Assembly 100%',       ref: 'FAB',  pCode: 'FAB_AF', aCode: 'FAB_AF', opacity: .55},
        {name: 'Block Painting 100%', ref: 'PE1',  pCode: 'PT_AF',  aCode: 'PT_AF',  opacity: .70},
        {name: 'On Ground Setting',   ref: 'PE3',  pCode: 'PE3_AS', aCode: 'PE3_AS', opacity: .85},
        {name: 'Final Setting',       ref: 'EREC', pCode: 'ER_AS',  aCode: 'ER_AS',  opacity: 1.0},
      ]

      let filterArea = (props.area == 'OVERALL') ? this.block_Status : this.block_Status.filter(f=> f.AREA == props.area)

      status.forEach(d=> {
        d.total = filterArea.filter(f=> f[`${d.ref}`] !== '' && f[`${d.pCode}`] !== undefined).length
        
        d.pro = Math.random()
        d.actual = (d.total * d.pro).toFixed(0)
        d.remain = d.total - d.actual
      })


      let actual = props.selection
      .append('g')
      .attr('transform', `translate(${props.x}, ${props.y})`)
      .attr('font-family', 'roboto')

      // TITLE & Actual Progress(%)
      actual
      .append('text')
      .attr('id', `${props.id}_ACT_TITLE`)
      .attr('transform', `translate(0, 0)`)
      .attr('font-size', 18).attr('fill', '#000')
      .text(props.area)

      actual
      .append('text')
      .attr('id', `${props.id}_ACT_PRO`)
      .attr('x', this.getNodeElValue(`#${props.id}_ACT_TITLE`, 'width') + 10)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Actual Cuml.')

      actual
      .append('text')
      .attr('x', this.getNodeElValue(`#${props.id}_ACT_TITLE`, 'width') + this.getNodeElValue(`#${props.id}_ACT_PRO`, 'width') + 15)
      .attr('font-size', 12).attr('fill', '#44A9DF')
      .text(act + '%')



      // Early & Delta Progress(%)
      let early = props.selection
      .append('g')
      .attr('transform', `translate(${props.x}, ${props.y + 18})`)
      .attr('font-family', 'roboto')

      early
      .append('text')
      .attr('id', `${props.id}_EARLY_TITLE`)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Early Plan Cuml.')

      early
      .append('text')
      .attr('id', `${props.id}_EARLY_PRO`)
      .attr('x', this.getNodeElValue(`#${props.id}_EARLY_TITLE`, 'width') + 5)
      .attr('font-size', 12).attr('fill', '#333')
      .text(ePlan + '%')

      early
      .append('text')
      .attr('x', this.getNodeElValue(`#${props.id}_EARLY_TITLE`, 'width') + this.getNodeElValue(`#${props.id}_EARLY_PRO`, 'width') + 15)
      .attr('font-size', 12).attr('fill', this.setDeltaColor(eDelta))
      .text(eDelta.toFixed(1) + '%')


      // Late & Delta Progress(%)
      let late = props.selection
      .append('g')
      .attr('transform', `translate(${props.x + 160}, ${props.y + 18})`)
      .attr('font-family', 'roboto')

      late
      .append('text')
      .attr('id', `${props.id}_LATE_TITLE`)
      .attr('font-size', 10).attr('fill', '#bcbcbc')
      .text('Late Plan Cuml.')

      late
      .append('text')
      .attr('id', `${props.id}_LATE_PRO`)
      .attr('x', this.getNodeElValue(`#${props.id}_LATE_TITLE`, 'width') + 5)
      .attr('font-size', 12).attr('fill', '#333')
      .text(lPlan + '%')

      late
      .append('text')
      .attr('id', `LATE_DELTA`)
      .attr('x', this.getNodeElValue(`#${props.id}_LATE_TITLE`, 'width') + this.getNodeElValue(`#${props.id}_LATE_PRO`, 'width') + 15)
      .attr('font-size', 12).attr('fill', this.setDeltaColor(lDelta))
      .text(lDelta.toFixed(1) + '%')


      // BAR CHART -----------------------------------------------------------------------------------------------------------------------
      let barChart = props.selection
      .append('g')
      .attr('transform', `translate(${props.x + 100}, ${props.y + 30})`)
      .attr('font-family', 'roboto')

      status.forEach((d,i) => {
        let y = i * 18

        barChart
        .append('text')
        .attr('transform', `translate(${-100}, ${y + 10})`)
        .attr('font-size', 10).attr('fill', '#757575')
        .text(d.name)

        barChart
        .append('rect')
        .attr('x', 0).attr('y', y)
        .attr('width', barLen).attr('height', 13)
        .attr('fill', '#E0E0DF').attr('opacity', .2)

        barChart
        .append('text')
        .attr('transform', `translate(${barLen + 2}, ${y + 10})`)
        .attr('font-size', 10).attr('fill', '#757575')
        .text(d.total)

        barChart
        .append('rect')
        .attr('x', 0).attr('y', y)
        .attr('width', barLen * d.pro).attr('height', 13)
        .attr('fill', '#44A9DF').attr('opacity', d.opacity)

        barChart
        .append('text')
        .attr('x', d.pro < 0.8 ?  barLen * d.pro + 2 :  barLen * d.pro - 2)
        .attr('y', y + 10)
        .attr('text-anchor', d.pro < 0.8 ? 'start' : 'end')
        .attr('font-size', 10).attr('fill', '#333')
        .text(d.actual)

      })


    },

  }
}