/* 
  ### Chart Default Data ### 
  Predefined component's behaviors for the Filter & Action
*/

// Special Charts
import TR_SCURVE_ENG            from '../10_Trion/Charts/SCurve_ENG/index.vue'
import TR_SCURVE_PRO            from '../10_Trion/Charts/SCurve_PRO/index.vue'
import TR_SCURVE_CON            from '../10_Trion/Charts/SCurve_CON/index.vue'

// Skyline Charts
import TR_SKYLINE_BLOCK         from '../10_Trion/Skylines/Blocks/index.vue'

// 3D Visualization
import TR_Erection_Network      from '../10_Trion/Visualization/Erection_Network/index.vue'
import TR_Erection_Timeline     from '../10_Trion/Visualization/Erection_Timeline/index.vue'
import TR_Block_Division        from '../10_Trion/Visualization/Block_Division/index.vue'


export default {
  
  // Special Charts
  TR_SCURVE_ENG,
  TR_SCURVE_PRO,
  TR_SCURVE_CON,

  // Skyline Charts
  TR_SKYLINE_BLOCK,

  // 3D Visualization
  TR_Erection_Network,
  TR_Erection_Timeline,
  TR_Block_Division,

}
