import * as d3 from 'd3'
import __M from 'moment'
import __C from '../../includes/primitives/_constant_'

export default {
  data: () => ({
    scaleTimelineInfo: {
      base: { 
        startXPos: 0,
        endXPos: 0,
        tickValues: [],
      },
      month: { tickValues: [] },
      year: { tickValues: [] },
      scale: null,
    },
    scaleTimelineTicks: {
      base: { 
        startXPos: 0,
        endXPos: 0,
        tickValues: [] 
      },
      month: { tickValues: [] },
      year: { tickValues: [] },
      scale: null,
    },

    timelineAreaX: null,
    timelineAreaY: null,
    timelineAreaWidth: null,

    timelineArea: null,
    timelineTickArea: null,

    timelineKeyName: 'cdate'
  }),
  methods: {
    getTickvaluesBase() {
      let datasource = JSON.parse(JSON.stringify(this.DataItems))
      let dates_ = datasource.map(d_ => __M(d_[this.timelineKeyName]).toDate())
      let sDate_ = __M(Math.min(...dates_)).toDate()
      let eDate_ = __M(Math.max(...dates_)).toDate()
      let tickValues_ = []

      // ### Set Timeline-Values for the Week ### ---------------------------
      let nextDate = sDate_
      while(nextDate <= eDate_) {
        tickValues_.push(nextDate)
        nextDate = __M(nextDate).add(7, 'days').toDate()
      }
      // ### ------------------------------------- --------------------------
      
      return tickValues_
      // return this.DataItems.map(d => this.parseDate(d[this.timelineKeyName]))
    },
    setTimelineArea(tValues) {
      if(this.Canvas.CanvasChartAreaAllowed == 'Y') {
        this.timelineAreaX = 0
        this.timelineAreaY = this.Canvas.CanvasChartHeight
        this.timelineAreaWidth = this.Canvas.CanvasChartWidth

      } else {
        this.timelineAreaX = tValues.x
        this.timelineAreaY = tValues.y
        this.timelineAreaWidth = tValues.w

      }

      this.timelineArea = tValues.s
      .append('g')
      .attr('class','_Timeline_area__')
      .attr('transform', `translate(${this.timelineAreaX},${this.timelineAreaY})`)

      // TL-A : Line type Timeline
      // TL-B : Tick type Timeline
      if(this.Timeline.TimelineType != 'TL-A') return

      this.timelineTickArea = tValues.s
      .append('g')
      .attr('class','_Timeline_tick_area__')
      .attr('transform', `translate(${this.timelineAreaX},${this.timelineAreaY})`)
    },
    setScaleTimelineInfo() {
      // The value's key name should be 'title' or others,
      // or the first column must be value?
      // Need to be regulated.
      // let dataItems = this.getDataRequested()
      let bandWidth = this.timelineAreaWidth / this.getTickvaluesBase().length
      // Scale Base
      this.scaleTimelineInfo.base.startXPos = this.ChartType == __C.CHART.TYPE_CODE_MONTHLY ? (bandWidth / 2) + 14 : bandWidth / 2
      this.scaleTimelineInfo.base.endXPos = this.ChartType == __C.CHART.TYPE_CODE_MONTHLY ? (this.timelineAreaWidth - bandWidth / 1.9) - 14 : this.timelineAreaWidth - bandWidth / 1.9
      this.scaleTimelineInfo.base.tickValues = this.getTickvaluesBase()

      let months_ = []
      let months__ = []
      let years_ = []
      let years__ = []
      let prevMonth_ = d3.timeFormat('%Y-%m')(this.scaleTimelineInfo.base.tickValues[0])
      let prevYear_ = d3.timeFormat('%Y')(this.scaleTimelineInfo.base.tickValues[0])

      // Extract the ranges of the month and year
      this.scaleTimelineInfo.base.tickValues.forEach(day => {
        if(prevMonth_ == d3.timeFormat('%Y-%m')(day)) months__.push(day)
        else {
          months_.push([ ...months__ ])
          months__ = [day]
          prevMonth_ = d3.timeFormat('%Y-%m')(day)
        }
        
        if(prevYear_ == d3.timeFormat('%Y')(day)) years__.push(day)
        else {
          years_.push([ ...years__ ])
          years__ = [day]
          prevYear_ = d3.timeFormat('%Y')(day)
        }
      })
      // Set each of a last extracted month and year, not pushed.
      if(months__.length > 0) months_.push(months__)
      if(years__.length > 0) years_.push(years__)

      // Scale Month
      this.scaleTimelineInfo.month.tickValues = months_.map(month => ({
        start: month[0],
        center: new Date(d3.mean(month)),
        end: month[month.length - 1]
      }))
      // Scale Year
      this.scaleTimelineInfo.year.tickValues = years_.map(year => ({
        start: year[0],
        center: new Date(d3.mean(year)),
        end: year[year.length - 1]
      }))

      this.scaleTimelineInfo.scale = d3.scaleTime()
      .domain(d3.extent(this.scaleTimelineInfo.base.tickValues, (d) => d))
      .range([this.scaleTimelineInfo.base.startXPos, this.scaleTimelineInfo.base.endXPos]) 
    },
    setScaleTimelineTicks() {
      if(this.Timeline.TimelineType != 'TL-A') return
      if(this.scaleTimelineInfo.base.tickValues.length < 2) return

      let lastIndex_ = this.scaleTimelineInfo.base.tickValues.length - 1
      let lastDate_ = this.scaleTimelineInfo.base.tickValues[lastIndex_]
      let days_ = lastDate_ - this.scaleTimelineInfo.base.tickValues[lastIndex_ - 1]
      let attemptedDate = this.parseDate(d3.timeFormat('%Y-%m-%d')(Number(lastDate_) + Number(days_)))

      // Scale Base
      this.scaleTimelineTicks.base.startXPos = -0.5
      this.scaleTimelineTicks.base.endXPos = this.timelineAreaWidth - 1
      this.scaleTimelineTicks.base.tickValues = [ ...this.scaleTimelineInfo.base.tickValues, attemptedDate ]

      let prevMonth_ = null
      let prevYear_ = null

      this.scaleTimelineTicks.month.tickValues = []
      this.scaleTimelineTicks.year.tickValues = []

      // Extract the ranges of the month and year
      this.scaleTimelineTicks.base.tickValues.forEach(day => {
        if(prevMonth_ != d3.timeFormat('%Y-%m')(day)) {
          this.scaleTimelineTicks.month.tickValues.push(day)
          prevMonth_ = d3.timeFormat('%Y-%m')(day)
        }
        if(prevYear_ != d3.timeFormat('%Y')(day)) {
          this.scaleTimelineTicks.year.tickValues.push(day)
          prevYear_ = d3.timeFormat('%Y')(day)
        }
      })

      if(!this.scaleTimelineTicks.month.tickValues.includes(attemptedDate)) this.scaleTimelineTicks.month.tickValues.push(attemptedDate)
      if(!this.scaleTimelineTicks.year.tickValues.includes(attemptedDate)) this.scaleTimelineTicks.year.tickValues.push(attemptedDate)

      this.scaleTimelineTicks.scale = d3.scaleTime()
      .domain(d3.extent(this.scaleTimelineTicks.base.tickValues, (d) => d))
      .range([this.scaleTimelineTicks.base.startXPos, this.scaleTimelineTicks.base.endXPos]) 
    },
    getDataRequested() {
      let dataItems = JSON.parse(JSON.stringify(this.DataItems))
      let truncated = []

      if(this.Timeline.TimelinePeriodStart > 0) {
        truncated = dataItems.slice(0, this.Timeline.TimelinePeriodStart)

      } else if(this.Timeline.TimelinePeriodCutoff > 0) {
        truncated = dataItems.filter(item => item[this.timelineKeyName] <= d3.timeFormat('%Y-%m-%d')(this.cutoffDate))

        if(truncated.length >= this.Timeline.TimelinePeriodCutoff) 
          truncated = truncated.slice(truncated.length - this.Timeline.TimelinePeriodCutoff)

      } else if(this.Timeline.TimelinePeriodFixedStart && this.Timeline.TimelinePeriodFixedEnd) {
        truncated = dataItems.filter(item => (
          item[this.timelineKeyName] >= this.Timeline.TimelinePeriodFixedStart &&
          item[this.timelineKeyName] <= this.Timeline.TimelinePeriodFixedEnd
        ))

      } else truncated = dataItems

      return truncated
    },
    getTimelineYPos(name) {
      let yPos_ = 0
      let padding = 0

      if(name == 'day') {
        // padding = this.Timeline.TimelineWeekLineSpace

      } else if(name == 'month') {
        if(this.Timeline.TimelineWeekTextDisplay == 'Y') padding = this.Timeline.TimelineWeekLineSpace
        
        yPos_ = Math.round(Number(this.getNodeElValue(`#timeline_wrapper_day___${this.localId}`, 'height')) + padding) - 2

      } else {
        if(this.Timeline.TimelineWeekTextDisplay == 'Y') padding = this.Timeline.TimelineWeekLineSpace - 2
        if(this.Timeline.TimelineMonthDisplay == 'Y') padding += this.Timeline.TimelineMonthLineSpace - 2

        yPos_ = Number(this.getNodeElValue(`#timeline_wrapper_day___${this.localId}`, 'height'))
        yPos_ += Number(this.getNodeElValue(`#timeline_wrapper_month___${this.localId}`, 'height'))
        yPos_ = Math.round(yPos_ + padding)
      }

      return yPos_
    },
    getTimelineTickYPos(name) {
      let yPos_ = 0

      if(name == 'day') {
        // yPos_ = - 3

      } else if(name == 'month') {
        yPos_ = this.Timeline.TimelineWeekLineHeight

      } else {
        yPos_ = this.Timeline.TimelineWeekLineHeight
        yPos_ += this.Timeline.TimelineMonthDisplay != 'Y' ? 0 : this.Timeline.TimelineMonthLineHeight
      }

      return yPos_
    },
  }
}
