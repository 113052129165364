import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global

export default {
  data: () => ({
  }),
  methods: {
    drawTransition() {
      this.dataSet.forEach((d,i) => {
        d3.select(`#sMenu_Bar_${i}`)
        .attr('width', d[this.prevMode])
        .transition()
        .duration(500)
        .attr('width', d[this.newMode])

        d3.select(`#sMenu_BarText_${i}`)
        .attr('x', 91 + d[this.prevMode])
        .transition()
        .duration(500)
        .attr('x', 91 + d[this.newMode])
        // .text(d[this.newMode])
        .tween("text", () => {
          
          var j = d3.interpolateNumber(d[this.prevMode], d[this.newMode])
          return (t) => {
            d3.select(`#sMenu_BarText_${i}`).text( `${Number(j(t).toFixed(1))}%` )
          }
        })
      })
    },
  }
}
