import { China1Api } from './api'

export default class China1Service {
    
  constructor() {
    this.china1Api = new China1Api()
  }

  putMrComment(params) {
    return this.china1Api.putMrComment(params)
  }
  updateMrComment(params) {
    return this.china1Api.updateMrComment(params)
  }
  updateMrCommentStatus(params) {
    return this.china1Api.updateMrCommentStatus(params)
  }
}
