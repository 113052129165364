export default {
  data: () => ({
    weekStatus: null,
    weekStyle: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    /*
      set_LocalValues()
      loadSvg()
      zoom()
    */

    set_LocalValues(){
      this.weekStyle = this.Queries.SQL1[0]

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      this.rawData.forEach(d=> {
        // d.date = d.DATE
        if(d.WEEK =='LAST' || d.WEEK =='THIS') {
          d.name = 'Total'
          d.width = this.weekStyle.TOTAL_SIZE
          d.tColor = this.weekStyle.TOTAL_TEXT_COLOR
        } else {
          d.name = d.WEEK
          d.width = this.weekStyle.WEEK_SIZE
          d.tColor = this.weekStyle.WEEK_TEXT_COLOR
        }
        d.value = d.VALUE
      })
    },

  }
}