import * as d3 from 'd3'

export default {
  methods: {

    chart_Controller() {

      let _data = this.rawData.filter(f=> f.CDATE >= this.timeline.startDate && f.CDATE <= this.timeline.endDate)

      this.scaleY = d3.scaleLinear()
      .domain([0, 100])
      .range([this.Chart.height, 0])

      this.scaleX = d3.scaleBand()
      .domain(this.rawData.map(d => d.CDATE ))
      .range([0, this.Chart.width]).padding(0)

      // Call Chart
      this.Line.forEach((d,i)=> {
        (d.type == 'ACT') ? _data = this.rawData.filter(f=> f.CDATE <= this.timeline.cutoff) : _data = this.rawData
        this.draw_lineChart(_data, d, i)
      })

      this.draw_Legends()
    },



    draw_lineChart(data_, line, no_) {

      let lineFunc = d3.line()
      .x(d => this.scaleX(d.CDATE))
      .y(d => this.scaleY(d[line.code]))

      let lineCghart = this.SCurve
      .append('g')
      .attr('id', `LINE_${no_}_${this.localId}`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      lineCghart
      .append('path')
      .attr('d', lineFunc(data_))
      .style('stroke', line.sColor)
      .style("stroke-dasharray", 
        (d)=> {
          if (line.type == 'DASH') return (3,3)
          else return (0,0)
        })
      .style('stroke-width', line.sWidth)
      .attr('opacity', line.opacity)
      .style('fill', 'none')
    },



    draw_Legends() {

      let legends = this.SCurve
      .append('g')
      .attr('id', `LEGENDS_${this.localId}`)
      .attr('transform', `translate(${0}, ${-this.Legend.margin})`)

      let x_ = 0
      this.Line.forEach((d,i)=> {

        let item = legends
        .append('g')
        .attr('id', `ITEM${i}_${this.localId}`)
        .attr('transform', `translate(${0}, ${-this.Legend.margin})`)
        .attr('font-family', 'roboto')

        item
        .append('rect')
        .attr('x', x_)
        .attr('y', -1.5)
        .attr('width', 10).attr('height', 2).attr('fill', d.sColor)

        item
        .append('text')
        .attr('id', `ITEM${i}_TEXT_${this.localId}`)
        .attr('x', x_ + 12)
        .attr('y', 0)
        .style('font-size', this.Legend.tSize).style('fill', this.Legend.tColor)
        .attr('alignment-baseline', 'middle')
        .text(d.name)

        let len = d3.select(`#ITEM${i}_${this.localId}`).node().getBBox().width
        x_ = x_ + len + 10
      })

      // Change Legend Position
      let totalLen = d3.select(`#LEGENDS_${this.localId}`).node().getBBox().width
      let position = 0

      if (this.Legend.align == 'center') {
        position = (this.Chart.width/2) - (totalLen/2)
      } else if (this.Legend.align == 'right') {
        position = this.Chart.width - totalLen
      }

      d3.select(`#LEGENDS_${this.localId}`).attr('transform', `translate(${position}, ${-this.Legend.margin})`)
    },
  }
}