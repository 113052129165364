<template>
  <v-dialog hide-overlay attach="#viewer_3d_content" v-model="opened" :width="560" >
    <v-card>
      <v-card-title class="pipingPopupWrapper">
        <div class="drag_move">
            <v-icon class="drag_icon">mdi-cursor-move</v-icon>
            <div>DRAG TO MOVE</div>
        </div>
        <div class="popup_header">
          <div class="popup_header_item1">SPOOL OVERVIEW</div>
        </div>
        <div class="popup_close">
          <a v-ripple class="btn-close3" title="Cancel & Close" @click="onClose">
            <i class="mdi mdi-close"></i>
          </a>
        </div>
        <div class="popup_contents_wrap">
          <div class="popup_contents">
            <div class="popup_contents_box">
              <fieldset>
                <legend>Information</legend>
                <div class="popup_contents_box_info">
                <div class="contents_wrap content_height">
                  <div class="contents_title">ISO No.</div>
                  <div class="contents_title height20">Subsystem No.</div>
                  <div class="contents_title">Service Class</div>
                  <div class="contents_title">Service class Category</div>
                  <div class="contents_title">AG/ UG/ PKG</div>
                  <div class="contents_title">LB/ SB</div>
                  <div class="contents_title">W BU</div>
                  <div class="contents_title">Weight</div>
                  <div class="contents_title">Length</div>
                  <div class="contents_title">ETA</div>
                  <div class="contents_title">Progress Status</div>
                </div>
                <div class="contents_wrap content_height">
                  <div class="contents_text" :style ="{color: params && params.TAG_NO ? '#000' : '#EEE'}">{{ params && params.TAG_NO? params.TAG_NO : 'No data' }}</div>
                  <div class="contents_text height20" :style ="{color: params && params.SUBSYSTEM ? '#000' : '#EEE'}">{{ params && params.SUBSYSTEM? params.SUBSYSTEM : 'No data' }}</div>
                  <div class="contents_text" :style ="{color: params && params.CLASS ? '#000' : '#EEE'}">{{ params && params.CLASS ? params.CLASS : 'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.CLASS_CATEGORY ? '#000' : '#EEE'}">{{ params && params.CLASS_CATEGORY ? params.CLASS_CATEGORY :'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.PKG ? '#000' : '#EEE'}">{{ params && params.PKG ? params.PKG :'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.ISO_SIZE ? '#000' : '#EEE'}">{{ params && params.ISO_SIZE ? params.ISO_SIZE :'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.ISO_SIZE ? '#000' : '#EEE'}">{{ params && params.ISO_SIZE ? params.WBU:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.SPOOL_WEGT ? '#000' : '#EEE'}">{{ params && params.SPOOL_WEGT ? Number(params.SPOOL_WEGT).toFixed(1) : 'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.SPOOL_LEN ? '#000' : '#EEE'}">{{ params && params.SPOOL_LEN ?  Number(params.SPOOL_LEN).toFixed(1)+'mm': 'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.ETA ? '#000' : '#EEE'}">{{ params && params.ETA ? params.ETA :'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.STATUS ? '#000' : '#EEE'}">{{ params && params.STATUS ? params.STATUS :'No data'}}</div>
                </div>
              </div>
              </fieldset>
              <fieldset>
                <legend>Material & Hold</legend>
                <div class="popup_contents_box_info">
                <div class="contents_wrap">
                  <div class="contents_title">Material Availabillity</div>
                  <div class="contents_title">Material Type</div>
                  <div class="contents_title">Hold Status</div>
                  <div class="contents_title">Hold Comments</div>
                </div>
                <div class="contents_wrap">
                  <div class="contents_text" :style ="{color: params && params.MATERIAL_AVAILABILITY ? '#000' : '#EEE'}">{{ params && params.MATERIAL_AVAILABILITY ? params.MATERIAL_AVAILABILITY:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.MATERIAL_TYPE ? '#000' : '#EEE'}">{{ params && params.MATERIAL_TYPE ? params.MATERIAL_TYPE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.HOLD_STATUS ? '#000' : '#EEE'}">{{ params && params.HOLD_STATUS ? params.HOLD_STATUS:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.HOLD_COMMENT ? '#000' : '#EEE'}">{{ params && params.HOLD_COMMENT ? params.HOLD_COMMENT:'No data'}}</div>
                </div>
              </div>
              </fieldset>

            </div>
          </div>
          <div class="popup_contents">
            <div class="popup_contents_box">
              <fieldset>
                <legend>Stage</legend>
                <div class="popup_contents_box_info">
                <div class="contents_wrap">
                  <div class="contents_title">Start Fab</div>
                  <div class="contents_title">Fabricated</div>
                  <div class="contents_title">QC Release</div>
                  <div class="contents_title">Shop Test Done</div>
                  <div class="contents_title">Sent To Paint</div>
                  <div class="contents_title">Painted</div>
                  <div class="contents_title">Final QC</div>
                  <div class="contents_title">Laydown</div>
                  <div class="contents_title">To Site</div>
                  <div class="contents_title">Erected</div>
                  <div class="contents_title">Welded Bolt</div>
                  <div class="contents_title">Supported</div>
                  <div class="contents_title">RFT</div>
                </div>
                <div class="contents_wrap">
                  <div class="contents_text" :style ="{color: params && params.FAB_START_DATE ? '#000' : '#EEE'}">{{ params && params.FAB_START_DATE ? params.FAB_START_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.FAB_FINISH_DATE ? '#000' : '#EEE'}">{{ params && params.FAB_FINISH_DATE ? params.FAB_FINISH_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.QC_RELEASE_DATE ? '#000' : '#EEE'}">{{ params && params.QC_RELEASE_DATE ? params.QC_RELEASE_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.SHOP_TEST_DATE ? '#000' : '#EEE'}">{{ params && params.SHOP_TEST_DATE ? params.SHOP_TEST_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.SENT_PAING_DATE ? '#000' : '#EEE'}">{{ params && params.SENT_PAING_DATE ? params.SENT_PAING_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.PAINTED_DATE ? '#000' : '#EEE'}">{{ params && params.PAINTED_DATE ? params.PAINTED_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.FINAL_QC_DATE ? '#000' : '#EEE'}">{{ params && params.FINAL_QC_DATE ? params.FINAL_QC_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.LAYDOWN_DATE ? '#000' : '#EEE'}">{{ params && params.LAYDOWN_DATE ? params.LAYDOWN_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.TOSITE_DATE ? '#000' : '#EEE'}">{{ params && params.TOSITE_DATE ? params.TOSITE_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.ERECTED_DATE ? '#000' : '#EEE'}">{{ params && params.ERECTED_DATE ? params.ERECTED_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.WELDBOLT_DATE ? '#000' : '#EEE'}">{{ params && params.WELDBOLT_DATE ? params.WELDBOLT_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.SUPPORTED_DATE ? '#000' : '#EEE'}">{{ params && params.SUPPORTED_DATE ? params.SUPPORTED_DATE:'No data'}}</div>
                  <div class="contents_text" :style ="{color: params && params.RFT_DATE ? '#000' : '#EEE'}">{{ params && params.RFT_DATE ? params.RFT_DATE:'No data'}}</div>
                </div>
              </div>
              </fieldset>
            </div>
          </div>
        </div>
      </v-card-title>

      <div class="pipe_popup_icons">
        <v-icon v-if="!onSpool" @click="onChangeSpool">mdi-chevron-down</v-icon>
        <v-icon v-else @click="onChangeSpool">mdi-chevron-up</v-icon>
      </div>

      <div v-if="onSpool" class="pipe_spool_list_wrap">
        <div class="pipe_spool_title">SPOOL LIST</div>
        <table style="table-layout:fixed;" class="pipe_spool_table">
          <colgroup>
              <col style="width:25px">
              <col style="width:65px">
              <col style="width:30px">
              <col style="width:50px">
              <col style="width:50px">
              <col style="width:50px">
              <col style="width:50px">
              <col style="width:50px">
              <col style="width:155px">
          </colgroup>
          <thead>
            <tr>
              <th>No</th>
              <th>Spool No.</th>
              <th>Sheet No.</th>
              <th>Weight</th>
              <th>Length</th>
              <th>Volumn</th>
              <th>Surface <br> Area</th>
              <th>Diameter</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(s, i) in spool" :key="i">
              <td>{{s.NO}}</td>
              <td>{{s.SPOOL_NO}}</td>
              <td>{{s.SHEET_NO}}</td>
              <td>{{s.SPOOL_WEGT}}</td>
              <td>{{s.SPOOL_LEN}}</td>
              <td>{{s.SPOOL_VOL}}</td>
              <td>{{s.SURFACE_AREA}}</td>
              <td>{{s.SPOOL_DIA}}</td>
              <td>{{s.STATUS}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import '@/assets/stylus/ui/component/_j3DpipingPopup.styl'
import { DBSupportService } from "@/services"
export default {
  name: 'j-modal-piping-popup',
  props: {
    params: {
      type: Object,
      default: () => ({})
      
    },
    
    value: null
  },
  data: () => ({
    onSpool: false,
    spool: []
  }),
  watch: {
    params(val) {
      this.onSpool = false
      this.getSpoolList(val.TAG_NO)
    }
  },
  created () {
    this.queryLibService = new DBSupportService()
  },
  computed: {
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val) },
    }
    
  },  
  methods: {
    onClose() {
      this.opened = false
    },
    onChangeSpool() {
      this.onSpool = !this.onSpool
    },
    getSpoolList(iso) {
      //  ISO_NO = 'mw01dc-pj-3450001-01'
      let filters = `ISO_NO = '${iso}'`
      this.queryLibService.getSqlQueryResult({ 
        idx: 21, 
        name: 'Piping Spool List',
        filters: filters
      }).then(res => {
        if (!res) this.spool = []
        else this.spool = res
      })
    }
  }
}
</script>
<style scoped lang="stylus">
.pipe_popup_icons {
  display: flex;
  justify-content: center;
}

.pipe_spool_list_wrap {
  max-height: 300px;
  overflow: auto;
  text-align: center;
  padding:1px 16px 16px;
}

.pipe_spool_title {
  text-align: left;
  color: #959595;
  font-size: 1rem;
  margin-bottom: 3px;
}

.pipe_spool_table {
  border-collapse: collapse;
}

.pipe_spool_table thead tr th {
  font-weight: 400;
  border: 1px solid #e1e1e1;
  padding: 3px;
  background-color: #e1f5fe;
}

.pipe_spool_table tbody tr {
  &:hover{
    background-color:#F5F5F5;
  }
  & td {
    border: 1px solid #e1e1e1;
    padding: 2px;
  }
}
</style>