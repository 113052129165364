import __C from '../primitives/_constant_'
import { FileApi } from './api'

export default class FileService {

  constructor() {
    this.fileApi = new FileApi()
  }

  download(fileId, config={}) {
    return new Promise(resolve => {
      this.fileApi.download(fileId, config).then(res => {
        resolve(res)
      })
    })
  }
  getOptionsList() {
    return new Promise(resolve => {
      this.fileApi.getOptionsList().then(res => {
        let optionsMap = ['lv1', 'lv2', 'lv3', 'area', 'block']
        let res__ = {}

        optionsMap.forEach(k => { 
          if (res[0][k].includes('},]')) resolve({})
          else if (res[0][k]) res__[k] = JSON.parse(res[0][k]) 
        })
    
        resolve(res__)
      })
    })
  }

  getFilterOptionsList() {
    return new Promise(resolve => {
      this.fileApi.getFilterOptionsList().then(res => {
        let optionsMap = ['lv1', 'lv2', 'lv3', 'area', 'block']
        let res__ = {}

        optionsMap.forEach(k => { 
          if (res[0][k].includes('},]')) res[0][k].replace('},]', '}]')
          else if (res[0][k]) res__[k] = JSON.parse(res[0][k]) 
        })
    
        resolve(res__)
      })
    })
  }

  getEqFilterOptionsList() {
    return new Promise(resolve => {
      this.fileApi.getEqFilterOptionsList().then(res => {
        let optionsMap = ['lv1', 'lv2', 'lv3', 'area', 'block']
        let res__ = {}

        optionsMap.forEach(k => { 
          if (res[0][k].includes('},]')) res[0][k].replace('},]', '}]')
          else if (res[0][k]) res__[k] = JSON.parse(res[0][k]) 
        })
    
        resolve(res__)
      })
    })
  }

  getEditInfo(idx) {
    return new Promise(resolve => {
      this.fileApi.getEditInfo(idx).then(res => {
        resolve(res)
      })
    })
  }

  getChEditInfo(idx) {
    return new Promise(resolve => {
      this.fileApi.getChEditInfo(idx).then(res => {
        resolve(res)
      })
    })
  }
  getEqEditInfo(idx) {
    return new Promise(resolve => {
      this.fileApi.getEqEditInfo(idx).then(res => {
        resolve(res)
      })
    })
  }
  getConEditInfo(idx) {
    return new Promise(resolve => {
      this.fileApi.getConEditInfo(idx).then(res => {
        resolve(res)
      })
    })
  }
  getFilesFromGallery(params) {
    return new Promise(resolve => {
      this.fileApi.getFilesFromGallery(params).then(res => {
        resolve(res)
      })
    })
  }
  getFilesFromChGallery(params) {
    return new Promise(resolve => {
      this.fileApi.getFilesFromChGallery(params).then(res => {
        resolve(res)
      })
    })
  }
  getFilesFromEqGallery(params) {
    return new Promise(resolve => {
      this.fileApi.getFilesFromEqGallery(params).then(res => {
        resolve(res)
      })
    })
  }
  getFilesFromConGallery(params) {
    return new Promise(resolve => {
      this.fileApi.getFilesFromConGallery(params).then(res => {
        resolve(res)
      })
    })
  }
  getFilesFromRenderingGallery(params) {
    return new Promise(resolve => {
      this.fileApi.getFilesFromRenderingGallery(params).then(res => {
        resolve(res)
      })
    })
  }
  putFile2Gallery (file) {
    return new Promise(resolve => {
      this.fileApi.putFile2Gallery(file).then(res => {
        resolve(res)
      })
    })
  }
  putFile2ChGallery (file) {
    return new Promise(resolve => {
      this.fileApi.putFile2ChGallery(file).then(res => {
        resolve(res)
      })
    })
  }
  putFile2EqGallery (file) {
    return new Promise(resolve => {
      this.fileApi.putFile2EqGallery(file).then(res => {
        resolve(res)
      })
    })
  }
  putFile2ConGallery (file) {
    return new Promise(resolve => {
      this.fileApi.putFile2ConGallery(file).then(res => {
        resolve(res)
      })
    })
  }
  putFile2RenderingGallery (file) {
    return new Promise(resolve => {
      this.fileApi.putFile2RenderingGallery(file).then(res => {
        resolve(res)
      })
    })
  }
  updFile2Gallery (file) {
    return new Promise(resolve => {
      this.fileApi.updFile2Gallery(file).then(res => {
        resolve(res)
      })
    })
  }
  updFile2ChGallery (file) {
    return new Promise(resolve => {
      this.fileApi.updFile2ChGallery(file).then(res => {
        resolve(res)
      })
    })
  }
  updFile2EqGallery (file) {
    return new Promise(resolve => {
      this.fileApi.updFile2EqGallery(file).then(res => {
        resolve(res)
      })
    })
  }
  updFile2ConGallery (file) {
    return new Promise(resolve => {
      this.fileApi.updFile2ConGallery(file).then(res => {
        resolve(res)
      })
    })
  }
  updFile2RenderingGallery (file) {
    return new Promise(resolve => {
      this.fileApi.updFile2RenderingGallery(file).then(res => {
        resolve(res)
      })
    })
  }
  delFileFromGallery (idx, fileId) {
    return new Promise(resolve => {
      this.fileApi.delFileFromGallery(idx, fileId).then(res => {
        resolve(res)
      })
    })
  }
  delFileFromEqGallery (idx, fileId) {
    return new Promise(resolve => {
      this.fileApi.delFileFromEqGallery(idx, fileId).then(res => {
        resolve(res)
      })
    })
  }
  delFileFromConGallery (idx, fileId) {
    return new Promise(resolve => {
      this.fileApi.delFileFromConGallery(idx, fileId).then(res => {
        resolve(res)
      })
    })
  }
  delFileFromRenderingGallery (idx, fileId) {
    return new Promise(resolve => {
      this.fileApi.delFileFromRenderingGallery(idx, fileId).then(res => {
        resolve(res)
      })
    })
  }
}