<template>
  <div class="j_mc_coiitem">
    <div class="lnb">
    </div>
    <div class="content">
      <a v-ripple class="btn-attach" title="add new attachment" @click="onOpenFile">
        <i class="mdi mdi-attachment"></i>
      </a>
      <div class="coiitem_header">
        <span class="coiitem_title">Regulatory - Certificate of Inspection </span>
        <span class="coiitem_sub_title">{{ title }}</span>
      </div>
      <div v-if="progress > 0" class="progress_bar">
        <div class="progress" :style="progress ? `width: ${progress}%;` : ''"></div>
      </div>
      <div v-if="error" class="upload_error">{{ error }}</div>
      <div class="item_info_wrapper">
        <div class="item_info">
          <span class="item_label">Item No.</span>
          <span class="item_value">{{ item.ITEM_NO }}</span>
          <span class="spacer"></span>
          <span class="item_label">Status</span>
          <span class="item_value">{{ item.STATUS }}</span>
          <span class="spacer"></span>
          <span class="item_label">Reg.Body</span>
          <span class="item_value">{{ item.REG_BODY }}</span>
        </div>
        <div class="item_info">
          <span class="item_label">Description</span>
          <span class="item_value w100">{{ item.DESCR }}</span>
        </div>
        <div class="item_info">
          <span class="item_label">References</span>
          <span class="item_value w100">{{ item.REF }}</span>
        </div>
        <div class="item_info no-margin">
          <span class="item_label">Comment / Guidance</span>
        </div>
        <div class="item_info">
          <span class="item_value w100 multi"><pre>{{ item.COMMENTS }}</pre></span>
        </div>
      </div>
      <div class="attachment_wrapper">
        <div v-for="(file, i) in files" class="attachment" :key="file.idx">
          <span class="no">{{ i + 1 }}</span>
          <a class="file_name" @click="onDownload(file.idx)">{{ file.name }}</a>
          <span class="date">{{ file.date }}</span>
          <span class="size">{{ file.size ? file.size.toLocaleString() : 0 }} KB</span>
          <span class="remove">
            <a v-ripple class="btn-delete" title="delete" @click="onDelete(file.idx)">
              <i class="mdi mdi-trash-can-outline"></i>
            </a>
          </span>
        </div>
      </div>
      <div v-if="preview" class="image_preview">
        <img :src="`${__C_.HOST_NAME}${preview.path}`" >
        <span>
          <a v-ripple class="download_sheet" @click="onDownload(preview.idx)">Download Confirmation Sheet</a>
        </span>
      </div>
    </div>

    <input 
      type="file" 
      multiple
      id="coi_files" 
      name="coi_files" 
      accept="image/*, .doc, .docx, .xls, .xlsx, .pdf, .csv, .txt, .zip"
      style="display:none;"
      @change="onHandleFiles"
    >

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_jMCCOIItem.styl'

import __C from '@/primitives/_constant_'
import { MD2Service } from "@/services"
import Loading from '@/mixins/loading.mixin'
import Url from '@/services/api/request.url'

export default {
  name: "j-mc-coiitem",
  mixins: [
    Loading
  ],
  props: {
    filters: {
      type: Object,
      default: () => ({})
    },
    target: {
      type: Object,
      default: () => ({
        code: '',   // N/A for COI-Item compoment  
        type: '',   // N/A for COI-Item compoment  
        id: Number,
        no: String
      })
    },
  },
  data: () => ({
    // Service Instances -------------
    md2Service: null,
    item: {},
    
    error: '',
    localFiles: [],
    progressData: {},

    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},
  }),
  computed: {

    __C_() { return __C },

    files() { return this.item && this.item.files ? this.item.files : [] },
    preview() { return this.files.length > 0 && this.files[0].preview ? this.files[0] : null },
    progress: {
      get() {
        return (
          !this.progressData || 
          !this.progressData.complete || 
          !this.progressData.total ? 
          0 : 
          Math.floor((this.progressData.complete * 100) / this.progressData.total)
        )
      },
      set(val) { this.progressData = val }
    },
    title() { return this.filters && this.filters.filters && this.filters.filters.TOPIC ? this.filters.filters.TOPIC : '' }
  },
  watch: {
    target: {
      handler(val) {
        if(!val) return
        // this.getPageSvg({
        // })
      }
    }
  },
  created() {
    this.md2Service = new MD2Service()
  },
  mounted() {
    // if(this.target.id) this.getPageSvg({
    // })
  },
  methods: {
    onDelete(fileId) {
      this.yes = () => {
        this.msgOpen = false
        this.md2Service.delCoiFile(fileId).then(() => {
          this.getCoiItem()
        })
        this.yes = () => {}
      }

      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Action Approval"
      this.msgInfo.message = "Do you want to delete this attachment?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText = ['Yes', 'No', 'Cancel']
      this.msgInfo.buttonText[0] = "Delete"
      this.msgOpen = true
    },
    onDownload(fileId) {
      var iframe = document.createElement('iframe')
      document.body.appendChild(iframe)
      let params = new URLSearchParams()
      params.append('token', this.account.token)
      iframe.src = `${Url.download}/${fileId}?${params.toString()}`

      setTimeout(() => {
        iframe.parentNode.removeChild(iframe)
      }, 10000);
    },
    onHandleFiles(e) {
      if(!e || !e.target) return
      this.appendFiles(Object.values(e.target.files).filter(f_ => !!f_))
    },
    onOpenFile() {
      document.querySelector(`input[name='coi_files']`).click()
    },
    onSave(_files) {
      let data_ = {
        itemNo: this.item.ITEM_NO,
        files : _files.map(f => {
          let fileNameParts = f.name.split('.')
          return {
            name      : f.name,
            mimeType  : f.type,
            fileType  : f.type.split('/')[0],
            ext       : fileNameParts[fileNameParts.length-1],
            dataUrl   : '',
            size      : Number(f.size),
            dimention : '',
          }
        })
      }

      let params = new FormData()
      params.append("data", JSON.stringify(data_))
      _files.forEach(f => { params.append("files", f) })

      let config = {
        onUploadProgress: e => {
          this.progress = {
            complete: e.loaded,
            total: e.total
          }
        }
      }
      this.md2Service.putCoiFiles(params, config).then(() => {
        this.progress = { complete: 0, total: 0 }
        this.getCoiItem()
      })
    },

    appendFiles(_files) {
      let maxFiles = 5
      let maxSize = 1000 * 1024000
      let currentFiles = this.localFiles.length + _files.length
      let currentSize = _files.map(f => f.size).reduce((a, b) => a + b, 0)

      if(currentFiles > maxFiles || currentSize > maxSize) {
        this.error = `Attachements are limited at 5 files, 1GB.`
        setTimeout(() => { this.error = '' }, 5000)
        return
      }

      this.onSave(_files)
    },
    getCoiItem() {
      return this.md2Service.getCoiItem(this.target.no).then(res => {
        if(!res) res = {}
        this.item = res
      })
    },
    run() {
      this.getCoiItem()
      this.$emit('complete', {
        name: this.title,
        dimention: {
          width: 650,
          height: 750
        }
      })
    }
  }
}
</script>
