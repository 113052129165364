<template>
  <div class="j_service_svg_page">

    <j-page-layout-builder-svg
      :mode="__C_.DASHBOARD.BUILDER_MODE_VIEWER"
      :chart-items="chartItems"
      :filters="filteredValues"
      :callback="{ ...callbackData, ...popupFreeCallback }"
      @request-action="onRequestedAction"
      @resize="onDashboardResize"
      @selectedItems = "selectedItems"
      @complete = "onComplete"
    />

    <j-page-svg-information
      v-if="chartComplete && infoRequired"
      class="j_page_svg_information"
      :chart-data="chartData"    
      :local-filters="localFilters"  
      @filter-values="onCodeChanged"
      @request-action="onRequestedAction"
    />

    <j-modal-slide-component 
      v-model="modalOpen"
      :component="componentName"
      :filters="filtersModal"
      :target="target"
      :prop-export-info="propExportInfo"
    />

  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"
import Loading from '@/mixins/loading.mixin'
import JPageSvgInformation from '@/components/JPageSvgInformation'
import JServiceComponents from '@/components/JServiceComponentTargets'

import { files as FileManagerApi } from '../../../lib/filebrowser/src/api'

export default {
  name: "service-svg-page",
  mixins: [
    Loading
  ],
  components: {
    JPageSvgInformation,
    ...JServiceComponents,
  },
  data: () => ({
    rData : null,
    componentName: '',
    target: {},
    propExportInfo: {},
    filtersModal: {},

    dimention: {},
    localFilters: {},
    infoRequired: false,
    
    chartComplete: false,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'commentOpened', 
      'modalOpened', 
      'popupEqOpened',
      'popupHlEqOpened',
      'popupFreeOpened', 
      'popupHlsOpened', 
      'popupFreeCallback',
      'callbackData'
    ]),
    ...mapState(__C.STORE_NAMESPACE.DASHBOARD, [
      'pageSvg', 'resultSet'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.APPLICATION, [
      'navStateId'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.APP_SERVICE, [
      'filteredValues'
    ]),

    __C_() { return __C },

    chartItems() {
      return !this.resultSet.chartItem || Object.keys(this.resultSet.chartItem).length === 0 ? [] : [this.resultSet.chartItem] 
    },
    modalOpen: {
      get() { return this.modalOpened },
      set(val) { return this.setModalOpened(val) },
    },
    //information guide v1
    chartData() {
      return this.resultSet.chartItem.QResultSummary
    },
    //information guide v2
    // chartData2() {
    //   return this.resultSet.chartItem.QResultExtras.SQL1
    // },
  },

  // watch: {
  //   '$route.query'(val) {
  //     if(!val) return
      
  //     let urlqueries = this.$route.query
  //     if(urlqueries.filters) {
  //       this.localFilters = JSON.parse(urlqueries.filters)
  //       this.setFilteredValues(JSON.parse(urlqueries.filters))
  //     }
  //     this.infoRequired = urlqueries.useinfo == 'true' ? true : false
  //   }
  // },

  beforeCreate() {
    this.loading = true
    this.chartComplete = false
  },
  created() {
    if (this.$route.path.includes('ServicePageSvgForMC')) {
      let action__ = JSON.parse(this.$route.params.action)
      let targetId = action__.target.id
      let id__ = __C.HOST_NAME.includes('dev') ? 788 : 760  // MENU-IDX, Check too createAction in service.module.js
      // let id__ = __C.HOST_NAME.includes('dev') ? 788 : targetId == 1025 ? 761 : 760 // MENU-IDX (console.log)

      this.setNavState({
        code: "M9-01-01", 
        groupCode: "M9-01",
        groupName: "Mechanical Completion",
        id: id__,
        name: "MC Skyline",
        parentCode: "M9",
        parentName: "System Completion",
        url: "/service/system_completion/mechanical_completion/mc_skyline/mc_skyline"
        // url: "/service/system_completion/mechanical_completion/mc_skyline/mc_skyline"
      })
      this.setDashboardId(id__)
    }

    this.setChild(__C.STORE_NAMESPACE_KEY.DASHBOARD)
    this.setType(__C.DASHBOARD.TYPE_CODE_PAGESVG)
    this.setPagecallFunc(this.run)
  },
  mounted() {

  },

  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.CONFIG, ['setLayout']),
    ...mapMutations(__C.STORE_NAMESPACE.APPLICATION, [
      'setPagecallFunc', 'setNavState'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild', 
      'setCommentOpened', 
      'setCommentProps', 
      'setFilteredValues', 
      'setModalOpened',
      'setItems',
      'setPopupFreeOpened',
      'setPopupHlsOpened',
      'setPopupEqOpened',
      'setPopupHlEqOpened',
      'setSelectItems',
      'setImgPopupOpened',
      'setSharedData',
      'setCommentTitle'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.DASHBOARD, [
      'setDimention',
      'setExtHeight',
      'setType',
      'setDashboardId'
    ]),
    ...mapActions(__C.STORE_NAMESPACE.DASHBOARD, [
      'getPageSvg', 'initService'
    ]),

    selectedItems(items) {

      this.setItems(items)
      if(items.lang) {
        this.localFilters.TOGGLE = items.lang == 'on' ? 'EN' : 'CN'
      }
      if(items.info) {
        this.infoRequired = items.info == 'on' ? true : false
        if(items.info == 'on' && items.area) {
          //console.log('selectedItems-items.area',items.area)
          this.localFilters.SEICODE_L1 = items.area
          this.localFilters.PLANT = items.plant
          //console.log('localFilters.SEICODE_L1',this.localFilters.SEICODE_L1)
        }
        if(this.infoRequired) this.localFilters.infoRequired = this.infoRequired
        //console.log('filteredValues.last',this.filteredValues)
        this.localFilters = { ...this.localFilters }
        //console.log('localFilters.last',this.localFilters)
      }
      
    },
    onComplete(v) {
      this.chartComplete = true
      // for the pdf printing from the API
      if(this.filteredValues && this.filteredValues.infoRequired) {
        this.infoRequired = true
        this.localFilters = { ...this.filteredValues }
      }
      if(this.infoRequired) {
        setTimeout(() => {
          let elbox = document.querySelector('.j_page_svg_information').getBoundingClientRect()
          this.setExtHeight(elbox.height)
        }, 1000)
      }
    },
    onDashboardResize(d) {
      this.dimention = d
      this.setDimention(d)
    },
    onRequestedAction(request) {
      let action___ = (
        request.sender.action && 
        request.sender.action.type == 'direct' ? 
        request.sender.action : 
        request.parent.action
      )
      
      if(!action___) {
        console.log(`[USER: undefined 'action'] Target is not defined.`)
        return
      }
      
      if(request.sender.action.target == 'routeto') {  // for Information Guide
        if(!request.sender.action.path) console.log(`[USER: PROP UNDEFINED] Target path is not defined.`)
        // else {
        //   let query =''
        //   if(request.sender.action.path == '/service/none_navi_items/blank_window/facility_map_information_guide'){
        //     query = '?layout=hidden&valign=center&useinfo=false'
        //   } else {
        //     query = '?layout=hidden&valign=flex-start&useinfo=true'
        //   }
        //   if(Object.keys(request.sender.filters).length > 0) query = `${query}&filters=${JSON.stringify(request.sender.filters)}`
        //   this.loading = true
        //   setTimeout(() => {
        //     this.$router.push(request.sender.action.path + query)  
        //     this.loading = false
        //   }, 1200);
        // }
        else this.$router.push(request.sender.action.path)
        return
      }

      if(request.sender.action.target == 'filter') {  // for mc skyline export pdf
        this.setFilteredValues({ ...request.sender.filters })
        return
      }

      // only for retrieving data result with given filter-value
      if(action___.target == 'none') {  
        if(Object.keys(request.sender).includes('loading')) this.loading = request.sender.loading
        else this.loading = true
        
        this.setFilteredValues({ ...this.filteredValues, ...request.sender.filters })
        // params type object 
        this.getPageSvg({ reqType: 'filtered', action: action___.name ? action___ : null }).then(() => {
          setTimeout(() => { this.loading = false }, 150)
        })
        return
      }
  
      if(action___.target == __C.REQUEST_ACTION.TARGET_URL) {
        // if (Object.keys(request.sender.filters).length > 0) this.setFiltersPushed(request.sender.filters)
        this.$router.push(action___.path)
        return
      }

      if(action___.target == __C.REQUEST_ACTION.TARGET_SINGLE_FILE_DOWNLOAD) {
        FileManagerApi.download(null, '', action___.path)
        return
      }

      if(!action___.component) {
        console.log(`[USER: undefined 'component'] Target Name is not defined.`)
        return
      }
      if(action___.component == __C.REQUEST_ACTION.COMPONENT_COMMENT) {
        if(action___.commentProps && action___.commentProps.title) this.setCommentTitle(action___.commentProps.title)

        if(action___.commentProps) this.setCommentProps(JSON.parse(JSON.stringify(action___.commentProps)))
        else this.setCommentProps({ refCode: `${__C.REQUEST_ACTION.COMPONENT_SVGPAGE}_${this.navStateId}` })

        this.setCommentOpened(true)

        return
      }

      if(action___.target == __C.REQUEST_ACTION.TARGET_IMG_POPUP) {
        this.setImgPopupOpened({
          open: true,
          imgPath: request.sender.imgPath,
          dimention: request.sender.dimention
        })
        return
      }

      // For the Pop-Up process
      if(action___.target != __C.REQUEST_ACTION.TARGET_POPUP && !action___.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }

      if([
        __C.REQUEST_ACTION.TARGET_POPUP,
        __C.REQUEST_ACTION.TARGET_ROOM_POPUP,
        __C.REQUEST_ACTION.TARGET_POPUP_HL_EQ
      ].includes(action___.target)) {
        if(request.sender.shared) this.setSharedData(request.sender.shared)
        if(action___.purpose == 'equipment-search' && !this.popupEqOpened) return

        this.setPopupEqOpened({
          open: true,
          queryid: action___.id,
          filters: request.sender.filters,
          data: request.sender.data,
          action: request.sender.action
        })
        return
      }
      // if(__C.REQUEST_ACTION.TARGET_POPUP_HL_EQ == action___.target) {
      //   this.setPopupHlEqOpened({
      //     open: true,
      //     queryid: action___.id,
      //     filters: request.sender.filters,
      //     data: request.sender.data,
      //   })
      //   return
      // }
      if(__C.REQUEST_ACTION.TARGET_POPUP_FREE == action___.target) {
        if(request.sender.shared) {
          this.setSharedData(request.sender.shared)
          return
        }
        if(action___.purpose == 'equipment-search' && !this.popupFreeOpened) return

        this.setPopupFreeOpened({
          open: true,
          component: action___.component,
          queryid: action___.id,
          queryname: action___.no,
          filters: request.sender.filters,
          filterString: request.sender.filterString,
          data: request.sender.data,
        })
        return
      }

      if(__C.REQUEST_ACTION.TARGET_POPUP_HLS == action___.target) {
        this.setPopupHlsOpened({
          open: true,
          component: action___.component,
          filters: request.sender.filters,
          data: request.sender.data,
        })
        return
      }
      
      this.setModalOpened(true)
      this.componentName = action___.component
      this.target = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: '',
        id: action___.id,
        no: action___.no
      }
      this.filtersModal = {
        filters: request.sender.filters,
        iFilters: request.sender.iFilters
      }
      this.propExportInfo = request.export
      
      
      console.log(`[USER: #DEBUG] Export Info  [0]: ${this.componentName} ${JSON.stringify(this.propExportInfo)}`)
      console.log(`[USER: #DEBUG] Target  [0]: ${this.componentName} ${JSON.stringify(this.target)}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${JSON.stringify(this.filtersModal.filters)}`)
      console.log(`[USER: #DEBUG] Filters [2]: ${JSON.stringify(this.filtersModal.iFilters)}`)
    },
    onCodeChanged(filters) {
      this.localFilters = filters
      this.setFilteredValues(this.localFilters)
    },
    run() {
      return this.initService().then(() => {

        let urlqueries = this.$route.query

        this.infoRequired = urlqueries.useinfo == 'true' ? true : false
        if(this.infoRequired) this.setFilteredValues({ infoRequired: true })

        if(urlqueries.filters) {
          this.localFilters = JSON.parse(urlqueries.filters)
          this.setFilteredValues({ ...this.filteredValues, ...JSON.parse(urlqueries.filters) })
        }

        return this.getPageSvg('init')
      })
    },
  }
}
</script>
