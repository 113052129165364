import { VCard, VCardActions, VDialog, VSpacer } from 'vuetify/lib'
import JButton from '../JButton'
import '../../../../src/assets/stylus/ui/_alert.styl'

export default {
  name: 'j-alert',
  components: {
    JButton,
    VCard,
    VCardActions,
    VDialog,
    VSpacer
  },
  props: {
    activatorOn: Boolean,
    button: {
      type: Array,
      default: () => [true, true, true]
    },
    buttonText: {
      type: Array,
      default: () => ['Yes', 'No', 'Cancel']
    },
    maxWidth: {
      type: [String, Number],
      default: '290'
    },
    message: {
      type: String,
      default: 'No informing message.'
    },
    title: String,
    titleDescription: String,
    type: {
      type: String,
      default: 'INFO' // ['INFO', 'WARN']
    },
    value: null
  },
  render() {
    let contentClass = 'j_alert type01 ' + (this.type == 'INFO' ? 'success' : 'alert')
    let _attrs = {
      buttons: [
        {
          class: { type02: true, pink: this.type == 'WARN', sky: this.type == 'INFO' },
          on: { click: () => this.$emit('yes') }
        },
        {
          class: { type02: true, pink: this.type == 'WARN', sky: this.type == 'INFO' },
          on: { click: () => this.$emit('no') }
        },
        {
          class: { type02: true, white: true },
          on: { click: () => this.$emit('cancel') }
        }
      ],
      message: {
        domProps: {
          innerHTML: this.message
        }
      },
      vDialog: {
        attrs: {
          maxWidth: this.maxWidth,
          persistent: true,
          value: this.value
        },
        on: {
          input: e => this.$emit('input', e.target.value)
        },
        props: {
          contentClass: contentClass,
          // noClickAnimation: true,
          value: this.value
        }
      }
    }
    return (
      <v-dialog {..._attrs.vDialog}>
        <v-card>
          <div class='alert__contents'>
            <i></i>
            <p>
              <span>{this.title || (this.type == 'INFO' ? 'Success!' : 'Alert!')}</span>
              <small {..._attrs.message}></small>
            </p>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            {this.button.map((b, i) => {
              if (b) return <j-button {..._attrs.buttons[i]}>{this.buttonText[i]}</j-button>
            })}
          </v-card-actions>
        </v-card>
      </v-dialog>
    )
  }
}
