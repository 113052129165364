import * as d3 from 'd3'

export default {
  methods: {

    draw_Overall(svg) {
      let cumulative = svg
      .append('g')
      .attr('id', `cumulative_${this.localId}`)

      let weekly = svg
      .append('g')
      .attr('id', `weekly_${this.localId}`)

      this.draw_Status(cumulative, 'cumulative', 'CUM')
      this.draw_Status(weekly, 'weekly', 'WK')

      let weeklyX = this.chartInfo.width - this.getNodeElValue(`#weekly_${this.localId}`, 'width')
      d3.select(`#weekly_${this.localId}`).attr('transform', `translate(${weeklyX}, 0)`)

      let y = this.chartInfo.overall.currentTSize * 2 + this.chartInfo.overall.tSize + 5

      let progress = svg
      .append('g')
      .attr('id', `progress_bar_${this.localId}`)
      .attr('transform', `translate(0,${this.getNodeElValue(`#cumulative_${this.localId}`, 'height') + 3})`)

      progress
      .append('rect')
      .attr('width', this.chartInfo.width).attr('height', this.chartInfo.overall.bar.height)
      .attr('fill', this.chartInfo.overall.bar.bgColor)
      .attr('opacity', this.chartInfo.overall.bar.bgOpacity)
      .attr('rx', 3)
      .attr('ry', 3)

      progress
      .append('rect')
      .attr('width', this.scale.oa(this.overallData.AC_CUM)).attr('height', this.chartInfo.overall.bar.height)
      .attr('fill', `url(#${this.localId}_${this.chartInfo.overall.bar.color}_0)`)
      .attr('opacity', this.chartInfo.overall.bar.opacity)
      .attr('rx', 3)
      .attr('ry', 3)

      progress
      .append('text')
      .attr('x', this.overallData.AC_CUM > 50 ? this.scale.oa(this.overallData.AC_CUM) - 5 : this.scale.oa(this.overallData.AC_CUM) + 5)
      .attr('y', this.chartInfo.overall.bar.height / 2)
      .attr('font-size', this.chartInfo.overall.bar.tSize)
      .attr('fill', this.chartInfo.overall.bar.tColor)
      .attr('text-anchor', this.overallData.AC_CUM > 50 ? 'end' : 'start')
      .attr('alignment-baseline', 'central')
      .text(`${this.overallData.AC_CUM}%`)
    },
    
    draw_Status(svg, id, col) {
      let y = this.chartInfo.overall.currentTSize

      let Actual = svg
      .append('g')
      .attr('id', `act_${id}_${this.localId}`)

      Actual
      .append('text')
      .attr('y', y)
      .attr('font-size', this.chartInfo.overall.currentTSize)
      .attr('fill', this.chartInfo.overall.tColor)
      .text(col == 'CUM' ? 'Cumulative' : 'This week')

      let x = this.getNodeElValue(`#act_${id}_${this.localId}`, 'width') + 3

      Actual
      .append('text')
      .attr('x', x).attr('y', y)
      .attr('font-size', this.chartInfo.overall.currentTSize)
      .attr('fill', this.chartInfo.overall.currentTColor)
      .text(`(${this.overallData[`AC_${col}`]}%)`)

      y += this.chartInfo.overall.currentTSize + 5

      let Plan = svg
      .append('g')
      .attr('id', `plan_${id}_${this.localId}`)

      Plan
      .append('text')
      .attr('y', y)
      .attr('font-size', this.chartInfo.overall.tSize)
      .attr('fill', this.chartInfo.overall.tColor)
      .text('Early')

      x = this.getNodeElValue(`#plan_${id}_${this.localId}`, 'width') + 5

      Plan
      .append('text')
      .attr('x', x).attr('y', y)
      .attr('font-size', this.chartInfo.overall.tSize)
      .attr('fill', this.chartInfo.overall.tColor)
      .text(`${this.overallData[`PE_${col}`]}%`)

      x = this.getNodeElValue(`#plan_${id}_${this.localId}`, 'width') + 5

      let deltaColor = '#333'
      let deltaValue = this.overallData[`DE_${col}`]
      if (deltaValue < 0) {
        deltaColor = '#EC407A'
        deltaValue *= -1
      } else if (deltaValue > 0) {
        deltaColor = '#44A9DF'
      }

      Plan
      .append('text')
      .attr('x', x).attr('y', y)
      .attr('font-size', this.chartInfo.overall.tSize)
      .attr('fill', deltaColor)
      .text(`${deltaValue}%`)

      x = this.getNodeElValue(`#plan_${id}_${this.localId}`, 'width') + 5
      
      let deltaPath = `M ${x}, ${y - 3} L ${x + 6}, ${y - 3} L ${x + 6}, ${y - 4} L ${x}, ${y - 4} Z`
      if (deltaValue !== 0) {
        deltaPath = `M ${x + 2}, ${y - 8} L ${x + 6}, ${y - 8} L ${x + 6}, ${y - 4} 
        L ${x + 8}, ${y - 4} L ${x + 4}, ${y} L ${x}, ${y - 4} 
        L ${x + 2}, ${y - 4} Z`
      }

      let rotate = `0`
      if (deltaColor == '#44A9DF') rotate = `180, ${x + 4}, ${y - 4}`

      Plan
      .append('path')
      .attr('id', `#delta_early_${id}`)
      .attr('d', deltaPath)
      .attr('fill', deltaColor)
      .attr('transform', `rotate(${rotate})`)

      x = this.getNodeElValue(`#plan_${id}_${this.localId}`, 'width') + 15

      Plan
      .append('text')
      .attr('x', x).attr('y', y)
      .attr('font-size', this.chartInfo.overall.tSize)
      .attr('fill', this.chartInfo.overall.tColor)
      .text('Late')

      x = this.getNodeElValue(`#plan_${id}_${this.localId}`, 'width') + 5

      Plan
      .append('text')
      .attr('x', x).attr('y', y)
      .attr('font-size', this.chartInfo.overall.tSize)
      .attr('fill', this.chartInfo.overall.tColor)
      .text(`${this.overallData[`PL_${col}`]}%`)

      x = this.getNodeElValue(`#plan_${id}_${this.localId}`, 'width') + 5

      deltaColor = '#333'
      deltaValue = this.overallData[`DL_${col}`]
      if (deltaValue < 0) {
        deltaColor = '#EC407A'
        deltaValue *= -1
      } else if (deltaValue > 0) {
        deltaColor = '#44A9DF'
      }

      Plan
      .append('text')
      .attr('x', x).attr('y', y)
      .attr('font-size', this.chartInfo.overall.tSize)
      .attr('fill', deltaColor)
      .text(`${deltaValue}%`)

      x = this.getNodeElValue(`#plan_${id}_${this.localId}`, 'width') + 5
      
      deltaPath = `M ${x}, ${y - 3} L ${x + 6}, ${y - 3} L ${x + 6}, ${y - 4} L ${x}, ${y - 4} Z`
      if (deltaValue !== 0) {
        deltaPath = `M ${x + 2}, ${y - 8} L ${x + 6}, ${y - 8} L ${x + 6}, ${y - 4} 
        L ${x + 8}, ${y - 4} L ${x + 4}, ${y} L ${x}, ${y - 4} 
        L ${x + 2}, ${y - 4} Z`
      }

      rotate = `0`
      if (deltaColor == '#44A9DF') rotate = `180, ${x + 4}, ${y - 4}`

      Plan
      .append('path')
      .attr('id', `#delta_late_${id}`)
      .attr('d', deltaPath)
      .attr('fill', deltaColor)
      .attr('transform', `rotate(${rotate})`)
    },
  }
}