import * as d3 from 'd3'
import __M from 'moment'

export default {
  methods: {
    draw_SkylineCharts() {

      let self = this
      let BoxesChart = this.SkylineCharts
      .append('g')
      .attr('class', 'skyline_boxes')
      .attr('transform', `translate(${this.timeline.baseX}, 0)`)

      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this);
        });
      }

      this.timeline.month.forEach((w, i) => {

        let drawY = this.timeline.baseY - this.skyline.skyBox.height - (this.skyline.skyBox.gap/2)
        let dateFiltered = this.rawData.filter(f => f.cDate == w.eDate)
        let self = this
        dateFiltered.forEach((d, i) => {
          
          let x1 = this.timeline.scale(d.sDate) + this.skyline.skyBox.gap
          let y1 = drawY
          let width_ = this.timeline.scale(d.cDate) - this.timeline.scale(d.sDate) - this.skyline.skyBox.gap

          let Boxes = BoxesChart
          
          .append('g')
          .attr('id', `BOX_${d[this.skyline.refColumn]}`)
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            let factor = (self.skyline.scale - 1) / 2
            let scale_x = width_ * factor
            let scale_y = self.skyline.skyBox.height * factor
            
            d3.select(this).moveToFront().transition().duration(150)
            .attr('transform', `translate(${x1-scale_x},${y1-scale_y}) scale(${self.skyline.scale})`)

            d3.select('#Detail_Group').transition().duration(300)
            .style('opacity', 1)

            self.mouseOver_Detail(d[self.skyline.refColumn]) // ---> Detailed Information
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)

            d3.select('#Detail_Group').transition().delay(500).duration(300)
            .style('opacity', self.detail.onOff == 'ON' ? 1 : 0)
          })
          .on('click', () => { this.link_Skyline(d[this.skyline.refColumn]) })

          Boxes
          .append('rect') // Background
          .attr('x', 0).attr('y',0)
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', '#fff')
          .attr('fill-opacity', d.opacity)
          .attr('stroke', d.sColor)
          .attr('stroke-width', d.sWidth)

          Boxes
          .append('rect') // Fill Color
          .attr('x', 0).attr('y',0)
          .attr('rx', d.radius)
          .attr('ry', d.radius)
          .attr('width', d.type !== '' ? width_ * (d[d.type]/100) : width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', d.bColor)
          .attr('fill-opacity', d.opacity)

          Boxes
          .append('text')
          .attr('x', width_/2)
          .attr('y', this.skyline.skyBox.height/2 + 1)
          .attr('font-family', this.skyline.skyBox.font)
          .attr('font-size', d.tSize)
          .attr('fill', d.tColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(d[this.skyline.refColumn].length > this.skyline.skyBox.tMaxLen ? d[this.skyline.refColumn].substr(0,this.skyline.skyBox.tMaxLen - 1) + '...' : d[this.skyline.refColumn])

          drawY = drawY - this.skyline.skyBox.height - (this.skyline.skyBox.gap)

          if(i == dateFiltered.length-1) {
            BoxesChart
            .append('text')
            .attr('class', 'block_group_count')
            .attr('transform', `translate(${x1 + (width_/2)}, ${drawY + this.skyline.skyBox.height - 5})`)
            .style('font-family', 'roboto')
            .style('font-size', 10)
            .style('fill', '#44A9DF')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .attr('visibility', 'visible')
            .text(dateFiltered.length)

          }
        })
      })
    },

    
  }
}
