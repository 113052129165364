export default {
  data: () => ({
    rawData: [],

    summaryTop: [],
    summaryValues: [],

    styles: null,

    cutoff: null,

    rowTitle: null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1))
      let sql2 = JSON.parse(JSON.stringify(this.Queries.SQL2))
      let sql3 = JSON.parse(JSON.stringify(this.Queries.SQL3[0]))

      sql1.forEach((d,i) => {
        this.summaryTop[i] = {
          title: d.TITLE,
          colWidth: d.COL_WIDTH,
          children: d.CHILDREN.replace(/\s/g, '').split('/'),
          div: d.DIVIDE,
          ref: d.REF.replace(/\s/g, '').split('/'),
          merge: d.MERGE.replace(/\s/g, '').split('/'),
          color: d.COLOR.replace(/\s/g, '').split('/'),
          link: d.LINK,
        }
      })

      sql2.forEach((d,i) => {
        this.summaryValues[i] = {
          title: d.TITLE,
          col: d.COL,
        }
      })

      this.styles = {
        titleHeight: sql3.TITLE_HEIGHT,
        lineHeight: sql3.LINE_HEIGHT,

        hFontSize: sql3.HEADER_FONT_SIZE,
        bFontSize: sql3.BODY_FONT_SIZE,
      }

      this.cutoff = sql3.CUTOFF
      this.rowTitle = sql3.ROW_TITLE
    },
  }
}