<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>

import mx_Core from './includes/mixins/zMixins'        

export default {
  name: "j-Skyline_Milestone",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.setDefaultValues()
      this.drawCanvas()
      
      this.drawing() // <------ Drawing Start
      
      this.drawNotes()
      this.drawTexts()
      
      this.complete()
    },

    drawing() {

      this.CanvasArea = this.svg
      .append('g')
      .attr('id', `Cansvas_Area${this.localId}`)
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('font-family', 'roboto')
      
      this.set_Gradients()
      this.draw_Header()

      // for export pdf
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}

      if(this.getSelectItems) {
        if(this.getItems) {
          this.draw_PDF()
        }
      }

    },
  }
}

</script>

