export default {
  layout: {
    lnb: {
      visible: true
    },
    gnb: {
      visible: true
    },
    anb: {
      visible: true
    },
    snb: {
      visible: true
    },
    fnb: {
      visible: true
    }
  }
}
