import * as d3 from 'd3'
import __C from '../../../../../includes/primitives/_constant_'

export default {

  methods: {

    drawForms() {

      let Pyramid = this.svg
      .append('g')
      .attr('id', 'Pyra')
      .attr('transform', 'translate(-100, -50) scale(1.5)')

      this.loadSvg(
        Pyramid, {
        x   : 0,
        y   : -35,
        url : `/China1/Images/Safety_Pyramid.svg`
      }).then(() => {

        Pyramid
        .append('text')
        .attr('x', 205).attr('y', 50)
        .style('fill', 'black').style('font-size', '13px').style('text-anchor','middle').style('alignment-baseline','middle').attr('font-weight','normal')
        .text('High Potential Diamond')


  
        this.DataItems.forEach((d,i) => {
          Pyramid
          .append('text')
          .attr('x', 166.5).attr('y', 110+i*22)
          .style('fill', 'black').style('font-size', '8px').style('text-anchor','middle').style('alignment-baseline','middle').attr('font-weight','bold')
          .text(d.LEFT_NO)
  
          Pyramid
          .append('text')
          .attr('x', 208).attr('y', 105+i*22)
          .style('fill', '#0000FF').style('font-size', '8px').style('text-anchor','middle').style('alignment-baseline','middle').attr('font-weight','bold')
          .text(d.RIGHT_NO)

          // Pyramid
          // .append('text')
          // .attr('x', 205).attr('y', 70)
          // .style('fill', 'black').style('font-size', '11px').style('text-anchor','middle').style('alignment-baseline','middle').attr('font-weight',250)
          // .text(d.PACKAGE)
        })
  
        d3.select(`#Left_Text`).attr('font-family','roboto').attr('font-weight','normal')
        d3.select(`#Right_Text`).attr('font-family','roboto').attr('font-weight','normal')
        
        this.drawTexts()
      })
    },

  }
}