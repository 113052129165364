export default {
  methods: {
    drawTitles() {
      if (this.Title.TitleMainDisplay == 'Y') {
        this.svg // Main Title
          .append('text')
          .attr('id', 'TitleMainX$TitleMainY')
          .attr('transform', `translate(${this.Title.TitleMainX},${this.Title.TitleMainY})`)
          .attr('class', this.Title.TitleMainStyle)
          .style('font-size', this.Title.TitleMainSize)
          .style('font-family', this.Title.TitleMainFont)
          .attr('fill', this.Title.TitleMainColor)
          .attr('text-anchor', this.Title.TitleMainAlign)
          .text(this.Title.TitleMainText)
          .call(this.el2Drag)
          .call(this.setCursorMovable)
      }

      if (this.Title.TitleSubDisplay == 'Y') {
        this.svg // Sub Title
          .append('text')
          .attr('id', 'TitleSubX$TitleSubY')
          .attr('transform', `translate(${this.Title.TitleSubX},${this.Title.TitleSubY})`)
          .attr('class', this.Title.TitleSubStyle)
          .style('font-size', this.Title.TitleSubSize)
          .style('font-family', this.Title.TitleSubFont)
          .attr('fill', this.Title.TitleSubColor)
          .attr('text-anchor', this.Title.TitleSubAlign)
          .text(this.Title.TitleSubText)
          .call(this.el2Drag)
          .call(this.setCursorMovable)
      }

      if (this.Title.TitleExtraDisplay == 'Y') {
        this.svg // Extra Title
          .append('text')
          .attr('id', 'TitleExtraX$TitleExtraY')
          .attr('transform', `translate(${this.Title.TitleExtraX},${this.Title.TitleExtraY})`)
          .attr('class', this.Title.TitleExtraStyle)
          .style('font-size', this.Title.TitleExtraSize)
          .style('font-family', this.Title.TitleExtraFont)
          .attr('fill', this.Title.TitleExtraColor)
          .attr('text-anchor', this.Title.TitleExtraAlign)
          .text(this.Title.TitleExtraText)
          .call(this.el2Drag)
          .call(this.setCursorMovable)
      }

      if (this.Title.TitleSqlDisplay == 'Y') {
        this.svg // Sql Title
          .append('text')
          .attr('id', 'TitleSqlX$TitleSqlY')
          .attr('transform', `translate(${this.Title.TitleSqlX},${this.Title.TitleSqlY})`)
          .attr('class', this.Title.TitleSqlStyle)
          .style('font-size', this.Title.TitleSqlSize)
          .style('font-family', this.Title.TitleSqlFont)
          .attr('fill', this.Title.TitleSqlColor)
          .attr('text-anchor', this.Title.TitleSqlAlign)
          .text(this.Title.TitleSqlText)
          .call(this.el2Drag)
          .call(this.setCursorMovable)
      }

      if (this.Title.TitleLineDisplay == 'Y') {
        this.svg // Line Title
          .append('line')
          .attr('id', 'TitleLineX$TitleLineY')
          .attr('transform', `translate(${this.Title.TitleLineX},${this.Title.TitleLineY})`)
          .attr('x1', 0)
          .attr('y1', 0)
          .attr('x2', this.Title.TitleLineLength)
          .attr('y2', 0)

          .attr('stroke', this.Title.TitleLineColor)
          .attr('stroke-width', this.Title.TitleLineWeight)

          .text(this.Title.TitleLineText)
          .call(this.el2Drag)
          .call(this.setCursorMovable)
      }
    }
  }
}
