import * as d3 from 'd3'

export default {

  methods: {

    draw_Summary() {

      let g = this.Group

      let Table = this.svg
      .append('g')
      .attr('transform', `translate(1, 1)`)
      .attr('font-family', 'roboto')

      let box_y = 0
      this.Group.name.forEach((group,gn)=> {
        Table
        .append('g')
        .attr('id', `group_${gn}_${this.localId}`)
        .attr('transform', `translate(0, ${box_y})`)

        // Group Box
        d3.select(`#group_${gn}_${this.localId}`)
        .append('rect')
        .attr('x', 0).attr('y', 0)
        .attr('width', g.box.width)
        .attr('height', g.box.height[gn])
        .attr('fill', g.box.fColor[gn])
        .attr('fill-opacity', g.box.fOpacity[gn])
        .attr('stroke-width', g.box.sWidth[gn])
        .attr('stroke-opacity', g.box.sOpacity[gn])
        .attr('stroke', g.box.sColor[gn])
        .attr('rx', g.box.radius[gn])

        // Group Title
        d3.select(`#group_${gn}_${this.localId}`)
        .append('text')
        .attr('x', g.padding)
        .attr('y', g.padding)
        .attr('fill', g.title.tColor[gn])
        .attr('font-size', g.title.tSize[gn])
        .attr('font-weight', g.title.tWeight[gn])
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'hanging')
        .style('cursor', 'default')
        .text(group)


        this.Column.position.forEach((col,cn)=> {
          d3.select(`#group_${gn}_${this.localId}`)
          .append('text')
          .attr('x', col)
          .attr('y', g.padding + g.header.lineHeight)
          .style('cursor', 'default')
          .attr('fill', g.header.tColor)
          .attr('font-size', g.header.tSize)
          .attr('font-weight', g.header.tWeight)
          .attr('text-anchor', this.Column.align[cn])
          .attr('alignment-baseline', 'ideographic')
          .text(this.Column.title[gn].name[cn])

          let val_y = g.padding + g.header.lineHeight + this.Value.height
          this.rawData.filter(f=> f[this.Group.refCode] == group).forEach((item,tn)=> {
            d3.select(`#group_${gn}_${this.localId}`)
            .append('text')
            .attr('id', `group_${gn}_${tn}_${cn}_${this.localId}`)
            .attr('transform', `translate(${col}, ${val_y})`)
            .attr('fill', this.Column.tColor[cn])
            .attr('font-size', this.Value.tSize)
            .attr('font-weight', this.Value.tWeight)
            .attr('text-anchor', this.Column.align[cn])
            .attr('alignment-baseline', 'ideographic')
            .style('cursor', this.Column.jsonLink[cn] !== '' ? 'pointer' : 'default')
            .text(item[this.Column.refCode[cn]])
            .text(() => {
              let value = item[this.Column.refCode[cn]]
              if(this.Column.format[cn] == '$') {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              } else if(this.Column.format[cn] == '%') {
                return `${value}%`
              } else {
                return value
              }
            })
            .call(this.event_Link, {
              idx:    this.rawData.findIndex(f=> f[this.Group.refCode] == item[this.Group.refCode] && f.ITEM == item[this.Group.refCode2]),
              x:      col,
              y:      val_y,
              align:  this.Column.align[cn],
              gName:  group, 
              group:  gn, 
              item:   tn, 
              col:    cn,
              filter: this.filters[cn],
            })


            val_y += this.Value.height
          })


        })

        
        box_y += g.box.height[gn] + g.margin
      })


    },

  } 
}