import { SafeIdMixin } from '../../../../../includes/mixins/safeid.mixin'
import gmx_Controller from '../../../../../includes/mixins/Controller.mixin'       // SVG Control
import gmx_Canvas from '../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts from '../../../../../includes/mixins/Text_Draw.mixin'
import gmx_Miscellaneous from '../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../../includes/mixins/Movable.mixin'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin'
import lmx_Declares from './Declares.mixin'

export default {
  mixins: [
    SafeIdMixin,
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Miscellaneous,
    gmx_Movable,

    lmx_ReactiveEvents,
    lmx_Declares,
  ],
}
