<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    Canvas: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.draw()
  },
  methods: {
    draw() {
      this.clear()
      this.drawCanvas()
      this.drawChart()
      this.complete()
    },
    drawChart() {
      this.svg.style('background-color', 'transparent')
      this.svg
      .append('circle')
      .attr('cx', 150)
      .attr('cy', 150)
      .attr('r', 150)
      .style('stroke', '#fff')
      .style('stroke-width', .5)
      .style('fill', 'none')
      this.svg
      .append('circle')
      .attr('cx', 150)
      .attr('cy', 150)
      .attr('r', 150)
      .style('fill', '#fff')
      .style('opacity', .8)
      this.svg
      .append('text')
      .attr('transform', 'translate(150,150)')
      .style('font-size', 48)
      .style('font-family', 'roboto')
      .style('font-weight', 900)
      .style('font-style', 'italic')
      .attr('fill', '#d5d5d5')
      .attr('alignment-baseline', 'middle')
      .attr('text-anchor', 'middle')
      .text('TBD')
    },
  }
}
</script>