import gmx_zMixins            from '../../../../../includes/Jmixins/zMixins'

// Locally declared common Data-Props & SVG Drawing Support
import lmx_Watching           from './Watching'
import lmx_Events             from './Events'
  
// Charts 
import lmx_LocalValues        from './set_LocalValues'
import lmx_draw_SVG           from './draw_SVG'



export default {
  
  mixins: [
    gmx_zMixins, 

    // Local Mixins
    lmx_Events,
    lmx_Watching,

    lmx_LocalValues,
    lmx_draw_SVG,

  ],

}
