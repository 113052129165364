import '../../../../src/assets/stylus/ui/component/_jModalSlideForService.styl'

import { VDialog } from 'vuetify/lib'

export default {
  name: 'j-modal-slide-for-service',
  components: {
    VDialog
  },
  props: {
    value: null,
  },
  render() {
    let data = {
      jmodal: {
        class: 'j_modal_slide_for_service'
      },
      vdialog: {
        props: {
          value: this.value,
          transition: 'slide-half-modal-transition',
          contentClass: 'slide_modal',
          width: 'unset'
        },
        on: { input: (val) => this.$emit('input', val) }
      }
    }

    return (
      <div { ...data.jmodal }>
        <v-dialog { ...data.vdialog }>
          { this.$slots.default }
        </v-dialog>
      </div>
    )
  }
}
