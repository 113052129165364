import * as d3 from 'd3'

export default {
  methods: {

    chart_Punch(selection) {

      let header =  this.header
      let bar =     this.barChart
      let tbl =     this.table
      let cols =    this.columns
      let len =     cols.length-1
      let colsBar = cols[len]

      //Scale for the radius
      let barScale = d3.scaleLinear()
      .domain([0, bar.maxValue])
      .range([0, bar.width])

      // Draw Header
      let y_ =  0
      this.columns.forEach((d,i)=> {
        selection
        .append('text')
        .attr('x', d.x).attr('y', y_)
        .style('font-size', header.tSize).style('fill', header.tColor)
        .attr('text-anchor', d.align).attr('alignment-baseline', 'middle')
        .text(d.name)
      })

      let grid_start =  colsBar.stX
      let grid_mid   =  colsBar.stX + bar.width / 2
      let grid_end   =  colsBar.stX + bar.width

      selection
      .append('text')
      .attr('x', grid_start).attr('y', tbl.y - tbl.lineHeight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text('0')

      selection
      .append('text')
      .attr('x', grid_mid).attr('y', tbl.y - tbl.lineHeight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(bar.maxValue/2)

      selection
      .append('text')
      .attr('x', grid_end).attr('y', tbl.y - tbl.lineHeight)
      .style('font-size', 8).style('fill', '#bcbcbc').attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .text(bar.maxValue)

      selection
      .append('line')
      .attr('x1', grid_start).attr('y1', tbl.y - tbl.lineHeight + 5).attr('x2', grid_start).attr('y2', header.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      selection
      .append('line')
      .attr('x1', grid_mid).attr('y1', tbl.y - tbl.lineHeight + 5).attr('x2', grid_mid).attr('y2', header.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      selection
      .append('line')
      .attr('x1', grid_end).attr('y1', tbl.y - tbl.lineHeight + 5).attr('x2', grid_end).attr('y2', header.gridHeight)
      .style('stroke', '#bcbcbc').style('stroke-width', 0.2)
      
      y_ = y_ + tbl.y
      this.rawData.forEach((d,i)=> {
        
        this.columns.forEach((c,j)=> {
          if( j < len) {
            selection
            .append('text')
            .attr('id', `${c.code}_${j}_${this.localId}`)
            .attr('transform', `translate(${c.x}, ${y_})`)
            .style('font-size', tbl.tSize)
            .style('fill', c.tColor)
            .attr('text-anchor', c.align).attr('alignment-baseline', 'middle')
            .text(d[c.code])
            .style('cursor', c.link == 'Y' ? 'pointer' : 'default')
            .call(this.call_LINK, {...c,idx:i})
          }
        })
        
        // Progress Bar Chart -------------
        let ACTIVE = d[colsBar.code]
        
        selection
        .append('rect')
        .attr('transform', `translate(${colsBar.stX}, ${y_ - (tbl.tSize/2) - 1})`)
        .attr('width', barScale(d.TOTAL)).attr('height', tbl.tSize)
        .attr('fill', '#bcbcbc').style('opacity', .15)

        selection
        .append('rect')
        .attr('transform', `translate(${colsBar.stX}, ${y_ - (tbl.tSize/2) - 1})`)
        .attr('width', 0).attr('height', tbl.tSize)
        .attr('stroke-width', 0.3).attr('stroke', bar.color).style('stroke-opacity', 1)
        .attr('fill', bar.color).style('fill-opacity', bar.opacity)
        .transition().duration(500).attr('width', barScale(ACTIVE))
      
        // ACTIVE ------------->
        selection
        .append('text')
        .attr('id', `${colsBar.code}_${i}_${this.localId}`)
        .attr('transform', `translate(${colsBar.stX}, ${y_})`)
        .attr('text-anchor', 'start')
        .style('font-size', tbl.tSize)
        .style('fill', colsBar.tColor)
        .attr('alignment-baseline', 'middle')
        .text(ACTIVE > 0 ? ACTIVE : '')
        .call(this.call_LINK, {...colsBar,idx:i})
        .style('cursor', colsBar.link == 'Y' ? 'pointer' : 'default')
        .transition().duration(500)
        .attr('transform', `translate(${colsBar.stX + barScale(ACTIVE) + 2}, ${y_})`)
        
        y_ = y_ + tbl.lineHeight
      })
      
      
    },

  }
}
