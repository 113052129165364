import * as d3 from 'd3'

export default {

  methods: {

    event_Link(selection, no) {

      if (this.rawData.link[no] !== 'Y') return

      selection
      .on('mouseover', () => {
          d3.select(`#value_${this.localId}_${no}`)
          .transition().duration(50)
          .style('opacity', .4)
        })
        .on('mouseout', () => {
          d3.select(`#value_${this.localId}_${no}`)
          .transition().duration(50)
          .style('opacity', 1)
        })
        
        .on('click', () => { this.link_Action(no) }) // --->
    },

    link_Action(no_) {

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {}

      // if delValue is 'Y', set dataSource
      // if(this.delValue.length > 0){
      //   this.delValue.forEach((d, i) => {
      //     dataSource = {...dataSource, [`KEY${i+1}`]: this.rawData[_style.idx][d]}
      //   })
      // }

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
      if(!request_) return

      // if filterCol is exist, set filterString_
      let filterString_ = this.rawData.filter[no_] || ''

      // if A/G iFilters is exist, (filterString_ + iFilters)
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }

      // combine filterString 
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

  } 
}