<template>
  <div :id='localId'></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'          // Mixins (Global & Local)

export default {
  name: "j-chart-table-summary",
  mixins: [ mx_Core ],
  props: {
    id: String,
    ChartType: String,

    FilterValues: { type: Object, default: () => ({}) },

    // Chart Data
    Database: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    
    // Table Header(Column) Information
    Table: { type: Object, default: () => ({}) },
    Styles: { type: Array, default: () => ([]) },
    Columns: { type: Array, default: () => ([]) },
    Header: { type: Array, default: () => ([]) },
    Footer: { type: Array, default: () => ([]) },

    // Database Table Values (Rows)
    DataItems: { type: Array, default: () => ([]) },

    // Environmental properties for the column
    ColumnProps: { type: Array, default: () => ([]) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-table-summary'}__${this.safeId('')}`
  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    // Main-Process flow *****
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return
      this.clear()
      this.init()

      this.setDefaultValues()
      // this.draw_Elements()
      // Canvas & Chart Area
      this.drawCanvas()   // Global
      this.drawChart()

      this.draw_SummaryTableHeader()

      this.draw_SummaryTableCells()
      this.draw_SummaryTableFooter()
      this.draw_SummaryTableLines()

      this.complete()
    },
    drawChart() {
      this.tableGroup = this.svg
      .append('g')
      .attr('id', 'table_group')
      .attr('transform', `translate(${this.Table.TableX + 0.5},${this.Table.TableY + 0.5})`)
    }
  }
}
</script>
