export default {
  data: () => ({
    dataSet: [],
    lineChartData: [],

    style: {
      id      : 'AITR',
      title   : ['Total', 'Completed', 'Remain'],
      cell    : [50, 110, 160],
      x       : 30,
      y       : 40,
      y1      : 10,
      y2      : 26,
      width   : 170,
      height  : 34,
      radius  : 3,
      opacity : 0.7,
      bColor  : '#E0E0DF',
      tSize   : 9,
      tColor  : '#757575',
      vSize   : 11,
      vColor  : '#333',
      value   : []
    },
    timeline: { 
      week        : [],
      month       : [],
      year        : [],
      plan        : [],
      actual      : [],
      scale       : null,     

      weekSize    : null,     // Auto
      length      : null,     // Auto
      weight      : 0,        // Manual, Should be assigned by 'SetDefaultValues()'
      height      : null,     // Auto
      font        : 'roboto', 
      baseX       : 115,
      baseY       : 550,

      style: {
        week: {
          display   : 'N',
          height    : 15,
          line      : 12,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 9,
          tColor    : '#757575',
        },
        month: {
          display   : 'N',
          height    : 14,
          line      : 15,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 10,
          tColor    : '#333',
        },
        year: {
          display   : 'N',
          height    : 10,
          line      : 20,
          sWidth    : 0.5,
          sColor    : '#BCBCBC',
          tSize     : 11,
          tColor    : '#000',
        }
      }
    },
    table: {
      display: 'Y',
      bottom: 500,
      dwarY:45,
      gapFromChart: 0,
      cellWidth: 0,
      labelX: -55,

      width:290, 
      hHeight: 20,
      lineHeight: 16,

      sColor:'#757575', 
      sWidth: 0.5, 
  
      headers: [
        {name: 'Total ITRs'   , colName: 'A_T'     , x: 15,  y: 20,  col: 'Y', link: 'Y', align: 'end',     tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#c5e1a5'}, 
        {name: 'Completed'    , colName: 'A_A'     , x: 55,  y: 20,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#80deea'}, 
        {name: 'Remained'     , colName: 'A_O'     , x: 110, y: 20,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#1976d2', dSize: 9, markupFill: '#1e88e5'}, 
        {name: 'Progress(%)'  , colName: 'A_PG'    , x: 110, y: 20,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#9c27b0'}, 
        {name: 'Cumulated(%)' , colName: 'A_CUM_PG', x: 110, y: 20,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#e53935', dSize: 9, markupFill: '#e53935'}, 
        {name: 'Punch "A"'    , colName: 'PA_O'    , x: 175, y: 13,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#9e9d24'}, 
        {name: 'Punch "B"'    , colName: 'PB_O'    , x: 250, y: 13,  col: 'Y', link: 'Y', align: 'middle',  tSize: 10, tColor: '#000', dColor: '#757575', dSize: 9, markupFill: '#e0e0e0'},
      ],
    }  
  })
}