var moduleBlock = [
  {  module: '41M001', block: '3A1' },
  {  module: '41M001', block: '3A2' },
  {  module: '41M001', block: '3A3' },
  {  module: '41M001', block: '3A4' },
  {  module: '41M001', block: '3AA' },
  {  module: '41M001', block: '3B1' },
  {  module: '41M001', block: '3B2' },
  {  module: '41M001', block: '3B3' },
  {  module: '41M001', block: '3B4' },
  {  module: '41M001', block: '3B5' },
  {  module: '41M001', block: '3B6' },
  {  module: '41M001', block: '3B7' },
  {  module: '41M001', block: '3B8' },
  {  module: '41M001', block: '3B9' },
  {  module: '41M001', block: '3C1' },
  {  module: '41M001', block: '3C2' },
  {  module: '41M001', block: '3C3' },
  {  module: '41M001', block: '3C4' },
  {  module: '41M001', block: '3C5' },
  {  module: '41M001', block: '3C9' },
  {  module: '41M001', block: '3D1' },
  {  module: '41M001', block: '3D2' },
  {  module: '41M001', block: '3D3' },
  {  module: '41M001', block: '3D9' },
  {  module: '41M001', block: '3DA' },
  {  module: '41M001', block: '3DB' },
  {  module: '41M001', block: '3F1' },
  {  module: '41M001', block: '3F2' },
  {  module: '41M001', block: '3F3' },
  {  module: '41M001', block: '3F4' },
  {  module: '41M001', block: '3G1' },
  {  module: '41M001', block: '3G2' },
  {  module: '41M001', block: '3G3' },
  {  module: '41M001', block: '3G4' },
  {  module: '41M001', block: '3G9' },
  {  module: '41M001', block: '3H1' },
  {  module: '41M001', block: '3H2' },
  {  module: '41M001', block: '3H3' },
  {  module: '41M001', block: '3HA' },
  {  module: '41M001', block: '3J1' },
  {  module: '41M001', block: '3J2' },
  {  module: '41M001', block: '3J3' },
  {  module: '41M001', block: '3J4' },
  {  module: '41M001', block: '3J5' },
  {  module: '41M001', block: '3J6' },
  {  module: '41M001', block: '3J7' },
  {  module: '41M001', block: '3JA' },
  {  module: '41M001', block: '3JB' },
  {  module: '41M001', block: '3K1' },
  {  module: '41M001', block: '3K2' },
  {  module: '41M001', block: '3K3' },
  {  module: '41M001', block: '3K4' },
  {  module: '41M001', block: '3K5' },
  {  module: '41M001', block: 'A00' },
  {  module: '41M001', block: 'B00' },
  {  module: '41M001', block: 'C00' },
  {  module: '41M001', block: 'M00' },
  {  module: '41M003', block: '4A1' },
  {  module: '41M003', block: '4A2' },
  {  module: '41M003', block: '4A3' },
  {  module: '41M003', block: '4A4' },
  {  module: '41M003', block: '4AA' },
  {  module: '41M003', block: '4B1' },
  {  module: '41M003', block: '4B2' },
  {  module: '41M003', block: '4B3' },
  {  module: '41M003', block: '4B4' },
  {  module: '41M003', block: '4B5' },
  {  module: '41M003', block: '4B6' },
  {  module: '41M003', block: '4B7' },
  {  module: '41M003', block: '4B8' },
  {  module: '41M003', block: '4B9' },
  {  module: '41M003', block: '4C1' },
  {  module: '41M003', block: '4C2' },
  {  module: '41M003', block: '4C3' },
  {  module: '41M003', block: '4C4' },
  {  module: '41M003', block: '4C5' },
  {  module: '41M003', block: '4C9' },
  {  module: '41M003', block: '4D1' },
  {  module: '41M003', block: '4D2' },
  {  module: '41M003', block: '4D3' },
  {  module: '41M003', block: '4D9' },
  {  module: '41M003', block: '4DA' },
  {  module: '41M003', block: '4DB' },
  {  module: '41M003', block: '4F1' },
  {  module: '41M003', block: '4F2' },
  {  module: '41M003', block: '4F3' },
  {  module: '41M003', block: '4F4' },
  {  module: '41M003', block: '4G1' },
  {  module: '41M003', block: '4G2' },
  {  module: '41M003', block: '4G3' },
  {  module: '41M003', block: '4G4' },
  {  module: '41M003', block: '4G9' },
  {  module: '41M003', block: '4H1' },
  {  module: '41M003', block: '4H2' },
  {  module: '41M003', block: '4H3' },
  {  module: '41M003', block: '4HA' },
  {  module: '41M003', block: '4J1' },
  {  module: '41M003', block: '4J2' },
  {  module: '41M003', block: '4J3' },
  {  module: '41M003', block: '4J4' },
  {  module: '41M003', block: '4J5' },
  {  module: '41M003', block: '4J6' },
  {  module: '41M003', block: '4J7' },
  {  module: '41M003', block: '4K1' },
  {  module: '41M003', block: '4K2' },
  {  module: '41M003', block: '4K3' },
  {  module: '41M003', block: '4K4' },
  {  module: '41M003', block: '4K5' },
  {  module: '41M003', block: 'A00' },
  {  module: '41M003', block: 'B00' },
  {  module: '41M003', block: 'C00' },
  {  module: '41M003', block: 'M00' },
  {  module: '41M004', block: '5A1' },
  {  module: '41M004', block: '5A2' },
  {  module: '41M004', block: '5A3' },
  {  module: '41M004', block: '5A4' },
  {  module: '41M004', block: '5AA' },
  {  module: '41M004', block: '5AB' },
  {  module: '41M004', block: '5B1' },
  {  module: '41M004', block: '5B2' },
  {  module: '41M004', block: '5B3' },
  {  module: '41M004', block: '5B4' },
  {  module: '41M004', block: '5B5' },
  {  module: '41M004', block: '5B6' },
  {  module: '41M004', block: '5B7' },
  {  module: '41M004', block: '5B8' },
  {  module: '41M004', block: '5B9' },
  {  module: '41M004', block: '5C1' },
  {  module: '41M004', block: '5C2' },
  {  module: '41M004', block: '5C3' },
  {  module: '41M004', block: '5C4' },
  {  module: '41M004', block: '5C5' },
  {  module: '41M004', block: '5C9' },
  {  module: '41M004', block: '5D1' },
  {  module: '41M004', block: '5D2' },
  {  module: '41M004', block: '5D3' },
  {  module: '41M004', block: '5D9' },
  {  module: '41M004', block: '5DA' },
  {  module: '41M004', block: '5DB' },
  {  module: '41M004', block: '5F1' },
  {  module: '41M004', block: '5F2' },
  {  module: '41M004', block: '5F3' },
  {  module: '41M004', block: '5F4' },
  {  module: '41M004', block: '5G1' },
  {  module: '41M004', block: '5G2' },
  {  module: '41M004', block: '5G3' },
  {  module: '41M004', block: '5G4' },
  {  module: '41M004', block: '5G9' },
  {  module: '41M004', block: '5H1' },
  {  module: '41M004', block: '5H2' },
  {  module: '41M004', block: '5H3' },
  {  module: '41M004', block: '5HA' },
  {  module: '41M004', block: '5J1' },
  {  module: '41M004', block: '5J2' },
  {  module: '41M004', block: '5J3' },
  {  module: '41M004', block: '5J4' },
  {  module: '41M004', block: '5J5' },
  {  module: '41M004', block: '5J6' },
  {  module: '41M004', block: '5J7' },
  {  module: '41M004', block: '5JA' },
  {  module: '41M004', block: '5K1' },
  {  module: '41M004', block: '5K2' },
  {  module: '41M004', block: '5K3' },
  {  module: '41M004', block: '5K4' },
  {  module: '41M004', block: '5K5' },
  {  module: '41M004', block: 'A00' },
  {  module: '41M004', block: 'B00' },
  {  module: '41M004', block: 'C00' },
  {  module: '41M004', block: 'M00' },
  {  module: '41M006', block: '6A1' },
  {  module: '41M006', block: '6A2' },
  {  module: '41M006', block: '6A3' },
  {  module: '41M006', block: '6A4' },
  {  module: '41M006', block: '6AA' },
  {  module: '41M006', block: '6B1' },
  {  module: '41M006', block: '6B2' },
  {  module: '41M006', block: '6B3' },
  {  module: '41M006', block: '6B4' },
  {  module: '41M006', block: '6B5' },
  {  module: '41M006', block: '6B6' },
  {  module: '41M006', block: '6B7' },
  {  module: '41M006', block: '6B8' },
  {  module: '41M006', block: '6B9' },
  {  module: '41M006', block: '6C1' },
  {  module: '41M006', block: '6C2' },
  {  module: '41M006', block: '6C3' },
  {  module: '41M006', block: '6C4' },
  {  module: '41M006', block: '6C5' },
  {  module: '41M006', block: '6C9' },
  {  module: '41M006', block: '6D1' },
  {  module: '41M006', block: '6D2' },
  {  module: '41M006', block: '6D3' },
  {  module: '41M006', block: '6D9' },
  {  module: '41M006', block: '6DA' },
  {  module: '41M006', block: '6DB' },
  {  module: '41M006', block: '6F1' },
  {  module: '41M006', block: '6F2' },
  {  module: '41M006', block: '6F3' },
  {  module: '41M006', block: '6F4' },
  {  module: '41M006', block: '6G1' },
  {  module: '41M006', block: '6G2' },
  {  module: '41M006', block: '6G3' },
  {  module: '41M006', block: '6G4' },
  {  module: '41M006', block: '6G9' },
  {  module: '41M006', block: '6H1' },
  {  module: '41M006', block: '6H2' },
  {  module: '41M006', block: '6H3' },
  {  module: '41M006', block: '6HA' },
  {  module: '41M006', block: '6J1' },
  {  module: '41M006', block: '6J2' },
  {  module: '41M006', block: '6J3' },
  {  module: '41M006', block: '6J4' },
  {  module: '41M006', block: '6J5' },
  {  module: '41M006', block: '6J6' },
  {  module: '41M006', block: '6J7' },
  {  module: '41M006', block: '6K1' },
  {  module: '41M006', block: '6K2' },
  {  module: '41M006', block: '6K3' },
  {  module: '41M006', block: '6K4' },
  {  module: '41M006', block: '6K5' },
  {  module: '41M006', block: 'A00' },
  {  module: '41M006', block: 'B00' },
  {  module: '41M006', block: 'C00' },
  {  module: '41M006', block: 'M00' },
  {  module: '41M007', block: '401' },
  {  module: '41M007', block: '402' },
  {  module: '41M007', block: '403' },
  {  module: '41M007', block: '411' },
  {  module: '41M007', block: '412' },
  {  module: '41M007', block: '413' },
  {  module: '41M007', block: '421' },
  {  module: '41M007', block: '422' },
  {  module: '41M007', block: '423' },
  {  module: '41M007', block: '431' },
  {  module: '41M007', block: '432' },
  {  module: '41M007', block: '433' },
  {  module: '41M007', block: '441' },
  {  module: '41M007', block: '442' },
  {  module: '41M007', block: '443' },
  {  module: '41M007', block: '444' },
  {  module: '41M007', block: '445' },
  {  module: '41M007', block: '446' },
  {  module: '41M007', block: '447' },
  {  module: '41M007', block: '44A' },
  {  module: '41M007', block: '44B' },
  {  module: '41M007', block: '451' },
  {  module: '41M007', block: '452' },
  {  module: '41M007', block: '453' },
  {  module: '41M007', block: '454' },
  {  module: '41M007', block: '455' },
  {  module: '41M007', block: '456' },
  {  module: '41M007', block: 'A00' },
  {  module: '41M007', block: 'B00' },
  {  module: '41M007', block: 'M00' },
  {  module: '41M008', block: '501' },
  {  module: '41M008', block: '502' },
  {  module: '41M008', block: '503' },
  {  module: '41M008', block: '504' },
  {  module: '41M008', block: '511' },
  {  module: '41M008', block: '512' },
  {  module: '41M008', block: '513' },
  {  module: '41M008', block: '521' },
  {  module: '41M008', block: '522' },
  {  module: '41M008', block: '523' },
  {  module: '41M008', block: '531' },
  {  module: '41M008', block: '532' },
  {  module: '41M008', block: '533' },
  {  module: '41M008', block: '53A' },
  {  module: '41M008', block: '541' },
  {  module: '41M008', block: '542' },
  {  module: '41M008', block: '543' },
  {  module: '41M008', block: '544' },
  {  module: '41M008', block: '545' },
  {  module: '41M008', block: '546' },
  {  module: '41M008', block: '551' },
  {  module: '41M008', block: '552' },
  {  module: '41M008', block: '553' },
  {  module: '41M008', block: '554' },
  {  module: '41M008', block: '55A' },
  {  module: '41M008', block: '561' },
  {  module: '41M008', block: '562' },
  {  module: '41M008', block: 'A00' },
  {  module: '41M008', block: 'B00' },
  {  module: '41M008', block: 'M00' },
  {  module: '41M009', block: '601' },
  {  module: '41M009', block: '602' },
  {  module: '41M009', block: '603' },
  {  module: '41M009', block: '604' },
  {  module: '41M009', block: '611' },
  {  module: '41M009', block: '612' },
  {  module: '41M009', block: '613' },
  {  module: '41M009', block: '621' },
  {  module: '41M009', block: '622' },
  {  module: '41M009', block: '623' },
  {  module: '41M009', block: '631' },
  {  module: '41M009', block: '632' },
  {  module: '41M009', block: '633' },
  {  module: '41M009', block: '63A' },
  {  module: '41M009', block: '641' },
  {  module: '41M009', block: '642' },
  {  module: '41M009', block: '643' },
  {  module: '41M009', block: '644' },
  {  module: '41M009', block: '645' },
  {  module: '41M009', block: '646' },
  {  module: '41M009', block: '651' },
  {  module: '41M009', block: '652' },
  {  module: '41M009', block: '653' },
  {  module: '41M009', block: '654' },
  {  module: '41M009', block: '65A' },
  {  module: '41M009', block: '661' },
  {  module: '41M009', block: '662' },
  {  module: '41M009', block: 'A00' },
  {  module: '41M009', block: 'B00' },
  {  module: '41M009', block: 'M00' },
  {  module: '41M010', block: '8A1' },
  {  module: '41M010', block: '8A2' },
  {  module: '41M010', block: '8B1' },
  {  module: '41M010', block: '8B2' },
  {  module: '41M010', block: '8C1' },
  {  module: '41M010', block: '8C2' },
  {  module: '41M010', block: '8D1' },
  {  module: '41M010', block: '8D2' },
  {  module: '41M010', block: '8D3' },
  {  module: '41M010', block: '8D4' },
  {  module: '41M010', block: '8D5' },
  {  module: '41M010', block: '8F1' },
  {  module: '41M010', block: '8F2' },
  {  module: '41M010', block: '8F3' },
  {  module: '41M010', block: 'A00' },
  {  module: '41M010', block: 'B00' },
  {  module: '41M010', block: 'M00' },
  {  module: '41M011', block: '7A1' },
  {  module: '41M011', block: '7A2' },
  {  module: '41M011', block: '7B1' },
  {  module: '41M011', block: '7B2' },
  {  module: '41M011', block: '7C1' },
  {  module: '41M011', block: '7C2' },
  {  module: '41M011', block: '7D1' },
  {  module: '41M011', block: '7D2' },
  {  module: '41M011', block: '7DA' },
  {  module: '41M011', block: '7DB' },
  {  module: '41M011', block: '7F1' },
  {  module: '41M011', block: '7F2' },
  {  module: '41M011', block: '7F3' },
  {  module: '41M011', block: '7F4' },
  {  module: '41M011', block: '7F5' },
  {  module: '41M011', block: '7F6' },
  {  module: '41M011', block: '7G1' },
  {  module: '41M011', block: '7G2' },
  {  module: '41M011', block: '7G3' },
  {  module: '41M011', block: 'A00' },
  {  module: '41M011', block: 'B00' },
  {  module: '41M011', block: 'M00' },
  {  module: '41M013', block: '8M1' },
  {  module: '41M013', block: '8M2' },
  {  module: '41M013', block: '8M3' },
  {  module: '41M013', block: '8P1' },
  {  module: '41M013', block: '8P2' },
  {  module: '41M013', block: '8P3' },
  {  module: '41M013', block: '8Q1' },
  {  module: '41M013', block: '8Q2' },
  {  module: '41M013', block: '8Q3' },
  {  module: '41M013', block: '8Q4' },
  {  module: '41M013', block: '8Q5' },
  {  module: '41M013', block: '8Q6' },
  {  module: '41M013', block: '8QA' },
  {  module: '41M013', block: '8QB' },
  {  module: '41M013', block: '8QC' },
  {  module: '41M013', block: '8R1' },
  {  module: '41M013', block: '8R2' },
  {  module: '41M013', block: '8R3' },
  {  module: '41M013', block: '8S1' },
  {  module: '41M013', block: '8S2' },
  {  module: '41M013', block: '8S3' },
  {  module: '41M013', block: '8S4' },
  {  module: '41M013', block: '8S5' },
  {  module: '41M013', block: '8S6' },
  {  module: '41M013', block: '8S7' },
  {  module: '41M013', block: '8S8' },
  {  module: '41M013', block: 'A00' },
  {  module: '41M013', block: 'B00' },
  {  module: '41M013', block: 'M00' },
  {  module: '41M014', block: '9M1' },
  {  module: '41M014', block: '9M2' },
  {  module: '41M014', block: '9M3' },
  {  module: '41M014', block: '9M4' },
  {  module: '41M014', block: '9P1' },
  {  module: '41M014', block: '9P2' },
  {  module: '41M014', block: '9P3' },
  {  module: '41M014', block: '9P4' },
  {  module: '41M014', block: '9P5' },
  {  module: '41M014', block: '9P6' },
  {  module: '41M014', block: '9P7' },
  {  module: '41M014', block: '9P8' },
  {  module: '41M014', block: '9P9' },
  {  module: '41M014', block: '9Q1' },
  {  module: '41M014', block: '9Q2' },
  {  module: '41M014', block: '9Q3' },
  {  module: '41M014', block: '9Q4' },
  {  module: '41M014', block: '9Q5' },
  {  module: '41M014', block: '9Q9' },
  {  module: '41M014', block: '9R1' },
  {  module: '41M014', block: '9R2' },
  {  module: '41M014', block: '9R3' },
  {  module: '41M014', block: '9RA' },
  {  module: '41M014', block: '9S1' },
  {  module: '41M014', block: '9S2' },
  {  module: '41M014', block: '9S3' },
  {  module: '41M014', block: '9SA' },
  {  module: '41M014', block: '9T1' },
  {  module: '41M014', block: '9T2' },
  {  module: '41M014', block: '9T3' },
  {  module: '41M014', block: '9T4' },
  {  module: '41M014', block: '9T5' },
  {  module: '41M014', block: '9U1' },
  {  module: '41M014', block: 'A00' },
  {  module: '41M014', block: 'B00' },
  {  module: '41M014', block: 'M00' },
  {  module: '41M015', block: '101' },
  {  module: '41M015', block: '102' },
  {  module: '41M015', block: '103' },
  {  module: '41M015', block: '111' },
  {  module: '41M015', block: '112' },
  {  module: '41M015', block: '121' },
  {  module: '41M015', block: '122' },
  {  module: '41M015', block: '131' },
  {  module: '41M015', block: 'A00' },
  {  module: '41M015', block: 'M00' },
  {  module: '41M016', block: '1A1' },
  {  module: '41M016', block: '1A2' },
  {  module: '41M016', block: '1A3' },
  {  module: '41M016', block: '1A4' },
  {  module: '41M016', block: '1A5' },
  {  module: '41M016', block: '1B1' },
  {  module: '41M016', block: '1B2' },
  {  module: '41M016', block: '1B3' },
  {  module: '41M016', block: '1B4' },
  {  module: '41M016', block: '1B5' },
  {  module: '41M016', block: '1B6' },
  {  module: '41M016', block: '1B7' },
  {  module: '41M016', block: '1B8' },
  {  module: '41M016', block: '1B9' },
  {  module: '41M016', block: '1C1' },
  {  module: '41M016', block: '1C2' },
  {  module: '41M016', block: '1C3' },
  {  module: '41M016', block: '1C4' },
  {  module: '41M016', block: '1C9' },
  {  module: '41M016', block: '1D1' },
  {  module: '41M016', block: '1D2' },
  {  module: '41M016', block: '1D3' },
  {  module: '41M016', block: '1DA' },
  {  module: '41M016', block: '1F1' },
  {  module: '41M016', block: '1F2' },
  {  module: '41M016', block: '1F3' },
  {  module: '41M016', block: '1FA' },
  {  module: '41M016', block: '1G1' },
  {  module: '41M016', block: '1G2' },
  {  module: '41M016', block: '1G3' },
  {  module: '41M016', block: '1G4' },
  {  module: '41M016', block: '1G5' },
  {  module: '41M016', block: '1H1' },
  {  module: '41M016', block: 'A00' },
  {  module: '41M016', block: 'B00' },
  {  module: '41M016', block: 'M00' },
  {  module: '41M018', block: '2A1' },
  {  module: '41M018', block: '2A2' },
  {  module: '41M018', block: '2A3' },
  {  module: '41M018', block: '2B1' },
  {  module: '41M018', block: '2B2' },
  {  module: '41M018', block: '2C1' },
  {  module: '41M018', block: '2C2' },
  {  module: '41M018', block: '2D1' },
  {  module: '41M018', block: 'A00' },
  {  module: '41M018', block: 'M00' },
  {  module: '41M801', block: '201' },
  {  module: '41M801', block: '202' },
  {  module: '41M801', block: '203' },
  {  module: '41M801', block: '211' },
  {  module: '41M801', block: '212' },
  {  module: '41M801', block: '221' },
  {  module: '41M801', block: '222' },
  {  module: '41M801', block: '223' },
  {  module: '41M801', block: '229' },
  {  module: '41M801', block: '231' },
  {  module: '41M801', block: '232' },
  {  module: '41M801', block: '233' },
  {  module: '41M801', block: '23A' },
  {  module: '41M801', block: '241' },
  {  module: '41M801', block: '242' },
  {  module: '41M801', block: '251' },
  {  module: '41M801', block: '252' },
  {  module: '41M801', block: '253' },
  {  module: '41M801', block: '261' },
  {  module: '41M801', block: '262' },
  {  module: '41M801', block: '263' },
  {  module: '41M801', block: '271' },
  {  module: '41M801', block: '272' },
  {  module: '41M801', block: '273' },
  {  module: '41M801', block: '274' },
  {  module: '41M801', block: '275' },
  {  module: '41M801', block: '276' },
  {  module: '41M801', block: '281' },
  {  module: '41M801', block: '282' },
  {  module: '41M801', block: '283' },
  {  module: '41M801', block: '284' },
  {  module: '41M801', block: 'A00' },
  {  module: '41M801', block: 'B00' },
  {  module: '41M801', block: 'C00' },
  {  module: '41M801', block: 'M00' },
  {  module: '42K055', block: '3W1' },
  {  module: '42K055', block: '3W2' },
  {  module: '42K055', block: '3W3' },
  {  module: '42K055', block: 'A00' },
  {  module: '42K055', block: 'M00' },
  {  module: '42K056', block: '3M1' },
  {  module: '42K056', block: '3P1' },
  {  module: '42K056', block: '3P2' },
  {  module: '42K056', block: '3P3' },
  {  module: '42K056', block: '3P4' },
  {  module: '42K056', block: 'A00' },
  {  module: '42K056', block: 'M00' },
  {  module: '42M051', block: '911' },
  {  module: '42M051', block: '912' },
  {  module: '42M051', block: '913' },
  {  module: '42M051', block: '921' },
  {  module: '42M051', block: '922' },
  {  module: '42M051', block: '923' },
  {  module: '42M051', block: '924' },
  {  module: '42M051', block: '925' },
  {  module: '42M051', block: '926' },
  {  module: '42M051', block: '927' },
  {  module: '42M051', block: '931' },
  {  module: '42M051', block: '932' },
  {  module: '42M051', block: '933' },
  {  module: '42M051', block: '941' },
  {  module: '42M051', block: '943' },
  {  module: '42M051', block: '94A' },
  {  module: '42M051', block: '94B' },
  {  module: '42M051', block: '951' },
  {  module: '42M051', block: '953' },
  {  module: '42M051', block: '961' },
  {  module: '42M051', block: '962' },
  {  module: '42M051', block: '963' },
  {  module: '42M051', block: '96A' },
  {  module: '42M051', block: '971' },
  {  module: '42M051', block: '972' },
  {  module: '42M051', block: '97A' },
  {  module: '42M051', block: '97B' },
  {  module: '42M051', block: '981' },
  {  module: '42M051', block: '982' },
  {  module: '42M051', block: '98A' },
  {  module: '42M051', block: '98B' },
  {  module: '42M051', block: '991' },
  {  module: '42M051', block: '992' },
  {  module: '42M051', block: '993' },
  {  module: '42M051', block: 'A00' },
  {  module: '42M051', block: 'B00' },
  {  module: '42M051', block: 'C00' },
  {  module: '42M051', block: 'M00' },
  {  module: '42M052', block: '5M1' },
  {  module: '42M052', block: '5M2' },
  {  module: '42M052', block: '5M3' },
  {  module: '42M052', block: '5MA' },
  {  module: '42M052', block: '5P1' },
  {  module: '42M052', block: '5P2' },
  {  module: '42M052', block: '5P3' },
  {  module: '42M052', block: '5P4' },
  {  module: '42M052', block: '5P5' },
  {  module: '42M052', block: '5P6' },
  {  module: '42M052', block: '5P7' },
  {  module: '42M052', block: '5Q1' },
  {  module: '42M052', block: '5Q2' },
  {  module: '42M052', block: '5Q3' },
  {  module: '42M052', block: '5Q4' },
  {  module: '42M052', block: '5Q5' },
  {  module: '42M052', block: '5R1' },
  {  module: '42M052', block: '5R2' },
  {  module: '42M052', block: '5R3' },
  {  module: '42M052', block: '5S1' },
  {  module: '42M052', block: '5S2' },
  {  module: '42M052', block: '5S3' },
  {  module: '42M052', block: '5S4' },
  {  module: '42M052', block: '5T1' },
  {  module: '42M052', block: '5T2' },
  {  module: '42M052', block: '5U1' },
  {  module: '42M052', block: '5U2' },
  {  module: '42M052', block: '5U3' },
  {  module: '42M052', block: '5UA' },
  {  module: '42M052', block: 'A00' },
  {  module: '42M052', block: 'B00' },
  {  module: '42M052', block: 'C00' },
  {  module: '42M052', block: 'M00' },
  {  module: '42M053', block: '5A1' },
  {  module: '42M053', block: '5A2' },
  {  module: '42M053', block: '5A3' },
  {  module: '42M053', block: '5AA' },
  {  module: '42M053', block: '5B1' },
  {  module: '42M053', block: '5B2' },
  {  module: '42M053', block: '5B3' },
  {  module: '42M053', block: '5B4' },
  {  module: '42M053', block: '5B5' },
  {  module: '42M053', block: '5B6' },
  {  module: '42M053', block: '5B7' },
  {  module: '42M053', block: '5B8' },
  {  module: '42M053', block: '5B9' },
  {  module: '42M053', block: '5C1' },
  {  module: '42M053', block: '5D1' },
  {  module: '42M053', block: '5D2' },
  {  module: '42M053', block: '5D3' },
  {  module: '42M053', block: '5D4' },
  {  module: '42M053', block: '5DA' },
  {  module: '42M053', block: '5F1' },
  {  module: '42M053', block: '5F2' },
  {  module: '42M053', block: '5F3' },
  {  module: '42M053', block: '5G1' },
  {  module: '42M053', block: '5G2' },
  {  module: '42M053', block: '5G3' },
  {  module: '42M053', block: '5G4' },
  {  module: '42M053', block: '5H1' },
  {  module: '42M053', block: '5H2' },
  {  module: '42M053', block: '5J1' },
  {  module: '42M053', block: '5J2' },
  {  module: '42M053', block: '5J3' },
  {  module: '42M053', block: '5J4' },
  {  module: '42M053', block: '5J5' },
  {  module: '42M053', block: '5J6' },
  {  module: '42M053', block: '5J7' },
  {  module: '42M053', block: '5K1' },
  {  module: '42M053', block: '5K2' },
  {  module: '42M053', block: 'A00' },
  {  module: '42M053', block: 'B00' },
  {  module: '42M053', block: 'C00' },
  {  module: '42M053', block: 'M00' },
  {  module: '42M054', block: '6A1' },
  {  module: '42M054', block: '6A2' },
  {  module: '42M054', block: '6A3' },
  {  module: '42M054', block: '6AA' },
  {  module: '42M054', block: '6B0' },
  {  module: '42M054', block: '6B1' },
  {  module: '42M054', block: '6B2' },
  {  module: '42M054', block: '6B3' },
  {  module: '42M054', block: '6B4' },
  {  module: '42M054', block: '6B5' },
  {  module: '42M054', block: '6B6' },
  {  module: '42M054', block: '6B7' },
  {  module: '42M054', block: '6B8' },
  {  module: '42M054', block: '6B9' },
  {  module: '42M054', block: '6C1' },
  {  module: '42M054', block: '6C2' },
  {  module: '42M054', block: '6C3' },
  {  module: '42M054', block: '6C4' },
  {  module: '42M054', block: '6D1' },
  {  module: '42M054', block: '6D2' },
  {  module: '42M054', block: '6D3' },
  {  module: '42M054', block: '6D4' },
  {  module: '42M054', block: '6DA' },
  {  module: '42M054', block: '6F1' },
  {  module: '42M054', block: '6F2' },
  {  module: '42M054', block: '6G1' },
  {  module: '42M054', block: '6G2' },
  {  module: '42M054', block: '6G3' },
  {  module: '42M054', block: '6G4' },
  {  module: '42M054', block: '6H1' },
  {  module: '42M054', block: '6H2' },
  {  module: '42M054', block: '6H3' },
  {  module: '42M054', block: '6J1' },
  {  module: '42M054', block: '6J2' },
  {  module: '42M054', block: '6J3' },
  {  module: '42M054', block: '6J4' },
  {  module: '42M054', block: '6J5' },
  {  module: '42M054', block: '6J6' },
  {  module: '42M054', block: '6J7' },
  {  module: '42M054', block: '6J8' },
  {  module: '42M054', block: '6J9' },
  {  module: '42M054', block: '6JA' },
  {  module: '42M054', block: '6JB' },
  {  module: '42M054', block: '6K1' },
  {  module: '42M054', block: 'A00' },
  {  module: '42M054', block: 'B00' },
  {  module: '42M054', block: 'C00' },
  {  module: '42M054', block: 'M00' },
  {  module: '42M055', block: '7A1' },
  {  module: '42M055', block: '7A2' },
  {  module: '42M055', block: '7A3' },
  {  module: '42M055', block: '7AA' },
  {  module: '42M055', block: '7B0' },
  {  module: '42M055', block: '7B1' },
  {  module: '42M055', block: '7B2' },
  {  module: '42M055', block: '7B3' },
  {  module: '42M055', block: '7B4' },
  {  module: '42M055', block: '7B5' },
  {  module: '42M055', block: '7B6' },
  {  module: '42M055', block: '7B7' },
  {  module: '42M055', block: '7B8' },
  {  module: '42M055', block: '7B9' },
  {  module: '42M055', block: '7C1' },
  {  module: '42M055', block: '7C2' },
  {  module: '42M055', block: '7C3' },
  {  module: '42M055', block: '7C4' },
  {  module: '42M055', block: '7C5' },
  {  module: '42M055', block: '7C6' },
  {  module: '42M055', block: '7C7' },
  {  module: '42M055', block: '7C8' },
  {  module: '42M055', block: '7D1' },
  {  module: '42M055', block: '7D2' },
  {  module: '42M055', block: '7D3' },
  {  module: '42M055', block: '7F1' },
  {  module: '42M055', block: '7F2' },
  {  module: '42M055', block: '7F3' },
  {  module: '42M055', block: '7G1' },
  {  module: '42M055', block: '7G2' },
  {  module: '42M055', block: '7G3' },
  {  module: '42M055', block: '7G4' },
  {  module: '42M055', block: '7H1' },
  {  module: '42M055', block: '7H2' },
  {  module: '42M055', block: '7H3' },
  {  module: '42M055', block: '7J0' },
  {  module: '42M055', block: '7J1' },
  {  module: '42M055', block: '7J2' },
  {  module: '42M055', block: '7J3' },
  {  module: '42M055', block: '7J4' },
  {  module: '42M055', block: '7J5' },
  {  module: '42M055', block: '7J6' },
  {  module: '42M055', block: '7J7' },
  {  module: '42M055', block: '7J8' },
  {  module: '42M055', block: '7J9' },
  {  module: '42M055', block: '7K1' },
  {  module: '42M055', block: 'A00' },
  {  module: '42M055', block: 'B00' },
  {  module: '42M055', block: 'C00' },
  {  module: '42M055', block: 'M00' },
  {  module: '42M056', block: '8A1' },
  {  module: '42M056', block: '8A2' },
  {  module: '42M056', block: '8A3' },
  {  module: '42M056', block: '8AA' },
  {  module: '42M056', block: '8B0' },
  {  module: '42M056', block: '8B1' },
  {  module: '42M056', block: '8B2' },
  {  module: '42M056', block: '8B3' },
  {  module: '42M056', block: '8B4' },
  {  module: '42M056', block: '8B5' },
  {  module: '42M056', block: '8B6' },
  {  module: '42M056', block: '8B7' },
  {  module: '42M056', block: '8B8' },
  {  module: '42M056', block: '8B9' },
  {  module: '42M056', block: '8C1' },
  {  module: '42M056', block: '8C2' },
  {  module: '42M056', block: '8C3' },
  {  module: '42M056', block: '8C4' },
  {  module: '42M056', block: '8C5' },
  {  module: '42M056', block: '8C6' },
  {  module: '42M056', block: '8C7' },
  {  module: '42M056', block: '8C8' },
  {  module: '42M056', block: '8D1' },
  {  module: '42M056', block: '8D2' },
  {  module: '42M056', block: '8D3' },
  {  module: '42M056', block: '8DA' },
  {  module: '42M056', block: '8F1' },
  {  module: '42M056', block: '8F2' },
  {  module: '42M056', block: '8F3' },
  {  module: '42M056', block: '8G1' },
  {  module: '42M056', block: '8G2' },
  {  module: '42M056', block: '8G3' },
  {  module: '42M056', block: '8G4' },
  {  module: '42M056', block: '8H1' },
  {  module: '42M056', block: '8H2' },
  {  module: '42M056', block: '8H3' },
  {  module: '42M056', block: '8J0' },
  {  module: '42M056', block: '8J1' },
  {  module: '42M056', block: '8J2' },
  {  module: '42M056', block: '8J3' },
  {  module: '42M056', block: '8J4' },
  {  module: '42M056', block: '8J5' },
  {  module: '42M056', block: '8J6' },
  {  module: '42M056', block: '8J7' },
  {  module: '42M056', block: '8J8' },
  {  module: '42M056', block: '8J9' },
  {  module: '42M056', block: '8K1' },
  {  module: '42M056', block: 'A00' },
  {  module: '42M056', block: 'B00' },
  {  module: '42M056', block: 'C00' },
  {  module: '42M056', block: 'M00' },
  {  module: '42M057', block: '9A1' },
  {  module: '42M057', block: '9A2' },
  {  module: '42M057', block: '9A3' },
  {  module: '42M057', block: '9B0' },
  {  module: '42M057', block: '9B1' },
  {  module: '42M057', block: '9B2' },
  {  module: '42M057', block: '9B3' },
  {  module: '42M057', block: '9B4' },
  {  module: '42M057', block: '9B5' },
  {  module: '42M057', block: '9B6' },
  {  module: '42M057', block: '9B7' },
  {  module: '42M057', block: '9B8' },
  {  module: '42M057', block: '9B9' },
  {  module: '42M057', block: '9C1' },
  {  module: '42M057', block: '9C2' },
  {  module: '42M057', block: '9C3' },
  {  module: '42M057', block: '9C4' },
  {  module: '42M057', block: '9C5' },
  {  module: '42M057', block: '9C6' },
  {  module: '42M057', block: '9C7' },
  {  module: '42M057', block: '9C8' },
  {  module: '42M057', block: '9D1' },
  {  module: '42M057', block: '9D2' },
  {  module: '42M057', block: '9D3' },
  {  module: '42M057', block: '9DA' },
  {  module: '42M057', block: '9F1' },
  {  module: '42M057', block: '9F2' },
  {  module: '42M057', block: '9F3' },
  {  module: '42M057', block: '9G1' },
  {  module: '42M057', block: '9G2' },
  {  module: '42M057', block: '9G3' },
  {  module: '42M057', block: '9G4' },
  {  module: '42M057', block: '9H1' },
  {  module: '42M057', block: '9H2' },
  {  module: '42M057', block: '9H3' },
  {  module: '42M057', block: '9HB' },
  {  module: '42M057', block: '9J0' },
  {  module: '42M057', block: '9J1' },
  {  module: '42M057', block: '9J2' },
  {  module: '42M057', block: '9J3' },
  {  module: '42M057', block: '9J4' },
  {  module: '42M057', block: '9J5' },
  {  module: '42M057', block: '9J6' },
  {  module: '42M057', block: '9J7' },
  {  module: '42M057', block: '9J8' },
  {  module: '42M057', block: '9J9' },
  {  module: '42M057', block: '9JB' },
  {  module: '42M057', block: '9K1' },
  {  module: '42M057', block: 'A00' },
  {  module: '42M057', block: 'B00' },
  {  module: '42M057', block: 'C00' },
  {  module: '42M057', block: 'M00' },
  {  module: '42M058', block: '2M1' },
  {  module: '42M058', block: '2M3' },
  {  module: '42M058', block: '2M4' },
  {  module: '42M058', block: '2P1' },
  {  module: '42M058', block: '2P2' },
  {  module: '42M058', block: '2P3' },
  {  module: '42M058', block: '2Q1' },
  {  module: '42M058', block: '2Q2' },
  {  module: '42M058', block: '2Q3' },
  {  module: '42M058', block: '2Q4' },
  {  module: '42M058', block: '2Q5' },
  {  module: '42M058', block: '2Q6' },
  {  module: '42M058', block: '2Q7' },
  {  module: '42M058', block: '2Q8' },
  {  module: '42M058', block: '2R1' },
  {  module: '42M058', block: '2R2' },
  {  module: '42M058', block: '2R3' },
  {  module: '42M058', block: '2S1' },
  {  module: '42M058', block: '2S2' },
  {  module: '42M058', block: '2S3' },
  {  module: '42M058', block: '2SA' },
  {  module: '42M058', block: '2T1' },
  {  module: '42M058', block: '2T2' },
  {  module: '42M058', block: '2T3' },
  {  module: '42M058', block: '2T4' },
  {  module: '42M058', block: '2T5' },
  {  module: '42M058', block: '2T6' },
  {  module: '42M058', block: '2T7' },
  {  module: '42M058', block: '2U1' },
  {  module: '42M058', block: '2U2' },
  {  module: '42M058', block: '2U3' },
  {  module: '42M058', block: '2UA' },
  {  module: '42M058', block: '2UB' },
  {  module: '42M058', block: '2V0' },
  {  module: '42M058', block: '2V1' },
  {  module: '42M058', block: '2V2' },
  {  module: '42M058', block: '2V3' },
  {  module: '42M058', block: '2V4' },
  {  module: '42M058', block: '2V5' },
  {  module: '42M058', block: '2V6' },
  {  module: '42M058', block: '2V7' },
  {  module: '42M058', block: '2V8' },
  {  module: '42M058', block: '2V9' },
  {  module: '42M058', block: '2W0' },
  {  module: '42M058', block: '2W1' },
  {  module: '42M058', block: '2W2' },
  {  module: '42M058', block: '2W3' },
  {  module: '42M058', block: '2W4' },
  {  module: '42M058', block: '2W5' },
  {  module: '42M058', block: '2W6' },
  {  module: '42M058', block: '2W7' },
  {  module: '42M058', block: '2W8' },
  {  module: '42M058', block: 'A00' },
  {  module: '42M058', block: 'B00' },
  {  module: '42M058', block: 'C00' },
  {  module: '42M058', block: 'M00' },
  {  module: '42M059', block: '1M1' },
  {  module: '42M059', block: '1M2' },
  {  module: '42M059', block: '1M3' },
  {  module: '42M059', block: '1P2' },
  {  module: '42M059', block: '1P4' },
  {  module: '42M059', block: '1P5' },
  {  module: '42M059', block: '1Q1' },
  {  module: '42M059', block: '1Q2' },
  {  module: '42M059', block: '1Q3' },
  {  module: '42M059', block: '1Q4' },
  {  module: '42M059', block: '1Q7' },
  {  module: '42M059', block: '1R1' },
  {  module: '42M059', block: '1R2' },
  {  module: '42M059', block: '1R3' },
  {  module: '42M059', block: '1R4' },
  {  module: '42M059', block: '1S1' },
  {  module: '42M059', block: '1S2' },
  {  module: '42M059', block: '1S3' },
  {  module: '42M059', block: '1SA' },
  {  module: '42M059', block: '1SB' },
  {  module: '42M059', block: '1SC' },
  {  module: '42M059', block: '1T0' },
  {  module: '42M059', block: '1T1' },
  {  module: '42M059', block: '1T2' },
  {  module: '42M059', block: '1T3' },
  {  module: '42M059', block: '1T4' },
  {  module: '42M059', block: '1T5' },
  {  module: '42M059', block: '1T7' },
  {  module: '42M059', block: '1T8' },
  {  module: '42M059', block: '1T9' },
  {  module: '42M059', block: '1TA' },
  {  module: '42M059', block: '1TB' },
  {  module: '42M059', block: '1U1' },
  {  module: '42M059', block: '1U2' },
  {  module: '42M059', block: '1U3' },
  {  module: '42M059', block: '1UA' },
  {  module: '42M059', block: '1UB' },
  {  module: '42M059', block: '1UC' },
  {  module: '42M059', block: '1V0' },
  {  module: '42M059', block: '1V1' },
  {  module: '42M059', block: '1V2' },
  {  module: '42M059', block: '1V3' },
  {  module: '42M059', block: '1V4' },
  {  module: '42M059', block: '1V5' },
  {  module: '42M059', block: '1V6' },
  {  module: '42M059', block: '1V7' },
  {  module: '42M059', block: '1V8' },
  {  module: '42M059', block: '1V9' },
  {  module: '42M059', block: '1W1' },
  {  module: '42M059', block: '1W2' },
  {  module: '42M059', block: '1W3' },
  {  module: '42M059', block: '1W4' },
  {  module: '42M059', block: '1W5' },
  {  module: '42M059', block: '1W6' },
  {  module: '42M059', block: '1W7' },
  {  module: '42M059', block: '1W8' },
  {  module: '42M059', block: '1WA' },
  {  module: '42M059', block: '1WB' },
  {  module: '42M059', block: 'A00' },
  {  module: '42M059', block: 'B00' },
  {  module: '42M059', block: 'C00' },
  {  module: '42M059', block: 'M00' },
  {  module: '42M060', block: '411' },
  {  module: '42M060', block: '412' },
  {  module: '42M060', block: '413' },
  {  module: '42M060', block: '421' },
  {  module: '42M060', block: '422' },
  {  module: '42M060', block: '423' },
  {  module: '42M060', block: '424' },
  {  module: '42M060', block: '425' },
  {  module: '42M060', block: '426' },
  {  module: '42M060', block: '427' },
  {  module: '42M060', block: '428' },
  {  module: '42M060', block: '431' },
  {  module: '42M060', block: '432' },
  {  module: '42M060', block: '433' },
  {  module: '42M060', block: '434' },
  {  module: '42M060', block: '435' },
  {  module: '42M060', block: 'A00' },
  {  module: '42M060', block: 'M00' },
  {  module: '42M063', block: '511' },
  {  module: '42M063', block: '512' },
  {  module: '42M063', block: '513' },
  {  module: '42M063', block: '521' },
  {  module: '42M063', block: '522' },
  {  module: '42M063', block: '523' },
  {  module: '42M063', block: '524' },
  {  module: '42M063', block: '525' },
  {  module: '42M063', block: '526' },
  {  module: '42M063', block: '527' },
  {  module: '42M063', block: '531' },
  {  module: '42M063', block: '532' },
  {  module: '42M063', block: '533' },
  {  module: '42M063', block: '534' },
  {  module: '42M063', block: '535' },
  {  module: '42M063', block: '536' },
  {  module: '42M063', block: 'A00' },
  {  module: '42M063', block: 'M00' },
  {  module: '42M065', block: '6M1' },
  {  module: '42M065', block: '6M2' },
  {  module: '42M065', block: '6M3' },
  {  module: '42M065', block: '6M4' },
  {  module: '42M065', block: '6MA' },
  {  module: '42M065', block: '6P1' },
  {  module: '42M065', block: '6P2' },
  {  module: '42M065', block: '6P3' },
  {  module: '42M065', block: '6P4' },
  {  module: '42M065', block: '6P5' },
  {  module: '42M065', block: '6P6' },
  {  module: '42M065', block: '6P7' },
  {  module: '42M065', block: '6Q1' },
  {  module: '42M065', block: '6Q2' },
  {  module: '42M065', block: '6Q3' },
  {  module: '42M065', block: '6QA' },
  {  module: '42M065', block: '6R1' },
  {  module: '42M065', block: '6R2' },
  {  module: '42M065', block: '6R3' },
  {  module: '42M065', block: '6S1' },
  {  module: '42M065', block: '6S2' },
  {  module: '42M065', block: '6S3' },
  {  module: '42M065', block: '6T1' },
  {  module: '42M065', block: '6T2' },
  {  module: '42M065', block: '6T3' },
  {  module: '42M065', block: '6T4' },
  {  module: '42M065', block: '6U1' },
  {  module: '42M065', block: '6U2' },
  {  module: '42M065', block: '6U3' },
  {  module: '42M065', block: '6U4' },
  {  module: '42M065', block: '6V1' },
  {  module: '42M065', block: '6V2' },
  {  module: '42M065', block: '6V3' },
  {  module: '42M065', block: '6W1' },
  {  module: '42M065', block: '6W2' },
  {  module: '42M065', block: '6W3' },
  {  module: '42M065', block: '6Y4' },
  {  module: '42M065', block: '6Y5' },
  {  module: '42M065', block: 'A00' },
  {  module: '42M065', block: 'B00' },
  {  module: '42M065', block: 'C00' },
  {  module: '42M065', block: 'M00' },
  {  module: '42M067', block: '611' },
  {  module: '42M067', block: '612' },
  {  module: '42M067', block: '613' },
  {  module: '42M067', block: '621' },
  {  module: '42M067', block: '622' },
  {  module: '42M067', block: '623' },
  {  module: '42M067', block: '624' },
  {  module: '42M067', block: '625' },
  {  module: '42M067', block: '62B' },
  {  module: '42M067', block: '631' },
  {  module: '42M067', block: '632' },
  {  module: '42M067', block: '633' },
  {  module: '42M067', block: '641' },
  {  module: '42M067', block: '642' },
  {  module: '42M067', block: '643' },
  {  module: '42M067', block: '64A' },
  {  module: '42M067', block: '64B' },
  {  module: '42M067', block: '651' },
  {  module: '42M067', block: '652' },
  {  module: '42M067', block: '653' },
  {  module: '42M067', block: '655' },
  {  module: '42M067', block: '656' },
  {  module: '42M067', block: '657' },
  {  module: '42M067', block: '661' },
  {  module: '42M067', block: '662' },
  {  module: '42M067', block: '663' },
  {  module: '42M067', block: '671' },
  {  module: '42M067', block: '672' },
  {  module: '42M067', block: '673' },
  {  module: '42M067', block: '674' },
  {  module: '42M067', block: '675' },
  {  module: '42M067', block: '676' },
  {  module: '42M067', block: '677' },
  {  module: '42M067', block: '678' },
  {  module: '42M067', block: '67A' },
  {  module: '42M067', block: '681' },
  {  module: '42M067', block: '682' },
  {  module: '42M067', block: '683' },
  {  module: '42M067', block: '684' },
  {  module: '42M067', block: '685' },
  {  module: '42M067', block: '691' },
  {  module: '42M067', block: '692' },
  {  module: '42M067', block: '69A' },
  {  module: '42M067', block: 'A00' },
  {  module: '42M067', block: 'B00' },
  {  module: '42M067', block: 'C00' },
  {  module: '42M067', block: 'M00' },
  {  module: '42M068', block: '701' },
  {  module: '42M068', block: '702' },
  {  module: '42M068', block: '703' },
  {  module: '42M068', block: '704' },
  {  module: '42M068', block: '705' },
  {  module: '42M068', block: '706' },
  {  module: '42M068', block: '707' },
  {  module: '42M068', block: '709' },
  {  module: '42M068', block: '711' },
  {  module: '42M068', block: '712' },
  {  module: '42M068', block: '713' },
  {  module: '42M068', block: '721' },
  {  module: '42M068', block: '722' },
  {  module: '42M068', block: '723' },
  {  module: '42M068', block: '731' },
  {  module: '42M068', block: '732' },
  {  module: '42M068', block: '733' },
  {  module: '42M068', block: '741' },
  {  module: '42M068', block: '742' },
  {  module: '42M068', block: '743' },
  {  module: '42M068', block: '751' },
  {  module: '42M068', block: '752' },
  {  module: '42M068', block: '753' },
  {  module: '42M068', block: '75A' },
  {  module: '42M068', block: '75B' },
  {  module: '42M068', block: '75C' },
  {  module: '42M068', block: '761' },
  {  module: '42M068', block: '762' },
  {  module: '42M068', block: '763' },
  {  module: '42M068', block: '771' },
  {  module: '42M068', block: '772' },
  {  module: '42M068', block: '773' },
  {  module: '42M068', block: '775' },
  {  module: '42M068', block: '776' },
  {  module: '42M068', block: '777' },
  {  module: '42M068', block: '77A' },
  {  module: '42M068', block: '77B' },
  {  module: '42M068', block: '77C' },
  {  module: '42M068', block: '781' },
  {  module: '42M068', block: '782' },
  {  module: '42M068', block: '783' },
  {  module: '42M068', block: '790' },
  {  module: '42M068', block: '791' },
  {  module: '42M068', block: '792' },
  {  module: '42M068', block: '793' },
  {  module: '42M068', block: '794' },
  {  module: '42M068', block: '795' },
  {  module: '42M068', block: '796' },
  {  module: '42M068', block: '797' },
  {  module: '42M068', block: '798' },
  {  module: '42M068', block: '799' },
  {  module: '42M068', block: '79A' },
  {  module: '42M068', block: '79B' },
  {  module: '42M068', block: 'A00' },
  {  module: '42M068', block: 'B00' },
  {  module: '42M068', block: 'C00' },
  {  module: '42M068', block: 'M00' },
  {  module: '42M069', block: '1A1' },
  {  module: '42M069', block: '1A2' },
  {  module: '42M069', block: '1C1' },
  {  module: '42M069', block: '1C2' },
  {  module: '42M069', block: '1F1' },
  {  module: '42M069', block: '1F2' },
  {  module: '42M069', block: '1H1' },
  {  module: '42M069', block: '1H2' },
  {  module: '42M069', block: '1J1' },
  {  module: '42M069', block: '1J2' },
  {  module: '42M069', block: '1K1' },
  {  module: '42M069', block: '1K2' },
  {  module: '42M069', block: '1K3' },
  {  module: '42M069', block: '1K4' },
  {  module: '42M069', block: '1K5' },
  {  module: '42M069', block: '1K6' },
  {  module: '42M069', block: '1K7' },
  {  module: '42M069', block: '1K8' },
  {  module: '42M069', block: '1K9' },
  {  module: '42M069', block: 'A00' },
  {  module: '42M069', block: 'B00' },
  {  module: '42M069', block: 'C00' },
  {  module: '42M069', block: 'M00' },
  {  module: '42M070', block: '2A1' },
  {  module: '42M070', block: '2A2' },
  {  module: '42M070', block: '2C1' },
  {  module: '42M070', block: '2C2' },
  {  module: '42M070', block: '2F1' },
  {  module: '42M070', block: '2F2' },
  {  module: '42M070', block: '2G1' },
  {  module: '42M070', block: '2G2' },
  {  module: '42M070', block: '2H1' },
  {  module: '42M070', block: '2H2' },
  {  module: '42M070', block: '2J1' },
  {  module: '42M070', block: '2J2' },
  {  module: '42M070', block: '2J3' },
  {  module: '42M070', block: '2J4' },
  {  module: '42M070', block: '2J5' },
  {  module: '42M070', block: '2J6' },
  {  module: '42M070', block: '2J7' },
  {  module: '42M070', block: '2J8' },
  {  module: '42M070', block: '2J9' },
  {  module: '42M070', block: '2JA' },
  {  module: '42M070', block: 'A00' },
  {  module: '42M070', block: 'B00' },
  {  module: '42M070', block: 'C00' },
  {  module: '42M070', block: 'M00' },
  {  module: '42M071', block: '3A1' },
  {  module: '42M071', block: '3A2' },
  {  module: '42M071', block: '3B1' },
  {  module: '42M071', block: '3B2' },
  {  module: '42M071', block: '3B3' },
  {  module: '42M071', block: '3B4' },
  {  module: '42M071', block: '3B5' },
  {  module: '42M071', block: '3C1' },
  {  module: '42M071', block: '3C2' },
  {  module: '42M071', block: '3D1' },
  {  module: '42M071', block: '3D2' },
  {  module: '42M071', block: '3DA' },
  {  module: '42M071', block: '3F1' },
  {  module: '42M071', block: '3F2' },
  {  module: '42M071', block: '3G1' },
  {  module: '42M071', block: '3G2' },
  {  module: '42M071', block: '3GA' },
  {  module: '42M071', block: '3H1' },
  {  module: '42M071', block: '3H2' },
  {  module: '42M071', block: '3H3' },
  {  module: '42M071', block: '3H4' },
  {  module: '42M071', block: '3H5' },
  {  module: '42M071', block: '3H6' },
  {  module: '42M071', block: '3H7' },
  {  module: '42M071', block: '3H8' },
  {  module: '42M071', block: '3H9' },
  {  module: '42M071', block: 'A00' },
  {  module: '42M071', block: 'B00' },
  {  module: '42M071', block: 'C00' },
  {  module: '42M071', block: 'M00' },
  {  module: '42M072', block: '4A1' },
  {  module: '42M072', block: '4A2' },
  {  module: '42M072', block: '4B1' },
  {  module: '42M072', block: '4B2' },
  {  module: '42M072', block: '4C1' },
  {  module: '42M072', block: '4C2' },
  {  module: '42M072', block: '4D1' },
  {  module: '42M072', block: '4D2' },
  {  module: '42M072', block: '4D3' },
  {  module: '42M072', block: '4F1' },
  {  module: '42M072', block: '4F2' },
  {  module: '42M072', block: '4G1' },
  {  module: '42M072', block: '4G2' },
  {  module: '42M072', block: '4H1' },
  {  module: '42M072', block: '4H2' },
  {  module: '42M072', block: '4H3' },
  {  module: '42M072', block: '4H4' },
  {  module: '42M072', block: '4H5' },
  {  module: '42M072', block: '4H6' },
  {  module: '42M072', block: '4H7' },
  {  module: '42M072', block: '4H8' },
  {  module: '42M072', block: '4H9' },
  {  module: '42M072', block: 'A00' },
  {  module: '42M072', block: 'B00' },
  {  module: '42M072', block: 'C00' },
  {  module: '42M072', block: 'M00' },
  {  module: '42M073', block: '4M1' },
  {  module: '42M073', block: '4M2' },
  {  module: '42M073', block: '4M3' },
  {  module: '42M073', block: '4P1' },
  {  module: '42M073', block: '4P2' },
  {  module: '42M073', block: '4P3' },
  {  module: '42M073', block: '4P4' },
  {  module: '42M073', block: '4Q1' },
  {  module: '42M073', block: '4Q2' },
  {  module: '42M073', block: '4Q3' },
  {  module: '42M073', block: '4Q4' },
  {  module: '42M073', block: '4Q5' },
  {  module: '42M073', block: '4R1' },
  {  module: '42M073', block: '4R2' },
  {  module: '42M073', block: '4S1' },
  {  module: '42M073', block: '4S2' },
  {  module: '42M073', block: '4S3' },
  {  module: '42M073', block: '4T1' },
  {  module: '42M073', block: '4T2' },
  {  module: '42M073', block: '4T3' },
  {  module: '42M073', block: '4T4' },
  {  module: '42M073', block: '4T5' },
  {  module: '42M073', block: '4TA' },
  {  module: '42M073', block: '4TB' },
  {  module: '42M073', block: '4U1' },
  {  module: '42M073', block: '4U2' },
  {  module: '42M073', block: '4V1' },
  {  module: '42M073', block: '4V2' },
  {  module: '42M073', block: '4W1' },
  {  module: '42M073', block: 'A00' },
  {  module: '42M073', block: 'B00' },
  {  module: '42M073', block: 'C00' },
  {  module: '42M073', block: 'M00' },
  {  module: '42M074', block: '7M1' },
  {  module: '42M074', block: '7M2' },
  {  module: '42M074', block: '7M3' },
  {  module: '42M074', block: '7P1' },
  {  module: '42M074', block: '7P2' },
  {  module: '42M074', block: '7P3' },
  {  module: '42M074', block: '7Q1' },
  {  module: '42M074', block: '7Q2' },
  {  module: '42M074', block: '7Q3' },
  {  module: '42M074', block: '7R1' },
  {  module: '42M074', block: '7R2' },
  {  module: '42M074', block: '7R3' },
  {  module: '42M074', block: '7S1' },
  {  module: '42M074', block: '7S2' },
  {  module: '42M074', block: '7S3' },
  {  module: '42M074', block: '7T1' },
  {  module: '42M074', block: '7T2' },
  {  module: '42M074', block: '7TA' },
  {  module: '42M074', block: '7U1' },
  {  module: '42M074', block: '7U2' },
  {  module: '42M074', block: '7U3' },
  {  module: '42M074', block: '7U4' },
  {  module: '42M074', block: '7V1' },
  {  module: '42M074', block: '7V2' },
  {  module: '42M074', block: '7V3' },
  {  module: '42M074', block: '7V7' },
  {  module: '42M074', block: '7V8' },
  {  module: '42M074', block: '7V9' },
  {  module: '42M074', block: '7W0' },
  {  module: '42M074', block: '7W1' },
  {  module: '42M074', block: '7W2' },
  {  module: '42M074', block: '7W3' },
  {  module: '42M074', block: '7W4' },
  {  module: '42M074', block: '7W5' },
  {  module: '42M074', block: '7W6' },
  {  module: '42M074', block: '7W7' },
  {  module: '42M074', block: '7W8' },
  {  module: '42M074', block: '7W9' },
  {  module: '42M074', block: '7WA' },
  {  module: '42M074', block: '7WB' },
  {  module: '42M074', block: '7WC' },
  {  module: '42M074', block: '7WD' },
  {  module: '42M074', block: 'A00' },
  {  module: '42M074', block: 'B00' },
  {  module: '42M074', block: 'C00' },
  {  module: '42M074', block: 'M00' },
  {  module: '42M075', block: '8M1' },
  {  module: '42M075', block: '8M2' },
  {  module: '42M075', block: '8M3' },
  {  module: '42M075', block: '8P1' },
  {  module: '42M075', block: '8P2' },
  {  module: '42M075', block: '8P3' },
  {  module: '42M075', block: '8Q1' },
  {  module: '42M075', block: '8Q2' },
  {  module: '42M075', block: '8Q3' },
  {  module: '42M075', block: '8R1' },
  {  module: '42M075', block: '8R2' },
  {  module: '42M075', block: '8R3' },
  {  module: '42M075', block: '8S1' },
  {  module: '42M075', block: '8S2' },
  {  module: '42M075', block: '8S3' },
  {  module: '42M075', block: '8T1' },
  {  module: '42M075', block: '8T2' },
  {  module: '42M075', block: '8U1' },
  {  module: '42M075', block: '8U2' },
  {  module: '42M075', block: '8U3' },
  {  module: '42M075', block: '8U4' },
  {  module: '42M075', block: '8V1' },
  {  module: '42M075', block: '8V2' },
  {  module: '42M075', block: '8V3' },
  {  module: '42M075', block: '8V7' },
  {  module: '42M075', block: '8V8' },
  {  module: '42M075', block: '8V9' },
  {  module: '42M075', block: '8W0' },
  {  module: '42M075', block: '8W1' },
  {  module: '42M075', block: '8W2' },
  {  module: '42M075', block: '8W3' },
  {  module: '42M075', block: '8W4' },
  {  module: '42M075', block: '8W5' },
  {  module: '42M075', block: '8W6' },
  {  module: '42M075', block: '8W7' },
  {  module: '42M075', block: '8W8' },
  {  module: '42M075', block: '8W9' },
  {  module: '42M075', block: '8WA' },
  {  module: '42M075', block: '8WB' },
  {  module: '42M075', block: '8WC' },
  {  module: '42M075', block: '8WD' },
  {  module: '42M075', block: 'A00' },
  {  module: '42M075', block: 'B00' },
  {  module: '42M075', block: 'C00' },
  {  module: '42M075', block: 'M00' },
  {  module: '42M078', block: '9M1' },
  {  module: '42M078', block: '9M2' },
  {  module: '42M078', block: '9M3' },
  {  module: '42M078', block: '9M4' },
  {  module: '42M078', block: '9M5' },
  {  module: '42M078', block: '9M6' },
  {  module: '42M078', block: '9M7' },
  {  module: '42M078', block: '9M8' },
  {  module: '42M078', block: '9M9' },
  {  module: '42M078', block: '9MA' },
  {  module: '42M078', block: '9P0' },
  {  module: '42M078', block: '9P1' },
  {  module: '42M078', block: '9P2' },
  {  module: '42M078', block: '9P3' },
  {  module: '42M078', block: '9P4' },
  {  module: '42M078', block: '9P5' },
  {  module: '42M078', block: '9P6' },
  {  module: '42M078', block: '9P7' },
  {  module: '42M078', block: '9P8' },
  {  module: '42M078', block: '9Q2' },
  {  module: '42M078', block: '9Q3' },
  {  module: '42M078', block: '9Q5' },
  {  module: '42M078', block: '9Q6' },
  {  module: '42M078', block: '9Q7' },
  {  module: '42M078', block: '9Q8' },
  {  module: '42M078', block: '9Q9' },
  {  module: '42M078', block: '9R1' },
  {  module: '42M078', block: '9R2' },
  {  module: '42M078', block: '9R3' },
  {  module: '42M078', block: '9R4' },
  {  module: '42M078', block: '9R9' },
  {  module: '42M078', block: '9S1' },
  {  module: '42M078', block: '9S2' },
  {  module: '42M078', block: '9S3' },
  {  module: '42M078', block: '9T0' },
  {  module: '42M078', block: '9T1' },
  {  module: '42M078', block: '9T2' },
  {  module: '42M078', block: '9T3' },
  {  module: '42M078', block: '9T4' },
  {  module: '42M078', block: '9T5' },
  {  module: '42M078', block: '9T6' },
  {  module: '42M078', block: '9T7' },
  {  module: '42M078', block: '9T8' },
  {  module: '42M078', block: '9T9' },
  {  module: '42M078', block: '9U1' },
  {  module: '42M078', block: '9U3' },
  {  module: '42M078', block: 'A00' },
  {  module: '42M078', block: 'B00' },
  {  module: '42M078', block: 'M00' },
  {  module: '42M081', block: '3R1' },
  {  module: '42M081', block: '3R2' },
  {  module: '42M081', block: '3S1' },
  {  module: '42M081', block: '3S2' },
  {  module: '42M081', block: '3S3' },
  {  module: '42M081', block: '3S4' },
  {  module: '42M081', block: '3T1' },
  {  module: '42M081', block: '3T2' },
  {  module: '42M081', block: '3U1' },
  {  module: '42M081', block: '3U2' },
  {  module: '42M081', block: '3V1' },
  {  module: '42M081', block: '3V2' },
  {  module: '42M081', block: '3V3' },
  {  module: '42M081', block: '3V4' },
  {  module: '42M081', block: '3V5' },
  {  module: '42M081', block: 'A00' },
  {  module: '42M081', block: 'B00' },
  {  module: '42M081', block: 'M00' },
  {  module: '42M086', block: '811' },
  {  module: '42M086', block: '821' },
  {  module: '42M086', block: '822' },
  {  module: '42M086', block: '823' },
  {  module: '42M086', block: '824' },
  {  module: '42M086', block: '831' },
  {  module: '42M086', block: 'A00' },
  {  module: '42M086', block: 'M00' },
  {  module: '42M091', block: '211' },
  {  module: '42M091', block: '221' },
  {  module: '42M091', block: '222' },
  {  module: '42M091', block: '223' },
  {  module: '42M091', block: '224' },
  {  module: '42M091', block: '231' },
  {  module: '42M091', block: '232' },
  {  module: '42M091', block: '241' },
  {  module: '42M091', block: '251' },
  {  module: '42M091', block: '261' },
  {  module: '42M091', block: 'A00' },
  {  module: '42M091', block: 'B00' },
  {  module: '42M091', block: 'M00' },
  {  module: '42M092', block: '311' },
  {  module: '42M092', block: '321' },
  {  module: '42M092', block: '322' },
  {  module: '42M092', block: '323' },
  {  module: '42M092', block: '331' },
  {  module: '42M092', block: '332' },
  {  module: '42M092', block: '341' },
  {  module: '42M092', block: '351' },
  {  module: '42M092', block: '361' },
  {  module: '42M092', block: 'A00' },
  {  module: '42M092', block: 'B00' },
  {  module: '42M092', block: 'M00' },
  {  module: '42M802', block: '111' },
  {  module: '42M802', block: '112' },
  {  module: '42M802', block: '113' },
  {  module: '42M802', block: '114' },
  {  module: '42M802', block: '121' },
  {  module: '42M802', block: '122' },
  {  module: '42M802', block: '131' },
  {  module: '42M802', block: '132' },
  {  module: '42M802', block: '133' },
  {  module: '42M802', block: '141' },
  {  module: '42M802', block: '142' },
  {  module: '42M802', block: '143' },
  {  module: '42M802', block: '151' },
  {  module: '42M802', block: '152' },
  {  module: '42M802', block: '153' },
  {  module: '42M802', block: '154' },
  {  module: '42M802', block: '157' },
  {  module: '42M802', block: '161' },
  {  module: '42M802', block: '162' },
  {  module: '42M802', block: 'A00' },
  {  module: '42M802', block: 'B00' },
  {  module: '42M802', block: 'M00' },
  {  module: '43K101', block: '6A1' },
  {  module: '43K101', block: '6A2' },
  {  module: '43K101', block: '6A3' },
  {  module: '43K101', block: '6B1' },
  {  module: '43K101', block: '6B2' },
  {  module: '43K101', block: '6B3' },
  {  module: '43K101', block: '6B4' },
  {  module: '43K101', block: '6B5' },
  {  module: '43K101', block: '6B6' },
  {  module: '43K101', block: 'A00' },
  {  module: '43K101', block: 'M00' },
  {  module: '43K102', block: '7A1' },
  {  module: '43K102', block: '7A2' },
  {  module: '43K102', block: '7A3' },
  {  module: '43K102', block: '7B1' },
  {  module: '43K102', block: '7B2' },
  {  module: '43K102', block: '7B3' },
  {  module: '43K102', block: '7B4' },
  {  module: '43K102', block: '7B5' },
  {  module: '43K102', block: '7B6' },
  {  module: '43K102', block: '7C1' },
  {  module: '43K102', block: '7C2' },
  {  module: '43K102', block: 'A00' },
  {  module: '43K102', block: 'M00' },
  {  module: '43M101', block: '7M1' },
  {  module: '43M101', block: '7M2' },
  {  module: '43M101', block: '7M3' },
  {  module: '43M101', block: '7P1' },
  {  module: '43M101', block: '7P2' },
  {  module: '43M101', block: '7P3' },
  {  module: '43M101', block: '7P4' },
  {  module: '43M101', block: '7Q1' },
  {  module: '43M101', block: '7Q2' },
  {  module: '43M101', block: '7Q3' },
  {  module: '43M101', block: '7Q4' },
  {  module: '43M101', block: '7R1' },
  {  module: '43M101', block: '7R2' },
  {  module: '43M101', block: '7R3' },
  {  module: '43M101', block: '7S1' },
  {  module: '43M101', block: '7S2' },
  {  module: '43M101', block: '7S3' },
  {  module: '43M101', block: '7T2' },
  {  module: '43M101', block: '7T3' },
  {  module: '43M101', block: '7U1' },
  {  module: '43M101', block: '7U2' },
  {  module: '43M101', block: '7U3' },
  {  module: '43M101', block: '7V1' },
  {  module: '43M101', block: '7V2' },
  {  module: '43M101', block: '7V3' },
  {  module: '43M101', block: '7V4' },
  {  module: '43M101', block: '7V5' },
  {  module: '43M101', block: 'A00' },
  {  module: '43M101', block: 'B00' },
  {  module: '43M101', block: 'M00' },
  {  module: '43M102', block: '1A1' },
  {  module: '43M102', block: '1A2' },
  {  module: '43M102', block: '1A3' },
  {  module: '43M102', block: '1A4' },
  {  module: '43M102', block: '1AA' },
  {  module: '43M102', block: '1B1' },
  {  module: '43M102', block: '1B2' },
  {  module: '43M102', block: '1B3' },
  {  module: '43M102', block: '1B4' },
  {  module: '43M102', block: '1B5' },
  {  module: '43M102', block: '1B6' },
  {  module: '43M102', block: '1B7' },
  {  module: '43M102', block: '1C1' },
  {  module: '43M102', block: '1C2' },
  {  module: '43M102', block: '1C3' },
  {  module: '43M102', block: '1C4' },
  {  module: '43M102', block: '1C5' },
  {  module: '43M102', block: '1C9' },
  {  module: '43M102', block: '1D1' },
  {  module: '43M102', block: '1D2' },
  {  module: '43M102', block: '1D3' },
  {  module: '43M102', block: '1D9' },
  {  module: '43M102', block: '1F1' },
  {  module: '43M102', block: '1F2' },
  {  module: '43M102', block: '1F3' },
  {  module: '43M102', block: '1F4' },
  {  module: '43M102', block: '1F5' },
  {  module: '43M102', block: '1F6' },
  {  module: '43M102', block: '1F7' },
  {  module: '43M102', block: '1F8' },
  {  module: '43M102', block: '1F9' },
  {  module: '43M102', block: '1G1' },
  {  module: '43M102', block: '1G2' },
  {  module: '43M102', block: '1G3' },
  {  module: '43M102', block: '1G4' },
  {  module: '43M102', block: '1G9' },
  {  module: '43M102', block: '1H1' },
  {  module: '43M102', block: '1H2' },
  {  module: '43M102', block: '1H3' },
  {  module: '43M102', block: '1HA' },
  {  module: '43M102', block: '1J1' },
  {  module: '43M102', block: '1J2' },
  {  module: '43M102', block: '1J3' },
  {  module: '43M102', block: '1J4' },
  {  module: '43M102', block: '1J5' },
  {  module: '43M102', block: '1J6' },
  {  module: '43M102', block: '1K1' },
  {  module: '43M102', block: '1K2' },
  {  module: '43M102', block: '1K3' },
  {  module: '43M102', block: '1K4' },
  {  module: '43M102', block: '1K5' },
  {  module: '43M102', block: 'A00' },
  {  module: '43M102', block: 'B00' },
  {  module: '43M102', block: 'C00' },
  {  module: '43M102', block: 'M00' },
  {  module: '43M103', block: '8A1' },
  {  module: '43M103', block: '8A2' },
  {  module: '43M103', block: '8A3' },
  {  module: '43M103', block: '8A4' },
  {  module: '43M103', block: '8B1' },
  {  module: '43M103', block: '8B2' },
  {  module: '43M103', block: '8B3' },
  {  module: '43M103', block: '8B4' },
  {  module: '43M103', block: '8B5' },
  {  module: '43M103', block: '8C1' },
  {  module: '43M103', block: '8C2' },
  {  module: '43M103', block: '8C3' },
  {  module: '43M103', block: '8C4' },
  {  module: '43M103', block: '8C9' },
  {  module: '43M103', block: '8D1' },
  {  module: '43M103', block: 'A00' },
  {  module: '43M103', block: 'B00' },
  {  module: '43M103', block: 'M00' },
  {  module: '43M104', block: '8X4' },
  {  module: '43M104', block: '9A1' },
  {  module: '43M104', block: '9A2' },
  {  module: '43M104', block: '9A3' },
  {  module: '43M104', block: '9B1' },
  {  module: '43M104', block: '9B2' },
  {  module: '43M104', block: '9B3' },
  {  module: '43M104', block: '9B4' },
  {  module: '43M104', block: '9B5' },
  {  module: '43M104', block: '9B6' },
  {  module: '43M104', block: '9B7' },
  {  module: '43M104', block: '9C1' },
  {  module: '43M104', block: '9C2' },
  {  module: '43M104', block: '9C3' },
  {  module: '43M104', block: '9C4' },
  {  module: '43M104', block: '9C5' },
  {  module: '43M104', block: '9C6' },
  {  module: '43M104', block: '9C7' },
  {  module: '43M104', block: '9C8' },
  {  module: '43M104', block: '9C9' },
  {  module: '43M104', block: '9D1' },
  {  module: '43M104', block: '9D2' },
  {  module: '43M104', block: '9D3' },
  {  module: '43M104', block: '9D9' },
  {  module: '43M104', block: '9F1' },
  {  module: '43M104', block: '9F2' },
  {  module: '43M104', block: '9F3' },
  {  module: '43M104', block: '9F5' },
  {  module: '43M104', block: '9F9' },
  {  module: '43M104', block: '9G1' },
  {  module: '43M104', block: '9G2' },
  {  module: '43M104', block: '9G3' },
  {  module: '43M104', block: '9G4' },
  {  module: '43M104', block: '9G5' },
  {  module: '43M104', block: '9G6' },
  {  module: '43M104', block: '9G7' },
  {  module: '43M104', block: '9H1' },
  {  module: '43M104', block: '9H2' },
  {  module: '43M104', block: '9H3' },
  {  module: '43M104', block: '9H4' },
  {  module: '43M104', block: '9H5' },
  {  module: '43M104', block: '9H6' },
  {  module: '43M104', block: '9J1' },
  {  module: '43M104', block: '9J2' },
  {  module: '43M104', block: '9J3' },
  {  module: '43M104', block: '9J9' },
  {  module: '43M104', block: '9K1' },
  {  module: '43M104', block: '9K2' },
  {  module: '43M104', block: '9KC' },
  {  module: '43M104', block: '9KD' },
  {  module: '43M104', block: '9L1' },
  {  module: '43M104', block: '9L2' },
  {  module: '43M104', block: '9L3' },
  {  module: '43M104', block: 'A00' },
  {  module: '43M104', block: 'B00' },
  {  module: '43M104', block: 'C00' },
  {  module: '43M104', block: 'M00' },
  {  module: '43M105', block: '1M1' },
  {  module: '43M105', block: '1M2' },
  {  module: '43M105', block: '1M3' },
  {  module: '43M105', block: '1P1' },
  {  module: '43M105', block: '1P2' },
  {  module: '43M105', block: '1P3' },
  {  module: '43M105', block: '1P4' },
  {  module: '43M105', block: '1Q1' },
  {  module: '43M105', block: '1Q2' },
  {  module: '43M105', block: '1Q9' },
  {  module: '43M105', block: 'A00' },
  {  module: '43M105', block: 'M00' },
  {  module: '43M106', block: '2M1' },
  {  module: '43M106', block: '2M2' },
  {  module: '43M106', block: '2M3' },
  {  module: '43M106', block: '2P1' },
  {  module: '43M106', block: '2P2' },
  {  module: '43M106', block: '2P3' },
  {  module: '43M106', block: '2Q1' },
  {  module: '43M106', block: '2Q2' },
  {  module: '43M106', block: '2Q3' },
  {  module: '43M106', block: '2Q4' },
  {  module: '43M106', block: '2R1' },
  {  module: '43M106', block: '2R2' },
  {  module: '43M106', block: '2R3' },
  {  module: '43M106', block: '2R6' },
  {  module: '43M106', block: '2S1' },
  {  module: '43M106', block: '2S2' },
  {  module: '43M106', block: '2S3' },
  {  module: '43M106', block: '2S9' },
  {  module: '43M106', block: '2T1' },
  {  module: '43M106', block: '2T2' },
  {  module: '43M106', block: '2T3' },
  {  module: '43M106', block: '2T4' },
  {  module: '43M106', block: '2T9' },
  {  module: '43M106', block: 'A00' },
  {  module: '43M106', block: 'B00' },
  {  module: '43M106', block: 'M00' },
  {  module: '43M108', block: '2A1' },
  {  module: '43M108', block: '2A2' },
  {  module: '43M108', block: '2A3' },
  {  module: '43M108', block: '2A4' },
  {  module: '43M108', block: '2A5' },
  {  module: '43M108', block: '2A6' },
  {  module: '43M108', block: '2B1' },
  {  module: '43M108', block: '2B3' },
  {  module: '43M108', block: '2B4' },
  {  module: '43M108', block: '2B5' },
  {  module: '43M108', block: '2B6' },
  {  module: '43M108', block: '2B7' },
  {  module: '43M108', block: '2B8' },
  {  module: '43M108', block: '2B9' },
  {  module: '43M108', block: '2C1' },
  {  module: '43M108', block: '2C2' },
  {  module: '43M108', block: '2C3' },
  {  module: '43M108', block: '2C4' },
  {  module: '43M108', block: '2C5' },
  {  module: '43M108', block: '2C8' },
  {  module: '43M108', block: '2C9' },
  {  module: '43M108', block: '2D1' },
  {  module: '43M108', block: '2D2' },
  {  module: '43M108', block: '2D3' },
  {  module: '43M108', block: '2D9' },
  {  module: '43M108', block: '2F1' },
  {  module: '43M108', block: '2F2' },
  {  module: '43M108', block: '2F3' },
  {  module: '43M108', block: '2F4' },
  {  module: '43M108', block: '2F5' },
  {  module: '43M108', block: '2F6' },
  {  module: '43M108', block: '2F7' },
  {  module: '43M108', block: '2G1' },
  {  module: '43M108', block: '2G2' },
  {  module: '43M108', block: '2G3' },
  {  module: '43M108', block: '2G9' },
  {  module: '43M108', block: '2H1' },
  {  module: '43M108', block: '2H2' },
  {  module: '43M108', block: '2H3' },
  {  module: '43M108', block: '2J2' },
  {  module: '43M108', block: '2J3' },
  {  module: '43M108', block: '2J4' },
  {  module: '43M108', block: '2J5' },
  {  module: '43M108', block: '2J6' },
  {  module: '43M108', block: '2JA' },
  {  module: '43M108', block: '2K1' },
  {  module: '43M108', block: '2K2' },
  {  module: '43M108', block: '2K3' },
  {  module: '43M108', block: '2K4' },
  {  module: '43M108', block: '2K5' },
  {  module: '43M108', block: '2K6' },
  {  module: '43M108', block: 'A00' },
  {  module: '43M108', block: 'B00' },
  {  module: '43M108', block: 'C00' },
  {  module: '43M108', block: 'M00' },
  {  module: '43M109', block: '8M1' },
  {  module: '43M109', block: '8M2' },
  {  module: '43M109', block: '8M3' },
  {  module: '43M109', block: '8P0' },
  {  module: '43M109', block: '8P1' },
  {  module: '43M109', block: '8P2' },
  {  module: '43M109', block: '8P3' },
  {  module: '43M109', block: '8P4' },
  {  module: '43M109', block: '8P5' },
  {  module: '43M109', block: '8P6' },
  {  module: '43M109', block: '8P7' },
  {  module: '43M109', block: '8P8' },
  {  module: '43M109', block: '8P9' },
  {  module: '43M109', block: '8Q0' },
  {  module: '43M109', block: '8Q1' },
  {  module: '43M109', block: '8Q2' },
  {  module: '43M109', block: '8Q3' },
  {  module: '43M109', block: '8Q4' },
  {  module: '43M109', block: '8Q5' },
  {  module: '43M109', block: '8Q6' },
  {  module: '43M109', block: '8Q7' },
  {  module: '43M109', block: '8Q8' },
  {  module: '43M109', block: '8Q9' },
  {  module: '43M109', block: '8R1' },
  {  module: '43M109', block: '8R2' },
  {  module: '43M109', block: '8R3' },
  {  module: '43M109', block: '8R4' },
  {  module: '43M109', block: '8R9' },
  {  module: '43M109', block: '8S1' },
  {  module: '43M109', block: '8S2' },
  {  module: '43M109', block: '8S3' },
  {  module: '43M109', block: '8S9' },
  {  module: '43M109', block: '8T1' },
  {  module: '43M109', block: '8T2' },
  {  module: '43M109', block: '8T9' },
  {  module: '43M109', block: '8TA' },
  {  module: '43M109', block: '8TB' },
  {  module: '43M109', block: '8U1' },
  {  module: '43M109', block: '8U2' },
  {  module: '43M109', block: '8U3' },
  {  module: '43M109', block: '8V1' },
  {  module: '43M109', block: '8V6' },
  {  module: '43M109', block: 'A00' },
  {  module: '43M109', block: 'B00' },
  {  module: '43M109', block: 'M00' },
  {  module: '43M112', block: '3M1' },
  {  module: '43M112', block: '3M2' },
  {  module: '43M112', block: '3M3' },
  {  module: '43M112', block: '3P1' },
  {  module: '43M112', block: '3P2' },
  {  module: '43M112', block: '3P3' },
  {  module: '43M112', block: '3P4' },
  {  module: '43M112', block: '3P9' },
  {  module: '43M112', block: '3Q2' },
  {  module: '43M112', block: 'A00' },
  {  module: '43M112', block: 'M00' },
  {  module: '43M113', block: '4M1' },
  {  module: '43M113', block: '4M2' },
  {  module: '43M113', block: '4M3' },
  {  module: '43M113', block: '4P1' },
  {  module: '43M113', block: '4P2' },
  {  module: '43M113', block: '4P3' },
  {  module: '43M113', block: '4P9' },
  {  module: '43M113', block: '4Q1' },
  {  module: '43M113', block: 'A00' },
  {  module: '43M113', block: 'M00' },
  {  module: '43M114', block: '5M1' },
  {  module: '43M114', block: '5M2' },
  {  module: '43M114', block: '5P1' },
  {  module: '43M114', block: '5Q1' },
  {  module: '43M114', block: '5Q2' },
  {  module: '43M114', block: '5Q3' },
  {  module: '43M114', block: '5Q9' },
  {  module: '43M114', block: '5R1' },
  {  module: '43M114', block: 'A00' },
  {  module: '43M114', block: 'B00' },
  {  module: '43M114', block: 'M00' },
  {  module: '43M115', block: '9M1' },
  {  module: '43M115', block: '9M2' },
  {  module: '43M115', block: '9M3' },
  {  module: '43M115', block: '9M4' },
  {  module: '43M115', block: '9P1' },
  {  module: '43M115', block: '9P3' },
  {  module: '43M115', block: '9Q1' },
  {  module: '43M115', block: '9Q2' },
  {  module: '43M115', block: '9Q3' },
  {  module: '43M115', block: '9Q4' },
  {  module: '43M115', block: '9Q5' },
  {  module: '43M115', block: '9R1' },
  {  module: '43M115', block: '9R2' },
  {  module: '43M115', block: '9R3' },
  {  module: '43M115', block: '9R9' },
  {  module: '43M115', block: 'A00' },
  {  module: '43M115', block: 'M00' },
  {  module: '44K156', block: '5A1' },
  {  module: '44K156', block: '5B1' },
  {  module: '44K156', block: '5B2' },
  {  module: '44K156', block: 'A00' },
  {  module: '44K156', block: 'M00' },
  {  module: '44M151', block: '3A1' },
  {  module: '44M151', block: '3A2' },
  {  module: '44M151', block: '3A3' },
  {  module: '44M151', block: '3A4' },
  {  module: '44M151', block: '3B1' },
  {  module: '44M151', block: '3B3' },
  {  module: '44M151', block: '3B4' },
  {  module: '44M151', block: '3B5' },
  {  module: '44M151', block: '3B6' },
  {  module: '44M151', block: '3B7' },
  {  module: '44M151', block: '3C1' },
  {  module: '44M151', block: '3C2' },
  {  module: '44M151', block: '3C3' },
  {  module: '44M151', block: '3C4' },
  {  module: '44M151', block: '3C9' },
  {  module: '44M151', block: '3D1' },
  {  module: '44M151', block: '3D2' },
  {  module: '44M151', block: '3D3' },
  {  module: '44M151', block: '3F1' },
  {  module: '44M151', block: '3F2' },
  {  module: '44M151', block: '3F3' },
  {  module: '44M151', block: '3F4' },
  {  module: '44M151', block: '3FB' },
  {  module: '44M151', block: '3FC' },
  {  module: '44M151', block: '3G1' },
  {  module: '44M151', block: '3G2' },
  {  module: '44M151', block: '3G3' },
  {  module: '44M151', block: '3G5' },
  {  module: '44M151', block: '3H1' },
  {  module: '44M151', block: '3H2' },
  {  module: '44M151', block: '3H3' },
  {  module: '44M151', block: '3HA' },
  {  module: '44M151', block: '3J1' },
  {  module: '44M151', block: '3J2' },
  {  module: '44M151', block: '3J3' },
  {  module: '44M151', block: '3J4' },
  {  module: '44M151', block: '3J5' },
  {  module: '44M151', block: '3K1' },
  {  module: '44M151', block: '3K3' },
  {  module: '44M151', block: '3K4' },
  {  module: '44M151', block: '3K5' },
  {  module: '44M151', block: '3K6' },
  {  module: '44M151', block: '3K7' },
  {  module: '44M151', block: '3L1' },
  {  module: '44M151', block: '3L3' },
  {  module: '44M151', block: 'A00' },
  {  module: '44M151', block: 'B00' },
  {  module: '44M151', block: 'C00' },
  {  module: '44M151', block: 'M00' },
  {  module: '45M201', block: '2M1' },
  {  module: '45M201', block: '2M2' },
  {  module: '45M201', block: '2M3' },
  {  module: '45M201', block: '2M4' },
  {  module: '45M201', block: '2P0' },
  {  module: '45M201', block: '2P1' },
  {  module: '45M201', block: '2P2' },
  {  module: '45M201', block: '2P3' },
  {  module: '45M201', block: '2P4' },
  {  module: '45M201', block: '2P5' },
  {  module: '45M201', block: '2P6' },
  {  module: '45M201', block: '2P7' },
  {  module: '45M201', block: '2P8' },
  {  module: '45M201', block: '2P9' },
  {  module: '45M201', block: '2Q1' },
  {  module: '45M201', block: '2Q2' },
  {  module: '45M201', block: '2Q3' },
  {  module: '45M201', block: '2Q4' },
  {  module: '45M201', block: '2Q5' },
  {  module: '45M201', block: '2Q6' },
  {  module: '45M201', block: '2Q7' },
  {  module: '45M201', block: '2Q8' },
  {  module: '45M201', block: '2Q9' },
  {  module: '45M201', block: '2R1' },
  {  module: '45M201', block: '2R2' },
  {  module: '45M201', block: '2R3' },
  {  module: '45M201', block: '2R4' },
  {  module: '45M201', block: '2S1' },
  {  module: '45M201', block: '2S2' },
  {  module: '45M201', block: '2S3' },
  {  module: '45M201', block: '2SA' },
  {  module: '45M201', block: '2SB' },
  {  module: '45M201', block: '2T1' },
  {  module: '45M201', block: '2T2' },
  {  module: '45M201', block: '2T3' },
  {  module: '45M201', block: '2T4' },
  {  module: '45M201', block: '2T5' },
  {  module: '45M201', block: '2U1' },
  {  module: '45M201', block: '2U2' },
  {  module: '45M201', block: '2U3' },
  {  module: '45M201', block: '2U4' },
  {  module: '45M201', block: '2UA' },
  {  module: '45M201', block: '2V1' },
  {  module: '45M201', block: 'A00' },
  {  module: '45M201', block: 'B00' },
  {  module: '45M201', block: 'M00' },
  {  module: '45M202', block: '3M1' },
  {  module: '45M202', block: '3M2' },
  {  module: '45M202', block: '3M3' },
  {  module: '45M202', block: '3P1' },
  {  module: '45M202', block: '3P2' },
  {  module: '45M202', block: '3P3' },
  {  module: '45M202', block: '3P4' },
  {  module: '45M202', block: '3P5' },
  {  module: '45M202', block: '3P6' },
  {  module: '45M202', block: '3P8' },
  {  module: '45M202', block: '3Q1' },
  {  module: '45M202', block: '3Q2' },
  {  module: '45M202', block: '3Q3' },
  {  module: '45M202', block: '3Q4' },
  {  module: '45M202', block: '3Q5' },
  {  module: '45M202', block: '3Q6' },
  {  module: '45M202', block: '3Q7' },
  {  module: '45M202', block: '3R1' },
  {  module: '45M202', block: '3R2' },
  {  module: '45M202', block: '3R3' },
  {  module: '45M202', block: '3S1' },
  {  module: '45M202', block: '3S2' },
  {  module: '45M202', block: '3S3' },
  {  module: '45M202', block: '3SA' },
  {  module: '45M202', block: '3T1' },
  {  module: '45M202', block: '3T2' },
  {  module: '45M202', block: '3T3' },
  {  module: '45M202', block: '3U1' },
  {  module: '45M202', block: '3U2' },
  {  module: '45M202', block: '3UA' },
  {  module: '45M202', block: '3V1' },
  {  module: '45M202', block: 'A00' },
  {  module: '45M202', block: 'B00' },
  {  module: '45M202', block: 'M00' },
  {  module: '45M206', block: '4M1' },
  {  module: '45M206', block: '4M2' },
  {  module: '45M206', block: '4M3' },
  {  module: '45M206', block: '4M4' },
  {  module: '45M206', block: '4P0' },
  {  module: '45M206', block: '4P1' },
  {  module: '45M206', block: '4P2' },
  {  module: '45M206', block: '4P3' },
  {  module: '45M206', block: '4P4' },
  {  module: '45M206', block: '4P5' },
  {  module: '45M206', block: '4P6' },
  {  module: '45M206', block: '4P7' },
  {  module: '45M206', block: '4P8' },
  {  module: '45M206', block: '4P9' },
  {  module: '45M206', block: '4Q1' },
  {  module: '45M206', block: '4Q2' },
  {  module: '45M206', block: '4Q3' },
  {  module: '45M206', block: '4Q4' },
  {  module: '45M206', block: '4R1' },
  {  module: '45M206', block: '4R2' },
  {  module: '45M206', block: '4R3' },
  {  module: '45M206', block: '4S1' },
  {  module: '45M206', block: '4S2' },
  {  module: '45M206', block: '4S3' },
  {  module: '45M206', block: '4T1' },
  {  module: '45M206', block: '4T2' },
  {  module: '45M206', block: '4T3' },
  {  module: '45M206', block: '4T4' },
  {  module: '45M206', block: '4U1' },
  {  module: '45M206', block: '4U2' },
  {  module: '45M206', block: '4U3' },
  {  module: '45M206', block: '4UA' },
  {  module: '45M206', block: '4V1' },
  {  module: '45M206', block: 'A00' },
  {  module: '45M206', block: 'B00' },
  {  module: '45M206', block: 'M00' },
  {  module: '45M208', block: '5M1' },
  {  module: '45M208', block: '5M2' },
  {  module: '45M208', block: '5M3' },
  {  module: '45M208', block: '5M4' },
  {  module: '45M208', block: '5P1' },
  {  module: '45M208', block: '5P2' },
  {  module: '45M208', block: '5P3' },
  {  module: '45M208', block: '5P4' },
  {  module: '45M208', block: '5P5' },
  {  module: '45M208', block: '5Q1' },
  {  module: '45M208', block: '5Q2' },
  {  module: '45M208', block: '5Q3' },
  {  module: '45M208', block: '5Q4' },
  {  module: '45M208', block: '5R1' },
  {  module: '45M208', block: '5R2' },
  {  module: '45M208', block: '5R3' },
  {  module: '45M208', block: '5RA' },
  {  module: '45M208', block: '5RB' },
  {  module: '45M208', block: '5RC' },
  {  module: '45M208', block: '5S1' },
  {  module: '45M208', block: '5S2' },
  {  module: '45M208', block: '5S3' },
  {  module: '45M208', block: '5T1' },
  {  module: '45M208', block: '5T2' },
  {  module: '45M208', block: 'A00' },
  {  module: '45M208', block: 'B00' },
  {  module: '45M208', block: 'M00' },
  {  module: '47M251', block: '4A1' },
  {  module: '47M251', block: '4A2' },
  {  module: '47M251', block: '4A3' },
  {  module: '47M251', block: '4AA' },
  {  module: '47M251', block: '4B1' },
  {  module: '47M251', block: '4B2' },
  {  module: '47M251', block: '4B3' },
  {  module: '47M251', block: '4B4' },
  {  module: '47M251', block: '4B5' },
  {  module: '47M251', block: '4B6' },
  {  module: '47M251', block: '4C1' },
  {  module: '47M251', block: '4C2' },
  {  module: '47M251', block: '4C3' },
  {  module: '47M251', block: '4C4' },
  {  module: '47M251', block: '4C5' },
  {  module: '47M251', block: '4D1' },
  {  module: '47M251', block: '4D2' },
  {  module: '47M251', block: '4D3' },
  {  module: '47M251', block: '4F1' },
  {  module: '47M251', block: '4F2' },
  {  module: '47M251', block: '4F3' },
  {  module: '47M251', block: '4F9' },
  {  module: '47M251', block: '4G6' },
  {  module: '47M251', block: '4H1' },
  {  module: '47M251', block: '4H2' },
  {  module: '47M251', block: '4H3' },
  {  module: '47M251', block: '4H9' },
  {  module: '47M251', block: '4HA' },
  {  module: '47M251', block: '4J0' },
  {  module: '47M251', block: '4J1' },
  {  module: '47M251', block: '4J2' },
  {  module: '47M251', block: '4J3' },
  {  module: '47M251', block: '4K0' },
  {  module: '47M251', block: '4K1' },
  {  module: '47M251', block: '4K2' },
  {  module: '47M251', block: '4K3' },
  {  module: '47M251', block: '4K4' },
  {  module: '47M251', block: '4K5' },
  {  module: '47M251', block: '4K6' },
  {  module: '47M251', block: '4K7' },
  {  module: '47M251', block: '4K8' },
  {  module: '47M251', block: '4K9' },
  {  module: '47M251', block: 'A00' },
  {  module: '47M251', block: 'B00' },
  {  module: '47M251', block: 'C00' },
  {  module: '47M251', block: 'M00' },
  {  module: '47M252', block: '5A1' },
  {  module: '47M252', block: '5A2' },
  {  module: '47M252', block: '5A3' },
  {  module: '47M252', block: '5B0' },
  {  module: '47M252', block: '5B1' },
  {  module: '47M252', block: '5B2' },
  {  module: '47M252', block: '5B3' },
  {  module: '47M252', block: '5B4' },
  {  module: '47M252', block: '5B5' },
  {  module: '47M252', block: '5B6' },
  {  module: '47M252', block: '5B7' },
  {  module: '47M252', block: '5B8' },
  {  module: '47M252', block: '5B9' },
  {  module: '47M252', block: '5C3' },
  {  module: '47M252', block: '5C4' },
  {  module: '47M252', block: '5C5' },
  {  module: '47M252', block: '5C9' },
  {  module: '47M252', block: '5D1' },
  {  module: '47M252', block: '5D2' },
  {  module: '47M252', block: '5D3' },
  {  module: '47M252', block: '5F1' },
  {  module: '47M252', block: '5G1' },
  {  module: '47M252', block: '5G2' },
  {  module: '47M252', block: '5G3' },
  {  module: '47M252', block: '5H1' },
  {  module: '47M252', block: '5H2' },
  {  module: '47M252', block: '5H3' },
  {  module: '47M252', block: '5J1' },
  {  module: '47M252', block: '5J2' },
  {  module: '47M252', block: '5J3' },
  {  module: '47M252', block: '5J4' },
  {  module: '47M252', block: '5J5' },
  {  module: '47M252', block: '5J7' },
  {  module: '47M252', block: '5J8' },
  {  module: '47M252', block: '5J9' },
  {  module: '47M252', block: '5K1' },
  {  module: '47M252', block: '5K2' },
  {  module: '47M252', block: '5K3' },
  {  module: '47M252', block: '5K4' },
  {  module: '47M252', block: 'A00' },
  {  module: '47M252', block: 'B00' },
  {  module: '47M252', block: 'C00' },
  {  module: '47M252', block: 'M00' },
  {  module: '47M253', block: '6A1' },
  {  module: '47M253', block: '6A2' },
  {  module: '47M253', block: '6A3' },
  {  module: '47M253', block: '6B1' },
  {  module: '47M253', block: '6B2' },
  {  module: '47M253', block: '6B3' },
  {  module: '47M253', block: '6C1' },
  {  module: '47M253', block: '6C2' },
  {  module: '47M253', block: '6C3' },
  {  module: '47M253', block: '6D1' },
  {  module: '47M253', block: '6D2' },
  {  module: '47M253', block: '6D3' },
  {  module: '47M253', block: '6F1' },
  {  module: '47M253', block: '6F2' },
  {  module: '47M253', block: '6F3' },
  {  module: '47M253', block: '6G1' },
  {  module: '47M253', block: '6G2' },
  {  module: '47M253', block: '6G4' },
  {  module: '47M253', block: 'A00' },
  {  module: '47M253', block: 'B00' },
  {  module: '47M253', block: 'M00' },
  {  module: '47M255', block: '6M1' },
  {  module: '47M255', block: '6M2' },
  {  module: '47M255', block: '6P1' },
  {  module: '47M255', block: '6P2' },
  {  module: '47M255', block: '6P3' },
  {  module: '47M255', block: '6P4' },
  {  module: '47M255', block: '6P5' },
  {  module: '47M255', block: '6P6' },
  {  module: '47M255', block: '6P7' },
  {  module: '47M255', block: '6P8' },
  {  module: '47M255', block: '6P9' },
  {  module: '47M255', block: 'A00' },
  {  module: '47M255', block: 'M00' },
  {  module: '47M256', block: '7M1' },
  {  module: '47M256', block: '7M2' },
  {  module: '47M256', block: '7P1' },
  {  module: '47M256', block: '7P2' },
  {  module: '47M256', block: '7P3' },
  {  module: '47M256', block: '7P4' },
  {  module: '47M256', block: '7P5' },
  {  module: '47M256', block: '7P6' },
  {  module: '47M256', block: '7P7' },
  {  module: '47M256', block: '7P8' },
  {  module: '47M256', block: '7P9' },
  {  module: '47M256', block: 'A00' },
  {  module: '47M256', block: 'M00' },
  {  module: '47M257', block: '8M1' },
  {  module: '47M257', block: '8M2' },
  {  module: '47M257', block: '8M3' },
  {  module: '47M257', block: '8P1' },
  {  module: '47M257', block: '8P2' },
  {  module: '47M257', block: '8P3' },
  {  module: '47M257', block: '8P4' },
  {  module: '47M257', block: '8Q1' },
  {  module: '47M257', block: '8Q2' },
  {  module: '47M257', block: '8Q3' },
  {  module: '47M257', block: '8Q9' },
  {  module: '47M257', block: '8R1' },
  {  module: '47M257', block: '8R2' },
  {  module: '47M257', block: '8R3' },
  {  module: '47M257', block: '8S0' },
  {  module: '47M257', block: '8S1' },
  {  module: '47M257', block: '8S2' },
  {  module: '47M257', block: '8S4' },
  {  module: '47M257', block: '8S6' },
  {  module: '47M257', block: '8S7' },
  {  module: '47M257', block: '8S8' },
  {  module: '47M257', block: '8S9' },
  {  module: '47M257', block: '8T1' },
  {  module: '47M257', block: '8U1' },
  {  module: '47M257', block: '8U2' },
  {  module: '47M257', block: 'A00' },
  {  module: '47M257', block: 'B00' },
  {  module: '47M257', block: 'M00' },
  {  module: '47M258', block: '9M1' },
  {  module: '47M258', block: '9M2' },
  {  module: '47M258', block: '9M3' },
  {  module: '47M258', block: '9P1' },
  {  module: '47M258', block: '9P2' },
  {  module: '47M258', block: '9Q1' },
  {  module: '47M258', block: 'A00' },
  {  module: '47M258', block: 'M00' },
  {  module: '47M261', block: '7A1' },
  {  module: '47M261', block: '7A2' },
  {  module: '47M261', block: '7A3' },
  {  module: '47M261', block: '7AA' },
  {  module: '47M261', block: '7B1' },
  {  module: '47M261', block: '7B2' },
  {  module: '47M261', block: '7B3' },
  {  module: '47M261', block: '7B6' },
  {  module: '47M261', block: '7C1' },
  {  module: '47M261', block: '7C2' },
  {  module: '47M261', block: '7C3' },
  {  module: '47M261', block: '7C4' },
  {  module: '47M261', block: '7C5' },
  {  module: '47M261', block: '7D1' },
  {  module: '47M261', block: '7D2' },
  {  module: '47M261', block: '7D3' },
  {  module: '47M261', block: '7F1' },
  {  module: '47M261', block: '7F2' },
  {  module: '47M261', block: '7F3' },
  {  module: '47M261', block: '7F9' },
  {  module: '47M261', block: '7FB' },
  {  module: '47M261', block: '7G6' },
  {  module: '47M261', block: '7H1' },
  {  module: '47M261', block: '7H2' },
  {  module: '47M261', block: '7H3' },
  {  module: '47M261', block: '7H9' },
  {  module: '47M261', block: '7J0' },
  {  module: '47M261', block: '7J1' },
  {  module: '47M261', block: '7J2' },
  {  module: '47M261', block: '7J3' },
  {  module: '47M261', block: '7K0' },
  {  module: '47M261', block: '7K1' },
  {  module: '47M261', block: '7K2' },
  {  module: '47M261', block: '7K3' },
  {  module: '47M261', block: '7K4' },
  {  module: '47M261', block: '7K5' },
  {  module: '47M261', block: '7K6' },
  {  module: '47M261', block: '7K7' },
  {  module: '47M261', block: '7K8' },
  {  module: '47M261', block: '7K9' },
  {  module: '47M261', block: '7KB' },
  {  module: '47M261', block: 'A00' },
  {  module: '47M261', block: 'B00' },
  {  module: '47M261', block: 'C00' },
  {  module: '47M261', block: 'M00' },
  {  module: '47M262', block: '8A1' },
  {  module: '47M262', block: '8A2' },
  {  module: '47M262', block: '8A3' },
  {  module: '47M262', block: '8AA' },
  {  module: '47M262', block: '8B0' },
  {  module: '47M262', block: '8B1' },
  {  module: '47M262', block: '8B2' },
  {  module: '47M262', block: '8B3' },
  {  module: '47M262', block: '8B5' },
  {  module: '47M262', block: '8B6' },
  {  module: '47M262', block: '8B8' },
  {  module: '47M262', block: '8B9' },
  {  module: '47M262', block: '8C3' },
  {  module: '47M262', block: '8C4' },
  {  module: '47M262', block: '8C5' },
  {  module: '47M262', block: '8C9' },
  {  module: '47M262', block: '8D1' },
  {  module: '47M262', block: '8D2' },
  {  module: '47M262', block: '8D3' },
  {  module: '47M262', block: '8D9' },
  {  module: '47M262', block: '8DB' },
  {  module: '47M262', block: '8F1' },
  {  module: '47M262', block: '8G1' },
  {  module: '47M262', block: '8G2' },
  {  module: '47M262', block: '8G3' },
  {  module: '47M262', block: '8G9' },
  {  module: '47M262', block: '8H1' },
  {  module: '47M262', block: '8H2' },
  {  module: '47M262', block: '8H3' },
  {  module: '47M262', block: '8J1' },
  {  module: '47M262', block: '8J2' },
  {  module: '47M262', block: '8J3' },
  {  module: '47M262', block: '8J4' },
  {  module: '47M262', block: '8J5' },
  {  module: '47M262', block: '8J7' },
  {  module: '47M262', block: '8J8' },
  {  module: '47M262', block: '8J9' },
  {  module: '47M262', block: '8JB' },
  {  module: '47M262', block: '8K1' },
  {  module: '47M262', block: '8K2' },
  {  module: '47M262', block: '8K3' },
  {  module: '47M262', block: '8K4' },
  {  module: '47M262', block: 'A00' },
  {  module: '47M262', block: 'B00' },
  {  module: '47M262', block: 'C00' },
  {  module: '47M262', block: 'M00' },
  {  module: '47M263', block: '9A1' },
  {  module: '47M263', block: '9A2' },
  {  module: '47M263', block: '9A3' },
  {  module: '47M263', block: '9B1' },
  {  module: '47M263', block: '9B2' },
  {  module: '47M263', block: '9B3' },
  {  module: '47M263', block: '9C1' },
  {  module: '47M263', block: '9C2' },
  {  module: '47M263', block: '9C3' },
  {  module: '47M263', block: '9D1' },
  {  module: '47M263', block: '9D2' },
  {  module: '47M263', block: '9D3' },
  {  module: '47M263', block: '9F1' },
  {  module: '47M263', block: '9F2' },
  {  module: '47M263', block: '9F3' },
  {  module: '47M263', block: '9G1' },
  {  module: '47M263', block: '9G2' },
  {  module: '47M263', block: '9G4' },
  {  module: '47M263', block: 'A00' },
  {  module: '47M263', block: 'B00' },
  {  module: '47M263', block: 'M00' },
  {  module: '47M265', block: '211' },
  {  module: '47M265', block: '212' },
  {  module: '47M265', block: '221' },
  {  module: '47M265', block: '222' },
  {  module: '47M265', block: '223' },
  {  module: '47M265', block: '224' },
  {  module: '47M265', block: '225' },
  {  module: '47M265', block: '226' },
  {  module: '47M265', block: '227' },
  {  module: '47M265', block: '228' },
  {  module: '47M265', block: '229' },
  {  module: '47M265', block: 'A00' },
  {  module: '47M265', block: 'M00' },
  {  module: '47M266', block: '311' },
  {  module: '47M266', block: '312' },
  {  module: '47M266', block: '321' },
  {  module: '47M266', block: '322' },
  {  module: '47M266', block: '323' },
  {  module: '47M266', block: '324' },
  {  module: '47M266', block: '325' },
  {  module: '47M266', block: '326' },
  {  module: '47M266', block: '327' },
  {  module: '47M266', block: '328' },
  {  module: '47M266', block: '329' },
  {  module: '47M266', block: 'A00' },
  {  module: '47M266', block: 'M00' },
  {  module: '47M267', block: '411' },
  {  module: '47M267', block: '412' },
  {  module: '47M267', block: '413' },
  {  module: '47M267', block: '41A' },
  {  module: '47M267', block: '421' },
  {  module: '47M267', block: '422' },
  {  module: '47M267', block: '423' },
  {  module: '47M267', block: '424' },
  {  module: '47M267', block: '431' },
  {  module: '47M267', block: '432' },
  {  module: '47M267', block: '433' },
  {  module: '47M267', block: '439' },
  {  module: '47M267', block: '441' },
  {  module: '47M267', block: '442' },
  {  module: '47M267', block: '443' },
  {  module: '47M267', block: '450' },
  {  module: '47M267', block: '451' },
  {  module: '47M267', block: '452' },
  {  module: '47M267', block: '454' },
  {  module: '47M267', block: '456' },
  {  module: '47M267', block: '457' },
  {  module: '47M267', block: '458' },
  {  module: '47M267', block: '459' },
  {  module: '47M267', block: '461' },
  {  module: '47M267', block: '471' },
  {  module: '47M267', block: '472' },
  {  module: '47M267', block: 'A00' },
  {  module: '47M267', block: 'B00' },
  {  module: '47M267', block: 'M00' },
  {  module: '47M268', block: '111' },
  {  module: '47M268', block: '112' },
  {  module: '47M268', block: '113' },
  {  module: '47M268', block: '11A' },
  {  module: '47M268', block: '121' },
  {  module: '47M268', block: '122' },
  {  module: '47M268', block: '131' },
  {  module: '47M268', block: 'A00' },
  {  module: '47M268', block: 'M00' },
  {  module: '51M433', block: '301' },
  {  module: '51M433', block: '311' },
  {  module: '51M433', block: '312' },
  {  module: '51M433', block: '321' },
  {  module: '51M433', block: '322' },
  {  module: '51M433', block: '331' },
  {  module: '51M433', block: '6Y1' },
  {  module: '51M433', block: 'A00' },
  {  module: '51M433', block: 'M00' },
  {  module: '58K613', block: '1A1' },
  {  module: '58K613', block: '1A2' },
  {  module: '58K613', block: '1A3' },
  {  module: '58K613', block: '1B1' },
  {  module: '58K613', block: '1B2' },
  {  module: '58K613', block: '1B3' },
  {  module: '58K613', block: '1B4' },
  {  module: '58K613', block: 'A00' },
  {  module: '58K613', block: 'M00' },
  {  module: '58M615', block: '3A1' },
  {  module: '58M615', block: '3A2' },
  {  module: '58M615', block: '3B1' },
  {  module: '58M615', block: '3B2' },
  {  module: '58M615', block: '3C1' },
  {  module: '58M615', block: 'A00' },
  {  module: '58M615', block: 'M00' }
]

var discDesc = [
  { value: 'Architectural', text: 'Architectural' },
  { value: 'E&I', text: 'E&I' },
  { value: 'HVAC', text: 'HVAC' },
  { value: 'Insulation', text: 'Insulation' },
  { value: 'Mechanical', text: 'Mechanical' },
  { value: 'Others', text: 'Others' },
  { value: 'Painting & PFP', text: 'Painting & PFP' },
  { value: 'Piping', text: 'Piping' },
  { value: 'Structural', text: 'Structural' },
]
var module = [
  { value: '41M001', text: '41M001' },
  { value: '41M003', text: '41M003' },
  { value: '41M004', text: '41M004' },
  { value: '41M006', text: '41M006' },
  { value: '41M007', text: '41M007' },
  { value: '41M008', text: '41M008' },
  { value: '41M009', text: '41M009' },
  { value: '41M010', text: '41M010' },
  { value: '41M011', text: '41M011' },
  { value: '41M013', text: '41M013' },
  { value: '41M014', text: '41M014' },
  { value: '41M015', text: '41M015' },
  { value: '41M016', text: '41M016' },
  { value: '41M018', text: '41M018' },
  { value: '41M801', text: '41M801' },
  { value: '42K055', text: '42K055' },
  { value: '42K056', text: '42K056' },
  { value: '42M051', text: '42M051' },
  { value: '42M052', text: '42M052' },
  { value: '42M053', text: '42M053' },
  { value: '42M054', text: '42M054' },
  { value: '42M055', text: '42M055' },
  { value: '42M056', text: '42M056' },
  { value: '42M057', text: '42M057' },
  { value: '42M058', text: '42M058' },
  { value: '42M059', text: '42M059' },
  { value: '42M060', text: '42M060' },
  { value: '42M063', text: '42M063' },
  { value: '42M065', text: '42M065' },
  { value: '42M067', text: '42M067' },
  { value: '42M068', text: '42M068' },
  { value: '42M069', text: '42M069' },
  { value: '42M070', text: '42M070' },
  { value: '42M071', text: '42M071' },
  { value: '42M072', text: '42M072' },
  { value: '42M073', text: '42M073' },
  { value: '42M074', text: '42M074' },
  { value: '42M075', text: '42M075' },
  { value: '42M078', text: '42M078' },
  { value: '42M081', text: '42M081' },
  { value: '42M086', text: '42M086' },
  { value: '42M091', text: '42M091' },
  { value: '42M092', text: '42M092' },
  { value: '42M802', text: '42M802' },
  { value: '43K101', text: '43K101' },
  { value: '43K102', text: '43K102' },
  { value: '43M101', text: '43M101' },
  { value: '43M102', text: '43M102' },
  { value: '43M103', text: '43M103' },
  { value: '43M104', text: '43M104' },
  { value: '43M105', text: '43M105' },
  { value: '43M106', text: '43M106' },
  { value: '43M108', text: '43M108' },
  { value: '43M109', text: '43M109' },
  { value: '43M112', text: '43M112' },
  { value: '43M113', text: '43M113' },
  { value: '43M114', text: '43M114' },
  { value: '43M115', text: '43M115' },
  { value: '44K156', text: '44K156' },
  { value: '44M151', text: '44M151' },
  { value: '45M201', text: '45M201' },
  { value: '45M202', text: '45M202' },
  { value: '45M206', text: '45M206' },
  { value: '45M208', text: '45M208' },
  { value: '47M251', text: '47M251' },
  { value: '47M252', text: '47M252' },
  { value: '47M253', text: '47M253' },
  { value: '47M255', text: '47M255' },
  { value: '47M256', text: '47M256' },
  { value: '47M257', text: '47M257' },
  { value: '47M258', text: '47M258' },
  { value: '47M261', text: '47M261' },
  { value: '47M262', text: '47M262' },
  { value: '47M263', text: '47M263' },
  { value: '47M265', text: '47M265' },
  { value: '47M266', text: '47M266' },
  { value: '47M267', text: '47M267' },
  { value: '47M268', text: '47M268' },
  { value: '51M433', text: '51M433' },
  { value: '58K613', text: '58K613' },
  { value: '58M615', text: '58M615' },
]
var block = [
  { value: '101', text: '101' },
  { value: '102', text: '102' },
  { value: '103', text: '103' },
  { value: '111', text: '111' },
  { value: '112', text: '112' },
  { value: '113', text: '113' },
  { value: '114', text: '114' },
  { value: '11A', text: '11A' },
  { value: '121', text: '121' },
  { value: '122', text: '122' },
  { value: '131', text: '131' },
  { value: '132', text: '132' },
  { value: '133', text: '133' },
  { value: '141', text: '141' },
  { value: '142', text: '142' },
  { value: '143', text: '143' },
  { value: '151', text: '151' },
  { value: '152', text: '152' },
  { value: '153', text: '153' },
  { value: '154', text: '154' },
  { value: '157', text: '157' },
  { value: '161', text: '161' },
  { value: '162', text: '162' },
  { value: '1A1', text: '1A1' },
  { value: '1A2', text: '1A2' },
  { value: '1A3', text: '1A3' },
  { value: '1A4', text: '1A4' },
  { value: '1A5', text: '1A5' },
  { value: '1AA', text: '1AA' },
  { value: '1B1', text: '1B1' },
  { value: '1B2', text: '1B2' },
  { value: '1B3', text: '1B3' },
  { value: '1B4', text: '1B4' },
  { value: '1B5', text: '1B5' },
  { value: '1B6', text: '1B6' },
  { value: '1B7', text: '1B7' },
  { value: '1B8', text: '1B8' },
  { value: '1B9', text: '1B9' },
  { value: '1C1', text: '1C1' },
  { value: '1C2', text: '1C2' },
  { value: '1C3', text: '1C3' },
  { value: '1C4', text: '1C4' },
  { value: '1C5', text: '1C5' },
  { value: '1C9', text: '1C9' },
  { value: '1D1', text: '1D1' },
  { value: '1D2', text: '1D2' },
  { value: '1D3', text: '1D3' },
  { value: '1D9', text: '1D9' },
  { value: '1DA', text: '1DA' },
  { value: '1F1', text: '1F1' },
  { value: '1F2', text: '1F2' },
  { value: '1F3', text: '1F3' },
  { value: '1F4', text: '1F4' },
  { value: '1F5', text: '1F5' },
  { value: '1F6', text: '1F6' },
  { value: '1F7', text: '1F7' },
  { value: '1F8', text: '1F8' },
  { value: '1F9', text: '1F9' },
  { value: '1FA', text: '1FA' },
  { value: '1G1', text: '1G1' },
  { value: '1G2', text: '1G2' },
  { value: '1G3', text: '1G3' },
  { value: '1G4', text: '1G4' },
  { value: '1G5', text: '1G5' },
  { value: '1G9', text: '1G9' },
  { value: '1H1', text: '1H1' },
  { value: '1H2', text: '1H2' },
  { value: '1H3', text: '1H3' },
  { value: '1HA', text: '1HA' },
  { value: '1J1', text: '1J1' },
  { value: '1J2', text: '1J2' },
  { value: '1J3', text: '1J3' },
  { value: '1J4', text: '1J4' },
  { value: '1J5', text: '1J5' },
  { value: '1J6', text: '1J6' },
  { value: '1K1', text: '1K1' },
  { value: '1K2', text: '1K2' },
  { value: '1K3', text: '1K3' },
  { value: '1K4', text: '1K4' },
  { value: '1K5', text: '1K5' },
  { value: '1K6', text: '1K6' },
  { value: '1K7', text: '1K7' },
  { value: '1K8', text: '1K8' },
  { value: '1K9', text: '1K9' },
  { value: '1M1', text: '1M1' },
  { value: '1M2', text: '1M2' },
  { value: '1M3', text: '1M3' },
  { value: '1P1', text: '1P1' },
  { value: '1P2', text: '1P2' },
  { value: '1P3', text: '1P3' },
  { value: '1P4', text: '1P4' },
  { value: '1P5', text: '1P5' },
  { value: '1Q1', text: '1Q1' },
  { value: '1Q2', text: '1Q2' },
  { value: '1Q3', text: '1Q3' },
  { value: '1Q4', text: '1Q4' },
  { value: '1Q7', text: '1Q7' },
  { value: '1Q9', text: '1Q9' },
  { value: '1R1', text: '1R1' },
  { value: '1R2', text: '1R2' },
  { value: '1R3', text: '1R3' },
  { value: '1R4', text: '1R4' },
  { value: '1S1', text: '1S1' },
  { value: '1S2', text: '1S2' },
  { value: '1S3', text: '1S3' },
  { value: '1SA', text: '1SA' },
  { value: '1SB', text: '1SB' },
  { value: '1SC', text: '1SC' },
  { value: '1T0', text: '1T0' },
  { value: '1T1', text: '1T1' },
  { value: '1T2', text: '1T2' },
  { value: '1T3', text: '1T3' },
  { value: '1T4', text: '1T4' },
  { value: '1T5', text: '1T5' },
  { value: '1T7', text: '1T7' },
  { value: '1T8', text: '1T8' },
  { value: '1T9', text: '1T9' },
  { value: '1TA', text: '1TA' },
  { value: '1TB', text: '1TB' },
  { value: '1U1', text: '1U1' },
  { value: '1U2', text: '1U2' },
  { value: '1U3', text: '1U3' },
  { value: '1UA', text: '1UA' },
  { value: '1UB', text: '1UB' },
  { value: '1UC', text: '1UC' },
  { value: '1V0', text: '1V0' },
  { value: '1V1', text: '1V1' },
  { value: '1V2', text: '1V2' },
  { value: '1V3', text: '1V3' },
  { value: '1V4', text: '1V4' },
  { value: '1V5', text: '1V5' },
  { value: '1V6', text: '1V6' },
  { value: '1V7', text: '1V7' },
  { value: '1V8', text: '1V8' },
  { value: '1V9', text: '1V9' },
  { value: '1W1', text: '1W1' },
  { value: '1W2', text: '1W2' },
  { value: '1W3', text: '1W3' },
  { value: '1W4', text: '1W4' },
  { value: '1W5', text: '1W5' },
  { value: '1W6', text: '1W6' },
  { value: '1W7', text: '1W7' },
  { value: '1W8', text: '1W8' },
  { value: '1WA', text: '1WA' },
  { value: '1WB', text: '1WB' },
  { value: '201', text: '201' },
  { value: '202', text: '202' },
  { value: '203', text: '203' },
  { value: '211', text: '211' },
  { value: '212', text: '212' },
  { value: '221', text: '221' },
  { value: '222', text: '222' },
  { value: '223', text: '223' },
  { value: '224', text: '224' },
  { value: '225', text: '225' },
  { value: '226', text: '226' },
  { value: '227', text: '227' },
  { value: '228', text: '228' },
  { value: '229', text: '229' },
  { value: '231', text: '231' },
  { value: '232', text: '232' },
  { value: '233', text: '233' },
  { value: '23A', text: '23A' },
  { value: '241', text: '241' },
  { value: '242', text: '242' },
  { value: '251', text: '251' },
  { value: '252', text: '252' },
  { value: '253', text: '253' },
  { value: '261', text: '261' },
  { value: '262', text: '262' },
  { value: '263', text: '263' },
  { value: '271', text: '271' },
  { value: '272', text: '272' },
  { value: '273', text: '273' },
  { value: '274', text: '274' },
  { value: '275', text: '275' },
  { value: '276', text: '276' },
  { value: '281', text: '281' },
  { value: '282', text: '282' },
  { value: '283', text: '283' },
  { value: '284', text: '284' },
  { value: '2A1', text: '2A1' },
  { value: '2A2', text: '2A2' },
  { value: '2A3', text: '2A3' },
  { value: '2A4', text: '2A4' },
  { value: '2A5', text: '2A5' },
  { value: '2A6', text: '2A6' },
  { value: '2B1', text: '2B1' },
  { value: '2B2', text: '2B2' },
  { value: '2B3', text: '2B3' },
  { value: '2B4', text: '2B4' },
  { value: '2B5', text: '2B5' },
  { value: '2B6', text: '2B6' },
  { value: '2B7', text: '2B7' },
  { value: '2B8', text: '2B8' },
  { value: '2B9', text: '2B9' },
  { value: '2C1', text: '2C1' },
  { value: '2C2', text: '2C2' },
  { value: '2C3', text: '2C3' },
  { value: '2C4', text: '2C4' },
  { value: '2C5', text: '2C5' },
  { value: '2C8', text: '2C8' },
  { value: '2C9', text: '2C9' },
  { value: '2D1', text: '2D1' },
  { value: '2D2', text: '2D2' },
  { value: '2D3', text: '2D3' },
  { value: '2D9', text: '2D9' },
  { value: '2F1', text: '2F1' },
  { value: '2F2', text: '2F2' },
  { value: '2F3', text: '2F3' },
  { value: '2F4', text: '2F4' },
  { value: '2F5', text: '2F5' },
  { value: '2F6', text: '2F6' },
  { value: '2F7', text: '2F7' },
  { value: '2G1', text: '2G1' },
  { value: '2G2', text: '2G2' },
  { value: '2G3', text: '2G3' },
  { value: '2G9', text: '2G9' },
  { value: '2H1', text: '2H1' },
  { value: '2H2', text: '2H2' },
  { value: '2H3', text: '2H3' },
  { value: '2J1', text: '2J1' },
  { value: '2J2', text: '2J2' },
  { value: '2J3', text: '2J3' },
  { value: '2J4', text: '2J4' },
  { value: '2J5', text: '2J5' },
  { value: '2J6', text: '2J6' },
  { value: '2J7', text: '2J7' },
  { value: '2J8', text: '2J8' },
  { value: '2J9', text: '2J9' },
  { value: '2JA', text: '2JA' },
  { value: '2K1', text: '2K1' },
  { value: '2K2', text: '2K2' },
  { value: '2K3', text: '2K3' },
  { value: '2K4', text: '2K4' },
  { value: '2K5', text: '2K5' },
  { value: '2K6', text: '2K6' },
  { value: '2M1', text: '2M1' },
  { value: '2M2', text: '2M2' },
  { value: '2M3', text: '2M3' },
  { value: '2M4', text: '2M4' },
  { value: '2P0', text: '2P0' },
  { value: '2P1', text: '2P1' },
  { value: '2P2', text: '2P2' },
  { value: '2P3', text: '2P3' },
  { value: '2P4', text: '2P4' },
  { value: '2P5', text: '2P5' },
  { value: '2P6', text: '2P6' },
  { value: '2P7', text: '2P7' },
  { value: '2P8', text: '2P8' },
  { value: '2P9', text: '2P9' },
  { value: '2Q1', text: '2Q1' },
  { value: '2Q2', text: '2Q2' },
  { value: '2Q3', text: '2Q3' },
  { value: '2Q4', text: '2Q4' },
  { value: '2Q5', text: '2Q5' },
  { value: '2Q6', text: '2Q6' },
  { value: '2Q7', text: '2Q7' },
  { value: '2Q8', text: '2Q8' },
  { value: '2Q9', text: '2Q9' },
  { value: '2R1', text: '2R1' },
  { value: '2R2', text: '2R2' },
  { value: '2R3', text: '2R3' },
  { value: '2R4', text: '2R4' },
  { value: '2R6', text: '2R6' },
  { value: '2S1', text: '2S1' },
  { value: '2S2', text: '2S2' },
  { value: '2S3', text: '2S3' },
  { value: '2S9', text: '2S9' },
  { value: '2SA', text: '2SA' },
  { value: '2SB', text: '2SB' },
  { value: '2T1', text: '2T1' },
  { value: '2T2', text: '2T2' },
  { value: '2T3', text: '2T3' },
  { value: '2T4', text: '2T4' },
  { value: '2T5', text: '2T5' },
  { value: '2T6', text: '2T6' },
  { value: '2T7', text: '2T7' },
  { value: '2T9', text: '2T9' },
  { value: '2U1', text: '2U1' },
  { value: '2U2', text: '2U2' },
  { value: '2U3', text: '2U3' },
  { value: '2U4', text: '2U4' },
  { value: '2UA', text: '2UA' },
  { value: '2UB', text: '2UB' },
  { value: '2V0', text: '2V0' },
  { value: '2V1', text: '2V1' },
  { value: '2V2', text: '2V2' },
  { value: '2V3', text: '2V3' },
  { value: '2V4', text: '2V4' },
  { value: '2V5', text: '2V5' },
  { value: '2V6', text: '2V6' },
  { value: '2V7', text: '2V7' },
  { value: '2V8', text: '2V8' },
  { value: '2V9', text: '2V9' },
  { value: '2W0', text: '2W0' },
  { value: '2W1', text: '2W1' },
  { value: '2W2', text: '2W2' },
  { value: '2W3', text: '2W3' },
  { value: '2W4', text: '2W4' },
  { value: '2W5', text: '2W5' },
  { value: '2W6', text: '2W6' },
  { value: '2W7', text: '2W7' },
  { value: '2W8', text: '2W8' },
  { value: '301', text: '301' },
  { value: '311', text: '311' },
  { value: '312', text: '312' },
  { value: '321', text: '321' },
  { value: '322', text: '322' },
  { value: '323', text: '323' },
  { value: '324', text: '324' },
  { value: '325', text: '325' },
  { value: '326', text: '326' },
  { value: '327', text: '327' },
  { value: '328', text: '328' },
  { value: '329', text: '329' },
  { value: '331', text: '331' },
  { value: '332', text: '332' },
  { value: '341', text: '341' },
  { value: '351', text: '351' },
  { value: '361', text: '361' },
  { value: '3A1', text: '3A1' },
  { value: '3A2', text: '3A2' },
  { value: '3A3', text: '3A3' },
  { value: '3A4', text: '3A4' },
  { value: '3AA', text: '3AA' },
  { value: '3B1', text: '3B1' },
  { value: '3B2', text: '3B2' },
  { value: '3B3', text: '3B3' },
  { value: '3B4', text: '3B4' },
  { value: '3B5', text: '3B5' },
  { value: '3B6', text: '3B6' },
  { value: '3B7', text: '3B7' },
  { value: '3B8', text: '3B8' },
  { value: '3B9', text: '3B9' },
  { value: '3C1', text: '3C1' },
  { value: '3C2', text: '3C2' },
  { value: '3C3', text: '3C3' },
  { value: '3C4', text: '3C4' },
  { value: '3C5', text: '3C5' },
  { value: '3C9', text: '3C9' },
  { value: '3D1', text: '3D1' },
  { value: '3D2', text: '3D2' },
  { value: '3D3', text: '3D3' },
  { value: '3D9', text: '3D9' },
  { value: '3DA', text: '3DA' },
  { value: '3DB', text: '3DB' },
  { value: '3F1', text: '3F1' },
  { value: '3F2', text: '3F2' },
  { value: '3F3', text: '3F3' },
  { value: '3F4', text: '3F4' },
  { value: '3FB', text: '3FB' },
  { value: '3FC', text: '3FC' },
  { value: '3G1', text: '3G1' },
  { value: '3G2', text: '3G2' },
  { value: '3G3', text: '3G3' },
  { value: '3G4', text: '3G4' },
  { value: '3G5', text: '3G5' },
  { value: '3G9', text: '3G9' },
  { value: '3GA', text: '3GA' },
  { value: '3H1', text: '3H1' },
  { value: '3H2', text: '3H2' },
  { value: '3H3', text: '3H3' },
  { value: '3H4', text: '3H4' },
  { value: '3H5', text: '3H5' },
  { value: '3H6', text: '3H6' },
  { value: '3H7', text: '3H7' },
  { value: '3H8', text: '3H8' },
  { value: '3H9', text: '3H9' },
  { value: '3HA', text: '3HA' },
  { value: '3J1', text: '3J1' },
  { value: '3J2', text: '3J2' },
  { value: '3J3', text: '3J3' },
  { value: '3J4', text: '3J4' },
  { value: '3J5', text: '3J5' },
  { value: '3J6', text: '3J6' },
  { value: '3J7', text: '3J7' },
  { value: '3JA', text: '3JA' },
  { value: '3JB', text: '3JB' },
  { value: '3K1', text: '3K1' },
  { value: '3K2', text: '3K2' },
  { value: '3K3', text: '3K3' },
  { value: '3K4', text: '3K4' },
  { value: '3K5', text: '3K5' },
  { value: '3K6', text: '3K6' },
  { value: '3K7', text: '3K7' },
  { value: '3L1', text: '3L1' },
  { value: '3L3', text: '3L3' },
  { value: '3M1', text: '3M1' },
  { value: '3M2', text: '3M2' },
  { value: '3M3', text: '3M3' },
  { value: '3P1', text: '3P1' },
  { value: '3P2', text: '3P2' },
  { value: '3P3', text: '3P3' },
  { value: '3P4', text: '3P4' },
  { value: '3P5', text: '3P5' },
  { value: '3P6', text: '3P6' },
  { value: '3P8', text: '3P8' },
  { value: '3P9', text: '3P9' },
  { value: '3Q1', text: '3Q1' },
  { value: '3Q2', text: '3Q2' },
  { value: '3Q3', text: '3Q3' },
  { value: '3Q4', text: '3Q4' },
  { value: '3Q5', text: '3Q5' },
  { value: '3Q6', text: '3Q6' },
  { value: '3Q7', text: '3Q7' },
  { value: '3R1', text: '3R1' },
  { value: '3R2', text: '3R2' },
  { value: '3R3', text: '3R3' },
  { value: '3S1', text: '3S1' },
  { value: '3S2', text: '3S2' },
  { value: '3S3', text: '3S3' },
  { value: '3S4', text: '3S4' },
  { value: '3SA', text: '3SA' },
  { value: '3T1', text: '3T1' },
  { value: '3T2', text: '3T2' },
  { value: '3T3', text: '3T3' },
  { value: '3U1', text: '3U1' },
  { value: '3U2', text: '3U2' },
  { value: '3UA', text: '3UA' },
  { value: '3V1', text: '3V1' },
  { value: '3V2', text: '3V2' },
  { value: '3V3', text: '3V3' },
  { value: '3V4', text: '3V4' },
  { value: '3V5', text: '3V5' },
  { value: '3W1', text: '3W1' },
  { value: '3W2', text: '3W2' },
  { value: '3W3', text: '3W3' },
  { value: '401', text: '401' },
  { value: '402', text: '402' },
  { value: '403', text: '403' },
  { value: '411', text: '411' },
  { value: '412', text: '412' },
  { value: '413', text: '413' },
  { value: '41A', text: '41A' },
  { value: '421', text: '421' },
  { value: '422', text: '422' },
  { value: '423', text: '423' },
  { value: '424', text: '424' },
  { value: '425', text: '425' },
  { value: '426', text: '426' },
  { value: '427', text: '427' },
  { value: '428', text: '428' },
  { value: '431', text: '431' },
  { value: '432', text: '432' },
  { value: '433', text: '433' },
  { value: '434', text: '434' },
  { value: '435', text: '435' },
  { value: '439', text: '439' },
  { value: '441', text: '441' },
  { value: '442', text: '442' },
  { value: '443', text: '443' },
  { value: '444', text: '444' },
  { value: '445', text: '445' },
  { value: '446', text: '446' },
  { value: '447', text: '447' },
  { value: '44A', text: '44A' },
  { value: '44B', text: '44B' },
  { value: '450', text: '450' },
  { value: '451', text: '451' },
  { value: '452', text: '452' },
  { value: '453', text: '453' },
  { value: '454', text: '454' },
  { value: '455', text: '455' },
  { value: '456', text: '456' },
  { value: '457', text: '457' },
  { value: '458', text: '458' },
  { value: '459', text: '459' },
  { value: '461', text: '461' },
  { value: '471', text: '471' },
  { value: '472', text: '472' },
  { value: '4A1', text: '4A1' },
  { value: '4A2', text: '4A2' },
  { value: '4A3', text: '4A3' },
  { value: '4A4', text: '4A4' },
  { value: '4AA', text: '4AA' },
  { value: '4B1', text: '4B1' },
  { value: '4B2', text: '4B2' },
  { value: '4B3', text: '4B3' },
  { value: '4B4', text: '4B4' },
  { value: '4B5', text: '4B5' },
  { value: '4B6', text: '4B6' },
  { value: '4B7', text: '4B7' },
  { value: '4B8', text: '4B8' },
  { value: '4B9', text: '4B9' },
  { value: '4C1', text: '4C1' },
  { value: '4C2', text: '4C2' },
  { value: '4C3', text: '4C3' },
  { value: '4C4', text: '4C4' },
  { value: '4C5', text: '4C5' },
  { value: '4C9', text: '4C9' },
  { value: '4D1', text: '4D1' },
  { value: '4D2', text: '4D2' },
  { value: '4D3', text: '4D3' },
  { value: '4D9', text: '4D9' },
  { value: '4DA', text: '4DA' },
  { value: '4DB', text: '4DB' },
  { value: '4F1', text: '4F1' },
  { value: '4F2', text: '4F2' },
  { value: '4F3', text: '4F3' },
  { value: '4F4', text: '4F4' },
  { value: '4F9', text: '4F9' },
  { value: '4G1', text: '4G1' },
  { value: '4G2', text: '4G2' },
  { value: '4G3', text: '4G3' },
  { value: '4G4', text: '4G4' },
  { value: '4G6', text: '4G6' },
  { value: '4G9', text: '4G9' },
  { value: '4H1', text: '4H1' },
  { value: '4H2', text: '4H2' },
  { value: '4H3', text: '4H3' },
  { value: '4H4', text: '4H4' },
  { value: '4H5', text: '4H5' },
  { value: '4H6', text: '4H6' },
  { value: '4H7', text: '4H7' },
  { value: '4H8', text: '4H8' },
  { value: '4H9', text: '4H9' },
  { value: '4HA', text: '4HA' },
  { value: '4J0', text: '4J0' },
  { value: '4J1', text: '4J1' },
  { value: '4J2', text: '4J2' },
  { value: '4J3', text: '4J3' },
  { value: '4J4', text: '4J4' },
  { value: '4J5', text: '4J5' },
  { value: '4J6', text: '4J6' },
  { value: '4J7', text: '4J7' },
  { value: '4K0', text: '4K0' },
  { value: '4K1', text: '4K1' },
  { value: '4K2', text: '4K2' },
  { value: '4K3', text: '4K3' },
  { value: '4K4', text: '4K4' },
  { value: '4K5', text: '4K5' },
  { value: '4K6', text: '4K6' },
  { value: '4K7', text: '4K7' },
  { value: '4K8', text: '4K8' },
  { value: '4K9', text: '4K9' },
  { value: '4M1', text: '4M1' },
  { value: '4M2', text: '4M2' },
  { value: '4M3', text: '4M3' },
  { value: '4M4', text: '4M4' },
  { value: '4P0', text: '4P0' },
  { value: '4P1', text: '4P1' },
  { value: '4P2', text: '4P2' },
  { value: '4P3', text: '4P3' },
  { value: '4P4', text: '4P4' },
  { value: '4P5', text: '4P5' },
  { value: '4P6', text: '4P6' },
  { value: '4P7', text: '4P7' },
  { value: '4P8', text: '4P8' },
  { value: '4P9', text: '4P9' },
  { value: '4Q1', text: '4Q1' },
  { value: '4Q2', text: '4Q2' },
  { value: '4Q3', text: '4Q3' },
  { value: '4Q4', text: '4Q4' },
  { value: '4Q5', text: '4Q5' },
  { value: '4R1', text: '4R1' },
  { value: '4R2', text: '4R2' },
  { value: '4R3', text: '4R3' },
  { value: '4S1', text: '4S1' },
  { value: '4S2', text: '4S2' },
  { value: '4S3', text: '4S3' },
  { value: '4T1', text: '4T1' },
  { value: '4T2', text: '4T2' },
  { value: '4T3', text: '4T3' },
  { value: '4T4', text: '4T4' },
  { value: '4T5', text: '4T5' },
  { value: '4TA', text: '4TA' },
  { value: '4TB', text: '4TB' },
  { value: '4U1', text: '4U1' },
  { value: '4U2', text: '4U2' },
  { value: '4U3', text: '4U3' },
  { value: '4UA', text: '4UA' },
  { value: '4V1', text: '4V1' },
  { value: '4V2', text: '4V2' },
  { value: '4W1', text: '4W1' },
  { value: '501', text: '501' },
  { value: '502', text: '502' },
  { value: '503', text: '503' },
  { value: '504', text: '504' },
  { value: '511', text: '511' },
  { value: '512', text: '512' },
  { value: '513', text: '513' },
  { value: '521', text: '521' },
  { value: '522', text: '522' },
  { value: '523', text: '523' },
  { value: '524', text: '524' },
  { value: '525', text: '525' },
  { value: '526', text: '526' },
  { value: '527', text: '527' },
  { value: '531', text: '531' },
  { value: '532', text: '532' },
  { value: '533', text: '533' },
  { value: '534', text: '534' },
  { value: '535', text: '535' },
  { value: '536', text: '536' },
  { value: '53A', text: '53A' },
  { value: '541', text: '541' },
  { value: '542', text: '542' },
  { value: '543', text: '543' },
  { value: '544', text: '544' },
  { value: '545', text: '545' },
  { value: '546', text: '546' },
  { value: '551', text: '551' },
  { value: '552', text: '552' },
  { value: '553', text: '553' },
  { value: '554', text: '554' },
  { value: '55A', text: '55A' },
  { value: '561', text: '561' },
  { value: '562', text: '562' },
  { value: '5A1', text: '5A1' },
  { value: '5A2', text: '5A2' },
  { value: '5A3', text: '5A3' },
  { value: '5A4', text: '5A4' },
  { value: '5AA', text: '5AA' },
  { value: '5AB', text: '5AB' },
  { value: '5B0', text: '5B0' },
  { value: '5B1', text: '5B1' },
  { value: '5B2', text: '5B2' },
  { value: '5B3', text: '5B3' },
  { value: '5B4', text: '5B4' },
  { value: '5B5', text: '5B5' },
  { value: '5B6', text: '5B6' },
  { value: '5B7', text: '5B7' },
  { value: '5B8', text: '5B8' },
  { value: '5B9', text: '5B9' },
  { value: '5C1', text: '5C1' },
  { value: '5C2', text: '5C2' },
  { value: '5C3', text: '5C3' },
  { value: '5C4', text: '5C4' },
  { value: '5C5', text: '5C5' },
  { value: '5C9', text: '5C9' },
  { value: '5D1', text: '5D1' },
  { value: '5D2', text: '5D2' },
  { value: '5D3', text: '5D3' },
  { value: '5D4', text: '5D4' },
  { value: '5D9', text: '5D9' },
  { value: '5DA', text: '5DA' },
  { value: '5DB', text: '5DB' },
  { value: '5F1', text: '5F1' },
  { value: '5F2', text: '5F2' },
  { value: '5F3', text: '5F3' },
  { value: '5F4', text: '5F4' },
  { value: '5G1', text: '5G1' },
  { value: '5G2', text: '5G2' },
  { value: '5G3', text: '5G3' },
  { value: '5G4', text: '5G4' },
  { value: '5G9', text: '5G9' },
  { value: '5H1', text: '5H1' },
  { value: '5H2', text: '5H2' },
  { value: '5H3', text: '5H3' },
  { value: '5HA', text: '5HA' },
  { value: '5J1', text: '5J1' },
  { value: '5J2', text: '5J2' },
  { value: '5J3', text: '5J3' },
  { value: '5J4', text: '5J4' },
  { value: '5J5', text: '5J5' },
  { value: '5J6', text: '5J6' },
  { value: '5J7', text: '5J7' },
  { value: '5J8', text: '5J8' },
  { value: '5J9', text: '5J9' },
  { value: '5JA', text: '5JA' },
  { value: '5K1', text: '5K1' },
  { value: '5K2', text: '5K2' },
  { value: '5K3', text: '5K3' },
  { value: '5K4', text: '5K4' },
  { value: '5K5', text: '5K5' },
  { value: '5M1', text: '5M1' },
  { value: '5M2', text: '5M2' },
  { value: '5M3', text: '5M3' },
  { value: '5M4', text: '5M4' },
  { value: '5MA', text: '5MA' },
  { value: '5P1', text: '5P1' },
  { value: '5P2', text: '5P2' },
  { value: '5P3', text: '5P3' },
  { value: '5P4', text: '5P4' },
  { value: '5P5', text: '5P5' },
  { value: '5P6', text: '5P6' },
  { value: '5P7', text: '5P7' },
  { value: '5Q1', text: '5Q1' },
  { value: '5Q2', text: '5Q2' },
  { value: '5Q3', text: '5Q3' },
  { value: '5Q4', text: '5Q4' },
  { value: '5Q5', text: '5Q5' },
  { value: '5Q9', text: '5Q9' },
  { value: '5R1', text: '5R1' },
  { value: '5R2', text: '5R2' },
  { value: '5R3', text: '5R3' },
  { value: '5RA', text: '5RA' },
  { value: '5RB', text: '5RB' },
  { value: '5RC', text: '5RC' },
  { value: '5S1', text: '5S1' },
  { value: '5S2', text: '5S2' },
  { value: '5S3', text: '5S3' },
  { value: '5S4', text: '5S4' },
  { value: '5T1', text: '5T1' },
  { value: '5T2', text: '5T2' },
  { value: '5U1', text: '5U1' },
  { value: '5U2', text: '5U2' },
  { value: '5U3', text: '5U3' },
  { value: '5UA', text: '5UA' },
  { value: '601', text: '601' },
  { value: '602', text: '602' },
  { value: '603', text: '603' },
  { value: '604', text: '604' },
  { value: '611', text: '611' },
  { value: '612', text: '612' },
  { value: '613', text: '613' },
  { value: '621', text: '621' },
  { value: '622', text: '622' },
  { value: '623', text: '623' },
  { value: '624', text: '624' },
  { value: '625', text: '625' },
  { value: '62B', text: '62B' },
  { value: '631', text: '631' },
  { value: '632', text: '632' },
  { value: '633', text: '633' },
  { value: '63A', text: '63A' },
  { value: '641', text: '641' },
  { value: '642', text: '642' },
  { value: '643', text: '643' },
  { value: '644', text: '644' },
  { value: '645', text: '645' },
  { value: '646', text: '646' },
  { value: '64A', text: '64A' },
  { value: '64B', text: '64B' },
  { value: '651', text: '651' },
  { value: '652', text: '652' },
  { value: '653', text: '653' },
  { value: '654', text: '654' },
  { value: '655', text: '655' },
  { value: '656', text: '656' },
  { value: '657', text: '657' },
  { value: '65A', text: '65A' },
  { value: '661', text: '661' },
  { value: '662', text: '662' },
  { value: '663', text: '663' },
  { value: '671', text: '671' },
  { value: '672', text: '672' },
  { value: '673', text: '673' },
  { value: '674', text: '674' },
  { value: '675', text: '675' },
  { value: '676', text: '676' },
  { value: '677', text: '677' },
  { value: '678', text: '678' },
  { value: '67A', text: '67A' },
  { value: '681', text: '681' },
  { value: '682', text: '682' },
  { value: '683', text: '683' },
  { value: '684', text: '684' },
  { value: '685', text: '685' },
  { value: '691', text: '691' },
  { value: '692', text: '692' },
  { value: '69A', text: '69A' },
  { value: '6A1', text: '6A1' },
  { value: '6A2', text: '6A2' },
  { value: '6A3', text: '6A3' },
  { value: '6A4', text: '6A4' },
  { value: '6AA', text: '6AA' },
  { value: '6B0', text: '6B0' },
  { value: '6B1', text: '6B1' },
  { value: '6B2', text: '6B2' },
  { value: '6B3', text: '6B3' },
  { value: '6B4', text: '6B4' },
  { value: '6B5', text: '6B5' },
  { value: '6B6', text: '6B6' },
  { value: '6B7', text: '6B7' },
  { value: '6B8', text: '6B8' },
  { value: '6B9', text: '6B9' },
  { value: '6C1', text: '6C1' },
  { value: '6C2', text: '6C2' },
  { value: '6C3', text: '6C3' },
  { value: '6C4', text: '6C4' },
  { value: '6C5', text: '6C5' },
  { value: '6C9', text: '6C9' },
  { value: '6D1', text: '6D1' },
  { value: '6D2', text: '6D2' },
  { value: '6D3', text: '6D3' },
  { value: '6D4', text: '6D4' },
  { value: '6D9', text: '6D9' },
  { value: '6DA', text: '6DA' },
  { value: '6DB', text: '6DB' },
  { value: '6F1', text: '6F1' },
  { value: '6F2', text: '6F2' },
  { value: '6F3', text: '6F3' },
  { value: '6F4', text: '6F4' },
  { value: '6G1', text: '6G1' },
  { value: '6G2', text: '6G2' },
  { value: '6G3', text: '6G3' },
  { value: '6G4', text: '6G4' },
  { value: '6G9', text: '6G9' },
  { value: '6H1', text: '6H1' },
  { value: '6H2', text: '6H2' },
  { value: '6H3', text: '6H3' },
  { value: '6HA', text: '6HA' },
  { value: '6J1', text: '6J1' },
  { value: '6J2', text: '6J2' },
  { value: '6J3', text: '6J3' },
  { value: '6J4', text: '6J4' },
  { value: '6J5', text: '6J5' },
  { value: '6J6', text: '6J6' },
  { value: '6J7', text: '6J7' },
  { value: '6J8', text: '6J8' },
  { value: '6J9', text: '6J9' },
  { value: '6JA', text: '6JA' },
  { value: '6JB', text: '6JB' },
  { value: '6K1', text: '6K1' },
  { value: '6K2', text: '6K2' },
  { value: '6K3', text: '6K3' },
  { value: '6K4', text: '6K4' },
  { value: '6K5', text: '6K5' },
  { value: '6M1', text: '6M1' },
  { value: '6M2', text: '6M2' },
  { value: '6M3', text: '6M3' },
  { value: '6M4', text: '6M4' },
  { value: '6MA', text: '6MA' },
  { value: '6P1', text: '6P1' },
  { value: '6P2', text: '6P2' },
  { value: '6P3', text: '6P3' },
  { value: '6P4', text: '6P4' },
  { value: '6P5', text: '6P5' },
  { value: '6P6', text: '6P6' },
  { value: '6P7', text: '6P7' },
  { value: '6P8', text: '6P8' },
  { value: '6P9', text: '6P9' },
  { value: '6Q1', text: '6Q1' },
  { value: '6Q2', text: '6Q2' },
  { value: '6Q3', text: '6Q3' },
  { value: '6QA', text: '6QA' },
  { value: '6R1', text: '6R1' },
  { value: '6R2', text: '6R2' },
  { value: '6R3', text: '6R3' },
  { value: '6S1', text: '6S1' },
  { value: '6S2', text: '6S2' },
  { value: '6S3', text: '6S3' },
  { value: '6T1', text: '6T1' },
  { value: '6T2', text: '6T2' },
  { value: '6T3', text: '6T3' },
  { value: '6T4', text: '6T4' },
  { value: '6U1', text: '6U1' },
  { value: '6U2', text: '6U2' },
  { value: '6U3', text: '6U3' },
  { value: '6U4', text: '6U4' },
  { value: '6V1', text: '6V1' },
  { value: '6V2', text: '6V2' },
  { value: '6V3', text: '6V3' },
  { value: '6W1', text: '6W1' },
  { value: '6W2', text: '6W2' },
  { value: '6W3', text: '6W3' },
  { value: '6Y1', text: '6Y1' },
  { value: '6Y4', text: '6Y4' },
  { value: '6Y5', text: '6Y5' },
  { value: '701', text: '701' },
  { value: '702', text: '702' },
  { value: '703', text: '703' },
  { value: '704', text: '704' },
  { value: '705', text: '705' },
  { value: '706', text: '706' },
  { value: '707', text: '707' },
  { value: '709', text: '709' },
  { value: '711', text: '711' },
  { value: '712', text: '712' },
  { value: '713', text: '713' },
  { value: '721', text: '721' },
  { value: '722', text: '722' },
  { value: '723', text: '723' },
  { value: '731', text: '731' },
  { value: '732', text: '732' },
  { value: '733', text: '733' },
  { value: '741', text: '741' },
  { value: '742', text: '742' },
  { value: '743', text: '743' },
  { value: '751', text: '751' },
  { value: '752', text: '752' },
  { value: '753', text: '753' },
  { value: '75A', text: '75A' },
  { value: '75B', text: '75B' },
  { value: '75C', text: '75C' },
  { value: '761', text: '761' },
  { value: '762', text: '762' },
  { value: '763', text: '763' },
  { value: '771', text: '771' },
  { value: '772', text: '772' },
  { value: '773', text: '773' },
  { value: '775', text: '775' },
  { value: '776', text: '776' },
  { value: '777', text: '777' },
  { value: '77A', text: '77A' },
  { value: '77B', text: '77B' },
  { value: '77C', text: '77C' },
  { value: '781', text: '781' },
  { value: '782', text: '782' },
  { value: '783', text: '783' },
  { value: '790', text: '790' },
  { value: '791', text: '791' },
  { value: '792', text: '792' },
  { value: '793', text: '793' },
  { value: '794', text: '794' },
  { value: '795', text: '795' },
  { value: '796', text: '796' },
  { value: '797', text: '797' },
  { value: '798', text: '798' },
  { value: '799', text: '799' },
  { value: '79A', text: '79A' },
  { value: '79B', text: '79B' },
  { value: '7A1', text: '7A1' },
  { value: '7A2', text: '7A2' },
  { value: '7A3', text: '7A3' },
  { value: '7AA', text: '7AA' },
  { value: '7B0', text: '7B0' },
  { value: '7B1', text: '7B1' },
  { value: '7B2', text: '7B2' },
  { value: '7B3', text: '7B3' },
  { value: '7B4', text: '7B4' },
  { value: '7B5', text: '7B5' },
  { value: '7B6', text: '7B6' },
  { value: '7B7', text: '7B7' },
  { value: '7B8', text: '7B8' },
  { value: '7B9', text: '7B9' },
  { value: '7C1', text: '7C1' },
  { value: '7C2', text: '7C2' },
  { value: '7C3', text: '7C3' },
  { value: '7C4', text: '7C4' },
  { value: '7C5', text: '7C5' },
  { value: '7C6', text: '7C6' },
  { value: '7C7', text: '7C7' },
  { value: '7C8', text: '7C8' },
  { value: '7D1', text: '7D1' },
  { value: '7D2', text: '7D2' },
  { value: '7D3', text: '7D3' },
  { value: '7DA', text: '7DA' },
  { value: '7DB', text: '7DB' },
  { value: '7F1', text: '7F1' },
  { value: '7F2', text: '7F2' },
  { value: '7F3', text: '7F3' },
  { value: '7F4', text: '7F4' },
  { value: '7F5', text: '7F5' },
  { value: '7F6', text: '7F6' },
  { value: '7F9', text: '7F9' },
  { value: '7FB', text: '7FB' },
  { value: '7G1', text: '7G1' },
  { value: '7G2', text: '7G2' },
  { value: '7G3', text: '7G3' },
  { value: '7G4', text: '7G4' },
  { value: '7G6', text: '7G6' },
  { value: '7H1', text: '7H1' },
  { value: '7H2', text: '7H2' },
  { value: '7H3', text: '7H3' },
  { value: '7H9', text: '7H9' },
  { value: '7J0', text: '7J0' },
  { value: '7J1', text: '7J1' },
  { value: '7J2', text: '7J2' },
  { value: '7J3', text: '7J3' },
  { value: '7J4', text: '7J4' },
  { value: '7J5', text: '7J5' },
  { value: '7J6', text: '7J6' },
  { value: '7J7', text: '7J7' },
  { value: '7J8', text: '7J8' },
  { value: '7J9', text: '7J9' },
  { value: '7K0', text: '7K0' },
  { value: '7K1', text: '7K1' },
  { value: '7K2', text: '7K2' },
  { value: '7K3', text: '7K3' },
  { value: '7K4', text: '7K4' },
  { value: '7K5', text: '7K5' },
  { value: '7K6', text: '7K6' },
  { value: '7K7', text: '7K7' },
  { value: '7K8', text: '7K8' },
  { value: '7K9', text: '7K9' },
  { value: '7KB', text: '7KB' },
  { value: '7M1', text: '7M1' },
  { value: '7M2', text: '7M2' },
  { value: '7M3', text: '7M3' },
  { value: '7P1', text: '7P1' },
  { value: '7P2', text: '7P2' },
  { value: '7P3', text: '7P3' },
  { value: '7P4', text: '7P4' },
  { value: '7P5', text: '7P5' },
  { value: '7P6', text: '7P6' },
  { value: '7P7', text: '7P7' },
  { value: '7P8', text: '7P8' },
  { value: '7P9', text: '7P9' },
  { value: '7Q1', text: '7Q1' },
  { value: '7Q2', text: '7Q2' },
  { value: '7Q3', text: '7Q3' },
  { value: '7Q4', text: '7Q4' },
  { value: '7R1', text: '7R1' },
  { value: '7R2', text: '7R2' },
  { value: '7R3', text: '7R3' },
  { value: '7S1', text: '7S1' },
  { value: '7S2', text: '7S2' },
  { value: '7S3', text: '7S3' },
  { value: '7T1', text: '7T1' },
  { value: '7T2', text: '7T2' },
  { value: '7T3', text: '7T3' },
  { value: '7TA', text: '7TA' },
  { value: '7U1', text: '7U1' },
  { value: '7U2', text: '7U2' },
  { value: '7U3', text: '7U3' },
  { value: '7U4', text: '7U4' },
  { value: '7V1', text: '7V1' },
  { value: '7V2', text: '7V2' },
  { value: '7V3', text: '7V3' },
  { value: '7V4', text: '7V4' },
  { value: '7V5', text: '7V5' },
  { value: '7V7', text: '7V7' },
  { value: '7V8', text: '7V8' },
  { value: '7V9', text: '7V9' },
  { value: '7W0', text: '7W0' },
  { value: '7W1', text: '7W1' },
  { value: '7W2', text: '7W2' },
  { value: '7W3', text: '7W3' },
  { value: '7W4', text: '7W4' },
  { value: '7W5', text: '7W5' },
  { value: '7W6', text: '7W6' },
  { value: '7W7', text: '7W7' },
  { value: '7W8', text: '7W8' },
  { value: '7W9', text: '7W9' },
  { value: '7WA', text: '7WA' },
  { value: '7WB', text: '7WB' },
  { value: '7WC', text: '7WC' },
  { value: '7WD', text: '7WD' },
  { value: '811', text: '811' },
  { value: '821', text: '821' },
  { value: '822', text: '822' },
  { value: '823', text: '823' },
  { value: '824', text: '824' },
  { value: '831', text: '831' },
  { value: '8A1', text: '8A1' },
  { value: '8A2', text: '8A2' },
  { value: '8A3', text: '8A3' },
  { value: '8A4', text: '8A4' },
  { value: '8AA', text: '8AA' },
  { value: '8B0', text: '8B0' },
  { value: '8B1', text: '8B1' },
  { value: '8B2', text: '8B2' },
  { value: '8B3', text: '8B3' },
  { value: '8B4', text: '8B4' },
  { value: '8B5', text: '8B5' },
  { value: '8B6', text: '8B6' },
  { value: '8B7', text: '8B7' },
  { value: '8B8', text: '8B8' },
  { value: '8B9', text: '8B9' },
  { value: '8C1', text: '8C1' },
  { value: '8C2', text: '8C2' },
  { value: '8C3', text: '8C3' },
  { value: '8C4', text: '8C4' },
  { value: '8C5', text: '8C5' },
  { value: '8C6', text: '8C6' },
  { value: '8C7', text: '8C7' },
  { value: '8C8', text: '8C8' },
  { value: '8C9', text: '8C9' },
  { value: '8D1', text: '8D1' },
  { value: '8D2', text: '8D2' },
  { value: '8D3', text: '8D3' },
  { value: '8D4', text: '8D4' },
  { value: '8D5', text: '8D5' },
  { value: '8D9', text: '8D9' },
  { value: '8DA', text: '8DA' },
  { value: '8DB', text: '8DB' },
  { value: '8F1', text: '8F1' },
  { value: '8F2', text: '8F2' },
  { value: '8F3', text: '8F3' },
  { value: '8G1', text: '8G1' },
  { value: '8G2', text: '8G2' },
  { value: '8G3', text: '8G3' },
  { value: '8G4', text: '8G4' },
  { value: '8G9', text: '8G9' },
  { value: '8H1', text: '8H1' },
  { value: '8H2', text: '8H2' },
  { value: '8H3', text: '8H3' },
  { value: '8J0', text: '8J0' },
  { value: '8J1', text: '8J1' },
  { value: '8J2', text: '8J2' },
  { value: '8J3', text: '8J3' },
  { value: '8J4', text: '8J4' },
  { value: '8J5', text: '8J5' },
  { value: '8J6', text: '8J6' },
  { value: '8J7', text: '8J7' },
  { value: '8J8', text: '8J8' },
  { value: '8J9', text: '8J9' },
  { value: '8JB', text: '8JB' },
  { value: '8K1', text: '8K1' },
  { value: '8K2', text: '8K2' },
  { value: '8K3', text: '8K3' },
  { value: '8K4', text: '8K4' },
  { value: '8M1', text: '8M1' },
  { value: '8M2', text: '8M2' },
  { value: '8M3', text: '8M3' },
  { value: '8P0', text: '8P0' },
  { value: '8P1', text: '8P1' },
  { value: '8P2', text: '8P2' },
  { value: '8P3', text: '8P3' },
  { value: '8P4', text: '8P4' },
  { value: '8P5', text: '8P5' },
  { value: '8P6', text: '8P6' },
  { value: '8P7', text: '8P7' },
  { value: '8P8', text: '8P8' },
  { value: '8P9', text: '8P9' },
  { value: '8Q0', text: '8Q0' },
  { value: '8Q1', text: '8Q1' },
  { value: '8Q2', text: '8Q2' },
  { value: '8Q3', text: '8Q3' },
  { value: '8Q4', text: '8Q4' },
  { value: '8Q5', text: '8Q5' },
  { value: '8Q6', text: '8Q6' },
  { value: '8Q7', text: '8Q7' },
  { value: '8Q8', text: '8Q8' },
  { value: '8Q9', text: '8Q9' },
  { value: '8QA', text: '8QA' },
  { value: '8QB', text: '8QB' },
  { value: '8QC', text: '8QC' },
  { value: '8R1', text: '8R1' },
  { value: '8R2', text: '8R2' },
  { value: '8R3', text: '8R3' },
  { value: '8R4', text: '8R4' },
  { value: '8R9', text: '8R9' },
  { value: '8S0', text: '8S0' },
  { value: '8S1', text: '8S1' },
  { value: '8S2', text: '8S2' },
  { value: '8S3', text: '8S3' },
  { value: '8S4', text: '8S4' },
  { value: '8S5', text: '8S5' },
  { value: '8S6', text: '8S6' },
  { value: '8S7', text: '8S7' },
  { value: '8S8', text: '8S8' },
  { value: '8S9', text: '8S9' },
  { value: '8T1', text: '8T1' },
  { value: '8T2', text: '8T2' },
  { value: '8T9', text: '8T9' },
  { value: '8TA', text: '8TA' },
  { value: '8TB', text: '8TB' },
  { value: '8U1', text: '8U1' },
  { value: '8U2', text: '8U2' },
  { value: '8U3', text: '8U3' },
  { value: '8U4', text: '8U4' },
  { value: '8V1', text: '8V1' },
  { value: '8V2', text: '8V2' },
  { value: '8V3', text: '8V3' },
  { value: '8V6', text: '8V6' },
  { value: '8V7', text: '8V7' },
  { value: '8V8', text: '8V8' },
  { value: '8V9', text: '8V9' },
  { value: '8W0', text: '8W0' },
  { value: '8W1', text: '8W1' },
  { value: '8W2', text: '8W2' },
  { value: '8W3', text: '8W3' },
  { value: '8W4', text: '8W4' },
  { value: '8W5', text: '8W5' },
  { value: '8W6', text: '8W6' },
  { value: '8W7', text: '8W7' },
  { value: '8W8', text: '8W8' },
  { value: '8W9', text: '8W9' },
  { value: '8WA', text: '8WA' },
  { value: '8WB', text: '8WB' },
  { value: '8WC', text: '8WC' },
  { value: '8WD', text: '8WD' },
  { value: '8X4', text: '8X4' },
  { value: '911', text: '911' },
  { value: '912', text: '912' },
  { value: '913', text: '913' },
  { value: '921', text: '921' },
  { value: '922', text: '922' },
  { value: '923', text: '923' },
  { value: '924', text: '924' },
  { value: '925', text: '925' },
  { value: '926', text: '926' },
  { value: '927', text: '927' },
  { value: '931', text: '931' },
  { value: '932', text: '932' },
  { value: '933', text: '933' },
  { value: '941', text: '941' },
  { value: '943', text: '943' },
  { value: '94A', text: '94A' },
  { value: '94B', text: '94B' },
  { value: '951', text: '951' },
  { value: '953', text: '953' },
  { value: '961', text: '961' },
  { value: '962', text: '962' },
  { value: '963', text: '963' },
  { value: '96A', text: '96A' },
  { value: '971', text: '971' },
  { value: '972', text: '972' },
  { value: '97A', text: '97A' },
  { value: '97B', text: '97B' },
  { value: '981', text: '981' },
  { value: '982', text: '982' },
  { value: '98A', text: '98A' },
  { value: '98B', text: '98B' },
  { value: '991', text: '991' },
  { value: '992', text: '992' },
  { value: '993', text: '993' },
  { value: '9A1', text: '9A1' },
  { value: '9A2', text: '9A2' },
  { value: '9A3', text: '9A3' },
  { value: '9B0', text: '9B0' },
  { value: '9B1', text: '9B1' },
  { value: '9B2', text: '9B2' },
  { value: '9B3', text: '9B3' },
  { value: '9B4', text: '9B4' },
  { value: '9B5', text: '9B5' },
  { value: '9B6', text: '9B6' },
  { value: '9B7', text: '9B7' },
  { value: '9B8', text: '9B8' },
  { value: '9B9', text: '9B9' },
  { value: '9C1', text: '9C1' },
  { value: '9C2', text: '9C2' },
  { value: '9C3', text: '9C3' },
  { value: '9C4', text: '9C4' },
  { value: '9C5', text: '9C5' },
  { value: '9C6', text: '9C6' },
  { value: '9C7', text: '9C7' },
  { value: '9C8', text: '9C8' },
  { value: '9C9', text: '9C9' },
  { value: '9D1', text: '9D1' },
  { value: '9D2', text: '9D2' },
  { value: '9D3', text: '9D3' },
  { value: '9D9', text: '9D9' },
  { value: '9DA', text: '9DA' },
  { value: '9F1', text: '9F1' },
  { value: '9F2', text: '9F2' },
  { value: '9F3', text: '9F3' },
  { value: '9F5', text: '9F5' },
  { value: '9F9', text: '9F9' },
  { value: '9G1', text: '9G1' },
  { value: '9G2', text: '9G2' },
  { value: '9G3', text: '9G3' },
  { value: '9G4', text: '9G4' },
  { value: '9G5', text: '9G5' },
  { value: '9G6', text: '9G6' },
  { value: '9G7', text: '9G7' },
  { value: '9H1', text: '9H1' },
  { value: '9H2', text: '9H2' },
  { value: '9H3', text: '9H3' },
  { value: '9H4', text: '9H4' },
  { value: '9H5', text: '9H5' },
  { value: '9H6', text: '9H6' },
  { value: '9HB', text: '9HB' },
  { value: '9J0', text: '9J0' },
  { value: '9J1', text: '9J1' },
  { value: '9J2', text: '9J2' },
  { value: '9J3', text: '9J3' },
  { value: '9J4', text: '9J4' },
  { value: '9J5', text: '9J5' },
  { value: '9J6', text: '9J6' },
  { value: '9J7', text: '9J7' },
  { value: '9J8', text: '9J8' },
  { value: '9J9', text: '9J9' },
  { value: '9JB', text: '9JB' },
  { value: '9K1', text: '9K1' },
  { value: '9K2', text: '9K2' },
  { value: '9KC', text: '9KC' },
  { value: '9KD', text: '9KD' },
  { value: '9L1', text: '9L1' },
  { value: '9L2', text: '9L2' },
  { value: '9L3', text: '9L3' },
  { value: '9M1', text: '9M1' },
  { value: '9M2', text: '9M2' },
  { value: '9M3', text: '9M3' },
  { value: '9M4', text: '9M4' },
  { value: '9M5', text: '9M5' },
  { value: '9M6', text: '9M6' },
  { value: '9M7', text: '9M7' },
  { value: '9M8', text: '9M8' },
  { value: '9M9', text: '9M9' },
  { value: '9MA', text: '9MA' },
  { value: '9P0', text: '9P0' },
  { value: '9P1', text: '9P1' },
  { value: '9P2', text: '9P2' },
  { value: '9P3', text: '9P3' },
  { value: '9P4', text: '9P4' },
  { value: '9P5', text: '9P5' },
  { value: '9P6', text: '9P6' },
  { value: '9P7', text: '9P7' },
  { value: '9P8', text: '9P8' },
  { value: '9P9', text: '9P9' },
  { value: '9Q1', text: '9Q1' },
  { value: '9Q2', text: '9Q2' },
  { value: '9Q3', text: '9Q3' },
  { value: '9Q4', text: '9Q4' },
  { value: '9Q5', text: '9Q5' },
  { value: '9Q6', text: '9Q6' },
  { value: '9Q7', text: '9Q7' },
  { value: '9Q8', text: '9Q8' },
  { value: '9Q9', text: '9Q9' },
  { value: '9R1', text: '9R1' },
  { value: '9R2', text: '9R2' },
  { value: '9R3', text: '9R3' },
  { value: '9R4', text: '9R4' },
  { value: '9R9', text: '9R9' },
  { value: '9RA', text: '9RA' },
  { value: '9S1', text: '9S1' },
  { value: '9S2', text: '9S2' },
  { value: '9S3', text: '9S3' },
  { value: '9SA', text: '9SA' },
  { value: '9T0', text: '9T0' },
  { value: '9T1', text: '9T1' },
  { value: '9T2', text: '9T2' },
  { value: '9T3', text: '9T3' },
  { value: '9T4', text: '9T4' },
  { value: '9T5', text: '9T5' },
  { value: '9T6', text: '9T6' },
  { value: '9T7', text: '9T7' },
  { value: '9T8', text: '9T8' },
  { value: '9T9', text: '9T9' },
  { value: '9U1', text: '9U1' },
  { value: '9U3', text: '9U3' },
  { value: 'A00', text: 'A00' },
  { value: 'B00', text: 'B00' },
  { value: 'C00', text: 'C00' },
  { value: 'M00', text: 'M00' },
]

export default {
  moduleBlock,
  discDesc,
  module,
  block
}