
export default {
  data: () => ({

    localData: {

      // stepData
      JSON1: [ 
        { SN: '1',  CDATE: '2025-01-31', },
        { SN: '2',  CDATE: '2025-02-28', },
        { SN: '3',  CDATE: '2025-03-28', },
        { SN: '4',  CDATE: '2025-04-25', },
        { SN: '5',  CDATE: '2025-05-30', },
        { SN: '6',  CDATE: '2025-06-27', },
        { SN: '7',  CDATE: '2025-07-25', },
        { SN: '8',  CDATE: '2025-08-29', },
        { SN: '9',  CDATE: '2025-09-26', },
        { SN: '10', CDATE: '2025-10-31', },
        { SN: '11', CDATE: '2025-11-28', },
        { SN: '12', CDATE: '2025-12-26', },
        { SN: '13', CDATE: '2026-01-30', },
        { SN: '14', CDATE: '2026-02-27', },
        { SN: '15', CDATE: '2026-03-27', },
        { SN: '16', CDATE: '2026-04-24', },
        { SN: '17', CDATE: '2026-05-29', },
        { SN: '18', CDATE: '2026-06-26', },
        { SN: '19', CDATE: '2026-07-31', },
        { SN: '20', CDATE: '2026-08-28', },
        { SN: '21', CDATE: '2026-09-25', },
        { SN: '22', CDATE: '2026-10-30', },
        { SN: '23', CDATE: '2026-11-27', },
        { SN: '24', CDATE: '2026-12-25', },
        { SN: '25', CDATE: '2027-01-29', },
        { SN: '26', CDATE: '2027-02-26', },
        { SN: '27', CDATE: '2027-03-26', },
      ],
  
      // BLOCKS
      JSON2: [ 
        { MONTH: '2025-02', BLK: 'EN11', MOD: '', },
        { MONTH: '2025-02', BLK: 'EP11', MOD: '', },
        { MONTH: '2025-02', BLK: 'LD11', MOD: '', },
        { MONTH: '2025-03', BLK: 'EN21', MOD: '', },
        { MONTH: '2025-03', BLK: 'LD21', MOD: '', },
        { MONTH: '2025-03', BLK: 'LD31', MOD: '', },
        { MONTH: '2025-03', BLK: 'LD41', MOD: '', },
        { MONTH: '2025-03', BLK: 'WN11', MOD: '', },
        { MONTH: '2025-03', BLK: 'WN21', MOD: '', },
        { MONTH: '2025-04', BLK: 'CD11', MOD: '', },
        { MONTH: '2025-04', BLK: 'CD13', MOD: '', },
        { MONTH: '2025-04', BLK: 'EN16', MOD: '', },
        { MONTH: '2025-04', BLK: 'EN21', MOD: '', },
        { MONTH: '2025-04', BLK: 'LD11', MOD: '', },
        { MONTH: '2025-04', BLK: 'NP11', MOD: '', },
        { MONTH: '2025-04', BLK: 'SD11', MOD: '', },
        { MONTH: '2025-04', BLK: 'SD12', MOD: '', },
        { MONTH: '2025-04', BLK: 'WN21', MOD: '', },
        { MONTH: '2025-05', BLK: 'CD21', MOD: '', },
        { MONTH: '2025-05', BLK: 'CD22', MOD: '', },
        { MONTH: '2025-05', BLK: 'CD23', MOD: '', },
        { MONTH: '2025-05', BLK: 'CD24', MOD: '', },
        { MONTH: '2025-05', BLK: 'EN11', MOD: '', },
        { MONTH: '2025-05', BLK: 'EN21', MOD: '', },
        { MONTH: '2025-05', BLK: 'EP11', MOD: '', },
        { MONTH: '2025-05', BLK: 'LD11', MOD: '', },
        { MONTH: '2025-05', BLK: 'LD21', MOD: '', },
        { MONTH: '2025-05', BLK: 'LD31', MOD: '', },
        { MONTH: '2025-05', BLK: 'LD41', MOD: '', },
        { MONTH: '2025-05', BLK: 'NP11', MOD: '', },
        { MONTH: '2025-05', BLK: 'SD11', MOD: '', },
        { MONTH: '2025-05', BLK: 'SD12', MOD: '', },
        { MONTH: '2025-05', BLK: 'WN11', MOD: '', },
        { MONTH: '2025-05', BLK: 'WN21', MOD: '', },
        { MONTH: '2025-05', BLK: 'WN26', MOD: '', },
        { MONTH: '2025-06', BLK: 'CD22', MOD: '', },
        { MONTH: '2025-06', BLK: 'CD23', MOD: '', },
        { MONTH: '2025-06', BLK: 'CD24', MOD: '', },
        { MONTH: '2025-06', BLK: 'CD25', MOD: '', },
        { MONTH: '2025-06', BLK: 'CD31', MOD: '', },
        { MONTH: '2025-06', BLK: 'EN16', MOD: '', },
        { MONTH: '2025-06', BLK: 'EN21', MOD: '', },
        { MONTH: '2025-06', BLK: 'LD41', MOD: '', },
        { MONTH: '2025-06', BLK: 'LHS1', MOD: '', },
        { MONTH: '2025-06', BLK: 'MOD',  MOD: 'LQ', },
        { MONTH: '2025-06', BLK: 'ND11', MOD: '', },
        { MONTH: '2025-06', BLK: 'ND12', MOD: '', },
        { MONTH: '2025-06', BLK: 'ND21', MOD: '', },
        { MONTH: '2025-06', BLK: 'ND22', MOD: '', },
        { MONTH: '2025-06', BLK: 'ND23', MOD: '', },
        { MONTH: '2025-06', BLK: 'ND31', MOD: '', },
        { MONTH: '2025-06', BLK: 'NP11', MOD: '', },
        { MONTH: '2025-06', BLK: 'SP11', MOD: '', },
        { MONTH: '2025-06', BLK: 'WN11', MOD: '', },
        { MONTH: '2025-06', BLK: 'WN16', MOD: '', },
        { MONTH: '2025-06', BLK: 'WN26', MOD: '', },
        { MONTH: '2025-06', BLK: 'WP11', MOD: '', },
        { MONTH: '2025-07', BLK: 'CD13', MOD: '', },
        { MONTH: '2025-07', BLK: 'CD31', MOD: '', },
        { MONTH: '2025-07', BLK: 'CD34', MOD: '', },
        { MONTH: '2025-07', BLK: 'EC11', MOD: '', },
        { MONTH: '2025-07', BLK: 'EC1G', MOD: '', },
        { MONTH: '2025-07', BLK: 'EC21', MOD: '', },
        { MONTH: '2025-07', BLK: 'EC2G', MOD: '', },
        { MONTH: '2025-07', BLK: 'EN16', MOD: '', },
        { MONTH: '2025-07', BLK: 'EN26', MOD: '', },
        { MONTH: '2025-07', BLK: 'ER31', MOD: '', },
        { MONTH: '2025-07', BLK: 'MOD',  MOD: 'LQ', },
        { MONTH: '2025-07', BLK: 'ND21', MOD: '', },
        { MONTH: '2025-07', BLK: 'SD12', MOD: '', },
        { MONTH: '2025-07', BLK: 'SP11', MOD: '', },
        { MONTH: '2025-07', BLK: 'WC1G', MOD: '', },
        { MONTH: '2025-07', BLK: 'WC2G', MOD: '', },
        { MONTH: '2025-07', BLK: 'WN11', MOD: '', },
        { MONTH: '2025-07', BLK: 'WN21', MOD: '', },
        { MONTH: '2025-07', BLK: 'WP11', MOD: '', },
        { MONTH: '2025-08', BLK: 'CD22', MOD: '', },
        { MONTH: '2025-08', BLK: 'CD23', MOD: '', },
        { MONTH: '2025-08', BLK: 'CD24', MOD: '', },
        { MONTH: '2025-08', BLK: 'CD34', MOD: '', },
        { MONTH: '2025-08', BLK: 'CD34', MOD: '', },
        { MONTH: '2025-08', BLK: 'EC11', MOD: '', },
        { MONTH: '2025-08', BLK: 'EC1G', MOD: '', },
        { MONTH: '2025-08', BLK: 'EC21', MOD: '', },
        { MONTH: '2025-08', BLK: 'EC2G', MOD: '', },
        { MONTH: '2025-08', BLK: 'EN26', MOD: '', },
        { MONTH: '2025-08', BLK: 'EN26', MOD: '', },
        { MONTH: '2025-08', BLK: 'ER31', MOD: '', },
        { MONTH: '2025-08', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2025-08', BLK: 'MOD',  MOD: 'LQ', },
        { MONTH: '2025-08', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2025-08', BLK: 'ND12', MOD: '', },
        { MONTH: '2025-08', BLK: 'ND22', MOD: '', },
        { MONTH: '2025-08', BLK: 'SD31', MOD: '', },
        { MONTH: '2025-08', BLK: 'SD33', MOD: '', },
        { MONTH: '2025-08', BLK: 'WC11', MOD: '', },
        { MONTH: '2025-08', BLK: 'WC1G', MOD: '', },
        { MONTH: '2025-08', BLK: 'WC21', MOD: '', },
        { MONTH: '2025-08', BLK: 'WC2G', MOD: '', },
        { MONTH: '2025-08', BLK: 'WN21', MOD: '', },
        { MONTH: '2025-08', BLK: 'WN26', MOD: '', },
        { MONTH: '2025-09', BLK: 'CD31', MOD: '', },
        { MONTH: '2025-09', BLK: 'CK11', MOD: '', },
        { MONTH: '2025-09', BLK: 'EC11', MOD: '', },
        { MONTH: '2025-09', BLK: 'EC21', MOD: '', },
        { MONTH: '2025-09', BLK: 'MOD',  MOD: 'CENTRAL', },
        { MONTH: '2025-09', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2025-09', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2025-09', BLK: 'ND21', MOD: '', },
        { MONTH: '2025-09', BLK: 'ND22', MOD: '', },
        { MONTH: '2025-09', BLK: 'ND23', MOD: '', },
        { MONTH: '2025-09', BLK: 'ND31', MOD: '', },
        { MONTH: '2025-09', BLK: 'ND33', MOD: '', },
        { MONTH: '2025-09', BLK: 'SD33', MOD: '', },
        { MONTH: '2025-09', BLK: 'WC11', MOD: '', },
        { MONTH: '2025-09', BLK: 'WC21', MOD: '', },
        { MONTH: '2025-09', BLK: 'WN21', MOD: '', },
        { MONTH: '2025-09', BLK: 'WPB1', MOD: '', },
        { MONTH: '2025-09', BLK: 'WPG1', MOD: '', },
        { MONTH: '2025-10', BLK: 'CD31', MOD: '', },
        { MONTH: '2025-10', BLK: 'CD34', MOD: '', },
        { MONTH: '2025-10', BLK: 'CK11', MOD: '', },
        { MONTH: '2025-10', BLK: 'CK14', MOD: '', },
        { MONTH: '2025-10', BLK: 'EC1G', MOD: '', },
        { MONTH: '2025-10', BLK: 'EC1K', MOD: '', },
        { MONTH: '2025-10', BLK: 'EC2G', MOD: '', },
        { MONTH: '2025-10', BLK: 'EC2K', MOD: '', },
        { MONTH: '2025-10', BLK: 'EF21', MOD: '', },
        { MONTH: '2025-10', BLK: 'EN42', MOD: '', },
        { MONTH: '2025-10', BLK: 'EN52', MOD: '', },
        { MONTH: '2025-10', BLK: 'ER31', MOD: '', },
        { MONTH: '2025-10', BLK: 'GD11', MOD: '', },
        { MONTH: '2025-10', BLK: 'KD11', MOD: '', },
        { MONTH: '2025-10', BLK: 'MOD',  MOD: 'CENTRAL', },
        { MONTH: '2025-10', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2025-10', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2025-10', BLK: 'ND31', MOD: '', },
        { MONTH: '2025-10', BLK: 'ND33', MOD: '', },
        { MONTH: '2025-10', BLK: 'SD31', MOD: '', },
        { MONTH: '2025-10', BLK: 'WC1G', MOD: '', },
        { MONTH: '2025-10', BLK: 'WC1K', MOD: '', },
        { MONTH: '2025-10', BLK: 'WC2G', MOD: '', },
        { MONTH: '2025-10', BLK: 'WC2K', MOD: '', },
        { MONTH: '2025-10', BLK: 'WN26', MOD: '', },
        { MONTH: '2025-10', BLK: 'WN41', MOD: '', },
        { MONTH: '2025-10', BLK: 'WN42', MOD: '', },
        { MONTH: '2025-11', BLK: 'CX11', MOD: '', },
        { MONTH: '2025-11', BLK: 'EC1G', MOD: '', },
        { MONTH: '2025-11', BLK: 'EC2G', MOD: '', },
        { MONTH: '2025-11', BLK: 'EF21', MOD: '', },
        { MONTH: '2025-11', BLK: 'ER31', MOD: '', },
        { MONTH: '2025-11', BLK: 'MOD',  MOD: 'CENTRAL', },
        { MONTH: '2025-11', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2025-11', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2025-11', BLK: 'ND21', MOD: '', },
        { MONTH: '2025-11', BLK: 'SC41', MOD: '', },
        { MONTH: '2025-11', BLK: 'SD33', MOD: '', },
        { MONTH: '2025-11', BLK: 'SF21', MOD: '', },
        { MONTH: '2025-11', BLK: 'WC1G', MOD: '', },
        { MONTH: '2025-11', BLK: 'WC1K', MOD: '', },
        { MONTH: '2025-11', BLK: 'WC2G', MOD: '', },
        { MONTH: '2025-11', BLK: 'WC2K', MOD: '', },
        { MONTH: '2025-11', BLK: 'WF21', MOD: '', },
        { MONTH: '2025-11', BLK: 'WN51', MOD: '', },
        { MONTH: '2025-12', BLK: 'CC41', MOD: '', },
        { MONTH: '2025-12', BLK: 'CC42', MOD: '', },
        { MONTH: '2025-12', BLK: 'CC44', MOD: '', },
        { MONTH: '2025-12', BLK: 'CF11', MOD: '', },
        { MONTH: '2025-12', BLK: 'CK14', MOD: '', },
        { MONTH: '2025-12', BLK: 'GD11', MOD: '', },
        { MONTH: '2025-12', BLK: 'KD11', MOD: '', },
        { MONTH: '2025-12', BLK: 'MOD',  MOD: 'CENTRAL', },
        { MONTH: '2025-12', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2025-12', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2025-12', BLK: 'ND31', MOD: '', },
        { MONTH: '2025-12', BLK: 'NF21', MOD: '', },
        { MONTH: '2025-12', BLK: 'NFP1', MOD: '', },
        { MONTH: '2025-12', BLK: 'NX11', MOD: '', },
        { MONTH: '2025-12', BLK: 'RC41', MOD: '', },
        { MONTH: '2025-12', BLK: 'RC42', MOD: '', },
        { MONTH: '2025-12', BLK: 'SC42', MOD: '', },
        { MONTH: '2025-12', BLK: 'SF21', MOD: '', },
        { MONTH: '2025-12', BLK: 'WF21', MOD: '', },
        { MONTH: '2026-01', BLK: 'MOD',  MOD: 'CENTRAL', },
        { MONTH: '2026-01', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2026-01', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2026-02', BLK: 'MOD',  MOD: 'CENTRAL', },
        { MONTH: '2026-02', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2026-02', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2026-03', BLK: 'MOD',  MOD: 'CENTRAL', },
        { MONTH: '2026-03', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2026-03', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2026-04', BLK: 'MOD',  MOD: 'NORTH', },
        { MONTH: '2026-04', BLK: 'MOD',  MOD: 'SOUTH', },
        { MONTH: '2026-05', BLK: 'LH11', MOD: '', },
        { MONTH: '2026-05', BLK: 'LV51', MOD: '', },
        { MONTH: '2026-05', BLK: 'LV52', MOD: '', },
        { MONTH: '2026-05', BLK: 'LV53', MOD: '', },
        { MONTH: '2026-05', BLK: 'MOD',  MOD: 'LQ', },
        { MONTH: '2026-06', BLK: 'LH11', MOD: '', },
        { MONTH: '2026-11', BLK: 'NF110', MOD: '', },
        { MONTH: '2026-11', BLK: 'NF120', MOD: '', },
        { MONTH: '2026-11', BLK: 'NF130', MOD: '', },
        { MONTH: '2026-12', BLK: 'NF110', MOD: '', },
        { MONTH: '2026-12', BLK: 'NF120', MOD: '', },
        { MONTH: '2026-12', BLK: 'NF130', MOD: '', },
        { MONTH: '2027-01', BLK: 'MOD',  MOD: 'FLARE', },
        { MONTH: '2027-02', BLK: 'MOD',  MOD: 'FLARE', },
      ],
  
      JSON3: [
        { SN: '1',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node',            BLK: 'WN11', },
        { SN: '2',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NW, Node',            BLK: 'WN16', },
        { SN: '3',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node',            BLK: 'WN21', },
        { SN: '4',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SW, Node',            BLK: 'WN26', },
        { SN: '5',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node',            BLK: 'EN11', },
        { SN: '6',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'NE, Node',            BLK: 'EN16', },
        { SN: '7',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node',            BLK: 'EN21', },
        { SN: '8',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'SE, Node',            BLK: 'EN26', },
        { SN: '9',   AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-West',          BLK: 'WN41', },
        { SN: '10',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-West',          BLK: 'WN42', },
        { SN: '11',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-West',          BLK: 'WN51', },
        { SN: '12',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-West',          BLK: 'WN52', },
        { SN: '13',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-East',          BLK: 'EN41', },
        { SN: '14',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'North-East',          BLK: 'EN42', },
        { SN: '15',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-East',          BLK: 'EN51', },
        { SN: '16',  AREA: 'HULL',    ZONE: 'NODE',       LEVEL: 'South-East',          BLK: 'EN52', },
        { SN: '17',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'East Pontoon',        BLK: 'EP11', },
        { SN: '18',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'West Pontoon',        BLK: 'WP11', },
        { SN: '19',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'West Pontoon',        BLK: 'WPG1', },
        { SN: '20',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'West Pontoon',        BLK: 'WPB1', },
        { SN: '21',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'South Pontoon',       BLK: 'SP11', },
        { SN: '22',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'North Pontoon',       BLK: 'NP11', },
        { SN: '23',  AREA: 'HULL',    ZONE: 'PONTOON',    LEVEL: 'North Pontoon',       BLK: 'NPB1', },
        { SN: '24',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Lower Column',    BLK: 'WC11', },
        { SN: '25',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Middle Column',   BLK: 'WC1K', },
        { SN: '26',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Middle Column',   BLK: 'WC3A', },
        { SN: '27',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    BLK: 'WC1G', },
        { SN: '28',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    BLK: 'WCP1', },
        { SN: '29',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    BLK: 'WCP2', },
        { SN: '30',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    BLK: 'WCP3', },
        { SN: '31',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    BLK: 'WCP4', },
        { SN: '32',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    BLK: 'WCP5', },
        { SN: '33',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NW, Upper Column',    BLK: 'WCP6', },
        { SN: '34',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Lower Column',    BLK: 'WC21', },
        { SN: '35',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Middle Column',   BLK: 'WC2K', },
        { SN: '36',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Middle Column',   BLK: 'WC4A', },
        { SN: '37',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column',    BLK: 'WC2G', },
        { SN: '38',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column',    BLK: 'WCP8', },
        { SN: '39',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SW, Upper Column',    BLK: 'WCP9', },
        { SN: '40',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Lower Column',    BLK: 'EC11', },
        { SN: '41',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Middle Column',   BLK: 'EC1K', },
        { SN: '42',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Middle Column',   BLK: 'EC3A', },
        { SN: '43',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column',    BLK: 'EC1G', },
        { SN: '44',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column',    BLK: 'ECP1', },
        { SN: '45',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'NE, Upper Column',    BLK: 'ECP3', },
        { SN: '46',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Lower Column',    BLK: 'EC21', },
        { SN: '47',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Middle Column',   BLK: 'EC2K', },
        { SN: '48',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Middle Column',   BLK: 'EC4A', },
        { SN: '49',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'SE, Upper Column',    BLK: 'EC2G', },
        { SN: '50',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'South-East',          BLK: 'ECP4', },
        { SN: '51',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'South-East',          BLK: 'ECP5', },
        { SN: '52',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'South-East',          BLK: 'ECP6', },
        { SN: '53',  AREA: 'HULL',    ZONE: 'COLUMN',     LEVEL: 'South-East',          BLK: 'ECP7', },
        { SN: '54',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'East',                BLK: 'EF21', },
        { SN: '55',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'West',                BLK: 'WF21', },
        { SN: '56',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'South',               BLK: 'SF21', },
        { SN: '57',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'North',               BLK: 'NF21', },
        { SN: '58',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'North',               BLK: 'NFP1', },
        { SN: '59',  AREA: 'HULL',    ZONE: 'UCF',        LEVEL: 'Center',              BLK: 'CF11', },
        { SN: '60',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'NW',                  BLK: 'CC41', },
        { SN: '61',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'SW',                  BLK: 'CC42', },
        { SN: '62',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'NE',                  BLK: 'CC43', },
        { SN: '63',  AREA: 'HULL',    ZONE: 'CAISION',    LEVEL: 'SE',                  BLK: 'CC44', },
        { SN: '64',  AREA: 'HULL',    ZONE: 'RISER PIPE', LEVEL: 'NW',                  BLK: 'RC41', },
        { SN: '65',  AREA: 'HULL',    ZONE: 'RISER PIPE', LEVEL: 'SW',                  BLK: 'RC42', },
        { SN: '66',  AREA: 'HULL',    ZONE: 'SUMP PILE',  LEVEL: 'NE',                  BLK: 'SC41', },
        { SN: '67',  AREA: 'HULL',    ZONE: 'SUMP PILE',  LEVEL: 'SE',                  BLK: 'SC42', },
        { SN: '68',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Production Deck',     BLK: 'ND11', },
        { SN: '69',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Production Deck',     BLK: 'ND12', },
        { SN: '70',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck',          BLK: 'ND21', },
        { SN: '71',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck',          BLK: 'ND22', },
        { SN: '72',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck',          BLK: 'ND23', },
        { SN: '73',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Mezz. Deck',          BLK: 'ND25', },
        { SN: '74',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck',           BLK: 'ND31', },
        { SN: '75',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck',           BLK: 'ND33', },
        { SN: '76',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Main Deck',           BLK: 'NX11', },
        { SN: '77',  AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'Compressor Platform', BLK: 'KD11', },
        { SN: '78',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Production Deck',     BLK: 'CD11', },
        { SN: '79',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Production Deck',     BLK: 'CD13', },
        { SN: '80',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          BLK: 'CD21', },
        { SN: '81',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          BLK: 'CD22', },
        { SN: '82',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          BLK: 'CD23', },
        { SN: '83',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          BLK: 'CD24', },
        { SN: '84',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          BLK: 'CD25', },
        { SN: '85',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          BLK: 'CD26', },
        { SN: '86',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Mezz. Deck',          BLK: 'CD27', },
        { SN: '87',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           BLK: 'CD31', },
        { SN: '88',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           BLK: 'CD34', },
        { SN: '89',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           BLK: 'CX11', },
        { SN: '90',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           BLK: 'CK11', },
        { SN: '91',  AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'Main Deck',           BLK: 'CK14', },
        { SN: '92',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck',     BLK: 'SD11', },
        { SN: '93',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Production Deck',     BLK: 'SD12', },
        { SN: '94',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck',           BLK: 'SD31', },
        { SN: '95',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck',           BLK: 'SD33', },
        { SN: '96',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Main Deck',           BLK: 'SX11', },
        { SN: '97',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Generator Building',  BLK: 'GD11', },
        { SN: '98',  AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'Erectrical Building', BLK: 'ER31', },
        { SN: '99',  AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Flare Tower',         BLK: 'FLARE', },
        { SN: '99',  AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Flare Tower',         BLK: 'NF110', },
        { SN: '99',  AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Flare Tower',         BLK: 'NF120', },
        { SN: '99',  AREA: 'TOPSIDE', ZONE: 'FLARE',      LEVEL: 'Flare Tower',         BLK: 'NF130', },
        { SN: '100', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 1',          BLK: 'LD11', },
        { SN: '101', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 2',          BLK: 'LD21', },
        { SN: '102', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 3',          BLK: 'LD31', },
        { SN: '103', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          BLK: 'LD41', },
        { SN: '104', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          BLK: 'LHS1', },
        { SN: '105', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          BLK: 'LH11', },
        { SN: '106', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          BLK: 'LV51', },
        { SN: '107', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          BLK: 'LV52', },
        { SN: '108', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'LQ-Level 4',          BLK: 'LV53', },
        { SN: '109', AREA: 'TOPSIDE', ZONE: 'NORTH',      LEVEL: 'MODULE',              BLK: 'NORTH', },
        { SN: '110', AREA: 'TOPSIDE', ZONE: 'CENTRAL',    LEVEL: 'MODULE',              BLK: 'CENTRAL', },
        { SN: '111', AREA: 'TOPSIDE', ZONE: 'SOUTH',      LEVEL: 'MODULE',              BLK: 'SOUTH', },
        { SN: '112', AREA: 'TOPSIDE', ZONE: 'LQ',         LEVEL: 'MODULE',              BLK: 'LQ', },
      ],



    }
  }),
}