import Defs from '../../../../includes/primitives/Color_Defs'
import Data from '../../../../includes/primitives/Color_Data'

import * as d3 from 'd3'

export default {
  // Initial Data Set
  data: () => ({
    TableTotalHeight: 0,
    HeaderTotalWidth: 0,
    HeaderTotalHeight: 0,
    FooterMergeWidth: 0,

    clone_Header: [],
    comp_Columns: [],

    tableGroup: null,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.ChartType', this.ChartType)
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('Object.keys(this.Title).length', Object.keys(this.Title).length)
      // console.log('Object.keys(this.Table).length', Object.keys(this.Table).length)
      // console.log('this.Styles.length', this.Styles.length)
      // console.log('this.Columns.length', this.Columns.length)
      // console.log('this.Header.length', this.Header.length)

      return  this.ChartType &&
              this.DataItems.length > 0 &&
              Object.keys(this.Canvas).length > 0 &&
              Object.keys(this.Table).length > 0 &&
              this.Styles.length > 0 &&
              this.Header.length > 0
    },
  },

  methods: {
    init() {
      if(this.tableGroup) this.tableGroup.remove()

      this.TableTotalHeight = 0
      this.HeaderTotalWidth = 0
      this.HeaderTotalHeight = 0
      
      this.clone_Header = []
      this.comp_Columns = []
    },

    setDefaultValues() { 
      this.clone_Header = JSON.parse(JSON.stringify(this.Header))
      this.clone_Columns = JSON.parse(JSON.stringify(this.Columns))

       //####------------------ Modified ------------------###
      // Column Generator
      //  this.clone_Columns = []
      //  this.Header.filter(f => f.width > 0).forEach((d,i) => {
      //    this.clone_Columns.push({
      //      column: Object.keys(this.DataItems[0])[i],
      //      type : 'Text',
      //      series : 1,
      //      align : 'middle',
      //      linked : 'N',
      //      padding : 0
      //    })
      //  })

      this.Table.TableHeadHeight.forEach((value_) => {
        this.HeaderTotalHeight += value_
      })
      this.TableTotalHeight = this.HeaderTotalHeight + (this.DataItems.length * this.Table.TableCellHeight)

      this.Calculate_CellsWidth()
      
      // Set the Defs_Gradients for using the color of Gradients
      this.Styles.filter(f => f.codes).forEach((d) => {
        
        d.codes.forEach((c, i) => {
          if (c.bColor.substr(0,1) != '#' && c.bColor != '' && c.bColor != 'transparent') {
            Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', [c.bColor], [`${d.type}${d.series}${i}`, 0.5, 1.7, 0.5, -0.2], this.localId)
          }
        })
      })
    },
   
   
    


    Calculate_CellsWidth() {

      // x1, x2, cx, px 값을 구해서 clone_Header과 clone_Columns에 추가로 저장한다
      this.clone_Header.filter(f => f.width > 0).forEach((data,i) => {

        data.x1 = this.HeaderTotalWidth
        data.cx = data.x1 + (data.width/2)
        data.x2 = this.HeaderTotalWidth + data.width

        if (this.Table.TableFootMergeCell == i) this.FooterMergeWidth = this.HeaderTotalWidth

        if(!!this.clone_Columns[i]) {
          this.clone_Columns[i].x1 = this.HeaderTotalWidth
          this.clone_Columns[i].x2 = this.HeaderTotalWidth + data.width
          this.clone_Columns[i].width = data.width
          this.clone_Columns[i].cx = data.cx
          this.clone_Columns[i].height = this.Table.TableCellHeight

          if (this.clone_Columns[i].align == 'start')  this.clone_Columns[i].px = this.clone_Columns[i].x1 + this.Table.TableCellPadding
          if (this.clone_Columns[i].align == 'middle') this.clone_Columns[i].px = data.cx
          if (this.clone_Columns[i].align == 'end')    this.clone_Columns[i].px = this.clone_Columns[i].x2 - this.Table.TableCellPadding

          this.clone_Columns[i].cell = 'Y'
          this.clone_Columns[i].depth = data.depth
          this.clone_Columns[i].border = data.border
        }
        this.HeaderTotalWidth += data.width
      })


      // 1차적으로 colspan뒤 width값을 가지면 colspan의 갯수만큼 width의 합계를 구해 저장한다. 
      //그리고 x1, x2를 구해 저장한다.
      this.clone_Header.forEach((data,i) => {
          if (i < this.clone_Header.length-1 && data.colspan > 1 && this.clone_Header[i+1].colspan == 1) {
            data.width = this.clone_Header.slice(i+1, i + data.colspan + 1).map(d => d.width).reduce((a, b) => a+b)
            data.x1 = this.clone_Header[i+1].x1
            data.x2 = this.clone_Header[i + data.colspan].x2
            data.cx = data.x1 + (data.width/2)
          }
          // this.console(data.name, data.width, data.x1, data.x2, data.cx, data.y1, data.y2 ,data.cy) 
      })


      // 하위 Depth가 Group을 가지면 그 Group들의 width합을 구해 저장한다. 
      //그리고 x1, x2를 구해 저장한다.
      this.clone_Header.forEach((data) => {
        if (data.width == 0) {
          data.width = this.clone_Header.filter(d => d.group == data.group && d.depth == data.depth + 1).map(d => d.width).reduce((a, b) => a+b)
          data.x1 = Math.min(...this.clone_Header.filter(d => d.group == data.group && d.depth == data.depth + 1).map(d => d.x1))
          data.x2 = Math.max(...this.clone_Header.filter(d => d.group == data.group && d.depth == data.depth + 1).map(d => d.x2))
          data.cx = data.x1 + (data.width/2)
        }
        // this.console(data.name, data.width, data.x1, data.x2, data.cx, data.y1, data.y2 ,data.cy)
      })


      // y값을 구한다.
      this.clone_Header.forEach((data) => {
        if (data.depth == 1 && data.rowspan == 3) {
          data.y1 = 0
          data.y2 = this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1] + this.Table.TableHeadHeight[2]
          data.height = data.y2 - data.y1
          data.cy = 1 + (this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1] + this.Table.TableHeadHeight[2]) / 2
        }

        if (data.depth == 1 && data.rowspan == 2) {
          data.y1 = 0
          data.y2 = this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1]
          data.height = data.y2 - data.y1
          data.cy = 1 + (this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1]) / 2
        }

        if (data.depth == 1 && data.rowspan == 1) {
          data.y1 = 0
          data.y2 = this.Table.TableHeadHeight[0]
          data.height = data.y2 - data.y1
          data.cy = 1 + (this.Table.TableHeadHeight[0] / 2)
        }

        if (data.depth == 2 && data.rowspan == 2) {
          if(this.Table.TableHeadHeight.length < 2) return

          data.y1 = this.Table.TableHeadHeight[0]
          data.y2 = this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1] + this.Table.TableHeadHeight[2]
          data.height = data.y2 - data.y1
          data.cy = 1 + this.Table.TableHeadHeight[0] + ((this.Table.TableHeadHeight[1] + this.Table.TableHeadHeight[2]) / 2)
        }

        if (data.depth == 2 && data.rowspan == 1) {
          if(this.Table.TableHeadHeight.length < 2) return

          data.y1 = this.Table.TableHeadHeight[0]
          data.y2 = this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1]
          data.height = data.y2 - data.y1
          data.cy = 1 + this.Table.TableHeadHeight[0] + ((this.Table.TableHeadHeight[1]) / 2)
        }

        if (data.depth == 3 && data.rowspan == 1) {
          if(this.Table.TableHeadHeight.length < 3) return
          data.y1 = this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1]
          data.y2 = this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1] + this.Table.TableHeadHeight[2]
          data.height = data.y2 - data.y1
          data.cy = 1 + this.Table.TableHeadHeight[0] + this.Table.TableHeadHeight[1] + ((this.Table.TableHeadHeight[2]) / 2)
        }

        // this.console(data.name, data.width, data.x1, data.x2, data.cx, data.y1, data.y2 ,data.cy)
      })
    },
    






    //####------------------ Event Function ------------------###
    // Column Generator
    setColumnsWithData() {
      if(this.Table.AutoApplyToColumns != 'Y') {
        this.clone_Columns = JSON.parse(JSON.stringify(this.Columns))
        return  
      } 

      this.clone_Columns = []
      this.Header.filter(f => f.width > 0).forEach((d,i) => {
        this.clone_Columns.push({
          column: Object.keys(this.DataItems[0])[i],
          type : 'Text',
          series : 1,
          align : 'middle',
          linked : 'N',
          padding : 0
        })
      })
      this.$emit('column-changed', this.clone_Columns)
    },
  }
}