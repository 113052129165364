export default {
  data: () => ({
    rawData:  [],
    
    commons:      null,
    header:       null,
    table:        null,
    barChart:     null,
    columns:      null,

    filters:      null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData  = JSON.parse(JSON.stringify(this.DataItems))
      this.commons  = JSON.parse(JSON.stringify(this.Common))
      this.columns  = JSON.parse(JSON.stringify(this.DataCols.Columns))
      this.filters  = JSON.parse(JSON.stringify(this.DataCols.Filters))
      let types     = JSON.parse(JSON.stringify(this.CTypes))

      // Charts Type
      this.header   = types.header
      this.table    = types.table
      this.barChart = types.barChart
      this.columns[this.columns.length-1].width = parseInt(this.barChart.width)

      // Get Position
      let x_ = 0
      this.columns.forEach(d => {
        d.stX = x_ + this.table.cellSpace
        if (d.align == 'start') d.x = x_ + this.table.cellSpace
        if (d.align == 'middle') d.x = x_ + (d.width/2)
        if (d.align == 'end') d.x = x_ + d.width - this.table.cellSpace
        x_ = x_ + d.width
      })
      // console.log(this.columns)

      this.barChart.maxValue = Math.max(...this.rawData.map(d => d.TOTAL))
      this.barChart.maxValue = this.getMaxBound(this.barChart.maxValue)

      // Get Grid Height
      this.header.gridHeight = ((this.rawData.length+1) * this.table.lineHeight) + (this.table.lineHeight / 3)
      
    },


  }
}