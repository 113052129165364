import * as d3 from 'd3'
import { finished } from 'stream'

export default {
  methods: {
    // ICON
    draw_Taps() {
      if(this.ModalTaps.DisplayTabMenu != 'Y') return

      let modalTaps = this.ContainerGroup
      .append('g')
      .attr('transform', `translate(${this.ModalTaps.TapsX},${this.ModalTaps.TapsY})`)

      let distance = 0
      this.ModalTaps.Menus.forEach((d, i) => {
        let TapMenu = modalTaps
        .append('g')
        .attr('id', `menu_${i}`)
        .attr('transform', `translate(${distance},0)`)
        .on('mouseover', () => {
          if(!this.tabable(i)) return

          TapMenu.style('cursor', 'pointer')
          d3.select(`#textMain_${i}`).transition().duration(150).attr('fill', this.ModalTaps.Style[0].overColor)
          d3.select(`#textSub_${i}`).transition().duration(150).attr('fill', this.ModalTaps.Style[1].overColor)
          d3.select(`#textBullet_${i}`).transition().duration(150).attr('fill', `url(#${this.localId}__LinearA4_Over_Bullet0)`)
        })
        .on('mouseout', () => {
          if(!this.tabable(i)) return
          
          TapMenu.style('cursor', 'default')
          
          if(this.tabSelected == i) {
            // Remain selected style -----------------------------------------------------------------------
            d3.select(`#textMain_${i}`).attr('fill', this.ModalTaps.Style[0].selectedColor)
            d3.select(`#textSub_${i}`).attr('fill', this.ModalTaps.Style[1].selectedColor)
            d3.select(`#textBullet_${i}`).attr('fill', `url(#${this.localId}__LinearA4_Selected_Bullet0)`)
            // --------------------- -----------------------------------------------------------------------
          } else {
            d3.select(`#textMain_${i}`).transition().duration(150.).attr('fill', this.ModalTaps.Style[0].outColor)
            d3.select(`#textSub_${i}`).transition().duration(150.).attr('fill', this.ModalTaps.Style[1].outColor)
            d3.select(`#textBullet_${i}`).transition().duration(150.).attr('fill', `url(#${this.localId}__LinearA4_Out_Bullet0)`)
          }
        })
        .on('click', () => {
          if(!this.tabable(i)) return
          
          // Clear previous selected
          d3.select(`#textMain_${this.tabSelected}`).transition().duration(150.).attr('fill', this.ModalTaps.Style[0].outColor)
          d3.select(`#textSub_${this.tabSelected}`).transition().duration(150.).attr('fill', this.ModalTaps.Style[1].outColor)
          d3.select(`#textBullet_${this.tabSelected}`).transition().duration(150.).attr('fill', `url(#${this.localId}__LinearA4_Out_Bullet0)`)

          this.tabSelected = i
          
          // Reset selected mouseover
          d3.select(`#textMain_${i}`).transition().duration(150).attr('fill', this.ModalTaps.Style[0].overColor)
          d3.select(`#textSub_${i}`).transition().duration(150).attr('fill', this.ModalTaps.Style[1].overColor)
          d3.select(`#textBullet_${i}`).transition().duration(150).attr('fill', `url(#${this.localId}__LinearA4_Over_Bullet0)`)

          let request_ = {
            dataType 	  : 'row',
            action   		: this.ModalTaps.Menus[i].action,
            filters  		: this.FilterValues,
            iFilters 		: {
              filterString : this.ModalTaps.Menus[i].action.filterString || '',
              inputFilter  : ''
            }
          }
          this.$emit('request-action', request_)
        })

        // Bullets
        TapMenu
        .append('rect')
        .attr('id', `textBullet_${i}`)
        .attr('x', 0)
        .attr('y', 0)
        .attr('rx', this.ModalTaps.Bullet.radius)
        .attr('ry', this.ModalTaps.Bullet.radius)
        .attr('width', this.ModalTaps.Bullet.width)
        .attr('height', this.ModalTaps.Bullet.height)
        .attr('stroke', this.ModalTaps.Bullet.strokeColor)
        .attr('stroke-width', this.ModalTaps.Bullet.stroke)
        .attr('fill',  () => {
          if(this.TabStatus[i]) return this.tabSelected != i ? `url(#${this.localId}__LinearA4_Out_Bullet0)` : `url(#${this.localId}__LinearA4_Selected_Bullet0)`
          return '#ebebeb'
        })
        
        // Main Title
        TapMenu
        .append('text')
        .attr('id', `textMain_${i}`)
        .attr('x', this.ModalTaps.Style[0].x)
        .attr('y', this.ModalTaps.Style[0].y)
        .attr('class', this.ModalTaps.Style[0].class)
        .attr('font-size', this.ModalTaps.Style[0].size)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'hanging')
        .attr('fill', () => {
            if(this.TabStatus[i]) return this.tabSelected != i ? this.ModalTaps.Style[0].outColor : this.ModalTaps.Style[0].selectedColor
            return '#ebebeb'
        })
        .style('font-family', this.ModalTaps.Style[0].font)
        .text(d.mainTitle)

        // Sub Title
        TapMenu
        .append('text')
        .attr('id', `textSub_${i}`)
        .attr('x', this.ModalTaps.Style[1].x)
        .attr('y', this.ModalTaps.Style[1].y)
        .attr('class', this.ModalTaps.Style[1].class)
        .attr('font-size', this.ModalTaps.Style[1].size)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'hanging')
        .attr('fill', () => {
          if(this.TabStatus[i]) return this.tabSelected != i ? this.ModalTaps.Style[1].outColor : this.ModalTaps.Style[1].selectedColor
          return '#ebebeb'
        })
        .style('font-family', this.ModalTaps.Style[1].font)
        .text(d.subTitle)

        TapMenu
        .append('rect')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', this.getNodeElValue(`#menu_${i}`,'width'))
        .attr('height', this.ModalTaps.Bullet.height)
        .attr('opacity', 0)
        .attr('fill',  '#fff')

        distance += this.getNodeElValue(`#menu_${i}`,'width') + this.ModalTaps.MenuSpace
      })

    },
  }
}
