<template>
  <v-dialog v-model="opened" width="500" class="j_picker">
    <template v-slot:activator="{ on }">
      <slot name="action" :action="{ on: on, open: onOpen }">
        <!-- Color Set Button -->
        <div v-if="multi" class="picker type_colorDecision">
          <span v-if="title" class="label">{{ title }}</span>
          <div v-on="on" @click="onOpen" class="color_decision">
            <div class="wrap_color_multi">
              <div v-for="(color, i) in value" :key="color.index" class="color_multi">
                <small>{{ i+1 }}</small>
                <span v-if="gradationType" :class="`${gradationType}_${color}`"></span>
                <span v-else :style="`background-color: ${color}`"></span>
              </div>
              <div v-if="!value || value.length === 0" class="color_multi">
                <small>1</small>
                <span></span>
              </div>
            </div>
          </div>
        </div>
        <!-- Single Button -->
        <div v-else class="picker" :class="!title ? 'just_box' : ''">
          <span v-if="title" class="label">{{ title }}</span>
          <v-btn v-if="gradationType" v-on="on" class="type16" :class="`${gradationType}_${value}`" @click="onOpen"></v-btn>
          <v-btn v-else v-on="on" class="type16" :class="{ transparent: value == 'transparent' }" :style="`background-color: ${value}`" @click="onOpen"></v-btn>
        </div>
      </slot>
    </template>

    <v-card class="color_palette">
      <v-card-title class="modal__header type_gray">
        <span>Color Picker</span>
        <button class="modal_close" @click="opened = false">
          <v-icon>mdi-close</v-icon>
        </button>
      </v-card-title>
      <v-card-text>
        <!-- ColorSet(Multi) Selector's Header -->
        <div v-if="multi" class="color_output top_area">
          <small v-if="tabSelected == 'gradations'" class="gradations_title">{{ colorGradTypeName }}</small>
          <div
            v-for="(color, i) in colorSet"
            data-color-output
            :key="color.index"
            :class="{ selected: i == colorSetIndex }"
          >
            <small>{{ i+1 }}</small>
            <a
              v-if="tabSelected == 'gradations'"
              :class="`${gradationType}_${color}`"
              @click="setColorSetIndex(i)"
            />
            <a v-else :style="`background-color: ${color}`" @click="setColorSetIndex(i)" />
            <span class="type_close" @click="removeColor(i)"></span>
          </div>
        </div>

        <!-- Tab Header -->
        <div class="color_tab" :class="{ top_area: !multi }">
          <a
            v-for="tab in tabs"
            :key="tab.index"
            :class="{ selected: tabSelected == tab.val }"
            @click="toggle(tab.val)"
          >{{ tab.text }}</a>
        </div>

        <!-- Tabs -->
        <div class="color_contents">
          <div :data-color-contents="tabSelected">
            <!-- Selected Color Preview -->
            <div class="color_pick">
              <div class="realtime">
                <span v-if="tabSelected == 'gradations'" :class="colorGradClassName"></span>
                <span v-else :style="`background-color: ${ colorPicked };`"></span>
                <small>{{ colorPicked }}</small>
              </div>
              <div class="etc">
                <span class="type_transparent" @click="colorPicker('transparent')"></span>
              </div>
            </div>

            <!-- Color Pallet for Gradation -->
            <div v-if="tabSelected == 'gradations'" class="color_set type_gradations">
              <div v-for="type in colorPallet[tabSelected].type" :key="type.index" data-colorset>
                <small>{{ type.text }}</small>
                <div class="color_set_gradations">
                  <span
                    v-for="color in colorPallet[tabSelected].colorset"
                    :class="`${type.code}_${color}${classColorSelected(color, type.code) ? ` ${classColorSelected(color, type.code)}` : ''}`"
                    :key="color.index"
                    @click="colorPicker(color, type.code)"
                  ></span>
                </div>
              </div>
            </div>

            <!-- Color Pallet for Normal (Standard or Full Colors) -->
            <div v-else class="color_set">
              <div v-for="colorset in colorPallet[tabSelected]" :key="colorset.index" data-colorset>
                <span
                  v-for="color in colorset"
                  :class="classColorSelected(color)"
                  :key="color.index"
                  :style="`background-color: ${color};`"
                  @click="colorPicker(color)"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <j-button class="type01 sky" @click="setColor">Set</j-button>
        <j-button class="type01" @click="opened = false">Cancel</j-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ColorName2Hex from '@/primitives/_colorNameMapper'
export default {
  name: "j-color-picker",
  props: {
    buttonText: String,
    colorAffected: { type: String, default: 'background-color' },
    multi: { type: Boolean, default: false },
    gradation: { type: Boolean, default: false },
    gradationType: String,
    title: {
      type: String,
      default: ""
    },
    value: null,
  },
  data: () => ({
    opened: false,
    tabSelected: 'standard',
    tabs: [],
    tabName: '',
    colorPallet: {
      standard: [
        ['#ff9e80', '#ffd180', '#ffe57f', '#ffff8d', '#f4ff81', '#ccff90', '#b9f6ca', '#a7ffeb', '#84ffff', '#80d8ff', '#82b1ff', '#8c9eff', '#b388ff', '#ea80fc', '#ff80ab', '#ff8a80'],
        ['#ff6e40', '#ffab40', '#ffd740', '#ffff00', '#eeff41', '#b2ff59', '#69f0ae', '#64ffda', '#18ffff', '#40c4ff', '#448aff', '#536dfe', '#7c4dff', '#e040fb', '#ff4081', '#ff5252'],
        ['#ff3d00', '#ff9100', '#ffc400', '#ffea00', '#c6ff00', '#76ff03', '#00e676', '#1de9b6', '#00e5ff', '#00b0ff', '#2979ff', '#3d5afe', '#651fff', '#d500f9', '#f50057', '#ff1744'],
        ['#dd2c00', '#ff6d00', '#ffab00', '#ffd600', '#aeea00', '#64dd17', '#00c853', '#00bfa5', '#00b8d4', '#0091ea', '#2962ff', '#3d5afe', '#6200ea', '#aa00ff', '#c51162', '#d50000'],
        ['#000000', '#263238', '#37474f', '#455a64', '#546e7a', '#607d8b', '#78909c', '#90a4ae', '#b0bec5', '#cfd8dc', '#eceff1', '#ffffff'],
        ['#212121', '#424242', '#616161', '#757575', '#9e9e9e', '#bdbdbd', '#e0e0e0', '#eeeeee', '#f5f5f5', '#fafafa'],
        ['#3e2723', '#4e342e', '#5d4037', '#6d4c41', '#795548', '#8d6e63', '#a1887f', '#bcaaa4', '#d7ccc8', '#efebe9'],
      ],
      full: [
        ['#fbe9e7', '#fff3e0', '#fff8e1', '#fffde7', '#f9fbe7', '#f1f8e9', '#e8f5e9', '#e0f2f1', '#e0f7fa', '#e1f5fe', '#e3f2fd', '#e8eaf6', '#e0e0df', '#f3e5f5', '#fce4ec', '#ffebee', '#ffffff'],
        ['#ffccbc', '#ffe0b2', '#ffecb3', '#fff9c4', '#f0f4c3', '#dcedc8', '#c8e6c9', '#b2dfdb', '#b2ebf2', '#b3e5fc', '#bbdefb', '#c5cae9', '#d1c4e9', '#e1bee7', '#f8bbd0', '#ffcdd2'],
        ['#ffab91', '#ffcc80', '#ffe082', '#fff59d', '#e6ee9c', '#c5e1a5', '#a5d6a7', '#80cbc4', '#80deea', '#81d4fa', '#90caf9', '#9fa8da', '#b39ddb', '#ce93d8', '#f48fb1', '#ef9a9a'],
        ['#ff8a65', '#ffb74d', '#ffd54f', '#fff176', '#dce775', '#aed581', '#81c784', '#4db6ac', '#4dd0e1', '#4fc3f7', '#64b5f6', '#7986cb', '#9575cd', '#ba68c8', '#f06292', '#e57373'],
        ['#ff7043', '#ffa726', '#ffca28', '#ffee58', '#d4e157', '#9ccc65', '#66bb6a', '#26a69a', '#26c6da', '#29b6f6', '#42a5f5', '#5c6bc0', '#7e57c2', '#ab47bc', '#ec407a', '#ef5350'],
        ['#ff5722', '#ff9800', '#ffc107', '#ffeb3b', '#cddc39', '#8bc34a', '#4caf50', '#009688', '#00bcd4', '#03a9f4', '#2196f3', '#3f51b5', '#673ab7', '#9c27b0', '#e91e63', '#f44336'],
        ['#f4511e', '#fb8c00', '#ffb300', '#fdd835', '#c0ca33', '#7cb342', '#43a047', '#00897b', '#00acc1', '#039be5', '#1e88e5', '#3949ab', '#5e35b1', '#8e24aa', '#d81b60', '#e53935'],
        ['#e64a19', '#f57c00', '#ffa000', '#fbc02d', '#afb42b', '#689f38', '#388e3c', '#00796b', '#0097a7', '#0288d1', '#1976d2', '#303f9f', '#512da8', '#7b1fa2', '#c2185b', '#d32f2f'],
        ['#d84315', '#ef6c00', '#ff8f00', '#f9a825', '#9e9d24', '#558b2f', '#2e7d32', '#00695c', '#00838f', '#0277bd', '#1565c0', '#283593', '#4527a0', '#6a1b9a', '#ad1457', '#c62828'],
        ['#bf360c', '#e65100', '#ff6f00', '#f57f17', '#827717', '#33691e', '#1b5e20', '#004d40', '#006064', '#01579b', '#0d47a1', '#1a237e', '#311b92', '#4a148c', '#880e4f', '#b71c1c'],
      ],
      gradations: {
        colorset: ['LightGray', 'Gray', 'Yellow', 'Gold', 'Orange', 'Pink', 'Violet', 'Red', 'Thistle', 'Purple', 'Indigo', 'LightBlue', 'SkyBlue', 'SteelBlue', 'YellowGreen', 'DarkGreen'],
        type: [
          { text: 'Linear A2', code: 'LinearA2' },
          { text: 'Linear A4', code: 'LinearA4' },
          { text: 'Linear A9', code: 'LinearA9' },
        ],
      }
    },
    color: '',
    colorSet: [],
    colorSetIndex: -1,
    colorPicked: '',
    colorGradType: '',
    colorGradClassName: '',
  }),
  computed: {
    isGradated() {
      if (this.value.length === 0) return false
      if (this.value.indexOf('#') < 0) return true
      return false
    },
    colorGradTypeName() {
      if (!this.colorGradType) return ''
      return this.colorPallet.gradations.type.find(t => t.code == this.colorGradType).text
    }
  },
  methods: {
    init() {
      this.color = ''
      this.colorSet = Array.from({ length: 16 }, () => '')
      this.colorSetIndex = -1
      this.colorPicked = ''
    },

    classColorSelected(color, type) {
      if(!this.value) return

      if(this.multi) {
        if(type) var classSelected_ = (this.value.findIndex(color_ => color_ == color) >= 0 && this.gradationType == type ? 'selected' : '')
        else classSelected_ = (this.value.findIndex(color_ => color_ == color) < 0 ? '' : 'selected')
      } else {
        if(type) classSelected_ = (this.value == color && this.gradationType == type ? 'selected' : '')
        else classSelected_ = (this.value == color ? 'selected' : '')
      }

      return classSelected_
    },
    checkColorSet(colorSet) {
      let gradated = this.colorPallet.gradations.type.findIndex(type => type.code == this.gradationType) >= 0

      if(gradated) {
        // using a sample value, Check the colorSet value is right name
        if(!ColorName2Hex.setGradients.Represent(colorSet[0])) this.$emit('gradate', '')

      } else {
        if(ColorName2Hex.setGradients.Represent(colorSet[0])) {
          colorSet.forEach((colorName, i) => { colorSet[i] = ColorName2Hex.Represent(colorSet[i]) })
          this.$emit('input', colorSet)
        }
      }
    },
    colorPicker(val, type) {
      this.colorPicked = val
      this.colorGradType = type
      this.colorGradClassName = `${type}_${val}`

      if(this.multi) {
        if (this.colorSetIndex > -1) this.colorSet[this.colorSetIndex] = val
      } else this.color = val
    },
    onOpen() {
      this.init()
      this.tabs = [
        { text: 'Standard', val: 'standard' },
        { text: 'Full Colors', val: 'full' },
      ]
      if(this.gradation) this.tabs.push({ text: 'Gradations', val: 'gradations' })

      if(this.multi) {
        if (this.value.length < 0) return

        let value_ = JSON.parse(JSON.stringify(this.value))
        this.checkColorSet(value_)

        value_.forEach((v, i) => { this.colorSet[i] = v })

        if(this.gradationType) {
          this.tabSelected = 'gradations'
          this.colorGradType = this.gradationType
        }

      } else this.color = this.value
    },
    removeColor(index) {
      let colorSet_ = Array.from({ length: 16 }, () => '')

      this.colorSet[index] = ''
      this.colorSet.filter(v => !!v).forEach((v, i) => { colorSet_[i] = v })

      this.colorSet = colorSet_
    },
    setColor() {
      this.opened = false
      this.$emit('input', this.multi ? this.colorSet.filter(v => !!v) : this.color)
      this.$emit('gradate', this.tabSelected == 'gradations' ? this.colorGradType : '')
    },
    setColorSetIndex(index) {
      this.colorSetIndex = index
    },
    toggle(val) {
      if(this.tabSelected == val) return

      if(this.multi) {
        if(this.tabSelected == 'gradations') {
          let colorSet_ = this.colorSet.filter(v => !!v).map(color => ColorName2Hex.setGradients.Represent(color))
          colorSet_.forEach((color, i) => { this.colorSet[i] = color })

          this.colorGradType = ''
          this.$emit('gradate', '')
          this.$emit('input', colorSet_)

        } else if(val == 'gradations') {
          let colorSet_ = this.colorSet.filter(v => !!v).map((_, i) => this.colorPallet.gradations.colorset[i])
          colorSet_.forEach((color, i) => { this.colorSet[i] = color })

          this.colorGradType = this.colorPallet.gradations.type[0].code
          this.$emit('gradate', this.colorGradType)
          this.$emit('input', colorSet_)
        }
      }

      this.tabSelected = val
    },
  }
}
</script>