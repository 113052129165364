import * as d3 from 'd3'

export default {
  data: () => ({
    rawData: null,
    filters: null,
    
    Header:   {},
    Group:    {},
    Value:    {},
    Column:   {},
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    
    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.filters = JSON.parse(JSON.stringify(this.Queries.SQL2[0]))

      this.Header = {
        height:       sql1.HEADER_HEIGHT,
        tColor:       sql1.HEADER_COLOR,
        tSize:        sql1.HEADER_SIZE,
        tWeight:      sql1.HEADER_WEIGHT,
      }
      
      this.Group = {

        refCode:      Object.keys(this.rawData[0])[0],
        refCode2:     Object.keys(this.rawData[0])[1],
        name:        [...new Set(this.rawData.map(d => d[Object.keys(this.rawData[0])[0]]))],
        padding:      sql1.GROUP_PADDING,
        margin:       sql1.GROUP_MARGIN,
        
        title: {
          tColor:       sql1.TITLE_COLOR.         replace(/\s/g, '').split('/'),
          tSize:        sql1.TITLE_SIZE.          replace(/\s/g, '').split('/').map(Number),
          tWeight:      sql1.TITLE_WEIGHT.        replace(/\s/g, '').split('/').map(Number),
          jsonMap:      sql1.TITLE_JSON_MAP,
        },
        
        header: {
          lineHeight: sql1.HEADER_HEIGHT,
          tColor:     sql1.HEADER_FONT_COLOR,
          tSize:      sql1.HEADER_FONT_SIZE,
          tWeight:    sql1.HEADER_FONT_WEIGHT,
        },

        box: {
          width:        0,
          height:       [],
          fColor:       sql1.BOX_FILL_COLOR.          replace(/\s/g, '').split('/'),
          fOpacity:     sql1.BOX_FILL_OPACITY.        replace(/\s/g, '').split('/').map(Number),
          sWidth:       sql1.BOX_STROKE_WIDTH.        replace(/\s/g, '').split('/').map(Number),
          sColor:       sql1.BOX_STROKE_COLOR.        replace(/\s/g, '').split('/'),
          sOpacity:     sql1.BOX_STROKE_OPACITY.      replace(/\s/g, '').split('/').map(Number),
          radius:       sql1.BOX_RADIUS.              replace(/\s/g, '').split('/').map(Number),
        },

        fColor:       sql1.BOX_FILL_COLOR.            replace(/\s/g, '').split('/'),
        fOpacity:     sql1.BOX_FILL_OPACITY.          replace(/\s/g, '').split('/').map(Number),
        sWidth:       sql1.BOX_STROKE_WIDTH.          replace(/\s/g, '').split('/').map(Number),
        sColor:       sql1.BOX_STROKE_COLOR.          replace(/\s/g, '').split('/'),
        sOpacity:     sql1.BOX_STROKE_OPACITY.        replace(/\s/g, '').split('/').map(Number),
        radius:       sql1.BOX_RADIUS.                replace(/\s/g, '').split('/').map(Number),
      }

      this.Group.name.forEach((d,i) => {
        let no_ = this.rawData.filter(f=> f[this.Group.refCode] == d).length
        this.Group.box.height[i] = (no_ * sql1.VALUE_LINEHEIGHT) + this.Header.height + this.Group.padding + (this.Group.padding/2)
      })

      this.Value = {
        padding:      sql1.VALUE_PADDING,
        height:       sql1.VALUE_LINEHEIGHT,
        tSize:        sql1.VALUE_SIZE,
        baseY:        sql1.VALUE_BASE_Y,
        tWeight:      sql1.VALUE_WEIGHT,
      }

      this.Column = {
        refCode:      sql1.REF_CODE.          replace(/\s/g, '').split('/'),
        title:        [],
        width:        sql1.WIDTH.             replace(/\s/g, '').split('/').map(Number),
        tColor:       sql1.TEXT_COLOR.        replace(/\s/g, '').split('/'),
        align:        sql1.ALIGN.             replace(/\s/g, '').split('/'),
        format:       sql1.FORMAT.            replace(/\s/g, '').split('/'),
        jsonMap:      sql1.JSON_MAP.          replace(/\s/g, '').split('/'),
        jsonLink:     sql1.JSON_LINK.         replace(/\s/g, '').split('/'),
        filter:       sql1.FILTER.            replace(/\s/g, '').split('/'),
        position:     [],
      }

      // Calculate - this.Column.position
      let x_ = this.Group.padding
      this.Column.width.forEach( (d,i)=> {
        if (this.Column.align[i] == 'start') this.Column.position.push(x_ + this.Value.padding)
        if (this.Column.align[i] == 'middle') this.Column.position.push(x_ + (Number(d)/2))
        if (this.Column.align[i] == 'end') this.Column.position.push(x_ + Number(d) - this.Value.padding)
        x_ = x_ + Number(d)
      })
      this.Group.box.width = x_ + this.Group.padding

      this.Group.name.forEach((d,i) => {
        this.Column.title.push(
          {name:      sql1[`TITLE_${i+1}`].            replace(/\s/g, '').split('/')}
        )
      })
    },

  }
}