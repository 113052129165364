import * as d3 from 'd3'

export default {
  methods: {

    tube_Chart(selection, scale) {
      let tube =  this.tube
      let cols = this.columns
      let pSize =   this.columns.find(f=> f.code == 'PROG').tSize
      let prog =  this.rawData.PROG


      // Reflected Effect ----------------------------------------------------------------
      selection // Reflected Box
      .append('rect')
      .attr('transform', `translate(0,${tube.height + tube.base})`)
      .attr('width', tube.tickness).attr('height', tube.base).attr('opacity', '0.75').attr('fill', '#BDBCBC')

      selection // Transparent Gradation Area
      .append('rect')
      .attr('transform', `translate(${-tube.tickness / 2},${tube.height + tube.base})`)
      .attr('width', tube.tickness * 2.5).attr('height', tube.base + 2).attr('fill', `url(#${this.localId}__Color_Reflection)`)

      selection // Shadow
      .append('ellipse')
      .attr('cx', tube.tickness / 4).attr('cy', tube.height + tube.base)
      .attr('rx', (tube.tickness * 1.5) / 2).attr('ry', (tube.tickness * 1.5) / 3 / 2).attr('fill', `url(#${this.localId}_Shadow)`)


      // Cylinder Progress ----------------------------------------------------------------
      selection // Series Bottom Box
      .append('rect')
      .attr('transform', `translate(0,${tube.height})`)
      .attr('width', tube.tickness).attr('height', tube.base).attr('fill', `url(#${this.localId}__TubeBase)`)

      selection // Series Bottom Cap Bottom
      .append('ellipse')
      .attr('cx', tube.tickness / 2) .attr('cy', tube.height + tube.base)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}__TubeBase)`)

      // Progress 
      selection
      .append('text')
      .attr('transform', `translate(${tube.tickness/2},${tube.height + (tube.base/2) + (tube.tickness/6)})`)
      .style('font-size', cols[3].tSize)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .attr('fill', cols[3].tColor)
      .text(this.set_Value(cols[3].format, prog))


      // Tube Cylinder------------------------------------------------------------------------
      selection
      .append('rect') // Tube Cylinder Box
      .attr('width', tube.tickness).attr('height', tube.height).attr('fill', `url(#${this.localId}__TubeBox)`).attr('opacity', 0.7)

      selection // Bottom Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', tube.height)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', '#E0E0DF').attr('opacity', 0.9)

      selection // Top Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', 0)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}__TubeCap)`)

      selection
      .append('path') // Cyinder Box
      .attr('d', `
      M ${0} ${scale(0)}
      V ${scale(0)}
      C 0 ${((tube.tickness/3/2)*1.25) + scale(0) } ${tube.tickness} ${((tube.tickness/3/2)*1.25) + scale(0) } ${tube.tickness} ${scale(0) } 
      V ${scale(0)} 
      `)
      .attr('fill', `url(#${this.localId}_${tube.color}_1)`)
      
      .attr('opacity', .9)
      .transition()
      .duration(500)
      .attr('d', () => {
      let h_ = scale(prog)
      let hy = scale(0) 

      return `
      M ${0} ${hy}
      V ${scale(prog)}
      C 0 ${((tube.tickness/3/2)*1.25) + h_} ${tube.tickness} ${((tube.tickness/3/2)*1.25) + h_} ${tube.tickness} ${h_} 
      V ${scale(0)} 
      `
      })
    
      selection // Bottom Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', tube.height)
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}_${tube.color}_1)`).attr('opacity', 1)

      selection // Top Cap
      .append('ellipse')
      .attr('cx', tube.tickness / 2).attr('cy', scale(0))
      .attr('rx', tube.tickness / 2).attr('ry', tube.tickness / 3 / 2).attr('fill', `url(#${this.localId}_${tube.color}_0)`).attr('opacity', 1)
      .transition().duration(500).attr('cy', scale(prog))

    },
    
    tube_Values(selection, scale) {
      let tube = this.tube
      let header = this.texts.header
      let cols = this.columns

      let prog =  this.rawData.PROG

      // Grid
      let line = [
        {x1: tube.tickness + 5, y1: tube.height, x2: tube.tickness + 20, y2: tube.height,},
        {x1: tube.tickness + 5, y1: tube.height, x2: tube.tickness + 20, y2: tube.height,},
        {x1: tube.tickness + 5, y1: scale(prog), x2: tube.tickness + 20, y2: scale(prog),},
        {x1: tube.tickness + 5, y1: 0, x2: tube.tickness + 20, y2: 0,},
        {x1: tube.tickness + 15, y1: 0, x2: tube.tickness + 15, y2: tube.height,},
      ]
      line.forEach(d=> {
        selection
        .append('line')
        .attr('x1', d.x1).attr('y1', d.y1).attr('x2', d.x2).attr('y2', d.y2)
        .style('stroke', '#bcbcbc').style('stroke-width', 1).style('stroke-opacity', .5)
      })
      
      // TOTAL
      let d = cols[0]
      selection
      .append('text')
      .attr('id', 'Tube_Total')
      .attr('transform', `translate(${tube.tickness/2},${scale(100)-tube.tickness/3})`)
      .style('font-size', d.tSize)
      .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
      .attr('fill', d.tColor)
      .attr('cursor', decodeURI.link == 'Y' ? 'pointer' : 'default')
      .text(this.set_Value(d.format, d.value))
      .call(this.call_LINK, d)

      // ACTUAL
      d = cols[1]
      selection
      .append('text')
      .attr('id', `Tube_Actual_${this.localId}`)
      .attr('transform', `translate(${tube.tickness + 25},${scale(prog/2)})`)
      .style('font-size', d.tSize)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', d.tColor)
      .attr('cursor', d.link == 'Y' ? 'pointer' : 'default')
      .text(this.set_Value(d.format, d.value))
      .call(this.call_LINK, d)

      // REMAIN
      d = cols[2]
      selection
      .append('text')
      .attr('id', `Tube_Remain_${this.localId}`)
      .attr('transform', `translate(${tube.tickness + 25},${scale(prog + (100-prog)/2)})`)
      .style('font-size', d.tSize)
      .style('font-family', 'roboto')
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .attr('fill', d.tColor)
      .attr('cursor', d.link == 'Y' ? 'pointer' : 'default')
      .text(this.set_Value(d.format, d.value))
      .call(this.call_LINK, d)
    
      // Name
      if(header.visible == 'Y') {

        let actual = this.getNodeElValue(`#Tube_Actual_${this.localId}`, 'width')
        let remain = this.getNodeElValue(`#Tube_Remain_${this.localId}`, 'width')
        selection
        .append('text')
        .attr('transform', `translate(${tube.tickness/2},${scale(100)-tube.tickness/3 - cols[0].tSize-2})`)
        .style('font-size', header.tSize)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
        .attr('fill', header.tColor)
        .attr('cursor', 'default')
        .text(cols[0].head)

        selection
        .append('text')
        .attr('transform', `translate(${actual + 3 + tube.tickness + 25},${scale(prog/2)})`)
        .style('font-size', header.tSize)
        .style('font-family', 'roboto')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .attr('fill', header.tColor)
        .attr('cursor', 'default')
        .text(cols[1].head)

        selection
        .append('text')
        .attr('transform', `translate(${remain + 3 + tube.tickness + 25},${scale(prog + (100-prog)/2)})`)
        .style('font-size', header.tSize)
        .style('font-family', 'roboto')
        .attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
        .attr('fill', header.tColor)
        .attr('cursor', 'default')
        .text(cols[2].head)
      }

    },
  }
}