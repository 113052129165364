import * as d3 from 'd3'
import { finished } from 'stream'

export default {
  methods: {
    // Bar Chart
    draw_BarChart(selection, d, c, py, ly, value) {
      let styleSet = this.Styles.find(f => f.type == 'Bar' && f.series == this.clone_Columns[c].series)
      let codeSet = styleSet.codes.find(f => f.code == this.DataItems[d][styleSet.refColumn])
      let codeNo = styleSet.codes.findIndex(f => f.code == this.DataItems[d][styleSet.refColumn])

      let limited = styleSet.bounds
      let len = (this.DataItems[d][styleSet.dataColumn] / 100) * styleSet.barLength
      let barPadding = this.Table.TableCellHeight - styleSet.barThickness
      
      selection
      .append('rect')
      .attr('x', this.clone_Columns[c].x1+1).attr('y', ly + (barPadding/2))
      .attr('rx', styleSet.barRadius).attr('ry', styleSet.barRadius)
      .attr('width', 0).attr('height', this.Table.TableCellHeight - barPadding)
      .attr('stroke-width',0)
      .attr('fill', () => {
        if (codeSet.bColor.substr(0,1) == '#') {
          return codeSet.bColor
        } else {
          return `url(#${this.localId}__LinearA4${styleSet.type}${styleSet.series}${codeNo}0)`
        }
      })
      .transition().duration(500).attr('width', len)

      selection
      .append('text')
      .attr('x', this.clone_Columns[c].x1)
      .attr('y', py)
      .style('font-family', this.Styles.find(f => f.type == 'Text').textFont)
      .attr('class', this.Styles.find(f => f.type == 'Text').textStyle)
      .attr('font-size', styleSet.textSize)
      .attr('fill', () => {
        if (styleSet.textPosition == 'inside') {
          if (len >= limited) return codeSet.tColor; else return this.Styles.find(f => f.type == 'Text').tColor
        } else {
          return this.Styles.find(f => f.type == 'Text').tColor
        }
      })
      .attr('text-anchor', () => {
        if (styleSet.textPosition == 'inside') {
          if (len >= limited) return 'end'; else return 'start'
        } else {
          return 'start'
        }
      })
      .attr('alignment-baseline', 'middle')
      .text(`${value.toFixed(styleSet.round)}${styleSet.unit}`)
      .transition().duration(500)
      .attr('x', () => {
        
        if (styleSet.textPosition == 'inside') {
          if (len >= limited) return this.clone_Columns[c].x1 + len - 2; else return this.clone_Columns[c].x1 + len + 2
        } else {
          return this.clone_Columns[c].x1 + len + 2
        }
      })
    },


  }
}
