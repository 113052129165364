import * as d3 from 'd3'

export default {
  methods: {

    /*
      call_Search()
      call_SCurve()
      link_Skyline()
      filtering_Search()
    */

    call_Search(input=null) {
      
      let data_List = JSON.parse(JSON.stringify(this.rawData))
      this.search.results = []

      // apply search input ------------------------
      if(input != null) this.search.input = input
      input = input.toLowerCase()
      
      this.search.results = input ? data_List.filter(f => this.search.targets.some(col => f[this.skyline.refColumn].toLowerCase().includes(input))) : []
      this.filtering_Search(this.legends.currentStatus) // ---> below
    },





    link_Skyline(code_) {
      console.log(code_)

      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible 

      dataSource = {[`KEY1`]: code_}
    
      // ********************************************************************************
      // console.log('dataSource: ', dataSource)11
      // let filterTesting = d_.filter !== '' ? this.filters[d_.filter] : ''
      // console.log('filterString_: ', filterTesting)
      // ********************************************************************************

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps('request', dataSource, this.ColumnProps)
      if(!request_) return


      // set for filterString 
      let filterString_ = ''


      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }


      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },



    filtering_Search(status) {

      this.rawData.forEach(d => {
        d.visibility = 'N'

        if (this.search.input == '') {
          if((status == 'TOTAL' || status == d[this.skyline.refStatus])) d.visibility = 'Y'

        } else {
          if(
              this.search.results.find(f => f[this.skyline.refColumn] == d[this.skyline.refColumn]) && 
              (status == 'TOTAL' || status == d[this.skyline.refStatus])
            ) d.visibility = 'Y'
        }
      })

      // Apply visibility based on preprocessed data
      this.rawData.forEach(d => {
        d3.select(`#BOX_${d[this.skyline.refColumn]}`)
        .attr('visibility', d.visibility == 'Y' ? 'visible' : 'hidden')
      })

      if (this.search.input || (status !== null && status !== 'TOTAL'))  {
        d3.selectAll('.block_group_count')
        .attr('visibility', 'hidden')
      } else {
        d3.selectAll('.block_group_count')
        .attr('visibility', 'visible')
      }
    },
  }
}