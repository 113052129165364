
export default {
  data: () => ({

    localData: {
      // Skyline
      JSON1: [ 
        {      
          BOX_WIDTH:              29,
          BOX_HEIGHT:             14,
          BOX_GAP:                2, 
          BOX_TEXT_SIZE:          9,

          // Legends 
          LEGEND_X:               45,
          LEGEND_Y:               80,
          BAR_HEIGHT:             75,
        }
      ],


      // Checksheet
      JSON2: [ 
        {
          TITLE:            ' Cutting   / FAB.       / Painting  / 3rd-PE    / Erection  ',
          JPG:              '           /            / A_        / C_        / D_        ',
          COLUMN:           ' FAB       / FAB        / PE1       / PE3       / EREC      ',
          REF:              ' SC_PF     / FAB_PF     / PT_PF     / PE3_PS    / ER_PS     ',
          PRO:              ' SC_PRO    / PT_PRO     / PT_PRO    / PE3_PRO   / ER_PRO    ',
          COLOR:            ' #FEED57   / #83D2F5    / #F7BACF   / #CDDC37   / #8BC248   ',
          WIDTH:            ' 29        / 29         / 29        / 29        / 27        ',
          START:            ' 2         / 4          / 4         / 4         / 1         ',
          END:              ' 2         / 4          / 4         / 4         / 1         '
        }
      ],


      
    }
  }),
}