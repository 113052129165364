import * as d3 from 'd3'

import Data from '../../../../includes/primitives/Color_Data'

export default {
  methods: {
    //------------------------------------------------------------------ drawLegends
    drawLegendsCheck() {
      if (this.ChartType.includes('Group') || this.ChartType.includes('Stack')) this.drawLegendsLocal()
      else this.drawLegends() // global
    },
    drawLegendsLocal() {
      if (this.Legends.LegendDisplay != 'Y') return

      // ---------------------------------------------------------------------------------------------------- Legends
      let legendGroup = this.svg
        .append('g')
        .attr('id', 'LegendX$LegendY')
        .attr('class', 'legend_wrapper')
        .attr('transform', `translate(${this.Legends.LegendX},${this.Legends.LegendY})`)
        .call(this.el2Drag)
        .call(this.setCursorMovable)

      if (this.Legends.LegendTitleDisplay == 'Y') {
        legendGroup // Title
          .append('text')
          .attr('transform', `translate(0,-10)`)
          .attr('class', this.Legends.LegendTitleStyle)
          .style('font-size', this.Legends.LegendTitleSize)
          .style('font-family', this.Legends.LegendTitleFont)
          .attr('fill', this.Legends.LegendTitleColor)
          .attr('text-anchor', 'start')
          .text('Legends')
      }

      let keys_ = Object.keys(this.DataItems[0]).slice(1)

      let legendItem = legendGroup
        .selectAll('g')
        .data(keys_)
        .enter()
        .append('g')
        .attr('id', (_, i) => `legend_series_item_group_${i}`)

      if (this.Legends.LegendBulletDisplay == 'Y') {
        if (this.Legends.LegendBulletType == 'square') {
          legendItem
            .append('rect')
            .attr('width', this.Legends.LegendBulletSize)
            .attr('height', this.Legends.LegendBulletSize)
            .attr('fill', (_, i) => this.SharedColorSet[i])
        } else {
          legendItem
            .append('circle')
            .attr('cx', this.Legends.LegendBulletSize / 2)
            .attr('cy', this.Legends.LegendBulletSize / 2)
            .attr('r', this.Legends.LegendBulletSize / 2)
            .attr('fill', (_, i) => this.SharedColorSet[i])
        }
      }

      legendItem // SERIES
        .append('text')
        .attr('id', (_, i) => `SeriesText${i}`)
        .attr(
          'transform',
          `translate(${this.Legends.LegendBulletSize + this.Legends.LegendBulletSpace}, 
          ${this.Legends.LegendBulletSize / 2 + this.Legends.LegendSeriesSize / 10})`
        )
        .attr('class', this.Legends.LegendSeriesStyle)
        .style('font-size', this.Legends.LegendSeriesSize)
        .style('font-family', this.Legends.LegendSeriesFont)
        .attr('font-weight', 400)
        .attr('fill', this.Legends.LegendSeriesColor)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(d => d)

      // if (this.Legends.LegendValueDisplay == 'Y') {

      //   legendItem // VALUE
      //   .append('text')
      //   .attr('id', (_, i) => `SeriesValue${i}`)
      //   .attr('transform', (_, i) => {
      //     let translateX_ = this.Legends.LegendBulletSize + this.Legends.LegendSeriesSpace + this.getNodeElValue(`#SeriesText${i}`, 'width') + 2
      //     let translateY_ = this.Legends.LegendBulletSize / 2 + this.Legends.LegendSeriesSize / 10
      //     return 'translate('+translateX_+","+ translateY_ +")"
      //   })
      //   .attr('class', this.Legends.LegendSeriesStyle)
      //   .style('font-size', this.Legends.LegendValueSize)
      //   .style('font-family', this.Legends.LegendSeriesFont)
      //   .attr('font-weight', 400)
      //   .attr('fill', this.Legends.LegendValueColor)
      //   .attr('text-anchor', 'start')
      //   .attr('alignment-baseline', 'middle')
      //   .text(d => `${d.value.toFixed(this.Legends.LegendValueRound)}`)
      // }

      // if (this.Legends.LegendUnitDisplay == 'Y') {
      //   legendItem // UNIT
      //   .append('text')
      //   .attr('id', (_, i) => `SeriesUnit${i}`)
      //   .attr('transform', (_, i) => {
      //     let translateX_ = this.Legends.LegendBulletSize + this.Legends.LegendSeriesSpace + this.getNodeElValue(`#SeriesText${i}`, 'width') + (this.Legends.LegendValueDisplay == 'Y' ? this.getNodeElValue(`#SeriesValue${i}`, 'width') + 2 : 0)
      //     let translateY_ = this.Legends.LegendBulletSize / 2 + this.Legends.LegendSeriesSize / 10
      //     return 'translate('+translateX_+","+ translateY_ +")"
      //   })
      //   .attr('class', this.Legends.LegendSeriesStyle)
      //   .style('font-size', this.Legends.LegendUnitSize)
      //   .style('font-family', this.Legends.LegendSeriesFont)
      //   .attr('font-weight', 400)
      //   .attr('fill', this.Legends.LegendUnitColor)
      //   .attr('text-anchor', 'start')
      //   .attr('alignment-baseline', 'middle')
      //   .text(this.Legends.LegendUnitFormat)
      // }

      let cumulated = 0

      if (this.Legends.LegendDirection == 'Horizontal') {
        let Total_Width = 0
        let Base_Point = 0

        Total_Width = keys_.map((_, i) => this.getNodeElValue(`#legend_series_item_group_${i}`, 'width') + this.Legends.LegendSeriesSpace).reduce((a, b) => a + b) - this.Legends.LegendSeriesSpace

        switch (this.Legends.LegendBasepoint) {
          case 'start':
            Base_Point = 0
            break
          case 'middle':
            Base_Point = -(Total_Width / 2)-5
            break
          case 'end':
            Base_Point = -Total_Width
            break
        }
        this.Legends.LegendX += Base_Point
        this.svg.select('.legend_wrapper').attr('transform', `translate(${this.Legends.LegendX},${this.Legends.LegendY})`)

        Base_Point = 40
        keys_.forEach((_, i) => {
          this.svg.select(`#legend_series_item_group_${i}`).attr('transform', `translate(${Base_Point},0)`)
          Base_Point += this.getNodeElValue(`#legend_series_item_group_${i}`, 'width') + this.Legends.LegendSeriesSpace
        })
      } else if (this.Legends.LegendDirection == 'Vertical') {
        keys_.forEach((_, i) => {
          this.svg.select(`#legend_series_item_group_${i}`).attr('transform', `translate(0,${cumulated})`)
          cumulated += this.getNodeElValue(`#legend_series_item_group_${i}`, 'height') + this.Legends.LegendLineSpace
        })
      }
    },

    //------------------------------------------------------------------ drawAxis
    drawAxis() {
      this.drawXAxisLine()
      this.drawXAxisLevel()

      this.drawYAxisLine()
      this.drawYAxisLevel()
    },
    drawXAxisLine() {
      if (this.Axis.AxisXLineDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      this.chartArea
        .append('g')
        .attr('class', '_x_axis_line__')
        .append('path')
        // Transform a data value to Y coordinate.
        // console.log(this.scaleYInfo)
        // .attr('d', `M 0,${this.scaleYInfo.scale(0)} H ${this.Canvas.CanvasChartWidth}`)
        .attr('d', `M 0,${this.Canvas.CanvasChartHeight} H ${this.Canvas.CanvasChartWidth}`)
        .style('stroke', this.Axis.AxisXLineColor)
        .style('stroke-width', this.Axis.AxisXLineWeight)
    },
    drawYAxisLine() {
      if (this.Axis.AxisYLineDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      this.chartArea
        .append('g')
        .attr('class', '_y_axis_line__')
        .append('path')
        // Transform a data value to Y coordinate.
        .attr('d', `M 0,0 V ${this.Canvas.CanvasChartHeight}`)
        .style('stroke', this.Axis.AxisYLineColor)
        .style('stroke-width', this.Axis.AxisYLineWeight)
    },
    drawXAxisLevel() {
      if (this.Axis.AxisXLevelDisplay != 'Y') return

      if (this.ChartType.includes('Vertical')) {
        let levelBox = this.chartArea
          .append('g')
          .attr('class', '_x_axis_level_unit__')
          .selectAll('g')
          .data(this.DataItems)
          .enter()
          .append('g')
          .attr('transform', d => `translate(${this.scaleXInfo.scale(d.title)- (this.Axis.AxisXLevelSize / 2)},-10)`)

        levelBox // Series Name
          .append('text')
          .attr('transform', `translate(
            ${Math.round(this.setThickness / 2)},
            ${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 3) + this.Axis.AxisXLevelSpace}
            )${this.Axis.AxisXLevelDirection == 'Vertical' ? ' rotate(-90) ' : ''}
          `)
          .attr('class', this.Axis.AxisXLevelStyle)
          .style('font-size', this.Axis.AxisXLevelSize)
          .style('font-family', this.Axis.AxisXLevelFont)

          //.style('font-weight', this.Bar.BarSeriesWeight)
          .attr('fill', (_, i) => (this.Axis.AxisXLevelAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Axis.AxisXLevelColor))
          .attr('text-anchor', this.Axis.AxisXLevelDirection == 'Vertical' ? 'end' : 'middle')
          .text(d => d.title)

      } else {

        let tickPoints_ = []
        this.scaleXInfo.tickValues.forEach((d, i) => {
          tickPoints_.push(this.scaleXInfo.scale(d))
        })
        tickPoints_.reverse()

        let levelBox = this.chartArea
          .append('g')
          .attr('class', '_x_axis_level_unit__')
          .selectAll('g')
          .data(this.scaleXInfo.tickValues)
          .enter()
          // Append every single box for a line value & unit. -------------------
          // Inited X, Y coordinate of the group is (0, 0), and its width
          // is 0 too. So, at the current time of that the '-this.Axis.AxisLevelSpace'
          // is applied to the X, X coordinate is '-this.Axis.AxisLevelSpace'.
          // And then the width will be extended toward left by the child
          // element's attribute ('text-anchor', 'end'). It doesn't need to 
          // recalculate the X coordinate of the every single boxes.
          .append('g')
          .attr('transform', (_, i) => `translate(${tickPoints_[i]},${this.Canvas.CanvasChartHeight + this.Axis.AxisXLevelSpace + (this.Axis.AxisXLevelSize / 2)})`)
        // .attr('transform', tickValue_ => {console.log(this.scaleXInfo.scale(tickValue_));})

        // Append Unit
        levelBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_${i}`)
          .style('font-size', this.Axis.AxisXLevelSize)
          .style('font-family', this.Axis.AxisXLevelFont)
          .attr('class', this.Axis.AxisXLevelStyle)
          .style('font-weight', this.Axis.AxisXLevelWeight)
          .attr('fill', this.Axis.AxisXLevelColor)
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'start')
          .text(tickValue_ => Math.round(tickValue_))
          .attr('x', (_, i) => -Math.round(this.getNodeElValue(`#Level_Unit_${i}`, 'width') / 2))

        // Append Unit
        levelBox
          .append('text')
          .attr('x', (_, i) => Math.round((this.getNodeElValue(`#Level_Unit_${i}`, 'width') / 2) * 1.1))
          .style('font-size', this.Axis.AxisXLevelUnitSize)
          .style('font-family', this.Axis.AxisXLevelFont)
          .style('font-weight', this.Axis.AxisXLevelWeight)
          .attr('class', this.Axis.AxisXLevelStyle)
          .attr('fill', this.Axis.AxisXLevelColor)
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'start')
          .text(this.Axis.AxisXLevelUnitFormat)
      }

    },
    drawYAxisLevel() {

      if (this.Axis.AxisYLevelDisplay != 'Y') return

      if (this.ChartType.includes('Vertical')) {
        

      
        let levelBox = this.chartArea
          .append('g')
          .attr('class', '_y_axis_level_unit__')
          .selectAll('g')
          .data(this.scaleYInfo.tickValues)
          .enter()
          // Append every single box for a line value & unit. -------------------
          // Inited X, Y coordinate of the group is (0, 0), and its width
          // is 0 too. So, at the current time of that the '-this.Axis.AxisLevelSpace'
          // is applied to the X, X coordinate is '-this.Axis.AxisLevelSpace'.
          // And then the width will be extended toward left by the child
          // element's attribute ('text-anchor', 'end'). It doesn't need to 
          // recalculate the X coordinate of the every single boxes.
          .append('g')
          .attr('class', (d, i) => `_y_axis_level_unit_group__${i}`)

        // Append Unit
        levelBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_${i}`)
          .style('font-size', this.Axis.AxisYLevelUnitSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          .style('font-weight', this.Axis.AxisYLevelWeight)
          .attr('fill', this.Axis.AxisYLevelColor)
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(this.Axis.AxisYLevelUnitFormat)

        // Append Unit
        levelBox
          .append('text')
          .attr('id', (_, i) => `Level_Unit_unit_${i}`)
          .attr('x', (_, i) => -Math.round(this.getNodeElValue(`#Level_Unit_${i}`, 'width') * 1.1))
          .style('font-size', this.Axis.AxisYLevelSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          .style('font-weight', this.Axis.AxisYLevelWeight)
          .attr('fill', this.Axis.AxisYLevelColor)
          .attr('alignment-baseline', 'middle')
          .attr('text-anchor', 'end')
          .text(tickValue_ => Math.round(tickValue_))

        // Alignment of level
        levelBox.attr('transform', (tickValue_, i)=>  {
          let levelTextWidth = d3.select(`#Level_Unit_${i}`).node().getBoundingClientRect().width
          let levelUnitWidth = d3.select(`#Level_Unit_unit_${i}`).node().getBoundingClientRect().width         
            return `translate(${ this.Axis.AxisYLevelPosition == 'right' ?  levelTextWidth + levelUnitWidth + this.Axis.AxisYLevelSpace : -this.Axis.AxisYLevelSpace }, ${this.scaleYInfo.scale(tickValue_)})`
        })
      } else {

        let levelBox = this.chartArea
          .append('g')
          .attr('class', '_y_axis_level_unit__')
          .selectAll('g')
          .data(this.DataItems)
          .enter()
          .append('g')
          .attr('transform', d => `translate(0,${this.scaleYInfo.scale(d.title)})`)

        levelBox // Series Name
          .append('text')
          .attr('class', (d,i) => `_y_axis_level_text__${i}`)
          // .attr('transform', `translate(${Math.round(this.setThickness / 2)},${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 3) + this.Bar.BarSeriesSpace})`)
          // .attr('transform', `translate(-${this.Axis.AxisYLevelSpace}, ${Math.round(this.setThickness / 3) + this.Axis.AxisYLevelSize / 2})`)
          .attr('transform', (d,i) =>   {
            let levelTextWidth = d3.select(`._y_axis_level_text__${i}`).node().getBoundingClientRect().width
            return `translate(${this.Axis.AxisYLevelPosition == 'left' ? -this.Axis.AxisYLevelSpace 
              : this.Axis.AxisYLevelSpace +  levelTextWidth},  ${Math.round(this.setThickness / 3) + this.Axis.AxisYLevelSize / 2})`
          })
          .style('font-size', this.Axis.AxisYLevelSize)
          .style('font-family', this.Axis.AxisYLevelFont)
          .attr('class', this.Axis.AxisYLevelStyle)
          //.style('font-weight', this.Axis.BarSeriesWeight)
          .attr('fill', (_, i) => (this.Axis.AxisYLevelAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Axis.AxisYLevelColor))
          .attr('text-anchor',` ${this.Axis.AxisYLevelPosition == 'left' ? 'end' : 'start'}`)
          .text(d => d.title)


          // `#_y_axis_level_text__${this.axisy.o.levelPosition}_${i}`).node().getBoundingClientRect().width * 1.1
      }
    },

    //------------------------------------------------------------------ drawGrid
    drawGrid() {
      if (this.ChartType.includes('Vertical')) this.drawGridYLines()
      else this.drawGridXLines()
    },
    drawGridXLines() {
      if (this.Axis.AxisYGridDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      this.chartArea
        .append('g')
        .attr('class', '_x_axis_grid_line__')
        .selectAll('path')
        .data(this.scaleXInfo.tickValues)
        .enter()
        .append('path')
        // Transform a data value to X coordinate.
        .attr('d', (tickValue_) => `M ${this.scaleXInfo.scale(tickValue_)},0 V ${this.Canvas.CanvasChartHeight}`)
        .style('stroke', this.Axis.AxisYGridColor)
        .style('stroke-width', this.Axis.AxisYGridWeight)
    },
    drawGridYLines() {
      if (this.Axis.AxisYGridDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      this.chartArea
        .append('g')
        .attr('class', '_y_axis_grid_line__')
        .selectAll('path')
        .data(this.scaleYInfo.tickValues)
        .enter()
        .append('path')
        // Transform a data value to Y coordinate.
        .attr('d', tickValue_ => `M 0,${this.scaleYInfo.scale(tickValue_)} H ${this.Canvas.CanvasChartWidth}`)
        .style('stroke', this.Axis.AxisYGridColor)
        .style('stroke-width', this.Axis.AxisYGridWeight)
    },

  }
}
