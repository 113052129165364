export default {
  namespaced: true,
  state: {
    codePropagated: {
      name: '',
      code: '',
    },
    menuUpdated: null,
  },
  mutations: {
    codePropagate (state, code) {
      state.codePropagated = code
    },
    setMenuStatusUpdated (state, status) {
      state.menuUpdated = Math.round((new Date()).getTime() / 1000)
    }
  },
  getters: {
  },
  actions: {
  }
}
