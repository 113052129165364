import * as d3 from 'd3'
import { finished } from 'stream'

export default {
  methods: {
    // ICON
    draw_SummaryTableFooter() {

      let footer = this.tableGroup
      .append('g')
      .attr('transform', `translate(0,${this.TableTotalHeight})`)

      if (this.Table.FootBorderTop == 'Y') {
        footer
        .append('line') // Top
        .attr('x1',0).attr('y1', 0)
        .attr('x2',this.HeaderTotalWidth).attr('y2', 0)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }

      if (this.Table.FootBorderBottom == 'Y') {
        footer
        .append('line') // Bottom
        .attr('x1',0).attr('y1', this.Table.TableFootHeight)
        .attr('x2',this.HeaderTotalWidth).attr('y2', this.Table.TableFootHeight)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }
      if (this.Table.FootBorderLeft == 'Y') {
        footer
        .append('line') // Left
        .attr('x1',0).attr('y1', 0)
        .attr('x2',0).attr('y2', this.Table.TableFootHeight)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }
      if (this.Table.FootBorderRight == 'Y') {
        footer
        .append('line') // Right
        .attr('x1',this.HeaderTotalWidth).attr('y1', 0)
        .attr('x2',this.HeaderTotalWidth).attr('y2', this.Table.TableFootHeight)
        .attr('stroke',this.Table.TableHeadStrokeColor[0])
        .attr('stroke-width',this.Table.TableHeadStroke[0])
      }

      if (this.Table.TableFootDisplay == 'N') return

      footer
      .append('text')
      .attr('x', this.FooterMergeWidth/2)
      .attr('y', this.Table.TableFootHeight/2)
      // .style('font-family', this.Styles.find(f => f.type == 'Text').textFont)
      .style('font-family', this.Table.TableCellFont)
      // .attr('class', this.Styles.find(f => f.type == 'Text').textStyle)
      .attr('class', this.Table.TableFootTextStyle)
      .attr('font-size', this.Table.TableFootTextSize)
      .attr('fill', this.Table.TableFootTextColor)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .text(this.Table.TableFootTitle)


      console.log(this.Columns.length)
      
      this.clone_Columns.slice(this.Table.TableFootMergeCell,  this.clone_Columns.length).forEach((col) => {

        let py = this.Table.TableFootHeight/2
        let value = this.Footer[0][col.column]

        if (col.type == 'Icon') { // Reference Icon

          let styleSet = this.Styles.find(f => f.type == 'Icon' && f.series == this.Table.TableFootRefIcon)
          let codeSet = styleSet.codes.find(f => f.code == this.Footer[0][styleSet.refColumn])
          
          footer
          .append('image') 
          .attr('xlink:href', () => {
            if (value > 0) return require('../../../../src/assets/svg/Prog_Up.svg')
            if (value == 0) return require('../../../../src/assets/svg/Prog_Zero.svg')
            if (value < 0) return require('../../../../src/assets/svg/Prog_Down.svg')
          })
          .attr('x', () => {
            if (styleSet.align == 'start') return col.x1 + styleSet.paddingX
            if (styleSet.align == 'end') return col.x2 - styleSet.iconSize - styleSet.paddingX
          })
          .attr('y', (this.Table.TableFootHeight - styleSet.iconSize) / 2)
          .attr('width', styleSet.iconSize).attr('height', styleSet.iconSize)
          
          
          footer
          .append('text')
          .attr('x', () => {
            if (styleSet.align == 'start') return col.x1 + styleSet.paddingX + styleSet.iconSize + 3
            if (styleSet.align == 'end') return col.x2 - styleSet.paddingX - styleSet.iconSize - 3
          })
          .attr('y', py)
          .style('font-family', this.Styles.find(f => f.type == 'Text').textFont)
          // .attr('class', this.Styles.find(f => f.type == 'Text').textStyle)
          .attr('class', this.Table.TableFootTextStyle)
          .attr('font-size', this.Table.TableFootTextSize)
          .attr('fill', codeSet.tColor)
          .attr('text-anchor', styleSet.align)
          .attr('alignment-baseline', 'middle')
          .text(value)

        } else if (col.type == 'Bar') { // Reference Bar

          let ly = this.Table.TableFootHeight/2
          let styleSet = this.Styles.find(f => f.type == 'Bar' && f.series == this.Table.TableFootRefBarChart)
          // console.log(styleSet)
          let codeSet = styleSet.codes.find(f => f.code == this.Footer[0][styleSet.refColumn])
          // console.log(codeSet)
          let codeNo = styleSet.codes.findIndex(f => f.code == this.Footer[0][styleSet.refColumn])
          
          let limited = styleSet.bounds
          let len = (this.Footer[0][styleSet.dataColumn] / 100) * styleSet.barLength
          let barPadding = this.Table.TableFootHeight - styleSet.barThickness
          
          footer
          .append('rect')
          .attr('x', col.x1+1).attr('y', (barPadding/2))
          .attr('rx', styleSet.barRadius).attr('ry', styleSet.barRadius)
          .attr('width', 0)
          .attr('height', this.Table.TableFootHeight - barPadding)
          .attr('stroke-width',0)
          .attr('fill', () => {
         
            if (codeSet.bColor.substr(0,1) == '#') {
              return codeSet.bColor
            } else {
              return `url(#${this.localId}__LinearA4${styleSet.type}${styleSet.series}${codeNo}0)`
            }
          })
          .transition().duration(500).attr('width', len)

          footer
          .append('text')
          .attr('x', col.x1)
          .attr('y', ly)
          .style('font-family', this.Styles.find(f => f.type == 'Text').textFont)
          // .attr('class', this.Styles.find(f => f.type == 'Text').textStylr)
          .attr('class', this.Table.TableFootTextStyle)
          .attr('font-size', styleSet.textSize)
          .attr('fill', () => {
            if (styleSet.textPosition == 'inside') {
              if (len >= limited) return codeSet.tColor; else return this.Table.TableFootTextColor
            } else {
              return this.Table.TableFootTextColor
            }
          })
          .attr('text-anchor', () => {
            if (styleSet.textPosition == 'inside') {
              if (len >= limited) return 'end'; else return 'start'
            } else {
              return 'start'
            }
          })
          .attr('alignment-baseline', 'middle')
          .text(`${value.toFixed(styleSet.round)}${styleSet.unit}`)
          .transition().duration(500)
          .attr('x', () => {
            
            if (styleSet.textPosition == 'inside') {
              if (len >= limited) return col.x1 + len - 2; else return col.x1 + len + 2
            } else {
              return col.x1 + len + 2
            }
          })

        } else {
            footer
            .append('text')
            .attr('x', col.px)
            .attr('y', py)
            .style('font-family', this.Styles.find(f => f.type == 'Text').textFont)
            // .attr('class', this.Styles.find(f => f.type == 'Text').textStyle)
            .attr('class', this.Table.TableFootTextStyle)
            .attr('font-size', this.Table.TableFootTextSize)
            .attr('fill', this.Table.TableFootTextColor)
            .attr('text-anchor', col.align)
            .attr('alignment-baseline', 'middle')
            .text(value)
        }
      })
          
    },
  }
}
