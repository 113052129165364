
import __C from '../primitives/_constant_'
import { ChartLibraryApi } from './api'

// http://patorjk.com/software/taag/#p=display&h=2&v=2&f=Banner3&t=BAR%20PROGRESS
export default class ChartLibraryService {
    
  constructor() {
    this.chartLibraryApi = new ChartLibraryApi()
  }
  
  // Transform Query Result
  transform(res) {
    // console.log('transform IN'  ,res) 
    res.QResultMilestone = res.QResultMilestone ? JSON.parse(res.QResultMilestone) : []
    res.Cutoff = res.QResultCutoff || ''
    res.TitleSqlText = res.QResultTitleSql || res.TitleSqlText
    // Summary Table
    if(res.QResultFooter) res.Footer = JSON.parse(res.QResultFooter)

    let __qResultSummary__ = res.QResultSummary ? JSON.parse(res.QResultSummary) : []
    if(__qResultSummary__.length == 0) {
      res.QResultSummary = []
      return
    }
    
    // For the JSON-IN-JSON result
    __qResultSummary__ = __qResultSummary__[0].JSON_PARSE ? JSON.parse(__qResultSummary__[0].JSON_PARSE) : __qResultSummary__

    // console.log('transform __qResultSummary__'  ,__qResultSummary__) 
    
    if(res.CatCode == __C.CHART.CAT_CODE_SKYLINE) {
      let lines_ = Object.keys(__qResultSummary__[0]).filter(k => k.includes('line'))
      let QResultSummary_ = []
      let cDateGroup_ = {
        cdate: __qResultSummary__[0].cdate,
        values: []
      }
      lines_.forEach(k => { cDateGroup_[k] = __qResultSummary__[0][k] })

      __qResultSummary__.forEach(sumres => {
        if(cDateGroup_.cdate != sumres.cdate) {
          QResultSummary_.push(JSON.parse(JSON.stringify(cDateGroup_)))
          cDateGroup_ = {
            cdate: sumres.cdate,
            values: []
          }
          lines_.forEach(k => { cDateGroup_[k] = sumres[k] })
        }

        cDateGroup_.values.push({
          name: sumres.name,
          cat1: sumres.cat1,
          cat2: sumres.cat2,
          prog1: sumres.prog1,
          prog2: sumres.prog2,
        })
      })

      QResultSummary_.push(JSON.parse(JSON.stringify(cDateGroup_)))
      res.QResultSummary = QResultSummary_

    } else if(res.CatCode == __C.CHART.CAT_CODE_HISTORY) {
      if(res.QResultHistTableSummary) res.TableSummary.items = JSON.parse(res.QResultHistTableSummary)
      
      // On using JS object, by definition, passing an object to another
      // dose not keep its element order.
      // for example,
      // - state.chartData = [{CDATE:'2019-09-30', BAR_PLAN:2938}]
      // - console.log(state.chartData) => [{BAR_PLAN:2938,CDATE:'2019-09-30'}]
      // if an object [{CDATE:'2019-09-30', BAR_PLAN:2938}] is passed to another,
      // it will be done to [{BAR_PLAN:2938,CDATE:'2019-09-30'}].
      // So, if the Object should be kept its elements' order, it must be passed
      // as an Array, and its keys & values should be assigned in order, manually.
      let chartData = []
      let source_ = JSON.parse(res.QResultSummary)[0].JSON_PARSE ? 
                    JSON.parse(res.QResultSummary)[0].JSON_PARSE :
                    res.QResultSummary
      JSON.parse(source_).forEach((d, i) => {
        chartData.push({})
        Object.keys(d).forEach(k => {
          chartData[i][k] = d[k]
        })
      })
      res.QResultSummary = chartData

    } else {
      res.QResultSummary = __qResultSummary__
    }
  }
  // Transform Elements
  transformEls(item, in_=true) {
    // For the common properties
    // console.log('transformEls IN',item,in_)
    if(in_) {
      item.Text = item.Text ? JSON.parse(item.Text) : { Text: [], Filter: [], Sql: [] }
      item.QResultTextSql = item.QResultTextSql ? JSON.parse(item.QResultTextSql) : []
      item.QResultTextSql.forEach((text_, i) => {
        if(item.Text.Sql.length > 0 && item.Text.Sql[i]) item.Text.Sql[i].text = text_
      })
      
      item.Tags = item.Tags ? JSON.parse(item.Tags) : []

    } else {
      item.Text = item.Text ? JSON.stringify(item.Text) : ''
      item.Tags = item.Tags ? JSON.stringify(item.Tags) : ''
    }

    return {
      [__C.CHART.CAT_CODE_DONUT]: (item, in_=true) => {
        item.CircleRadius         = in_ ? JSON.parse(item.CircleRadius)       : JSON.stringify(item.CircleRadius)
        item.CircleStroke         = in_ ? JSON.parse(item.CircleStroke)       : JSON.stringify(item.CircleStroke)
        item.CircleBkColor        = in_ ? JSON.parse(item.CircleBkColor)      : JSON.stringify(item.CircleBkColor)
        item.CircleFtColor        = in_ ? JSON.parse(item.CircleFtColor)      : JSON.stringify(item.CircleFtColor)
        item.CircleGaugeRadius    = in_ ? JSON.parse(item.CircleGaugeRadius)  : JSON.stringify(item.CircleGaugeRadius)
        item.CircleGaugeCount     = in_ ? JSON.parse(item.CircleGaugeCount)   : JSON.stringify(item.CircleGaugeCount)
        item.CircleGaugeLength    = in_ ? JSON.parse(item.CircleGaugeLength)  : JSON.stringify(item.CircleGaugeLength)
        item.CircleGaugeStroke    = in_ ? JSON.parse(item.CircleGaugeStroke)  : JSON.stringify(item.CircleGaugeStroke)
        item.CircleGaugeColor     = in_ ? JSON.parse(item.CircleGaugeColor)   : JSON.stringify(item.CircleGaugeColor)
        item.CircleMultiBkColors  = in_ ? JSON.parse(item.CircleMultiBkColors || '[]') : JSON.stringify(item.CircleMultiBkColors)
        item.CircleMultiFtColors  = in_ ? JSON.parse(item.CircleMultiFtColors || '[]') : JSON.stringify(item.CircleMultiFtColors)
        item.Values               = in_ ? JSON.parse(item.Values)             : JSON.stringify(item.Values)
        if(in_) this.transform(item)
      },
      [__C.CHART.CAT_CODE_PIE]: (item, in_=true) => {
        if(in_) {
          item.CircleColorSet = item.CircleColorSet ? JSON.parse(item.CircleColorSet) : []
          this.transform(item)

        } else item.CircleColorSet = item.CircleColorSet ? JSON.stringify(item.CircleColorSet) : ''
      },
      [__C.CHART.CAT_CODE_BAR_AXIS]: (item, in_=true) => {
        if(in_) {
          item.BarColorSet = item.BarColorSet ? JSON.parse(item.BarColorSet) : []
          this.transform(item)

        } else item.BarColorSet = item.BarColorSet ? JSON.stringify(item.BarColorSet) : ''
      },
      [__C.CHART.CAT_CODE_BAR_SOLID]: (item, in_=true) => {
        if(in_) {
          item.BarColorSet = item.BarColorSet ? JSON.parse(item.BarColorSet) : []
          this.transform(item)

        } else item.BarColorSet = item.BarColorSet ? JSON.stringify(item.BarColorSet) : ''
      },
      [__C.CHART.CAT_CODE_BAR_PROGRESS]: (item, in_=true) => {
        if(in_) {
          item.BarColorSet = item.BarColorSet ? JSON.parse(item.BarColorSet) : []

          item.Chtcomm = item.Chtcomm ? JSON.parse(item.Chtcomm) : {}
          item.Chttype = item.Chttype ? JSON.parse(item.Chttype) : {}
          item.Chtcol = item.Chtcol ? JSON.parse(item.Chtcol) : []

          // console.log('get transformEls')
          // console.log('item.Chtcomm',item.Chtcomm)
          // console.log('item.Chttype',item.Chttype)
          // console.log('item.Chtcol',item.Chtcol)
          this.transform(item)

        } else {

          item.BarColorSet = item.BarColorSet ? JSON.stringify(item.BarColorSet) : ''
          item.Chtcomm = item.Chtcomm ? JSON.stringify(item.Chtcomm) : ''
          item.Chttype = item.Chttype ? JSON.stringify(item.Chttype) : ''
          item.Chtcol = item.Chtcol ? JSON.stringify(item.Chtcol) : []
          // console.log('transformEls else',in_)
          // console.log('item.Chtcomm',item.Chtcomm)
          // console.log('item.Chttype',item.Chttype)
          // console.log('item.Chtcol',item.Chtcol)
        }
      },
      [__C.CHART.CAT_CODE_SUMMARY]: (item, in_=true) => {
        if(in_) {

          item.Chtcomm = item.Chtcomm ? JSON.parse(item.Chtcomm) : {}
          item.Chttype = item.Chttype ? JSON.parse(item.Chttype) : {}
          item.Chtcol = item.Chtcol ? JSON.parse(item.Chtcol) : []

          this.transform(item)

        } else {

          item.Chtcomm = item.Chtcomm ? JSON.stringify(item.Chtcomm) : ''
          item.Chttype = item.Chttype ? JSON.stringify(item.Chttype) : ''
          item.Chtcol = item.Chtcol ? JSON.stringify(item.Chtcol) : []
        }
      },
      [__C.CHART.CAT_CODE_WEEKLY]: (item, in_=true) => {
        if(in_) {

          item.Chtcomm = item.Chtcomm ? JSON.parse(item.Chtcomm) : {}
          item.Chttype = item.Chttype ? JSON.parse(item.Chttype) : {}
          item.Chtcol = item.Chtcol ? JSON.parse(item.Chtcol) : []

          this.transform(item)

        } else {

          item.Chtcomm = item.Chtcomm ? JSON.stringify(item.Chtcomm) : ''
          item.Chttype = item.Chttype ? JSON.stringify(item.Chttype) : ''
          item.Chtcol = item.Chtcol ? JSON.stringify(item.Chtcol) : []
        }
      },

      [__C.CHART.CAT_CODE_BAR_DELTA]: (item, in_=true) => {
        if(in_) {
          item.Delta = item.Delta ? JSON.parse(item.Delta) : {}
          this.transform(item)

        } else item.Delta = item.Delta ? JSON.stringify(item.Delta) : ''
      },
      [__C.CHART.CAT_CODE_HISTORY]: (item, in_=true) => {
        if(in_) {
          item.Axis = item.Axis ? JSON.parse(item.Axis) : {}
          item.Line = item.Line ? JSON.parse(item.Line) : {}
          item.BarColorSet  = item.BarColorSet  ? JSON.parse(item.BarColorSet)  : []
          item.BarBorder    = item.BarBorder    ? JSON.parse(item.BarBorder)    : {}
          item.TableSummary = item.TableSummary ? JSON.parse(item.TableSummary) : {}
          this.transform(item)
        } else {
          item.Axis = item.Axis ? JSON.stringify(item.Axis) : ''
          item.Line = item.Line ? JSON.stringify(item.Line) : ''
          item.BarColorSet  = item.BarColorSet  ? JSON.stringify(item.BarColorSet)  : ''
          item.BarBorder    = item.BarBorder    ? JSON.stringify(item.BarBorder)    : ''
          item.TableSummary = item.TableSummary ? JSON.stringify(item.TableSummary) : ''
        }
      },
      [__C.CHART.CAT_CODE_RADAR]: (item, in_=true) => {
        if(in_) {
          item.Radar = item.Radar ? JSON.parse(item.Radar) : {
            chart: {},
            level: {},
            axes: {},
            series: {},
            value: {},
            area: {},
            node: {},
            legend: {},
          }
          this.transform(item)

        } else item.Radar = item.Radar ? JSON.stringify(item.Radar) : ''
      },
      [__C.CHART.CAT_CODE_FLOAT]: (item, in_=true) => {
        if(in_) {
          item.FloatBar       = item.FloatBar       ? JSON.parse(item.FloatBar)       : []
          item.FloatBarSeries = item.FloatBarSeries ? JSON.parse(item.FloatBarSeries) : []
          item.FloatLine      = item.FloatLine      ? JSON.parse(item.FloatLine)      : []
          this.transform(item)
        } else {
          item.FloatBar       = item.FloatBar       ? JSON.stringify(item.FloatBar)       : ''
          item.FloatBarSeries = item.FloatBarSeries ? JSON.stringify(item.FloatBarSeries) : ''
          item.FloatLine      = item.FloatLine      ? JSON.stringify(item.FloatLine)      : ''
        }
      },
      [__C.CHART.CAT_CODE_SKYLINE]: (item, in_=true) => {
        if(in_) {
          item.ActionAttrs  = item.ActionAttrs  ? JSON.parse(item.ActionAttrs)  : null
          item.LineStyles   = item.LineStyles   ? JSON.parse(item.LineStyles)   : []
          item.StyleAttrs   = item.StyleAttrs   ? JSON.parse(item.StyleAttrs)   : {}
          this.transform(item)
        } else {
          item.ActionAttrs  = item.ActionAttrs  ? JSON.stringify(item.ActionAttrs)  : ''
          item.LineStyles   = item.LineStyles   ? JSON.stringify(item.LineStyles)   : ''
          item.StyleAttrs   = item.StyleAttrs   ? JSON.stringify(item.StyleAttrs)   : ''
        }
      },
      [__C.CHART.CAT_CODE_TABLE_SUMMARY]: (item, in_=true) => {
        if(in_) {
          item.Style = item.Style ? JSON.parse(item.Style) : []
          item.Table = item.Table ? JSON.parse(item.Table) : {}
          // JSON Formatted String like JSON-Prop
          // item.Header = item.Header ? JSON.parse(item.Header) : []
          item.Columns = item.Columns ? JSON.parse(item.Columns) : []
          item.Footer = item.Footer ? JSON.parse(item.Footer) : []
          this.transform(item)
        } else {
          item.Style = item.Style ? JSON.stringify(item.Style) : ''
          item.Table = item.Table ? JSON.stringify(item.Table) : ''
          item.Columns = item.Columns ? JSON.stringify(item.Columns) : ''
          item.Footer = item.Footer ? JSON.stringify(item.Footer) : ''
        }
      },
      [__C.CHART.CAT_CODE_CUSTOM]: (item, in_=true) => {
        // Customized Charts
        // 1. Chart Custom        CAT_CODE_CUSTOM
        // 2. Chart Controller    CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER
        // 3. Chart Controller    CAT_CODE_COMPLIB_SVG_NAVIGATION
        // 4. SVG Tab Component   CAT_CODE_COMPLIB_SVG_TAB
        // 5. SVG Page Component  CAT_CODE_COMPLIB_SVG_PAGE
        if(in_) {
          // For the Chart Controller --------------------------------
          item.Queries = item.Queries ? JSON.parse(item.Queries) : []
          item.QResultExtras = item.QResultExtras ? JSON.parse(item.QResultExtras) : {}
          // For the SVG Tab Component -------------------------------
          if(item.TabAttrs) {
            item.TabAttrs = JSON.parse(item.TabAttrs)
            item.TabStatus = item.TabStatus ? JSON.parse(item.TabStatus) : []
          } else {
            item.TabAttrs = {
              text: {},
              draw: {},
              tabs: {},
            }
            item.TabStatus = []
          }
          // For the SVG Page Component ------------------------------
          item.ActionAttrs = item.ActionAttrs ? JSON.parse(item.ActionAttrs) : null
          item.MoreAttrs = item.MoreAttrs ? JSON.parse(item.MoreAttrs) : null
          item.Border = item.Border ? JSON.parse(item.Border) : []
          item.ChartData = item.ChartData ? JSON.parse(item.ChartData) : []
          item.Status = item.Status ? JSON.parse(item.Status) : []
          // ---------------------------------------------------------
          this.transform(item)
        } else {
          // For the Chart Controller --------------------------------
          item.Queries = item.Queries ? JSON.stringify(item.Queries) : ''
          item.QResultExtras = item.QResultExtras ? JSON.stringify(item.QResultExtras) : ''
          // For the SVG Tab Component -------------------------------
          item.TabAttrs = item.TabAttrs ? JSON.stringify(item.TabAttrs) : ''
          // For the SVG Page Component ------------------------------
          item.ActionAttrs = item.ActionAttrs ? JSON.stringify(item.ActionAttrs) : ''
          item.MoreAttrs = item.MoreAttrs ? JSON.stringify(item.MoreAttrs) : ''
          item.Border = item.Border ? JSON.stringify(item.Border) : ''
          item.ChartData = item.ChartData ? JSON.stringify(item.ChartData) : ''
          item.Status = item.Status ? JSON.stringify(item.Status) : ''
          // ---------------------------------------------------------
        }
      },
    }
  }

  // getQueryValidator (param, callback) {
  //   this.chartLibraryApi.getQueryValidator(param, (res) => { callback(res) })
  // }

  getQueryValidator (param, callback) {
    this.chartLibraryApi.getQueryValidator(param, res => {
      callback(res)
    })
  }

  getChart (catCode, chartNo) {
    return new Promise(resolve => { 
      switch(catCode) {
        case __C.CHART.CAT_CODE_BAR_DELTA     : this.getChartBarDelta(chartNo, resolve);      break;
        case __C.CHART.CAT_CODE_BAR_AXIS      : this.getChartBarNormal(chartNo, resolve);     break;
        case __C.CHART.CAT_CODE_BAR_PROGRESS  : this.getChartBarProgress(chartNo, resolve);   break;
        case __C.CHART.CAT_CODE_BAR_SOLID     : this.getChartBarSolid(chartNo, resolve);      break;
        case __C.CHART.CAT_CODE_DONUT         : this.getChartDonut(chartNo, resolve);         break;
        case __C.CHART.CAT_CODE_FLOAT         : this.getChartFloating(chartNo, resolve);      break;
        case __C.CHART.CAT_CODE_HISTORY       : this.getChartHistogram(chartNo, resolve);     break;
        case __C.CHART.CAT_CODE_PIE           : this.getChartPie(chartNo, resolve);           break;
        case __C.CHART.CAT_CODE_RADAR         : this.getChartRadar(chartNo, resolve);         break;
        case __C.CHART.CAT_CODE_SKYLINE       : this.getChartSkyline(chartNo, resolve);       break;
        case __C.CHART.CAT_CODE_SUMMARY       : this.getChartSummary(chartNo, resolve);       break;
        case __C.CHART.CAT_CODE_TABLE_SUMMARY : this.getChartTableSummary(chartNo, resolve);  break;
        case __C.CHART.CAT_CODE_WEEKLY        : this.getChartWeekly(chartNo, resolve);        break;
        case __C.CHART.CAT_CODE_CUSTOM                      :
        case __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER:
        case __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION      :
        case __C.CHART.CAT_CODE_COMPLIB_SVG_PAGE            : this.getChartCustom(chartNo, null, resolve); break;
      }
    })
  }
  getChartList (catCode, phaseCode, itemCode, callback) {
    this.chartLibraryApi.getChartList(catCode, phaseCode, itemCode, (res) => { 

      if(!res) {
        callback([])
        return
      }
      callback(res) })
  }
  getChartThumbs (catCode, phaseCode, itemCode, callback) {
    this.chartLibraryApi.getChartThumbs(catCode, phaseCode, itemCode, (res) => {
      if(!res) {
        callback([])
        return
      }

      res.forEach(charts_ => {
        let xmlString_ = []
        let xmlCharts_ = charts_.XmlString.split('|$$|')
        xmlCharts_.forEach(chart_ => {
          let xmlChart_ = chart_.split('|$|')
          xmlString_.push({ Name: xmlChart_[0], ChartNo: xmlChart_[1], Tags: xmlChart_[2], UsageStatus: xmlChart_[3], Svg: xmlChart_[4] })
        })

        charts_.XmlString = xmlString_
      })

      callback(res)
    })
  }

  // Bar Delta
  getChartBarDelta (chartNo, callback) {
    this.chartLibraryApi.getChartBarDelta(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartBarDelta (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartBarDelta(item, res => { callback(res) })
  }
  sasChartBarDelta (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartBarDelta(item, res => { callback(res) })
  }
  updChartBarDelta (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartBarDelta(item, res => { callback(res) })
  }
  delChartBarDelta (chartNo, callback) {
    this.chartLibraryApi.delChartBarDelta(chartNo, res => { callback(res) })
  }

  // Bar Normal(Axis)
  getChartBarNormal(chartNo, callback) {
    this.chartLibraryApi.getChartBarNormal(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartBarNormal(item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartBarNormal(item, res => { callback(res) })
  }
  sasChartBarNormal(item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartBarNormal(item, res => { callback(res) })
  }
  updChartBarNormal(item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartBarNormal(item, res => { callback(res) })
  }
  delChartBarNormal(chartNo, callback) {
    this.chartLibraryApi.delChartBarNormal(chartNo, res => { callback(res) })
  }

  // Bar Progress
  getChartBarProgress (chartNo, callback) {
    this.chartLibraryApi.getChartBarProgress(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartBarProgress (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartBarProgress(item, res => { callback(res) })
  }
  sasChartBarProgress (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartBarProgress(item, res => { callback(res) })
  }
  updChartBarProgress (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartBarProgress(item, res => { callback(res) })
  }
  delChartBarProgress (chartNo, callback) {
    this.chartLibraryApi.delChartBarProgress(chartNo, res => { callback(res) })
  }

  // Bar Solid
  getChartBarSolid (chartNo, callback) {
    this.chartLibraryApi.getChartBarSolid(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartBarSolid (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartBarSolid(item, res => { callback(res) })
  }
  sasChartBarSolid (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartBarSolid(item, res => { callback(res) })
  }
  updChartBarSolid (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartBarSolid(item, res => { callback(res) })
  }
  delChartBarSolid (chartNo, callback) {
    this.chartLibraryApi.delChartBarSolid(chartNo, res => { callback(res) })
  }

  // Donut
  getChartDonut (chartNo, callback) {
    this.chartLibraryApi.getChartDonut(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartDonut (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartDonut(item, res => { callback(res) })
  }
  sasChartDonut (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartDonut(item, res => { callback(res) })
  }
  updChartDonut (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartDonut(item, res => { callback(res) })
  }
  delChartDonut (chartNo, callback) {
    this.chartLibraryApi.delChartDonut(chartNo, res => { callback(res) })
  }

  // Floating
  getChartFloating (chartNo, callback) {
    this.chartLibraryApi.getChartFloating(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartFloating (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartFloating(item, res => { callback(res) })
  }
  sasChartFloating (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartFloating(item, res => { callback(res) })
  }
  updChartFloating (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartFloating(item, res => { callback(res) })
  }
  delChartFloating (chartNo, callback) {
    this.chartLibraryApi.delChartFloating(chartNo, res => { callback(res) })
  }

  // Histogram
  getChartHistogram (chartNo, callback) {
    this.chartLibraryApi.getChartHistogram(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartHistogram (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartHistogram(item, res => { callback(res) })
  }
  sasChartHistogram (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartHistogram(item, res => { callback(res) })
  }
  updChartHistogram (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartHistogram(item, res => { callback(res) })
  }
  delChartHistogram (chartNo, callback) {
    this.chartLibraryApi.delChartHistogram(chartNo, res => { callback(res) })
  }

  // Pie
  getChartPie (chartNo, callback) {
    this.chartLibraryApi.getChartPie(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartPie (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartPie(item, res => { callback(res) })
  }
  sasChartPie (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartPie(item, res => { callback(res) })
  }
  updChartPie (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartPie(item, res => { callback(res) })
  }
  delChartPie (chartNo, callback) {
    this.chartLibraryApi.delChartPie(chartNo, res => { callback(res) })
  }

  // Radar
  getChartRadar (chartNo, callback) {
    this.chartLibraryApi.getChartRadar(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartRadar (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartRadar(item, res => { callback(res) })
  }
  sasChartRadar (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartRadar(item, res => { callback(res) })
  }
  updChartRadar (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartRadar(item, res => { callback(res) })
  }
  delChartRadar (chartNo, callback) {
    this.chartLibraryApi.delChartRadar(chartNo, res => { callback(res) })
  }

  // Skyline
  getChartSkyline (chartNo, callback) {
    this.chartLibraryApi.getChartSkyline(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartSkyline (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartSkyline(item, res => { callback(res) })
  }
  sasChartSkyline (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartSkyline(item, res => { callback(res) })
  }
  updChartSkyline (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartSkyline(item, res => { callback(res) })
  }
  delChartSkyline (chartNo, callback) {
    this.chartLibraryApi.delChartSkyline(chartNo, res => { callback(res) })
  }

  // Summary
  getChartSummary (chartNo, callback) {
    this.chartLibraryApi.getChartSummary(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartSummary (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartSummary(item, res => { callback(res) })
  }
  sasChartSummary (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartSummary(item, res => { callback(res) })
  }
  updChartSummary (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartSummary(item, res => { callback(res) })
  }
  delChartSummary (chartNo, callback) {
    this.chartLibraryApi.delChartSummary(chartNo, res => { callback(res) })
  }

  // Table Summary
  getChartTableSummary (chartNo, callback) {
    this.chartLibraryApi.getChartTableSummary(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartTableSummary (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartTableSummary(item, res => { callback(res) })
  }
  sasChartTableSummary (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartTableSummary(item, res => { callback(res) })
  }
  updChartTableSummary (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartTableSummary(item, res => { callback(res) })
  }
  delChartTableSummary (chartNo, callback) {
    this.chartLibraryApi.delChartTableSummary(chartNo, res => { callback(res) })
  }

  // Weekly
  getChartWeekly (chartNo, callback) {
    this.chartLibraryApi.getChartWeekly(chartNo, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[res.CatCode](res)
        callback(res)
      }
    })
  }
  putChartWeekly (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.putChartWeekly(item, res => { callback(res) })
  }
  sasChartWeekly (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.sasChartWeekly(item, res => { callback(res) })
  }
  updChartWeekly (item, callback) {
    this.transformEls(item, false)[item.CatCode](item, false)
    this.chartLibraryApi.updChartWeekly(item, res => { callback(res) })
  }
  delChartWeekly (chartNo, callback) {
    this.chartLibraryApi.delChartWeekly(chartNo, res => { callback(res) })
  }  
  
  // Customized Charts
  // 1. Chart Custom        CAT_CODE_CUSTOM
  // 2. Chart Controller    CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER
  // 3. SVG Tab Component   CAT_CODE_COMPLIB_SVG_TAB
  // 4. SVG Page Component  CAT_CODE_COMPLIB_SVG_PAGE
  getChartCustom (chartNo, filters, callback) {
    let f__ = filters ? JSON.stringify(filters) : '{}'

    this.chartLibraryApi.getChartCustom(chartNo, f__, res => {
      if(!res) callback({})
      else {
        this.transformEls(res)[__C.CHART.CAT_CODE_CUSTOM](res)
        callback(res)
      }
    })
  }
  putChartCustom (item, callback) {
    this.transformEls(item, false)[__C.CHART.CAT_CODE_CUSTOM](item, false)
    this.chartLibraryApi.putChartCustom(item, res => { callback(res) })
  }
  sasChartCustom (item, callback) {
    this.transformEls(item, false)[__C.CHART.CAT_CODE_CUSTOM](item, false)
    this.chartLibraryApi.sasChartCustom(item, res => { callback(res) })
  }
  updChartCustom (item, callback) {
    this.transformEls(item, false)[__C.CHART.CAT_CODE_CUSTOM](item, false)
    this.chartLibraryApi.updChartCustom(item, res => { callback(res) })
  }
  delChartCustom (chartNo, callback) {
    this.chartLibraryApi.delChartCustom(chartNo, res => { callback(res) })
  }
}


