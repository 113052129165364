export default {
  layout: {
    spacing: 20,
    padding: 10,
    background: '#f5f5f5',
    dimention:{
      width: 100,
      height: 100
    }
  },
  container: {
    containerNo: '',

    title: '',
    titleX: 10,
    titleY: 5,
    font: 'roboto',
    fontStyle: 'regular',
    fontSize: 13,
    color: '#000',

    column: { start: 1, span: 1 },
    row: { start: 1, span: 1 },
    width: 100,
    height: 100,
    borderWeight: 1,
    borderColor: '#E0E0E0',
    fillColor: '#fff',

    // By the action of appending a Chart-Item including
    // Controller, 'items'and its properties will be filled
    // from the DASHBOARD-BUILDER.
    items: []
  },
  more: {
    useMenuComponent: 'N',
    menuComponentIdx: 0
  },
  item: {
    catCode   : '',
    chartNo   : '',
    chartType : '',
    name      : '',
    role      : '',   // chart | controller
    roleNo    : '',   // chartNo + random string
    config    : '',   // JSON format
    action    : '',   // JSON format
    sql       : '',
    width     : 0,
    height    : 0,
    x         : 0,
    y         : 0,
  }
}
