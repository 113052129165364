import * as d3 from 'd3'

export default {
  methods: {

    draw_Header() {

      this.DataTable = this.svg
      .append('g')
      .attr('transform', `translate(${this.Header.x}, ${this.Header.y})`)


      // Heager Line
      this.DataTable
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', this.TableWidth).attr('height', this.Header.height)
      .attr('fill', this.Header.background)
      .attr('opacity', this.Header.opacity)

      this.DataTable
      .append('line')
      .attr('x1', 0).attr('y1', .5)
      .attr('x2', this.TableWidth).attr('y2', .5)
      .attr('stroke', this.Header.line.color)
      .attr('stroke-width', this.Header.line.size)

      this.DataTable
      .append('line')
      .attr('x1', 0).attr('y1', this.Header.height + .5)
      .attr('x2', this.TableWidth).attr('y2', this.Header.height +.5)
      .attr('stroke', this.Header.line.color)
      .attr('stroke-width', this.Header.line.size)

      let x_ = 0
      this.Columns.forEach(d => {
        let split = d.NAME.split('/')

        this.DataTable
        .append('text')
        .attr('x', x_ + (d.WIDTH/2))
        .attr('y', () => {
          if (split.length == 1) return (this.Header.height/2)
          else {
            return (this.Header.height/2) - 1
          }
        })
        .attr('font-weight',this.Header.font.weight)
        .attr('font-size', this.Header.font.size)
        .attr('fill', this.Header.font.color)
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', split.length == 1 ? 'middle' : 'alphabetic') // ideographic
        .text(split[0])
        
        if (split.length > 1) {
          this.DataTable
          .append('text')
          .attr('x', x_ + (d.WIDTH/2))
          .attr('y', (this.Header.height/2) + 2)
          .attr('font-weight',this.Header.font.weight)
          .attr('font-size', this.Header.font.size - 1)
          .attr('fill', 'gray')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'hanging') // ideographic
          .text(split[1])
        }

        x_ += d.WIDTH
      })
    },



  }
}