import * as d3 from 'd3'

export default {
  methods: {

    create_Button(selection, bus, d) {
      let btn = selection
      .append('g')
      .attr('id', `${d.id}`)
      .attr('cursor', 'pointer')
      .on('click', () => {
        bus.$emit(`${d.event}`, d.target)
      })
      
      btn
      .append('rect')
      .attr('id', `${d.id}_box`)
      .attr('x', d.x).attr('y', d.y)
      .attr('width', d.width).attr('height', d.height)
      .attr('fill', d.boxColor).attr('stroke', 'none')

      btn
      .append('text')
      .attr('x', d.x + d.width / 2).attr('y', d.y + 15)
      .attr('font-size', 12).attr('fill', '#999')
      .attr('alignment-baseline', 'middle').attr('text-anchor', 'middle')
      .text(d.text)
    },

    change_Color(d) {
      d.boxColor == 'lightblue' ? d.boxColor = 'pink' : d.boxColor = 'lightblue'
      d3.select(`#${d.id}_box`).attr('fill', d.boxColor)
    },

  }
}