/**
 * Event Call Functions
 */

export default {
  methods: {
    callHandlerTextLinkAction(selection, d) {
      let ratio = 1.3
      // let elrect = selection.node().getBoundingClientRect()
      let color = selection.style('fill')
      let vanchor = selection.attr('text-anchor') || selection.style('text-anchor')
      let next = {
        x: d.x,
        y: d.y,
      }

      if(vanchor == 'start') {
        next.y = d.y + Math.round(d.y * .01)
      } else if(vanchor == 'middle') {
        // 
      } else {
        next.y = d.y + Math.round(d.y * .01)
      }

      selection
      .on('mouseover', () => {
        selection
        .transition().duration(100)
        .attr('transform', `translate(${next.x}, ${next.y}) scale(${ratio})`)
        .style('fill', '#EC407A')
      })
      .on('mouseout', () => {
        selection
        .transition().duration(100)
        .attr('transform', `translate(${d.x}, ${d.y}) scale(1)`)
        .style('fill', `${color}`)
      })
      .on('click', () => { this.$emit('request-action', d.request) })
    },
  }
}