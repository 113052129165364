import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {

    init_gmx_SkylineValues_Week(dataSet_, d_) {
      
      if(dataSet_.length === 0) {
        console.log('[USER: setTimelineEnv()] Timeline data is not available in the "DateItems".')
        return
      }
    
      let dates_ = dataSet_.map(m_ => moment(m_[d_.refColumn]).toDate()) // data값만 가져온다
      
      // Timeline의 시작 날짜와 종료날짜의 Cutoff 요일의 날짜를 구한다.

      let sDate_ = moment(d_.startDate).toDate()
      let eDate_ = moment(moment(d_.endDate).format('YYYY-MM-DD 23:59:59')).toDate()

      let weekNames = ['sunday', 'monday', 'tuesday', 'wednesday ', 'thursday', 'friday', 'saturday']
      let cutoff_Week = weekNames.findIndex(n_ => n_ == d_.weekEnd)

      let diff_ = cutoff_Week - sDate_.getDay()
      sDate_ = moment(sDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      diff_ = cutoff_Week - eDate_.getDay()
      eDate_ = moment(eDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()



      /* ### Set Timeline-Values for the Week ###
        this.timeline.week {
          name: , sDate: , mDate: , eDate: 
        }
      */
      let nextDate = moment(moment(sDate_).format('YYYY-MM-DD 23:59:59')).toDate()
      while(nextDate <= eDate_) {
        let value_ = {
          sDate: moment(moment(nextDate).add(-6, 'days').format('YYYY-MM-DD')).toDate(),
          mDate: null,
          eDate: nextDate
        }
        value_.mDate = new Date(d3.mean([value_.sDate, value_.eDate]))
        
        this.timeline.week.push({ name: value_.eDate.getDate(), ...value_ })
        nextDate = moment(nextDate).add(7, 'days').toDate()
      }
      

      /* ### Set Timeline-Values for the Month ###
        this.timeline.week {
          name: , sDate: , mDate: , eDate: 
        }
      */
      eDate_ = null
      let months_ = []
      let months__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y-%m')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y-%m')(w_.eDate)

          if(i > 0) {
            months_.push(months__)
            months__ = []
          }
        }
        months__.push(w_)
        if(i === this.timeline.week.length - 1) months_.push(months__)
      })
      months_.forEach(m_ => {
        this.timeline.month.push({
          name: d3.timeFormat('%b')(m_[0].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })



      /* ### Set Timeline-Values for the Year ###
        this.timeline.week {
          name: , sDate: , mDate: , eDate: 
        }
      */
      eDate_ = null
      let years_ = []
      let years__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y')(w_.eDate)

          if(i > 0) {
            years_.push(years__)
            years__ = []
          }
        }
        years__.push(w_)
        if(i === this.timeline.week.length - 1) years_.push(years__)
      })
      years_.forEach(m_ => {
        this.timeline.year.push({
          name: d3.timeFormat('%Y')(m_[m_.length - 1].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })

      // ### Set necessary ENV-Values for the Skyline ### -------------------
      this.timeline.length = this.timeline.week.length * (d_.skyBox_Width + (d_.skyBox_Gap * 2))
      this.timeline.weekSize = d_.skyBox_Width + (d_.skyBox_Gap * 2)
   
      // get min & max values of the timeline-scale
      this.timeline.scale = d3.scaleTime()
      .domain([
        this.timeline.week[0].sDate, 
        this.timeline.week[this.timeline.week.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.length])
    },

    
    init_gmx_SkylineValues_Week2(dataSet_) {
      
      if(dataSet_.length === 0) {
        console.log('[USER: setTimelineEnv()] Timeline data is not available in the "DateItems".')
        return
      }
    
      let dates_ = dataSet_.map(m_ => moment(m_[this.timeline.refColumn]).toDate()) // data값만 가져온다
      
      // Timeline의 시작 날짜와 종료날짜의 Cutoff 요일의 날짜를 구한다.

      let sDate_ = moment(this.timeline.startDate).toDate()
      let eDate_ = moment(moment(this.timeline.endDate).format('YYYY-MM-DD 23:59:59')).toDate()

      let weekNames = ['sunday', 'monday', 'tuesday', 'wednesday ', 'thursday', 'friday', 'saturday']
      let cutoff_Week = weekNames.findIndex(n_ => n_ == this.timeline.weekEnd)

      let diff_ = cutoff_Week - sDate_.getDay()
      sDate_ = moment(sDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()

      diff_ = cutoff_Week - eDate_.getDay()
      eDate_ = moment(eDate_).add(diff_ < 0 ? 7 + diff_ : diff_, 'days').toDate()



      /* ### Set Timeline-Values for the Week ###
        this.timeline.week {
          name: , sDate: , mDate: , eDate: 
        }
      */
      let nextDate = moment(moment(sDate_).format('YYYY-MM-DD 23:59:59')).toDate()
      while(nextDate <= eDate_) {
        let value_ = {
          sDate: moment(moment(nextDate).add(-6, 'days').format('YYYY-MM-DD')).toDate(),
          mDate: null,
          eDate: nextDate
        }
        value_.mDate = new Date(d3.mean([value_.sDate, value_.eDate]))
        
        this.timeline.week.push({ name: value_.eDate.getDate(), ...value_ })
        nextDate = moment(nextDate).add(7, 'days').toDate()
      }
      

      /* ### Set Timeline-Values for the Month ###
        this.timeline.week {
          name: , sDate: , mDate: , eDate: 
        }
      */
      eDate_ = null
      let months_ = []
      let months__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y-%m')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y-%m')(w_.eDate)

          if(i > 0) {
            months_.push(months__)
            months__ = []
          }
        }
        months__.push(w_)
        if(i === this.timeline.week.length - 1) months_.push(months__)
      })
      months_.forEach(m_ => {
        this.timeline.month.push({
          name: d3.timeFormat('%b')(m_[0].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })



      /* ### Set Timeline-Values for the Year ###
        this.timeline.week {
          name: , sDate: , mDate: , eDate: 
        }
      */
      eDate_ = null
      let years_ = []
      let years__ = []
      this.timeline.week.forEach((w_, i) => {
        if(eDate_ != d3.timeFormat('%Y')(w_.eDate)) {
          eDate_ = d3.timeFormat('%Y')(w_.eDate)

          if(i > 0) {
            years_.push(years__)
            years__ = []
          }
        }
        years__.push(w_)
        if(i === this.timeline.week.length - 1) years_.push(years__)
      })
      years_.forEach(m_ => {
        this.timeline.year.push({
          name: d3.timeFormat('%Y')(m_[m_.length - 1].eDate), 
          sDate: m_[0].sDate, 
          mDate: new Date(d3.mean([ m_[0].sDate, m_[m_.length - 1].eDate ])),
          eDate: m_[m_.length - 1].eDate
        })
      })

      // ### Set necessary ENV-Values for the Skyline ### -------------------
      this.timeline.length = this.timeline.week.length * (this.skyline.skyBox.width + (this.skyline.skyBox.gap * 2))
      this.timeline.weekSize = this.skyline.skyBox.width + (this.skyline.skyBox.gap * 2)
   
      // get min & max values of the timeline-scale
      this.timeline.scale = d3.scaleTime()
      .domain([
        this.timeline.week[0].sDate, 
        this.timeline.week[this.timeline.week.length - 1].eDate
      ])
      .rangeRound([0, this.timeline.length])
    },


    get_gmx_TimelineCDate_Week(date) {
      let d_ = new Date(date)
      let cdate = this.timeline.week.find(f => d_ >= f.sDate && d_ <= f.eDate)
      // return cdate ? moment(moment(cdate.eDate).format('YYYY-MM-DD')).toDate() : null
      return cdate ? cdate : null
    },


    get_gmx_MatchTimeline(wk_, gap_) {

      // Skyline의 x축의 길이와 Chart의 x축의 길이를 일치시키기
      function get_WeekendDate(date_, wk_) {
        let weekNames = ['sunday', 'monday', 'tuesday', 'wednesday ', 'thursday', 'friday', 'saturday']
        let cutoff_Week = weekNames.findIndex(n_ => n_ == wk_)
        let cDate = moment(date_).toDate()
        let diff = cutoff_Week - cDate.getDay()
        cDate = moment(cDate).add(diff < 0 ? 7 + diff : diff, 'days').toDate()
        return cDate
      }
      
      let startDate = moment(get_WeekendDate(this.timeline.endDate, wk_))
      let endDate = moment(get_WeekendDate(this.timeline.startDate, wk_))

      let noWeek = startDate.diff(endDate, 'days')/7 + 1
      let weeksize = this.canvas.chart.width / noWeek - (gap_ * 2)
      return weeksize

      // this.init_gmx_SkylineValues_Week(
      //   this.rawData, 
      //   {
      //     refColumn:    this.timeline.refColumn,
      //     startDate:    this.timeline.startDate,
      //     endDate:      this.timeline.endDate,
      //     weekEnd:      'friday',
      //     skyBox_Width: this.get_gmx_MatchTimeline('friday', 1), // *** week
      //     skyBox_Gap:   1 // *** week
      //   }
      // )
    },

























    
    loadSvg(selection, data) {
      return d3.xml(`${this.__HOST_NAME_RESOURCE}${data.url}`).then(source => {
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },



















  }

}