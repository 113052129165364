<template>
  <div :id="localId"></div>
</template>

<script>
import * as d3 from 'd3'
import Defs from '../../includes/primitives/Color_Defs'     // SVG Color Defines for Gradation Effect
import mx_Core from './includes/mixins/importer'            // Global & Local Mixins

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,
    ChartType: String,

    // Chart Data
    Database: { type: Object, default: () => ({}) },
    Canvas: { type: Object, default: () => ({}) },
    Legends: { type: Object, default: () => ({}) },
    Circle: { type: Object, default: () => ({}) },
    Value: { type: Array, default: () => ([]) },

    // Database
    DataItems: { type: Array, default: () => ([]) },

    values: {
      type: Object,
      default: () => ({
        milestone: [],
        note: ''
      })
    }
  },
  created() {
    this.localId = `${this.id || 'j-chart-donut'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()

      this.setDefaultValues()
      this.drawCanvas()
      this.drawChart()
      
      this.complete()
    },

    drawChart() {

        this.draw_DonutPie()
        this.draw_IconGrid()
        this.draw_Gauges()
        this.draw_Values(0)
        this.draw_LegendsPie(0)

    },
  }
}
</script>