import { SystemCodeApi } from './api'

export default class SystemService {
    
  constructor() {
    this.systemCodeApi = new SystemCodeApi()
  }

  chartOptions (catCode, chartType, callback) {
    this.systemCodeApi.chartOptions(catCode, chartType, res => { callback(res) })
  }
  datatableOptions (catCode, callback) {
    this.systemCodeApi.datatableOptions(catCode, res => { callback(res) })
  }
  dashboardOptions (catCode, callback) {
    this.systemCodeApi.dashboardOptions(catCode, res => { callback(res) })
  }

  companies(sysadmin, callback) {
    this.systemCodeApi.companies(sysadmin, (res) => { callback(res) })
  }
  // compUsers(compName, permit, callback) {
  //   this.systemCodeApi.compUsers(compName, permit, res => { callback(res) })
  // }
  compUsers(params) {

    return this.systemCodeApi.compUsers(params)
    // return this.systemCodeApi.compUsers(compName, permit).then(res => {
    //   return new Promise(resolve => {
    //     resolve(res)
    //   })
    // })
  }

  department(callback) {
    this.systemCodeApi.department(res => { callback(res) })
  }
  
  iconGroup (callback) {
    this.systemCodeApi.iconGroup(res => { callback(res) })
  }
  permissionGroup (callback) {
    this.systemCodeApi.permissionGroup(res => { callback(res) })
  }
  position (callback) {
    this.systemCodeApi.position(res => { callback(res) })
  }
  serviceStatus (callback) {
    this.systemCodeApi.serviceStatus(res => { callback(res) })
  }
  subItemPhase (callback) {
    this.systemCodeApi.subItemPhase(res => callback(res))
  }
  subItemGroup (phaseCode, callback) {
    this.systemCodeApi.subItemGroup(phaseCode, res => callback(res))
  }
  subItemItem (phaseCode, groupCode, callback) {
    this.systemCodeApi.subItemItem(phaseCode, groupCode, res => callback(res))
  }
  sysColumns (dbName, tableName, callback) {
    this.systemCodeApi.sysColumns(dbName, tableName, res => { callback(!res ? [] : res) })
  }
  sysColumnsFromQuery (params, callback) {
    this.systemCodeApi.sysColumnsFromQuery(params, res => { callback(!res ? [] : res) })
  }
  sysTables (dbName, schemaName, callback) {
    this.systemCodeApi.sysTables( dbName, schemaName, (res) => { callback(res) })
  }
  sysDatabases (callback) {
    this.systemCodeApi.sysDatabases(res => callback(res))
  }
  userApproval (callback) {
    this.systemCodeApi.userApproval(res => {
      callback(res)
    })
  }
  galleryCodeOptions(callback) {
    this.systemCodeApi.galleryCodeOptions(res => {
      callback(res)
    })
  }
  getNavList (callback) {
    this.systemCodeApi.getNavList(res => callback(res.data))
  }
  getDataSourceList (callback) {
    this.systemCodeApi.getDataSourceList(res => callback(res.data))
  }
  getNotification (callback) {
    this.systemCodeApi.getNotification(res => callback(res.data))
  }
  getUrlData (callback) {
    this.systemCodeApi.getUrlData(res => { callback(res.data) })
  }
  getCommentList (callback) {
    this.systemCodeApi.getCommentList(res => { callback(res.data) })
  }
  getComment (callback) {
    this.systemCodeApi.getComment(res => { callback(res.data) })
  }
  putNotification (params, callback) {
    this.systemCodeApi.putNotification(params, res => callback(res))
  }
  upNotification (params, callback) {
    this.systemCodeApi.upNotification(params, res => callback(res))
  }
  delNotification (params, callback) {
    this.systemCodeApi.delNotification(params, res => callback(res))
  }

}
