import __C from '../primitives/_constant_'
import { ApplicationApi } from './api'
import { ChartLibraryService } from '../services'

export default class ApplicationService {
    
  constructor() {
    this.applicationApi = new ApplicationApi()
    this.chartLibService = new ChartLibraryService()
  }

  createExport(params, callback) {
    this.applicationApi.createExport(params, res => callback(res))
  }
  createExcel(params, callback) {
    this.applicationApi.createExcel(params, res => callback(res))
  }

  getNavigationMenu (callback, permitGroups) {
    this.applicationApi.getNavigationMenu((res) => {
      if(!res) {
        res = { route: [], state: [] }
        callback(res)
        return
      }

      let phases = res.filter(m => m.type == 'Phase')
      let groups = res.filter(m => m.type == 'Group')
      let items = res.filter(m => m.type == 'Item')
      let subitems = res.filter(m => m.type == 'Sub-Item')

      let state = [ ...items, ...subitems ].map(item => {
        return {
          id: item.idx, 
          name: item.itemName, 
          code: item.itemCode, 
          groupName: item.groupName, 
          groupCode: item.groupCode, 
          parentName: item.phaseName, 
          parentCode: item.phaseCode,
          url: item.url
        }
      })

      items.forEach(t => {
        t.children = subitems.filter(s => s.phaseCode == t.phaseCode && s.groupCode == t.groupCode && s.itemCode == t.itemCode)
      })
      groups.forEach(g => {
        g.children = items.filter(t => t.phaseCode == g.phaseCode && t.groupCode == g.groupCode)
      })
      phases.forEach(p => {
        p.children = groups.filter(g => g.phaseCode == p.phaseCode)
      })
      callback({ route: phases, state })

    }, permitGroups)
  }
  requestDataCollectionIDG(params, callback) {
    this.applicationApi.requestDataCollectionIDG(params, res => {
      // responded data structure
      // {
      //   filterChains: [],   // LNB Filtering Chains
      //   filterOptions: [],  // LNB Filtering Options
      //   chartValues: [],    // SVG JSON Props and Aggregate values for the Charts 
      //   dataProps: [],      // IDG Configuration values
      //   dataList: [],       // Intended DataGrid List
      //   pagination: {},     // Pagination Info including the Total Rows
      //   summaryData: [],    // Summary by the current filters
      //   titleData: {},      // Title Query Result
      //   tableAttrs: {},     // Data-List Display Options
      //   tableType: ',       // Data-Table Type
      // }
      if(!res) callback({
        info: {},
        filterChains: [],
        filterOptions: [],
        chartValues: [],
        dataProps: [],
        dataList: [],
        pagination: {},
        summaryData: [],
        titleData: {},
        tableAttrs: { title: {}, header: {}, body: {}, group: {}, style: {}, svg: {} },
        catCode: '',
        tableType: '',

      }); else callback({
        info: res.info ? JSON.parse(res.info) : {},
        filterChains: res.filterChains ? JSON.parse(res.filterChains) : [],
        filterOptions: res.filterOptions ? JSON.parse(res.filterOptions) : [],
        chartValues: res.chartValues ? JSON.parse(res.chartValues) : [],
        dataProps: res.dataProps ? JSON.parse(res.dataProps) : [],
        dataList: res.dataList ? JSON.parse(res.dataList) : [],
        pagination: res.pagination ? JSON.parse(res.pagination) : {},
        summaryData: res.summaryData ? JSON.parse(res.summaryData) : [],
        titleData: res.titleData ? JSON.parse(res.titleData) : {},
        tableAttrs: res.tableAttrs ? JSON.parse(res.tableAttrs) : { title: {}, header: {}, body: {}, group: {}, style: {}, svg: {} },
        catCode: res.catCode,
        tableType: res.tableType,
      })
    })
  }
  requestPatDataCollectionIDG(params, callback) {
    this.applicationApi.requestPatDataCollectionIDG(params, res => { 
      if(!res) callback({
        chartValues: [],
        dataList: [],
      }); else callback({
        chartValues: res.chartValues ? JSON.parse(res.chartValues) : [],
        dataList: res.dataList ? JSON.parse(res.dataList) : [],
      })
    })
  }
  requestChartValues(params, callback) {
    this.applicationApi.requestChartValues(params, res => { callback(res) })
  }
  requestFilteredChainOptions(params, callback) {
    this.applicationApi.requestFilteredChainOptions(params, res => { 
      if(!res || !res.chainOptions) callback({ chainOptions: [] })
      else callback({ chainOptions: JSON.parse(res.chainOptions) })
    })
  }

  // Dashboard Service ################################################
  getDashboard(params, callback) {
    this.applicationApi.getDashboard(params, res => {
      // responded data structure
      // {
      //   dashboard: [             // Dashboard Configuration values
      //     containerAttrs: [],
      //     layoutAttrs: {},
      //     moreAttrs: {},
      //   ],      
      //   chartItems: [],          // The properties for all the charts included in the dashboard 
      //   filterChainOptions: [],  // LNB Filtering Chains
      //   filterOptions: [],       // LNB Filtering Options
      //   filterCompoment: {},     // LNB Filtering Component
      // }
      if(!res) callback({ 
        dashboard: {
          containerAttrs: [],
          layoutAttrs: {},
          moreAttrs: {},
        }, 
        chartItems: [],
        filters: {},
        filterChainOptions: [],
        filterOptions: [],
        filterCompoment: {},

      }); else {
        if(res.dashboard) {
          var dashboard_ = JSON.parse(res.dashboard)
          dashboard_.containerAttrs = JSON.parse(dashboard_.containerAttrs)
          dashboard_.layoutAttrs = JSON.parse(dashboard_.layoutAttrs)
          dashboard_.moreAttrs = dashboard_.moreAttrs ? JSON.parse(dashboard_.moreAttrs) : {}

        } else {
          dashboard_ = {
            containerAttrs: [],
            layoutAttrs: {},
            moreAttrs: {},
          }
        }
        
        if(res.chartItems) {
          var chartItems_ = JSON.parse(res.chartItems)

          chartItems_.forEach(chart => { 
            if([
              __C.CHART.CAT_CODE_CUSTOM,
              __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER

              // These items won't be used in the Dashboard feataures,
              // tt will be used for Page-Svg Features.
              // __C.CHART.CAT_CODE_COMPLIB_SVG_TAB,
              // __C.CHART.CAT_CODE_COMPLIB_SVG_PAGE,
              // __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION,

            ].includes(chart.CatCode)) var catCode__ = __C.CHART.CAT_CODE_CUSTOM
            else catCode__ = chart.CatCode

            this.chartLibService.transformEls(chart)[catCode__](chart)
          })
        } else chartItems_ = []
        
        callback({ 
          dashboard: dashboard_, 
          chartItems: chartItems_, 
          filters: res.filters ? JSON.parse(res.filters) : {},
          filterChainOptions: res.filterChainOptions ? JSON.parse(res.filterChainOptions) : [],
          filterOptions: res.filterOptions ? JSON.parse(res.filterOptions) : [],
          filterCompoment: res.filterCompoment ? JSON.parse(res.filterCompoment) : {},
        })
      }
    })
  }

  // Dashboard Container Service ####################################
  getContainer(params, callback) {
    this.applicationApi.getContainer(params, res => {
      if(!res) callback({ 
        container: {}, 
        chartItems: []

      }); else {
        if(res.chartItems) {
          var chartItems_ = JSON.parse(res.chartItems)

          chartItems_.forEach(chart => { 
            if([
              __C.CHART.CAT_CODE_CUSTOM,
              __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER,
            ].includes(chart.CatCode)) var catCode__ = __C.CHART.CAT_CODE_CUSTOM
            else catCode__ = chart.CatCode
            this.chartLibService.transformEls(chart)[catCode__](chart)
          })
        } else chartItems_ = []
        
        callback({ 
          container: JSON.parse(res.container), 
          chartItems: chartItems_
        })
      }
    })
  }

  getContainerActionQResult(params, callback) {
    // @@params   -------------------------------------------------
    // @idx       Dashboard Idx
    // @action    REQUEST-ACTION
    // @filters   Filtered Value

    this.applicationApi.getContainerActionQResult(params, res => {
      if(!res) {
        callback([])
        return
      }
      
      res.forEach(item => { this.chartLibService.transform(item) })
      callback(res)
    })
  }

  // Page Svg Service ###############################################
  getPageSvg(params, callback) {
    // @@params   -------------------------------------------------
    // @idx       Page Svg Idx / Navigation Menu Item(Sub-Item) IDX
    // @fValues   Filtered Value
    // @reqType   Requested Type : init | filtered

    this.applicationApi.getPageSvg(params, res => {
      // responded data structure
      // {
      //   chartItem: {},           // The properties for a SVG as the Page
      //   filters: {}              // Initial Values for the global filters if it is not set.
      //   filterChainOptions: [],  // LNB Filtering Chains
      //   filterOptions: [],       // LNB Filtering Options
      // }
      if(!res) callback({ 
        chartItem: {},
        filters: {},
        filterChainOptions: [],
        filterOptions: [],
        filterCompoment: {}
      }); else {
        res.chartItem = res.chartItem ? JSON.parse(res.chartItem) : {}

        if([
          // __C.CHART.CAT_CODE_CUSTOM,
          // __C.CHART.CAT_CODE_COMPLIB_SVG_CHART_CONTROLLER,
          // __C.CHART.CAT_CODE_COMPLIB_SVG_NAVIGATION,
          // __C.CHART.CAT_CODE_COMPLIB_SVG_TAB,
          __C.CHART.CAT_CODE_COMPLIB_SVG_PAGE

        ].includes(res.chartItem.CatCode)) var catCode__ = __C.CHART.CAT_CODE_CUSTOM
        else catCode__ = res.chartItem.CatCode

        if (!catCode__) return

        this.chartLibService.transformEls(res.chartItem)[catCode__](res.chartItem)

        callback({ 
          chartItem: res.chartItem, 
          filters: res.filters ? JSON.parse(res.filters) : {},
          filterChainOptions: res.filterChainOptions ? JSON.parse(res.filterChainOptions) : [],
          filterOptions: res.filterOptions ? JSON.parse(res.filterOptions) : [],
          filterCompoment: res.filterCompoment ? JSON.parse(res.filterCompoment) : {},
        })
      }
    })
  }
  
  // Other Service ###############################################
  putRequestHistory(params) {
    return this.applicationApi.putRequestHistory(params)
  }

  LevedRequestHistory(params) {
    return this.applicationApi.LevedRequestHistory(params)
  }

  uniqMember(userId) {
    return this.applicationApi.uniqMember(userId).then(res => {
      return new Promise(resolve => {
        resolve(res)
      })
    })
  }

  forgotMember(params) {
    return this.applicationApi.forgotMember(params).then(res => {
      return new Promise(resolve => {
        resolve(res)
      })
    })
  }

  reqMember(params) {
    return this.applicationApi.reqMember(params)
  }
  // getRequestHistory(sysadmin, compName, userId, permit) {
  //   return this.applicationApi.getRequestHistory(sysadmin, compName, userId, permit).then(res => {
  //     if(!res) res = []

  //     let prevItem = {}
  //     res.forEach(item => {
  //       let item_ = { ...item }
  //       if(prevItem.PHASE_NAME == item.PHASE_NAME) item.PHASE_NAME = ''
  //       if(prevItem.GROUP_NAME == item.GROUP_NAME) item.GROUP_NAME = ''
  //       if(prevItem.ITEM_NAME == item.ITEM_NAME) item.ITEM_NAME = ''
  //       prevItem = item_
  //     })

  //     return new Promise(resolve => { resolve(res) })
  //   })
  // }
  getRequestHistory(params) {
    return this.applicationApi.getRequestHistory(params).then(res => {
      if(!res) res = []
 
      let prevItem = {}
      res.forEach(item => {
        let item_ = { ...item }
        if(prevItem.PHASE_NAME == item.PHASE_NAME) item.PHASE_NAME = ''
        if(prevItem.GROUP_NAME == item.GROUP_NAME) item.GROUP_NAME = ''
        if(prevItem.ITEM_NAME == item.ITEM_NAME) item.ITEM_NAME = ''
        prevItem = item_
      })

      return new Promise(resolve => { resolve(res) })
    })
  }
  putBoardReport(params, config) {
    return this.applicationApi.putBoardReport(params)
  }
  getBoardReport(idx) {
    return this.applicationApi.getBoardReport(idx).then(res => {
      return new Promise(resolve => {
        if(!res) res = {}
        else res.files = res.files ? JSON.parse(res.files) : []
        resolve(res)
      })
    })
  }
  getBoardReports(catcode, groupcode) {
    return this.applicationApi.getBoardReports(catcode, groupcode).then(res => {
      return new Promise(resolve => {
        resolve(res || [])
      })
    })
  }
  updBoardReport(params, config) {
    return this.applicationApi.updBoardReport(params, config).then(res => {
      return new Promise(resolve => {
        resolve(res || [])
      })
    })
  }
  delBoardReport(idx) {
    return this.applicationApi.delBoardReport(idx)
  }
  delBoardReportFile(idx) {
    return this.applicationApi.delBoardReportFile(idx)
  }

  //Taps Item Check
  getTabs (params, callback) {
      this.applicationApi.getTabs(params, res => { 
        if(callback) callback(res) 
      })
  }
}