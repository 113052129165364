export default {
  watch: {
    ChartType(typeName) { if(!typeName) return; this.register(this.draw); },
    
    DataItems: { handler(val) { this.register(this.draw); }, deep: true },
    Database: { handler() { this.register(this.draw) }, deep: true },
    Canvas: { handler() { this.register(this.draw) }, deep: true },
    Title: { handler() { this.register(this.draw) }, deep: true },
    Note: { handler() { this.register(this.draw) }, deep: true },

    Timeline: { handler() { this.register(this.draw) }, deep: true },
    AxisY: { handler() { this.register(this.draw) }, deep: true },
    Boxes: { handler() { this.register(this.draw) }, deep: true },
    Status: { handler() { this.register(this.draw) }, deep: true },
    Line: { handler() { this.register(this.draw) }, deep: true },
    Milestone: { handler() { this.register(this.draw) }, deep: true },
    Legends: { handler() { this.register(this.draw) }, deep: true },
  },
}
