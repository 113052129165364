import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Block(_d) {

      this.model_Block = this.svg
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      let info = this.model_Block
      .append('g')
      .attr('transform', `translate(40, 370)`)
      .attr('font-family', 'roboto')

      info
      .append('text')
      .attr('x', 0).attr('y', 0)
      .attr('font-size', 14).attr('fill', '#000')
      .text(`Area: ${_d.AREA}`)

      info
      .append('text')
      .attr('x', 0).attr('y', 20)
      .attr('font-size', 14).attr('fill', '#000')
      .text(`Block No: ${_d.BLK}`)

      info
      .append('text')
      .attr('x', 0).attr('y', 40)
      .attr('font-size', 14).attr('fill', '#000')
      .text(`${_d.TITLE}`)









      let model = this.model_Block
      .append('svg')
      .attr('width', 1100).attr('height', 700)
      
      .append('g')
      .attr('id', `MODEL_BLOCK`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      if (this.current.area == 'Hull') {
        this.loadSvg(
          model, {
          x   : 0,
          y   : 0,
          url : `/Visualization/BlockDivision/${this.current.area}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(400,350) scale(.8)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.draw_Block_Canvas(_d) // ----->
        })
      }
      
      if (this.current.area == 'Topside' || this.current.area == 'LQ') {
        this.loadSvg(
          model, {
          x   : 0,
          y   : 0,
          url : `/Visualization/BlockDivision/Topside/${_d.AREA}/${_d.BLK}.svg`
        }).then(() => {

          d3.selectAll("text").style('font-family', 'roboto')
          d3.select(`#MODEL_BLOCK`).attr('transform', `translate(200,310) scale(.9)`).style('opacity', 0)
          d3.select(`#MODEL_BLOCK`).transition().duration(500).style('opacity', 1)

          this.draw_Block_Canvas(_d) // ----->
        }) 
      }

      // let info = this.model_Block
      // .append('g')
      // .attr('id', `BLOCK_INFO`)
      // .attr('transform', `translate(40,500)`)
      
      

      console.log(_d)





    },

  }
}