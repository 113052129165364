import * as d3 from 'd3'
import { finished } from 'stream'

export default {
  methods: {
    // ICON
    draw_Icon(selection, d, c, py, ly, value) {
      let styleSet = this.Styles.find(f => f.type == 'Icon' && f.series == this.clone_Columns[c].series)
      let codeSet = styleSet.codes.find(f => f.code == this.DataItems[d][styleSet.refColumn])
      
      selection
      .append('image')
      .attr('xlink:href', () => {
        if (value > 0) return require('../../../../src/assets/svg/Prog_Up.svg')
        if (value == 0) return require('../../../../src/assets/svg/Prog_Zero.svg')
        if (value < 0) return require('../../../../src/assets/svg/Prog_Down.svg')
      })
      .attr('x', () => {
        if (styleSet.align == 'start') return this.clone_Columns[c].x1 + styleSet.paddingX
        if (styleSet.align == 'end') return this.clone_Columns[c].x2 - styleSet.iconSize - styleSet.paddingX
      })
      .attr('y', ly + ((this.Table.TableCellHeight - styleSet.iconSize) / 2))
      .attr('width', styleSet.iconSize).attr('height', styleSet.iconSize)
      
      
      selection
      .append('text')
      .attr('x', () => {
        if (styleSet.align == 'start') return this.clone_Columns[c].x1 + styleSet.paddingX + styleSet.iconSize + 3
        if (styleSet.align == 'end') return this.clone_Columns[c].x2 - styleSet.paddingX - styleSet.iconSize - 3
      })
      .attr('y', py)
      .style('font-family', this.Styles.find(f => f.type == 'Text').textFont)
      .attr('class', this.Styles.find(f => f.type == 'Text').textFont)
      .attr('font-size', styleSet.textSize)
      .attr('fill', codeSet.tColor)
      .attr('text-anchor', styleSet.align)
      .attr('alignment-baseline', 'middle')
      .text(value)
    },


  }
}
