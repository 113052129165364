import * as d3 from 'd3'

export default {
  
  methods: {

    draw_Overall_Canvas() {
      
      this.canvas_Overall = this.svg
      .append('g')
      .attr('id', `CANVAS_Overall`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')


      // this.canvas_Overall
      // .append('text')
      // .attr('transform', `translate(1350, 50)`)
      // .attr('font-size', 24).attr('fill', '#000')
      // .text('CANVAS - OVERALL')


      this.block_Summary({
        selection: this.canvas_Overall,
        id: 'OV',
        x: 700,
        y: 500,
        area: 'OVERALL',
        code: 'CO'
      })




      
      this.selectedItems = {  
        // ...this.selectedItems,
        stage: 'Overall',
        area: this.current.area
      }

    },
    
  }
}