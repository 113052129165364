import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_IconGrid() {
      // --------------------------------------------------------------- Icon
      if (this.Circle.CircleIconDisplay == 'Y') {
        this.svg
          .append('image')
          .attr('xlink:href', this.Circle.CircleIconUrl)
          .attr('x', this.pieCenterX - (this.Circle.CircleIconSize / 2)).attr('y', this.Circle.CircleIconY - (this.Circle.CircleIconSize / 2)).attr('width', this.Circle.CircleIconSize).attr('height', this.Circle.CircleIconSize)
      }

      // --------------------------------------------------------------- Grid
      if (this.Circle.CircleGridDisplay == 'Y') {
        this.svg
          .append('line')
          .attr('x1', this.pieCenterX - (this.Circle.CircleGridLenH / 2)).attr('y1', this.Circle.CircleCenterY)
          .attr('x2', this.pieCenterX + (this.Circle.CircleGridLenH / 2)).attr('y2', this.Circle.CircleCenterY).attr('stroke', this.Circle.CircleGridColor).attr('stroke-width', this.Circle.CircleGridStroke)
        this.svg
          .append('line')
          .attr('x1', this.pieCenterX).attr('y1', this.Circle.CircleCenterY)
          .attr('x2', this.pieCenterX).attr('y2', this.Circle.CircleCenterY + this.Circle.CircleGridLenV).attr('stroke', this.Circle.CircleGridColor).attr('stroke-width', this.Circle.CircleGridStroke)
      }
    },


    draw_Gauges() {
      if (this.Circle.CircleGaugeDisplay == 'Y') {
        let Gauges = this.svg
          .append('g')

        this.Circle.CircleGaugeRadius.forEach((_, i) => {
          let st = 0; let an = 360; let ro = an / this.Circle.CircleGaugeCount[i]
          let Y1 = this.Circle.CircleCenterY - this.Circle.CircleGaugeRadius[i] + 4
          let Y2 = Y1 - (this.Circle.CircleGaugeLength[i] * 1)
          if (this.Circle.CircleIconDisplay == 'Y') { st = 225; an = 495; ro = 270 / this.Circle.CircleGaugeCount[i] }

          for (var n = st;n < an;n += ro) {
            Gauges
              .append('line')
              .attr('x1', this.pieCenterX).attr('y1', Y1)
              .attr('x2', this.pieCenterX).attr('y2', Y2)
              .attr('stroke', this.Circle.CircleGaugeColor[i]).attr('stroke-width', this.Circle.CircleGaugeStroke[i])
              .attr('transform', () => `rotate(${n},${this.pieCenterX},${this.Circle.CircleCenterY})`)
          }
        })
      }
    },


    draw_Values(sn) {
      this.localValue.forEach((value_, i) => {
        if (value_.ValueDisplay != 'Y') return

        let valueText = this.svg
          .append('g')
          .attr('id', `${this.localId}__Value${sn}_${i}`)
          .attr('transform', `translate(${0},${0})`)

        valueText
          .append('text')
          .attr('id', `${this.localId}__Text${sn}_${i}`)
          .attr('class', value_.ValueStyle)
          .style('font-family', value_.ValueFont)
          .style('font-size', value_.ValuetSize)
          .attr('fill', () => value_.ValueColor)
          .attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
          .text(() => this.DataItems[value_.ValueData][value_.ValueType.toLowerCase()])

        if (value_.ValueUnitFormat !== '') {
          valueText
            .append('text')
            .attr('x', this.getNodeElValue(`#${this.localId}__Text${sn}_${i}`, 'width') + 1)
            .style('font-family', value_.ValueFont).style('font-size', value_.ValueUnitSize).attr('fill', value_.ValueColor)
            .attr('text-anchor', 'start').attr('alignment-baseline', 'alphabetic')
            .text(value_.ValueUnitFormat)
        }
        let xPoint = 0
        if (value_.ValueAlign == 'right') xPoint = this.pieCenterX - this.getNodeElValue(`#${this.localId}__Value${sn}_${i}`, 'width') - 4
        if (value_.ValueAlign == 'center') xPoint = this.pieCenterX - (this.getNodeElValue(`#${this.localId}__Value${sn}_${i}`, 'width') / 2)
        if (value_.ValueAlign == 'left') xPoint = this.pieCenterX + 4
        this.svg.select(`#${this.localId}__Value${sn}_${i}`)
          .attr('transform', `translate(${xPoint},${value_.ValueY})`)
      })
    },


    draw_LegendsPie(sn) {

      if (this.Legends.LegendDisplay == 'Y') {
        let self_ = this
        let CumulativeX = 0
        let CumulativeY = 0

        let LegendGroup = this.svg
          .append('g')
          .attr('id', `${this.localId}__LegendGroup`)
          .attr('transform', `translate(${0},${0})`)

        this.filterByType[this.Circle.CircleType](this.DataItems).forEach((value_, i) => {
          let Legends = LegendGroup
            .append('g')
            .attr('id', `${this.localId}__Legend_${i}`)

          if (this.Legends.LegendBulletDisplay == 'Y') {
            Legends
              .append('rect')
              .attr('width', this.Legends.LegendBulletSize).attr('height', this.Legends.LegendBulletSize).attr('fill', this.Circle.CircleFtColor[i])
          }

          Legends
            .append('text')
            .attr('id', `${this.localId}__Text_${i}`)
            .attr('x', this.Legends.LegendBulletSize + this.Legends.LegendBulletSpace).attr('y', (this.Legends.LegendBulletSize / 2) + 1)
            .style('font-family', this.Legends.LegendSeriesFont).style('font-size', this.Legends.LegendSeriesSize).attr('fill', this.Legends.LegendSeriesColor)
            .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(value_.title)

          this.svg.select(`#${this.localId}__Legend_${i}`).attr('transform', `translate(${CumulativeX},${0})`)
          CumulativeX += this.getNodeElValue(`#${this.localId}__Legend_${i}`, 'width') + this.Legends.LegendSeriesSpace
        })
        this.svg.select(`#${this.localId}__LegendGroup`)
          .attr('transform', `translate(${this.pieCenterX - (this.getNodeElValue(`#${this.localId}__LegendGroup`, 'width') / 2)},${this.Circle.CircleCenterY + this.Legends.LegendSpaceY})`)

      }

    },

  }
}