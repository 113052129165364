export default {
  data: () => ({
    rawData:  [],
    
    styles:   {},
    barChart: {},
    columns:  {},
    filters:  {}
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      // Styles
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      // Columns
      let s_ = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      // filter & link columns
      this.filters = JSON.parse(JSON.stringify(this.Queries.SQL2[0])) 

      this.styles.x               = s_.X
      this.styles.y               = s_.Y
      this.styles.hColor          = s_.HEADER_COLOR
      this.styles.hSize           = s_.HEADER_TEXT_SIZE
      this.styles.tSize           = s_.DATA_TEXT_SIZE
      this.styles.tableY          = s_.TABLE_Y
      this.styles.padding         = s_.CELL_PADDING
      this.styles.lineHight       = s_.LINE_HEIGHT

      this.barChart.bColor        = s_.BAR_COLOR
      this.barChart.barWidth      = s_.BAR_WIDTH
      this.barChart.opacity       = s_.BAR_OPACITY


      // Column Properties   
      this.columns.code           = s_.REF_CODE.replace(/\s/g, '').split('/')
      this.columns.lastCode       = this.columns.code[this.columns.code.length-1]
      this.columns.code.pop()     
      this.columns.last_idx       = this.columns.code.length

      this.columns.name           = s_.NAME.replace(/\s/g, '').split('/')
      this.columns.jsonMap        = s_.JSON_MAP.replace(/\s/g, '').split('/')
      this.columns.jsonLink       = s_.JSON_LINK.replace(/\s/g, '').split('/')
      this.columns.filter         = s_.FILTER.replace(/\s/g, '').split('/')
      this.columns.overlap        = s_.OVERLAP.replace(/\s/g, '').split('/')
      this.columns.width          = s_.COL_WIDTH.replace(/\s/g, '').split('/').map(Number)
      this.columns.tColor         = s_.TEXT_COLOR.replace(/\s/g, '').split('/')
      this.columns.align          = s_.ALIGN.replace(/\s/g, '').split('/')

      // Get Position
      this.columns.position  = []
      let x = 0
      this.columns.width.forEach((d,i)=> {
        if (this.columns.align[i] == 'start') this.columns.position.push(x + s_.CELL_PADDING)
        if (this.columns.align[i] == 'middle') this.columns.position.push(x + (d/2))
        if (this.columns.align[i] == 'end') this.columns.position.push(x + d - s_.CELL_PADDING)
        x = x + d
      })

      // Get Max Value
      this.styles.maxValue = Math.max(...this.rawData.map(d => d.TOTAL))
      this.styles.maxValue = this.getMaxBound(this.styles.maxValue)
      
      // Get Grid Height
      this.styles.gridHeight = ((this.rawData.length+1) * this.styles.lineHight) + (this.styles.lineHight / 3)
    },

  }
}