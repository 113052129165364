export default {
  watch: {
    ChartType(typeName) { if(!typeName) return; this.register(this.draw); },
    Database: { handler() { this.register(this.draw) }, deep: true },
    Canvas: { handler() { this.register(this.draw) }, deep: true },

    Table: { handler() { this.register(this.draw) }, deep: true },
    Styles: { handler() { this.register(this.draw) }, deep: true },
    Columns: { handler() { this.register(this.draw) }, deep: true },
    Header: { handler() { this.register(this.draw) }, deep: true },

    DataItems: { handler(val) { this.register(this.draw) }, deep: true },
    Footer: { handler(val) { this.register(this.draw) }, deep: true },
  },
}
