import _configDefault from "@/primitives/_configDefault"

export default {
  namespaced: true,
  state: {
    layout: _configDefault.layout
  },
  getters: {
  },
  mutations: {
    setLayout(state, layout) {
      state.layout = layout
    },
    setLnbVisible(state, visible) {
      state.layout.lnb.visible = visible
    },
  },
  actions: {
  }
}
