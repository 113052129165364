function isWebGLAvailable() {
  try {
      const canvas = document.createElement('canvas');
      return !!(window.WebGLRenderingContext && (canvas.getContext('webgl') || canvas.getContext('experimental-webgl')));
  } catch (e) {
      return false;
  }
}

function getWebGLErrorMessage() {
  const message = document.createElement('div');
  message.id = 'webgl-error-message';
  message.style.fontFamily = 'monospace';
  message.style.fontSize = '13px';
  message.style.fontWeight = 'normal';
  message.style.textAlign = 'center';
  message.style.background = '#fff';
  message.style.color = '#000';
  message.style.padding = '1.5em';
  message.style.width = '400px';
  message.style.margin = '5em auto 0';
  message.innerHTML = window.WebGLRenderingContext ? [
      'Your graphics card does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color:#000">WebGL</a>.<br />',
      'Find out how to get it <a href="http://get.webgl.org/" style="color:#000">here</a>.'
  ].join('\n') : [
      'Your browser does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" style="color:#000">WebGL</a>.<br/>',
      'Find out how to get it <a href="http://get.webgl.org/" style="color:#000">here</a>.'
  ].join('\n');

  return message;
}

export const WEBGL = {
  isWebGLAvailable,
  getWebGLErrorMessage
};
