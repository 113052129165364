import { VCardActions, VForm, VSpacer } from 'vuetify/lib'
import { JButton, JModal } from '..'

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

export default {
  name: 'j-modal-from-group',
  components: {
    JButton,
    JModal,
    VCardActions,
    VForm,
    VSpacer,
  },
  props: {
    activatorOn: Boolean,
    title: String,
    titleDescription: {
      type: String,
      default: 'Modification'
    },
    value: null,
    width: {
      type: [String, Number],
      default: '350'
    },
  },
  data: () => ({
    valid: Boolean
  }),
  medhod: {
    action(type) {
      this.$emit(type)
    },
    formReset() {
      this.$refs.form.reset()
    }
  },
  render () {
    let _self = this
    let _attrs = {
      jModal: {
        attrs: _extends({}, this.$props),
        on: {
          close: () => this.$emit('close'),
          input: (value) => this.$emit('input', value),
        },
        props: {
          value: this.value
        },
        scopedSlots: {
          default: this.$slots.default,
          modalFooter: this.$slots.modalFooter || function (props) {
            return (
              <v-card-actions>
                <v-spacer></v-spacer>
                <j-button class="type01 sky" { ...{ attrs: { disabled: _self.valid }, on: { click: () => _self.action('create') }} }>Create</j-button>
                <j-button class="type01 sky" { ...{ attrs: { disabled: _self.valid }, on: { click: () => _self.action('edit') }} }>Edit</j-button>
                <j-button class="type01 delete" { ...{ on: { click: () => _self.action('delete') }} }>Delete</j-button>
                <j-button class="type01" { ...{ on: { click: () => _self.action('cancel') }} }>cancel</j-button>
              </v-card-actions>
            )     
          }
        }
      },
      vForm: {
        attrs: { value: this.value },
        on: { input: (value) => this.valid = value },
        props: { value: this.value },
      }
    }
    return (
      <j-modal { ..._attrs.jModal }>
        <v-form { ..._attrs.vForm } ref="form">
          { this.$slots.default }
        </v-form>
        { 
          this.$slots.modalFooter ||
          <template slot="modalFooter" slot-scope="props" { ...{ props: { _self: this }}}>
            <v-card-actions>
              <v-spacer></v-spacer>
              <j-button class="type01 sky" { ...{ attrs: { disabled: _self.valid }, on: { click: () => _self.action('create') }} }>Create</j-button>
              <j-button class="type01 sky" { ...{ attrs: { disabled: _self.valid }, on: { click: () => _self.action('edit') }} }>Edit</j-button>
              <j-button class="type01 delete" { ...{ on: { click: () => _self.action('delete') }} }>Delete</j-button>
              <j-button class="type01" { ...{ on: { click: () => _self.action('cancel') }} }>cancel</j-button>
            </v-card-actions>        
          </template>
        }
      </j-modal>
    )
  }
}