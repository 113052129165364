import __C from '../../primitives/_constant_'
import Api from './api'
import Url from './request.url'

export default class ThreedSupportApi extends Api {

  constructor() {
    super()
  }

  getConnectedUsers() {
    return new Promise(resolve => {
      this.axios().get(`${Url.threed.users}`).then(res => { resolve(res.data) })
    })
  }
}