/**
 * Event Call Functions
 */

import * as d3 from 'd3'

export default {
  methods: {
    callFuncSEIInfoAction(selection) {
      selection
      .on('mouseover', (d) => {
        let group = d3.select(`#${this.localId} .seigroup_${d}`)
        group.select('.sei_description')
        .style('fill', '#056fff')
        .style('font-weight', 700)
        .style('font-size', 14)
        .style('text-decoration','underline')

        let seiL2Area = this.seiCodeInfo.find(f => d == f.SEICODE_L2)
          d3.select(`#box${seiL2Area.SVG_SN}`)
          .style('fill', '#57C1EE')
          .style('opacity', 0.3)
      })
      .on('mouseout', (d) => {
        let group = d3.select(`#${this.localId} .seigroup_${d}`)
        group.select('.sei_description')
        .style('fill', this.seiStyle.typeText.fontFill)
        .style('font-weight', this.seiStyle.typeText.fontWeight)
        .style('font-size', this.seiStyle.typeText.fontSize)
        .style('text-decoration','none')

        let seiL2Area = this.seiCodeInfo.find(f => d == f.SEICODE_L2)
          d3.select(`#box${seiL2Area.SVG_SN}`)
          .style('fill', 'none')
          .style('opacity', 1)
      })
      .on('click', (d) => {
        // d: SEICODE_L2
        let group = d3.select(`#${this.localId} .seigroup_${d}`)
        group.select('.sei_description')
        .style('fill', '#056fff')
        .style('font-weight', 700)
        .style('font-size', 13)
        .style('text-decoration','underline')
        
        // If use the touchpad devices -- begin //
        let seiL2Area = this.seiCodeInfo.find(f => d == f.SEICODE_L2)
          d3.select(`#box${seiL2Area.SVG_SN}`)
          .style('fill', '#57C1EE')
          .style('opacity', 0.3)
        // If use the touchpad devices -- end //

          let request = this.buildRequest(d)
        // this.$emit('request-action', request)
      })
    },
    callFuncHomeAction(selection) {
      let request = this.getRequestColumnProps('routeto', selection, this.ColumnProps)
        //let request = this.buildRequest(d)
        this.$emit('request-action', request)
    },
  }
}