import * as d3 from 'd3'
import __M from 'moment'

export default {
  data: () => ({
    dataSet: [],
    dataMinValue1: 0,
    dataMaxValue1: 0,
    dataAvgValue: 0,

    dataMinValue2: 0,
    dataMaxValue2: 0,

    axisYHeight: 160,
    axisXWidth: 500,

    scaleY1: null,
    scaleY2: null,
    lineFunc: null,
    lineZero: null,

    dataXTimelineValues: '',
    dataXTimelineValuesMonth: '',

    tickValues1: [],
    tickValues2: [],
    lastDate: 0,
    lastValue: 0,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.timelineFor = 'history'
      this.timelineKeyName = 'CDATE'

      this.timeline.baseX = this.Canvas.CanvasChartX
      this.timeline.baseY = this.Canvas.CanvasChartY + this.axisYHeight
      this.timeline.width = this.axisXWidth

      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      this.setTimelineEnv(this.dataSet)

      this.dataSet.forEach(d => {
        let date__ = this.getTimelineCDate(d[this.timelineKeyName])
        d.sDate = date__.sDate
        d.mDate = date__.mDate
        d.cDate = date__.eDate
        d.date = d3.timeParse('%Y-%m-%d')(d[this.timelineKeyName])
      })
      
      this.timeline.height = this.timeline.style.week.height
      if (this.timeline.style.month.display == 'Y') this.timeline.height += this.timeline.style.month.height
      if (this.timeline.style.year.display == 'Y') this.timeline.height += this.timeline.style.year.height

      
      // Get tickValues1 / tickValues2
      this.tickValues1 = this.dataSet.filter(d => typeof d.A_A_CUM != 'undefined').map(d => ({ date: d.mDate, remain: d.A_A_CUM }))
      this.tickValues2 = this.dataSet.filter(d => typeof d.A_O != 'undefined').map(d => ({ date: d.mDate, week: d.A_O }))

      // getMaxBound for remain / week
      this.dataMaxValue1 = this.getMaxBound(Math.max(...this.tickValues1.map(d => d.remain)))
      this.dataMaxValue2 = this.getMaxBound(Math.max(...this.tickValues2.map(d => d.week)))

      // Get Average
      this.tickValues2.forEach(d => { this.dataAvgValue += Number(d.week)})
      if (this.dataAvgValue == 0) this.dataAvgValue = 0; else this.dataAvgValue = this.dataAvgValue/8

      this.lastWeek = this.tickValues2[this.tickValues2.length-1].week
      this.lastValue = this.tickValues1[this.tickValues1.length-1].remain
      this.lastDate = this.tickValues1[this.tickValues1.length-1].date

      this.scaleY1 = d3
      .scaleLinear()
      .domain([this.dataMinValue1, this.dataMaxValue1])
      .range([this.axisYHeight, 0])
      
      this.scaleY2 = d3
      .scaleLinear()
      .domain([this.dataMinValue2, this.dataMaxValue2])
      .range([this.axisYHeight, 0])

      this.lineZero = d3.line()
      .x(d => this.timeline.scale(d.date))
      .y(this.axisYHeight)
      
      this.lineFunc = d3.line()
      .x(d => this.timeline.scale(d.date))
      .y(d => this.scaleY1(d.remain))


      // Table Properties
      this.table.width = this.timeline.width
      this.table.height = this.table.lineHeight * this.table.headers.length
      this.table.cellWidth = this.axisXWidth / this.timeline.week.length

      // Summary Data
      this.style.value = [this.Queries.SQL2[0].TOTAL, this.Queries.SQL2[0].ACTUAL, this.Queries.SQL2[0].REMAIN]
    },
  }
}