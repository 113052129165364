import * as d3 from 'd3'

export default {
  data: () => ({
    viewerWidth: 750,
    viewerHeight: 750,
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)

      return (
        Object.keys(this.Canvas).length > 0
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.viewerWidth = this.Canvas.CanvasWidth - 20
      this.viewerHeight = this.Canvas.CanvasHeight - 20

      this.svg
      .append('clipPath')
      .attr('id', 'mask_three_d_viewer')
      .attr('transform', 'translate(1, 1)')
      .append('rect')
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', this.viewerWidth - 2)
      .attr('height', this.viewerHeight - 2)
    },
  }
}