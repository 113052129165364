import * as d3 from 'd3'

export default {
  data: () => ({
    dataSet: [],
    innerSvg: null,
    keyPlanSvg: null,

    progSummary: [
      { TITLE: 'Cutting Commenced',   TYPE: 'Cutting',  FieldTotal: 'CUT_T',  TOTAL: 10, FieldAct: 'CUT_A',  ACTUAL: 0,},
      { TITLE: 'Assembly 100%',       TYPE: 'Assembly', FieldTotal: 'ASSY_T', TOTAL: 10, FieldAct: 'ASSY_A', ACTUAL: 0,},
      { TITLE: 'BLK Painting 100%',   TYPE: 'Painting', FieldTotal: 'PNT_T',  TOTAL: 10, FieldAct: 'PNT_A',  ACTUAL: 0,},
      { TITLE: 'Erection Completion', TYPE: 'Erection', FieldTotal: 'EREC_T', TOTAL: 10, FieldAct: 'EREC_A', ACTUAL: 0,},
    ],
  }),
  computed: {
    ready2draw() {

      // console.log('------------------------------')
      // console.log('this.DataItems.length', this.DataItems.length)
      // console.log('Object.keys(this.Canvas).length', Object.keys(this.Canvas).length)
      // console.log('this.Status.length', this.Status.length)
      // console.log('this.Border.length', this.Border.length)

      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0 &&
        this.Status.length > 0  &&
        this.Border.length > 0 
      )
    },
  },
  methods: {
    setDefaultValues(){
      this.dataSet = JSON.parse(JSON.stringify(this.DataItems))
      
      this.dataSet.forEach(data => {
        let bItem = this.Border.find(t_ => t_.type == data.TYPE)
        if(bItem) {
          data.shape = bItem.shape
          data.width = bItem.width
          data.height = bItem.height
          data.color = bItem.color
          data.stroke = bItem.stroke
          data.textSize = bItem.textSize
          data.padding = bItem.padding
        }
      })

      this.dataSet.forEach(data => {
        let bItem = this.Status.find(t_ => t_.type == data.STATUS)
        if(bItem) {
          data.bgColor = bItem.bgColor
          data.textColor = bItem.textColor
        }
      })



      this.progSummary.forEach((_,i) => {
        this.progSummary[i].TOTAL = this.Queries.SQL1[0][`${this.progSummary[i].FieldTotal}`]
        this.progSummary[i].ACTUAL = this.Queries.SQL1[0][`${this.progSummary[i].FieldAct}`]
      })
    },

    
    getMatrixProps: (selection, transform) => {
      let matrixEls = transform.substring(transform.indexOf('(') + 1, transform.indexOf(')')).split(' ')
      let elWidth = selection.node().getBoundingClientRect().width
      let elHeight = selection.node().getBoundingClientRect().height
      
      return {
        a: matrixEls[0],
        b: matrixEls[1],
        c: matrixEls[2],
        d: matrixEls[3],
        dx: matrixEls[4],
        dy: matrixEls[5],
        cx: Number(matrixEls[4]) + elWidth / 2,
        cy: Number(matrixEls[5]) - elHeight / 4,
      }
    }
    
  }
}