import Vue from 'vue'
import { PageComponentService } from "@/services"

const _pageComponentService = new PageComponentService()

export default {
  namespaced: true,
  state: {
    datagridItem: {},
    previewTitle: '',
    previewItems: [],
    summaryData: []
  },
  mutations: {
    setDatagridItem(state, item) {
      if(Object.keys(item).length > 0) {
        Object.keys(item).forEach(k => {
          if(Object.keys(state.datagridItem).includes(k)) state.datagridItem[k] = item[k]
          else Vue.set(state.datagridItem, k, item[k])
        })
      } else state.datagridItem = {}
    },
    setEmpty(state) { 
      state.datagridItem = {}
      state.previewItems = []
      state.summaryData = []
      state.previewTitle = ''
    },
    setPreviewTitle(state, title) { 
      state.previewTitle = title
    },
    setPreviewItems(state, items) { 
      state.previewItems = items
    },
    setPreviewSummaryData(state, sumData) { 
      state.summaryData = sumData
    },
  },
  getters: {
    general: (state) => ({
      name: state.datagridItem.name || '',
      phaseCode: state.datagridItem.phaseCode || '',
      groupCode: state.datagridItem.groupCode || '',
      itemCode: state.datagridItem.itemCode || '',
      subItemCode: state.datagridItem.subItemCode || '',
      desc: state.datagridItem.desc || '',
    }),
    database: (state) => ({
      dbName: state.datagridItem.dbName || '',
      schemaName: state.datagridItem.schemaName || '',
      tableName: state.datagridItem.tableName || '',
      directAccess: state.datagridItem.directAccess || '',
      version: state.datagridItem.version || '',
      jsonProps: state.datagridItem.jsonProps || '',
      qApplied: state.datagridItem.qApplied || '',
      qDataset: state.datagridItem.qDataset || '',
      qSp: state.datagridItem.qSp || '',
      qSummary: state.datagridItem.qSummary || '',
      qTotalRows: state.datagridItem.qTotalRows || '',
    }),
    dataSource: (state) => {
      return (
        state.datagridItem.dbName &&
        state.datagridItem.schemaName &&
        state.datagridItem.tableName ?
        `${state.datagridItem.dbName}.${state.datagridItem.schemaName}.${state.datagridItem.tableName}` :
        ''
      )
    },
    tableAttrs: (state) => ({
      pagination: (
        state.datagridItem.tableAttrs &&
        state.datagridItem.tableAttrs.pagination ? 
        state.datagridItem.tableAttrs.pagination : 
        {}
      ),
      table: state.datagridItem.tableAttrs || {},
      header: state.datagridItem.headerAttrs || {},
      body: state.datagridItem.bodyAttrs || {},
      group: state.datagridItem.groupAttrs || {},
      style: state.datagridItem.styleAttrs || {},
      svg: state.datagridItem.svgAttrs || {},
    }),
  },
  actions: {
    getDatagridItem({ commit }, idx) {
      return new Promise((resolve) => {
        _pageComponentService.getDatatables(
          null, null, null, null,
          idx, 
          (item) => {
            commit('setDatagridItem', item[0])
            resolve(true)
          }
        )
      })
    },
    getDatatablePreview({ commit }, idx) {
      let params = new URLSearchParams()
      params.append('idx', idx)
      params.append('catCode', 'Page')    // Page | Modal
      params.append('info', '')
      params.append('iFilter', '')
      params.append('fValues', '')
      params.append('cProps', '')         // Configuring properties for the Intended DataGrid
      params.append('pInfo', '')          // User updated pagenation info
      params.append('reqType', 'preview')

      return new Promise((resolve) => {
        _pageComponentService.getDatatablePreview(params, res => {
          commit('setPreviewItems', res.dataList)
          commit('setPreviewSummaryData', res.summaryData)
          resolve(res)
        })
      })
    },
  }
}