<template>
  <div class="j_page_svg_information__wrapper">
    <div class="control_bar">
      <div class="control__left">
        <v-select
          v-if="!showSelect"
          v-model="selectedSEICode"
          attach
          class="lnb_single_select"
          item-text="DESCRIPTION"
          item-value="SEICODE_L1"
          label="SEI Code L1"
          placeholder="SEI Code L1"
          :items="seiCodeL1"
          :menu-props="{ maxHeight: '190', closeOnContentClick: true, contentClass:'lnb_dropdown'}"
        ></v-select>
        <j-button v-if="!forPrint && editable" class="type01 sky" text @click="onEdit">Edit</j-button>
      </div>
      <div class="control__mid"></div>
      <div class="control__right"></div>
    </div>
    <div v-html="codeDetailInfo" class="information_detail">
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import __C from '@/primitives/_constant_'
import Comment from '@/mixins/comment.mixin'
import { SystemCodeService, CommunicationService } from '@/services'

export default {
  name: 'j-page-svg-information',
  mixins: [
    Comment
  ],
  props: {
    forPrint: { type: Boolean, default: false },
    chartData: { type: Array, default: () => ([]) },
    localFilters: { type: Object, default: () => ({}) },
    getSelectItems: null,
  },
  data: () => ({
    communicationService: null,
    codeDetailInfo: '',
    selectedItems: null,
    Toggle: { status: 'EN', },
    showSelect: false,
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    editable() {
      return this.account && this.account.permitLevel <= __C.ACCOUNT.PERMISSION_LEVEL.SVC_ADMIN
    },
    seiCodeL1() {
      //information guide v1
      return this.chartData && this.chartData[0].seiCodeL1.length > 0 ? this.chartData[0].seiCodeL1 : []
      //information guide v2
      //return this.chartData2? this.chartData2 : []
    },
    selectedSEICode: {
      get() { return this.localFilters && this.localFilters.SEICODE_L1 ? this.localFilters.SEICODE_L1 : '' },
      set(v) { this.$emit('filter-values', { SEICODE_L1: v }) },
    },
    currentInfo() {
      return this.seiCodeL1.find(item => item.SEICODE_L1 === this.selectedSEICode)
    },
    localfilterToggle() {
      //information guide v1
      if(this.localFilters && this.localFilters.TOGGLE){
        return this.Toggle.status = this.localFilters ? this.localFilters.TOGGLE : 'EN'
      //information guide v2
      } else if(this.localFilters && this.localFilters.lang) {
        return this.Toggle.status = this.localFilters.lang == 'on' ? 'EN' : 'CN'
      } else return {}
    },
  },
  watch: {
    selectedSEICode() {
      this.getCodeInfo()
    },
    localfilterToggle() {
      this.getCodeInfo()
    },
    getSelectItems() {
      this.selectedItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      //Toggle status for pdf export 
      if(this.selectedItems) {
        if(this.selectedItems.lang) {
          this.Toggle.status = this.selectedItems.lang == 'on' ? 'EN' : 'CN'
        }
      }
      this.getCodeInfo()
    }
  },
  created() {
    this.communicationService = new CommunicationService()
  },
  mounted() {
    this.showSelect = this.localFilters && this.localFilters.PLANT == 'MN' ? this.forPrint : true
    this.selectedItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
    //Toggle status for pdf export 
    if(this.selectedItems) {
      if(this.selectedItems.lang) {
        this.Toggle.status = this.selectedItems.lang == 'on' ? 'EN' : 'CN'
      }
    }
    this.getCodeInfo()
  },
  methods: {
    onEdit() {
      if(!this.selectedSEICode) {
        console.log('[#DEBUG: NOT DEFINED INIT CODE] Please select SEI Code L1 first.')
        return
      }

      let title = this.currentInfo ? this.currentInfo.DESCRIPTION : '' 
      
      this.setCommentTitle(title)
      this.setCommentProps({ refCode: `PageSvgInfo__SEICode_${this.selectedSEICode}` })
      this.onCommentOpen()

    },
    getCodeInfo() {
      if(!this.selectedSEICode) {
        console.log('[#DEBUG: NOT DEFINED INIT CODE] Please select SEI Code L1 first.')
        return
      }
      this.communicationService.getComments((`PageSvgInfo__SEICode_${this.selectedSEICode}`).toUpperCase()).then( res => {
        //console.log(`PageSvgInfo__SEICode_${this.selectedSEICode}`, res? 'true' : 'false', 'res:', res,'res.length: ',res.length)
          this.codeDetailInfo = res && res.length > 0 && this.Toggle.status == 'EN' ? res[0].comment :
          this.Toggle.status == 'CN' && res.length > 1 && res[1].parentId == res[0].idx ? res[1].comment :res[0].comment
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.j_page_svg_information__wrapper {
  padding: 3rem;

  width: 1600px;
  position: relative;
  top: -5px;
  background-color: #fff;

  
  .control_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .control__left {
      width: 50rem;
      display: flex;
      align-items: center;
    }
    .control__right {
      width: 50rem;
      display: flex;
      align-items: center;
    }
  }

  .lnb_single_select {
    padding-top: 4px;
  }
    

  .information_detail {
    padding: 2rem;
  }

}

</style>