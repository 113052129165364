<template>
  <div v-if="dataAvailable" :id="localId" class="j_mcr_comment_viewpoint">
    <div v-if="removable" class="direction_controller_wrapper">
      <div class="comment_number">
        {{ number+1 }}
      </div>
      <div class="spacer line"></div>
      <div class="controller_box">
        <div class="delete_button" @click="removeComment(number)">
          <i class="material-icons">
            close
          </i>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="field">
        <label class="label_mr_area">*Area No. (SEI Sub-Unit)</label>
        <kim-select
          v-model="area"
          placeholder="Select the Area No."
          itemText="DESCRIPTION"
          itemValue="CODE"
          :items="itemArea"
        ></kim-select>
      </div>
      <div class="spacer"></div>
      <div class="field">
        <label class="label_mr_pid">P&ID Reference</label>
        <kim-select
          v-model="pidref"
          placeholder="Select the P&ID"
          itemText="DESCRIPTION"
          itemValue="CODE"
          :items="itemPid"
        ></kim-select>
      </div>
    </div>
    <div class="row">
      <div class="field">
        <label>Location (Tag Number)</label>
        <kim-select
          clear-text
          placeholder="Input/Select the Location"
          itemText="DESCRIPTION"
          itemValue="TAG_NO"
          :items="itemLocation"
          @change="onLocationChange"
        ></kim-select>
        <button v-ripple class="btn_location_new" @click="newTag">
          <v-icon class="icon_location_new">mdi-plus</v-icon>
        </button>
        <div class="check_box" @click="applyLevels=!applyLevels">
          <input v-model="applyLevels" type="checkbox">
          <span>Apply selected levels (Package, Plant)</span>
        </div>
      </div>
      <div class="spacer"></div>
      <div class="field"></div>
    </div>
    <div class="row">
      <div class="field full_input">
        <label></label>
        <div class="location_wrapper">
          <div
            v-for="(tag, i) in locationTags" 
            class="locatoin_box"
            :key="i"
          >
            <span class="location" :title="tagDescription(tag)">{{ tag }}</span>
            <button v-ripple @click="deleteTag(i)">
              <v-icon class="icon_location_delete">mdi-close</v-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row_spacer"></div>
    <div class="row">
      <div class="field full_input align_top">
        <label class="label_mr_comment">*MR Comment</label>
        <textarea v-model="comment" class="kim_input" type="text" placeholder="Input the Review Comment"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="field">
        <label class="label_mr_category">*Category</label>
        <kim-select
          v-model="category"
          placeholder="Select Category"
          itemText="CODE"
          itemValue="CODE"
          :items="itemCategory"
        ></kim-select>
      </div>
      <div class="spacer"></div>
      <div class="field">
        <label class="label_mr_discipline">*Discipline</label>
        <kim-select
          v-model="discipline"
          placeholder="Select Discipline"
          itemText="CODE"
          itemValue="CODE"
          :items="itemDiscipline"
        ></kim-select>
      </div>
    </div>
    <div class="row">
      <div v-if="modeNew" class="field">
        <label class="label_mr_status">*Status</label>
        <kim-select
          v-model="status"
          placeholder="Input/Select the Status"
          itemText="CODE"
          itemValue="CODE"
          :items="itemStatus"
        ></kim-select>
      </div>
      <div v-else class="field">
        <label class="label_mr_status no_validate">Status</label>
        <label class="data">{{ status }}</label>
      </div>
      <div class="spacer"></div>
      <div class="field">
        <label class="label_mr_compdate">*Target Completion Date</label>
        <j-date-picker
          v-model="targetDate"
          no-default
          class="mrc_date_picker"
          :nudge-left="70"
        />
      </div>
    </div>
    <div class="row">
      <div v-if="modeNew" class="field">
        <label class="label_mr_createdby">*Originator</label>
        <input v-model="createdBy" class="kim_input" type="text" placeholder="Input the Originator">
      </div>
      <div v-else class="field">
        <label class="label_mr_createdby no_validate">Originator</label>
        <label class="data">{{ createdBy }}</label>
      </div>
      <div class="spacer"></div>
      <div v-if="modeNew" class="field">
        <label class="label_mr_createddate">*Date Created</label>
        <j-date-picker
          v-model="createdDate"
          no-default
          class="mrc_date_picker"
          :nudge-left="70"
        />
      </div>
      <div v-else  class="field">
        <label class="label_mr_createddate no_validate">Date Created</label>
        <label class="data">{{ createdDate }}</label>
      </div>
    </div>
    <div class="row_spacer"></div>
    <div class="row">
      <div class="field full_input align_top">
        <label>Related To</label>
        <textarea v-model="relatedTo" class="kim_input" placeholder="Input the Related To"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="field full_input align_top">
        <label>Comment (General)</label>
        <textarea v-model="generalComment" class="kim_input" type="text" placeholder="Input the General Comment"></textarea>
      </div>
    </div>
    <div class="row_spacer"></div>
    <div ref="vpfiles" class="vp_files_container">
      <div class="vpfile_controller_wrapper">
        <div class="vpfile_controller" @click="add">
          <i class="material-icons">
            add
          </i>
          <span>Add a Viewpoint</span>
        </div>
      </div>
    </div>
    <div class="form_tag">
      <div class="form_header">
        <div class="form_header_title">CREATE A NEW TAG</div>
      </div>
      <div class="form_body">
        <div class="row">
          <div class="field align_top">
            <label class="label_mr_tag_no">Tag No.</label>
            <div class="input_wrapper">
              <input v-model="mrTag.tagNo" class="kim_input" type="text" placeholder="Tag No.">
              <span class="input_tagno">Existed Tag No.</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="field align_top">
            <label class="label_mr_tag_desc">Description</label>
            <div class="input_wrapper">
              <textarea v-model="mrTag.description" class="kim_input" type="text" placeholder="Tag Description"></textarea>
              <span class="input_description">(500 characters)</span>
            </div>
          </div>
        </div>
      </div>
      <div class="form_footer">
        <div class="bottom_wrapper">
          <button type="button" class="j_button type01 sky" :class="{ disabled: !tagUpdatable }" :disabled="!tagUpdatable" @click="saveTag">Create</button>
          <button type="button" class="j_button type01 pink" @click="closeTag">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Moment from 'moment'

import '@/assets/stylus/ui/component/_mrc.styl'

import __C from '@/primitives/_constant_'
import Data from '@/primitives/_china1Default'

import { SafeIdMixin } from '@/mixins/safeid.mixin'
import JMcrCommentViewpointFile from '@/components/china1/JMcrCommentViewpointFile'

export default {
  name: 'j-mcr-comment-viewpoint',
  mixins: [
    SafeIdMixin
  ],
  props: {
    number: Number,
    removable: {
      type: Boolean,
      default: false
    },
    modeNew: {
      type: Boolean,
      default: true
    },
    package: String,
    plant: String,
    items: {
      type: Object,
      default: () => ({})
    },
    value: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    mrComment: {},
    ACCOUNT: null,
    dummytag: '',
    vpFileInstances: [],
    vpFiles: [],
    applyLevels: false,
    mrTag: {},
  }),
  computed: {
    __C_() { return __C },

    tagUpdatable() { return this.mrTag.tagNo || this.mrTag.description },
    dataAvailable() { return Object.keys(this.value).length > 0 },
    mrComment_() { 
      this.mrComment = JSON.parse(JSON.stringify(this.value))
      return this.mrComment
    },

    itemArea() { return this.items.area.filter(item => item.PLANT == this.plant) },
    itemPid() { return this.items.pids.filter(item => item.AREA == this.mrComment.area) },
    itemLocation() {
      let items_ = JSON.parse(JSON.stringify(this.items.tags))
      this.mrComment.newTags.forEach(t => {
        items_.push({
          TAG_NO      : t.tagNo,
          PACKAGE     : this.package,
          PLANT       : this.plant,
          DESCRIPTION : `${t.tagNo} | ${t.description}`,
          MR_NO       : '',
          MR_DESCR    : t.description
        })
      })

      if(!this.applyLevels) return items_

      if(this.package) items_ = items_.filter(item => item.PACKAGE == this.package)
      if(this.plant) items_ = items_.filter(item => item.PLANT == this.plant)
      return items_
    },
    itemStatus() { return this.items.mrcStatus },
    itemCategory() { return this.items.mrcCategory },
    itemDiscipline() { return this.items.discipline },

    area: {
      get() { return this.mrComment.area },
      set(val) { 
        this.mrComment.area = val
        this.send2parent()
      },
    },
    pidref: {
      get() { return this.mrComment.pidref },
      set(val) { 
        this.mrComment.pidref = val
        this.send2parent()
      },
    },
    locationTags() {
      if(!this.mrComment.location) return []
      return this.mrComment.location.split(',').filter(t => !!t)
    },
    category: {
      get() { return this.mrComment.category },
      set(val) { 
        this.mrComment.category = val
        this.send2parent()
      },
    },
    discipline: {
      get() { return this.mrComment.discipline },
      set(val) { 
        this.mrComment.discipline = val
        this.send2parent()
      },
    },
    comment: {
      get() { return this.mrComment.text },
      set(val) { 
        this.mrComment.text = val
        this.send2parent()
      },
    },
    status: {
      get() { return this.mrComment.status },
      set(val) { 
        this.mrComment.status = val
        this.send2parent()
      },
    },
    targetDate: {
      get() { return this.mrComment.targetDate },
      set(val) {
        if(val && this.createdDate && val < this.createdDate) this.mrComment.targetDate = ''
        else this.mrComment.targetDate = val

        this.send2parent()
      },
    },
    createdBy: {
      get() { return this.mrComment.createdBy },
      set(val) { 
        this.mrComment.createdBy = val
        this.send2parent()
      },
    },
    createdDate: {
      get() { return this.mrComment.createdDate },
      set(val) { 
        if(val && this.targetDate && val > this.targetDate) this.mrComment.createdDate = ''
        else this.mrComment.createdDate = val

        this.send2parent()
      },
    },
    relatedTo: {
      get() { return this.mrComment.relatedTo },
      set(val) { 
        this.mrComment.relatedTo = val
        this.send2parent()
      },
    },
    generalComment: {
      get() { return this.mrComment.generalComment },
      set(val) { 
        this.mrComment.generalComment = val
        this.send2parent()
      },
    },
  },
  watch: {
    package(val) {
      this.mrComment.packageNo = val
    },
    plant(val) {
      this.mrComment.plant = val
      this.mrComment.area = ''
      this.mrComment.pidref = ''
    }
  },
  created() {
    this.localId = this.id || 'j-mcr-comment-viewpoint__' + this.safeId('')
    this.mrComment = JSON.parse(JSON.stringify(this.value))
  },
  mounted() {
    this.ACCOUNT = JSON.parse(localStorage.getItem('ACCOUNT'))
    this.setDefault()
  },
  methods: {
    onFile(f, i) {
      this.vpFiles[i] = f
      this.$emit('file', this.vpFiles, this.number)
    },
    onLocationChange(v) {
      this.addTag(v)
    },
    tagDescription(tag) {
      let tagItem = this.itemLocation.find(t => t.TAG_NO == tag)
      if(tagItem) return tagItem.MR_DESCR

      tagItem = this.mrComment.newTags.find(t => t.tagNo == tag)
      return tagItem ? tagItem.description : ''
    },
    removeComment(i) {
      this.$emit('remove', i)
    },
    setDefault() {
      if(!this.mrComment.createdDate) this.mrComment.createdDate = Moment(new Date()).format('YYYY-MM-DD')
      if(!this.mrComment.createdBy) this.mrComment.createdBy = this.ACCOUNT.userName

      this.mrComment.viewpoints.forEach((vp, i) => {
        let Component = Vue.extend(JMcrCommentViewpointFile)
        let instance = new Component({
          propsData: { 
            number: i+1,
            value: vp
          },
          // on: {
          //   input: this.update,
          //   remove: this.remove,
          //   file: this.onFile,
          // }
        })
        instance.$on('input', this.update)
        instance.$on('remove', this.remove)
        instance.$on('file', this.onFile)
        instance.$mount()

        this.$refs.vpfiles.appendChild(instance.$el)
        this.vpFileInstances.push(instance)
        this.vpFiles.push(null)
      })
    },
    newTag() {
      this.mrTag = JSON.parse(JSON.stringify(Data.formTag))
      document.querySelector(`#${this.localId} .form_tag`).classList.add('show')
    },
    saveTag() {
      let validated = true
      
      if(!this.mrTag.tagNo) {
        document.querySelector(`#${this.localId} .label_mr_tag_no`).classList.add('required')
        validated = false
      } else {
        if(
          this.items.tags.find(t => t.TAG_NO == this.mrTag.tagNo) || 
          this.mrComment.newTags.find(t => t.tagNo == this.mrTag.tagNo)
        ) {
          document.querySelector(`#${this.localId} .label_mr_tag_no`).classList.add('required')
          document.querySelector(`#${this.localId} .input_tagno`).classList.add('show')
          validated = false

        } else {
          document.querySelector(`#${this.localId} .label_mr_tag_no`).classList.remove('required')
          document.querySelector(`#${this.localId} .input_tagno`).classList.remove('show')
        }
      }
      
      if(!this.mrTag.description) {
        document.querySelector(`#${this.localId} .label_mr_tag_desc`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_tag_desc`).classList.remove('required')

      if(!validated) return

      this.mrComment.newTags.push({ ...this.mrTag })
      this.addTag(this.mrTag.tagNo)
      this.closeTag()
    },
    closeTag() {
      document.querySelector(`#${this.localId} .form_tag`).classList.remove('show')
    },
    addTag(tag) {
      let tags = this.mrComment.location.split(',').filter(t => !!t)
      if(tags.find(t => t == tag)) return

      tags.push(tag)
      this.mrComment.location = tags.join(',')
      this.dummytag = ''
      this.send2parent()
    },
    deleteTag(i) {
      let tags = this.mrComment.location.split(',').filter(t => !!t)
      tags[i] = null
      tags = tags.filter(t => !!t)

      this.mrComment.location = tags.join(',')
      this.send2parent()
    },
    add() {
      this.mrComment.viewpoints.push({ ...Data.mrViewpoint })

      let length = this.mrComment.viewpoints.length
      let Component = Vue.extend(JMcrCommentViewpointFile)
      let instance = new Component({
          propsData: { 
            number: length,
            value: this.mrComment.viewpoints[length-1]
          }
      })
      instance.$on('input', this.update)
      instance.$on('remove', this.remove)
      instance.$on('file', this.onFile)
      instance.$mount()

      this.$refs.vpfiles.appendChild(instance.$el)
      this.vpFileInstances.push(instance)
      this.vpFiles.push(null)

      this.send2parent()
    },
    update(v, i) {
      this.mrComment.viewpoints[i] = v
      this.send2parent()
    },
    remove(i) {
      if(this.mrComment.viewpoints[i].id) this.$emit('viewpoint-removed', this.mrComment.viewpoints[i].id)
      
      let viewpoints_ = JSON.parse(JSON.stringify(this.mrComment.viewpoints))
      viewpoints_[i] = null

      this.vpFiles.splice(i, 1)
      this.mrComment.viewpoints = viewpoints_.filter(attr => !!attr)

      this.vpFileInstances[i].$el.parentNode.removeChild(this.vpFileInstances[i].$el) 
      this.vpFileInstances.splice(i, 1)
      this.vpFiles.splice(i, 1)

      this.vpFileInstances.forEach((ins, i) => { 
        ins.number = i+1 
        ins.value = this.mrComment.viewpoints[i]
      })

      // File is required in the JMcrCommentViewpointFile, 
      // that's why not to emit file removing event here.

      // When the file event fired from JMcrCommentViewpointFile, 
      // files will be updated at that time from the child to parent.

      this.send2parent()
    },
    validate() {
      let validated = true

      if(!this.mrComment.area) {
        document.querySelector(`#${this.localId} .label_mr_area`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_area`).classList.remove('required')

      // if(!this.mrComment.pidref) {
      //   document.querySelector(`#${this.localId} .label_mr_pid`).classList.add('required')
      //   validated = false
      // } else document.querySelector(`#${this.localId} .label_mr_pid`).classList.remove('required')

      if(!this.mrComment.text) {
        document.querySelector(`#${this.localId} .label_mr_comment`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_comment`).classList.remove('required')

      if(!this.mrComment.category) {
        document.querySelector(`#${this.localId} .label_mr_category`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_category`).classList.remove('required')

      if(!this.mrComment.discipline) {
        document.querySelector(`#${this.localId} .label_mr_discipline`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_discipline`).classList.remove('required')

      if(!this.mrComment.status) {
        document.querySelector(`#${this.localId} .label_mr_status`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_status`).classList.remove('required')

      if(!this.mrComment.targetDate) {
        document.querySelector(`#${this.localId} .label_mr_compdate`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_compdate`).classList.remove('required')

      if(!this.mrComment.createdBy) {
        document.querySelector(`#${this.localId} .label_mr_createdby`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_createdby`).classList.remove('required')

      if(!this.mrComment.createdDate) {
        document.querySelector(`#${this.localId} .label_mr_createddate`).classList.add('required')
        validated = false
      } else document.querySelector(`#${this.localId} .label_mr_createddate`).classList.remove('required')

      this.vpFileInstances.forEach(ins => { validated = !ins.validate() ? false : validated })

      return validated
    },
    send2parent() {
      this.$emit('input', JSON.parse(JSON.stringify(this.mrComment)), this.number)
    },
  }
}
</script>

<style lang="stylus" scoped>
.j_mcr_comment_viewpoint {
  position: relative;
  padding: 3rem;
  width: 100%;

  .mrc_date_picker {
    background-color: #fff;
  }

  .btn_location_new {
    width: 2rem;
    height: 3rem;
    margin-left: 1px;
    border: 1px solid #bbb;
    border-radius: 3px;
    background-color: #fff;

    .icon_location_new {
      font-size: 1.2rem;
    }
  }

  .location_wrapper {
    display: flex;
    flex-wrap: wrap;

    .locatoin_box {
      display: flex;
      margin-right: .5rem;
      margin-bottom: .1rem;

      span {
        display: block;
        padding: .3rem;
        border: 1px solid #bbb;
        border-radius: 3px;
        background-color: #fff;
        cursor: default;
      }

      .icon_location_delete {
        font-size: 1.2rem;
      }
    }
  }

  .vpfile_controller_wrapper {
    display: flex;
    justify-content: flex-end;

    .vpfile_controller {
      display: flex;

      width: 10rem;
      padding: .3rem;
      margin-right: -3rem;
      margin-bottom: 1px;
      background-color: #eee;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      .material-icons {
        font-size: 1.8rem !important;
      }
    }
    .vpfile_controller:hover {
      cursor: pointer;
    }
  }


  .direction_controller_wrapper {
    display: flex
    align-items: center;

    margin-top: -2rem;
    margin-bottom: 1rem;
    
    .comment_number {
      width: 5rem;
      padding: .5rem;
      color: #fff;
      font-weight: 900;
      text-align: center;

      background-color: #ccc;
      border-radius: 1rem;
    }

    .delete_button:hover {
      cursor: pointer;
    }
  }

  .spacer {
    &.line {
      height: 1px;
      background-color: #ccc;
      margin-left: 7rem;
      margin-right: 7rem;
    }
  }

  .kim_input {
    width: 20rem;
    height: 3rem;
    border: 1px solid #bbb;
    padding: .5rem;
    padding-right: 2.4rem;

    background-color: #fff;
    border-radius: 3px;

    &.readonly {
      background-color: #f4f5f5;
    }
  }
  .kim_input:hover {
    border-color: #777;
  }
  .kim_input::placeholder {
    color: #ccc;
    font-style: italic;
  }

  textarea {
    height: 12rem !important;
  }

  .row_spacer {
    margin: 2rem;
  }

  .row {
    display: flex;
    margin-bottom: 1px;

    .field {
      display: flex;
      align-items: center;

      &.full_input {
        width: 100%;

        input,
        textarea {
          width: 100%;
        }
      }

      &.align_top {
        align-items: flex-start;
        
        label {
          margin-top: .4rem;
        }
      }


      label {
        min-width: 12rem;

        &.required {
          color: #f54242;
          font-style: italic;
          font-weight: 500;
        }

        &.no_validate {
          color: #999;
        }

        &.data {
          display: flex;
          align-items: center;

          color: rgba(0,0,0,0.87);
          min-width: 20rem;
          height: 3rem;
          padding-left: .5rem;
          border-bottom: 1px solid #999;
        }
      }

      .check_box {
        display: flex;
        margin: 0 1rem;

        span {
          display: block;
          margin: 0 .5rem;
        }

        input {
          border: 1px solid #bbb !important;
        }
        input:hover {
          cursor: pointer;
          border-color: #777 !important;
        }
      }
      .check_box:hover {
        cursor: pointer;
      }
    } 
  }

  .form_tag {
    position: absolute;
    top: 9.2rem;
    left: 22rem;

    display: none;

    padding: 0 1rem;
    border: 1px solid #bbb;
    background-color: #fff;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);

    &.show {
      display: block;
    }

    .form_header {
      padding: 1rem;
      color: #999;
    }
    .form_body {
      padding: 1rem;

      .label_mr_tag_no,
      .label_mr_tag_desc {
        min-width: 9rem;
      }

      .input_wrapper {
        span {
          display: block;
          padding: .3rem;
          color: #999;
        }

        .input_tagno {
          display: none;

          &.show {
            display: block;
          }
        }
      }
    }

    .form_footer {
      padding: 1rem;

      .bottom_wrapper {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>
