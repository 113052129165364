// Essential Mixins
import { SafeIdMixin }          from './safeID'
import gmx_Controller           from './Controller' 

// Global Drawings
import draw_Canvas              from './draw_Canvas'
import draw_Text                from './draw_Text'
import draw_Buttons             from './draw_Buttons'
import draw_ToolTip             from './draw_ToolTip'
import draw_SearchTool          from './draw_SearchTool'
import draw_Movable             from './draw_Movable'

// Global Functions
import func_Global              from './func_Global'
import func_Miscellaneous       from './func_Miscellaneous'
import func_Element             from './func_Element'
import func_barCharts           from './func_barCharts'

// Global Functional Charts
import fCht_SumTables           from './fCht_SummaryTable'
import fCht_Radar               from './fCht_Radar'

// Global Setting Values
import init_Timeline_Week       from './init_Timeline_Week.js'
import init_Timeline_Month      from './init_Timeline_Month.js'

import init_TimelineValue       from './init_TimelineValue.js'

import init_Skyline_Week        from './init_Skyline_Week.js'
import init_Skyline_Month       from './init_Skyline_Month.js'



export default {
  
  mixins: [
    SafeIdMixin, 
    gmx_Controller,

    // Global Drawings
    draw_Canvas,
    draw_Text, 
    draw_Buttons,
    draw_ToolTip,
    draw_SearchTool,
    draw_Movable,

    // Global Functions
    func_Global,
    func_Miscellaneous,
    func_Element,
    func_barCharts,

    // Global Functional Charts
    fCht_SumTables,
    fCht_Radar,
    
    // set_InitValues
    init_Timeline_Week,
    init_Timeline_Month,
    
    init_TimelineValue,

    init_Skyline_Week,
    init_Skyline_Month,

  ],

}
