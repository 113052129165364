import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/Color_Defs'

export default {
  computed: {
    target() { return this.syncparent.target || {} }
  },
  methods: {
    setDefaultValues() {
      if(this.svg) this.svg.remove()

      this.svg = d3.select(`#${this.localId}`)
      .append('svg')
      .attr('xmlns', 'http://www.w3.org/2000/svg')
      .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      .attr('version', '1.2')
      .attr('id', `${this.localId}_svg`)
      .attr('class', 'canvas')

      // Drow-Shadows ---------------------------
      this.svg
      .append('defs')
      .append('filter')
      .attr('id', `${this.localId}__drop_shadow_el`)
      .append('feDropShadow')
      .attr('dx', 1)
      .attr('dy', 1)
      .attr('stdDeviation', 2)
      .attr('flood-opacity', .1)
      // ----------------------------------------

      let lightBlue = this.svg
      .append('defs')
      .append('linearGradient').attr('id', `shadowTimeline`).attr('x1', 0.5).attr('x2', 0.5).attr('y2', 1)

      lightBlue.append('stop').attr('stop-color', '#3d3d3d').attr('offset', '0').attr('stop-opacity', 0.5)
      lightBlue.append('stop').attr('stop-color', '#fff').attr('offset', '1').attr('stop-opacity', 0.3)
      
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightGray'], [`LightGray`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['YellowGreen'], [`YellowGreen`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['LightBlue'], [`LightBlue`, 0.5, 1.0, 0.5, -0.2], this.localId)
      Defs.setDefs_Gradients(this.svg, 1, 'LinearA4', ['Pink'], [`Pink`, 0.5, 1.0, 0.5, -0.2], this.localId)
    },
    run() {
      if(this.syncparent && this.target && this.target.plan) this.predraw()
      else setTimeout(() => { this.run() }, 50)
    }
  }
}