import * as d3 from 'd3'
import AxisYDeclares from './AxisY_Declares.mixin'

export default {
  mixins: [AxisYDeclares],
  methods: {
    // 'aValues' structure -----------------------------------------
    // {
    //   s: selection,      // D3 Selection where the AxisY-Wrapper(SVG Group) to be placed.
    //   x: Number,         // X coordinate of the Axis wrapper(SVG Group)
    //   y: Number,         // Y coordinate of the Axis wrapper(SVG Group)
    //   w: Number,         // Width of the grid-line
    //   h: Number,         // Height of the Axis Y
    //   m: Number,         // Max-Value for the Axis-Bound
    // }
    drawAxisY(aValues) {
      this.setAxisYValues(aValues)
      this.setScaleAxisYInfo(aValues.h, aValues.m)

      this.drawAxisYArea(aValues.s)
      this.drawAxisYGridLines()
      this.putAxisYLevelNUnit()
    },
    drawAxisYArea(selection) {
      this.axisyArea = selection
        .append('g')
        .attr('class', '_axisy_area__')
        .attr('transform', `translate(${this.axisyAreaX},${this.axisyAreaY})`)
    },
    drawAxisYGridLines() {
      if (this.AxisY.AxisYGridDisplay != 'Y') return

      // If some bunch of the lines is drawn in the SVG scope directly,
      // the line's weight couldn't be drawn as we expected. It could be
      // thickened. Draw the lines in a specified group instead.
      this.axisyArea
        .append('g')
        .attr('class', '_axisy_grid_line__')
        .selectAll('path')
        .data(this.scaleAxisYInfo.tickValues)
        .enter()
        .append('path')
        // Transform a data value to Y coordinate.
        .attr(
          'd',
          (tickValue_, i) => `
        M ${this.AxisY.AxisYLevelPosition == 'left' ? 0 : -this.axisyWidth},
          ${this.scaleAxisYInfo.scale(tickValue_)} 
        H ${i === 0 ? (this.AxisY.AxisYLevelPosition == 'left' ? 0 : -this.axisyWidth) : this.AxisY.AxisYLevelPosition == 'left' ? this.axisyWidth : 0}
      `
        )
        .style('stroke', this.AxisY.AxisYGridColor)
        .style('stroke-width', this.AxisY.AxisYGridWeight)
    },
    putAxisYLevelNUnit() {
      if (this.AxisY.AxisYLevelDisplay != 'Y') return

      if (this.AxisY.AxisYLineDisplay == 'Y') {
        this.axisyArea
          .append('path')
          .attr('class', '_axisy_line__')
          .attr('d', `M 0,0 V ${this.axisyHeight}`)
          .style('stroke', this.AxisY.AxisYLineColor)
          .style('stroke-width', this.AxisY.AxisYLineWeight)
      }

      let levelBox = this.axisyArea
        .append('g')
        .attr('class', '_axisy_level_unit__')
        .selectAll('g')
        .data(this.scaleAxisYInfo.tickValues)
        .enter()
        // Append every single box for a line value & unit. -------------------
        // Inited X, Y coordinate of the group is (0, 0), and its width
        // is 0 too. So, at the current time of that the '-this.AxisY.AxisYLevelSpace'
        // is applied to the X, X coordinate is '-this.AxisY.AxisYLevelSpace'.
        // And then the width will be extended toward left by the child
        // element's attribute ('text-anchor', 'end'). It doesn't need to
        // recalculate the X coordinate of the every single boxes.
        .append('g')
        .attr(
          'transform',
          tickValue_ => `translate(
        ${this.AxisY.AxisYLevelSpace * (this.AxisY.AxisYLevelPosition == 'left' ? -1 : 1)},
        ${this.scaleAxisYInfo.scale(tickValue_)}
      )`
        )

      // Append Unit
      levelBox
        .append('text')
        .attr('id', (_, i) => `axisy_level_unit_${i}___${this.localId}`)
        .attr('class', this.AxisY.AxisYLevelStyle)
        .style('font-size', this.AxisY.AxisYLevelUnitSize)
        .style('font-family', this.AxisY.AxisYLevelFont)
        .style('font-weight', this.AxisY.AxisYLevelWeight)
        .attr('fill', this.AxisY.AxisYLevelColor)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', this.AxisY.AxisYLevelPosition == 'left' ? 'end' : 'start')
        .text(this.AxisY.AxisYLevelUnitFormat)

      // Append Unit
      levelBox
        .append('text')
        .attr('id', (_, i) => `axisy_level_text_${i}___${this.localId}`)
        .attr('class', this.AxisY.AxisYLevelStyle)
        .attr('x', (_, i) => (this.AxisY.AxisYLevelPosition == 'left' ? -Math.round(this.getNodeElValue(`#axisy_level_unit_${i}___${this.localId}`, 'width') * 1.1) : 0))
        .style('font-size', this.AxisY.AxisYLevelSize)
        .style('font-family', this.AxisY.AxisYLevelFont)
        .style('font-weight', this.AxisY.AxisYLevelWeight)
        .attr('fill', this.AxisY.AxisYLevelColor)
        .attr('alignment-baseline', 'middle')
        .attr('text-anchor', this.AxisY.AxisYLevelPosition == 'left' ? 'end' : 'start')
        .text(tickValue_ => Math.round(tickValue_))

      if (this.AxisY.AxisYLevelPosition == 'right') {
        this.scaleAxisYInfo.tickValues.forEach((_, i) => {
          this.svg.select(`#axisy_level_unit_${i}___${this.localId}`).attr('transform', `translate(${this.getNodeElValue(`#axisy_level_text_${i}___${this.localId}`, 'width') * 1.1}, 0)`)
        })
      }
    }
  }
}
