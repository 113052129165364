// Styles
import '../../../../src/assets/stylus/ui/component/_jOverlay.styl'

/* @vue/component */
export default {
  name: 'j-overlay',
  props: {
    absolute: Boolean,
    contentRight: Boolean,
    color: {
      type: String,
      default: '#212121',
    },
    dark: {
      type: Boolean,
      default: true,
    },
    opacity: {
      type: [Number, String],
      default: 0.32,
    },
    value: {
      default: false,
    },
    zIndex: {
      type: [Number, String],
      default: 5,
    },
  },

  computed: {
    __scrim() {
      const data = {
        class: {
          'j-overlay__scrim': true,
        },
        style: {
          'background-color': this.color,
          'opacity': this.computedOpacity,
        }
      }
      return this.$createElement('div', data)
    },
    classes() {
      return {
        'j-overlay': true,
        'j-overlay--absolute': this.absolute,
        'j-overlay--active': this.isActive,
        'j-overlay--content-right': this.contentRight,
      }
    },
    computedOpacity() {
      return Number(this.isActive ? this.opacity : 0)
    },
    isActive() { 
      return this.value 
    },
    styles() {
      return {
        zIndex: this.zIndex,
      }
    },
  },

  methods: {
    genContent() {
      return this.$createElement('div', {
        staticClass: 'j-overlay__content',
      }, this.$slots.default)
    },
  },

  render(h) {
    const children = [this.__scrim]

    if(this.isActive) children.push(this.genContent())

    return h('div', {
      class: this.classes,
      style: this.styles,
      on: {
        click: (e) => { this.$emit('click', e) }
      }
    }, children)
  },
}