export default {
  data: () => ({

    DataTable: null, 
    Header: {},
    Data: {},
    Columns: [], 

    TableWidth: 0,

    ColLink:[],
    filterCondition:'',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      let d_ = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      this.Header =
      {
        x: d_.X,
        y: d_.Y,
        background: d_.HEADER_BACKGROUND,
        opacity:    d_.HEADER_OPACITY,
        height:     d_.HEADER_HEIGHT,

        line: {
          color:    d_.HEADER_LINE_COLOR,
          size:     d_.HEADER_LINE_SIZE,
        },

        font: {
          color:    d_.HEADER_FONT_COLOR,
          weight:   d_.HEADER_FONT_WEIGHT,
          size:     d_.HEADER_FONT_SIZE,
        }
      }

      this.Data =
      {
        height:     d_.DATA_HEIGHT,
        hovor:      d_.DATA_HOVOR,
        line: {
          color:    d_.DATA_LINE_COLOR,
          size:     d_.DATA_LINE_SIZE,
        },

        font: {
          weight:   d_.DATA_FONT_WEIGHT,
          size:     d_.DATA_FONT_SIZE,
        }
      }

      this.Columns = JSON.parse(JSON.stringify(this.Queries.SQL2))

      this.TableWidth = 0
      this.Columns.forEach(d => {

        if (d.ALIGN == 'start' && d.TYPE !== 'PROG') d.x = this.TableWidth + d.PADDING
        if (d.ALIGN == 'middle' && d.TYPE !== 'PROG') d.x = this.TableWidth + (d.WIDTH/2)
        if (d.ALIGN == 'end' && d.TYPE !== 'PROG') d.x = this.TableWidth + d.WIDTH - d.PADDING

        if (d.TYPE == 'PROG') d.x = this.TableWidth 

        this.TableWidth += d.WIDTH
        d.col = this.TableWidth

        if (d.TYPE.substr(d.TYPE.length-1, 1) == '$') {
          d.FORMAT = '$'
          d.TYPE = d.TYPE.slice(0, -1)
        }
        // link columns setup
        if (d.COL_LINK !== '') this.ColLink.push(d.CODE)
        
      })
      
      // Resize Canvas
      this.Canvas.CanvasWidth = this.TableWidth+ this.Header.x + 1
      this.Canvas.CanvasHeight = (this.rawData.length * this.Data.height) + this.Header.height + this.Header.y + 1

      this.Canvas.CanvasChartWidth = this.TableWidth+ this.Header.x + 1
      this.Canvas.CanvasChartHeight = (this.rawData.length * this.Data.height) + this.Header.height + this.Header.y + 1

      // filter & link columns
      this.filterCondition = JSON.parse(JSON.stringify(this.Queries.SQL3[0])) 
    },
  }
}