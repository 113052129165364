import Data from './Color_Data'

// Set Color Reflection Effect
const setDefs_Reflections = (selection, id) => {
  let Color_Reflection = selection
    .append('defs')
    .append('linearGradient')
    .attr('id', `${id ? id + '__' : ''}Color_Reflection`)
    .attr('x1', '0')
    .attr('y1', '0')
    .attr('x2', '0')
    .attr('y2', '100%')

  Data.setReflection[1].values.forEach((item, i) => {
    Color_Reflection.append('stop')
      .attr('stop-color', Data.setReflection[2].values[i])
      .attr('stop-opacity', Data.setReflection[1].values[i])
      .attr('offset', Data.setReflection[0].values[i])
  })
}

const setDefs_Shadows = (selection, id) => {
  // Set Color Shadow Effect
  let Color_Shadow = selection
    .append('defs')
    .append('radialGradient')
    .attr('id', `${id ? id + '__' : ''}Color_Shadow`)

  Color_Shadow.append('stop')
    .attr('stop-color', '#414141')
    .attr('offset', '0')

  Color_Shadow.append('stop')
    .attr('stop-color', '#fff')
    .attr('stop-opacity', '0.3')
    .attr('offset', '1')
}

/* 
  Gradient Color Setting - updated: 2019-08-06 
    This function is provided 3 type of gradient(16 colors().
      1. LanearA2 
      2. LanearA4
      3. LanearA9
*/

const setDefs_Gradients = (selection, nums, Get_cType, ColorSet, direct = [], id) => {
  Array.from({ length: nums }, (_, index_) => index_).forEach(sn => {
    let Gradients = selection
      .append('defs')
      .append('linearGradient')
      .attr('id', `${id ? id + '__' : ''}${Get_cType}${direct[0]}${sn}`)
      .attr('x1', `${direct[1]}`)
      .attr('y1', `${direct[2]}`)
      .attr('x2', `${direct[3]}`)
      .attr('y2', `${direct[4]}`)

    let arrayValue = Array.from({ length: Data.setGradients.Length[Get_cType] }, (_, i) => i)
    arrayValue.forEach(i => {
      Gradients.append('stop')
        .attr('stop-color', Data.setGradients[Get_cType](ColorSet[sn], i))
        .attr('offset', Data.setGradients[Get_cType]('Offset', i))
    })
  })
}

export default {
  setDefs_Reflections,
  setDefs_Shadows,
  setDefs_Gradients
}
