import Api from './api'
import Url from './request.url'

export default class FilebrowserApi extends Api {

  constructor() {
    super()
  }

  getFileResources(path, name, sort) {
    return new Promise(resolve => {
      let params = { path, name, sort }
      this.axios().get(Url.system.filebrowser.resources, { params }).then(res => { resolve(res.data) })
    })
  }
  getFileRaw(path, name) {
    return new Promise(resolve => {
      let params = { path: path, name: name, inline: true }
      this.axios().get(Url.system.filebrowser.raw, { params }).then(res => { resolve(res.data) })
    })
  }
}