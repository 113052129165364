import * as d3 from 'd3'

export default class AxisY {
    
  // @param {o} object, Axis configuration values
  // {
  //   i: Safe ID.
  //   s: Selection where the AxisY-Wrapper(SVG Group) to be placed.
  //   x: X coordinate of the Axis wrapper(SVG Group)
  //   y: Y coordinate of the Axis wrapper(SVG Group)
  //   w: Width of the Grid Line
  //   h: Height of the Axis Y
  //   b: Bound {
  //     min: Number,
  //     max: Number
  //   },
  //   c: Mode Comparison (true | false)
  //   o: Object Values. If not assigned, default object values will 
  //      be applied.
  //      {
  //        levelDisplay: String (Y | N),
  //        levelSpace: Number,
  //        levelFont: String,
  //        levelStyle: String,
  //        levelSize: Number,
  //        levelWeight: Number,
  //        levelColor: String,
  //        levelUnitSize: Number,
  //        levelUnitFormat: String,
  //        lineWeight: Number,
  //        lineColor: String,
  //        gridNum: Number,
  //        gridWeight: Number,
  //        gridColor: String,
  //        gridOpacity: Number,
  //        zeroLineWeight: Number,
  //        zeroLineColor: String,
  //        zeroLineOpacity: Number,
  //      }
  // }
  constructor(objectValues, scaleInfo) {
    this.axisy = objectValues
    this.scaleYInfo = scaleInfo
    this.axisyArea = null

    this.drawAxisYArea()
  }
  drawAxisYArea() {
    this.axisyArea = this.axisy.s
    .append('g')
    .attr('class', `_axisy_area__${this.axisy.o.levelPosition}___${this.axisy.i}`)
    .attr('transform', `translate(${this.axisy.x},${this.axisy.y})`)
  }
  draw() {
    this.axisyArea
    .append('path')
    .attr('d', `M 0,0 V ${this.axisy.h}`)
    .style('stroke', this.axisy.o.lineColor)
    .style('stroke-width', this.axisy.o.lineWeight)

    let levelBox = this.axisyArea
    .append('g')
    .attr('class', `_axisy_level_unit__${this.axisy.o.levelPosition}___${this.axisy.i}`)
    .selectAll('g')
    .data(this.scaleYInfo.tickValues)
    .enter()
    // Append every single box for a line value & unit. -------------------
    // Inited X, Y coordinate of the group is (0, 0), and its width
    // is 0 too. So, at the current time of that the '-this.axisy.AxisYLevelSpace'
    // is applied to the X, X coordinate is '-this.axisy.AxisYLevelSpace'.
    // And then the width will be extended toward left by the child
    // element's attribute ('text-anchor', 'end'). It doesn't need to
    // recalculate the X coordinate of the every single boxes.
    .append('g')
    .attr('transform', tickValue_ => `translate(
        ${this.axisy.o.levelSpace * (this.axisy.o.levelPosition == 'left' ? -1 : 1)},
        ${this.scaleYInfo.scale(tickValue_)}
      )`
    )

    // Append Unit
    levelBox
    .append('text')
    .attr('id', (_, i) => `axisy_level_unit_${this.axisy.o.levelPosition}_${i}___${this.axisy.i}`)
    .attr('class', this.axisy.o.levelStyle)
    .style('font-size', this.axisy.o.levelUnitSize)
    .style('font-family', this.axisy.o.levelFont)
    // .style('font-weight', this.axisy.o.levelWeight)
    .attr('fill', this.axisy.o.levelColor)
    .attr('alignment-baseline', 'middle')
    .attr('text-anchor', this.axisy.o.levelPosition == 'left' ? 'end' : 'start')
    .text(this.axisy.o.levelUnitFormat)

    // Append Level
    levelBox
    .append('text')
    .attr('id', (_, i) => `axisy_level_text_${this.axisy.o.levelPosition}_${i}___${this.axisy.i}`)
    .attr('class', this.axisy.o.levelStyle)
    .attr('x', (_, i) => (
      this.axisy.o.levelPosition == 'left' ? 
      -Math.round(this.axisyArea.select(`#axisy_level_unit_${this.axisy.o.levelPosition}_${i}___${this.axisy.i}`).node().getBoundingClientRect().width * 1.1) : 
      0
    ))
    .style('font-size', this.axisy.o.levelSize)
    .style('font-family', this.axisy.o.levelFont)
    // .style('font-weight', this.axisy.o.levelWeight)
    .attr('fill', this.axisy.o.levelColor)
    .attr('alignment-baseline', 'middle')
    .attr('text-anchor', this.axisy.o.levelPosition == 'left' ? 'end' : 'start')
    .text(tickValue_ => d3.format(',')(Math.round(this.axisy.c ? Math.abs(tickValue_) : tickValue_)))

    if (this.axisy.o.levelPosition == 'right') {
      this.scaleYInfo.tickValues.forEach((_, i) => {
        let etxtWidth = this.axisyArea.select(`#axisy_level_text_${this.axisy.o.levelPosition}_${i}___${this.axisy.i}`).node().getBoundingClientRect().width
        this.axisyArea.select(`#axisy_level_unit_${this.axisy.o.levelPosition}_${i}___${this.axisy.i}`).attr('transform', `translate(${etxtWidth * 1.1}, 0)`)
      })
    }
  }

  drawGrid() {
    let lineGroup_ = this.axisyArea
    .append('g')

    lineGroup_
    .selectAll('path')
    .data(this.scaleYInfo.tickValues)
    .enter()
    .append('path')
    .attr('d', d => `M 0,${this.scaleYInfo.scale(d)} H ${this.axisy.w}`)
    .attr('opacity', typeof this.axisy.o.gridOpacity == 'undefined' ? 1 : this.axisy.o.gridOpacity)
    .style('stroke', this.axisy.o.gridColor)
    .style('stroke-width', this.axisy.o.gridWeight)

    if(this.axisy.o.zeroLineWeight) {
      lineGroup_
      .append('path')
      .attr('d', `M 0,${Math.round(this.axisy.h/2)} H ${this.axisy.w}`)
      .attr('opacity', typeof this.axisy.o.zeroLineOpacity == 'undefined' ? 1 : this.axisy.o.zeroLineOpacity)
      .style('stroke', this.axisy.o.zeroLineColor)
      .style('stroke-width', this.axisy.o.zeroLineWeight)
    }
  }
}