import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Chart_Controller(redraw) {

      this.Chart_Area = this.svg
      .append('g')
      .attr('id', `Area_Chart`)
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto')
 
      let fData = null, tData = null

      fData = this.rawData.filter(f=> 
        f.LEV == this.current.phase.name && 
        f.AREA == this.current.area.name && 
        f.DISC == this.current.disc.name
      ) 

      this.lineChart.forEach((d,i)=> {
        if(d.type == 'ACT') {
          tData = fData.filter(f=> f.CDATE <= this.timeline.cutoff) 
        } else if(d.type == 'DASH') {
          tData = fData.filter(f=> f.CDATE >= this.timeline.cutoff) 
        } else {
          tData = fData
        }
        this.draw_LineChart(tData, d, redraw) // -----> below
      })

    },


    draw_LineChart(tData, cht, redraw) {
      
      let lineFunc = d3.line()
      .x(d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate()))
      .y(d => this.scale.Primary(d[`${cht.code}`]))

      if (redraw) {
        d3.select(`#LINE_${cht.code}`).transition().duration(500).attr('d', lineFunc(tData))
      }
      else {
        let lineChart = this.Chart_Area
        lineChart
        .append('path')
        .attr('id', `LINE_${cht.code}`)
        .attr('d', lineFunc(tData))
        .style('stroke', cht.sColor)
        .style('stroke-width', cht.sWidth)
        .style('fill', 'none')
        .style("stroke-dasharray", (cht.type == 'DASH') ? (3,3) : (0,0))
      } 
    },

  }
}