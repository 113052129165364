import __C from '../../includes/primitives/_constant_'   // Global Defs
import * as d3 from 'd3'

export default {

  methods: {
    /*
      getRequestColumnProps(colName='', dataSource={}, colProps=[]) {
      drawTexts()  
      drawTextText(svg)
      drawFilterText(svg)
      drawSqlText(svg)
      drawNotes(svg=null)
    */
    // ### Global Common Function - will be moved to data-function-mixin.
    getRequestColumnProps(colName='', dataSource={}, colProps=[]) {
      //find < "value": "request" >  in JSON Properties ( Page Component )
      let colProps_ = colProps.find(props_ => props_.value == colName)
 
      if(!colProps_ || !colProps_.link) return null
      
      // columnParts function definition
      let columnParts = (colString) => {
        let colParts_ = colString.replace(/\s/g, '').split('=')
        if(colParts_.length === 0) return { source: '', target: '' }
        if(colParts_.length === 1) return { source: colParts_[0], target: colParts_[0] }
        return { source: colParts_[1], target: colParts_[0] }
      }

      let values_ = colProps_.link.values
      let valueObject_ = {}
      if(values_ && values_.length > 0) {
        values_.forEach(col_ => {
          let colParts__ = columnParts(col_)

          if(!colParts__.source) console.log(`[USER: undefined] Undefined column-name for the action-value.`)
          else if(!dataSource[colParts__.source]) console.log(`[USER: not found '${colParts__.source}'] Not found matched value-column for the filter-value, but it could be found from the 'FilterValues' instead.`)
          else valueObject_[colParts__.target] = dataSource[colParts__.source]
        })
      }

      return {
        dataType: 'row',
        action: {
          type: 'direct',
          ...colProps_.link.action
        },
        filters: valueObject_,
        iFilters: { 
          filterString: colProps_.link.filterString || '',
          inputFilter: ''
        }
      }
    },

    // ### Global Common Function - draw Texts from A/G
    drawTexts() {
      if(Object.keys(this.Text).length === 0) return

      let gText = this.svg
      .append('g')
      .attr('class', '_global_elements__text__')
      .attr('transform', `translate(0,0)`)

      this.drawTextText(gText)
      this.drawFilterText(gText)
      this.drawSqlText(gText)
    },

    // ### Global Common Function - draw Texts from A/G
    drawTextText(svg) {
      if(this.Text.Text.length === 0) return

      svg
      .append('g')
      .attr('class', '_global_elements__text__text_')
      .attr('transform', `translate(0,0)`)
      .selectAll('text')
      .data(this.Text.Text)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${d.x},${d.y})${d.direction == 'Vertical' ? ' rotate(-90) ' : ''}`)
      .attr(__C.CHART.MOVED_EL_TYPE_TEXT, (d, i) => `Text$Text$${i}$x$y`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)
      .style('font-family', d => d.font)
      .attr('class', d => d.class)
      .attr('font-size', d => d.size)
      .attr('text-anchor', d => d.align)
      .attr('alignment-baseline', 'hanging')
      .attr('fill', d => d.color)
      // ### For displaying the multi-line text ### ---
      .selectAll('tspan')
      .data(d => (d.text || '').split('\n').map(t_ => ({ text: t_, size: d.size })))
      .enter()
      .append("tspan")
      .attr("x", 0)
      .attr("dy", d => Math.round(d.size * 1.2))
      .style('font-family', (d, i, j) => j[0].parentElement.style.fontFamily)
      .text(d => d.text)
      // ### ---------------------------------- ### ---
    },

    // ### Global Common Function - draw Texts from A/G
    drawFilterText(svg) {
      if(!this.FilterValues || this.Text.Filter.length === 0) return

      svg
      .append('g')
      .attr('class', '_global_elements__text__filter_')
      .attr('transform', `translate(0,0)`)
      .selectAll('text')
      .data(this.Text.Filter)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${d.x},${d.y})${d.direction == 'Vertical' ? ' rotate(-90) ' : ''}`)
      .attr(__C.CHART.MOVED_EL_TYPE_TEXT, (d, i) => `Text$Filter$${i}$x$y`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)
      .style('font-family', d => d.font)
      .attr('class', d => d.class)
      .attr('font-size', d => d.size)
      .attr('text-anchor', d => d.align)
      .attr('alignment-baseline', 'hanging')
      .attr('fill', d => d.color)
      // ### For displaying the multi-line text ### ---
      .selectAll('tspan')
      .data(d => (this.FilterValues[d.name] ? this.FilterValues[d.name] : `[${d.name}]` || '').split('\n').map(t_ => ({ text: t_, size: d.size })))
      .enter()
      .append("tspan")
      .attr("x", 0)
      .attr("dy", d => Math.round(d.size * 1.2))
      .style('font-family', (d, i, j) => j[0].parentElement.style.fontFamily)
      .text(d => d.text == '[FILTER]' ? 'All Area' : d.text)
      // ### ---------------------------------- ### ---
    },
    
    // ### Global Common Function - draw Texts from A/G
    drawSqlText(svg) {
      if(this.Text.Sql.length === 0) return

      svg
      .append('g')
      .attr('class', '_global_elements__text__sql_')
      .attr('transform', `translate(0,0)`)
      .selectAll('text')
      .data(this.Text.Sql)
      .enter()
      .append('text')
      .attr('transform', d => `translate(${d.x},${d.y})${d.direction == 'Vertical' ? ' rotate(-90) ' : ''}`)
      .attr(__C.CHART.MOVED_EL_TYPE_TEXT, (d, i) => `Text$Sql$${i}$x$y`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)
      .style('font-family', d => d.font)
      .attr('class', d => d.class)
      .attr('font-size', d => d.size)
      .attr('text-anchor', d => d.align)
      .attr('alignment-baseline', 'hanging')
      .attr('fill', d => d.color)
      // ### For displaying the multi-line text ### ---
      .selectAll('tspan')
      .data(d => (d.text || '').split('\n').map(t_ => ({ text: t_, size: d.size })))
      .enter()
      .append("tspan")
      .attr("x", 0)
      .attr("dy", d => Math.round(d.size * 1.2))
      .style('font-family', (d, i, j) => j[0].parentElement.style.fontFamily)
      .text(d => d.text)
      // ### ---------------------------------- ### ---
    },
    
    // ### Global Common Function - draw Notes from A/G
    drawNotes(svg=null) {
      if(this.Note.NoteDisplay != 'Y') return
      
      let noteGroup = (svg ? svg : this.svg)    // Title
      .append('g')
      .attr('id', 'NoteX$NoteY')
      .attr('transform', `translate(${this.Note.NoteX},${this.Note.NoteY})`)
      .call(this.el2Drag)
      .call(this.setCursorMovable)

      noteGroup
      .append('text')
      .attr('class', this.Note.NoteTitleStyle)
      .style('font-size', this.Note.NoteTitleSize)
      .style('font-family', this.Note.NoteTitleFont)
      // .style('font-weight', 500)
      .attr('fill', this.Note.NoteTitleColor)
      .attr('text-anchor', this.Note.NoteAlign)
      .text(this.Note.NoteTitleText)

      noteGroup // Note Contents
      .append('text')
      .attr('class', this.Note.NoteContentsStyle)
      .attr('x', 0)
      .attr('y', Math.round(this.Note.NoteTitleSize * 0.2))
      .style('font-family', this.Note.NoteContentsFont)
      // .style('font-weight', 300)
      .style('font-size', this.Note.NoteContentsSize)
      .attr('fill', this.Note.NoteContentsColor)
      .attr('text-anchor', this.Note.NoteAlign)
      .selectAll('tspan')
      .data((this.Note.NoteContentsText || '').split('\n'))
      .enter()
      .append("tspan")
      .attr("x", 0)
      // .attr("dx", 0)
      .attr("dy", Math.round(this.Note.NoteContentsSize * 1.2))
      .text(d => d)
    }
  }
}
