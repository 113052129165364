import lmx_drawScene        from './3D/draw_Scene'
import lmx_loadFBX          from './3D/load_FBX'
import lmx_sceneEvents      from './3D/Scene_Events'

export default {
  mixins: [
    lmx_drawScene,
    lmx_loadFBX,
    lmx_sceneEvents,
  ]
}