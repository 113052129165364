import * as d3 from 'd3'

export default {
  methods: {
    draw_SkylineCharts() {

      let self = this
      this.canvasSkyline = this.svg
      .append('g')
      .attr('id', 'SKYLINE')
      .attr('transform', `translate(${this.timeline.baseX}, 0)`)
      .attr('font-family', 'roboto')

      d3.selection.prototype.moveToFront = function() {
        return this.each(function(){
          this.parentNode.appendChild(this);
        });
      }

      this.timeline.week.forEach((w, i) => {

        let hovorScale = 1.4
        let drawY = this.timeline.baseY - this.skyline.skyBox.height - (this.skyline.skyBox.gap/2)

        let dateFiltered = null
        dateFiltered = this.area_Data.filter(f => f.cDate == w.eDate)

        dateFiltered.forEach((d, i) => {
          
          let x1 = this.timeline.scale(d.sDate) + this.skyline.skyBox.gap
          let y1 = drawY
          let width_ = this.timeline.scale(d.cDate) - this.timeline.scale(d.sDate) - this.skyline.skyBox.gap

          let Boxes = this.canvasSkyline
          
          .append('g')
          .attr('id', `BOX_${d[this.currentStage.col]}`)
          .attr('transform', `translate(${x1},${y1})`)
          .style('cursor', 'pointer')
          .on('mouseover', function() {
            let factor = (hovorScale - 1) / 2
            let scale_x = width_ * factor
            let scale_y = self.skyline.skyBox.height * factor
            
            d3.select(this).moveToFront().transition().duration(150)
            .attr('transform', `translate(${x1-scale_x},${y1-scale_y}) scale(${hovorScale})`)

            d3.select('#DETAIL').transition().duration(200)
            .attr('opacity', 1)

            self.mouseOver_Detail(d) // ---> Detailed Information
          })
          .on('mouseout', function(){
            d3.select(this).transition().duration(150)
            .attr('transform', `translate(${x1},${y1}) scale(1)`)

            d3.select('#DETAIL').transition().delay(500).duration(300)
            .attr('opacity', self.detail.onOff == 'ON' ? 1 : 0)
          })
          .on('click', () => { this.link_Skyline(d[this.currentStage.col]) })

          Boxes
          .append('rect') // Background
          .attr('x', 0).attr('y',0)
          .attr('rx', 1)
          .attr('ry', 1)
          .attr('width', width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', '#fff')
          .attr('stroke', '#757575')
          .attr('stroke-width', .5)

          Boxes
          .append('rect') // Fill Color
          .attr('x', 0).attr('y',0)
          .attr('rx', 1)
          .attr('ry', 1)
          .attr('width', d.type == 'PRO' || d.type == 'REMAIN' ? width_ * (1) : width_)
          .attr('height', this.skyline.skyBox.height)
          .attr('fill', d.bColor)
          .attr('fill-opacity', d.opacity)
       
          Boxes
          .append('text')
          .attr('x', width_/2)
          .attr('y', this.skyline.skyBox.height/2 + 1)
          .attr('font-family', this.skyline.skyBox.font)
          .attr('font-size', d.tSize)
          .attr('fill', '#000')
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .style('pointer-events', 'none')
          .text(d[this.currentStage.col])

          drawY = drawY - this.skyline.skyBox.height - (this.skyline.skyBox.gap)
          
          // Draw the number of box per Month
          if(i == dateFiltered.length-1) {
            this.boxGroup = this.canvasSkyline
            .append('text')
            .attr('class', 'block_group_count')
            .attr('transform', `translate(${x1 + (width_/2)}, ${drawY + this.skyline.skyBox.height - 5})`)
            .style('font-family', 'roboto')
            .style('font-size', 10)
            .style('fill', '#757575')
            .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle')
            .attr('visibility', 'visible')
            .text(dateFiltered.length)
          }
          
        })
      })
    },

  }
}
