<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import * as d3 from 'd3'
import __M from 'moment'

import SvgService       from '../../../../src/services/svg.service'

import { SafeIdMixin }  from '../../../../includes/mixins/safeid.mixin'
import Timeline         from '../../../../includes/mixins/timeline.v2.mixin'

import Declares         from './mixins/declares.mixin'

export default {
  name: 'k-chart-tl-ms-single-item-schedule',
  mixins: [
    SafeIdMixin,
    Timeline,
    Declares
  ],
  props: {
    id: String,
    item: {
      type: Object,
      default: () => ({
        TASK:"Shipment5",
        EQ_DESC:"CAUSTICTOWER HPDEPROPANIZER",
        HH_PCS:2,
        LOGI_VENDOR:"SLTC",
        DUR_DAY:4,
        PLAN_ARR_DATE:"2022-11-10",
        PLAN_DEP_DATE:"2022-11-13",
        FC_ARR_DATE:"2022-12-05",
        FC_DEP_DATE:"2022-12-08",
        ACT_ARR_DATE:"2022-12-07",
        ACT_DEP_DATE:"2022-12-11"
      })
    },
    chart: {
      type: Object,
      default: () => ({ x: 0, y: 0 })
    },
    cutoffline: {
      type: Object,
      default: () => ({ y1: -1, y2: 1 })
    },
    monthline: {
      type: Object,
      default: () => ({ y1: 0, y2: 1, opacity: .5 })
    },
    size: {
      type: String,
      default: () => 'large'
    },
    keyName: {
      type: String,
      default: () => 'TASK'
    },
    syncparent: {
      type: Object,
      default: () => null
    }
  },
  watch: {
    syncparent(d) { 
      if(!d) return 
      this.predraw()
    },
    item(d) { 
      if(!d) return 
      this.predraw()
    }
  },
  data: () => ({
    svgService: null,

    svg: null,
    timelineArea: null,
    cutoff: null,

    color: [
      '#bcbdbe',
      '#ebc61c',
      '#6cd91c',
      '#4075ec',
      '#40c6ec',
      '#da40ec',
      '#ec7940',
      '#ec4040',
      '#40ec99',
    ]
  }),
  created() {
    this.localId = `${this.id || 'k-chart-tl-ms-single-item-schedule'}__${this.safeId('')}`
    this.svgService = new SvgService()

    d3.selection.prototype.moveToFront = function() {
      return this.each(function(){
        this.parentNode.appendChild(this)
      })
    }
    d3.selection.prototype.moveToBack = function() {  
      return this.each(function() { 
        var firstChild = this.parentNode.firstChild; 
        if (firstChild) { 
            this.parentNode.insertBefore(this, firstChild); 
        } 
      })
    }
  },
  mounted() {
    this.run()
  },
  methods: {
    predraw() {
      this.cutoff = this.syncparent.cutoff
      this.timeline = {
        width     : this.syncparent.w,
        height    : this.size == 'small' ? 20 : 80,
        blockWidth: 30,
        startDate : this.syncparent.sdate,
        endDate   : this.syncparent.edate,
        weekday   : this.syncparent.weekday,
        scale     : null,
        year      : [],
        month     : [],
        week      : [],
      }
      this.setTimelineEnv()
      this.draw()
    },
    draw() {
      this.setDefaultValues()

      this.timelineArea = this.svg
      .append('g')
      .attr('id', 'schedule_wrapper')
      .attr('transform', `translate(${this.chart.x}, ${this.chart.y})`)

      this.drawSchedule()

      let el = d3.select(`#${this.localId} #schedule_wrapper`).node()
      let elbox = el.getBoundingClientRect()
      if(this.size != 'small') this.timeline.height = Math.round(elbox.height) + 22
    
      this.svg.attr('width', this.timeline.width)
      this.svg.attr('height', this.timeline.height)

      this.drawMonthLine()

      // setTimeout(() => { this.toXmlString() }, 500)
    },
    drawMonthLine() {
      // Timeline Seperator
      let mLineWrapper = this.timelineArea
      .append('g')
      .attr('class', '__month_line_wrapper')
      .attr('transform', `translate(0, 0)`)

      this.timeline.month.forEach((d, i) => {
        mLineWrapper
        .append('line')
        .attr('x1', Math.round(this.timeline.scale(d.eDate))+.5).attr('y1', this.monthline.y1)
        .attr('x2', Math.round(this.timeline.scale(d.eDate))+.5).attr('y2', this.timeline.height+this.monthline.y2)
        .attr('stroke', '#eee').attr('stroke-width', 1)
        .style('opacity', this.monthline.opacity)
      })

      mLineWrapper.moveToBack()

      if(this.cutoff < this.timeline.startDate) return
      
      /**
       * draw cutoff line
       */
      this.timelineArea
      .append('line')
      .attr('x1', Math.round(this.timeline.scale(__M(this.cutoff).toDate()))+.5).attr('y1', this.cutoffline.y1)
      .attr('x2', Math.round(this.timeline.scale(__M(this.cutoff).toDate()))+.5).attr('y2', this.timeline.height+this.cutoffline.y2)
      .attr('stroke', '#fa2c72').attr('stroke-width', 1)
      .style('opacity', .5)
    },
    drawSchedule() {
      let keys = Object.keys(this.target)
      let schedule = {}

      keys.forEach(k => {
        schedule[k] = { 
          arr: this.item[this.target[k].start] ? Math.round(this.timeline.scale(__M(this.item[this.target[k].start] + ' 00:00:00').toDate())) : null,
          dep: this.item[this.target[k].end] ? Math.round(this.timeline.scale(__M(this.item[this.target[k].end] + ' 23:59:59').toDate())) : null, 
          w: 0, 
        }
        
        if(schedule[k].dep && schedule[k].arr) schedule[k].w = schedule[k].dep - schedule[k].arr
      })

      let y__ = this.size == 'small' ? 3 : 12
      let barH = this.size == 'small' ? 3 : 12
      let barGap = this.size == 'small' ? 5 : 15

      /**
       * draw plan bar
       */
      keys.forEach((k, i) => {
        let wrapper = this.timelineArea
        .append('g')
        .attr('class', `schedule_${k}_bar_wrapper`)

        let fnDrawLine__ = () => {
          if(schedule[k].arr) wrapper
          .append('rect')
          .attr('transform', `translate(${schedule[k].arr}, ${y__+barGap*i})`)
          .attr('rx', 2).attr('ry', 2)
          .attr('width', schedule[k].w)
          .attr('height', barH)
          .attr('fill', this.color[i])
        }

        if(this.syncparent.milestone && this.item[this.syncparent.milestone.typeColName]) {
          let col__ = this.syncparent.milestone.typeColName
          let type_ = this.syncparent.milestone.typeCode

          if(this.item[col__] == type_.line) fnDrawLine__()
          else {
            let date__ = schedule[k].arr || schedule[k].dep
            
            if(date__) wrapper
            .append('circle')
            .attr('cx', date__)
            .attr('cy', y__+barGap*i+(barH/2))
            .attr('r', barH*.65)
            .style('fill', this.color[i])
          }

        } else fnDrawLine__()

        if(this.size != 'small' && this.item[this.target[k].start]) wrapper
        .append('text') // Start Date
        .attr('transform', `translate(${schedule[k].arr-3}, ${y__+7+barGap*i})`)
        .attr('text-anchor', 'end')
        .attr('alignment-baseline', 'middle')
        .style('font-size', 9)
        .style('fill', '#bcbcbc')
        .text(`${d3.timeFormat('%d-%b')(__M(this.item[this.target[k].start]).toDate())}`)

        if(this.size != 'small' && this.item[this.target[k].end] && this.target[k].start != this.target[k].end) wrapper
        .append('text') // Finish Date
        .attr('transform', `translate(${schedule[k].dep+3}, ${y__+7+barGap*i})`)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .style('font-size', 9)
        .style('fill', '#bcbcbc')
        .text(`${d3.timeFormat('%d-%b')(__M(this.item[this.target[k].end]).toDate())}`)
      })
    },
    toXmlString() {
      let elString = this.svgService.dom2xmlString(d3.select(`#${this.localId}`))
      this.$emit('to-xml-string', {
        [this.item[this.keyName]]: {
          xmlString: elString,
          width: this.timeline.width,
          height: this.timeline.height,
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.svg_wrapper {
  display: flex !important;
}
</style>