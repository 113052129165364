import __C from '../../../primitives/_constant_'
import __F from '@/primitives/_function_'
import LocalStorage from '@/services/local.storage/appservice.persistence'
import { ApplicationService } from '@/services'

const _db = new LocalStorage()
const _appService = new ApplicationService()

export default {
  namespaced: true,
  state: {
    // Instant values
    dashboardID: -1,
    dimention: null,
    docID: '',
    type: 'PAGE',   // PAGE | PAGESVG

    resultSet: {
      chartItem: {},  // Fot the PAGESVG
      chartItems: [],
      dashboard: {},
      filterChains: [],
      filterOptions: [],
      filterCompoment: {},
    },

    /*** stored props in the local storage ***/
    // 'filteredValues' JS Data Structure
    // {
    //   COLUMN_NAME: "<String|Number|Array>",
    //   ...
    // }
    // Example:
    // {
    //   BLOCK:[],
    //   DEL_LEAD:["Rinat Bakirov","In-Tae Song","Zaigul Dossaliyeva"],
    //   MODULE:["42M073","44K156","43M104"],
    //   PE_BLOCK:["6HA","8QC","4HA","1UC"],
    //   RFS:"2020",
    //   SEALIFT:"2019",
    //   YARD:"DSM"
    // }
    filteredValues: {},
    filtersPushed: {},
    /* ------------------------------------ */

    actions: '',   // JSON Format (String)
    envValues: {},

    extHeight: 0,
  },
  mutations: {
    setActions (state, action) { state.actions = action },
    setChartItems (state, chartItems_) { state.chartItems = chartItems_ },
    setDashboardId (state, id) { state.dashboardID = id },
    setDimention(state, d) { state.dimention = d },
    setDocID (state, id) { state.docID = id },
    setEnvValues (state, values) { state.envValues = values },
    setExtHeight(state, h) { state.extHeight = h },
    setFilteredValues (state, values) { state.filteredValues = values },
    setFiltersPushed (state, values) { state.filtersPushed = values },
    setResultSet (state, result) { state.resultSet = result },
    setDashboard (state, dashboard) { state.resultSet.dashboard = dashboard },
    setChartItemsResultSet (state, chartItems) { state.resultSet.chartItems = chartItems },
    setChartItem (state, chartItem) { state.resultSet.chartItem = chartItem },
    setType (state, type) { state.type = type },
  },
  getters: {
    exportInfo: (state) => {
      if(state.type == 'PAGE') var info_ = {
        name: state.resultSet.dashboard.name,
        ...(state.resultSet.dashboard.layoutAttrs.dimention || {})
      }; else if (state.type == 'MODELVIEWER') info_ = {
        name: 'Module Status',
        ...(state.dimention || {})
      }; else info_ = {
        name: state.resultSet.chartItem.Name,
        width: state.resultSet.chartItem.CanvasWidth,
        height: state.resultSet.chartItem.CanvasHeight
      }

      if(state.extHeight) info_.height += state.extHeight

      return info_
    },
    filterChains: (state) => {
      if(!state.resultSet.filterChains || state.resultSet.filterChains.length == 0) return null
      return state.resultSet.filterChains
    },
    filterOptions: (state) => {
      if(!state.resultSet.filterOptions || state.resultSet.filterOptions.length == 0) return null
      return state.resultSet.filterOptions
    },
    filteredValues: (state) => {
      if(!state.filteredValues || Object.keys(state.filteredValues).length == 0) return null
      return state.filteredValues
    },
    filteredValuesTrimed: (getters) => {
      if(!getters.filteredValues) return null

      // hard cloning of the object
      let js = JSON.parse(JSON.stringify(getters.filteredValues))
      // remove empty element, becuase not accepted emtpy element to the database
      Object.keys(js).forEach(k => {
        if(!js[k] || (typeof js[k] == 'object' && Array.isArray(js[k]) && js[k].length === 0)) delete js[k]
      })

      if(Object.keys(js).length === 0) return null

      return js
    },
    filterCompAvailable: (state) => { 
      let avail_ = (
        state.resultSet.filterCompoment &&
        state.resultSet.filterCompoment.componentName &&
        state.resultSet.filterCompoment.filterOptions &&
        state.resultSet.filterCompoment.filterOptions.length > 0
      )
      return avail_
    },
    filterComponentAttrs(state) { return {
      canvas: { ...__F.extractEls(state.resultSet.filterCompoment.componentAttrs, 'canvas') }
    }},
    filterComponentName(state) { return state.resultSet.filterCompoment.componentName },
    filterCompOptions(state) { return state.resultSet.filterCompoment.filterOptions }
  },
  actions: {
    initService ({ state, rootState, commit, dispatch }) {
      if(
        !rootState[__C.STORE_NAMESPACE.APPLICATION].navState || 
        !rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id
      ) {
        commit('setDashboardId', 0)
        return new Promise(resolve => resolve(null))
      }
      
      // id : nav-menu idx (item || subitem)
      let id___ = rootState[__C.STORE_NAMESPACE.APPLICATION].navState.id
      let docID__ = `DASHBOARD:${state.type}:${id___}`
      commit('setDocID', docID__)

      return new Promise((resolve) => {
        _db.getEnvValues(docID__).then(eValues => {
          commit('setEnvValues', eValues)

          // to do: need to be checked previously stored filtered values...

          _db.getFilterStatus(id___).then(fStatus => {
            commit(`${__C.STORE_NAMESPACE.APP_SERVICE}/setNonFilter`, fStatus.nonFilter, { root: true })

            _db.getFilteredValues(docID__).then(fValues => {
              commit('setDashboardId', id___)

              if(Object.keys(state.filtersPushed).length > 0) {
                commit('setFilteredValues', state.filtersPushed)
                dispatch('postInit')
                resolve(true)

              } else {
                if(!fStatus || fStatus.nonFilter != 'Y') {
                  commit('setFilteredValues', fValues)
                  resolve(true)
  
                } else {
                  dispatch('updateFilteredValues', {}).then(() => {
                    resolve(true)
                  })
                  .catch(this.$showError)
                }
              }
            })
          })
        })
      })
    },
    postInit({ state, rootState, commit, dispatch }) {
      commit('setFiltersPushed', {})
    },
    getDashboard({ state, getters, commit }, requestType) {
      if(!state.dashboardID || state.dashboardID < 1) 
        return new Promise((resolve) => { resolve(false) })
      
      let fValues = getters.filteredValuesTrimed ? JSON.stringify(getters.filteredValuesTrimed) : ''
      
      let params = new URLSearchParams()
      params.append('idx', state.dashboardID)
      params.append('catCode', 'Page')
      params.append('fValues', fValues)
      params.append('iFilter', '')
      params.append('actions', state.actions)
      params.append('reqType', requestType)

      console.log('[USER: #DEBUG# MENU-IDX]', state.dashboardID)
      console.log('[USER: #DEBUG# FILTERED]', fValues)
      console.log('[USER: #DEBUG# ACTIONS ]', state.actions)
      console.log('[USER: #DEBUG# REQUEST ]', requestType)

      return new Promise((resolve) => {
        _appService.getDashboard(
          params, 
          (resultSet) => {
            // responded data structure
            // {
            //   dashboard:
            //   chartItems:
            //   filterChains: [
            //    [ ...filterOptions ],
            //    [ ...filterOptions ],
            //    ...
            //   ],
            //   filterOptions: [{      // LNB Filtering Options
            //     colName: String,     // Physical Column Name
            //     label: String,       // Displaying Column Name
            //     type: String,        // Component type
            //     options: Array       // Component Values
            //   }],
            //   filterCompoment: {
            //     componentName: String,
            //     componentAttrs: Object,
            //     filterOptions: Array
            //   },
            // }

            commit(`${__C.STORE_NAMESPACE.DASHBOARD_ADMIN}/setDashboardItem`, resultSet.dashboard, { root: true })
            
            if(['home', 'init'].includes(requestType)) {
              resultSet['filterChains'] = resultSet.filterChainOptions
              commit('setResultSet', resultSet)

              // if(!state.filteredValues || Object.keys(state.filteredValues).length === 0)
              //   commit('setFilteredValues', resultSet.filters)
                
            } else {
              commit('setDashboard', resultSet.dashboard)
              commit('setChartItemsResultSet', resultSet.chartItems)
            }

            resolve({
              dashboard: resultSet.dashboard,
              chartItems: resultSet.chartItems,
            })
          }
        )
      }).catch(this.$showError)
    },
    getPageSvg({ state, getters, commit }, params) {
      if(!state.dashboardID || state.dashboardID < 1) return new Promise((resolve) => { resolve(false) })

      if(typeof params == 'object') {
        var requestType = params.reqType
        var action = params.action ? JSON.stringify(params.action) : ''
      } else {
        requestType = params
        action = ''
      }

      let fValues = getters.filteredValuesTrimed ? JSON.stringify(getters.filteredValuesTrimed) : ''
      let urlParams = new URLSearchParams()
      urlParams.append('idx', state.dashboardID)
      urlParams.append('catCode', 'Page')
      urlParams.append('fValues', fValues)
      urlParams.append('iFilter', '')
      urlParams.append('action', action)
      urlParams.append('reqType', requestType)

      console.log('[USER: #DEBUG# MENU-IDX]', state.dashboardID)
      console.log('[USER: #DEBUG# FILTERED]', fValues)
      console.log('[USER: #DEBUG# ACTION  ]', action)
      console.log('[USER: #DEBUG# REQUEST ]', requestType)

      return new Promise((resolve) => {
        _appService.getPageSvg(
          urlParams, 
          (resultSet) => {
            // responded data structure
            // {
            //   chartItem: {}          // Page-SVG
            //   filters: {}            // Initial Values for the global filters if it is not set.
            //   filterChains: [
            //    [ ...filterOptions ],
            //    [ ...filterOptions ],
            //    ...
            //   ],
            //   filterOptions: [{      // LNB Filtering Options
            //     colName: String,     // Physical Column Name
            //     label: String,       // Displaying Column Name
            //     type: String,        // Component type
            //     options: Array       // Component Values
            //     component: Array     // When the type is 'custom', component name should be defined.
            //   }],
            //   filterCompoment: {
            //     componentName: String,
            //     componentAttrs: Object,
            //     filterOptions: Array
            //   },
            // }

            if(requestType == 'init') {
              commit('setResultSet', {
                chartItem: resultSet.chartItem,
                filterChains: resultSet.filterChainOptions,
                filterOptions: resultSet.filterOptions,
                filterCompoment: resultSet.filterCompoment
              })
            } else {
              commit('setChartItem', resultSet.chartItem)
            }

            commit('setFilteredValues', resultSet.filters)
            
            resolve(resultSet.chartItem)
          }
        )
      })
      .catch(this.$showError)
    },

    resetFilterChain({ state }, params) {
      let initParameters = new URLSearchParams()
      initParameters.append('idx', params.id)           // menu-item or sub-item's idx
      initParameters.append('fNames', params.fNames)    // targeted chain & columns
      initParameters.append('fValues', params.fValues)

      return new Promise((resolve) => {
        _appService.requestFilteredChainOptions(
          initParameters,
          (res) => {
            // responded data structure
            // {
            //   chainOptions: [ ...filterOptions ]
            // }
            resolve(res.chainOptions)
          }
        )
      })
    },
    sendRequest({ state, dispatch }, type = 'init') {
      if(state.type == __C.DASHBOARD.TYPE_CODE_PAGESVG) return dispatch('getPageSvg', type)
      return dispatch('getDashboard', type)
    },
    updateEnvValues ({ state, commit }, values) { 
      return new Promise((resolve) => {
        _db.updateEnvValues(state.docID, values).then(() => {
          commit('setEnvValues', values)
          resolve(true)
        })
        .catch(this.$showError)
      })
    },
    updateFilteredValues ({ state, commit }, values) { 
      return new Promise((resolve) => {
        _db.updateFilteredValues(state.docID, values).then(() => {
          commit('setFilteredValues', values)
          resolve(true)
        })
        .catch(this.$showError)
      })
    },
    removeFilteredValues ({ state, commit }) { 
      return new Promise((resolve) => {
        _db.removeFilteredValues(state.docID).then(() => {
          commit('setFilteredValues', {})
          resolve(true)
        })
        .catch(this.$showError)
      })
    },
  }
}
