import Vue from 'vue'
import __F from '@/primitives/_function_'

export default {
  namespaced: true,
  state: {
    // DataItems --
    chartData: [],
    // ------------
    chartItem: {},
    sqlValues: {
      milestone: [],
      note: '',
    },
  },
  mutations: {
    setChartData(state, data) { state.chartData = data },
    setChartItem(state, item) {
      if (Object.keys(item).length > 0) {
        Object.keys(item).forEach(k => {
          if (Object.keys(state.chartItem).includes(k)) state.chartItem[k] = item[k]
          else Vue.set(state.chartItem, k, item[k])
        })        
      } else state.chartItem = {}
    },
    setEmpty(state) {
      state.chartData = []
      state.chartItem = {}
      state.sqlValues = {
        milestone: [],
        note: '',
      }
    },
    setSqlValueMilestone(state, data) { state.sqlValues.milestone = data },
    setSqlValueNote(state, val) { state.sqlValues.note = val },
  },
  getters: {
    general: (state) => ({
      PhaseCode: state.chartItem.PhaseCode || '',
      GroupCode: state.chartItem.GroupCode || '',
      ItemCode: state.chartItem.ItemCode || '',
      SubItem: state.chartItem.SubItem || 0,
      Name: state.chartItem.Name || ''
    }),
    database: (state) => ({
      DatabaseAccess: state.chartItem.QApplied || '',
      DatabaseJSON: state.chartItem.JsonString || '',
      DatabaseQuery: state.chartItem.QSummary || '',
      DatabaseSp: state.chartItem.QSp || ''
    }),
    axis      : (state) => ({ ...__F.extractEls(state.chartItem, 'axis') }),
    bar       : (state) => ({ ...__F.extractEls(state.chartItem, 'bar') }),
    box       : (state) => ({ ...__F.extractEls(state.chartItem, 'box') }),
    canvas    : (state) => ({ ...__F.extractEls(state.chartItem, 'canvas') }),
    circle    : (state) => ({ ...__F.extractEls(state.chartItem, 'circle') }),
    compare   : (state) => ({ ...__F.extractEls(state.chartItem, 'compare') }),
    filter    : (state) => ({ ...__F.extractEls(state.chartItem, 'filter') }),
    guideline : (state) => ({ ...__F.extractEls(state.chartItem, 'guide') }),
    legend    : (state) => ({ ...__F.extractEls(state.chartItem, 'legend') }),
    line      : (state) => ({ ...__F.extractEls(state.chartItem, 'line') }),
    milestone : (state) => ({ ...__F.extractEls(state.chartItem, 'milestone') }),
    note      : (state) => ({ ...__F.extractEls(state.chartItem, 'note') }),
    package_  : (state) => ({ ...__F.extractEls(state.chartItem, 'package') }),
    table     : (state) => ({ ...__F.extractEls(state.chartItem, 'table') }),
    timeline  : (state) => ({ ...__F.extractEls(state.chartItem, 'timeline') }),
    title     : (state) => ({ ...__F.extractEls(state.chartItem, 'title') }),
    values    : (state) => ({ ...__F.extractEls(state.chartItem, 'value') }),
    chtcomm   : (state) => ({ ...__F.extractEls(state.chartItem, 'chtcomm') }),
    chttype   : (state) => ({ ...__F.extractEls(state.chartItem, 'chttype') }),
    chtcol    : (state) => ({ ...__F.extractEls(state.chartItem, 'chtcol') }),
    tags      : (state) => ({ ...__F.extractEls(state.chartItem, 'tags') }),
    usagestatus : (state) => ({ ...__F.extractEls(state.chartItem, 'usagestatus') }),

  },
  actions: {
  }
}
