import { NoticeService } from '../../services'

const _noticeService = new NoticeService()

export default {
  namespaced: true,
  state: {
    unreads: {},
  },
  getters: {
  },
  mutations: {
    setUnreadsNum (state, num) { state.unreads = num },
  },
  actions: {
    getUnreadNoticeNum({ commit }, sender, account) {
      return new Promise(resolve => {
        _noticeService.getUnreadsNum(sender, account).then(data => {
          if(!data) {
            resolve(null)
            return
          }
          commit('setUnreadsNum', data.unreads)
          resolve(data.unreads)
        })
      })
    },
  }
}
