import * as d3 from 'd3'
import moment from 'moment'

export default {

  methods: {
    
    draw_Timeline_Month() {   

      this.canvasTimeline = this.canvasForm
      .append('g')
      .attr('id', 'TIMELINE')
      .attr('transform', `translate(${this.timeline.baseX}, ${this.timeline.baseY})`)

      /////////// draw the timeline background without the text
      this.canvasTimeline
      .append('path') // shadow
      .attr('d', `M50,${this.timeline.height + 6} H ${this.timeline.length-50} L ${this.timeline.length},${this.timeline.height + 16} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`) 

      this.canvasTimeline
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 2)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .99)

      this.canvasTimeline
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.length).attr('height', this.timeline.height)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .5)

      this.canvasTimeline
      .append('rect') 
      .attr('x', 0).attr('y', this.timeline.height + 1).attr('width', this.timeline.length).attr('height', 6)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`)


      // Month Text & Timeline Seperator for the Month
      this.canvasTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 4)
      .style('font-size', 10)
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name.toUpperCase())

      this.canvasTimeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)}, 2 V15`
      })
      .attr('stroke', '#BCBCBC')
      .attr('stroke-width', 0.5)

      // Year Text & Timeline Seperator for the Year
      this.canvasTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 3 +  20)
      .style('font-size', 11)
      .attr('fill', '#000')
      .attr('font-weight', 500)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      this.canvasTimeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, ${15} V${35}`
      })
      .attr('stroke', '#BCBCBC')
      .attr('stroke-width', 0.5)


      // cutoff month highlight
      let cutoff = moment(this.timeline.cutoff).format('YYYY-MM')

      this.timeline.month.forEach(d => {
        if (moment(d.eDate).format('YYYY-MM') == cutoff) {
          this.canvasTimeline
          .append('rect')
          .attr('x',this.timeline.scale(d.sDate) + 1)
          .attr('y',2.5)
          .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate) - this.skyline.skyBox.gap)
          .attr('height', 11)
          .style('opacity', 0.3)
          .attr('fill','#00b0f0')
          .attr('stroke','#bcbcbc')
          .attr('stroke-width',0.5)               
        }
      })
    }, 


    draw_Timeline_Week() {    
      let timelineHeight = 40
      this.canvasTimeline = this.canvasForm
      .append('g')
      .attr('transform', `translate(${this.timeline.baseX}, ${this.timeline.baseY})`)

      /////////// draw the timeline background without the text
      this.canvasTimeline
      .append('path') // shadow
      .attr('d', `M50,${timelineHeight + 6} H ${this.timeline.length-50} L ${this.timeline.length},${timelineHeight + 16} H0 Z`)
      .attr('fill', `url(#shadowTimeline)`) 

      this.canvasTimeline
      .append('rect') 
      .attr('x', 0).attr('y', 0).attr('width', this.timeline.length).attr('height', 2)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .99)

      this.canvasTimeline
      .append('rect')
      .attr('x', 0).attr('y', 2).attr('width', this.timeline.length).attr('height', timelineHeight)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`).attr('opacity', .5)

      this.canvasTimeline
      .append('rect') 
      .attr('x', 0).attr('y', timelineHeight + 1).attr('width', this.timeline.length).attr('height', 6)
      .attr('fill', `url(#${this.localId}_LightGray_TL)`)


      // Week Text ------------------------------------------------------
      this.canvasTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.week)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 11)
      .style('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'middle')
      .text(d => d.name)

      this.canvasTimeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.week)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.week.length-1) return `M${this.timeline.scale(d.eDate)}, 2 V 11`
      })
      .attr('stroke', '#bcbcbc')
      .attr('stroke-width', .5)


     // cutoff Week highlight
     let cutoff = moment(this.timeline.cutoff).format('YYYY-MM-DD')

     this.timeline.week.forEach(d => {
       if (moment(d.eDate).format('YYYY-MM-DD') == cutoff) {
        this.canvasTimeline
         .append('rect')
         .attr('x',this.timeline.scale(d.sDate) + 1)
         .attr('y',.5)
         .attr('width', this.timeline.scale(d.eDate) - this.timeline.scale(d.sDate))
         .attr('height',14)
         .style('opacity', 0.3)
         .attr('fill','#00b0f0')
         .attr('stroke','#bcbcbc')
         .attr('stroke-width',0.5)               
       }
     })


      // Month Text ------------------------------------------------------
      this.canvasTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.month)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 16)
      .style('font-size', 9)
      .attr('fill', '#333')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name.toUpperCase())

      this.canvasTimeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.month)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.month.length-1) return `M${this.timeline.scale(d.eDate)}, 11 V20`
      })
      .attr('stroke', '#BCBCBC')
      .attr('stroke-width', 0.5)


      // Year Text ------------------------------------------------------
      this.canvasTimeline
      .append('g')
      .selectAll('text')
      .data(this.timeline.year)
      .enter()
      .append('text')
      .attr('x', d => this.timeline.scale(d.mDate))
      .attr('y', 29)
      .style('font-size', 12)
      .attr('fill', '#000')
      .attr('font-weight', 500)
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('cursor', 'default')
      .text(d => d.name)

      this.canvasTimeline
      .append('g')
      .selectAll('path')
      .data(this.timeline.year)
      .enter()
      .append('path')
      .attr('d', (d, i) => {
        if (i != this.timeline.year.length-1) return `M${this.timeline.scale(d.eDate)}, 20 V38`
      })
      .attr('stroke', '#BCBCBC')
      .attr('stroke-width', 0.5)



    }, 
  }
}
