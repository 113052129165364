import MilestoneDeclares from './Milestone_Declares.mixin'

export default {
  mixins: [ MilestoneDeclares ],
  methods: {
    // 'aValues' structure -----------------------------------------
    // {
    // s: this.svg,                  // Selection where the AxisY-Wrapper(SVG Group) to be placed.
    // x: this.Canvas.CanvasChartX,  // X coordinate of the Axis wrapper(SVG Group)
    // y: this.Canvas.CanvasChartY,  // Y coordinate of the Axis wrapper(SVG Group)
    // h: Number,                    // Height of the line
    // w: Number,                    // Milestone Area Width
    // scaleX: Callback Function     // Scale Callback function for X coordinate
    // },     
    drawMilestone(mValues) {
      if(this.Milestone.MilestoneDisplay != 'Y') return

      this.setMilestoneValues(mValues)

      if(!this.isMilestoneDataValid) return
      
      this.drawMilestoneArea(mValues.s)
      this.drawMilestoneContent()
    },
    drawMilestoneArea(selection) {
      this.milestoneArea = selection
      .append('g')
      .attr('class', `milestone_area___${this.localId}`)
      .attr('transform', `translate(${this.milestoneAreaX},${this.milestoneAreaY})`)
    },
    drawMilestoneContent() {
      let itemProc = this.milestoneArea
      .selectAll('g')
      .data(this.milestoneData)
      .enter()

      let milestoreSinleArea = itemProc
      .append('g')
      .attr('transform', d => `translate(${this.scaleMilestone(this.parseDate(d.CDATE)) + this.milestoneXCPoint},0)`)
      
      milestoreSinleArea
      .append('path')
      .attr('d', `M 0,0 V ${-this.Milestone.MilestoneLineHeight}`)
      .attr('stroke', this.Milestone.MilestoneLineColor)
      .attr('stroke-width', this.Milestone.MilestoneLineWeight)
      .style('stroke-dasharray', this.Milestone.MilestoneLineStyle)

      milestoreSinleArea
      .append('text')
      .attr('transform', `rotate(-90) translate(${this.Milestone.MilestoneLineHeight},${this.Milestone.MilestoneTextSize})`)
      .attr('text-anchor', 'end')
      .style('font-family', this.Milestone.MilestoneTextFont)  
      .style('font-size', this.Milestone.MilestoneTextSize)
      .style('fill', this.Milestone.MilestoneTextColor)
      .text(d => `${d.desc} ${d.CDATE}`)
    },
  }
}