<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        

export default {
  name: "j-chart-weekly",
  mixins: [mx_Core],
  props: {
    id: String,
    ChartType: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps:  { type: Array, default: () => ([]) },
    Database:     { type: Object, default: () => ({}) },
    Canvas:       { type: Object, default: () => ({}) },
    Common:       { type: Object, default: () => ({}) },
    CTypes:       { type: Object, default: () => ({}) },
    DataItems:    { type: Array, default: () => ([]) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-weekly'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }
      this.clear()
      this.set_LocalValues()
      this.drawCanvas()
      this.drawing()
      this.complete()
    },
    drawing() {
      // Call functions
      this.draw_WeekPerformance()
    },
  }
}
</script>