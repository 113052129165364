import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs
import Data from '../../../../includes/primitives/Color_Data'

export default {
  methods: {
    drawChartEmbossed() {
      let chartWrapper = this.chartArea
        .append('g')
        .attr('class', '_chart_wrapper__')
        .selectAll('g')
        .data(this.DataItems)
        .enter()
        .append('g')
        .attr('transform', d => `translate(${this.scaleXInfo.scale(d.title)},0)`)

      chartWrapper // Shadow
        .append('ellipse')
        .attr('cx', Math.round(this.setThickness / 2))
        .attr('cy', this.Canvas.CanvasChartHeight)
        .attr('rx', Math.round((this.setThickness * 1.5) / 2))
        .attr('ry', Math.round((this.setThickness * 1.5) / 3 / 2))
        .attr('fill', `url(#${this.localId}__Shadow)`)

      if (this.Bar.BarBackDisplay == 'Y') {
        chartWrapper // Back bar
          .append('rect')
          .attr('rx', this.Bar.BarRadius)
          .attr('ry', this.Bar.BarRadius)
          .attr('width', this.setThickness)
          .attr('height', this.Canvas.CanvasChartHeight)
          .attr('fill', this.Bar.BarBackColor)
      }

      chartWrapper // Front bar
      .append('path') // Cyinder Box
      .attr('d', d => {
        return `
          M 0 ${this.Canvas.CanvasChartHeight} 
          V ${this.Canvas.CanvasChartHeight} 
          H ${this.setThickness} 
          V ${this.Canvas.CanvasChartHeight}
          `
        })
      .attr('fill', (_, i) => `url(#${this.localId}__${this.Bar.BarColorType}${i})`)
      .transition()
      .duration(500)
      .attr('d', d => {
        return `
          M 0 ${this.Canvas.CanvasChartHeight} 
          V ${this.scaleYInfo.scale(d.value)} 
          H ${this.setThickness} 
          V ${this.Canvas.CanvasChartHeight}
          `
        })
      // ------------------------------------------------------------------------- Series & Value
      if (this.Bar.BarSeriesDisplay == 'Y') {
        chartWrapper // Series Name
          .append('text')
          .attr('transform', `translate(${Math.round(this.setThickness / 2)},${this.Canvas.CanvasChartHeight + Math.round(this.setThickness / 3) + this.Bar.BarSeriesSpace})`)
          .attr('class', this.Bar.BarSeriesStyle)
          .style('font-size', this.Bar.BarSeriesSize)
          .style('font-family', this.Bar.BarSeriesFont)
          //.style('font-weight', this.Bar.BarSeriesWeight)
          .attr('fill', (_, i) => (this.Bar.BarSeriesAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarSeriesColor))
          .attr('text-anchor', 'middle')
          .text(d => d.title)
      }

      let valueWrapper = chartWrapper // Value
        .append('g')
        .attr('id', (_, i) => `value_wrapper_${i}`)

      if (this.Bar.BarValueDisplay == 'Y') {
        valueWrapper
          .append('text')
          .attr('id', (_, i) => `ValueText${i}`)
          .attr('class', this.Bar.BarValueStyle)
          .style('font-size', this.Bar.BarValueSize)
          .style('font-family', this.Bar.BarValueFont)
          //.style('font-weight', this.Bar.BarValueWeight)
          .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarValueColor))
          .text(d => `${d.value.toFixed(this.Bar.BarValueRound)}`)
      }

      if (this.Bar.BarUnitDisplay == 'Y') {
        valueWrapper // Units
          .append('text')
          .attr('transform', (_, i) => `translate(${this.Bar.BarValueDisplay == 'Y' ? Number(this.getNodeElValue(`#ValueText${i}`, 'width')) : 0},0)`)
          .attr('id', (_, i) => `ValueUnit${i}`)
          .attr('class', this.Bar.BarValueStyle)
          .style('font-size', this.Bar.BarUnitSize)
          .style('font-family', this.Bar.BarValueFont)
          //.style('font-weight', this.Bar.BarValueWeight)
          .attr('fill', (_, i) => (this.Bar.BarValueAutoColor == 'Y' ? Data.setGradients['Represent'](this.SharedColorSet[i]) : this.Bar.BarUnitColor))
          .text(this.Bar.BarUnitFormat)
      }

      // Reset the position of the value-wrapper
      this.DataItems.forEach((d, i) => {
        let valueWrapperId_ = `#value_wrapper_${i}`
        let valueWrapperBox_ = d3.select(`#value_wrapper_${i}`).node().getBoundingClientRect()
        let wrapperWidth_ = this.getNodeElValue(valueWrapperId_, 'width')

        this.svg.select(`#value_wrapper_${i}`)
        .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)},${this.Canvas.CanvasChartHeight - this.Bar.BarValueSpace})`)
        .transition()
        .duration(500)
        .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)}, 
            ${this.scaleYInfo.scale(d.value) - this.Bar.BarValueSpace + ( this.Bar.BarValuePosition == 'top' ?  0 : + valueWrapperBox_.height)}
          )`)
        // .attr('transform', `translate(${Math.round((this.setThickness - wrapperWidth_) / 2)},${this.scaleYInfo.scale(d.value) - this.Bar.BarValueSpace})`)
      })
    }
  }
}
