import * as d3 from 'd3'
import Filter from '../../../../includes/primitives/Filter_Defs'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_Legends() {

      if(this.Legends.LegendDisplay != 'Y') return

      let self_ = this
      let legendCumY = 0
      let tpmY = 0
      let Base_Point = 0
      let legends = this.svg

      .append('g')
      .attr('id', 'legends')
      .attr('transform', `translate(${this.Legends.LegendX},${this.Legends.LegendY})`)

      // Tool Bax Title
      legends
      .append('text') 
      .attr('class', this.Legends.LegendTitleStyle)
      .style('font-family', this.Legends.LegendTitleFont).style('font-size', this.Legends.LegendTitleSize) .attr('fill', this.Legends.LegendTitleColor)
      .attr('text-anchor', 'start').attr('alignment-baseline', 'middle').text(this.Legends.LegendTitleText)
      
      // ----------------------------------------------------------------- Draw Legends of CATEGORY
      legendCumY += this.Legends.LegendTitleSpace
      legends
      .append('text')
      .attr('class', this.Legends.LegendGroupStyle)
      .attr('transform', `translate(${this.Legends.LegendTapSpace},${legendCumY})`)
      .style('font-family', this.Legends.LegendGroupFont)
      .style('font-size', this.Legends.LegendGroupSize)
      .attr('fill', this.Legends.LegendGroupColor)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(this.Legends.LegendGroupCatText)

      let comp_Status = JSON.parse(JSON.stringify(this.Status))
      let CategoryGroup = legends
      .selectAll()
      .data(comp_Status.StatusCategory)
      .enter()
      .append('g')
      .attr('id', (d,i) => `category_${i}___${this.localId}`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .on('click', (d,i) => this.displayController(d,'category', i))

      CategoryGroup
      .append('rect')
      .attr('x', this.Legends.LegendTapSpace*2) .attr('y', 0)
      .attr('width', this.Legends.LegendBulletSize)
      .attr('height', this.Legends.LegendBulletSize)
      .attr('fill', '#ffffff')
      .attr('stroke', (d) => d.value)
      .attr('stroke-width', 1)

      CategoryGroup
      .append('text')
      .attr('class', this.Legends.LegendTextStyle)
      .style('font',this.Legends.LegendTextFont)
      .attr('transform', `translate(${this.Legends.LegendTapSpace*2 + this.Legends.LegendBulletSize + 5},${(this.Legends.LegendBulletSize/2) + 1})`)
      .style('font-size', this.Legends.LegendTextSize).attr('fill', this.Legends.LegendTextColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text((d) => d.name)
      
      Base_Point = 0
      legendCumY += this.Legends.LegendGroupSpace
      if (this.Legends.LegendDirection == 'Horizontal') {
        this.Status.StatusCategory.forEach((_, i) => {
          this.svg.select(`#category_${i}___${this.localId}`).attr('transform', `translate(${Base_Point},${legendCumY})`)
          Base_Point += this.getNodeElValue(`#category_${i}___${this.localId}`,'width') + 10
        })
      }
      else 
      if (this.Legends.LegendDirection == 'Vertical') {
        this.Status.StatusCategory.forEach((_, i) => {
          this.svg.select(`#category_${i}___${this.localId}`).attr('transform', `translate(${0},${legendCumY})`)
          legendCumY += this.Legends.LegendBulletSpace
        })
      }

      // ----------------------------------------------------------------- Draw Legends of STATUS
      if (this.Legends.LegendDirection == 'Horizontal') 
        legendCumY += this.Legends.LegendGroupSpace + this.Legends.LegendBulletSpace
      else 
        legendCumY += this.Legends.LegendGroupSpace 
   
      legends
      .append('text')
      .attr('class', this.Legends.LegendGroupStyle)
      .attr('transform', `translate(${this.Legends.LegendTapSpace},${legendCumY})`)
      .style('font-family', this.Legends.LegendGroupFont)
      .style('font-size', this.Legends.LegendGroupSize)
      .attr('fill', this.Legends.LegendGroupColor)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(this.Legends.LegendGroupStatusText)

      let StatusGroup = legends
      .selectAll()
      .data(comp_Status.StatusStage)
      .enter()
      .append('g')
      .attr('id', (d,i) => `Status_${i}___${this.localId}`)
      .attr('opacity', 1)
      .style('cursor', 'pointer')
      .on('click', (d,i) => this.displayController(d, 'stage', i))

      

      StatusGroup
      .append('rect')
      .attr('x', this.Legends.LegendTapSpace*2).attr('y', 0)
      .attr('width', this.Legends.LegendBulletSize).attr('height', this.Legends.LegendBulletSize).attr('fill', (d) => d.value).attr('stroke', '#E0E0E0').attr('stroke-width', 1)

      StatusGroup
      .append('text')
      .attr('transform', `translate(${this.Legends.LegendTapSpace*2 + this.Legends.LegendBulletSize + 5},${(this.Legends.LegendBulletSize/2) + 1})`)
      .attr('class', this.Legends.LegendTextStyle)
      .style('font',this.Legends.LegendTextFont)
      .style('font-size', this.Legends.LegendTextSize) .attr('fill', this.Legends.LegendTextColor).attr('text-anchor', 'start').attr('alignment-baseline', 'middle')
      .text((d) => d.name)
      
      Base_Point = 0
      legendCumY += this.Legends.LegendGroupSpace
      if (this.Legends.LegendDirection == 'Horizontal') {
        this.Status.StatusStage.forEach((_, i) => {
          this.svg.select(`#Status_${i}___${this.localId}`).attr('transform', `translate(${Base_Point},${legendCumY})`)
          Base_Point += this.getNodeElValue(`#Status_${i}___${this.localId}`,'width') + 10
        })
      } else if (this.Legends.LegendDirection == 'Vertical') {
        this.Status.StatusStage.forEach((_, i) => {
          this.svg.select(`#Status_${i}___${this.localId}`).attr('transform', `translate(${0},${legendCumY})`)
          legendCumY += this.Legends.LegendBulletSpace
        })
      }
    },
    
    displayController(d_, group_, i) {
      let opacityBox = 0
      let opacityLegend = 0

      if (d_.display == 'Y') {opacityBox = 0; opacityLegend = .3; d_.display = 'N';} else
      if (d_.display == 'N') {opacityBox = 1; opacityLegend = 1;  d_.display = 'Y';}

      if (group_ == 'category') {

        this.svg.select(`#category_${i}___${this.localId}`)
        .transition().duration(100)
        .attr('opacity', opacityLegend)

        const result = this.Status.StatusStage.filter(status_ => status_.display == 'Y')
        result.forEach(target_=> {
          // console.log(target_.code, target_.display) //
         
          this.Comp_DataItems.forEach(item_ => {
            item_.values.forEach(value_ => {
              if (d_.code == value_.cat1 && target_.code == value_.cat2) this.svg.select(`#n${value_.name}___${this.localId}`).transition().duration(400).attr('opacity', opacityBox)
            })
          })
        })
      }

      if (group_ == 'stage') {

        this.svg.select(`#Status_${i}___${this.localId}`)
        .transition().duration(100)
        .attr('opacity', opacityLegend)

        const result = this.Status.StatusCategory.filter(status_ => status_.display == 'Y')
        result.forEach(target_=> {
          // console.log(target_.code, target_.display) //
         
          this.Comp_DataItems.forEach(item_ => {
            item_.values.forEach(value_ => {
              if (d_.code == value_.cat2 && target_.code == value_.cat1) this.svg.select(`#n${value_.name}___${this.localId}`).transition().duration(400).attr('opacity', opacityBox)
            })
          })
        })
      }
    },


  }
}
