import * as d3 from 'd3'

export default {
  methods: {

    draw_Model_Erection() {
      
      this.model_Erection = this.svg
      .append('g')
      .attr('id', `MODEL_ERECTION`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Erection, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/${this.current.area}.svg`
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')

        // ########################################################### HULL ###########################################################
        if (this.current.area == 'Hull') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-700,-1500) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(1000).style('opacity', 1).attr('transform', `translate(250,0) scale(1)`)
  
          this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#LINE-4TH`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(1000).attr('transform', `translate(${d.X2},${d.Y2}) scale(.7)`)
            d3.select(`#LINE-2ND`).transition().duration(300).delay(1800).style('opacity', 1)
          })

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#PNG-${d.BLK}`)
              .transition().duration(1000).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(.7)`)
              d3.select(`#LINE-3RD`).transition().duration(300).delay(1000).style('opacity', 1)
            })
          }, 1200)

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#PNG-${d.BLK}`)
              .transition().duration(500).delay(500).attr('transform', `translate(${d.X4},${d.Y4}) scale(.7)`)
              d3.select(`#LINE-4TH`).transition().duration(300).delay(1000).style('opacity', 1)
            })
          }, 2200)

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#${d.BLK}`)
              .transition().duration(500).attr('opacity', 1)
              d3.select(`#MASK-${d.BLK}`)
              .attr('cursor', 'pointer').call(this.call_Erection, d)
            })
            this.draw_Erection_Canvas() // ----->
          }, 3000)
        }

        // ########################################################### TOPSIDE ###########################################################
        if (this.current.area == 'Topside') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-1000,-1300) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(1000).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(1000).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#LINE-2ND`).transition().duration(300).delay(1800).style('opacity', 1)
          })

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#PNG-${d.BLK}`)
              .transition().duration(1000).delay(500).attr('transform', `translate(${d.X3},${d.Y3}) scale(1)`)
              d3.select(`#LINE-3RD`).transition().duration(300).delay(1000).style('opacity', 1)
            })
          }, 1200)

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#${d.BLK}`)
              .transition().duration(500).style('opacity', 1)
              d3.select(`#MASK-${d.BLK}`)
              .attr('cursor', 'pointer').call(this.call_Erection_Topside, d)
            })
            this.draw_Erection_Canvas() // ----->
          }, 2000)
        }



        // ########################################################### LQ ###########################################################
        if (this.current.area == 'LQ') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 0).attr('transform', `translate(-1000,-1300) scale(3)`)
          d3.select(`#MODEL_ERECTION`).transition().duration(1000).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {

            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`)
            .transition().duration(750).delay(1000).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#LINE-3RD`).transition().duration(300).delay(1800).style('opacity', 1)
          })

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#${d.BLK}`)
              .transition().duration(500).style('opacity', 1)
              d3.select(`#MASK-${d.BLK}`)
              .attr('cursor', 'pointer').call(this.call_Erection_Topside, d)
            })
            this.draw_Erection_Canvas() // ----->
          }, 1500)
        }

        
        
      })

    },


    back_Model_Erection() {
      
      this.model_Erection = this.svg
      .append('g')
      .attr('id', `MODEL_ERECTION`)
      .attr('transform', `translate(340,80)`)
      .attr('font-family', 'roboto')

      this.loadSvg(
        this.model_Erection, {
        x   : 0,
        y   : 0,
        url : `/Visualization/BlockDivision/${this.current.area}.svg`
      }).then(() => {

        d3.selectAll("text").style('font-family', 'roboto')

        // --------------------------------------- Hull ----------------------------------------------
        if (this.current.area == 'Hull') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 1).attr('transform', `translate(250,0) scale(1)`)
  
          this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).attr('visibility', 'hidden').style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#LINE-4TH`).style('opacity', 0) 

            d3.select(`#PNG-${d.BLK}`).transition().duration(750).attr('transform', `translate(${d.X4},${d.Y4}) scale(.7)`)
            d3.select(`#${d.BLK}`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#LINE-3RD`).transition().duration(500).delay(250).style('opacity', 1)
            d3.select(`#LINE-4TH`).transition().duration(500).delay(250).style('opacity', 1) 
            d3.select(`#LINE-4TH`).transition().duration(500).delay(250).style('opacity', 1)
          })

          this.draw_Erection_Canvas() // ----->

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#MASK-${d.BLK}`).attr('cursor', 'pointer').attr('visibility', 'display').call(this.call_Erection, d)
            })
          }, 1000)
        }

        // --------------------------------------- TOPSIDE ----------------------------------------------
        if (this.current.area == 'Topside') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).attr('visibility', 'hidden').style('opacity', 0)
            d3.select(`#LINE-2ND`).style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#TEXTS`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`).transition().duration(750).attr('transform', `translate(${d.X3},${d.Y3}) scale(1)`)
            d3.select(`#${d.BLK}`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#LINE-2ND`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#LINE-3RD`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#TEXTS`).transition().duration(500).delay(500).style('opacity', 1) 
          })

          this.draw_Erection_Canvas() // ----->

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#MASK-${d.BLK}`).attr('cursor', 'pointer').attr('visibility', 'display').call(this.call_Erection_Topside, d)
            })
          }, 1000)
        }


        // --------------------------------------- LQ ----------------------------------------------
        
        if (this.current.area == 'LQ') {
          d3.select(`#MODEL_ERECTION`).style('opacity', 1).attr('transform', `translate(100,30) scale(1)`)
  
          this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
            d3.select(`#${d.BLK}`).attr('opacity', 0)
            d3.select(`#MASK-${d.BLK}`).attr('visibility', 'hidden').style('opacity', 0)
            d3.select(`#LINE-3RD`).style('opacity', 0)
            d3.select(`#TEXTS`).style('opacity', 0)

            d3.select(`#PNG-${d.BLK}`).transition().duration(750).attr('transform', `translate(${d.X2},${d.Y2}) scale(1)`)
            d3.select(`#${d.BLK}`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#LINE-3RD`).transition().duration(500).delay(500).style('opacity', 1)
            d3.select(`#TEXTS`).transition().duration(500).delay(500).style('opacity', 1) 
          })

          this.draw_Erection_Canvas() // ----->

          setTimeout(() => { 
            this.block_List.filter(f=> f.AREA == this.current.area).forEach(d => {
              d3.select(`#MASK-${d.BLK}`).attr('cursor', 'pointer').attr('visibility', 'display').call(this.call_Erection_Topside, d)
            })
          }, 1000)
        }

       
        
      })

    },
  }
}