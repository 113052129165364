import * as d3 from 'd3'

export default {
  
  methods: {

    draw_2PE_Canvas(_d) {
      
      this.canvas_2PE = this.svg
      .append('g')
      .attr('id', `CANVAS_2PE`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.button_Home_v2(
        this.canvas_2PE, 
        {x: 450, y: 97, scale: 0.8,}, 
        this.call_Home, 
        {
          model: this.model_2PE,  
          canvas: this.canvas_2PE, 
          func: this.draw_Model_Overall,
          data: null
        }
      )

      this.button_Back_v2(
        this.canvas_2PE, 
        {x: 490, y: 97, scale: 0.8,}, 
        this.call_Back,
        {
          model: this.model_2PE,  
          canvas: this.canvas_2PE, 
          func: this.back_Model_Erection,
          data: null
        }
      )



      // Load a Legend SVG
      this.loadSvg(
        this.canvas_2PE, {
        x: 30, y: 150,
        url : '/Visualization/BlockDivision/includes/Keyplan_Topside.svg'
      }).then(() => {

        this.keyPlan.forEach(d => {
          if(_d.KP !== d) d3.select(`#${d}`).style('opacity', 0)
        })
        d3.selectAll("text").style('font-family', 'roboto')
      })
      


      // Load a Legend SVG
      this.loadSvg(
        this.canvas_2PE, {
        x: 25, y: 965,
        url : '/Visualization/BlockDivision/includes/Legends_Horizontal.svg'
      }).then(() => {
        d3.selectAll("text").style('font-family', 'roboto')
      })

      // this.canvas_2PE
      // .append('text')
      // .attr('transform', `translate(1350, 50)`)
      // .attr('font-size', 24).attr('fill', '#000')
      // .text('CANVAS - 2PE ' + this.current.pe)


    },
  }
}