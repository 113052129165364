import * as d3 from 'd3'

export default {
  data: () => ({
    selectedItems: null,
    getItems: null,
  }),

  methods: {

    set_selectedItems(){
      this.selectedItems = {
        ...this.selectedItems,
        phaseId: this.current.phase.id,
        areaId: this.current.area.id,
        discId: this.current.disc.id,
      }
    },

    draw_PDF() {
      // Phase part
      this.current.phase = this.WBS.phase.find(f => f.id == this.getItems.phaseId)
      if(this.getItems.areaId !== 'OV') {
        this.current.area = this.WBS.area.find(f => f.id == this.getItems.areaId)
      }
      if(this.getItems.discId !== 'OV') {
        this.current.disc = this.WBS.disc.find(f => f.id == this.getItems.discId)
      }

      d3.select(`#PHASE_RADIO_DT`).attr('fill', 'none')
      d3.select(`#PHASE_RADIO_${this.current.phase.id}`).attr('fill', '#F35E90')
      this.current.phase.name = this.current.phase.name
      this.current.phase.id = this.current.phase.id

      d3.selectAll(`.AREA_TEXT`).text(`${this.current.phase.name} Status`)
      d3.select(`#DISC_MAIN_TEXT`).text(`Progress by Discipline for ${this.current.phase.name}(${this.current.area.name})`)
      d3.select(`#DISC_SUB_TEXT`).text(`${this.current.phase.name} Progress Status`)

      this.summary_Controller(this.current.phase.name)
      this.draw_Chart_Controller(true)

      // Area part
      if(this.current.area.id !== 'OV') {
        d3.select(`#AREA_RADIO_${this.current.area.id}`).attr('fill', '#44A9DF')

        this.WBS.disc.forEach((d,i)=> {
          this.draw_Disc_Summary(d)
        })
        d3.select(`#DISC_MAIN_TEXT`).text(`Progress by Discipline for ${this.current.phase.name}(${this.current.area.name})`)
  
        this.draw_Chart_Controller(true)
      }

      // Disc part
      if(this.current.disc.id !== 'OV') {
        d3.select(`#DISC_CHECK_${this.current.disc.id}`).attr('opacity', 1)
        this.current.disc.name = this.current.disc.name
        this.current.disc.id = this.current.disc.id

        d3.select(`#ADDRESS`)
        .text(`${this.current.phase.name} > ${this.current.area.name} > ${this.current.disc.name}`)

        this.draw_Chart_Controller(true)
      }
    },
  }
}