import * as d3 from 'd3'

export default {
  methods: {

    draw_Text(selection, d, c, py, value, colName, dataSource) {
      
      let styleSet = this.Styles.find(f => f.type == 'Text' && f.series == this.clone_Columns[c].series)
      if (styleSet.zeroDisplay == 'N' && value == 0) return
      
        let textOnly = selection
        .append('g')
        .attr('class', 'item_text')
        .attr('transform', `translate(0,0)`)
        .on('mouseover', () => {
          if (styleSet.linked == 'Y') {
            textOnly.style('cursor', 'pointer')
            d3.select(`#${this.localId}_textOnly_${d}_${c}`).transition().duration(150).attr('font-size', styleSet.textSize + styleSet.hovorText).attr('fill', styleSet.hovorColor)
          }
        })
        .on('mouseout', () => {
          if (styleSet.linked == 'Y') {
            textOnly.style('cursor', 'default')
            d3.select(`#${this.localId}_textOnly_${d}_${c}`).transition().duration(150).attr('font-size', styleSet.textSize).attr('fill', styleSet.textColor)
          }
        })
        .on('click', () => {
          let request_ = this.getRequestColumnProps(colName, dataSource, this.ColumnProps)
          if(request_) {
            request_.filters = {
              ...request_.filters,
              ...this.FilterValues
            }
            this.$emit('request-action', request_)
          }   
        })

        
        textOnly
        .append('text')
        .attr('id', `${this.localId}_textOnly_${d}_${c}`)
        .attr('x', this.clone_Columns[c].px)
        .attr('y', py)
        .style('font-family', styleSet.textFont)
        .attr('class', styleSet.textStyle)
        .attr('font-size', styleSet.textSize)
        .attr('fill', styleSet.textColor)
        .attr('text-anchor', this.clone_Columns[c].align)
        .attr('alignment-baseline', 'middle')
        .text(value)
          
        // console.log(this.clone_Columns[c].px)
    },


  }
}
