import __C from '@/primitives/_constant_'
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, ['commentProps', 'modalOpened']),

    commentable() { 
      return [
        // '/service/system_completion/mechanical_completion/mc_skyline',
      ].includes(this.$route.path) && this.modalOpened
    },
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setCommentOpened', 'setCommentProps', 'setCommentTitle'
    ]),
    onCommentOpen() {
      this.setCommentOpened(true)
    },
  }
}