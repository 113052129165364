<template>
  <tab 
    ref="tab"
    :id="id" 
    :label="label"
    @close="onClosed"
    @resize="onResized"
  />
</template>

<script>
import Tab from '../../svgchartlib/09_Projects/TCO/00_System/ModalTaps'
export default {
  name: 'j-modal-slide-tab',
  components: {
    Tab
  },
  props: {
    id: String,
    label: String,
    // colorIndex: {
    //   type: Number,
    //   default: () => 0
    // },
    value: null
  },
  data: () => ({
    colorIndex: 0,
  }),
  methods: {
    onClosed() {
      this.$emit('close')
    },
    onResized(d) {
      this.$emit('resize', d)
    },
    draw() {
      this.$refs.tab.colorIndex = this.colorIndex
      this.$refs.tab.draw()
    }
  }
}
</script>
<style lang="stylus" scoped>

</style>
