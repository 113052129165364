// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin } from '../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
import gmx_Controller from '../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas from '../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Titles from '../../../../includes/mixins/Draw_Titles.mixin'
import gmx_Legens from '../../../../includes/mixins/Draw_Legends.mixin'
import gmx_Notes from '../../../../includes/mixins/Draw_Notes.mixin'
// Global Functions
import gmx_Miscellaneous from '../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable from '../../../../includes/mixins/Movable.mixin'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares from './Declares.mixin'  
import lmx_Elements from './Elements.mixin'          // Common functions for Drawing the Charts & Tables
import lmx_Header from './Draw_Header.mixin'          //Drawing the Header in Table
import lmx_DataCells from './Draw_DataCells.mixin'          //Drawing the Header in Table
import lmx_Footer from './Draw_Footer.mixin'
import lmx_TableLines from './Draw_TableLines.mixin'

import lmx_Coloring from './Style_Coloring.mixin'
import lmx_BarChart from './Style_BarChart.mixin'
import lmx_Icons from './Style_Icons.mixin'
import lmx_Text from './Style_Text.mixin'

export default {
  mixins: [
    SafeIdMixin,
    
    gmx_Controller,
    gmx_Canvas,
    gmx_Titles,
    gmx_Legens,
    gmx_Notes,
    gmx_Miscellaneous,
    gmx_Movable,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Elements,
    lmx_Header,
    lmx_DataCells,
    lmx_Footer,
    lmx_TableLines,
    
    lmx_Text,
    lmx_Coloring,
    lmx_BarChart,
    lmx_Icons,
  ],
}
