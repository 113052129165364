import * as d3 from 'd3'

export default {
  data: () => ({
    dataItems: []
  }),
  computed: {
    ready2draw() {
      return this.DataItems.length > 0
    },
  },
  methods: {
    setDefaultValues() {
      this.dataItems = JSON.parse(JSON.stringify(this.DataItems)).map(d => ({
        total: d3.format(",")(d.total),
        actual: d3.format(",")(d.actual),
        rem: d.total - d.actual,
        prog: d.actual / d.total
      }))
    }
  }
}