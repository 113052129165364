import * as d3 from 'd3'
import __C from '../../../../includes/primitives/_constant_'   // Global Defs
import _ColorData from '../../../../includes/primitives/Color_Data'
import Defs from '../../../../includes/primitives/Color_Defs'

export default {
  methods: {
    //------------------------------------------------------------------ drawLegends
    drawChart() {
      this.drawBar()
      this.drawLine()
      this.drawBarSeries()
      this.drawBox()
      this.drawGradient()
    },
    drawGradient() {
      this.FloatBar.forEach(d => {
        if(!Object.keys(_ColorData.setGradients.Length).includes(d.BarColorType)) return
        Defs.setDefs_Gradients(this.svg, 1, d.BarColorType, [d.BarColor], [d.BarColor, -0.5, 0.5, 1.5, 0.5], this.localId)
      })
    },
    drawBar() {
      if(this.FloatBar.length === 0) return
      let rectG = this.svg.append('g')
      .attr('class', `rectG_${this.localId}`)

      Array.from({length: this.FloatBar.length}, () => null).forEach((d, i) => {
        //bg
        rectG
        .append('rect')
        .attr('x', this.FloatBar[i].BarX)
        .attr('y', this.FloatBar[i].BarY)
        .attr('width', this.FloatBar[i].BarWidth)
        .attr('height', this.FloatBar[i].BarHeight)
        .attr('rx', this.FloatBar[i].BarRadius)
        .attr('ry', this.FloatBar[i].BarRadius)
        .style('fill', this.FloatBar[i].BarBgColor)
        .style('opacity', this.FloatBar[i].BarOpacity)

        //actual
        rectG
        .append('rect')
        .attr('x', this.FloatBar[i].BarX)
        .attr('y', this.FloatBar[i].BarY)
        .attr('width', this.dataItems[i] ? this.FloatBar[i].BarWidth * this.dataItems[i].prog : this.FloatBar[i].BarWidth * 0)
        .attr('height', this.FloatBar[i].BarHeight)
        .attr('rx', this.FloatBar[i].BarRadius)
        .attr('ry', this.FloatBar[i].BarRadius)
        .attr('fill', (d_, i_) => Object.keys(_ColorData.setGradients.Length).includes(this.FloatBar[i].BarColorType) ? `url(#${this.localId}__${this.FloatBar[i].BarColorType}${this.FloatBar[i].BarColor}0)` : this.FloatBar[i].BarColor)
        .style('opacity', this.FloatBar[i].BarOpacity)
      }) 
    },
    drawBarSeries() {
      if(this.FloatBarSeries.length === 0) return

      let BarSereisTextG = this.svg.append('g')
      .attr('class', `BarSeriesTextG_${this.localId}`)
      Array.from({length: this.FloatBarSeries.length}, () => null).forEach((d, i) => {
        Object.keys(this.FloatBarSeries[0]).forEach(key => {
          if(this.FloatBarSeries[i][key].BarSeriesDisplay != 'Y') return

          let barSeries = BarSereisTextG
          .append('text')
          .attr(__C.CHART.MOVED_EL_TYPE_BARSERIES, `FloatBarSeries$${i}$${key}$BarSeriesX$BarSeriesY`)
          .attr('class', this.FloatBarSeries[i][key].BarSeriesClass)
          .style('font-family', this.FloatBarSeries[i][key].BarSeriesFont)
          .style('font-size', this.FloatBarSeries[i][key].BarSeriesSize)
          .style('text-anchor', this.FloatBarSeries[i][key].BarSeriesAlign)
          .style('fill', this.FloatBarSeries[i][key].BarSeriesColor)

          if(key != 'prog') {
            barSeries
            .text(this.dataItems[i] ? this.dataItems[i][key] : null)
            .attr('x', null) 
            .attr('y', null)
            .attr('transform', key == 'prog' ? '' : `translate(${this.FloatBarSeries[i][key].BarSeriesX},${this.FloatBarSeries[i][key].BarSeriesY})`)
            .call(this.el2Drag)
            .call(this.setCursorMovable)
          } else {
            barSeries
            .text(this.dataItems[i] ? d3.format(",.1%")(this.dataItems[i][key]) : null)
            .attr('x',this.dataItems[i] ? this.FloatBar[i].BarWidth * this.dataItems[i].prog + this.FloatBarSeries[i][key].BarSeriesX : this.FloatBarSeries[i][key].BarSeriesX) 
            .attr('y',this.dataItems[i] ? this.FloatBar[i].BarY + this.FloatBarSeries[i][key].BarSeriesY : this.FloatBar[i].BarY)
          }
          
        })
      }) 
    },
    drawLine() {
      if(this.FloatLine.length === 0) return

      let lineG = this.svg.append('g')
      .attr('class', `lineG_${this.localId}`)
      Array.from({length: this.FloatLine.length}, () => null).forEach((d, i) => {
        //actual
        lineG
        .append('line')
        .attr('transform', `translate(${this.FloatLine[i].LineX1},${this.FloatLine[i].LineY1})`)
        .attr(__C.CHART.MOVED_EL_TYPE_LINE, `FloatLine$${i}$LineX1$LineY1`)
        .attr('x1', 0)
        .attr('y1', 0)
        .attr('x2', this.FloatLine[i].LineDirection === 'Horizontal' ? this.FloatLine[i].LineWidth : 0)
        .attr('y2', this.FloatLine[i].LineDirection === 'Vertical' ? this.FloatLine[i].LineWidth : 0)
        .style('stroke', this.FloatLine[i].LineStrokeColor)
        .style('stroke-width', this.FloatLine[i].LineStrokeWidth)
        .style('opacity', this.FloatLine[i].LineOpacity)
        .call(this.el2Drag)
        .call(this.setCursorMovable)
      }) 
    },
    drawBox() {
      if(this.FloatBox.length === 0) return
      let boxG = this.svg.append('g')
      .attr('class', `boxG_${this.localId}`)

      Array.from({length: this.FloatBox.length}, () => null).forEach((d, i) => {
        //bg
        boxG
        .append('rect')
        .attr('x', this.FloatBox[i].BoxX)
        .attr('y', this.FloatBox[i].BoxY)
        .attr('width', this.FloatBox[i].BoxWidth)
        .attr('height', this.FloatBox[i].BoxHeight)
        .attr('rx', this.FloatBox[i].BoxRadius)
        .attr('ry', this.FloatBox[i].BoxRadius)
        .style('fill', this.FloatBox[i].BoxColor)
        .style('stroke', this.FloatBox[i].BoxStrokeColor)
        .style('stroke-width', this.FloatBox[i].BoxStrokeWidth)
        .style('opacity', this.FloatBox[i].BoxOpacity)
        
      })
    }
  }
}
