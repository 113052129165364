/* 
  ### SVG Controller for the Page Customized ### 
  Unregulated page components.
*/

// Common
import Comm_TabModalContainer from '../01_ModalContainer'

// Project
import Project_InformationGuideSectors from '../../09_Projects/China1/01_Project/InformationGuideSectors'

// Planining

// Engineering

// Procurement

// Construction




// ------------------------------------------------------------------------------------------------------------------------------------------------

export default {
  Comm_TabModalContainer,
  Project_InformationGuideSectors
}
