import * as d3 from 'd3'

export default {
  methods: {
    draw_Elements() {
       let Header2= [
        { group: 1,   depth: 1,   name: 'Sub-System',              colspan: 1,   rowspan: 2,  width: 180,   border: 0,  align: 'middle'},

        { group: 2,   depth: 1,   name: 'A1 QVD',                  colspan: 4,   rowspan: 1,  width: 0,     border: 0,  align: 'middle'},
        { group: 2,   depth: 2,   name: 'Toal',                    colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 2,   depth: 2,   name: 'Comp.',                   colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 2,   depth: 2,   name: 'Pcow',                    colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 2,   depth: 2,   name: 'Remain.',                 colspan: 1,   rowspan: 1,  width: 50,    border: 0,  align: 'end'},
        

        { group: 3,   depth: 1,   name: 'Punch',                   colspan: 2,   rowspan: 1,  width: 0,     border: 0,  align: 'middle'},
        { group: 3,   depth: 2,   name: 'A',                       colspan: 1,   rowspan: 1,  width: 40,    border: 1,  align: 'end'},
        { group: 3,   depth: 2,   name: 'B',                       colspan: 1,   rowspan: 1,  width: 40,    border: 0,  align: 'end'},

        { group: 4,   depth: 1,   name: 'B1 QVD',                  colspan: 4,   rowspan: 1,  width: 0,     border: 0,  align: 'middle'},
        { group: 4,   depth: 2,   name: 'Toal',                    colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 4,   depth: 2,   name: 'Comp.',                   colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 4,   depth: 2,   name: 'Pcow',                    colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 4,   depth: 2,   name: 'Remain.',                 colspan: 1,   rowspan: 1,  width: 50,    border: 0,  align: 'end'},


        { group: 5,   depth: 1,   name: 'D1 QVD',                  colspan: 4,   rowspan: 1,  width: 0,     border: 0,  align: 'middle'},
        { group: 5,   depth: 2,   name: 'Toal',                    colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 5,   depth: 2,   name: 'Comp.',                   colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 5,   depth: 2,   name: 'Pcow',                    colspan: 1,   rowspan: 1,  width: 50,    border: 1,  align: 'end'},
        { group: 5,   depth: 2,   name: 'Remain.',                 colspan: 1,   rowspan: 1,  width: 50,    border: 0,  align: 'end'},


      ]
   },



  }
}
