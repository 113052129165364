import { SystemApi } from './api'

export default class SystemService {
    
  constructor() {
    this.systemApi = new SystemApi()
  }

  getDiscSummary (itemCode, callback) {
    this.systemApi.getDiscSummary(itemCode, res => {
      callback(res)
    })
  }

  get3DItems (itemCode,itemSize, callback) {
    this.systemApi.get3DItems(itemCode,itemSize, res => {
      callback(res)
    })
  }

  get3DStage (itemCode, callback) {
    this.systemApi.get3DStage(itemCode, res => {
      callback(res)
    })
  }

  getBlockErection (callback) {
    this.systemApi.getBlockErection(res => {
      callback(res)
    })
  }

  getBlockErectionStage (callback) {
    this.systemApi.getBlockErectionStage(res => {
      callback(res)
    })
  }

  getCommodity (itemCode, callback) {
    this.systemApi.getCommodity(itemCode, res => {
      callback(res)
    })
  }

  getEqInstallation (callback) {
    this.systemApi.getEqInstallation(res => {
      callback(res)
    })
  }

  getEqInstallationStage (callback) {
    this.systemApi.getEqInstallationStage(res => {
      callback(res)
    })
  }

  getPipeInstallation (callback) {
    this.systemApi.getPipeInstallation(res => {
      callback(res)
    })
  }

  getPipeInstallationStage (callback) {
    this.systemApi.getPipeInstallationStage(res => {
      callback(res)
    })
  }

  getPressureSummary (callback) {
    this.systemApi.getPressureSummary(res => {
      callback(res)
    })
  }

  getModalListTab (callback) {
    this.systemApi.getModalListTab(res => {
      callback(res)
    })
  }

  getPressureStage (callback) {
    this.systemApi.getPressureStage(res => {
      callback(res)
    })
  }

  queryResult2JSON (params, callback) {
    this.systemApi.queryResult2JSON(params, res => { callback(res) })
  }

  getCodeGroup (callback) {
    this.systemApi.getCodeGroup(res => {
      callback(res)
    })
  }
  putCodeGroup (codeGroup, callback) {
    this.systemApi.putCodeGroup(codeGroup, res => {
      callback(res)
    })
  }
  updCodeGroup (codeGroup, callback) {
    this.systemApi.updCodeGroup(codeGroup, res => {
      callback(res)
    })
  }
  delCodeGroup (code, callback) {
    this.systemApi.delCodeGroup(code, res => {
      callback(res)
    })
  }
  getCodeIndex (groupCode, callback) {
    this.systemApi.getCodeIndex(groupCode, res => {
      callback(res)
    })
  }
  putCodeIndex (codeIndex, callback) {
    this.systemApi.putCodeIndex(codeIndex, res => {
      callback(res)
    })
  }
  updCodeIndex (codeIndex, callback) {
    this.systemApi.updCodeIndex(codeIndex, res => {
      callback(res)
    })
  }
  delCodeIndex (codeIndex, callback) {
    this.systemApi.delCodeIndex(codeIndex, res => {
      callback(res)
    })
  }
  getNavRelated ( relatedType, code, callback) {
    this.systemApi.getNavRelated( relatedType, code, res => {
      callback(res)
    })
  }
  getNavPhase (callback) {
    this.systemApi.getNavPhase(res => {
      callback(res)
    })
  }
  putNavPhase (navPhase, callback) {
    this.systemApi.putNavPhase(navPhase, res => {
      callback(res)
    })
  }
  updNavPhase (navPhase, callback) {
    this.systemApi.updNavPhase(navPhase, res => {
      callback(res)
    })
  }
  delNavPhase (phaseCode, callback) {
    this.systemApi.delNavPhase(phaseCode, res => {
      callback(res)
    })
  }
  getNavGroup (phaseCode, callback) {
    this.systemApi.getNavGroup(phaseCode, res => {
      callback(res)
    })
  }
  putNavGroup (navGroup, callback) {
    this.systemApi.putNavGroup(navGroup, res => {
      callback(res)
    })
  }
  updNavGroup (navGroup, callback) {
    this.systemApi.updNavGroup(navGroup, res => {
      callback(res)
    })
  }
  delNavGroup (phaseCode, groupCode, callback) {
    this.systemApi.delNavGroup(phaseCode, groupCode, res => {
      callback(res)
    })
  }
  getNavItem (callback, phaseCode, groupCode) {
    this.systemApi.getNavItem(res => callback(res), phaseCode, groupCode)
  }
  putNavItem (navItem, callback) {
    this.systemApi.putNavItem(navItem, res => {
      callback(res)
    })
  }
  transferPhase (navPhase, callback) {
    this.systemApi.transferPhase(navPhase, res => {
      callback(res)
    })
  }
  transferGroup (navGroup, callback) {
    this.systemApi.transferGroup(navGroup, res => {
      callback(res)
    })
  }
  transferItem (navItem, callback) {
    this.systemApi.transferItem(navItem, res => {
      callback(res)
    })
  }
  transferSubItem (navItem, callback) {
    this.systemApi.transferSubItem(navItem, res => {
      callback(res)
    })
  }
  updNavItem (navGroup, callback) {
    this.systemApi.updNavItem(navGroup, res => {
      callback(res)
    })
  }
  delNavItem (itemCode, callback) {
    this.systemApi.delNavItem(itemCode, res => {
      callback(res)
    })
  }
  orignUpdate (updateItem, callback) {
    this.systemApi.orignUpdate(updateItem, res => {
      callback(res)
    })
  }
  devUpdate (updateItem, callback) {
    this.systemApi.devUpdate(updateItem, res => {
      callback(res)
    })
  }
  getNavMenuForSubItem (callback) {
    let generateMenu = (res) => {
      if(!res) res = []

      let phases = res.filter(m => m.type == 'Phase')
      let groups = res.filter(m => m.type == 'Group')
      let items = res.filter(m => m.type == 'Item')

      groups.forEach(g => {
        g.children = items.filter(t => t.phaseCode === g.phaseCode && t.groupCode === g.groupCode)
      })
      phases.forEach(p => {
        p.children = groups.filter(g => g.phaseCode === p.phaseCode)
      })
      callback(phases)
    }
    this.systemApi.getNavMenuForSubItem(res => generateMenu(res))
  }
  getNavCatMenuForChart (callback) {
    let generateMenu = (res) => {
      if(!res) res = []

      let phases = [
        {
          phaseCode: 'ALL',
          phaseName: 'All'
        }
      ].concat(res.filter(m => m.type == 'Phase'))
      let groups = res.filter(m => m.type == 'Group')
      let items = res.filter(m => m.type == 'Item')

      groups.forEach(g => {
        g.children = items.filter(t => t.phaseCode === g.phaseCode && t.groupCode === g.groupCode)
      })
      phases.forEach(p => {
        p.children = groups.filter(g => g.phaseCode === p.phaseCode)
      })
      callback(phases)
    }
    this.systemApi.getNavCatMenuForChart(res => generateMenu(res))
  }
  getNavSubItem (callback, phaseCode, itemCode) {
    this.systemApi.getNavSubItem(res => callback(res), phaseCode, itemCode)
  }
  putNavSubItem (navSubItem, callback) {
    this.systemApi.putNavSubItem(navSubItem, res => callback(res))
  }
  updNavSubItem (navSubItem, callback) {
    this.systemApi.updNavSubItem(navSubItem, res => callback(res))
  }
  delNavSubItem (idx, callback) {
    this.systemApi.delNavSubItem(idx, res => callback(res))
  }
  getNavCatMenuNumChild (type, callback) {
    let generateMenu = (res) => {
      if(!res) res = []

      let phases = [{ phaseCode: 'ALL', phaseName: 'All' }]

      phases = phases.concat(res.filter(m => m.type == 'Phase'))
      let groups = res.filter(m => m.type == 'Group')
      let items = res.filter(m => m.type == 'Item')

      groups.forEach(g => {
        g.children = items.filter(t => t.phaseCode === g.phaseCode && t.groupCode === g.groupCode)
      })
      phases.forEach(p => {
        p.children = groups.filter(g => g.phaseCode === p.phaseCode)
      })
      callback(phases)
    }
    this.systemApi.getNavCatMenuNumChild(type, res => generateMenu(res))
  }
  getWButton (callback, phaseCode, groupCode, itemCode, subItem) {
    this.systemApi.getWButton(res => callback(res), phaseCode, groupCode, itemCode, subItem)
  }
  putWButton (wButton, callback) {
    this.systemApi.putWButton(wButton, res => {
      callback(res)
    })
  }
  updWButton (wButton, callback) {
    this.systemApi.updWButton(wButton, res => {
      callback(res)
    })
  }
  delWButton (idx, callback) {
    this.systemApi.delWButton(idx, res => {
      callback(res)
    })
  }
  getIconRelated(catCode, iconCode, callback){
    this.systemApi.getIconRelated(catCode, iconCode, res => {
      callback(res)
    })
  }
  getIcons (catCode, callback) {
    this.systemApi.getIcons(catCode, res => {
      callback(res)
    })
  }
  getIcon (iconCode, callback) {
    this.systemApi.getIcon(iconCode, res => {
      callback(res)
    })
  }
  putIcons (icon, callback) {
    this.systemApi.putIcons(icon, res => {
      callback(res)
    })
  }
  updIcons (icon, callback) {
    this.systemApi.updIcons(icon, res => {
      callback(res)
    })
  }
  delIcons (iconCode, callback) {
    this.systemApi.delIcons(iconCode, res => {
      callback(res)
    })
  }
  getCharts (catCode, callback) {
    this.systemApi.getCharts(catCode, res => {
      callback(res)
    })
  }
  getChart (chartType, callback) {
    this.systemApi.getChart(chartType, res => {
      callback(res)
    })
  }
  putChart (chart, callback) {
    this.systemApi.putChart(chart, res => {
      callback(res)
    })
  }
  updChart (chart, callback) {
    this.systemApi.updChart(chart, res => {
      callback(res)
    })
  }
  delChart (idx, callback) {
    this.systemApi.delChart(idx, res => {
      callback(res)
    })
  }
  getMemberPosition (callback) {
    this.systemApi.getMemberPosition(res => {
      callback(res)
    })
  }
  getMemberDepartment (callback) {
    this.systemApi.getMemberDepartment(res => {
      callback(res)
    })
  }
  setPriority (env, callback) {
    this.systemApi.setPriority(env, res => {
      if(!!callback) callback(res)
    })
  }
  getUpdateList (callback) {
    this.systemApi.getUpdateList(res => {
       callback(res)
    })
  }

  getWorkorders(phase, group) {
    return new Promise(resolve => {
      this.systemApi.getWorkorders(phase, group).then(res => {
        if(!res) res = []
        else {
          res.forEach(item => {
            item.dataProc = JSON.parse(item.dataProc)
            item.uxui = JSON.parse(item.uxui)
            item.implement = JSON.parse(item.implement)
            item.qc = JSON.parse(item.qc)
          })
        }
        resolve(res) 
      })
    })
  }
  getWorkorder(idx, version) {
    return new Promise(resolve => {
      this.systemApi.getWorkorder(idx, version).then(res => {
        if(!res) res = {}
        else {
          res.dataProc = JSON.parse(res.dataProc)
          res.uxui = JSON.parse(res.uxui)
          res.implement = JSON.parse(res.implement)
          res.qc = JSON.parse(res.qc)
          res.files = res.files ? JSON.parse(res.files) : []
          res.versionHist = JSON.parse(res.versionHist)
        }
        resolve(res) 
      })
    })
  }
  putWorkorder(order) {
    order.dataProc = JSON.stringify(order.dataProc)
    order.uxui = JSON.stringify(order.uxui)
    order.implement = JSON.stringify(order.implement)
    order.qc = JSON.stringify(order.qc)
    return this.systemApi.putWorkorder(order)
  }
  updWorkorder(order) {
    delete order.versionHist
    delete order.files

    order.dataProc = JSON.stringify(order.dataProc)
    order.uxui = JSON.stringify(order.uxui)
    order.implement = JSON.stringify(order.implement)
    order.qc = JSON.stringify(order.qc)
    return this.systemApi.updWorkorder(order)
  }

  getWorkorderFiles(idx, version) {
    return this.systemApi.getWorkorderFiles(idx, version)
  }
  putWorkorderFiles(params, config) {
    return this.systemApi.putWorkorderFiles(params, config)
  }
  delWorkorderFile(idx) {
    return this.systemApi.delWorkorderFile(idx)
  }
  
}
