import * as d3 from 'd3'

export default {
  methods: {

    call_LINK(selection, d_) {
      
      if (d_.link != 'Y') return
      
      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(100)
        .style('fill', 'red')
      })
      .on('mouseout', function(){
        d3.select(this).transition().duration(100)
        .style('fill', d_.tColor)
      })
      .on('click', () => {
        // if linkCol is exist, set dataSource
        let dataSource = {}

        // Default Key map
        this.columns.forEach((d, i) => {
          if (d.jMap !== '') dataSource = {...dataSource, [d.jMap]: this.rawData[d.code]}
        })
        // For Filter chain Key map
        this.filters.forEach((d, i) => {
          if (d.jMap !== '') dataSource = {...dataSource, [d.jMap]: this.rawData[d.code]}
        })
        // ********************************************************************************
        // console.log('dataSource: ', dataSource)
        // let filterTesting = d_.filter !== '' ? this.filters[d_.filter] : ''
        // console.log('filterString_: ', filterTesting)
        // ********************************************************************************
        
        // Mapping the jsonLink(value) to JsonProps in A/G 
        let request_ = this.getRequestColumnProps(d_.jLink, dataSource, this.ColumnProps)
        if(!request_) return

        // set for filterString 
        
        let filterString_ = d_.filter !== '' ? d_.filter.trim() : ''

        // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
        if(request_.iFilters.filterString) {
          request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
        } else {
          request_.iFilters.filterString = filterString_||''
        }

        // combine filterString and inputFilter &&& emit request-action
        request_ = {...request_,
          filters : {
            ...this.FilterValues,
            ...request_.filters,
          },
          iFilters: { 
            filterString : request_.iFilters.filterString||'',
            inputFilter : request_.iFilters.inputFilter,},
        }
        this.$emit('request-action', request_)
      })
    }, 


 
    
  }
}