// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin }        from '../../../../../../includes/mixins/safeid.mixin'           // ATAGlance Framework Globals
import gmx_SvgToolPath        from '../../../../../../includes/mixins/SvgTools_Path.mixin'    // SVG Tools Path
import gmx_Controller         from '../../../../../../includes/mixins/Controller.mixin'       // SVG Control
// Global Drawings
import gmx_Canvas             from '../../../../../../includes/mixins/Draw_Canvas.mixin'
import gmx_Texts              from '../../../../../../includes/mixins/Text_Draw.mixin'
// Global Functions
import gmx_Miscellaneous      from '../../../../../../includes/mixins/Miscellaneous.mixins'
import gmx_Movable            from '../../../../../../includes/mixins/Movable.mixin'
import gmx_Scrollable         from '../../../../../../includes/mixins/Scrollable.mixin'
// Global Drawing Function
import gmx_draw_BoxedSummary  from '../../../../../../includes/mixins/draw_BoxedSummary.mixin'
import gmx_draw_Table         from '../../../../../../includes/mixins/draw_Table.mixin'
import gmx_draw_TableBarChart from '../../../../../../includes/mixins/draw_TableBarChart.mixin'
import gmx_draw_Controller    from '../../../../../../includes/mixins/draw_Controllers.mixin'
// Global Event-Call Handlers
import gmx_event_callHandlers from '../../../../../../includes/mixins/event_callHandlers.mixin'

// -------------------------------------------------------------------------------------- Local
// Locally declared common Data-Props & SVG Drawing Support
import lmx_ReactiveEvents from './Realtime_Monitor.mixin' // Reactive Events
import lmx_Declares       from './Declares.mixin'
import lmx_Datastyles     from './DataStyles.mixin'
import lmx_EventCallFuncs from './event_callFunc.mixin'
import drawTagController  from './draw_tagButtonControl'
import drawCommonFunction from './draw_common.mixin'

import routerAction       from './router_action.mixin'

export default {
  mixins: [
    SafeIdMixin,

    gmx_SvgToolPath,
    gmx_Controller,
    gmx_Canvas,
    gmx_Texts,
    gmx_Miscellaneous,
    gmx_Movable,
    gmx_Scrollable,

    gmx_draw_BoxedSummary,
    gmx_draw_Table,
    gmx_draw_TableBarChart,
    gmx_draw_Controller,

    gmx_event_callHandlers,

    lmx_ReactiveEvents,
    lmx_Declares,
    lmx_Datastyles,
    lmx_EventCallFuncs,
    drawTagController,
    drawCommonFunction,

    routerAction,
  ],
}
