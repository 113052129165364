 <template>
  <div :id="localId" class="svg_wrapper">
    <div ref="svgContainer" class="svg-container">
      <ThreeComponent />
    </div>
  </div>
</template>

<script>

import * as d3 from 'd3'
import mx_Core from './includes/mixins/zMixins'
import ThreeComponent from './3Dview.vue' // ThreeComponent 가져오기
import { EventBus } from './includes/mixins/EventBus' // EventBus 가져오기

export default {
  name: "j-3D_Render",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },
  components: {
    ThreeComponent // ThreeComponent 등록
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)
  },
 
  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      // if (!this.ready2draw) {
      //   return
      // }

      // this.clear()
      // this.svg = d3.select(`#${this.localId}`).append('svg')
      this.svg = d3.select(this.$refs.svgContainer).append('svg')
      // .attr('xmlns', 'http://www.w3.org/2000/svg')
      // .attr('xmlns:xlink', 'http://www.w3.org/1999/xlink')
      // .attr('version', '1.2')
      // .attr('id', `${this.localId}_svg`)
      // .attr('class', 'canvas')
      // .attr('width', 1)
      // .attr('height', 1)

      this.setDefaultValues()
      this.drawCanvas()
      this.drawNotes()
      this.drawTexts()
      
      this.drawing_SVG() // <------ Drawing Start
      this.complete()
    },

    drawing_SVG() {
      this.sCanvasArea = this.svg
      .append('g')
      .attr('id', 'Cansvas_Area')
      .attr('transform', `translate(0, 0)`)
      .attr('font-family', 'roboto')
      
      this.draw_Button(EventBus)
    },
  },
}

</script>

<style lang="scss" scoped>
.svg_wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.svg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
</style>