import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    draw_DonutPie() {

      this.pieSectors = []
      this.calculatePieSectors()

      // Draw Background Donut Chart
      let bgGroup = this.svg
        .append('g')
        .attr('transform', `translate(${this.Circle.CircleCenterX},${this.Circle.CircleCenterY})`)

        var arc1 = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(this.angleStart * Math.PI / 180)
        .endAngle(this.angleEnd * Math.PI / 180)

        bgGroup
        .selectAll('path')
        .data(this.pieSectors)
        .enter()
        .append('path')
        .attr('d', arc1)
        .attr('fill', (d)  => d.bgColor)

      // Draw Front Donut Chart
      let frontGroup = this.svg
        .append('g')
        .attr('transform', `translate(${this.Circle.CircleCenterX},${this.Circle.CircleCenterY})`)


        let arc = d3.arc()
        .outerRadius(d => d.Ra)
        .innerRadius(d => d.Ra - d.stroke)
        .startAngle(this.angleStart * Math.PI / 180)
        .endAngle(d => d.endAngle || this.angleStart * Math.PI / 180)

        frontGroup
        .selectAll('path')
        .data(this.pieSectors)
        .enter()
        .append('path')
        .attr('d', arc)
        .attr('fill', (d)  => d.color)
        .transition()
        .duration(750)
        .attrTween('d', d => {
          let endAngle_ = ( ((this.angleSize * (d.percentage/100)) + this.angleStart) * Math.PI / 180)
          let interpolate = d3.interpolate(this.angleStart * Math.PI / 180, endAngle_)
          return function(t) {
            d.endAngle = interpolate(t)
            return arc(d);
          }
        })
    },

  }
}