<template>
  <v-dialog hide-overlay content-class="comment_dialog" v-model="opened" :width="708" >
    <v-card>
      <v-card-title class="commentsWrapper">
        <div class="popup_admin">
          <div class="popup_header">
            <div class="popup_header_text">COMMENT BOARD</div> 
            <div class="popup_close">
              <a v-ripple class="btn-close3" title="Cancel & Close" @click="onClose"><i class="mdi mdi-close"></i></a>
            </div>         
          </div>
          <div class="popup_contents_wrap">
            <div class="popup_add_wrap">
              <div class="add_btn"><button type="button" class="type01 sky j_button" @click="onAdd">Create</button></div>
            </div>
            <table class="popup_contents">
              <thead>
                <tr>
                  <th>No.</th>
                  <th>Classification</th>
                  <th>Tilte</th>
                  <th>Created By</th>
                  <th>Created Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value,index) in boardItem" :key="index" @click="propStatus(value)">
                  <td>{{value.NO}}</td>
                  <td>{{value.PHASE}}</td>
                  <td>{{value.TITLE}}</td>
                  <td>{{value.CREATER}}</td>
                  <td>{{value.CREATED}}</td>
                </tr>
              </tbody>

            </table>
          </div>   
        </div>
        <div class="popup_footer">
          <j-pagination 
            :showStatu="true"
            :pagination="pagination"
            :sideslide="true"
            @first="onPageTo(1)"
            @previous="onPageTo"
            @next="onPageTo"
            @last="onPageTo"
            />
        </div>
      <slide-modal
        v-model="modalOpened"
      >
    </slide-modal>        
      </v-card-title>
    </v-card>
  </v-dialog>
</template>
<script>
import '@/assets/stylus/ui/component/_jCommentsPopup.styl'
import JPagination from '../../lib/jin/uicomponents/JPagination'
import { mapMutations } from 'vuex'
import __C from '@/primitives/_constant_'
import __M from 'moment'
import SlideModal from '../components/JCommentsBoard.Modal'
export default {
  name: 'service-board',
  components: {
  JPagination,
  SlideModal
},
  props: {
    value: null
  },
  data: () => ({
    modalOpened: false,
    pagination: {
      page: 1,
      rowsPerPage: 15,
      totalRows: null
    },
    boardItem: [
      { NO : 1, PHASE: 'Planning', TITLE: 'DS-M049-F-004-001' , CREATER: 'PLATFORM', CREATED: '2021-01-25' },
      { NO : 2, PHASE: 'Engineering', TITLE: 'DS-M049-F-004-002' , CREATER: 'PLATFORM', CREATED: '2021-01-25' },
      { NO : 3, PHASE: 'Procurement', TITLE: 'DS-M049-F-004-003' , CREATER: 'PLATFORM', CREATED: '2021-01-25' },
      { NO : 4, PHASE: 'Construction', TITLE: 'DS-M049-F-004-004' , CREATER: 'PLATFORM', CREATED: '2021-01-25' },
      { NO : 5, PHASE: 'HSSE', TITLE: 'DS-M049-F-004-005' , CREATER: 'PLATFORM', CREATED: '2021-01-25' },
    ]
  }),
  computed: {
    opened: {
      get() { return this.value },
      set(val) { this.$emit('input', val)},
    }
  },
  created() {
    // this.setChild('')
  },
  watch: {
    modalOpened(val) {
      this.setModalOpened(val)
    },    
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild', 
      'setModalOpened'
    ]),
    onAdd() {
      this.modalOpened = true
    },   
    onClose() {
      this.opened = false
    },
    onPageTo(p) {
      this.pagination.page = p
    },
    propStatus(v) {
      
    }    
  }
}
</script>