<template>
  <div :id="localId"></div>
</template>

<script>
import mx_Core from './includes/mixins/importer'

export default {
  name: "j-chart-donut",
  mixins: [mx_Core],
  props: {
    id: String,

    FilterValues: {
      type: Object,
      default: () => ({
        MOD: 'MOD-NO',
        MOD_DESC: 'Module Description',
      })
    },

    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },

    DataItems: { type: Array, default: () => ([]) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      // Check all the necessary values are available
      if (!this.ready2draw) return

      this.clear()
      this.drawCanvas()

      this.drawChart()

      this.drawTexts()
      this.complete()
    },
    drawChart() {
    },
  }
}
</script>