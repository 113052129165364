import * as d3 from 'd3'

export default {


  methods: {

    draw_Summary() {
      let s = this.style
      let d = this.rawData

      let totalTextWidth = 0

      let Table = this.svg
      .append('g')
      .attr('id', `table-${this.localId}`)


      for(let i=0; i < d.count; i++) {
        Table
        .append('text')
        .attr('id', `cols_${this.localId}_${i}`)
        .attr('x', 0).attr('y', s.col.fSize+s.col.vPadding)
        .attr('fill', s.col.fColor).attr('font-size', s.col.fSize)
        .attr('font-weight', s.col.fWeight)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'hanging') // hanging
        .attr('font-family', 'roboto')
        .text(d.text[i])

        d.width[i] = d3.select(`#cols_${this.localId}_${i}`).node().getBBox().width
        totalTextWidth += d.width[i]
      }

      let textwidth = s.cir.width - ((d.count-1) * s.cir.padding)
      let spaceSum = textwidth - totalTextWidth
      let space = (spaceSum / d.count) / 2

      let cols_x = 0
      for(let i=0; i < d.count; i++) {

        d3.select(`#cols_${this.localId}_${i}`)
        .attr('x', cols_x + ((d.width[i] + (space * 2))/2))

        let endAn = ''

        let arc = d3.arc()
        .outerRadius(s.cir.radius)
        .innerRadius(s.cir.radius-s.cir.thickness)
        .startAngle(Math.PI / 180)
        .endAngle(() => endAn || 360 * Math.PI / 180)
  
        Table
        .append('path')
        .attr('d', arc)
        .attr('fill', s.cir.xColor)
        .attr('opacity', s.cir.opacity)
        .attr('transform', `translate(${cols_x+((d.width[i] + (space * 2))/2)},${s.col.fSize})`)
        .transition()
        .duration(0)
        .attrTween('d', ()=> {
          let endAngle_ = ( ((360 * (d.value[i]/100)) ) * Math.PI / 180)
          let interpolate = d3.interpolate(360 * Math.PI / 180, endAngle_)
          return function(t) {
            endAn = interpolate(t)
            return arc();
          }
        })

        Table
        .append('path')
        .attr('d', arc)
        .attr('fill', s.cir.gColor)
        .attr('opacity', s.cir.gOpacity)
        .attr('transform', `translate(${cols_x+((d.width[i] + (space * 2))/2)},${s.col.fSize})`)
        .style('stroke-width', 2)
        .transition()
        .duration(0)
        .attrTween('d', ()=> {
          let endAngle_ = ( ((-360 * ((100-d.value[i])/100)) ) * Math.PI / 180)
          let interpolate = d3.interpolate(360 * Math.PI / 180, endAngle_)
          return function(t) {
            endAn = interpolate(t)
            return arc();
          }
        })

        Table
        .append('text')
        .attr('id', `value_${this.localId}_${i}`)
        .attr('x', cols_x + ((d.width[i] + (space * 2))/2))
        .attr('y', s.col.fSize)
        .attr('fill', d.color[i]).attr('font-size', s.value.fSize)
        .attr('font-weight', s.value.fWeight)
        .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle') // hanging
        .attr('font-family', 'roboto')
        .style('cursor', d.link[i] == 'Y' ? 'pointer' : 'default')
        .text(d.value[i]+'%')
        
        cols_x += d.width[i] + (space * 2) + s.cir.padding
      }
    },

  } 
}