import '../../../../src/assets/stylus/ui/component/_jPagination.styl'
import '../../../../src/assets/stylus/ui/component/_jPaginationside.styl'
import { VIcon } from 'vuetify/lib'

export default {
  name: 'j-pagination',
  components: {
    VIcon
  },
  props: {
    sideslide: Boolean,
    showStatus: { type: Boolean, default: true },
    pagination: { type: Object, default: () => null },
  },
  data: () => ({
    totalPages: null
  }),
  computed: {
    styleFirst() {
      if(!this.pagination || !this.pagination.page || this.pagination.page == 1) {
        var data = {
          class:  'disabled'
        }      
      } else {
        data = {
          on: {
            click: () => { this.$emit('first') }
          }
        }
      }
      return data
    },
    stylePrevious() {
      if(!this.pagination || !this.pagination.page || this.pagination.page == 1) {
        var data = {
          class:  'disabled'
        }      
      } else {
        data = {
          on: {
            click: () => { this.$emit('previous', this.pagination.page - 1) }
          }
        }
      }
      return data
    },
    styleNext() {
      if(!this.pagination || !this.pagination.page || this.pagination.page == this.totalPages) {
        var data = {
          class:  'disabled'
        }      
      } else {
        data = {
          on: {
            click: () => { this.$emit('next', Number(this.pagination.page) + 1) }
          }
        }
      }
      return data
    },
    styleLast() {
      if(!this.pagination || !this.pagination.page || this.pagination.page == this.totalPages) {
        var data = {
          class:  'disabled'
        }      
      } else {
        data = {
          on: {
            click: () => { this.$emit('last', this.totalPages) }
          }
        }
      }
      return data
    }
  },
  methods: {
    genStatus() {
      if(!this.showStatus || !this.pagination || Object.keys(this.pagination).length === 0) return null

      let currentPage = !this.pagination.page ? 0 : this.pagination.page
      if(this.pagination.totalRows <= 0) this.totalPages = 0
      else this.totalPages = Math.ceil(this.pagination.totalRows / this.pagination.rowsPerPage)

      if (this.sideslide) {
        return this.$createElement('span', { class: 'j_pagination_status_' }, [
          this.$createElement('span', { class: '_current_' }, [currentPage.toLocaleString()]),
          ' / ',
          this.$createElement('span', { class: '_total' }, [this.totalPages.toLocaleString()])
        ])
      } else {
        return this.$createElement('span', { class: 'j_pagination_status' }, [
          this.$createElement('span', { class: '_current' }, [currentPage.toLocaleString()]),
          ' / ',
          this.$createElement('span', { class: '_total' }, [this.totalPages.toLocaleString()])
        ])
      }
    }
  },
  render() {
    if (this.sideslide) {
      return (
        <div class="j_pagination_side">
          <a class="j_pagination_first" { ...this.styleFirst }><v-icon title="first">mdi-page-first</v-icon></a>
          <a class="j_pagination_privious" { ...this.stylePrevious }><v-icon title="previous">mdi-chevron-left</v-icon></a>
          { this.genStatus() }
          <a class="j_pagination_next" { ...this.styleNext }><v-icon title="next">mdi-chevron-right</v-icon></a>
          <a class="j_pagination_last" { ...this.styleLast }><v-icon title="last">mdi-page-last</v-icon></a>
        </div>
      )
    }else {
      return (
        <div class="j_pagination">
          <a class="j_pagination_first" { ...this.styleFirst }><v-icon title="first">mdi-page-first</v-icon></a>
          <a class="j_pagination_privious" { ...this.stylePrevious }><v-icon title="previous">mdi-chevron-left</v-icon></a>
          { this.genStatus() }
          <a class="j_pagination_next" { ...this.styleNext }><v-icon title="next">mdi-chevron-right</v-icon></a>
          <a class="j_pagination_last" { ...this.styleLast }><v-icon title="last">mdi-page-last</v-icon></a>
        </div>
      )
    }
  }
}
