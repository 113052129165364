import _CONST_ from '../primitives/_constant_'

export default class ConfigService {
    
  constructor() {}

  getEnvironment() {
    // localStorage.removeItem(_CONST_.LOCAL_STORAGE_NAME.ENVIRONMENT)
    let env = localStorage.getItem(_CONST_.LOCAL_STORAGE_NAME.ENVIRONMENT)
    if(!!env) return JSON.parse(env)
    else return null
  }

  setEnvironment(env) {
    localStorage.setItem(_CONST_.LOCAL_STORAGE_NAME.ENVIRONMENT, JSON.stringify(env))
  }
}
