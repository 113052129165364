import * as d3 from 'd3'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs

export default {
  methods: {
    draw_LineCharts() {
      let truncated = this.getDataRequested()
      let lines_ = Object.keys(this.Comp_DataItems[0]).filter(k => k.includes('line'))

      this.lineGroupOpacity = 1
      this.drawPoint_X = this.Canvas.CanvasChartX + (this.Boxes.BoxesBoxSpace + this.Boxes.BoxesBoxWidth)/2
      this.drawPoint_Y = this.Canvas.CanvasChartY + this.Canvas.CanvasChartHeight
      this.kept_drawPoint_X = this.Canvas.CanvasChartX + (this.Boxes.BoxesBoxSpace + this.Boxes.BoxesBoxWidth)/2

      let lineGroup = this.viewBox
      .append('g')
      .attr('class', '_lines_group__')
      .attr('opacity', this.lineGroupOpacity)

      lines_.forEach(k => {
        let lineFunc = d3.line()
        .x(d => this.scaleTimelineInfo.scale(this.parseDate(d.cdate)))
        .y(d => this.scaleAxisYInfo.scale(d[k]))

        lineGroup
        .append('path')
        .attr('d', lineFunc(truncated))
        .attr('stroke', this.Line.LineStyles[0].color)
        .style('stroke-dasharray', this.Line.LineStyles[0].stroke)
        .attr('opacity', 1)
        .style('fill', 'none')
  
        if(this.Line.LineMarkerDisplay == 'Y') {
          let markerType = this.Line.LineMarkerType == 'circle' ? 'circle' : 'rect'
  
          lineGroup
          .append('g')
          .attr('transform', `translate(0,0)`)
          .selectAll(markerType)
          .data(truncated)
          .enter()
          .append(markerType)
          .attr((markerType == 'circle' ? 'cx' : 'x'), d => this.scaleTimelineInfo.scale(this.parseDate(d.cdate)))
          .attr((markerType == 'circle' ? 'cy' : 'y'), d => this.scaleAxisYInfo.scale(d[k]))
          .attr((markerType == 'circle' ? 'r' : 'width'), this.Line.LineMarkerSize)
          .attr((markerType == 'circle' ? null : 'height'), this.Line.LineMarkerSize)
          .attr((markerType == 'circle' ? null :'transform'), `translate(${-this.Line.LineMarkerSize / 2} , ${-this.Line.LineMarkerSize / 2})`)
          .attr('stroke', this.Line.LineStyles[0].color)
          .attr('stroke-width', this.Line.LineMarkerStroke)
          .attr('opacity', 1)
          .attr('fill', '#fff')
        }
       
        if(this.Line.LineValueDisplay == 'Y') { 
          lineGroup
          .append('g')
          .attr('class', this.Line.LineValueStyle)
          .attr('transform', `translate(0,0)`)
          .selectAll('text')
          .data(truncated)
          .enter()
          .append('text')
          .attr('x', d => this.scaleTimelineInfo.scale(this.parseDate(d.cdate)))
          .attr('y', d => this.scaleAxisYInfo.scale(d[k])-this.Line.LineValueSpace)
          .style('font-family', this.Line.LineValueFont)
          .style('font-size', this.Line.LineValueSize)
          .attr('fill', this.Line.LineValueColor)
          .attr('text-anchor', 'middle')
          .attr('alignment-baseline', 'middle')
          .text(d => d[k] + this.Line.LineValueFormat)
        }
      })
    },
  }
}
