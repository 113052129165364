import * as d3 from 'd3'
import { DH_UNABLE_TO_CHECK_GENERATOR } from 'constants'

// ------------------------------------------------------------------------------- Global
// Global Data & Defs


export default {
  methods: {

    loadSvg(path) {
      return d3.xml(path).then(source => {
        if(this.innerSvg) {
          this.innerSvg.html()
          this.innerSvg.remove()
          this.innerSvg = null
        }

        this.innerSvg = source.documentElement
        this.svg.node().append(this.innerSvg)

        this.innerSvg = d3.select(this.innerSvg)
        this.innerSvg.attr('x',0).attr('y',0).attr('width',1200).attr('height',850)
        .attr('opacity', 0)
        .transition()
        .duration(500)
        .attr('opacity', 1)
        // .delay(200)
        
        return new Promise(resolve => {
          resolve('test')
        })
      })
    },

    
    draw_IsoView(mod) {
      console.log('draw_IsoView');
      let self_ = this

      // KeyPlan
      // draw the KeyPlan from svg file
      d3.xml(`${this.__HOST_NAME_RESOURCE}/TCO/BlockDivision/${mod}/svg/${mod}_ISO.svg`).then(source => {
        let svgISO = source.documentElement
        this.svg.node().append(svgISO)
        svgISO = d3.select(svgISO).attr('x',5).attr('y',330)
        
        let maskLayer = svgISO.select(`#MASK`)

        maskLayer.selectAll('g').each((_, i, g) => {

          let setAction_ = (selection) => {
            selection
              .on('mouseover', (_, i, a) => { 
                d3.select(a[i])
                .style('cursor', 'pointer')
                .transition().duration(200).style('opacity', 0.7)
              })
              .on('mouseout', (_, i, a) => { 
                d3.select(a[i])
                .style('cursor', 'default')
                .transition().duration(100).style('opacity', 0)
              })
              .on('click', () => { self_.reforming(BLK) })
          }
  
          let elMask = d3.select(d3.select(g[i]).node().children[1]) // Polygon ,rect, path, circle, ellipse
          let elText = d3.select(g[i]).select('text')
          let BLK = elText.text()
  
          elText.style('opacity', 0)
          elMask.style('opacity', 0).attr('fill','#fff').call(setAction_)
        })

      })
    },
    

    draw_BarChart() {
      // Module No. & Title
      let x = -85
      let y = (i) => -55 + (15*i)
      let progressChart = this.svg
      .append('g')
      .attr('class', 'progress_group')
      .attr('transform', 'translate(220, 160)')
      .selectAll('g')
      .data(this.progSummary)
      .enter()

      progressChart
        .append('text')
        .attr('transform', (draw, i) => `translate(${x + 0}, ${y(i)})`)
        .style('font-family', 'roboto').style('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d => d.TITLE)
      
      progressChart
        .append('rect')
        .attr('x', x + 5).attr('y', (d, i) => y(i))
        .attr('width', 100).attr('height', 10)
        .attr('fill', '#F4F5F5')

      progressChart
        .append('rect')
        .attr('x', x + 5).attr('y', (d, i) => y(i))
        .attr('width', 0).attr('height', 10)
        .attr('fill', d => this.Status.find(color => color.type == d.TYPE).bgColor)
        .transition()
        .duration(500)
        .attr('width', d => (d.ACTUAL/d.TOTAL)*100 )

      progressChart
        .append('text')
        .attr('id', (d, i) => `act_${i}`)
        .attr('transform', (d, i) => `translate(${x + 3}, ${y(i)})`)
        .style('font-family', 'roboto').style('font-size', 10).attr('fill', '#fff').attr('text-anchor', 'end').attr('alignment-baseline', 'hanging')
        .text(d => d.ACTUAL)
        .transition()
        .duration(500)
        .attr('transform', (d, i) => `translate(${x + (d.ACTUAL/d.TOTAL)*100 + 3}, ${y(i)})`)

      progressChart
        .append('text')
        .attr('transform', (d, i) => `translate(${x + 107}, ${y(i)})`)
        .style('font-family', 'roboto').style('font-size', 10).attr('fill', '#757575').attr('text-anchor', 'start').attr('alignment-baseline', 'hanging')
        .text(d => d.TOTAL)
    },

    draw_FormElements() {
      // Legends
      // draw the legends from svg file
      d3.xml(`${this.__HOST_NAME_RESOURCE}/TCO/BlockDivision/forms/Legends.svg`).then(source => {
        let svgLegends = source.documentElement
        this.svg.node().append(svgLegends)
        d3.select(svgLegends).attr('x',1050).attr('y',665)
      })
    },


    draw_BlockCodes() {
      // CODE Definition
      // The code must be in the 'CODE' layer of AI.
      let codeLayer = this.innerSvg.select(`#CODE`)
      let countCode = codeLayer.node().children.length // Get no of Code(text)

      for(var i=0; i < countCode; i++) {
        let codeName = codeLayer.node().children[i].textContent
        let idx = this.dataSet.findIndex(d => d.BLK == codeName)

        if (idx < 0) continue

        // console.log(i, codeName, this.dataSet[idx].BLK)

        this.dataSet[idx].x = this.getMatrixProps(d3.select(codeLayer.node().children[i]), d3.select(codeLayer.node().children[i]).attr('transform')).cx
        this.dataSet[idx].y = this.getMatrixProps(d3.select(codeLayer.node().children[i]), d3.select(codeLayer.node().children[i]).attr('transform')).cy

        d3.select(codeLayer.node().children[i]).style('visibility','hidden')
        // Draw border of code
        // The border of code provides two types Rect and ellipse.
        let data = this.dataSet[idx]
        if (data.shape == 'rect') {
          this.innerSvg
            .append('rect')
            .attr('x', data.x - data.width/2).attr('y', data.y - data.height/2)
            .attr('width', data.width).attr('height', data.height)
            .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bgColor)
        }  
        if (data.shape == 'ellipse') {
          this.innerSvg
            .append('ellipse')
            .attr('cx', data.x).attr('cy', data.y)
            .attr('rx', data.width/2).attr('ry', data.height/2)
            .attr('stroke', data.color).attr('stroke-width', data.stroke).attr('fill', data.bgColor)
        }
        this.innerSvg 
          .append('text')
          .attr('x', data.x).attr('y', data.y + data.padding)
          .style('font-family', 'roboto').attr('fill', data.textColor).style('font-size', data.textSize)
          .attr('text-anchor', 'middle').attr('alignment-baseline', 'middle').text(data.BLK)
      }

      // Mask button Definition
      // The Mask button must be in the 'MASK' layer of AI.
      let self_ = this
      let maskLayer = this.innerSvg.select(`#MASK`)
      
      maskLayer.selectAll('g').each((_, i, g) => {

        let setAction_ = (selection) => {
          selection
            .on('mouseover', (_, i, a) => { 
              d3.select(a[i])
              .style('cursor', 'pointer')
              .transition().duration(200).style('opacity', 0.7)
              
              toolTip
              .transition().duration(200).style('opacity', 0.9);

              _setToolTip__(BLK)
            })
            .on('mouseout', (_, i, a) => { 
              d3.select(a[i])
              .style('cursor', 'default')
              .transition().duration(100).style('opacity', 0)

              toolTip.
              transition().duration(100).style('opacity', 0)
            })
            .on('mousemove', (_, i, a) => {
              let coord = d3.mouse(a[i])
              toolTip.attr('transform', `translate(${coord[0]}, ${coord[1]})`)
            })
            .on('click', (_, i, t) => {
              self_.$emit('request-action', {
                action: {
                  ...this.Action,
                },
                filters: {
                  ...this.FilterValues,
                  BLOCK: BLK
                }
              })
            })
        }

        let elMask = d3.select(d3.select(g[i]).node().children[1]) // Polygon ,rect, path, circle, ellipse
        let elText = d3.select(g[i]).select('text')
        let BLK = elText.text()

        elText.style('opacity', 0)
        elMask.style('opacity', 0).attr('fill','#fff').call(setAction_)
      })

      
      let x = -85
      let y = -55
      let toolTip = this.innerSvg
        .append('g')
        .attr('transform', 'translate(200, 200)')
        .style('pointer-events', 'none')
        
      let _setToolTip__ = (BLK) => {
        let contents = this.dataSet.find(item => item.BLK == BLK)
        if(!contents) return

        toolTip
          .append('path')
          .attr('d', `${this.leftTooltipPath(170,160, 10,15)}`)
          .attr('fill','#F4F5F5')
          .style('stroke-width', 10)
          .style('storke', '#000')
        toolTip
          .append('text')
          .attr('transform', `translate(${x - 10}, ${y + 0})`)
          .style('font-family', 'roboto').style('font-size', 14).attr('fill', '#000000').attr('text-anchor', 'end')
          .text(`${contents.BLK} Block`)
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 0}, ${y + 18})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'end')
          .text('Length:')
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 0}, ${y + 36})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'end')
          .text('Depth:')
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 0}, ${y + 54})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'end')
          .text('Height:')
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 0}, ${y + 72})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'end')
          .text('Weight:')
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 0}, ${y + 100})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'end')
          .text('Erection Plan:')
        toolTip
          .append('text')
          .attr('transform', `translate( ${x + 0}, ${y + 118})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#757575').attr('text-anchor', 'end')
          .text('Erection Actual:')
        
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 5}, ${y + 18})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#F35E90').attr('text-anchor', 'start')
          .text(`${contents.DEP}`)
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 5}, ${y + 36})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#F35E90').attr('text-anchor', 'start')
          .text(`${contents.LEN}`)
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 5}, ${y + 54})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#F35E90').attr('text-anchor', 'start')
          .text(`${contents.HEI}`)
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 5}, ${y + 72})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#F35E90').attr('text-anchor', 'start')
          .text(`${contents.WT}`)
        toolTip
          .append('text')
          .attr('transform', `translate(${x + 5}, ${y + 100})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#44A9DF').attr('text-anchor', 'enstartd')
          .text(`${contents.EREC_PLAN}`)
        toolTip
          .append('text')
          .attr('transform', `translate( ${x + 5}, ${y + 118})`)
          .style('font-family', 'roboto').style('font-size', 11).attr('fill', '#F35E90').attr('text-anchor', 'start')
          .text(`${contents.EREC_ACTUAL}`)
      }
    },
  }
}