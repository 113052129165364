export default {
  data: () => ({
    weekStatus: null,

    rawData:    null,

    area:       null,
    title:      null,
    weekend:    null,
    weekday:    null,
    value:      null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let commons = JSON.parse(JSON.stringify(this.Common))
      let types = JSON.parse(JSON.stringify(this.CTypes))
      
      this.area = commons.area
      this.title = types.title
      this.weekend = types.weekend
      this.weekday = types.weekday
      this.value = types.value
      
      let x_ = 0
      this.rawData.forEach((d,i)=> {

        if(d.WEEK =='LAST' || d.WEEK =='THIS') {
          d.name = 'TOTAL'
          d.width = this.weekend.width
          d.tColor = this.weekend.tColor
        } else {
          d.name = d.WEEK
          d.width = this.weekday.width
          d.tColor = this.weekday.tColor
        }
        d.value = d.VALUE
        d.x = x_
        d.cx = x_ + (d.width/2)

        if(i==0 || i==(this.rawData.length-2)) x_ += (d.width + (this.weekday.gap*2))
        else x_ += d.width + this.weekday.gap
      })

    },

  }
}