<template>
  <div :id='localId'></div>
</template>

<script>
import * as d3 from 'd3'
import __M from 'moment'

import { SafeIdMixin } from '../../mixins/safeid.mixin'
export default {
  name: 'j-svg-bar-progress',
  mixins: [
    SafeIdMixin
  ],
  props: {
    width: Number,
    height: Number,
    radius: Number,
    cutoff: null,
    min: null,
    max: null,
    data: {
      type: Array,
      default: () => ([])
    },
    value: null
  },
  data: () => ({
    widthLocal: 0,
    axis: {
      x: {
        scale: null
      },
      y: {
        scale: null
      },
    }
  }),
  computed: {
  },
  watch: {
    data: {
      handler(val) {
        this.draw()
      },
      deep: true,
    }
  },
  created() {
    this.localId = this.id || 'j-svg-bar-p-f-a-schedule__' + this.safeId('')
  },
  mounted() {
    this.draw()
  },
  methods: {
    clear() {
      if(!d3.select(`#${this.localId}`).empty()) d3.select(`#${this.localId}`).html('')
      this.svg = d3.select(`#${this.localId}`).append('svg')
    },
    draw() {
      this.clear()
      this.setScale()
      this.drawSvg()
      this.drawBar()
    },
    drawBar() {
      this.svg
      .append('g')
      .selectAll()
      .data(this.data)
      .enter()
      .append('rect')
      .attr('transform', (d, i) => `translate(${d.start && (d.end || this.cutoff) ? this.axis.x.scale(d.start) : 0},${this.axis.y.scale(i)})`)
      .attr('width', d => {
        if(d.start && d.end && __M(d.start).format('YYYY-MM-DD') == __M(d.end).format('YYYY-MM-DD')) d.end = __M(d.end).add(1, 'days')
        return d.start && (d.end || this.cutoff) ? this.axis.x.scale((d.end || this.cutoff)) - this.axis.x.scale(d.start) : 0
      })
      .attr('height', 2)
      .attr('fill', d => d.color)

      this.svg
      .append('path')
      .attr('d', `M ${this.axis.x.scale(this.cutoff)},0 V 21`)
      .style('stroke', '#ffc107')
      .style('stroke-width', .5)
    },
    drawSvg() {
      this.svg
      .attr('width', this.widthLocal)
      .attr('height', this.height)
    },

    setScale() {
      this.widthLocal = d3.select(`#${this.localId}`).node().getBoundingClientRect().width

      this.axis.x.scale = d3.scaleTime()
      .domain([this.min, this.max])
      .range([0, this.widthLocal])

      this.axis.y.scale = d3.scaleBand()
      .domain([ ...this.data.map((_, i) => i) ])
      .range([3, this.height])
      .padding(0.03)
    }
  }
}
</script>
