export default {
  name: 'j-radio-group',
  props: {
    id: String,
    label: String,
    name: String,
    options: {
      type: Array,
      default: () => [{ text: 'Radio 1', value: 'r1' }, { text: 'Radio 2', value: 'r2' }]
    },
    // The $prop(s) its type is not defined has the init value though,
    // it will be 'undefined' until the first time new/init value is
    // assigned/processed.
    value: null,
    // default value
    checked: { type: [String, Array, Boolean], default: false }
  },
  data: () => ({
    localId_: null
  }),
  computed: {
    // Radio Groups can only have a single value, so determining if checked is simple
    isChecked(option) {
      if (this.checked != '' && !this.value) {
        this.$emit('input', this.checked)
      }
      return localValue => !!this.value && this.value === localValue
    },
    safeId() {
      // Computed property that returns a dynamic function for creating the ID.
      // Reacts to changes in both .id and .localId_ And regens a new function
      // const id = this.id || this.localId_ || Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
      const id =
        Math.random()
          .toString(36)
          .substring(2, 15) +
        Math.random()
          .toString(36)
          .substring(2, 15)

      // We return a function that accepts an optional suffix string
      // So this computed prop looks and works like a method!
      // But benefits from Vue's Computed prop caching
      const fn = suffix => {
        // if (!id) {
        //   return null
        // }
        suffix = String(suffix || '').replace(/\s+/g, '_')
        return suffix ? id + '_' + suffix : id
      }
      return fn
    }
  },
  methods: {
    handleChange({ target: { checked, value } }) {
      // Change is only emitted on user interaction

      // * 2. Should be input emiting event to interact,
      //      not the change event for v-model
      this.$emit('input', checked ? value : null)
    }
  },
  render() {
    return (
      <div class='flex wrap__option_group'>
        <span class='label'>{this.label}</span>
        <div class='option_group'>
          {this.options.map((option, idx) => {
            const uid = this.safeId(`_JRG_option_${idx}_`)
            return (
              <label
                {...{
                  // Attributes & some other property (e.g., listner, class, attrs, ...)
                  // structure is different to the React. React dosen't have them.
                  attrs: {
                    for: uid
                  }
                }}>
                <input
                  {...{
                    attrs: {
                      id: uid,
                      name: this.name,
                      type: 'radio'
                    },
                    // * 1. Should be nested in domProps, not in arrts to handle
                    //      the result interactively...
                    domProps: {
                      value: option.value,
                      checked: this.isChecked(option.value)
                    },
                    on: {
                      change: this.handleChange
                    }
                  }}
                />
                {option.text}
              </label>
            )
          })}
        </div>
      </div>
    )
  }
}
