
import __C from '../../../../src/primitives/_constant_'

export async function fetchURL (url, opts,token) {
  //console.log('utils fetchURL',url,opts,token)
  opts = opts || {}
  opts.headers = opts.headers || {}
  opts.credentials = 'include'  // 쿠키 포함

  let { headers, ...rest } = opts
  headers['Authorization'] = `Bearer ${token}`
  const res = await fetch(`${__C.HOST_NAME_API}/v0/filebrowser${url}`, {
    headers: {
      ...headers
    },
    ...rest
  })

  return res
}

export async function fetchJSON (url, opts) {
  const res = await fetchURL(url, opts)

  if (res.status === 200) {
    return res.json()
  } else {
    throw new Error(res.status)
  }
}

export function removePrefix (url) {
  if (url.startsWith('/files')) {
    url = url.slice(6)
  }

  if (url === '') url = '/'
  if (url[0] !== '/') url = '/' + url
  return url
}

