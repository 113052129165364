<template>
  <div>
    <v-dialog v-model="opened" :content-class="expanded ? 'j_modal editor editor_width_full' : 'j_modal editor editor_width_sm'" persistent >
      <v-card>
        <v-card-title v-if="!expanded" class="modal__header type_gray">
          <span>
            {{ title }}
            <small>{{ subTitle }}</small>
          </span>
          <j-button class="modal_close second_btn" @click="expanded = !expanded">
            <v-icon>mdi-crop-square</v-icon>
          </j-button>
          <j-button class="modal_close" @click="close">
            <v-icon>mdi-close</v-icon>
          </j-button>
        </v-card-title>
        <div v-else class="modal__header type_gray">
          <span>
            {{ title }}
            <small>{{ subTitle }}</small>
          </span>
          <j-button class="modal_close second_btn full_modal_btn" @click="expanded = !expanded">
            <v-icon>mdi-image-filter-none</v-icon>
          </j-button>
          <j-button class="modal_close" @click="close">
            <v-icon>mdi-close</v-icon>
          </j-button>
        </div>
        <v-card-text>
          <textarea v-model="scripted" name class="type_exp" />
        </v-card-text>
        <v-card-actions>
          <j-button v-if="buttons.validate" class="type01 sky" text @click="onValidate">Validate</j-button>
          <v-spacer></v-spacer>
          <div :class="validClass">
            <pre>{{ message }}</pre>
          </div>
          <v-spacer></v-spacer>
          <j-button v-if="buttons.save" class="type01 sky" text @click="onSave">Save</j-button>
          <j-button class="type01" text @click="close">{{ buttons.save ? 'Cancel' : 'Close' }}</j-button>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :title-description="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :button-text="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>
  </div>
</template>
<script>
export default {
  name: 'j-modal-scripter',
  data: () => ({
    expanded: false,
    // activators --------------------
    msgOpen: false,
    // general -----------------------
    localItem: [],
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
  }),
  props: {
    buttons: { 
      type: Object, 
      default: () => ({
        validate: true,
        save: true
      })
    },
    message: String,
    opened: {
      type: Boolean,
      default: false
    },
    subTitle: String,
    title: String,
    type: String,
    valid: null,
    value: null
  },
  computed: {
    scripted: {
      get() { return this.value },
      set(val) { return this.$emit('input', val) }
    },
    validClass() { return this.valid ? 'positive' : 'negative' }
  },
  watch: {
    expanded: {
      handler(val) {
        if(!val) return
        this.initModalPos(val)
      },
      deep: true
    }
  },
  methods: {
    close() {
      this.onValidate()
      setTimeout(() => {
        if(this.valid||this.scripted==='') { 
          this.$emit('close')
          this.initExpandState()
        }else{ 
          this.yes = () => {
            this.msgOpen = false
            this.yes = () => {}
          }
          this.msgInfo.type = "WARN"
          this.msgInfo.title = "VALIDATION ERROR"
          this.msgInfo.titleDescription = "VALIDATION FAILED"
          this.msgInfo.message = "Please validate your script before closing"
          this.msgInfo.button = [true, false, false]
          this.msgInfo.buttonText[0] = 'OK'
          this.msgOpen = true
        }
      }, 1000)
    },
    initExpandState() {
      this.expanded = false
    },
    initModalPos(val) {
      if(val) {
        setTimeout(() => {
          document.querySelector('.v-dialog.v-dialog--active.editor_width_full').style.position = "initial"
        }, 1)
      } 
    },
    onSave() {
      this.onValidate()
      setTimeout(() => {
        if(this.valid||this.scripted==='') {
          this.$emit('save')
        }else{
          this.yes = () => {
            this.msgOpen = false
            this.yes = () => {}
          }
          this.msgInfo.type = "WARN"
          this.msgInfo.title = "VALIDATION ERROR"
          this.msgInfo.titleDescription = "VALIDATION FAILED"
          this.msgInfo.message = "Please validate your script before saving"
          this.msgInfo.button = [true, false, false]
          this.msgInfo.buttonText[0] = 'OK'
          this.msgOpen = true
        }
      }, 1000)
    },
    onValidate() {
      this.$emit('validate')
    },
  }
}
</script>