<template>
  <div :id="localId"></div>
</template>

<script>
import __C from '../../includes/primitives/_constant_'   // Global Defs
import mx_Core from './includes/mixins/importer'

export default {
  name: "j-chart-floating",
  mixins: [
    mx_Core
  ],
  props: {
    id: String,
    ChartType: String,

    // Chart Data
    // Database: { type: Object, default: () => ({})},
    Canvas:        { type: Object, default: () => ({})},
    FloatBar:      { type: Array, default: () => ([])},
    FloatBarSeries:{ type: Array, default: () => ([])},
    FloatLine:     { type: Array, default: () => ([])},
    FloatBox:      { type: Array, default: () => ([])},
    Text:          { type: Object, default: () => ({})},

    // Database
    DataItems: { type: Array, default: () => ([])},
  },
  created() {
    this.localId = `${this.id || 'j-chart-floating'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    // Main-Process flow *****
    draw() {
      // Check all the necessary values are available
      if(!this.ready2draw) return
      this.clear()

      // Local - running at the local mixin 'DeclaresNDrawingSupport.mixin.mixin' ---
      this.setDefaultValues()
      // // ----------------------------------------------------------------------------

      // // Define the d3 effect at the very 
      // // top of the SVG scope, not middle
      // // of the SVG along with other elements.
      // this.applyColorDefs()

      // Canvas & Chart Area
      this.drawCanvas()                   // Global
      this.drawTexts()

      // Other Elements
      this.drawChart()                    // Local  - bar render

      // Send drawing complete event to the parent, 
      // and the others completing the job.
      this.complete()
    },
  }
}
</script>