<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>
import mx_Core from './includes/mixins/zMixins'        

export default {
  name: "j-chart-data-table-b",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },
  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
  },
  mounted() {
    this.register(this.draw)
  },
  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }
      this.clear()
      this.set_LocalValues()
      this.drawCanvas()
      this.drawNotes()
      this.drawTexts()
      this.drawing()  
      this.complete()
    },
    drawing() {
      this.sCanvasArea = this.svg
      .append('g')
      .attr('id', 'Cansvas_Area')
      .attr('transform', `translate(0.5, 0.5)`)
      .attr('font-family', 'roboto')

      this.draw_Data()
    },
  }
}
</script>