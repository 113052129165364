import * as d3 from 'd3'

export default {
  methods: {

    call_Overall(selection) {
      // let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#MODEL_AREA`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // if(clickTimeout) return
        this.action_Overall_to_Area()
        // clickTimeout = true
      }) 
    },
 

    call_Area(selection, _area) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#${_area}-PNG`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => { 
        if(clickTimeout) return
        
        this.current.area = _area
        this.executeFunction(
          {
            model: this.model_Area,  
            canvas: this.canvas_Area, 
            func: this.draw_Model_Erection,
            data: null
        })
        clickTimeout = true
      })
    },


    call_Erection(selection, _d) {
      let clickTimeout = false
      
      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', .5) // KeyPlan
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', 0) // KeyPlan
      })
      .on('click', () => {
        if(clickTimeout) return

        this.current.block = _d.BLK
        this.executeFunction(
          {
            model: this.model_Erection,  
            canvas: this.canvas_Erection, 
            func: this.draw_Model_Block,
            data: _d
        })
        clickTimeout = true
      })
    },


    call_Erection_Topside(selection, _d) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', .5) // KeyPlan
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
        d3.select(`#${_d.KP}`).transition().duration(50).style('opacity', 0) // KeyPlan
      })
      .on('click', () => {
        if(clickTimeout) return

        this.current.pe = _d.BLK
        this.executeFunction(
          {
            model: this.model_Erection,  
            canvas: this.canvas_Erection, 
            func: this.draw_Model_2PE,
            data: _d
        })
        clickTimeout = true
      })
    },

    
    call_2PE(selection, _d) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', .5)
      })
      .on('mouseout', () => {
        d3.select(`#PNG-${_d.BLK}`).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        // console.log(_d)
        if(clickTimeout) return

        this.current.pe = _d.AREA
        this.executeFunction(
          {
            model: this.model_2PE,  
            canvas: this.canvas_2PE, 
            func: this.draw_Model_Block,
            data: _d
        })
        clickTimeout = true
      })
    },



    // ---------------------------------------------------------------------------------------------------------
    // Home & Back Button
    call_Home(selection, exec) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(clickTimeout) return
        this.executeFunction(exec)
        clickTimeout = true
      }) 
    },

    call_Back(selection, exec) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(clickTimeout) return
        this.executeFunction(exec)
        clickTimeout = true
      }) 
    },

    call_Home2(selection, exec) {
      let clickTimeout = false

      selection
      .on('mouseover', () => {
        selection
        .transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', () => {
        selection
        .transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(clickTimeout) return
        selection.call(exec)
        clickTimeout = true
      }) 
    },


    action_Overall_to_Area() {
      d3.select(`#CANVAS_Overall`).transition().duration(500).style("opacity", 0)
      
      setTimeout(() => { 
        this.canvas_Overall.remove()

        d3.select(`#Overall-MASK`).attr('visibility', 'hidden')

        d3.select(`#Hull`)
        .transition().duration(500).attr('transform', `translate(0,150)`)
        d3.select(`#Topside`)
        .transition().duration(500).attr('transform', `translate(0,-100)`)
        d3.select(`#LQ`)
        .transition().duration(500).attr('transform', `translate(150,-200)`)
      }, 500)

      setTimeout(() => { 
        d3.select(`#Topside-MASK`).attr('visibility', 'display')
        d3.select(`#Hull-MASK`).attr('visibility', 'display')
        d3.select(`#LQ-MASK`).attr('visibility', 'display')
        this.draw_Area_Canvas() // ----->
      }, 1200)
    },


    action_Area_to_Overall() {
      this.canvas_Area.transition().duration(500).style('opacity', 0)

      d3.select(`#Topside-MASK`).attr('visibility', 'hidden')
      d3.select(`#Hull-MASK`).attr('visibility', 'hidden')
      d3.select(`#LQ-MASK`).attr('visibility', 'hidden')

      d3.select(`#Hull`)
      .transition().duration(500).delay(500).attr('transform', `translate(0,0)`)
      d3.select(`#Topside`)
      .transition().duration(500).delay(500).attr('transform', `translate(0,0)`)
      d3.select(`#LQ`)
      .transition().duration(500).delay(500).attr('transform', `translate(0,0)`)

      setTimeout(() => { 

        // Remove the AREA's Dashboard
        this.canvas_Area.remove()
        this.draw_Overall_Canvas() // ----->
        d3.select(`#Overall-MASK`).attr('visibility', 'display').attr('cursor', 'pointer')
      }, 1500)
    },


    // ---------------------------------------------------------------------------------------------------------
    executeFunction(_obj) {

      _obj.model.transition().duration(500).style('opacity', 0)
      _obj.canvas.transition().duration(500).style('opacity', 0)

      setTimeout(() => { 
        // remove Model & Canvas
        _obj.model.remove()
        _obj.canvas.remove()

        // Call Function
        _obj.func(_obj.data)
      }, 1000)
      
    },

  }
}