
export default {
  Canvas: {
    CanvasWidth: 1300,
    CanvasHeight: 900,
    CanvasBorderWeight: 0,
    CanvasBorderColor: '#bcbcbc',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 1300,
    CanvasChartHeight: 900,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },
  
  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {CDATE: '2024-01-01', PLAN: 410, ACTUAL: 200, REM: 100, PRO1: 36, PRO2: 10},
    {CDATE: '2024-01-02', PLAN: 200, ACTUAL: 150, REM:  50, PRO1: 75, PRO2: 20},
    {CDATE: '2024-01-03', PLAN: 150, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 25},
    {CDATE: '2024-01-04', PLAN:  50, ACTUAL: 160, REM:  60, PRO1: 60, PRO2: 30},
    {CDATE: '2024-01-05', PLAN: 390, ACTUAL: 150, REM: 250, PRO1: 50, PRO2: 40},
    {CDATE: '2024-01-06', PLAN: 320, ACTUAL: 399, REM: 245, PRO1: 45, PRO2: 70},
    {CDATE: '2024-01-07', PLAN:  10, ACTUAL: 135, REM: 235, PRO1: 35, PRO2: 72},
    {CDATE: '2024-01-08', PLAN: 215, ACTUAL: 165, REM: 265, PRO1: 65, PRO2: 74},
    {CDATE: '2024-01-09', PLAN: 189, ACTUAL: 260, REM: 160, PRO1: 60, PRO2: 75},
    {CDATE: '2024-01-10', PLAN: 352, ACTUAL: 248, REM: 148, PRO1: 48, PRO2: 76},
    {CDATE: '2024-01-11', PLAN: 300, ACTUAL: 365, REM: 265, PRO1: 65, PRO2: 77},
    {CDATE: '2024-01-12', PLAN: 250, ACTUAL: 298, REM: 198, PRO1: 98, PRO2: 80},
    {CDATE: '2024-01-13', PLAN: 120, ACTUAL: 175, REM: 275, PRO1: 75, PRO2: 85},
    {CDATE: '2024-01-14', PLAN: 250, ACTUAL: 233, REM: 401, PRO1: 33, PRO2: 97},
    {CDATE: '2024-01-15', PLAN: 350, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 100},
    {CDATE: '2024-02-01', PLAN: 410, ACTUAL: 200, REM: 100, PRO1: 36, PRO2: 10},
    {CDATE: '2024-02-02', PLAN: 200, ACTUAL: 150, REM:  50, PRO1: 75, PRO2: 20},
    {CDATE: '2024-02-03', PLAN: 150, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 25},
    {CDATE: '2024-02-04', PLAN:  50, ACTUAL: 160, REM:  60, PRO1: 60, PRO2: 30},
    {CDATE: '2024-02-05', PLAN: 390, ACTUAL: 150, REM: 250, PRO1: 50, PRO2: 40},
    {CDATE: '2024-02-06', PLAN: 320, ACTUAL: 399, REM: 245, PRO1: 45, PRO2: 70},
    {CDATE: '2024-02-07', PLAN:  10, ACTUAL: 135, REM: 235, PRO1: 35, PRO2: 72},
    {CDATE: '2024-02-08', PLAN: 215, ACTUAL: 165, REM: 265, PRO1: 65, PRO2: 74},
    {CDATE: '2024-02-09', PLAN: 189, ACTUAL: 260, REM: 160, PRO1: 60, PRO2: 75},
    {CDATE: '2024-02-10', PLAN: 352, ACTUAL: 248, REM: 148, PRO1: 48, PRO2: 76},
    {CDATE: '2024-02-11', PLAN: 300, ACTUAL: 365, REM: 265, PRO1: 65, PRO2: 77},
    {CDATE: '2024-02-12', PLAN: 250, ACTUAL: 298, REM: 198, PRO1: 98, PRO2: 80},
    {CDATE: '2024-02-13', PLAN: 120, ACTUAL: 175, REM: 275, PRO1: 75, PRO2: 85},
    {CDATE: '2024-02-14', PLAN: 250, ACTUAL: 233, REM: 401, PRO1: 33, PRO2: 97},
    {CDATE: '2024-02-15', PLAN: 350, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 100},
    {CDATE: '2024-03-01', PLAN: 410, ACTUAL: 200, REM: 100, PRO1: 36, PRO2: 10},
    {CDATE: '2024-03-02', PLAN: 200, ACTUAL: 150, REM:  50, PRO1: 75, PRO2: 20},
    {CDATE: '2024-03-03', PLAN: 150, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 25},
    {CDATE: '2024-03-04', PLAN:  50, ACTUAL: 160, REM:  60, PRO1: 60, PRO2: 30},
    {CDATE: '2024-03-05', PLAN: 390, ACTUAL: 150, REM: 250, PRO1: 50, PRO2: 40},
    {CDATE: '2024-03-06', PLAN: 320, ACTUAL: 399, REM: 245, PRO1: 45, PRO2: 70},
    {CDATE: '2024-03-07', PLAN:  10, ACTUAL: 135, REM: 235, PRO1: 35, PRO2: 72},
    {CDATE: '2024-03-08', PLAN: 215, ACTUAL: 165, REM: 265, PRO1: 65, PRO2: 74},
    {CDATE: '2024-03-09', PLAN: 189, ACTUAL: 260, REM: 160, PRO1: 60, PRO2: 75},
    {CDATE: '2024-03-10', PLAN: 352, ACTUAL: 248, REM: 148, PRO1: 48, PRO2: 76},
    {CDATE: '2024-03-11', PLAN: 300, ACTUAL: 365, REM: 265, PRO1: 65, PRO2: 77},
    {CDATE: '2024-03-12', PLAN: 250, ACTUAL: 298, REM: 198, PRO1: 98, PRO2: 80},
    {CDATE: '2024-03-13', PLAN: 120, ACTUAL: 175, REM: 275, PRO1: 75, PRO2: 85},
    {CDATE: '2024-03-14', PLAN: 250, ACTUAL: 233, REM: 401, PRO1: 33, PRO2: 97},
    {CDATE: '2024-03-15', PLAN: 350, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 100},
    {CDATE: '2024-04-01', PLAN: 410, ACTUAL: 200, REM: 100, PRO1: 36, PRO2: 10},
    {CDATE: '2024-04-02', PLAN: 200, ACTUAL: 150, REM:  50, PRO1: 75, PRO2: 20},
    {CDATE: '2024-04-03', PLAN: 150, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 25},
    {CDATE: '2024-04-04', PLAN:  50, ACTUAL: 160, REM:  60, PRO1: 60, PRO2: 30},
    {CDATE: '2024-04-05', PLAN: 390, ACTUAL: 150, REM: 250, PRO1: 50, PRO2: 40},
    {CDATE: '2024-04-06', PLAN: 320, ACTUAL: 399, REM: 245, PRO1: 45, PRO2: 70},
    {CDATE: '2024-04-07', PLAN:  10, ACTUAL: 135, REM: 235, PRO1: 35, PRO2: 72},
    {CDATE: '2024-04-08', PLAN: 215, ACTUAL: 165, REM: 265, PRO1: 65, PRO2: 74},
    {CDATE: '2024-04-09', PLAN: 189, ACTUAL: 260, REM: 160, PRO1: 60, PRO2: 75},
    {CDATE: '2024-04-10', PLAN: 352, ACTUAL: 248, REM: 148, PRO1: 48, PRO2: 76},
    {CDATE: '2024-04-11', PLAN: 300, ACTUAL: 365, REM: 265, PRO1: 65, PRO2: 77},
    {CDATE: '2024-04-12', PLAN: 250, ACTUAL: 298, REM: 198, PRO1: 98, PRO2: 80},
    {CDATE: '2024-04-13', PLAN: 120, ACTUAL: 175, REM: 275, PRO1: 75, PRO2: 85},
    {CDATE: '2024-04-14', PLAN: 250, ACTUAL: 233, REM: 401, PRO1: 33, PRO2: 97},
    {CDATE: '2024-04-15', PLAN: 350, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 100},
    {CDATE: '2024-05-01', PLAN: 410, ACTUAL: 200, REM: 100, PRO1: 36, PRO2: 10},
    {CDATE: '2024-05-02', PLAN: 200, ACTUAL: 150, REM:  50, PRO1: 75, PRO2: 20},
    {CDATE: '2024-05-03', PLAN: 150, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 25},
    {CDATE: '2024-05-04', PLAN:  50, ACTUAL: 160, REM:  60, PRO1: 60, PRO2: 30},
    {CDATE: '2024-05-05', PLAN: 390, ACTUAL: 150, REM: 250, PRO1: 50, PRO2: 40},
    {CDATE: '2024-05-06', PLAN: 320, ACTUAL: 399, REM: 245, PRO1: 45, PRO2: 70},
    {CDATE: '2024-05-07', PLAN:  10, ACTUAL: 135, REM: 235, PRO1: 35, PRO2: 72},
    {CDATE: '2024-05-08', PLAN: 215, ACTUAL: 165, REM: 265, PRO1: 65, PRO2: 74},
    {CDATE: '2024-05-09', PLAN: 189, ACTUAL: 260, REM: 160, PRO1: 60, PRO2: 75},
    {CDATE: '2024-05-10', PLAN: 352, ACTUAL: 248, REM: 148, PRO1: 48, PRO2: 76},
    {CDATE: '2024-05-11', PLAN: 300, ACTUAL: 365, REM: 265, PRO1: 65, PRO2: 77},
    {CDATE: '2024-05-12', PLAN: 250, ACTUAL: 298, REM: 198, PRO1: 98, PRO2: 80},
    {CDATE: '2024-05-13', PLAN: 120, ACTUAL: 175, REM: 275, PRO1: 75, PRO2: 85},
    {CDATE: '2024-05-14', PLAN: 250, ACTUAL: 233, REM: 401, PRO1: 33, PRO2: 97},
    {CDATE: '2024-05-15', PLAN: 350, ACTUAL: 125, REM: 225, PRO1: 25, PRO2: 100},
  ],

  Queries: {

    SQL1: [ 
      {
        CUTOFF:                 '2023-01-08', // <---

        CANVAS_WIDTH:           800,
        CANVAS_HEIGHT:          500,
        CANVAS_STROKE:          '#E0E0DF',
        CANVAS_STROKE_WIDTH:    0.5,

        TITLE_MAIN_Y:           30,
        TITLE_MAIN_TITLE:       'S3-Curve for System Completion',
        TITLE_MAIN_COLOR:       '#44A9DF',
        TITLE_MAIN_SIZE:        14,

        TITLE_SUB_Y:            50,
        TITLE_SUB_TITLE:        'Progress(%)',
        TITLE_SUB_COLOR:        'gray',
        TITLE_SUB_SIZE:         10,
        
        PADDING_TOP:            120,
        PADDING_RIGHT:          70,
        PADDING_BOTTOM:         100,
        PADDING_LEFT:           70,
      }
    ],

    // Axis Styles
    SQL2: [ 
      {
        AXIS:                   'X                    / PRIMARY        / SECONDARY   ', // Do not Change
        VISIBLE:                'Y                    / Y              / Y           ',
        TITLE:                  'Schedule (Cut-off)   / Quantitty(Qty) / Progress(%) ',
        TITLE_GAP:              '70                   / 45             / 45          ',
        TITLE_SIZE:             '12                   / 12             / 12          ',
        TITLE_COLOR:            '#333                 / #333           / #333        ',
        TEXT_SIZE:              '9                    / 9              / 9           ',
        TEXT_COLOR:             '#757575              / #757575        / #757575     ',
        TEXT_UNIT:              '                     / K              / %           ',
        ALIGN:                  'end                  / end            / start       ',
        ROTATE:                 '-45                  / 0              / 0           ',
        STROKE:                 '#bcbcbc              / #bcbcbc        / #bcbcbc     ', 
        STROKE_WIDTH:           '.5                   / .5             / .5          ',
     
        GRID_VISIBLE:           'Y', 
        GRID_STROKE:            '#bcbcbc', 
        GRID_STROKE_WIDTH:      .5,
        GRID_OPACITY:           .2,
      }
    ],

    // Styles for Chart
    SQL3: [ 
      {
        BAR_GAP_SIZE:           2,
        BAR_LAYERED:            'N', // <---(Y/N)

        AXIS:               'PRIMARY  / PRIMARY  / PRIMARY   / SECONDARY   / SECONDARY   / SECONDARY  ',
        CODE:               'PLAN     / ACTUAL   / REM       / PRO1        / PRO2        / PRO3       ',
        NAME:               'Plan     / Actual   / Remain    / Progress 1  / Progress 2  / Progress 3 ',
        TYPE:               'BAR      / BAR      / BAR       / LINE        / LINE        / LINE       ',
        VISIBLE:            'Y        / Y        /            / Y           / Y           /            ',
        CUTOFF:             '         / Y        / Y          /             / Y           /            ',
        NODE_RADIUS:        '         /          /           / 3           / 3           / 3          ',
        STROKE_COLOR:       '#757575  / #757575  / #757575   / #CECECE     / #F7BACF     / #F7BACF    ',
        STROKE_WIDTH:       '0        / 0        / 0         / 1           / 1           / 2          ',
        DASH:               '         /          /           / 3           / 0           / 0          ',
        COLOR:              '#CECECE  / #83D2F5  / #FCE3EB   / #ffffff     / #ffffff     / #ffffff    ',
        OPACITY:            '.3       / .9       / .7        / .9          / .9          / .9         ',
        VALUE_VISIBLE:      'Y        / N        / Y         / Y           / Y           / N          ',
        VALUE_SIZE:         '8        / 8        / 8         / 8           / 8           / 8          ',
        VALUE_COLOR:        '#bcbcbc  / #83D2F5  / #757575   / #757575     / #F7BACF     / #757575    ',
        ANIMATION:          '750      / 750      / 750       / 750         / 750         / 750        ',

        LEGENDS_VISIBLE:        'Y',
        LEGENDS_Y:              90,
        LEGEND_ALIGN:           'middle',
        LEGEND_TEXT_SIZE:       9,
        LEGEND_TEXT_COLOR:      '#757575',
      },
    ],

  }
}