// -------------------------------------------------------------------------------------- Global
import { SafeIdMixin }        from '../../../../../../../includes/mixins/safeid.mixin'                // ATAGlance Framework Globals
// Global Drawing Function
import gmx_draw_BoxedSummary  from '../../../../../../../includes/mixins/draw_BoxedSummary.mixin'
// Global Event-Call Handlers
import gmx_event_callHandlers from '../../../../../../../includes/mixins/event_callHandlers.mixin'

export default {
  mixins: [
    SafeIdMixin,
    gmx_draw_BoxedSummary,
    gmx_event_callHandlers
  ],
}
