import * as d3 from 'd3'

export default {
  data: () => ({
    selectedSearchOption: '',
  }),
  methods: {
    drawGroupBox(selection, d) {
      let box__ = selection
      .append('g')
      .attr('transform', `translate(${d.x}, ${d.y})`)

      box__
      .append('rect')
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', d.width)
      .attr('height', d.height)
      .attr('fill', '#fff')
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      box__
      .append('rect')
      .attr('transform', `translate(10, -5)`)
      .attr('width', d.labelWidth)
      .attr('height', 10)
      .attr('fill', '#fff')

      box__
      .append('text')
      .attr('transform', `translate(20, 1)`)
      .style('font-size', 9)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text(d.label)

      return box__
    },
    drawSearchTool(selection, __data) {
      d3.select(`#${this.localId}`).select('search_tool').remove()

      let box__ = selection
      .append('g')
      .attr('transform', `translate(${__data.x}, ${__data.y})`)
      .attr('id', 'search_tool')

      box__
      .append('rect')
      .attr('rx', 3)
      .attr('ry', 3)
      .attr('width', 250)
      .attr('height', 75)
      .attr('fill', '#fff')
      // .attr('fill-opacity', 0)
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)

      box__
      .append('rect')
      .attr('transform', `translate(10, -5)`)
      .attr('width', 55)
      .attr('height', 10)
      .attr('fill', '#fff')

      box__
      .append('text')
      .attr('transform', `translate(20, 1)`)
      .style('font-size', 9)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .text('Search')

      // input box border -----------
      box__
      .append('rect')
      .attr('transform', `translate(15, 36)`)
      .attr('width', 219)
      .attr('height', 22)
      .attr('fill', '#fff')
      .attr('stroke', '#dadada')
      .attr('stroke-width', .5)
      // ----------------------------

      let searchOptions = box__
      .append('g')
      .attr('id', '__search_tool_search_options')
      .attr('transform', `translate(20, 20)`)

      // ### draw search options (filtering target) in the search group box
      this.selectedSearchOption = __data.selected
      
      __data.optionValues.forEach((v_, i) => {
        searchOptions
        .append('circle')
        .attr('class', `search_circle__${i}`)
        .attr('cx', v_.x)
        .attr('cy', 0)
        .attr('r', 3)
        .style('stroke', v_.name == __data.selected ? __data.style.searchOptions.selected.stroke : __data.style.searchOptions.out.stroke)
        .style('stroke-width', .5)
        .style('fill', v_.name == __data.selected ? __data.style.searchOptions.selected.fill : __data.style.searchOptions.out.fill)

        searchOptions
        .append('text')
        .attr('class', `search_text__${i}`)
        .attr('transform', `translate(${v_.x + 7}, 1)`)
        .style('font-size', 9)
        .style('fill', v_.name == __data.selected ? __data.style.searchOptions.selected.color : __data.style.searchOptions.out.color)
        .attr('text-anchor', 'start')
        .attr('alignment-baseline', 'middle')
        .text(v_.text)

        searchOptions
        .datum({ ...v_, optionValues: __data.optionValues})
        .append('rect')
        .attr('id', `search_mask__${i}`)
        .attr('transform', `translate(${v_.x - 5}, -8)`)
        .attr('width', v_.textWidth)
        .attr('height', 16)
        .attr('fill', '#000')
        .attr('fill-opacity', 0)
        .style('cursor', 'pointer')
        .call(__data.callFunc.searchOption)
      })

      // ### draw search input in the search group box
      box__
      .append("foreignObject")
      .attr('x', 15)
      .attr('y', 36)
      .attr('width', 180)
      .attr('height', 22)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'search_input')     // for the javascript document.getElementById
      .attr('class', 'search_input')  // for the d3 general purpose
      .attr('contentEditable', true)
      // .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 14px; text-align: left; color: ${this.skyline.legend.item.tColor}`)
      .attr('style', `padding: 2px 5px; font-size: 10px; line-height: 18px; text-align: left;`)
      .text(__data.input || '')
      .call(__data.callFunc.searchInput)

      box__
      .append('text')
      .attr('class', 'delete_search_text')
      .attr('transform', `translate(203, 48)`)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .attr('font-family', 'roboto')
      .style('font-size', 11)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      .text('x')
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { 
        d3.select(`#${this.localId}`).select('.search_input').text('')
        __data.callFunc.search()
      })

      box__
      .append('image') 
      .attr('transform', `translate(215, 41)`)
      .attr('xlink:href', require('../../src/assets/svg/icons/iconmonstr-magnifier-2.svg'))
      .attr('width', 12)
      .attr('height', 12)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', __data.callFunc.search)
    },
    drawSearchToolFree(selection, _data) {
      let box_ = this.drawGroupBox(selection, {
        x         : _data.x,
        y         : _data.y,
        width     : _data.width,
        height    : _data.height,
        label     : _data.label,
        labelWidth: _data.labelWidth,
      })

      let margin = 15
      let inputHeight = 22
      let targetNames = _data.targets.map(t => t.text)

      // input box border -----------
      box_
      .append('rect')
      .attr('transform', `translate(${margin}.3, ${margin}.3)`)
      .attr('width', _data.width - margin*2)
      .attr('height', inputHeight)
      .attr('fill', '#fff')
      .attr('stroke', '#dadada')
      .attr('stroke-width', .3)
      // ----------------------------

      // ### draw search input in the search group box
      box_
      .append("foreignObject")
      .attr('x', margin)
      .attr('y', margin)
      .attr('width', _data.width - margin*2)
      .attr('height', inputHeight)
      .append('xhtml:div')
      .append('div')
      .attr('id', 'search_input')     // for the javascript document.getElementById
      .attr('class', 'search_input')  // for the d3 general purpose
      .attr('contentEditable', true)
      .attr('style', `padding: 2px 48px 2px 5px; font-size: 10px; line-height: 18px; text-align: left; white-space: nowrap;`)
      .on('keydown', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))     
          setTimeout(() => { this.setCaret(document.getElementById('search_input')) })
        }

        this.register(() => { 
          let text = d3.select(a[i]).text()
          if(text.length > 25) d3.select(a[i]).text(text.substring(0, 24))
          _data.callFunc(text) 
        }, 500) 
      })
      .on('keyup', (_, i, a) => {
        if(d3.event.keyCode === 13){
          this.trimDivText(d3.select(a[i]))
          this.setCaret(document.getElementById('search_input'))
        }
      })

      box_
      .append('text')
      .attr('class', 'delete_search_text')
      .attr('transform', `translate(${_data.width-42}, ${margin+11})`)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .style('font-size', 11)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      .text('x')
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      .on('click', () => { 
        d3.select(`#${this.localId}`).select('.search_input').text('')
        _data.callFunc('')
      })

      box_
      .append('image') 
      .attr('transform', `translate(${_data.width-32}, ${margin+5})`)
      .attr('xlink:href', require('../../src/assets/svg/icons/iconmonstr-magnifier-2.svg'))
      .attr('width', 12)
      .attr('height', 12)
      .style('cursor', 'pointer')
      .style('opacity', .3)
      .on('mouseover', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .7) })
      .on('mouseout', (_, i, a) => { d3.select(a[i]).transition().duration(100).style('opacity', .3) })
      // .on('click', (_, i, a) => { _data.callFunc(d3.select(a[i]).text()) })
      
      box_
      .append('text')
      .attr('transform', `translate(${margin}, ${margin+inputHeight+10})`)
      .attr('text-anchor', 'start')
      .attr('alignment-baseline', 'middle')
      .style('font-family', 'roboto')
      .style('font-size', 9)
      .style('fill', '#757575')
      .text(`(*)Target: ${targetNames.join(', ')}`)
    },

    /**
     * Input & Search functions ------------------------------------ S
     */ 
    trimDivText(selection) {
      let text = selection.html().replace(/<div(.*?)\/div>/g, '')
      selection.html(text)
    },
    setCaret(el) {
      let range = document.createRange(),
          sel = window.getSelection(),
          lastKnownIndex = -1;
      for (let i = 0; i < el.childNodes.length; i++) {
        if (this.isTextNodeAndContentNoEmpty(el.childNodes[i])) {
          lastKnownIndex = i;
        }
      }
      if (lastKnownIndex === -1) {
        // throw new Error('Could not find valid text content');
        return
      }
      let row = el.childNodes[lastKnownIndex],
          col = row.textContent.length;
      range.setStart(row, col);
      range.collapse(true);
      sel.removeAllRanges();
      sel.addRange(range);
      el.focus();
    },
    isTextNodeAndContentNoEmpty(node) {
      return node.nodeType == Node.TEXT_NODE && node.textContent.trim().length > 0
    },
    /**
     * Input & Search functions ------------------------------------ E
     */ 
   }
}
