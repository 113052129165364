<template>
  <div :id="localId" class="j_editor">
    <div v-if="uploadProgress > 0" class="progress_bar">
      <div class="progress" :style="uploadProgress ? `width: ${uploadProgress}%;` : ''"></div>
      <span class="uploaded">{{ uploadProgress }}%</span>
    </div>
    <span v-else-if="!error" class="upload_notice">{{ uploadNotice }}</span>
    <span v-else class="upload_error">{{ error }}</span>
    <ckeditor v-model="editorData" :config="editorConfig" :editor="editor" @ready="onEditorReady"></ckeditor>
    <div class="button_action_wrapper">
      <a v-if="buttons[0]" v-ripple class="editor-btn-attachment" title="attach" @click="onOpenFile">
        <i class="mdi mdi-attachment"></i>
      </a>
      <a v-if="buttons[1]" v-ripple class="editor-btn-save" title="save" @click="onSave">
        <i class="mdi mdi-content-save-outline"></i>
      </a>
      <a v-if="buttons[2]" v-ripple class="editor-btn-cancel" title="cancel" @click="onCancel">
        <i class="mdi mdi-window-close"></i>
      </a>
    </div>

    <div v-if="fileDocs.length > 0" class="doc_container">
      <span class="icon_attachment"><i class="mdi mdi-attachment"></i></span>
      <div v-for="file in fileDocs" :key="file.name" class="doc">
        <span class="doc_name">{{ file.name }}</span>
        <a v-ripple class="editor-btn-remove-doc" @click="onRemoveFile(file.name)">
          <i class="mdi mdi-close"></i>
        </a>
      </div>
    </div>

    <div v-if="fileImages.length > 0" class="image_media_container">
      <div v-for="file in fileImages" :key="file.name" class="image">
        <img :src="file.dataUrl" :title="file.name" />
        <a v-ripple class="editor-btn-remove-image" @click="onRemoveFile(file.name)">
          <i class="mdi mdi-close-circle"></i>
        </a>
      </div>
    </div>
    
    <input 
      type="file" 
      multiple
      id="files" 
      name="files" 
      style="display:none;"
      
      :accept="accept"
      @change="onHandleFiles"
    >

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </div>
</template>

<script>
import '@/assets/stylus/ui/component/_jEditor.styl'

import CKEditor from '@ckeditor/ckeditor5-vue'
import ClassicEditor from 'jgseditor-build-classic'

import __C from '@/primitives/_constant_'
import { SafeIdMixin } from '@/mixins/safeid.mixin'

export default {
  name: "j-editor",
  mixins: [
    SafeIdMixin
  ],
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    // for editing the comment ---
    comment: {
      type: Object,
      default: () => ({})
    },
    // ---------------------------

    accept: {
      type: String,
      default: () => 'video/*, image/*, .doc, .docx, .xls, .xlsx, .pdf, .csv, .txt, .zip'
    },
    allowNoComment: {
      type: Boolean,
      default: () => false
    },
    buttons: {
      type: Array,
      default: () => [false, true, true]
    },
    files: {
      type: Array,
      default: () => null
    },
    limitCount: {
      type: Number,
      default: () => 5
    },
    noticeExt: {
      type: String,
      default: () => ''
    },
    progress: {
      type: Object,
      default: () => ({
        complete: 0,
        total: 0
      })
    },
    thumbSize: {
      type: Number,
      default: () => 87
    },
    value: null,
  },
  data: () => ({
    editor: ClassicEditor,
    editorData: '',
    editorConfig: {
      allowedContent: 'p h1 h2 h3 strong em ul ol li[*]{*}(*)',
      disallowedContent: 'script; *[on*]; *[src]; *[href]; iframe; object; embed; form',
      toolbar: [ 
        'heading',
        '|',
        'bold','italic',
        //'link',
        'bulletedList','numberedList',
        '|',
        'outdent','indent',
        '|',
        'blockQuote',
        '|',
        //'imageUpload',
        'insertTable',
        //'mediaEmbed',
        '|',
        'undo','redo',
        '|',
        'fontSize','FontFamily','FontColor','fontBackgroundColor',
      ],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h2', title: 'Heading 1', class: 'ck-heading_heading1'},
          { model: 'heading2', view: 'h3', title: 'Heading 2', class: 'ck-heading_heading2'},
          { model: 'heading3', view: 'h4', title: 'Heading 3', class: 'ck-heading_heading3'},
          { model: 'heading4', view: 'h5', title: 'Heading 4', class: 'ck-heading_heading4'},
          { model: 'heading5', view: 'h6', title: 'Heading 5', class: 'ck-heading_heading5'},
          { model: 'heading6', view: 'h7', title: 'Heading 6', class: 'ck-heading_heading6'}
        ]
      },
      fontFamily: {
        options: [
        'default',
        'Arial, Helvetica, sans-serif',
        'Courier New, Courier, monospace',
        'Georgia, serif',
        'Lucida Sans Unicode, Lucida Grande, sans-serif',
        'Tahoma, Geneva, sans-serif',
        'Times New Roman, Times, serif',
        'Trebuchet MS, Helvetica, sans-serif',
        'Verdana, Geneva, sans-serif'
        ],
      },

      fontSize : {
        options: [ 6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,28,30,34,38],
      },
      image: {
        // resizeUnit: "%",
        // resizeOptions : [
        //   {
        //   name: 'imageResize:original',
        //   value: null,
        //   icon: 'original'
        //   },
        //   {
        //   name: 'imageResize:25',
        //   value: '25',
        //   icon: 'small'
        //   },
        //   {
        //   name: 'imageResize:50',
        //   value: '50',
        //   icon: 'medium'
        //   },
        //   {
        //   name: 'imageResize:75',
        //   value: '75',
        //   icon: 'large'
        //   }
        // ],
        toolbar: [ 
          'imageStyle:full','imageStyle:side',
          '|',
          'imageTextAlternative',
          // '|',
          // 'imageResize:25', 'imageResize:50', 'imageResize:75', 'imageResize:original',
        ],
        // upload: {
        //   panel: {
        //     items: ['insertImageViaUrl'],
        //   },
        // },
      },
      htmlSupport: {
        allow: [
          {
            name: /.*/,
            attributes: true,
            classes: true,
            styles: true
          }
        ],
        disallow: [
          {
            name: /^(script|iframe|object|embed|applet)$/i,
            attributes: true,
            classes: true,
            styles: true
          }
        ]
      },
    },

    error: '',
    localFiles: [],

    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},
  }),
  computed: {
    
    __C_() { return __C },

    modeEdit() { return this.comment && [__C.FORM.EDIT_MODE_MOD, __C.FORM.EDIT_MODE_EDIT].includes(this.comment.mode) },
    fileDocs() {
      if(this.localFiles.length_ === 0) return []
      return this.localFiles.filter(f_ => !['image', 'video'].includes(f_.type.split('/')[0]))
    },
    fileImages() {
      if(this.localFiles.length_ === 0) return []
      return this.localFiles.filter(f_ => ['image', 'video'].includes(f_.type.split('/')[0]))
    },
    uploadNotice() {
      if (this.buttons[0]) return `Limited at ${this.limitCount} files${this.noticeExt ? ' (' + this.noticeExt + ')' : ''}, 1GB.`
      else return ''
    },
    uploadProgress() {
      return (
        !this.progress || 
        !this.progress.complete || 
        !this.progress.total ? 
        0 : 
        Math.floor((this.progress.complete * 100) / this.progress.total)
      )
    }
  },
  watch: {
    comment: {
      handler(val) {
        this.setComment()
      },
      deep: true
    }
  },
  created() {
    this.localId = this.id || 'j-editor__' + this.safeId('')
  },
  mounted() {
    this.setComment()
    //console.log(this.editor.builtinPlugins.map( plugin => plugin.pluginName));
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onEditorReady(e) {
      // console.log(e)
    },
    onHandleFiles(e) {
      if(!e || !e.target) return
      this.appendFiles(Object.values(e.target.files).filter(f_ => !!f_))
    },
    onOpenFile() {
      document.querySelector(`#${this.localId} input[name='files']`).click()
    },
    onRemoveFile(fileName) {
      let index_ = null

      this.yes = () => {
        this.msgOpen = false
        this.yes = () => {}

        this.$emit('remove', this.localFiles[index_].idx)

        // TO DO: Send event, if the file is aleady stored

        this.localFiles[index_] = null
        this.localFiles = this.localFiles.filter(f_ => !!f_)
      }

      index_ = this.localFiles.findIndex(f_ => f_.name == fileName)
      if(!this.localFiles[index_].idx) {
        this.localFiles[index_] = null
        this.localFiles = this.localFiles.filter(f_ => !!f_)
      } else {
        this.msgInfo.type = "WARN"
        this.msgInfo.title = "Delete File"
        this.msgInfo.message = "Do you want to delete this file?"
        this.msgOpen = true
      }
    },
    onSave() {
      if(!this.editorData && !this.allowNoComment) {
        this.error = `No comment to save.`
        setTimeout(() => { this.error = '' }, 3000)
        
      } else {
        this.$emit('save', {
          comment: this.editorData,
          files: this.localFiles,
        })
      }
    },

    async appendFiles(_files) {
      let maxFiles = this.limitCount
      let maxSize = 1000 * 1024000
      let currentFiles = this.localFiles.length + _files.length
      let currentSize = (
        this.localFiles.map(f => f.size).reduce((a, b) => a + b, 0) +
        _files.map(f => f.size).reduce((a, b) => a + b, 0)
      )

      if(currentFiles > maxFiles || currentSize > maxSize) {
        this.error = `Attachements are limited at ${this.limitCount} files, 1GB.`
        setTimeout(() => { this.error = '' }, 5000)
        return
      }

      let length_ = _files.length

      for(let i=0; i<length_; i++) {
        let index_ = this.localFiles.findIndex(f___ => f___.name == _files[i].name)

        // if(index_ >= 0) this.localFiles[index_] = await this.getFileInfo(_files[i])
        if(index_ >= 0) {
          this.error = `Same file name "${this.localFiles[index_].name}", it's not updated.`
          setTimeout(() => { this.error = '' }, 5000)
          
        } else this.localFiles.push(await this.getFileInfo(_files[i]))
      }
    },
    getFileInfo(_file) {
      let type_ = _file.type.split('/')

      _file.mimeType = _file.type
      _file.fileType = type_[0]

      return new Promise(resolve => {
        switch(_file.fileType) {
          case 'image': this.getImageInfo(_file).then(f_ => { resolve(f_) }); break;
          case 'video': this.getMediaInfo(_file).then(f_ => { resolve(f_) }); break;
          default: resolve(this.getDocInfo(_file))
        }
      })
    },
    getImageInfo(_file) {
      if(_file.type == 'image/svg+xml') {
        // let img = document.querySelector('.dz-image img')
        // _file.width = parseInt(img.naturalWidth)
        // _file.height = parseInt(img.naturalHeight)
        _file.ext = 'svg'
      } else {
        _file.ext = _file.type.split('/')[1]
      }

      let image_ = document.createElement('img')
      let objectURL_ = URL.createObjectURL(_file)

      return new Promise(resolve => {
        image_.onload = () => {
          _file.width = image_.width
          _file.height = image_.height
          _file.dimention = `${image_.width},${image_.height}`
          _file.dataUrl = this.getThumbBlob(image_, _file)

          resolve(_file)
        }
        image_.src = objectURL_
      })
    },
    getMediaInfo(_file) {
      _file.ext = _file.type.split('/')[1]
      if (!['mov', 'mpeg', 'mp4', 'wmv'].includes(_file.ext)) return new Promise(resolve => {
        resolve(_file)
        return
      })

      let initScreenshot__ = () => {
        // video_.setAttribute('currentTime', 5)   // not working
        video_.currentTime = 5

        _file.width = video_.videoWidth
        _file.height = video_.videoHeight
        _file.dimention = `${video_.videoWidth},${video_.videoHeight}`
      }
      let video_ = document.createElement('video')
      let objectURL_ = URL.createObjectURL(_file)

      return new Promise(resolve => {
        video_.setAttribute('src', objectURL_)
        video_.addEventListener('loadedmetadata', () => { initScreenshot__() })
        video_.addEventListener('canplay', () => {
          _file.dataUrl = this.getThumbBlob(video_, _file)
          resolve(_file)
        })

        document.body.appendChild(video_)
        video_.play()
      })
    },
    getDocInfo(_file) {
      let fileNameParts = _file.name.split('.')
      _file.ext = fileNameParts[fileNameParts.length-1]
      return _file
    },
    getArchiveInfo(_file) {
      return _file
    },
    getThumbBlob(target, _file) {
      let defaultSize_ = this.thumbSize

      if(_file.width <= defaultSize_ && _file.height <= defaultSize_) {
        var w_ = _file.width
        var h_ = _file.height

      } else if(_file.width >= _file.height) {
        var ratio_ = (defaultSize_ / _file.width)
        w_ = defaultSize_
        h_ = Math.round(_file.height * ratio_)
        
      } else {
        ratio_ = (defaultSize_ / _file.height)
        w_ = Math.round(_file.width * ratio_)
        h_ = defaultSize_
      }

      let canvas_ = document.createElement('canvas')
      let ctx_ = canvas_.getContext('2d')

      canvas_.width = w_
      canvas_.height = h_

      ctx_.drawImage(target, 0, 0, canvas_.width, canvas_.height)
      
      return canvas_.toDataURL()
    },
    setComment() {
      if(this.comment && [__C.FORM.EDIT_MODE_MOD, __C.FORM.EDIT_MODE_EDIT].includes(this.comment.mode)) {
        if (this.buttons[0]) {
          let files_ = JSON.parse(JSON.stringify(this.comment.files))
          files_.forEach(f_ => { f_.type = f_.mimeType })

          this.localFiles = files_
        }
        
        setTimeout(() => { this.editorData = this.comment.comment })
      }
    }
  }
}
</script>
<style>
/* .ck-content { 
  height:500px; 
} */
.ck-editor__editable {
  max-height: 500px;
}
</style>