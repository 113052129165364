import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    progress:           null,
    block_List:         [],
    block_Status:       [],
    keyPlan:            [],

    // Selections
    model_Overall:      null,
    model_Area:         null,
    model_Erection:     null,
    model_2PE:          null,
    model_Block:        null,

    canvas_Form:        null,
    canvas_Overall:     null,
    canvas_Area:        null,
    canvas_Erection:    null,
    canvas_2PE:         null,

    current: {
      area:   null,
      pe:     null,
      block:  null,
    },

    // PDF
    selectedItems :     null,
    getItems      :     null,
  }),


  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){

      this.progress = JSON.parse(JSON.stringify(this.DataItems[0]))
      this.block_Status = JSON.parse(JSON.stringify(this.Queries.SQL1))
      this.block_List = this.localData.JSON1

      this.keyPlan = [ ...new Set(this.block_List.map(d => d.KP)) ]
    },


    loadSvg(selection, data) {
      return d3.xml(`${this.HOST}${data.url}`).then(source => {
 
        let svg_ = source.documentElement
        selection.node().append(svg_)

        svg_ = d3.select(svg_)
        .attr('x', data.x)
        .attr('y', data.y)

        return new Promise(resolve => {
          resolve(svg_)
        })
      })
    },


    set_Gradients() {

      // set Gradient color for the Timeline
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'Pink', [.5, .8, .5, -1], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'LightBlue', [.5, 1, .7, -.1], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'Yellow', [.5, .8, .5, 0], this.localId)
      Defs.setDefs_Gradients(this.svg, 'H', 'LinearA4', 'YellowGreen', [.5, .8, .5, 0], this.localId)

      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Pink', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'LightBlue', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'Yellow', [.8, .5, .0, .5], this.localId)
      Defs.setDefs_Gradients(this.svg, 'V', 'LinearA4', 'YellowGreen', [.8, .5, .0, .5], this.localId)
    },

  }
}