export default {
  
  methods: {

    // Area Stage
    draw_Area_Canvas() {
      
      this.canvas_Area = this.svg
      .append('g')
      .attr('id', `CANVAS_AREA`)
      .attr('transform', `translate(0,0)`)
      .attr('font-family', 'roboto')

      this.button_Home_v2(this.canvas_Area, {x: 450, y: 97, scale: 0.8,}, this.call_Home2, this.action_Area_to_Overall)
      
      // this.canvas_Area
      // .append('text')
      // .attr('transform', `translate(1350, 50)`)
      // .attr('font-size', 24).attr('fill', '#000')
      // .text('CANVAS - ARAE')


      this.block_Summary({
        selection: this.canvas_Area,
        id: 'TOP',
        x: 630,
        y: 500,
        area: 'TOPSIDE',
        code: 'CT'
      })

      this.block_Summary({
        selection: this.canvas_Area,
        id: 'HULL',
        x: 670,
        y: 800,
        area: 'HULL',
        code: 'CH'
      })

      this.block_Summary({
        selection: this.canvas_Area,
        id: 'LQ',
        x: 780,
        y: 260,
        area: 'LQ',
        code: 'CL'
      })

    }
  }
}